























































import { USPComponentTileViewObject } from '@/types/serverContract';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import USPComponentTile from '@/components/organisms/USPComponentTile/USPComponentTile.vue';
import FlickityWrap from '@/core/FlickityWrap.vue';
import { ADJACENT_DIRECTION } from '@/core/enums/enums';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoints.mixin';
import breakpointService from '@/core/responsive/breakpoints/breakpoints.service';

@Component({
    components: {
        USPComponentTile: USPComponentTile,
        FlickityWrap: FlickityWrap
    }
})
export default class USPComponentCarousel extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @Prop({ type: Array }) USPComponentTiles!: USPComponentTileViewObject[];

    fitTiles: USPComponentTileViewObject[];

    get alwaysUseCarouselOn() {
        return breakpointService.isActiveBreakpoint('xs, sm, md');
    }

    get showCarousel() {
        return this.fitTiles.length >= 2;
    }

    get getOptions() {
        return {
            draggable: true,
            prevNextButtons: false,
            pageDots: false,
            contain: true,
            groupCells: false,
            imagesLoaded: true,
            accessibility: false,
            dragThreshold: 20,
            cellAlign: 'left',
            autoPlay: 5000
        };
    }

    created() {
        this.prepareFitTiles();
    }

    changeSlider(direction: ADJACENT_DIRECTION) {
        switch (direction) {
            case ADJACENT_DIRECTION.Next:
                (this.$refs.flickity as FlickityWrap).next(true, false);
                break;
            case ADJACENT_DIRECTION.Previous:
                (this.$refs.flickity as FlickityWrap).previous(true, false);
                break;
        }
    }

    prepareFitTiles() {
        this.fitTiles = this.USPComponentTiles.filter(tile => this.hasRequiredData(tile));
    }

    hasRequiredData(tile: USPComponentTileViewObject) {
        return tile &&
        tile.icon &&
        tile.header &&
        tile.bodyText;
    }
}
