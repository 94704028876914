










































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    AddOrUpdateSpecialOrderLineItemRequest,
    AddToBasketRequest,
    DeleteProductCatalogLineRequest,
    FavoriteOrderLineItemViewObject,
    PriceRequest,
    PriceViewObject,
    UnitViewObject,
    UpdateFavoriteOrderSpecialLineItemRequest,
    UpdateProductCatalogLineItemRequest
} from '@/types/serverContract';
import ProductPrices from '@/project/products/ProductPrices.vue';
import Api from '@/project/http/api';
import SpecialProductTileAddToBasket from '@/project/products/SpecialProductTileAddToBasket.vue';
import priceService from '@/project/products/price.service';
import Constants from '@/project/config/constants';
import urlHelper from '@/project/shared/services/urlHelperSimpleSearch.service';
import ProductAddToBasket from '@/project/products/ProductAddToBasket.vue';
import productTrackingService from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';
import trackingService from '@/core/tracking/tracking.service';

@Component({
    components: {
        ProductAddToBasket,
        ProductPrices,
        SpecialProductTileAddToBasket
    }
})
export default class ProductCatalogProductTile extends Vue {
    @Prop({
        required: true,
        type: Object
    }) lineItem!: FavoriteOrderLineItemViewObject;

    pricesPending: boolean = false;
    price: PriceViewObject | null = null;
    updatePending: boolean = false;
    fixedUnitQty: number | null = this.lineItem.selectedUnit.isFixedUnit ? this.lineItem.fixedUnitValue || 0 : null;

    addSpecialLineItemPayload: AddOrUpdateSpecialOrderLineItemRequest = {
        lineItemId: null,
        manufacturer: this.lineItem.isSpecialLineItem ? this.lineItem.manufacturer : '',
        productItemNumber: this.lineItem.isSpecialLineItem ? this.lineItem.productItemNumber : '',
        productText: this.lineItem.isSpecialLineItem ? this.lineItem.productText : '',
        quantity: this.lineItem.isSpecialLineItem ? this.lineItem.quantity : 1,
        supplementaryProductText: this.lineItem.isSpecialLineItem ? this.lineItem.supplementaryProductText : '',
        unitCode: this.lineItem.isSpecialLineItem ? this.lineItem.selectedUnit.unitCode : '',
        caseInformation: null
    };

    get productCatalogId(): number {
        return urlHelper.getId();
    }

    get orderableUnits(): UnitViewObject[] | {fixedUnitQuantity: object; isFixedUnit: boolean; unitCode: string}[] {
        if (this.lineItem.isSpecialLineItem) {
            const arr: {fixedUnitQuantity: object; isFixedUnit: boolean; unitCode: string}[] = [];
            arr.push({
                unitCode: this.lineItem.selectedUnit.unitCode,
                isFixedUnit: false,
                fixedUnitQuantity: {}
            });
            return arr;
        }
        return this.lineItem.availableUnits;
    }

    get currentOrderUnit(): UnitViewObject {
        const currentOrderUnitFound = this.lineItem.availableUnits ? this.lineItem.availableUnits.find((unit) => {
            return this.lineItem.selectedUnit.unitCode === unit.unitCode;
        }) : this.lineItem.selectedUnit;

        return this.lineItem.availableUnits && currentOrderUnitFound ? currentOrderUnitFound : this.lineItem.selectedUnit;
    }

    created() {
        this.getPricesForProduct(this.lineItem);
    }

    async removeLineFromOrder(productId: number) {
        if (!this.lineItem.allowEdit) {
            return;
        }

        const payload: DeleteProductCatalogLineRequest = {
            productCatalogId: this.productCatalogId,
            lineId: productId,
            isSpecialLineItem: this.lineItem.isSpecialLineItem
        };

        try {
            await Api.productCatalog.deleteLineItem(payload).then(() => this.$emit('deleteSuccess'));
        } finally {
        }
    }

    async updateSpecialLineItem() {
        this.updatePending = true;

        const updateFavoriteOrderSpecialLinePayload: UpdateFavoriteOrderSpecialLineItemRequest = {
            lineItemId: this.lineItem.id,
            displayName: '',
            quantity: this.addSpecialLineItemPayload.quantity,
            favoriteOrderId: this.productCatalogId,
            fixedUnitValue: null,
            certificateCode: null,
            unitCode: this.lineItem.selectedUnit.unitCode,
            manufacturer: this.lineItem.manufacturer,
            productItemNumber: this.lineItem.productItemNumber,
            productText: this.lineItem.productText,
            supplementaryProductText: this.lineItem.supplementaryProductText
        };

        try {
            await Api.favoriteOrder.updateSpecialLineItem(updateFavoriteOrderSpecialLinePayload);
        } finally {
            this.updatePending = true;
        }
    }

    async onProductAddToBasketChange(payload: AddToBasketRequest) {
        if (payload === null) {
            return;
        }
        this.updatePending = true;
        const updateProductCatalogLineItemPayload: UpdateProductCatalogLineItemRequest = {
            lineItemId: this.lineItem.id,
            productCatalogId: this.productCatalogId,
            productId: this.lineItem.productId,
            quantity: payload.quantity,
            unitCode: payload.unit,
            certificateCode: payload.certificateCode,
            isFixedUnit: payload.isFixedUnit,
            fixedUnitValue: payload.fixedUnitValue,
            fixedUnitCode: payload.fixedUnitCode,
            stamping: payload.stamping
        };

        try {
            await Api.productCatalog.updateLineItem(updateProductCatalogLineItemPayload);
        } finally {
            this.updatePending = false;
        }
    }

    async getPricesForProduct(lineItem: FavoriteOrderLineItemViewObject | null) {
        if (!lineItem) {
            return;
        }

        this.pricesPending = true;

        const priceRequest: PriceRequest = {
            id: lineItem && lineItem.productId ? lineItem.productId : '',
            availabilityType: lineItem ? lineItem.availabilityType : 0,
            certificateCode: lineItem ? lineItem.availableCertificate : '',
            isSurplus: lineItem ? lineItem.isSurplus : false,
            isTemporarilyPhasedOut: lineItem ? lineItem.isTemporarilyPhasedOut : false,
            isVendorUnableToDeliver: lineItem ? lineItem.isVendorUnableToDeliver : false
        };

        try {
            this.price = await priceService.getPrice(priceRequest);
        } finally {
            this.pricesPending = false;
        }
    }

    onSpecialLineItemQtyChanged(specialLineItemQtyChanged: number): void {
        this.addSpecialLineItemPayload.quantity = specialLineItemQtyChanged;
        this.updateSpecialLineItem();
    }

    getThumbnailImage(productImageUrl: string): string {
        return productImageUrl || Constants.ImageNotFound;
    }

    trackProductClick(): void {
        if (this.lineItem) {
            productTrackingService.TrackProduct(
                PRODUCT_TRACKING_EVENT.ProductClick,
                productTrackingService.ToTrackedProduct(
                    this.lineItem,
                    PRODUCT_TRACKING_TYPE.FavoriteOrderLineItemViewObject,
                    this.price
                )
            );
        }
        this.$router.push({ path: this.lineItem.url });
    }

    // This function to be passed to the tracking directive
    public trackCurrentProduct() {
        // Passed a single product in a list to avoid breaking changes in tracking, ultimately this should be changed
        return trackingService.getAndTrackImpressionsFromFavoriteOrderLineItem([this.lineItem]);
    }
}
