import httpService from '@/core/http/http.service';
import {
    AddEasySupplyOrderLineRequest,
    AddEasySupplyProcuredProductToWarehouseRequest,
    AddEasySupplyWarehouseLineRequest,
    CreateEasySupplyProcuredProductRequest,
    CreateEasySupplyWarehouseRequest,
    EasySupplyOrderLinesRequest,
    DeleteEasySupplyOrderLineRequest,
    DeleteEasySupplyWarehouseLineRequest,
    EasySupplyOrderLinesViewModel,
    EasySupplyProcuredProductsListViewModel,
    EasySupplyWarehouseCreateViewModel,
    EasySupplyWarehouseDetailViewModel,
    EasySupplyWarehouseDetailsListViewModel,
    ProcuredProductUnitsViewModel,
    SearchEasySupplyWarehouseRequest,
    UpdateEasySupplyProcuredProductRequest,
    UpdateEasySupplyWarehouseLineRequest,
    UpdateEasySupplyWarehouseRequest,
    EasySupplyWarehouseDetailImportResultViewModel,
    AddEasySupplySmartButtonRequest,
    AddEasySupplyGatewayRequest,
    DeleteEasySupplySmartButtonRequest,
    ReconfigureEasySupplyGatewaysRequest,
    ReplaceEasySupplyGatewayRequest,
    EasySupplyWarehouseLinesForProcuredProductRequest,
    EasySupplyProcuredProductWarehouseLinesViewModel,
    DeleteEasySupplyGatewayRequest,
    UpdateEasySupplyGatewayFirmwareDetailsRequest,
    UpdateEasySupplySmartButtonFirmwareDetailsRequest,
    AddProductToEasySupplyWarehouseListViewModel
} from '@/types/serverContract';

export default class EasySupplyController {
    public async getEasySupplyWarehouseDetailsList(): Promise<EasySupplyWarehouseDetailsListViewModel> {
        return httpService.get<EasySupplyWarehouseDetailsListViewModel>('easySupplyApi/easySupplyWarehouseDetailsList');
    }

    public async getAddToEasySupplyWarehouseList(): Promise<AddProductToEasySupplyWarehouseListViewModel> {
        return httpService.get<AddProductToEasySupplyWarehouseListViewModel>('easySupplyApi/getAddToEasySupplyWarehouseList');
    }

    public async deleteEasySupplyWarehouse(id: number): Promise<EasySupplyWarehouseDetailsListViewModel> {
        return httpService.delete<EasySupplyWarehouseDetailsListViewModel>('easySupplyApi/deleteEasySupplyWarehouse', { id });
    }

    public async updateEasySupplyWarehouse(payload: UpdateEasySupplyWarehouseRequest): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.post<EasySupplyWarehouseDetailViewModel>('easySupplyApi/easySupplyWarehouse', payload);
    }

    public async getEasySupplyWarehouseCreateOptions(): Promise<EasySupplyWarehouseCreateViewModel> {
        return httpService.get<EasySupplyWarehouseCreateViewModel>('easySupplyApi/easySupplyWarehouseCreateOptions');
    }

    public async createEasySupplyWarehouse(payload: CreateEasySupplyWarehouseRequest): Promise<void> {
        return httpService.put('easySupplyApi/easySupplyWarehouse', payload);
    }

    public async addToEasySupplyWarehouseLine(payload: AddEasySupplyWarehouseLineRequest, messagesId: string): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.put<EasySupplyWarehouseDetailViewModel>('easySupplyApi/easySupplyWarehouseLine', payload, messagesId);
    }

    public async getEasySupplyOrderLines(payload: EasySupplyOrderLinesRequest): Promise<EasySupplyOrderLinesViewModel> {
        return httpService.get<EasySupplyOrderLinesViewModel>('easySupplyApi/easySupplyOrderLines', payload);
    }

    public async deleteEasySupplyOrderLine(payload: DeleteEasySupplyOrderLineRequest): Promise<EasySupplyOrderLinesViewModel> {
        return httpService.delete<EasySupplyOrderLinesViewModel>('easySupplyApi/easySupplyOrderLine', payload);
    }

    public async addEasySupplyOrderLine(payload: AddEasySupplyOrderLineRequest): Promise<EasySupplyOrderLinesViewModel> {
        return httpService.put<EasySupplyOrderLinesViewModel>('easySupplyApi/easySupplyOrderLine', payload);
    }

    public async getEasySupplyWarehouseDetail(payload: SearchEasySupplyWarehouseRequest): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.post('easySupplyApi/search', payload);
    }

    public async getEasySupplyWarehouseDetailForLabels(easySupplyWarehouseId: number): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.get<EasySupplyWarehouseDetailViewModel>('easySupplyApi/detailsForLabels', { easySupplyWarehouseId });
    }

    public async editEasySupplyWarehouseLine(payload: UpdateEasySupplyWarehouseLineRequest): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.post('easySupplyApi/easySupplyWarehouseLine', payload);
    }

    public async deleteEasySupplyWarehouseLine(payload: DeleteEasySupplyWarehouseLineRequest): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.post('easySupplyApi/deleteEasySupplyWarehouseLine', payload);
    }

    public async importEasySupplyFromCsv(payload: any): Promise<string> {
        return httpService.post('easySupplyApi/importFromCsv', payload);
    }

    public async importEasySupplyFromCsvResult(correlationId: string): Promise<EasySupplyWarehouseDetailImportResultViewModel> {
        return httpService.get('easySupplyApi/importFromCsvResult', { correlationId });
    }

    public async getProcuredProducts(): Promise<EasySupplyProcuredProductsListViewModel> {
        return httpService.get<EasySupplyProcuredProductsListViewModel>('easySupplyApi/procuredProducts');
    }

    public async createEasySupplyProcuredProduct(payload: CreateEasySupplyProcuredProductRequest): Promise<EasySupplyProcuredProductsListViewModel> {
        return httpService.put<EasySupplyProcuredProductsListViewModel>('easySupplyApi/createProcuredProduct', payload);
    }

    public async updateEasySupplyProcuredProduct(payload: UpdateEasySupplyProcuredProductRequest): Promise<EasySupplyProcuredProductsListViewModel> {
        return httpService.post('easySupplyApi/updateProcuredProduct', payload);
    }

    public async deleteEasySupplyProcuredProduct(id: number): Promise<EasySupplyProcuredProductsListViewModel> {
        return httpService.delete<EasySupplyProcuredProductsListViewModel>('easySupplyApi/procuredProduct', { id });
    }

    public async procuredProductUnits(): Promise<ProcuredProductUnitsViewModel> {
        return httpService.get('easySupplyApi/procuredProductUnits');
    }

    public async addProcuredProductToWarehouse(payload: AddEasySupplyProcuredProductToWarehouseRequest): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.put('easySupplyApi/easySupplyProcuredProductToWarehouse', payload);
    }

    public async easySupplyWarehouseLinesForProcuredProduct(payload: EasySupplyWarehouseLinesForProcuredProductRequest): Promise<EasySupplyProcuredProductWarehouseLinesViewModel> {
        return httpService.get<EasySupplyProcuredProductWarehouseLinesViewModel>('easySupplyApi/easySupplyWarehouseLinesForProcuredProduct', payload);
    }

    public async addEasySupplyGateway(payload: AddEasySupplyGatewayRequest): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.put('easySupplyApi/easySupplyGateway', payload);
    }

    public async reconfigureEasySupplyGateways(payload: ReconfigureEasySupplyGatewaysRequest): Promise<void> {
        return httpService.post('easySupplyApi/reconfigureEasySupplyGateways', payload);
    }

    public async replaceEasySupplyProcuredProduct(payload: ReplaceEasySupplyGatewayRequest): Promise<void> {
        return httpService.post('easySupplyApi/replaceEasySupplyGateway', payload);
    }

    public async addEasySupplySmartButton(payload: AddEasySupplySmartButtonRequest): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.put('easySupplyApi/easySupplySmartButton', payload);
    }

    public async deleteEasySupplySmartButton(payload: DeleteEasySupplySmartButtonRequest): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.delete<EasySupplyWarehouseDetailViewModel>('easySupplyApi/easySupplySmartButton', payload);
    }

    public async deleteEasySupplyGateway(payload: DeleteEasySupplyGatewayRequest): Promise<EasySupplyWarehouseDetailViewModel> {
        return httpService.delete<EasySupplyWarehouseDetailViewModel>('easySupplyApi/deleteEasySupplyGateway', payload);
    }

    public async updateEasySupplyGatewayFirmwareDetails(payload: UpdateEasySupplyGatewayFirmwareDetailsRequest): Promise<void> {
        return httpService.post('easySupplyApi/updateEasySupplyGatewayFirmwareDetails', payload);
    }

    public async updateEasySupplySmartButtonsFirmwareDetails(payload: UpdateEasySupplySmartButtonFirmwareDetailsRequest): Promise<void> {
        return httpService.post('easySupplyApi/updateEasySupplySmartButtonsFirmwareDetails', payload);
    }
}
