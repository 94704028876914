import httpService from '@/core/http/http.service';
import {
    ProductTileViewObject,
    ProductTileSlimViewObject,
    Top100ProductsRequest,
    Top100ProductsViewModel,
    LatestProductsRequest,
    LatestProductsViewModel,
    GetByVariantIdsRequest
} from '@/types/serverContract';

export default class ProductsController {
    public async getRelatedProducts(hybrisCodes: string[]): Promise<ProductTileViewObject[]> {
        return httpService.post('productApi/byHybrisCode', { hybrisCodes });
    }

    public async getRelatedProductsSlim(hybrisCodes: string[]): Promise<ProductTileSlimViewObject[]> {
        return httpService.post('productApi/ByHybrisCodeSlim', { hybrisCodes });
    }

    public async getProductsByVariantIdsSlim(payload: GetByVariantIdsRequest): Promise<ProductTileSlimViewObject[]> {
        return httpService.post('productApi/ByVariantIdsSlim', payload);
    }

    public async top100Products(payload: Top100ProductsRequest): Promise<Top100ProductsViewModel> {
        return httpService.get<Top100ProductsViewModel>('productApi/top100Products', payload);
    }

    public async LatestProducts(payload: LatestProductsRequest): Promise<LatestProductsViewModel> {
        return httpService.get<LatestProductsViewModel>('productApi/latestProducts', payload);
    }
}
