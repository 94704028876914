














































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import {
    InvoiceIdType,
    ProductIdType,
    SearchInvoiceHistoryRequest
} from '@/types/serverContract';
import dayjs, { Dayjs } from 'dayjs';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import BookkeepingUrlHelper from './BookkeepingUrlHelper.service';
import { dateParseFormat } from '@/project/config/utilities';
import { clone } from 'lodash-es';
import DatePickerCtrl from '@/project/form/DatePickerCtrl.vue';
import { Route } from 'vue-router';
import { KeyValue } from '@/project/config/models';

@Component({
    components: {
        DatePickerCtrl,
        SpinnerOverlay
    }
})
export default class InvoiceSearchForm extends Vue {
    optionsProductIdTypeChange: KeyValue[] = Object.keys(ProductIdType)
        .filter((key: string): boolean => isNaN(Number(key)))
        .map((val: string): KeyValue => {
            return {
                key: val,
                value: this.$tr(this.$labels.Views.Bookkeeping.Invoices.ProductIdType[val])
            };
        });

    optionsInvoiceIdType: KeyValue[] = Object.keys(InvoiceIdType)
        .filter((key: string): boolean => isNaN(Number(key)))
        .map((val: string): KeyValue => {
            return {
                key: val,
                value: this.$tr(this.$labels.Views.Bookkeeping.Invoices.InvoiceIdType[val])
            };
        });

    pending: boolean = false;
    selectedInvoiceIdType: KeyValue | null = null;
    selectedProductIdType: KeyValue | null = null;
    searchInvoiceRequestPayload: SearchInvoiceHistoryRequest = {
        invoiceNumber: BookkeepingUrlHelper.getSearchQuery('invoiceNumber'),
        invoiceIdType: BookkeepingUrlHelper.getSearchQuery('invoiceIdType') === '' ? InvoiceIdType.All : (BookkeepingUrlHelper.getSearchQuery('invoiceIdType') as unknown as InvoiceIdType),
        caseNumber: BookkeepingUrlHelper.getSearchQuery('caseNumber'),
        fromDate: BookkeepingUrlHelper.getSearchQuery('fromDate') === '' ? dayjs().subtract(6, 'month').format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('fromDate'),
        orderNumber: BookkeepingUrlHelper.getSearchQuery('orderNumber'),
        page: BookkeepingUrlHelper.getPageNumber(),
        pageSize: 20,
        productId: BookkeepingUrlHelper.getSearchQuery('productId'),
        productIdType: BookkeepingUrlHelper.getSearchQuery('productIdType') === '' ? ProductIdType.Sani : (BookkeepingUrlHelper.getSearchQuery('productIdType') as unknown as ProductIdType),
        reference: BookkeepingUrlHelper.getSearchQuery('reference'),
        toDate: BookkeepingUrlHelper.getSearchQuery('toDate') === '' ? dayjs().format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('toDate'),
        requisitionNumber: BookkeepingUrlHelper.getSearchQuery('requisitionNumber')
    };

    get isDateSpanTooLong(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) > 6;
    }

    get isDateSpanTooShort(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) < 0;
    }

    get dayjsFromDate(): Dayjs {
        return dayjs(this.searchInvoiceRequestPayload.fromDate);
    }

    get dayjsToDate(): Dayjs {
        return dayjs(this.searchInvoiceRequestPayload.toDate);
    }

    created() {
        this.searchInvoiceHistory();
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            return;
        }
        const pageChanged = this.searchInvoiceRequestPayload.page !== BookkeepingUrlHelper.getPageNumber();
        if (pageChanged) {
            this.searchInvoiceRequestPayload.page = BookkeepingUrlHelper.getPageNumber();
            this.searchInvoiceHistory();
        }
    }

    @Watch('searchInvoiceRequestPayload.fromDate')
    setFromDate(): void {
        if (this.isDateSpanTooLong) {
            this.searchInvoiceRequestPayload.toDate = this.dayjsFromDate.clone().add(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.searchInvoiceRequestPayload.toDate = clone(this.searchInvoiceRequestPayload.fromDate);
        }
    }

    @Watch('searchInvoiceRequestPayload.toDate')
    setToDate(): void {
        if (this.isDateSpanTooLong) {
            this.searchInvoiceRequestPayload.fromDate = this.dayjsToDate.clone().subtract(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.searchInvoiceRequestPayload.fromDate = clone(this.searchInvoiceRequestPayload.toDate);
        }
    }

    async searchInvoiceHistory(): Promise<void> {
        this.pending = true;
        try {
            await Api.invoices.search(this.searchInvoiceRequestPayload).then(searchInvoiceResult => {
                this.$emit('searchSuccess', searchInvoiceResult);
                BookkeepingUrlHelper.setSearchQuery({
                    invoiceNumber: this.searchInvoiceRequestPayload.invoiceNumber,
                    invoiceIdType: this.searchInvoiceRequestPayload.invoiceIdType,
                    caseNumber: this.searchInvoiceRequestPayload.caseNumber,
                    fromDate: this.searchInvoiceRequestPayload.fromDate,
                    orderNumber: this.searchInvoiceRequestPayload.orderNumber,
                    productId: this.searchInvoiceRequestPayload.productId,
                    productIdType: this.searchInvoiceRequestPayload.productIdType,
                    reference: this.searchInvoiceRequestPayload.reference,
                    toDate: this.searchInvoiceRequestPayload.toDate,
                    requisitionNumber: this.searchInvoiceRequestPayload.requisitionNumber,
                    page: this.searchInvoiceRequestPayload.page
                });
            });
        } finally {
            this.pending = false;
        }
    }

    onProductIdTypeChange(chosenProductIdType: any): void {
        this.searchInvoiceRequestPayload.productIdType = (ProductIdType[chosenProductIdType.key] as unknown as ProductIdType);
        this.selectedProductIdType = this.optionsProductIdTypeChange[this.searchInvoiceRequestPayload.productIdType];
    }

    onInvoiceIdTypeChange(chosenInvoiceIdType: any): void {
        this.searchInvoiceRequestPayload.invoiceIdType = (InvoiceIdType[chosenInvoiceIdType.key] as unknown as InvoiceIdType);
        this.selectedInvoiceIdType = this.optionsInvoiceIdType[this.searchInvoiceRequestPayload.invoiceIdType];
    }

    submitForm(): void {
        this.searchInvoiceRequestPayload.page = 1;
        this.searchInvoiceHistory();
    }
}
