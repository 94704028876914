import httpService from '@/core/http/http.service';
import {
    GetLatestOrdersRequest,
    GetOrderCertificateDetailsRequest,
    GetOrderDetailsRequest,
    OrderCertificateDetailsViewModel,
    OrderDetailsViewModel,
    OrderHistoryListViewModel,
    SearchOrderHistoryRequest,
    LatestOrderResumeViewModel
} from '@/types/serverContract';

export default class OrderHistoryController {
    public async search(payload: SearchOrderHistoryRequest): Promise<OrderHistoryListViewModel> {
        return httpService.get('orderHistoryApi/search', payload);
    }

    public async orderDetails(payload: GetOrderDetailsRequest): Promise<OrderDetailsViewModel> {
        return httpService.get<OrderDetailsViewModel>('orderHistoryApi/orderDetails', payload);
    }

    public async orderCertificateDetails(payload: GetOrderCertificateDetailsRequest): Promise<OrderCertificateDetailsViewModel> {
        return httpService.get<OrderCertificateDetailsViewModel>('orderHistoryApi/orderCertificateDetails', payload);
    }

    public async getLatestOrders(payload: GetLatestOrdersRequest): Promise<LatestOrderResumeViewModel> {
        return httpService.get('orderHistoryApi/getLatestOrders', payload);
    }
}
