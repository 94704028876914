

















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    DeliveryAddressViewObject,
    DeliveryAddressViewModel,
    DeliveryType
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { filter } from 'lodash-es';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class FlexBoxDeliveryAddress extends Vue {
    @Prop({
        type: Object,
        default: null
    }) selectedDeliveryAddress!: DeliveryAddressViewObject | null;

    pending: boolean = false;
    deliveryAddresses: DeliveryAddressViewModel | null = null;
    searchTerm: string = '';
    showAllAddress: boolean = false;
    deliveryAddressesLimit: number = 12;

    get deliveryAddressesToDisplay(): DeliveryAddressViewObject[] | null {
        return this.showAllAddress ? this.filteredDeliveryAddress : this.filteredDeliveryAddress!.slice(0, this.deliveryAddressesLimit);
    }

    get filteredDeliveryAddress(): DeliveryAddressViewObject[] | null {
        if (this.deliveryAddresses) {
            let filterResult = [...this.deliveryAddresses.addresses];
            if (this.searchTerm !== '') {
                filterResult = filter(this.deliveryAddresses.addresses, adr => {
                    // Excluding id from being hit by filtering
                    const filterAddress = { ...adr };
                    filterAddress.id = '';
                    return Object.values(filterAddress).join('').toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0;
                });
            }
            return filterResult;
        }
        return null;
    }

    created(): void {
        this.getDeliveryAddress();
    }

    async getDeliveryAddress(): Promise<void> {
        this.pending = true;
        try {
            await Api.deliveryAddress.getDeliveryAddresses(DeliveryType.DAG).then(deliveryAddresses => {
                this.deliveryAddresses = deliveryAddresses;
            });
        } finally {
            this.pending = false;
        }
    }

    selectDeliveryAddress(address: DeliveryAddressViewObject): void {
        this.$emit('selectDeliveryAddress', address);
    }

    toggleShowAllAddress(): void {
        this.showAllAddress = !this.showAllAddress;
    }

    goBack(): void {
        this.$emit('goBack');
    }
}
