









































































































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import scrollService from '@/core/scroll/scroll.service';
import './round.filter';

@Component
export default class VaPromilleCalc extends Vue {
    // Size
    sizePromille: number = 10;
    sizeLength: number = 10;
    sizeResult: number = 0;
    showSizeCalculations: boolean = false;
    // Length
    lengthPromille: number = 10;
    lengthHeight: number = 0.1;
    lengthResult: number = 0;
    showLengthCalculations: boolean = false;
    // Promille
    promilleHeight: number = 0.2;
    promilleLength: number = 38;
    promilleResult: number = 0;
    showPromilleCalculations: boolean = false;

    public $refs!: {
        calcHeightElement: HTMLElement;
        calcLengthElement: HTMLElement;
        calcPromilleElement: HTMLElement;
    };

    created(): void {
        this.sizeResult = this.calculateSize();
        this.lengthResult = this.calculateLength();
        this.promilleResult = this.calculatePromille();
    }

    // Size
    calculateSize(): number {
        const promille = this.sizePromille;
        const length = this.sizeLength;

        const sizeResult = (promille * length / 1000);
        this.sizeResult = sizeResult;
        return sizeResult;
    };

    toggleSize(): void {
        this.showSizeCalculations = !this.showSizeCalculations;
    }

    // Length
    calculateLength(): number {
        const height = this.lengthHeight;
        const promille = this.lengthPromille;

        const lengthResult = (height * 1000 / promille);
        this.lengthResult = lengthResult;
        return lengthResult;
    };

    toggleLength(): void {
        this.showLengthCalculations = !this.showLengthCalculations;
    }

    // Promille
    calculatePromille(): number {
        const height = this.promilleHeight;
        const length = this.promilleLength;

        const promilleResult = (height * 1000 / length);
        this.promilleResult = promilleResult;
        return promilleResult;
    };

    togglePromille(): void {
        this.showPromilleCalculations = !this.showPromilleCalculations;
    }

    goto(element: HTMLElement): void {
        scrollService.scrollToElement(element);
    };
}
