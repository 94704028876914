import { render, staticRenderFns } from "./ForgotPasswordPanel.vue?vue&type=template&id=6c7a14b6&scoped=true"
import script from "./ForgotPasswordPanel.vue?vue&type=script&lang=ts"
export * from "./ForgotPasswordPanel.vue?vue&type=script&lang=ts"
import style0 from "./ForgotPasswordPanel.vue?vue&type=style&index=0&id=6c7a14b6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c7a14b6",
  null
  
)

export default component.exports