
































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';

@Component({
    components: {
        SpinnerElement
    }
})
export default class ProductAddToBasketLifeCycleIcons extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    pending!: boolean;

    @Prop({
        type: String,
        default: 'currentColor'
    })
    color!: string;

    @Prop({
        type: Boolean,
        default: false
    })
    success!: boolean;

    @Prop({
        type: String,
        default: 'sanicon-ico-basket'
    })
    icon!: string;

    @Prop({
        default: false,
        type: Boolean
    })
    compact!: boolean;
}
