





































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { UpdateMyPasswordRequest } from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit,
        SpinnerOverlay
    }
})
export default class UpdateMyUserChangePassword extends Vue {
    changePasswordPayload: UpdateMyPasswordRequest = {
        confirmPassword: '',
        password: ''
    };

    showingPassword: boolean = false;
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;

    get mustMatchMessage() {
        return this.$tr(this.$labels.Views.ChangePassword.PasswordsNotEqual);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async submitChangePassword() {
        if (this.newPasswordsMatch()) {
            this.pending = true;
            try {
                await Api.user.changeMyPassword(this.changePasswordPayload);
                this.showSuccessIndicator();
            } catch {
            } finally {
                this.pending = false;
            }
        }
    }

    newPasswordsMatch(): boolean {
        return this.changePasswordPayload.password === this.changePasswordPayload.confirmPassword;
    }

    toggleShowPassword() {
        this.showingPassword = !this.showingPassword;
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
            this.resetForm();
        }, this.successTimer);
    }

    resetForm(): void {
        this.changePasswordPayload = {
            confirmPassword: '',
            password: ''
        };
    }
}
