



































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
    HelpDeskUserViewObject,
    RolesForSalesAccountViewObject
} from '@/types/serverContract';

@Component
export default class HelpDeskUserLine extends Vue {
    @Prop({ required: true, type: Object }) user!: HelpDeskUserViewObject;
    @Prop({ default: '', type: String }) searchSoldToPartnerId!: string;

    showAllAccounts: boolean = false;

    get sortedRoles(): RolesForSalesAccountViewObject[] {
        const roles = this.user.rolesForSalesAccounts;
        const searchSoldToPartnerId = this.searchSoldToPartnerId;
        if (searchSoldToPartnerId) {
            // move role with soldToPartnerId matching with searchSoldToPartnerId first
            roles.sort(role => role.soldToPartnerId === searchSoldToPartnerId ? -1 : 0);
        }
        return roles;
    }

    @Watch('user')
    onUserChange(): void {
        this.showAllAccounts = false;
    }

    selectUser(): void {
        this.$emit('userSelected', this.user);
    }

    toggle(): void {
        this.showAllAccounts = !this.showAllAccounts;
    }
}
