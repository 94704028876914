






















































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import urlHelper from '@/project/shared/services/urlHelperSimpleSearch.service';
import {
    GetProjectFolderProductCatalogRequest,
    ProjectFolderProductCatalogDetailViewModel,
    SortByViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import ProductCatalogDetailsActionLinks from '@/project/myServices/projectFolder/ProductCatalogDetailsActionLinks.vue';
import AddProductCatalogToBasket from '@/project/myServices/projectFolder/AddProductCatalogToBasket.vue';
import ProductCatalogDetailsSorting from '@/project/myServices/projectFolder/ProductCatalogDetailsSorting.vue';
import ProductCatalogProductTile from '@/project/myServices/projectFolder/ProductCatalogProductTile.vue';
import QueryPaging from '@/project/shared/QueryPaging.vue';
import { Route } from 'vue-router';
import scrollService from '@/core/scroll/scroll.service';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component({
    components: {
        QueryPaging,
        ProductCatalogProductTile,
        ProductCatalogDetailsSorting,
        AddProductCatalogToBasket,
        ProductCatalogDetailsActionLinks,
        SpinnerOverlay
    }
})
export default class ProductCatalogDetails extends Vue {
    productCatalogDetails: ProjectFolderProductCatalogDetailViewModel | null = null;
    pending: boolean = false;
    searchProjectCatalogPayload: GetProjectFolderProductCatalogRequest = {
        page: urlHelper.getPageNumber(),
        productCatalogId: urlHelper.getId(),
        sortBy: urlHelper.getSorting(),
        lineSearchTerm: urlHelper.getSearchTerm()
    };

    get sortingOptions(): SortByViewObject[] | null {
        return this.productCatalogDetails ? this.productCatalogDetails.sortingOptions : null;
    }

    get hasProductCatalogDetails(): boolean {
        return isNotNullEmptyOrUndefined(this.productCatalogDetails);
    }

    created(): void {
        this.getProductCatalogDetails();
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path === oldRoute.path) {
            scrollService.scrollToTop();
            this.updateProductCatalogDetailsPayload();
            this.getProductCatalogDetails();
        }
    }

    async getProductCatalogDetails(): Promise<void> {
        this.pending = true;
        try {
            await Api.productCatalog.productCatalogDetails(this.searchProjectCatalogPayload).then(productCatalogDetails => {
                this.productCatalogDetails = productCatalogDetails;
            });
        } finally {
            this.pending = false;
        }
    }

    setSearchTerm(): void {
        urlHelper.setSearchTerm(this.searchProjectCatalogPayload.lineSearchTerm);
    }

    updateProductCatalogDetailsPayload(): void {
        this.searchProjectCatalogPayload = {
            productCatalogId: urlHelper.getId(),
            page: urlHelper.getPageNumber(),
            sortBy: urlHelper.getSorting(),
            lineSearchTerm: urlHelper.getSearchTerm()
        };
    }
}
