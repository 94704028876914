import { EmptyLabel } from '@/types/label';
import log from '../logging.service';
import translateFilter from './translate.filter';
declare global {
    interface Window {
        dictionary: {
            [key: string]: string;
        };
    }
}

class DictionaryService {
    private dictionaryMap;

    constructor() {
        if (!window.dictionary) {
            throw new Error('### window.dictionary not set ###');
        }

        this.dictionaryMap = new Map<string, string>(Object.entries(window.dictionary));
    }

    firstLetterToLowerCase(inputString: string): string {
        if (inputString.length === 0) {
            return inputString; // Return the string as is if it's an empty string
        }

        const firstLetter = inputString[0].toLowerCase();
        const remainingString = inputString.slice(1);
        return firstLetter + remainingString;
    }

    public GetUmbracoFromKey(key: string, args: string[] = []) {
        if (!key) {
            return translateFilter('validation.UndefinedLabel');
        }
        if (key === 'undefined' || key === 'null' || key === 'NaN') {
            return EmptyLabel;
        }
        key = this.firstLetterToLowerCase(key); // Lowercase as for some reason it want it like that in the dicitonary map
        const translated = this.dictionaryMap.get(key);
        if (!translated) {
            log.warn(`### Key '${key}' not found in dictionary ###`);
            return key;
        }
        return this.format(translated, args);
    }

    public get(key: string, args: string[] = []) {
        const translated = this.dictionaryMap.get(key);
        if (!translated) {
            log.warn(`### Key '${key}' not found in dictionary ###`);
            return key;
        }
        return this.format(translated, args);
    }

    private format(translated, args: string[] = []): string {
        return args.reduce((result, arg, ix) => {
            return result.split(`{${ix}}`).join(arg);
        }, translated);
    }
}

export default new DictionaryService();
