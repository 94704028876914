










































































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    BasketViewModel,
    DeliveryAddressForCheckoutViewModel,
    DeliveryAddressForCheckoutViewObject,
    DeliveryType,
    SetPreferredDeliveryAddressRequest,
    OrderViewModel,
    CreateDeliveryAddressRequest
} from '@/types/serverContract';
import { BasketAction, BasketGetter } from '@/store/basket';
import { CheckoutAction, CheckoutGetter } from '@/store/checkout';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import DeliveryAddressAddAddressModal from '@/project/checkout/DeliveryAddressAddAddressModal.vue';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import constants from '@/project/config/constants';
import bus from '@/core/bus';

@Component({
    components: {
        SpinnerOverlay,
        DeliveryAddressAddAddressModal,
        ModalOverlay
    }
})
export default class DeliveryAddress extends Vue {
    @BasketGetter basket!: BasketViewModel;
    @BasketAction hasDeliveryMethodChanged!: (payload: DeliveryType | null) => Promise<boolean>;
    @CheckoutAction setOrder!: (payload: OrderViewModel | {}) => void;
    @CheckoutAction setOrderApproved!: (payload: boolean) => boolean;
    @CheckoutGetter isOrderApproved!: boolean;

    deliveryAddresses: DeliveryAddressForCheckoutViewModel | null = null;
    selectedDeliveryAddress: DeliveryAddressForCheckoutViewObject | null = null;
    showSelectionArea: boolean = false;
    searchTerm: string = '';
    showAllAddress: boolean = false;
    deliveryAddressesLimit: number = 12;
    showOverlay: boolean = false;
    currentDeliveryMethod: DeliveryType | null = null;
    // updateDeliveryAddresses: boolean = false;
    shouldDisplayAddAddressButton: boolean = false;
    isLoadingAddress: boolean = false;
    currentNewNightAddress: CreateDeliveryAddressRequest | null = null;
    hasAddedNightAddressAgain: boolean = false;

    get hasRequiredData(): boolean {
        return this.basket && (this.basket.deliveryMethod !== undefined &&
            this.basket.deliveryMethod !== 0);
    }

    get preferredAddress(): DeliveryAddressForCheckoutViewObject | null {
        if (this.deliveryAddresses === null) {
            return null;
        }

        return this.deliveryAddresses.addresses.find(address => address.isPreferred) || this.deliveryAddresses.addresses.find(address => address.id === this.deliveryAddresses!.selectedAddressId) || null;
    }

    get deliveryAddressesToDisplay(): DeliveryAddressForCheckoutViewObject[] {
        return this.showAllAddress ? this.filteredDeliveryAddress : this.filteredDeliveryAddress.slice(0, this.deliveryAddressesLimit);
    }

    get filteredDeliveryAddress(): DeliveryAddressForCheckoutViewObject[] {
        if (!this.deliveryAddresses) {
            return [];
        }
        if (!this.searchTerm) {
            return this.deliveryAddresses.addresses;
        }
        const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
        return this.deliveryAddresses.addresses.filter(adr => {
            const filterAddress = { ...adr, id: '', isPreferred: '' };
            return Object.values(filterAddress).join('').toLowerCase().includes(lowerCaseSearchTerm);
        });
    }

    get isDeliveryMethodStoreOrCC(): boolean {
        return this.currentDeliveryMethod !== null && (this.currentDeliveryMethod === DeliveryType.BUTIK || this.currentDeliveryMethod === DeliveryType.CC);
    }

    get isDeliveryDayOrNat(): boolean {
        return this.currentDeliveryMethod === DeliveryType.DAG || this.currentDeliveryMethod === DeliveryType.NAT;
    }

    get isSelectedAddressManualAddress(): boolean {
        return this.selectedDeliveryAddress !== null && this.selectedDeliveryAddress.id === constants.manualDeliveryAddressId;
    }

    get setPreferredLabel(): string {
        if (this.currentDeliveryMethod === null) {
            return '';
        }
        return this.currentDeliveryMethod === DeliveryType.BUTIK ? this.$tr(this.$labels.Views.Checkout.Delivery.SetAsAccountPickupStore) : this.$tr(this.$labels.Views.Checkout.Delivery.SetAsDefaultDeliveryAddress);
    }

    created() {
        bus.on('DeliveryAddress-onDeliveryMethodChanged', this.onDeliveryMethodChanged);
    }

    beforeDestroy() {
        bus.off('DeliveryAddress-onDeliveryMethodChanged', this.onDeliveryMethodChanged);
    }

    onDeliveryMethodChanged() {
        this.currentDeliveryMethod = this.basket.deliveryMethod;
        Api.deliveryAddress.getDeliveryAddressesForCheckout(this.currentDeliveryMethod).then((deliveryAddresses) => {
            this.deliveryAddresses = deliveryAddresses;
            this.getDeliveryAddress();
        });
    }

    async getDeliveryAddress(): Promise<void> {
        if (this.hasRequiredData && !this.isLoadingAddress) {
            this.selectedDeliveryAddress = null;
            if (this.deliveryAddresses) {
                if (this.deliveryAddresses.selectedAddressId && this.deliveryAddresses.selectedAddressId !== '') {
                    this.isLoadingAddress = true;
                    this.handlePreselectedDeliveryAddress();
                } else {
                    if (this.preferredAddress !== null) {
                        this.isLoadingAddress = true;
                        this.handlePreferredDeliveryAddress();
                    } else if (this.deliveryAddresses.addresses.length === 1) {
                        this.isLoadingAddress = true;
                        this.handleSingleDeliveryAddress();
                    } else {
                        // if there's no preselected or preferred delivery address, and there's more than one delivery address, show the selection area'
                        this.handleNoPreselectedOrPreferredDeliveryAddress();
                    }
                }
            } else {
                this.handleNoPreselectedOrPreferredDeliveryAddress();
            }
        }
    }

    async selectDeliveryAddress(address: DeliveryAddressForCheckoutViewObject | null): Promise<void> {
        if (!address) {
            this.showSelectionArea = true;
        } else if (this.selectedDeliveryAddress === address) {
            this.showSelectionArea = false;
        } else {
            await this.setDeliveryAddress(address);
        }
    }

    async handlePreselectedDeliveryAddress() {
        const filteredAddresses = this.deliveryAddresses!.addresses.filter(adr => adr.id === this.deliveryAddresses!.selectedAddressId);
        const preSelectedDeliveryAddress = filteredAddresses.length === 1 ? filteredAddresses[0] : null;

        this.selectDeliveryAddress(preSelectedDeliveryAddress);
    }

    async handlePreferredDeliveryAddress() {
        this.selectDeliveryAddress(this.preferredAddress);
    }

    handleSingleDeliveryAddress() {
        const singleAddress = this.deliveryAddresses!.addresses[0];

        this.selectDeliveryAddress(singleAddress);
    }

    handleNoPreselectedOrPreferredDeliveryAddress() {
        this.$emit('isLoading', false);
        this.showSelectionArea = true;
    }

    async setDeliveryAddress(address: DeliveryAddressForCheckoutViewObject): Promise<void> {
        this.$emit('isLoading', true);

        let error: any;
        try {
            this.isLoadingAddress = true;
            if (address.id === 'ManualAddress_' && this.currentNewNightAddress && !this.hasAddedNightAddressAgain) {
                this.hasAddedNightAddressAgain = true;
                await Api.deliveryAddress.addDeliveryAddress(this.currentNewNightAddress);
                this.onNewAddressCreated(this.currentNewNightAddress);
            } else {
                this.hasAddedNightAddressAgain = false;
                await Api.deliveryAddress.setDeliveryAddress(address.id);
                this.resetCurrentOrder();
            }
        } catch (e) {
            this.showSelectionArea = true;
            this.isLoadingAddress = false;
            this.$emit('isLoading', false);
            error = e;
        } finally {
            if (!error) {
                this.isLoadingAddress = false;
                this.selectedDeliveryAddress = address;

                // do not stop loading, the date and the approved list must be updated after, so the DeliveryApproveOrder component will stop loading
                this.showSelectionArea = false;
                bus.emit('DeliveryDate-onDeliveryAddressChanged');
            }
        }
    }

    async setPreferredStore(): Promise<void> {
        if (this.selectedDeliveryAddress) {
            const payload: SetPreferredDeliveryAddressRequest = {
                deliveryAddressId: this.selectedDeliveryAddress.id,
                deliveryMethod: this.currentDeliveryMethod!
            };
            await Api.deliveryAddress.setPreferredStore(payload);
        }
    }

    toggleShowSelectionArea(): void {
        this.showSelectionArea = !this.showSelectionArea;
    }

    async onNewAddressCreated(currentCreatedDeliveryAddressRequest: CreateDeliveryAddressRequest) {
        this.currentNewNightAddress = currentCreatedDeliveryAddressRequest;

        try {
            await Api.deliveryAddress.getDeliveryAddressesForCheckout(this.basket.deliveryMethod).then(deliveryAddresses => {
                this.deliveryAddresses = deliveryAddresses;
            });
        } finally {
            this.getDeliveryAddress();
        }
    }

    toggleShowAllAddress(): void {
        this.showAllAddress = !this.showAllAddress;
    }

    showCreateDeliveryAddress(): void {
        this.showOverlay = true;
    }

    hideCreateDeliveryAddress(): void {
        this.showOverlay = false;
    }

    resetCurrentOrder(): void {
        this.setOrder({});
        this.setOrderApproved(false);
    }

    getFullAddress(street: string, number: string, floor: string) {
        return `${street}${number ? ` ${number}` : ''}${floor ? `, ${floor}` : ''}`;
    }
}
