import {
    IFeatureToggling,
    Localization,
    LoginInformation,
    PageData,
    ServerContext,
    SitePages,
    SiteViewObject,
    RaptorContextData,
    CookieBotContextData
} from '@/types/serverContract';

declare global {
    interface Window {
        serverContext: ServerContext;
    }
}

class ServerContextService {
    constructor() {
        if (!window.serverContext) {
            throw new Error('### window.serverContext not set ###');
        }
    }

    public get initialPageData(): PageData {
        return window.serverContext.pageData;
    }

    public get sitePages(): SitePages {
        return window.serverContext.sitePages;
    }

    public get applicationCacheBuster(): string {
        return window.serverContext.applicationCacheBuster;
    }

    public get Localization(): Localization {
        return window.serverContext.localization;
    }

    public get FeatureToggling(): IFeatureToggling {
        return window.serverContext.featureToggling;
    }

    public get Sites(): SiteViewObject[] {
        return window.serverContext.sites;
    }

    public get loginInformation(): LoginInformation | null {
        return window.serverContext.loginInformation;
    }

    public get raptorContextData(): RaptorContextData {
        return window.serverContext.raptorContextData;
    }

    public get serverName(): any | null {
        return window.serverContext.serverName;
    }

    public get currency(): string {
        return window.serverContext.currency;
    }

    public get cookieBotContextData(): CookieBotContextData {
        return window.serverContext.cookieBotContextData;
    }

    public get audiences(): string {
        return window.serverContext.audiences;
    }

    public get nudgeOnPLP(): boolean {
        return window.serverContext.nudgeOnPLP;
    }

    public get nudgeOnPDP(): boolean {
        return window.serverContext.nudgeOnPDP;
    }

    public get nudgeOnTypeahead(): boolean {
        return window.serverContext.nudgeOnTypeahead;
    }

    public get nudgeOnBasket(): boolean {
        return window.serverContext.nudgeOnBasket;
    }
}

export default new ServerContextService();
