



































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import {
    FirmwareDetailsViewObject,
    UpdateEasySupplySmartButtonFirmwareDetailsRequest
} from '@/types/serverContract';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class UpdateSmartButtonsFirmware extends Vue {
    @Prop({
        required: true,
        type: String
    }) hardwareIdentifier!: string;

    @Prop({
        type: Object
    }) smartButtonsFirmwareDetails!: FirmwareDetailsViewObject;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number = 0;
    smartButtonsFirmwareHost: string = '';
    smartButtonsFirmwarePort: number = 0;
    smartButtonsFirmwareUri: string = '';
    smartButtonsFirmwareVersion: string = '';

    created() {
        this.setSmartButtonsFirmware(this.smartButtonsFirmwareDetails);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async updateSmartButtonsFirmware(): Promise<void> {
        if (this.pending) return;
        this.pending = true;

        const payload: UpdateEasySupplySmartButtonFirmwareDetailsRequest = {
            gatewayHardwareIdentifier: this.hardwareIdentifier,
            host: this.smartButtonsFirmwareHost,
            port: this.smartButtonsFirmwarePort,
            uri: this.smartButtonsFirmwareUri,
            version: this.smartButtonsFirmwareVersion
        };

        try {
            await Api.easySupply.updateEasySupplySmartButtonsFirmwareDetails(payload);
            this.showSuccessIndicator();
            this.$emit('updatedWarehouse');
            this.closeModal();
        } finally {
            this.pending = false;
        }
    }

    setSmartButtonsFirmware(firmware: FirmwareDetailsViewObject): void {
        if (firmware) {
            this.smartButtonsFirmwareHost = firmware.host;
            this.smartButtonsFirmwarePort = firmware.port;
            this.smartButtonsFirmwareUri = firmware.uri;
            this.smartButtonsFirmwareVersion = firmware.version;
        }
    }

    hideUpdateSmartButtonsFirmware(): void {
        this.$emit('hideUpdateSmartButtonsFirmware');
    }

    closeModal() {
        this.$emit('closeModal');
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors!: any;
}
