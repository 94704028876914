






































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    UserViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';
import constants from '@/project/config/constants';

@Component
export default class UpdateMailSubscriptions extends Vue {
    @Prop({
        required: true,
        type: Object
    }) userInformation!: UserViewModel;

    pending: boolean = false;

    get subscribeToEmailsUrl() {
        return constants.subscribeToEmailsUrl;
    }

    get subscriptionText() {
        return this.userInformation.isSubscribedToMarketingMails
            ? this.$tr(this.$labels.Views.EditUser.NewsMail.Views_EditUser_NewsMail_Unsubscribe_Text)
            : this.$tr(this.$labels.Views.EditUser.NewsMail.Views_EditUser_NewsMail_Subscribe_Text);
    }

    async submit(): Promise<void> {
        if (this.pending) return;

        this.pending = true;

        try {
            await this.optOutEmail();
        } finally {
            this.pending = false;
        }
    }

    async optOutEmail(): Promise<void> {
        await Api.hubSpot.unsubscribeAll({ emailAddress: this.userInformation.emailAddress });
    }
}
