


















import Vue from 'vue';
import scrollService from '@/core/scroll/scroll.service';
import { AnchorElementViewObject } from '@/types/serverContract';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class AnchorNavigation extends Vue {
    // This line will be commented back in later when the Umbraco part is done.
    @Prop({ type: Array as () => AnchorElementViewObject[] }) navigationItems!: AnchorElementViewObject[];

    scrollToElement(anchorId: string): void {
        const anchorElement = document.getElementById(anchorId);

        if (!anchorElement) {
            return;
        }

        scrollService.scrollToElement(anchorElement);
    }
}
