




























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import imageService from '@/core/image.service';

@Component
export default class ImageTextButtonModuleHorizontalSplit extends Vue {
    @Prop({
        type: String
    }) preheader!: string;

    @Prop({
        type: String
    }) header!: string;

    @Prop({
        type: String
    }) subheader!: string;

    @Prop({
        default: '',
        type: String
    }) textColor!: string;

    @Prop({
        default: '',
        type: String
    }) textBackgroundColor!: string;

    @Prop({
        type: String,
        default: 'Left',
        validator: (value: string) => ['Left', 'Right'].indexOf(value) > -1
    }) textAlignment!: string;

    @Prop({
        type: String
    }) buttonLink!: string;

    @Prop({
        default: '_self',
        type: String
    }) buttonLinkTarget!: string;

    @Prop({
        default: '',
        type: String
    }) buttonText!: string;

    @Prop({
        default: '',
        type: String
    }) buttonTextColor!: string;

    @Prop({
        default: '',
        type: String
    }) buttonColor!: string;

    @Prop({
        type: String,
        default: 'Left',
        validator: (value: string) => ['Left', 'Right'].indexOf(value) > -1
    }) buttonAlignment!: string;

    @Prop({
        default: '',
        type: String
    }) image!: string;

    @Prop({
        type: String
    }) imageLink!: string;

    @Prop({
        default: '_self',
        type: String
    }) imageLinkTarget!: string;

    get imageClasses(): string {
        return 'bg-center bg-cover bg-no-repeat';
    }

    get buttonBackgroundColor(): string {
        return '#' + this.buttonColor;
    }

    get styleForImage(): Partial<CSSStyleDeclaration> | null {
        if (this.image) {
            return {
                backgroundImage: `url('${this.getWebpCompatibleOptimizedSource}')`
            };
        }
        return null;
    }

    get getWebpCompatibleOptimizedSource(): string {
        return imageService.getWebpCompatibleOptimizedSource(this.image);
    }

    get styleForBackground(): Partial<CSSStyleDeclaration> | null {
        if (this.textBackgroundColor) {
            return {
                backgroundColor: `#${this.textBackgroundColor}`
            };
        }
        return null;
    }

    get styleForHeaders(): Partial<CSSStyleDeclaration> | null {
        if (this.textColor) {
            return {
                color: `#${this.textColor}`
            };
        }
        return null;
    }

    get styleForButton(): Partial<CSSStyleDeclaration> | null {
        if (this.buttonColor) {
            return {
                backgroundColor: this.buttonBackgroundColor,
                color: this.buttonTextColor
            };
        }
        return null;
    }

    trackPromotionClick(): void {
        this.$emit('trackPromotionClick');
    }
}
