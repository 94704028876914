import httpService from '@/core/http/http.service';
import {
    RecommendedProductsViewModel
} from '@/types/serverContract';

export default class RecommendedProductsController {
    public async getRecommendedProducts(productIds: string[]): Promise<RecommendedProductsViewModel[]> {
        return httpService.get<RecommendedProductsViewModel[]>(`/RecommendedProductsApi/RecommendedProducts?productIds=${productIds.join('&productIds=')}`);
    }
}
