














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ArticleImage extends Vue {
    @Prop({ type: String, default: '' }) image!: string;
    @Prop({ type: String, default: '' }) altText!: string;
}
