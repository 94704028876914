











































































































































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import {
    ReportOptionsViewModel,
    PriceType,
    ProductNumberType,
    ReportType,
    KeyAndValue,
    BaseReportRequest
} from '@/types/serverContract';
import urlHelper from '@/project/favoriteOrders/urlHelperFavoriteOrders.service';

@Component
export default class DownloadCatalogForm extends Vue {
    @Prop({
        type: Boolean,
        default: false
    }) pending!: boolean;

    @Prop({
        type: Boolean,
        default: true
    }) showEmailAddress!: boolean;

    emailAddressForReport = '';

    downloadCatalogStepNames: {enterEmailAddress: string; selectPriceType: string; selectProductNumberType: string; selectReportType: string} = {
        selectReportType: 'selectReportType',
        selectPriceType: 'selectPriceType',
        selectProductNumberType: 'selectProductNumberType',
        enterEmailAddress: 'enterEmailAddress'
    };

    downloadCatalogSteps: {isActive: boolean; name: string}[] = [
        { name: this.downloadCatalogStepNames.selectReportType, isActive: true },
        { name: this.downloadCatalogStepNames.selectPriceType, isActive: false },
        { name: this.downloadCatalogStepNames.selectProductNumberType, isActive: false },
        { name: this.downloadCatalogStepNames.enterEmailAddress, isActive: false }
    ];

    reportOptions: ReportOptionsViewModel | null = null;
    showChooseLabelPrinterReportTypeState: string = 'showChooseLabelPrinterReportType-true';
    selectedLabelPrinterReportType: KeyAndValue<ReportType, string> | null = null;

    baseReportRequest: BaseReportRequest = new class implements BaseReportRequest {
        reportType: ReportType = ReportType.ProductList;
        priceType: PriceType = PriceType.None;
        productNumberType: ProductNumberType = ProductNumberType.SaniNumber;
        sortBy: string = urlHelper.getSorting()
    }();

    get showChooseLabelPrinterReportType(): boolean {
        return this.baseReportRequest.reportType !== ReportType.ProductList &&
                this.baseReportRequest.reportType !== ReportType.LabelsSmall &&
                this.baseReportRequest.reportType !== ReportType.LabelsLarge;
    }

    get currentStep(): string {
        return this.downloadCatalogSteps.find(step => step.isActive)!.name;
    }

    get isFirstStep(): boolean {
        return this.downloadCatalogSteps.findIndex(step => step.isActive) === 0;
    }

    get isLastStep(): boolean {
        return this.downloadCatalogSteps.findIndex(step => step.isActive) === this.downloadCatalogSteps.length - 1;
    }

    @Watch('selectedLabelPrinterReportType')
    onSelectedLabelPrinterReportTypeChange() {
        if (this.selectedLabelPrinterReportType) {
            this.baseReportRequest.reportType = this.selectedLabelPrinterReportType.key;
        }
    }

    created() {
        this.getReportOptions();
    }

    async getReportOptions() {
        try {
            this.reportOptions = await Api.reports.reportOptions();
            this.emailAddressForReport = this.reportOptions.emailAddressForReport;
        } finally {

        }
    }

    onSelectChooseLabelPrinterReportType() {
        this.baseReportRequest.reportType = this.reportOptions!.labelPrinterReportTypes[0].key;
        this.selectedLabelPrinterReportType = this.getLabelPrinterReportByKey(this.baseReportRequest.reportType);
    }

    getLabelPrinterReportByKey(key: ReportType): KeyAndValue<ReportType, string> {
        return this.reportOptions!.labelPrinterReportTypes.find(labelPrinterReportType => labelPrinterReportType.key === key)!;
    }

    gotoPreviousStep() {
        const currentStep = this.downloadCatalogSteps.find(step => step.isActive);
        currentStep!.isActive = false;
        this.downloadCatalogSteps[this.downloadCatalogSteps.indexOf(currentStep!) - 1].isActive = true;
    }

    gotoNextStep() {
        const currentStep = this.downloadCatalogSteps.find(step => step.isActive);
        currentStep!.isActive = false;
        this.downloadCatalogSteps[this.downloadCatalogSteps.indexOf(currentStep!) + 1].isActive = true;
    }

    onSubmit() {
        this.$emit('downloadCatalog', this.baseReportRequest, this.emailAddressForReport);
    }
}
