export enum BACKGROUND_COLORS {
    BLUE_100= 'bg-blue-100',
    BLUE_350= 'bg-blue-350',
    BLUE_580= 'bg-blue-580',
    BLUE_690= 'bg-blue-690',
    WHITE= 'bg-white'
};

export enum BACKGROUND_COLORS_BLUE {
    BLUE_100 = 'bg-blue-100',
    BLUE_350 = 'bg-blue-350',
    BLUE_580 = 'bg-blue-580',
    BLUE_690 = 'bg-blue-690'
}

export enum COLORS_AHLSELL_BLUE {
    AHLSELL_15 = 'ahlsell-blue-15',
    AHLSELL_30 = 'ahlsell-blue-30',
    AHLSELL_45 = 'ahlsell-blue-45',
    AHLSELL_60 = 'ahlsell-blue-60'
}

export enum BACKGROUND_COLORS_UNITY {
    BLUE_100 = 'bg-ahlsell-unity-blue',
    BLUE_580 = 'bg-ahlsell-unity-green',
    BLUE_690 = 'bg-ahlsell-unity-orange'
}

export enum TILE_INDEX {
    First = 'First',
    Last = 'Last',
    Middle = 'Middle'
}
