





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { SuggestionViewObject } from '@/types/serverContract';
import FlickityWrap from '@/core/FlickityWrap.vue';

@Component
export default class SearchPanelSuggestionsSlider extends Vue {
    @Prop({
        type: Array,
        required: true
    }) suggestions!: SuggestionViewObject[];

    @Prop({
        type: String,
        default: ''
    }) selectedSuggestion!: string[];

    @Prop({
        type: String,
        default: ''
    }) btnClasses!: string;

    flickityOptions = {
        wrapAround: false,
        freeScroll: true,
        setGallerySize: true,
        contain: true,
        prevNextButtons: true,
        pageDots: false,
        percentPosition: false,
        cellAlign: 'left'
    };

    updated() {
        this.reloadFlickity();
    }

    onSuggestionClick(term): void {
        this.$emit('onSuggestionClick', term);
    }

    suggestionSearch(term): void {
        this.$emit('doSuggestionSearch', term);
    }

    reloadFlickity() {
        const flickitySlider = this.$refs.flickityWrap as FlickityWrap;
        flickitySlider.reloadCells();
    }

    clearSelectedSuggestion(): void {
        this.$emit('clearSelectedSuggestion');
    }

    getSelectedClasses(suggestion): string {
        return this.selectedSuggestion === suggestion.term ? 'selected' : '';
    }
}
