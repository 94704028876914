














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import {
    ArticleViewObject,
    RecommendationContentRequest,
    RecommendationParameter,
    RecommendationViewModel
} from '@/types/serverContract';
import RecommendationsArticlesSlider from '@/project/recommendations/RecommendationsArticlesSlider.vue';

@Component({
    components: {
        RecommendationsArticlesSlider
    }
})
export default class RaptorContentView extends Vue {
    @Prop({
        type: String,
        required: true
    }) title!: string;

    @Prop({
        type: String,
        required: true
    }) method!: string;

    @Prop({
        type: Array,
        required: true
    }) parameters!: RecommendationParameter[];

    @Prop({
        type: Number,
        default: 10
    }) maxElements!: number;

    pending: boolean = false;
    recommendationViewModel: RecommendationViewModel<ArticleViewObject> | null = null;

    get recommendationElements(): ArticleViewObject[] | null {
        return this.recommendationViewModel ? this.recommendationViewModel.elements : null;
    }

    created() {
        this.getRaptorProducts();
    }

    async getRaptorProducts() {
        this.pending = true;

        const payload: RecommendationContentRequest = {
            maxElements: this.maxElements,
            method: this.method,
            parameters: this.parameters
        };

        try {
            this.recommendationViewModel = await Api.recommendation.recommendationContent(payload);
        } finally {
            this.pending = false;
        }
    }
}
