






































import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { UserViewModel } from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import { UserGetter } from '@/store/user';
import serverContext from '@/core/serverContext.service';
import RouterLinkConditional from '@/core/routerLinkConditional.vue';

@Component({
    components: { SpinnerOverlay, RouterLinkConditional }
})
export default class AccountAvatarMenu extends Vue {
    @UserGetter user!: UserViewModel;

    showSpinner: boolean = false;

    get settingsPageLink(): string | null {
        return serverContext.sitePages.userEditPage ? serverContext.sitePages.userEditPage.url : null;
    }

    async logout(event) {
        event.preventDefault();
        this.showSpinner = true;

        try {
            await Api.authentication.logout();
        } finally {
            this.$emit('done');
        }
    }
}
