import httpService from '@/core/http/http.service';
import {
    CopyToFavoriteOrderRequest,
    DeleteScannerListLineRequest,
    GetScannerListRequest,
    ScannerListDetailViewModel,
    ScannerListsViewModel,
    UpdateScannerListLineItemRequest
} from '@/types/serverContract';

export default class ScannerListsController {
    public async scannerLists(): Promise<ScannerListsViewModel> {
        return httpService.get<ScannerListsViewModel>('scannerListApi/scannerLists');
    }

    public async scannerList(payload: GetScannerListRequest): Promise<ScannerListDetailViewModel> {
        return httpService.get<ScannerListDetailViewModel>('scannerListApi/scannerList', payload);
    }

    public async deleteScannerList(scannerListId: number): Promise<ScannerListsViewModel> {
        return httpService.delete<ScannerListsViewModel>('scannerListApi/scannerList/' + scannerListId);
    }

    public async lineItem(payload: UpdateScannerListLineItemRequest): Promise<void> {
        return httpService.post<void>('scannerListApi/lineItem', payload);
    }

    public async deleteLineItem(payload: DeleteScannerListLineRequest): Promise<void> {
        return httpService.post<void>('scannerListApi/deleteLineItem', payload);
    }

    public async copyToFavoriteOrder(payload: CopyToFavoriteOrderRequest): Promise<void> {
        return httpService.post<void>('scannerListApi/copyToFavoriteOrder', payload);
    }
}
