











































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    AllowanceChargeCalculationViewObject,
    LineItemDeliveryViewObject,
    LineItemViewObject,
    LoginInformation
} from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import Constants from '@/project/config/constants';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import DeliveryApproveOrderLineItemPrice from '@/project/checkout/DeliveryApproveOrderLineItemPrice.vue';

@Component({
    components: { DeliveryApproveOrderLineItemPrice }
})
export default class DeliveryApproveOrderLineItem extends Vue {
    @Prop({ required: true, type: Object }) lineDelivery!: LineItemDeliveryViewObject;

    @AppGetter loginInformation!: LoginInformation;

    isNotNullEmptyOrUndefined: Function = isNotNullEmptyOrUndefined;

    get hasCharges(): boolean {
        return this.lineDelivery.lineItem.charges && !!this.lineDelivery.lineItem.charges.length;
    }

    get chargesWithValues(): AllowanceChargeCalculationViewObject[] {
        return this.lineDelivery.lineItem.charges.filter(charge => isNotNullEmptyOrUndefined(charge.total));
    }

    get hasCertificate(): boolean {
        return isNotNullEmptyOrUndefined(this.lineDelivery.lineItem.certificate);
    }

    getThumbnailImage(lineItem: LineItemViewObject): string {
        return lineItem.thumbnailImage ? lineItem.thumbnailImage : Constants.ImageNotFound;
    }
}
