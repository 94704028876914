





































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import HistoricalDataSearchForm from '@/project/bookkeeping/historical/HistoricalDataSearchForm.vue';
import {
    DocumentListViewModel,
    HistoricDeliveryNotesRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import BookkeepingUrlHelper from '../BookkeepingUrlHelper.service';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import { headerHeight } from '@/core/responsive/breakpoints/breakpoints.service';
import scrollService from '@/core/scroll/scroll.service';
import QueryPaging from '@/project/shared/QueryPaging.vue';

@Component({
    components: {
        QueryPaging,
        SpinnerOverlay,
        HistoricalDataSearchForm
    }
})
export default class HistoricalDeliveryNotes extends Vue {
    pending: boolean = false;
    documentList: DocumentListViewModel | null = null;
    currentPage: number = 1;

    public $refs!: {
        documentsList: HTMLElement;
    };

    async searchDeliveryNotes(searchHistoricalDataPayload: HistoricDeliveryNotesRequest): Promise<void> {
        this.pending = true;
        try {
            await Api.historicalData.getDeliveryNotes(searchHistoricalDataPayload).then(searchDeliveryNotesResult => {
                const isInitialSearch = this.documentList === null;
                this.documentList = searchDeliveryNotesResult;
                this.currentPage = searchHistoricalDataPayload.page;
                if (!isInitialSearch) {
                    scrollService.scrollToElement(this.$refs.documentsList, -headerHeight(true));
                }
                BookkeepingUrlHelper.setSearchQuery({
                    fromDate: searchHistoricalDataPayload.fromDate,
                    orderNumber: searchHistoricalDataPayload.documentId,
                    toDate: searchHistoricalDataPayload.toDate,
                    page: searchHistoricalDataPayload.page
                });
            });
        } finally {
            this.pending = false;
        }
    }
}
