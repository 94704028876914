




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import {
    LineItemViewObject
} from '@/types/serverContract';

@Component
export default class LineItemCertificateOptions extends Vue {
    @Prop({
        type: Object
    }) lineItem!: LineItemViewObject;

    @Prop({
        type: String
    }) certificateCode!: string;

    @Prop({
        required: true,
        type: Boolean
    }) stamping!: boolean;

    @Prop({
        required: true,
        type: Boolean
    }) addCertificate!: boolean;

    currentCertificateAdded: boolean = this.addCertificate;
    currentStamping: boolean = this.stamping;

    get certificateInputLabel(): string {
        const hasCertificatePrice: boolean = this.lineItem.certificateOptions && isNotNullEmptyOrUndefined(this.lineItem.certificateOptions.certificatePrice);
        if (hasCertificatePrice) {
            return `${this.$tr(this.$labels.Product.Certificate)}:`;
        }
        return this.$tr(this.$labels.Product.Certificate);
    }

    get stampingInputLabel(): string {
        const hasStampingPrice: boolean = this.lineItem.certificateOptions && this.lineItem.certificateOptions.stamping && isNotNullEmptyOrUndefined(this.lineItem.certificateOptions.stampingPrice);
        if (hasStampingPrice) {
            return `${this.$tr(this.$labels.Product.Stamping)}:`;
        }
        return this.$tr(this.$labels.Product.Stamping);
    }

    updateLineItemtest() {
        this.$emit('update:addCertificate', this.currentCertificateAdded);
        this.$emit('update:certificateCode', this.lineItem.certificateOptions ? this.lineItem.certificateOptions.certificateCode : null);
        if (!this.currentCertificateAdded) {
            this.currentStamping = false;
        }
        this.$emit('update:stamping', this.currentStamping);

        this.$emit('updateLineItem');
    }
}
