import httpService from '@/core/http/http.service';
import {
    DeliveryViewModel,
    SetDeliveryTimeRequest
} from '@/types/serverContract';

export default class DeliveryTimesController {
    public async setDeliveryTime(payload: SetDeliveryTimeRequest): Promise<DeliveryViewModel> {
        return httpService.post('DeliveryTimesApi/DeliveryTime', payload);
    }
}
