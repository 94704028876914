


























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { FacetGroupViewObject } from '@/types/serverContract';
import urlHelper from './urlHelper.service';
import constants from '@/project/config/constants';

@Component
export default class SearchFacetGroupFacetRange extends Vue {
    @Prop({
        required: true,
        type: Object
    }) facetGroup!: FacetGroupViewObject;

    @Prop({
        default: 0,
        type: Number
    }) totalResultCount!: number;

    facetRangeMinInputValue: string = this.facetRangeMinUrlValue;
    facetRangeMaxInputValue: string = this.facetRangeMaxUrlValue;

    get facetRangeMinHasValue(): boolean {
        return !!urlHelper.getFacets()[this.facetGroup.key + constants.rangeFacetExtension.min];
    }

    get facetRangeMinUrlValue(): string {
        return this.facetRangeMinHasValue ? urlHelper.getFacets()[this.facetGroup.key + constants.rangeFacetExtension.min][0] : '';
    }

    get facetRangeMaxHasValue(): boolean {
        return !!urlHelper.getFacets()[this.facetGroup.key + constants.rangeFacetExtension.max];
    }

    get facetRangeMaxUrlValue(): string {
        return this.facetRangeMaxHasValue ? urlHelper.getFacets()[this.facetGroup.key + constants.rangeFacetExtension.max][0] : '';
    }

    get hasRangeValue(): boolean {
        return this.facetRangeMinHasValue || this.facetRangeMaxHasValue;
    }

    get chosenFacetRangeDisplayValue(): string {
        return `${this.facetRangeMinUrlValue} - ${this.facetRangeMaxUrlValue} ${this.facetGroup.range.unit} (${this.totalResultCount})`;
    }

    get rangeFacetsSubmitButtonLabel(): string {
        return this.$tr(this.$labels.Views.Search.Facets.Range.ButtonLabel);
    }

    @Watch('hasRangeValue', {
        immediate: true
    })
    hasRangeValueChange(): void {
        this.$emit('hasRangeValueChange', this.hasRangeValue);
    }

    setRangeFacet(): void {
        urlHelper.updateRangeFacetValue(this.facetGroup.key, constants.rangeFacetExtension.min, this.facetRangeMinInputValue);
        urlHelper.updateRangeFacetValue(this.facetGroup.key, constants.rangeFacetExtension.max, this.facetRangeMaxInputValue);
    }

    public resetFacetRange(): void {
        this.facetRangeMinInputValue = '';
        this.facetRangeMaxInputValue = '';
        urlHelper.updateRangeFacetValue(this.facetGroup.key, constants.rangeFacetExtension.min, '');
        urlHelper.updateRangeFacetValue(this.facetGroup.key, constants.rangeFacetExtension.max, '');
    }
}
