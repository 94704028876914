
















































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { OrderDetailsViewModel } from '@/types/serverContract';
import Api from '@/project/http/api';
import BookkeepingUrlHelper from './BookkeepingUrlHelper.service';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import OrderHistoryDetailsLineItem from '@/project/bookkeeping/OrderHistoryDetailsLineItem.vue';
import OrderHistoryDetailsActionLinks from '@/project/bookkeeping/OrderHistoryDetailsActionLinks.vue';
import { Route } from 'vue-router';

@Component({
    components: { OrderHistoryDetailsActionLinks, OrderHistoryDetailsLineItem, SpinnerOverlay }
})
export default class OrderHistoryDetails extends Vue {
    orderDetails: OrderDetailsViewModel | null = null;
    pending: boolean = false;

    created() {
        this.getOrderDetails();
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            return;
        }
        this.getOrderDetails();
    }

    async getOrderDetails() {
        this.pending = true;
        try {
            this.orderDetails = await Api.orderHistory.orderDetails({
                orderId: BookkeepingUrlHelper.getOrderId()
            });
        } finally {
            this.pending = false;
        }
    }
}
