













import { UserGetter } from '@/store/user';
import { UserViewModel } from '@/types/serverContract';
import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component
export default class AccountAvatarCircle extends Vue {
    @UserGetter user!: UserViewModel;
    isAnimating: boolean = false;

    get initials(): string {
        if (!this.user) {
            return '';
        }
        const name = this.user.name;
        const initials = name.match(/\b\w/g) || [];
        return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    }
}
