



















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
    AddOrUpdateSpecialOrderLineItemRequest,
    AllowanceChargeCalculationViewObject,
    LineItemViewObject,
    UnitViewObject,
    UpdateLineItemRequest
} from '@/types/serverContract';
import { Prop } from 'vue-property-decorator';
import ErrorMessages from '@/project/shared/ErrorMessages.vue';
import LineItemQuantity from '@/project/basket/LineItemQuantity.vue';
import Api from '@/project/http/api';
import { BasketAction } from '@/store/basket';
import { IUnitData, IFixedUnitValidationState } from '@/project/products/productHelper.utils';
import ProductOrderableUnit from '@/project/products/ProductOrderableUnit.vue';
import ProductNudgingTile from '@/project/products/ProductNudgingTile.vue';
import FixedUnitValidation from '@/project/shared/FixedUnitValidation.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import tracking from '@/core/tracking/tracking.service';
import LineItemPrice from '@/project/basket/LineItemPrice.vue';
import LineItemDescription from '@/project/basket/LineItemDescription.vue';
import LineItemActionButtons from '@/project/basket/LineItemActionButtons.vue';
import LineItemOrderInformationBox from '@/project/basket/LineItemOrderInformationBox.vue';
import LineItemCertificateOptions from '@/project/basket/LineItemCertificateOptions.vue';
import LineItemImage from '@/project/basket/LineItemImage.vue';
import LineItemPriceTotal from '@/project/basket/LineItemPriceTotal.vue';
import LineReference from '@/project/basket/LineReference.vue';
import { FlexBoxGetter } from '@/store/flexBox';
import EcoCertificationsBadges from '@/project/products/EcoCertificationsBadges.vue';
import serverContext from '@/core/serverContext.service';
import bus from '@/core/bus';
import productTrackingService, { GetFixedUnitVariantName, TrackedProduct } from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';

@Component({
    components: {
        LineItemOrderInformationBox,
        LineItemCertificateOptions,
        LineItemImage,
        LineItemDescription,
        LineItemActionButtons,
        LineItemPriceTotal,
        LineReference,
        LineItemPrice,
        FixedUnitValidation,
        ErrorMessages,
        LineItemQuantity,
        ProductOrderableUnit,
        EcoCertificationsBadges,
        ProductNudgingTile
    }
})
export default class LineItem extends Vue {
    @Prop({
        required: true,
        type: Object
    })
    lineItem!: LineItemViewObject;

    @Prop({
        required: false,
        default: false,
        type: Boolean
    })
    addReferenceLines!: boolean;

    @Prop({
        required: false,
        type: Array
    })
    referenceHelpList!: string[];

    @BasketAction setExistingOrderForm!: (payload: AddOrUpdateSpecialOrderLineItemRequest) => void;

    @FlexBoxGetter isInFlexBoxContext!: boolean;

    calculatingPrice: boolean = false;
    isSoldOut: boolean = false;
    pending: boolean = false;
    isDeleting: boolean = false;
    success: boolean = false;
    removeNudging: boolean = false;
    addCertificate: boolean = !!this.lineItem.certificate;
    fixedUnitQty: number | null = this.lineItem.fixedUnit ? this.lineItem.fixedUnit.value : null;

    fixedUnitValidationState: IFixedUnitValidationState = {
        isValid: true,
        maxValueNullOrValid: true,
        minValueValid: true
    };

    lineCustomerReference: string = '';

    selectedUnit: UnitViewObject = this.lineItem.unit;
    certificateCode: string | null =
    this.lineItem.certificateOptions ? this.lineItem.certificateOptions.certificateCode : null;

    stamping: boolean = this.lineItem.certificate ? this.lineItem.certificate.stamping : false;

    timeout: number | null = null;
    showBorderIfNudging: boolean = false;

    get basketLineItemPayload(): UpdateLineItemRequest {
        return {
            certificateCode: this.addCertificate ? this.certificateCode : null,
            fixedUnitValue: this.selectedUnit.isFixedUnit ? this.fixedUnitQty : null,
            fixedUnitCode: this.selectedUnit.isFixedUnit
                ? this.selectedUnit.fixedUnitQuantity.fixedUnitCode
                : null,
            isFixedUnit: this.selectedUnit.isFixedUnit ? this.selectedUnit.isFixedUnit : false,
            lineItemId: this.lineItem.lineIdentifier,
            quantity: this.lineItem.quantity,
            stamping: this.stamping,
            unit: this.selectedUnit.unitCode,
            customerReference: this.lineCustomerReference
        };
    }

    get hasPrimaryProperties(): boolean {
        return this.lineItem.primaryProperties && this.lineItem.primaryProperties.length >= 1;
    }

    get hasVariantProperties(): boolean {
        return this.lineItem.variantProperties && this.lineItem.variantProperties.length >= 1;
    }

    get fixedUnitMinQuantity(): string {
        return this.selectedUnit &&
            this.selectedUnit.isFixedUnit &&
            this.selectedUnit.fixedUnitQuantity.minimumQuantity
            ? this.selectedUnit.fixedUnitQuantity.minimumQuantity.toString()
            : '';
    }

    get fixedUnitMaxQuantity(): string {
        return this.selectedUnit &&
            this.selectedUnit.isFixedUnit &&
            this.selectedUnit.fixedUnitQuantity.maximumQuantity
            ? this.selectedUnit.fixedUnitQuantity.maximumQuantity.toString()
            : '';
    }

    get fixedUnitCode(): string {
        return this.selectedUnit && this.selectedUnit.isFixedUnit
            ? this.selectedUnit.fixedUnitQuantity.fixedUnitCode
            : '';
    }

    get hasOrderableUnits(): boolean {
        return (
            !this.lineItem.isSpecialOrderLineItem &&
            this.lineItem.orderableUnits &&
            this.lineItem.orderableUnits.length > 1
        );
    }

    get chargesWithPrice(): AllowanceChargeCalculationViewObject[] {
        return this.lineItem.charges.filter(charge => isNotNullEmptyOrUndefined(charge.total));
    }

    get nudgeBasketVisibility(): boolean {
        return !this.removeNudging && serverContext.nudgeOnBasket;
    }

    created() {
        bus.on('calculate-price-start', this.onCalculationPriceStart);
        bus.on('calculation-price-end', this.onCalculationPriceEnd);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        bus.off('calculation-start', this.onCalculationPriceStart);
        bus.off('calculation-end', this.onCalculationPriceEnd);
    }

    updateCurrentUnit(unitData: IUnitData): void {
        if (
            this.selectedUnit.unitKey !== unitData.orderableUnit.unitKey ||
            this.fixedUnitQty !== unitData.unitPayload.fixedUnitValue
        ) {
            this.selectedUnit = unitData.orderableUnit;
            this.fixedUnitQty = unitData.unitPayload.fixedUnitValue;

            this.$nextTick().then(() => {
                if (this.fixedUnitValidationState.isValid) {
                    this.updateLineItem();
                }
            });
        }
    }

    editSpecialOrderLineItem(): void {
        if (!this.lineItem.isSpecialOrderLineItem) {
            return;
        }
        const specialOrderPayLoad: AddOrUpdateSpecialOrderLineItemRequest = {
            lineItemId: this.lineItem.specialOrderLine
                ? this.lineItem.specialOrderLine.lineItemId
                : '',
            manufacturer: this.lineItem.specialOrderLine
                ? this.lineItem.specialOrderLine.manufacturer
                : '',
            productItemNumber: this.lineItem.specialOrderLine
                ? this.lineItem.specialOrderLine.productItemNumber
                : '',
            productText: this.lineItem.specialOrderLine
                ? this.lineItem.specialOrderLine.productText
                : '',
            quantity: this.lineItem.quantity,
            supplementaryProductText: this.lineItem.specialOrderLine
                ? this.lineItem.specialOrderLine.supplementaryProductText
                : '',
            unitCode: this.lineItem.unit ? this.lineItem.unit.unitCode : 'EA',
            caseInformation: null
        };

        this.setExistingOrderForm(specialOrderPayLoad);
    }

    validateFixedUnit(validState: IFixedUnitValidationState): void {
        this.fixedUnitValidationState = validState;
    }

    async updateLineItem() {
        this.pending = true;
        try {
            await Api.basket.updateBasketLineItem(this.basketLineItemPayload);
        } finally {
            this.$emit('customer-reference-help-list-updated');
            this.pending = false;
        }
    }

    async deleteLineItem() {
        this.isDeleting = true;
        try {
            await Api.basket.deleteBasketLineItem(this.lineItem.lineIdentifier);
            this.trackRemoveFromCart();
        } finally {
            this.isDeleting = false;
        }
    }

    private trackRemoveFromCart(): void {
        productTrackingService.TrackProduct(
            PRODUCT_TRACKING_EVENT.RemoveFromCart,
            productTrackingService.ToTrackedProduct(
                this.lineItem,
                PRODUCT_TRACKING_TYPE.LineItemViewObject,
                null,
                new TrackedProduct({
                    variant: GetFixedUnitVariantName(
                        this.basketLineItemPayload.isFixedUnit,
                        this.basketLineItemPayload.fixedUnitValue,
                        this.basketLineItemPayload.fixedUnitCode,
                        this.basketLineItemPayload.unit)
                })
            )
        );
    }

    onNudgingHasRecommendedProduct(value: boolean) {
        // if nudging have recommended product we want to add the border to the product
        this.showBorderIfNudging = value;
    }

    onRemoveNudgingUpdate(value: boolean) {
        this.removeNudging = value;
        this.showBorderIfNudging = false;
    }

    onCalculationPriceStart() {
        this.calculatingPrice = true;
    }

    onCalculationPriceEnd() {
        this.calculatingPrice = false;
    }

    public trackBasketNudgeExpanded(event): void {
        if (!event) {
            return;
        }
        tracking.trackNudgingForSalesExpand(
            this.lineItem.unitPrice,
            this.lineItem.productId,
            'BasketLineItem'
        );
    }

    public trackLineReferenceInteraction() {
        tracking.trackLineReferenceInteraction(this.lineItem.productId);
    }
}
