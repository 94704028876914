import {
    ActionTree,
    GetterTree,
    MutationTree
} from 'vuex';
import { namespace } from 'vuex-class';
import httpService from '@/core/http/http.service';
import log from '../../core/logging.service';
import {
    AgreementViewModel,
    AgreementViewObject,
    LoginInformation,
    PageDataViewModel,
    RaptorContextData
} from '@/types/serverContract';
import serverContextService from '@/core/serverContext.service';
import { StorageService } from '@/core/storage.service';
import Constants from '@/project/config/constants';

interface AppState {
    canSeeGrossPrices: boolean;
    canSeeNetPrices: boolean;
    hasOpenModal: boolean;
    hideNetPrices: boolean;
    isLoadingSpaPage: boolean;
    isLoginOverlayOpen: boolean;
    isLoginPanelActive: boolean;
    isNotFound: boolean;
    isPrerenderRequest: boolean;
    isPunchoutMode: boolean;
    loginInformation: LoginInformation | null;
    nonAcceptedAgreement: AgreementViewObject | null;
    pageDataViewModel: PageDataViewModel;
    pageHasCampaignView: boolean;
    raptorContextData: RaptorContextData;
    useFallbackCookieConsent: boolean;
}

enum MutationMethod {
    setHasOpenModal = 'setHasOpenModal',
    setHideNetPrices = 'setHideNetPrices',
    setIsLoginOverlayOpen = 'setIsLoginOverlayOpen',
    setIsNotFound = 'setIsNotFound',
    setIsPrerenderRequest = 'setIsPrerenderRequest',
    setLoginPanelActive = 'setLoginPanelActive',
    setNonAcceptedAgreement = 'setNonAcceptedAgreement',
    setPageHasCampaignView = 'setPageHasCampaignView',
    setSpaLoadingState = 'setSpaLoadingState',
    setUseFallbackCookieConsent = 'setUseFallbackCookieConsent',
    toggleHideNetPrices = 'toggleHideNetPrices',
    updatePage = 'updatePage'
}

const state: AppState = {
    pageDataViewModel: {
        content: null,
        pageData: serverContextService.initialPageData,
        featureToggling: serverContextService.FeatureToggling,
        sites: serverContextService.Sites,
        isNotFound: serverContextService.initialPageData.isNotFound
    },
    isLoadingSpaPage: false,
    isLoginPanelActive: false,
    hideNetPrices: false,
    canSeeNetPrices: serverContextService.loginInformation !== null ? serverContextService.loginInformation.canSeeNetPrices : false,
    canSeeGrossPrices: serverContextService.loginInformation !== null ? serverContextService.loginInformation.canSeeGrossPrices : false,
    loginInformation: serverContextService.loginInformation,
    raptorContextData: serverContextService.raptorContextData,
    nonAcceptedAgreement: null,
    hasOpenModal: false,
    isPrerenderRequest: serverContextService.initialPageData.isPrerenderRequest,
    useFallbackCookieConsent: serverContextService.initialPageData.useFallbackCookieConsent,
    isNotFound: serverContextService.initialPageData.isNotFound,
    isPunchoutMode: serverContextService.loginInformation !== null ? !!serverContextService.loginInformation.punchOutProvider : false,
    pageHasCampaignView: false,
    isLoginOverlayOpen: false
};

const getters: GetterTree<AppState, any> = {
    content: state => state.pageDataViewModel.content,
    hasSpaPage: state => state.pageDataViewModel.content != null,
    isNotFound: state => state.pageDataViewModel.isNotFound,
    metadata: state => state.pageDataViewModel.pageData.metadata,
    raptorPageData: state => state.pageDataViewModel.pageData.raptorPageData,
    breadcrumb: state => state.pageDataViewModel.pageData.breadcrumb,
    featureToggling: state => state.pageDataViewModel.featureToggling,
    infoMessages: state => state.pageDataViewModel.pageData.infoMessages,
    isCheckoutView: state => state.pageDataViewModel.pageData.isCheckoutView,
    pageHasCampaignView: state => state.pageHasCampaignView,
    leftNavigation: state => state.pageDataViewModel.pageData.leftNavigation,
    pageType: state => state.pageDataViewModel.pageData.pageType,
    isLoadingSpaPage: state => state.isLoadingSpaPage,
    loginInformation: state => state.loginInformation,
    isLoggedIn: state => state.loginInformation !== null,
    currentAccount: state => state.loginInformation !== null ? state.loginInformation.currentAccount : null,
    raptorContextData: state => state.raptorContextData,
    isLoginPanelActive: state => state.isLoginPanelActive,
    hideNetPrices: state => state.hideNetPrices,
    canSeeNetPrices: state => state.canSeeNetPrices,
    canSeeGrossPrices: state => state.canSeeGrossPrices,
    nonAcceptedAgreement: state => state.nonAcceptedAgreement,
    hasOpenModal: state => state.hasOpenModal,
    isPrerenderRequest: state => state.isPrerenderRequest,
    useFallbackCookieConsent: state => state.useFallbackCookieConsent,
    isPunchoutMode: state => state.isPunchoutMode,
    isLoginOverlayOpen: state => state.isLoginOverlayOpen
};

let noOfOutstandingFetches = 0;
const actions: ActionTree<AppState, any> = {
    async fetchPage({ commit }, route: string = '') {
        try {
            noOfOutstandingFetches++;
            commit(MutationMethod.setSpaLoadingState, true);
            const page = await httpService.getPage(route);
            if (page) {
                // Only use if page is not null. If null it has been canceled because of a newer page-request
                commit(MutationMethod.updatePage, page);
            }
            return page;
        } catch (error) {
            if (error.isRedirect === true) {
                // Don't log redirects
            } else {
                log.error(error);
            }
        } finally {
            noOfOutstandingFetches--;
            if (noOfOutstandingFetches === 0) {
                commit(MutationMethod.setSpaLoadingState, false);
            }
        }
    },
    setLoginPanelActive({ commit }, active: boolean) {
        commit(MutationMethod.setLoginPanelActive, active);
    },
    toggleHideNetPrices({ commit, state }) {
        commit(MutationMethod.toggleHideNetPrices);

        StorageService.setItem(Constants.storageKeys.hideNetPrices, state.hideNetPrices);
    },
    setHideNetPrices({ commit }, active: boolean) {
        commit(MutationMethod.setHideNetPrices, active);
        StorageService.setItem(Constants.storageKeys.hideNetPrices, active);
    },
    setNonAcceptedAgreement({ commit }, nonAcceptedAgreement: AgreementViewModel) {
        commit(MutationMethod.setNonAcceptedAgreement, nonAcceptedAgreement.agreement ? nonAcceptedAgreement.agreement : null);
    },
    setIsModalOpen({ commit }, isOpen: boolean) {
        commit(MutationMethod.setHasOpenModal, isOpen);
    },
    isPrerenderRequest({ commit }, isPrerenderRequest: boolean) {
        commit(MutationMethod.setIsPrerenderRequest, isPrerenderRequest);
    },
    useFallbackCookieConsent({ commit }, useFallbackCookieConsent: boolean) {
        commit(MutationMethod.setUseFallbackCookieConsent, useFallbackCookieConsent);
    },
    isNotFound({ commit }, isNotFound: boolean) {
        commit(MutationMethod.setIsNotFound, isNotFound);
    },
    setPageHasCampaignView({ commit }) {
        commit(MutationMethod.setPageHasCampaignView, true);
    },
    toggleLoginOverlay({ commit, state }, forceclose) {
        commit(MutationMethod.setIsLoginOverlayOpen, forceclose ? false : !state.isLoginOverlayOpen);
    }
};

const mutations: MutationTree<AppState> = {
    [MutationMethod.updatePage](state: AppState, data: PageDataViewModel) {
        state.pageDataViewModel = data;
    },
    [MutationMethod.setSpaLoadingState](state, isLoading) {
        state.isLoadingSpaPage = isLoading;
    },
    [MutationMethod.setLoginPanelActive](state, active) {
        state.isLoginPanelActive = active;
    },
    [MutationMethod.toggleHideNetPrices](state) {
        state.hideNetPrices = !state.hideNetPrices;
    },
    [MutationMethod.setHideNetPrices](state, active) {
        state.hideNetPrices = active;
    },
    [MutationMethod.setNonAcceptedAgreement](state, nonAcceptedAgreement) {
        state.nonAcceptedAgreement = nonAcceptedAgreement;
    },
    [MutationMethod.setHasOpenModal](state, isOpen) {
        state.hasOpenModal = isOpen;
    },
    [MutationMethod.setIsPrerenderRequest](state, isPrerenderRequest) {
        state.isPrerenderRequest = isPrerenderRequest;
    },
    [MutationMethod.setUseFallbackCookieConsent](state, useFallbackCookieConsent) {
        state.useFallbackCookieConsent = useFallbackCookieConsent;
    },
    [MutationMethod.setIsNotFound](state, isNotFound) {
        state.isNotFound = isNotFound;
    },
    [MutationMethod.setPageHasCampaignView](state, campaignViewExists) {
        state.pageHasCampaignView = campaignViewExists;
    },
    [MutationMethod.setIsLoginOverlayOpen](state, isLoginOverlayOpen) {
        state.isLoginOverlayOpen = isLoginOverlayOpen;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export const { Getter: AppGetter, Action: AppAction } = namespace('app');
