import { store } from '../../store';
import {
    RaptorPageData,
    RaptorContextData
} from '@/types/serverContract';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

declare global {
    interface Window {
        CookieConsent: any;
        raptor: any;
        raptorTrackingServiceInit(): void;
    }
}

export interface DataLayerEntry { [key: string]: any }

export interface TrackProductClickRaptorViewModel {
    eventType: string;
    methodName: string;
    productId: string;
    productName: string;
}

export class RaptorTracking {
    public initPageViewTracking(): void {
        // We don't know if raptor has loaded yet. If not, put the first page view tracking in a callback method.
        if (window.raptor) {
            this.trackPageVisit();
        } else {
            window.raptorTrackingServiceInit = () => {
                this.trackPageVisit();
            };
        }
    }

    public trackPageVisit() {
        const raptorPageData: RaptorPageData = store.getters['app/raptorPageData'];
        this.trackEvent('visit',
            raptorPageData.productId,
            raptorPageData.productName,
            raptorPageData.brandId,
            raptorPageData.pageId,
            raptorPageData.contentType,
            '',
            raptorPageData.categoryPath);
    }

    public trackProductClick(trackingData: TrackProductClickRaptorViewModel) {
        if (!isNotNullEmptyOrUndefined(window.raptor)) {
            return;
        }

        if (isNotNullEmptyOrUndefined(window.CookieConsent) && isNotNullEmptyOrUndefined(window.CookieConsent.consent)) {
            if (!window.CookieConsent.consent.statistics) {
                return;
            }
        }

        const raptorContextData: RaptorContextData = store.getters['app/raptorContextData'];

        window.raptor.trackClickEvent(
            { methodName: trackingData.methodName },
            [
                trackingData.eventType,
                trackingData.productId,
                trackingData.productName,
                '',
                '',
                '',
                raptorContextData!.domain, // {Domain}
                raptorContextData!.userId, // {UserId}
                '', // {IsSubscriber}
                raptorContextData!.caseKundenr, // {CASE_Kundenr}
                raptorContextData!.caseKontonr, // {CASE_Kontonr}
                '', // {BrandId}
                '', // {BasketContent}
                '', // {ContentId}
                raptorContextData!.kundeKategori, // {KundeKategori}
                '', // {ContentType}
                '', // {OrderId}
                '', // {Location}
                '', // {ButikMode}
                '', // {PostalCode}
                '', // {OrderDate}
                '', // {PurchaseOrderType}
                raptorContextData!.fakeContactType, // {CostCenter}
                '', // {VendorNo}
                '', // {Shop}
                '', // {Division}
                '', // {Segment}
                '', // {CategoryTreeLevel0}
                '', // {CategoryTreeLevel1}
                '', // {CategoryTreeLevel2}
                '', // {CategoryTreeLevel3}
                '', // {CategoryTreeLevel4}
                '', // {CategoryTreeLevel5}
                '', // {BillingQuantityUnit}
                '', // {GrossProfitPct}
                '', // {BillingQuantity}
                '', // {Revenue}
                '', // {GrossProfit}
                '', // {EmployeeInitials}
                '', // {SalesOffice}
                raptorContextData!.billToPartnerId, // {BillTo}
                '', // {Order origin}
                raptorContextData!.caseKontonr ? 'IsLoggedIn' : 'NotLoggedIn' // {LoggedInState}
            ]);
    }

    private trackEvent(eventType: string, productId: string, productName: string, brandId: string, pageId: string, contentType: string, basketContent: string, categoryPath: string) {
        if (!isNotNullEmptyOrUndefined(window.raptor)) {
            return;
        }

        if (isNotNullEmptyOrUndefined(window.CookieConsent) && isNotNullEmptyOrUndefined(window.CookieConsent.consent)) {
            if (!window.CookieConsent.consent.statistics) {
                return;
            }
        }

        const raptorContextData: RaptorContextData = store.getters['app/raptorContextData'];

        window.raptor.trackEvent(
            eventType, // {EventType}
            productId, // {ProductId}
            productName, // {ProductName}
            categoryPath, // {CategoryPath}
            '', // {Price}
            '', // {Currency}
            raptorContextData!.domain, // {Domain}
            raptorContextData!.userId, // {UserId}
            '', // {IsSubscriber}
            raptorContextData!.caseKundenr, // {CASE_Kundenr}
            raptorContextData!.caseKontonr, // {CASE_Kontonr}
            brandId, // {BrandId}
            basketContent, // {BasketContent}
            pageId, // {ContentId}
            raptorContextData!.kundeKategori, // {KundeKategori}
            contentType, // {ContentType}
            '', // {OrderId}
            '', // {Location}
            '', // {ButikMode}
            '', // {PostalCode}
            '', // {OrderDate}
            '', // {PurchaseOrderType}
            raptorContextData!.fakeContactType, // {CostCenter}
            '', // {VendorNo}
            '', // {Shop}
            '', // {Division}
            '', // {Segment}
            '', // {CategoryTreeLevel0}
            '', // {CategoryTreeLevel1}
            '', // {CategoryTreeLevel2}
            '', // {CategoryTreeLevel3}
            '', // {CategoryTreeLevel4}
            '', // {CategoryTreeLevel5}
            '', // {BillingQuantityUnit}
            '', // {GrossProfitPct}
            '', // {BillingQuantity}
            '', // {Revenue}
            '', // {GrossProfit}
            '', // {EmployeeInitials}
            '', // {SalesOffice}
            raptorContextData!.billToPartnerId, // {BillTo}
            '', // {Order origin}
            raptorContextData!.caseKontonr ? 'IsLoggedIn' : 'NotLoggedIn' // {LoggedInState}
        );
    }

    public get raptorSessionId(): any | null {
        if (!isNotNullEmptyOrUndefined(window.raptor)) {
            return null;
        }

        return window.raptor.sessionID;
    }
}

export default new RaptorTracking();
