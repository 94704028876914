


























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import ToggleCtrl from '@/project/form/ToggleCtrl.vue';
import ProductStoreStockItem from '@/project/products/ProductStoreStockItem.vue';
import { StoresStockQuantityViewModel, StoreStockQuantityViewObject } from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';
import { cloneDeep, debounce } from 'lodash-es';

@Component({
    components: {
        ModalOverlay,
        ToggleCtrl,
        ProductStoreStockItem,
        SpinnerElement
    }
})
export default class ProductStorageStatusModal extends Vue {
     @Prop({
         type: String
     }) productId!: string;

     showOnlyStoresWithProductInStock: boolean = false;
     showModal: boolean = false;
     storePending: boolean = false;
     storeStockViewModel: StoresStockQuantityViewModel | null = null;
     storeStockResetModel: StoresStockQuantityViewModel | null = null;
     inputSearch: string = '';
     suggestDebounceDelay: number = 500;
     debouncedOnInputSearchChangeFilterStoreByCity = debounce(this.onInputSearchChangeFilterStoreByCity, this.suggestDebounceDelay);

     get stores(): StoreStockQuantityViewObject[] | [] {
         if (this.storeStockViewModel) {
             let storeStocks = cloneDeep(this.storeStockViewModel.storeStockQuantityViewObject);
             if (this.showOnlyStoresWithProductInStock) {
                 storeStocks = this.filterStoresByInStock(storeStocks);
             }
             storeStocks = this.sortStoresAlphabetical(storeStocks);
             this.setMyStoreAsTheFirst(storeStocks);
             return storeStocks;
         }
         return [];
     }

     filterStoresByInStock(storeStocks: StoreStockQuantityViewObject[]): StoreStockQuantityViewObject[] {
         return storeStocks.filter(store => store.quantity);
     }

     sortStoresAlphabetical(storeStocks: StoreStockQuantityViewObject[]): StoreStockQuantityViewObject[] {
         return storeStocks.sort((a, b) => a.name.localeCompare(b.name));
     }

     setMyStoreAsTheFirst(storeStocks: StoreStockQuantityViewObject[]): void {
         const myStore = storeStocks.find(store => store.isMyStore);
         if (myStore) {
             const myStoreIndex = storeStocks.indexOf(myStore);
             storeStocks.splice(myStoreIndex, 1);
             storeStocks.unshift(myStore);
         }
     }

     toggleModal() {
         this.getStoresForProduct(this.productId);
         this.showModal = !this.showModal;
     }

     ToggleShowOnlyStoresWithProductInStock() {
         const BasketLineRefenceToggle = this.$refs.toggleReferenceLines as ToggleCtrl;
         this.showOnlyStoresWithProductInStock = BasketLineRefenceToggle.localToggledRight;
     }

     onInputSearchChangeFilterStoreByCity() {
         // Check if storeStockResetModel is initialized
         if (this.storeStockResetModel === null && this.storeStockViewModel) {
             this.storeStockResetModel = { ...this.storeStockViewModel };
         }

         if (this.inputSearch && this.storeStockViewModel && this.storeStockResetModel) {
             // Filter the data based on the inputSearch
             this.storeStockViewModel.storeStockQuantityViewObject = this.storeStockResetModel.storeStockQuantityViewObject.filter((storeStockItem) => {
                 return storeStockItem.city.toLowerCase().includes(this.inputSearch.toLowerCase());
             });
         } else if (this.storeStockViewModel && this.storeStockResetModel) {
             // Reset the data to the original unfiltered state
             this.storeStockViewModel.storeStockQuantityViewObject = [...this.storeStockResetModel.storeStockQuantityViewObject];
         }
     }

     async getStoresForProduct(productId: string) {
         if (productId) {
             this.storePending = true;
             try {
                 this.storeStockViewModel = await Api.stores.getStoreStockQuantity(productId);
             } finally {
                 this.storePending = false;
             }
         }
     }
}
