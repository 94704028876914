


















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { StoresListViewModel, StoreViewObject, MapLocation } from '@/types/serverContract';
import StoreListItem from '@/project/store/StoreListItem.vue';
import Constants from '@/project/config/constants';
import StoreIconsList from '@/project/store/StoreIconsList.vue';
import imageService from '@/core/image.service';
import { markerSize } from '@/types/mapMarkerSizes';

@Component({
    components: {
        StoreListItem,
        StoreIconsList
    }
})
export default class StoreList extends Vue {
    @Prop({
        required: true,
        type: Object
    }) mapCenter!: MapLocation;

    pending: boolean = false;
    storesListModel: StoresListViewModel | null = null;
    centerLat: number = 0;
    centerLng: number = 0;
    zoom: number = this.mapCenter.zoom;
    infoWindow = {
        position: { lat: 0, lng: 0 },
        open: false,
        template: ''
    };

    created() {
        this.getStores();
    }

    async getStores() {
        this.pending = true;
        try {
            this.storesListModel = await Api.stores.getStores();
            if (this.storesListModel) {
                this.setMyStore(this.storesListModel.storeList);
            }
        } finally {
            this.pending = false;
        }
    }

    openInfoWindow(store: StoreViewObject) {
        this.setInfoWindowTemplate(store);
        this.infoWindow.position = this.getCoordinates(store);
        this.infoWindow.open = true;
    }

    setInfoWindowTemplate(store: StoreViewObject) {
        this.infoWindow.template = `<div class="p-10">
                                    <a href="${store.url}" class="inline-block">
                                        <span class="block font-semibold mb-10 text-18 text-blue-700">${store.city}</span>
                                        <p class="mb-5">${store.street}</p>
                                        <p><span>${store.postalCode}</span> <span>${store.city}</span></p>
                                    </a>
                                </div>`;
    }

    resetAndCloseInfoWindow() {
        this.infoWindow = {
            position: { lat: 0, lng: 0 },
            open: false,
            template: ''
        };
    }

    storeIcon(isCollectStore: boolean, icon: string | null) {
        return {
            url: icon ? imageService.getWebpOptimizedSourceStrictSizeStretch(icon, '', markerSize, markerSize) : (isCollectStore ? Constants.CollectStoreMarker : Constants.StoreMarker),
            size: { width: markerSize, height: markerSize, f: 'px', b: 'px' }
        };
    }

    setMyStore(stores: StoreViewObject[]): void {
        const myStore = stores.find(store => store.isMyStore);

        this.centerLat = myStore ? myStore.mapLocation.latitude : this.mapCenter.latitude;
        this.centerLng = myStore ? myStore.mapLocation.longitude : this.mapCenter.longitude;
    }

    getCoordinates(store: StoreViewObject) {
        return {
            lat: store.mapLocation.latitude,
            lng: store.mapLocation.longitude
        };
    }
}
