















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
    NavigationItem,
    NavigationViewModel
} from '@/types/serverContract';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import { ActiveInfo } from '@/project/config/models';
import SideMenuItem from './SideMenuItem.vue';
import tracking, { NavigationClicksTypes } from '@/core/tracking/tracking.service';

const MAX_LEVELS = 10;

@Component({
    name: 'SideMenuSubLevel',
    components: {
        OffCanvasOverlay,
        SideMenuItem,
        // eslint-disable-next-line
        SideMenuSubLevel
    }
})
export default class SideMenuSubLevel extends Vue {
    @Prop({
        type: Object,
        required: true
    }) navigationItem!: NavigationViewModel;

    @Prop({
        type: Number,
        required: true
    }) activeLevel!: number;

    @Prop({
        type: Boolean,
        default: false
    }) tabCloseSubLevel!: boolean;

    @Prop({
        type: Function,
        required: true
    }) getBackgroundColorFunction!: Function;

    @Prop({
        type: Function,
        required: true
    }) shrinkToTabFunction!: Function;

    @Prop({
        type: Function,
        required: true
    }) isLastSubLevelFunction!: Function;

    @Prop({
        type: Function,
        required: true
    }) getLastChildFunction!: Function;

    isActive = true;
    sublevelTabCloseSubLevel = false;
    showSubLevel = false;
    nextLevelNavigationItem: NavigationItem | null = null;
    nextActiveLevel: number | null = null;
    isTransitionDone = true;
    isTab = false;
    isLastItem = true;
    setTabSizeFullHeight = false;
    subLevelComponent: SideMenuSubLevel | null = null;

    activeInfo: ActiveInfo = { level: this.activeLevel };

    backgroundColorStyle: Partial<CSSStyleDeclaration> = this.getBackgroundColorFunction(5 * this.activeLevel);
    subLevelLeftAlignmentMarginStyle: Partial<CSSStyleDeclaration> | null = null;
    subLevelBackgroundColorStyle: Partial<CSSStyleDeclaration> | null = null;

    navigationTracking(clickText: string) {
        const currentLevelNumber = this.activeInfo && this.activeInfo.level ? this.activeInfo.level + 1 : 1;
        tracking.trackNavigationClick({
            type: ('Left navigation bar - submenu_' + currentLevelNumber) as NavigationClicksTypes,
            clickText: clickText
        });
    }

    get sublevelEnterAnimationClasses() {
        return 'animated slideInLeft u-anim-dur-500 cubic-bezier-animation';
    }

    get sublevelLeaveAnimationClasses() {
        return 'animated slideOutLeft u-anim-dur-500 cubic-bezier-animation';
    }

    get tabEnterAnimationClasses() {
        const animCls = this.isTab ? 'fadeInUp' : 'fadeIn';
        return `animated ${animCls} u-anim-dur-500`;
    }

    get tabLeaveAnimationClasses() {
        const animCls = !this.isTab ? 'fadeOutDown' : 'fadeOut';
        return `animated ${animCls} u-anim-dur-500`;
    }

    @Watch('tabCloseSubLevel')
    onTabCloseSubLevelChange() {
        this.isTransitionDone = false;
        this.$nextTick().then(() => {
            this.closeSubLevel();
        });
    }

    tabAnimationAfterEnter() {
        // Add the class after enter transition is complete
        this.$nextTick(() => {
            this.setTabSizeFullHeight = true;
        });
    }

    tabAnimationBeforeLeave() {
        // Add the class after enter transition is complete
        this.$nextTick(() => {
            this.setTabSizeFullHeight = false;
        });
    }

    goToTab(label: string) {
        // calc how much we need to slide left when closing subLevelComponent
        this.calcMoreLeftAmount();
        // reset tab height for animation correction
        this.setTabSizeFullHeight = false;
        this.sublevelTabCloseSubLevel = true;
        this.navigationTracking(label);
    }

    calcMoreLeftAmount() {
        if (this.$refs.subLevelComponent) {
            const lastChild = this.getLastChildFunction(this.$refs.subLevelComponent) as SideMenuSubLevel;
            let moreLeft = lastChild.activeLevel - 2 - this.activeLevel;

            if (moreLeft > 0) {
                if (!lastChild || !lastChild.$parent || !lastChild.$parent.$el.parentElement) {
                    return;
                }

                lastChild.$parent.$el.parentElement.style.left = `${-71 * (moreLeft - 1)}px`;
            }
        }
    }

    goToNextLevel(navigationItem: NavigationItem) {
        // not already open
        if (!this.nextActiveLevel) {
            this.setTransitionStatus(false);
        }
        this.nextActiveLevel = this.activeInfo.level + 1;
        this.nextLevelNavigationItem = navigationItem;
        const nextLevelActiveInfo: ActiveInfo = { level: this.nextActiveLevel };

        this.subLevelBackgroundColorStyle = this.getBackgroundColorFunction(5 * this.nextActiveLevel);
        this.showSubLevel = true;
        this.isLastItem = this.isLastSubLevelFunction(nextLevelActiveInfo);

        const currentIterationLevel: ActiveInfo = { level: this.nextActiveLevel!, iterationLevel: this.activeInfo.level };

        this.$emit('updatePreviousItem', currentIterationLevel);
    }

    shouldShowNextLevel(navigationItem: NavigationItem): boolean {
        return navigationItem.children && navigationItem.children.length > 0 && this.activeLevel + 1 < MAX_LEVELS;
    }

    goToPreviousLevel() {
        this.isActive = false;
        this.closeSubLevelInParent();
    }

    closeSubLevelInParent() {
        this.$emit('closeSubLevel');
    }

    closeSubLevel() {
        this.showSubLevel = false;
        this.nextActiveLevel = null;
        this.nextLevelNavigationItem = null;
        // this.leftAlignmentMarginStyle = this.getLeftAmountFunction(this.activeInfo);
        this.isTab = false;
        this.$emit('updatePreviousItem', this.activeInfo);
    }

    updatePreviousItem(previousActive: ActiveInfo) {
        // get current iteration level and update values
        const currentIterationLevel: ActiveInfo = { level: this.nextActiveLevel!, iterationLevel: this.activeLevel };
        this.$emit('updatePreviousItem', currentIterationLevel);

        this.isTab = this.shrinkToTabFunction(previousActive);
        this.isLastItem = this.isLastSubLevelFunction(previousActive);
        this.sublevelTabCloseSubLevel = false;
    }

    closeOverlay(label?: string) {
        this.$emit('closeOverlay');
        if (label) this.navigationTracking(label);
    }

    setTransitionStatus(done: boolean) {
        this.isTransitionDone = done;
    }
}
