import httpService from '@/core/http/http.service';
import {
    StoreIconsListViewModel,
    StoresListViewModel,
    StoresStockQuantityViewModel
} from '@/types/serverContract';

export default class StoreController {
    public async getStoreStockQuantity(productId: string): Promise<StoresStockQuantityViewModel> {
        return httpService.get<StoresStockQuantityViewModel>('storeApi/storeStockQuantity', { productId });
    }

    public async getStores(): Promise<StoresListViewModel> {
        return httpService.get<StoresListViewModel>('storeApi/stores');
    }

    public async getStoreIcons(): Promise<StoreIconsListViewModel> {
        return httpService.get<StoreIconsListViewModel>('storeApi/storeIcons');
    }
}
