





























import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { StoreViewObject } from '@/types/serverContract';

@Component
export default class StoreListItem extends Vue {
    @Prop({
        type: Object
    }) store!: StoreViewObject;
}
