



































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { BasketGetter } from '@/store/basket';
import { CheckoutGetter, CheckoutAction } from '@/store/checkout';
import {
    BasketViewModel,
    OrderViewModel
} from '@/types/serverContract';
import OrderSummary from '@/project/checkout/OrderSummary.vue';
import EcoCertificationTotal from '@/project/basket/EcoCertificationTotal.vue';

@Component({
    components: {
        OrderSummary,
        EcoCertificationTotal
    }
})
export default class DeliveryApproveOrderSummary extends Vue {
    @BasketGetter basket!: BasketViewModel;
    @CheckoutGetter order!: OrderViewModel;
    @CheckoutGetter isOrderApproved!: boolean;

    @CheckoutAction setOrderApproved!: (payload: boolean) => boolean;

    get deliveryFeeNote(): string {
        return this.$tr(this.$labels.Views.Checkout.Delivery.Shipping.DeliveryFeeNote);
    }

    toggleIsOrderApproved() {
        this.setOrderApproved(!this.isOrderApproved);
    }
}
