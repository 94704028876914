










































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { uuid } from '@/project/config/utilities';

export interface CustomValidation {
    message: string;
    validator: () => boolean;
}

@Component({
    inject: ['$validator']
})
export default class TextInput extends Vue {
    @Prop({
        required: false,
        default: '',
        type: String
    }) label!: string;

    @Prop({
        required: true,
        type: [String, Number]
    }) value!: string | number;

    @Prop({
        required: true,
        type: String
    }) name!: string;

    @Prop({
        required: false,
        type: String
    }) placeholder!: string;

    @Prop({
        default: '',
        type: String
    }) constraints!: string;

    @Prop({
        default: '',
        type: String
    }) description!: string;

    @Prop({
        default: '',
        type: String
    }) icon!: string;

    @Prop({
        default: true,
        type: Boolean
    }) showClear!: boolean;

    @Prop({
        default: true,
        type: Boolean
    }) showError!: boolean;

    @Prop({
        default: false,
        type: Boolean
    }) autofocus!: boolean;

    @Prop({
        default: true,
        type: Boolean
    }) ignoreLastPass!: boolean;

    @Prop({
        default: '',
        type: String
    }) inputClass!: string;

    @Prop({
        default: false,
        type: Boolean
    }) disabled!: boolean;

    @Prop({
        type: Object,
        default: () => ({
            validator: () => true,
            message: ''
        } as CustomValidation)
    }) public customValidate!: CustomValidation;

    timeout: number | null = null;

    get id() {
        return this.name + uuid();
    }

    get field() {
        return this.vvFields[this.name];
    }

    get valid() {
        if (!this.field || !this.field.validated) {
            return false;
        }

        return this.field.valid && this.customValidate.validator();
    }

    get required() {
        return this.field && this.field.required;
    }

    get invalid() {
        if (!this.validated) {
            return false;
        }

        return this.field.invalid;
    }

    get validated() {
        return !(!this.field || !this.field.validated);
    }

    get error(): string | null {
        return (
            this.vvErrors.first(this.name) ||
            (this.validated && !this.customValidate.validator()
                ? this.customValidate.message
                : null)
        );
    }

    get placeholderShown() {
        return !this.value && this.placeholder;
    }

    get listeners() {
        const { input, blur, ...listeners } = this.$listeners; // eslint-disable-line @typescript-eslint/no-unused-vars
        return listeners;
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    @Watch('autofocus', {
        immediate: true
    })
    onAutofocusChange(value: boolean) {
        if (value) {
            try {
                this.timeout = setTimeout(() => (this.$refs.input as HTMLBaseElement).focus(), 400);
            } catch (e) {} // Safari sometimes
        }
    }

    @Watch('error')
    onErrorChange(value: string) {
        this.$emit('update:error', value);
    }

    public focus() {
        (this.$refs['input'] as HTMLInputElement).focus();
    }

    onInput(ev) {
        this.$emit('input', ev.target.value);

        if (this.field.validated && this.field.invalid) {
            this.validate();
        }
    }

    clearInput() {
        this.$emit('input', '').$nextTick(() => {
            this.validate();
        });
        this.focus();
    }

    onBlur(ev) {
        this.$emit('blur', ev);

        if (this.field.dirty) {
            this.validate();
        }
    }

    hasValue(value: any) {
        return value !== '' && value != null;
    }

    validate() {
        this.$validator.validate(this.name);
    }

    // From Vue-validate
    private vvErrors!: any;

    // From Vue-validate
    private vvFields!: any;
}
