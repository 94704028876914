







































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AddressViewObject } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';

@Component
export default class TrackAndTraceDetailsShipmentInfoFreightInfo extends Vue {
    @Prop({
        required: true,
        type: Object
    }) freightInfo!: {
        courier: string;
        id: string;
        senderReference: string;
    };

    @Prop({
        required: true,
        type: Boolean
    }) hasProofOfDelivery!: boolean;

    @Prop({
        required: true,
        type: Object
    }) freightInfoSender!: AddressViewObject;

    @Prop({
        required: true,
        type: Object
    }) freightInfoReceiver!: AddressViewObject;

    serverContext = serverContext;

    // Temp: until download url is delivered by BE
    get DfProofOfDeliveryUrl(): string {
        return `api/trackAndTraceApi/getDfProofOfDelivery?shipmentId=${this.freightInfo.id}&siteName=${serverContext.Localization.siteName}`;
    }
}
