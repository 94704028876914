











































































































































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
    DeleteEasySupplyWarehouseLineRequest,
    EasySupplyWarehouseLineViewObject,
    EasySupplyGatewayDetailViewObject,
    UpdateEasySupplyWarehouseLineRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import SmartButton from '@/project/easySupply/gateway/SmartButton.vue';
import DeleteSmartButton from '@/project/easySupply/gateway/DeleteSmartButton.vue';
import AddSmartButton from '@/project/easySupply/gateway/AddSmartButton.vue';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import keyboardService from '@/core/keyCodes';

@Component({
    components: {
        SmartButton,
        DeleteSmartButton,
        AddSmartButton,
        ModalOverlay,
        ButtonSubmit
    }
})
export default class EasySupplyWarehouseLineItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) warehouseLine!: EasySupplyWarehouseLineViewObject;

    @Prop({
        type: Object
    }) gateway!: EasySupplyGatewayDetailViewObject;

    showEditModal: boolean = false;
    showConfirmDeleteModal: boolean = false;
    locationDescription: string = '';
    reorderQuantity: string = '1';
    showSmartButtonModal: boolean = false;
    isDeleteSmartButton: boolean = false;
    pending: boolean = false;

    get smartButtonModalHeadingLabel(): string {
        return this.warehouseLine.smartButton
            ? this.$tr(this.$labels.Views.Services.EasySupply.Edit.Smartbutton.Text)
            : this.$tr(this.$labels.Views.Services.EasySupply.Edit.Smartbutton.AddSmartbutton);
    }

    get smartButtonStatusIndicator(): string {
        if (this.warehouseLine.smartButton.smartButtonStatus) {
            if (this.warehouseLine.smartButton.smartButtonStatus === 'Deployed') {
                return 'bg-green-500';
            } else if (this.warehouseLine.smartButton.smartButtonStatus === 'LowBattery' || this.warehouseLine.smartButton.smartButtonStatus === 'CannotBeConfigured') {
                return 'bg-yellow-500';
            } else if (this.warehouseLine.smartButton.smartButtonStatus === 'Missing') {
                return 'bg-red-500';
            }
        }

        return 'bg-yellow-500';
    }

    created(): void {
        this.setLocationDescription();
        this.setReorderQuantity();
    }

    destroyed(): void {
        document.removeEventListener('keyup', this.keyHandler);
    }

    @Watch('warehouseLine')
    onWarehouseLineChange(): void {
        this.setLocationDescription();
        this.setReorderQuantity();
    }

    async editLine() {
        if (this.pending) return;

        this.pending = true;

        const payload: UpdateEasySupplyWarehouseLineRequest = {
            easySupplyWarehouseId: this.warehouseLine!.easySupplyWarehouseId,
            easySupplyWarehouseLineId: this.warehouseLine!.id,
            locationDescription: this.locationDescription,
            reorderQuantity: parseInt(this.reorderQuantity, 10),
            reorderThreshold: null
        };

        try {
            await Api.easySupply.editEasySupplyWarehouseLine(payload);
            this.$emit('updatedWarehouse');
            this.closeConfirmationModal();
        } finally {
            this.pending = false;
        }
    }

    async deleteLine() {
        const payload: DeleteEasySupplyWarehouseLineRequest = {
            easySupplyWarehouseId: this.warehouseLine.easySupplyWarehouseId,
            lineId: this.warehouseLine.id
        };

        try {
            this.showConfirmDeleteModal = false;
            await Api.easySupply.deleteEasySupplyWarehouseLine(payload);
            this.$emit('updatedWarehouse');
        } finally { }
    }

    onUpdatedWarehouse(): void {
        this.$emit('updatedWarehouse');
    }

    setLocationDescription() {
        this.locationDescription = this.warehouseLine && this.warehouseLine.locationDescription ? this.warehouseLine.locationDescription : '';
    }

    setReorderQuantity() {
        this.reorderQuantity = this.warehouseLine && this.warehouseLine.reorderQuantity ? this.warehouseLine.reorderQuantity.toString() : '1';
    }

    openConfirmationModal() {
        this.showConfirmDeleteModal = true;
        document.addEventListener('keyup', this.keyHandler);
    }

    closeConfirmationModal() {
        this.showConfirmDeleteModal = false;
        this.showEditModal = false;
        document.removeEventListener('keyup', this.keyHandler);
        this.resetConfirmationModal();
    }

    resetConfirmationModal() {
        this.setLocationDescription();
        this.setReorderQuantity();
    }

    keyHandler(event) {
        if (keyboardService.isEnter(event)) {
            this.deleteLine();
        }
    }

    openSmartButtonModal() {
        this.showSmartButtonModal = true;
    }

    closeSmartButtonModal() {
        this.showSmartButtonModal = false;
        this.resetSmartButtonModal();
    }

    resetSmartButtonModal() {
        this.hideDeleteSmartButton();
    }

    showDeleteSmartButton(): void {
        this.isDeleteSmartButton = true;
    }

    hideDeleteSmartButton(): void {
        this.isDeleteSmartButton = false;
    }

    private vvErrors!: any;
}
