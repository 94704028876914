import httpService from '@/core/http/http.service';
import {
    SpecialLineItemUnitsViewModel
} from '@/types/serverContract';

export default class UnitController {
    public async specialLineItemUnits(): Promise<SpecialLineItemUnitsViewModel> {
        return httpService.get('unit/specialLineItemUnits');
    }
}
