





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CategoryButtonViewModel } from '@/types/serverContract';
import imageService from '@/core/image.service';

@Component
export default class CategoryButtons extends Vue {
    @Prop({ type: Array, default: () => [] }) categories!: CategoryButtonViewModel[];

    get cssCols() {
        const categoryCount = this.categories.length;
        return categoryCount > 2 ? 'md:grid-cols-2 lg:grid-cols-3 brake-col-md-3' : `md:grid-cols-${categoryCount}`;
    }

    get cssHeader() {
        return this.categories.length > 2 ? 'md:text-24' : '';
    }

    getImageStyles(imageUrl) {
        return imageUrl ? `background-image: url('${imageService.getWebpOptimizedSource(imageUrl, '', 700)}')` : null;
    }

    getLinkClasses(index, totalCount) {
        const lastMustFillSpace = index === totalCount - 1 && totalCount % 2 === 1;
        return lastMustFillSpace ? 'md:grid-column-3-full lg:grid-column-auto' : '';
    }
}
