




























































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    AvailabilityStatus,
    StockStatusViewObject,
    BasketViewModel
} from '@/types/serverContract';
import { FlexBoxGetter } from '@/store/flexBox';
import { BasketGetter } from '@/store/basket';
import flexBoxService from '@/project/flexBox/flexBox.service';

@Component
export default class ProductInventory extends Vue {
    @Prop({ type: Object, required: true }) inventoryStatus!: StockStatusViewObject;
    @Prop({ type: String, default: '' }) stockTheme!: string;
    @Prop({ type: Boolean, default: false }) showAmountInParenthesis!: boolean;
    @Prop({ type: Boolean, default: false }) showAmount!: boolean;
    @Prop({ type: Boolean, default: false }) hideDelivery!: boolean;
    @Prop({ type: String, default: 'text-18' }) iconSize!: string;
    @Prop({ type: Boolean, required: true }) isStockedItem!: boolean;
    @Prop({ type: Boolean, default: false }) inlineMode!: boolean;

    @FlexBoxGetter isInFlexBoxContext!: boolean;

    @BasketGetter basket!: BasketViewModel;

    get inventoryQuantity(): string {
        return this.inventoryStatus.inventory.quantity;
    }

    get outOfStockIndicator(): string {
        return this.inventoryStatus.inStockIndicator === 'indicator-warning' ? 'bg-yellow-500' : 'bg-red-500';
    }

    get inventoryUnit(): string | undefined {
        return this.inventoryStatus.inventory ? this.inventoryStatus.inventory.unit.displayName : undefined;
    }

    get isInStock(): boolean {
        return this.inventoryStatus.status === AvailabilityStatus.InStock;
    }

    get isInStockHideamount(): string {
        return this.$tr(this.$labels.Product.InStock).charAt(0).toUpperCase() + this.$tr(this.$labels.Product.InStock).slice(1);
    }

    get isTemporarilyPhasedOut(): boolean {
        return this.inventoryStatus.status === AvailabilityStatus.TemporarilyPhasedOut;
    }

    get isVendorUnableToDeliver(): boolean {
        return this.inventoryStatus.status === AvailabilityStatus.VendorUnableToDeliver;
    }

    get deliveryText(): string {
        return flexBoxService.getFlexBoxInfoText(this.$labels.Views.FlexBox.TimeSpecific.ForDeliveryIn.name, this.$labels.Views.FlexBox.TimeSpecific.ForDeliveryIn.name);
    }
}
