
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'store-sync'
})
export default class StoreSync extends Vue {
    @Prop({
        required: true
    })
    private namespace!: string;

    @Prop({
        required: true
    })
    private property!: string;

    @Prop({
        required: true
    })
    private data!: any;

    private created() {
        this.$store.commit(
            'sync',
            {
                namespace: this.namespace,
                property: this.property,
                data: this.data
            },
            { root: true }
        );
    }
}
