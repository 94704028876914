var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-text-input"},[_c('div',{staticClass:"relative"},[_c('input',_vm._g(_vm._b({directives:[{name:"validate",rawName:"v-validate.disable",value:(_vm.constraints),expression:"constraints",modifiers:{"disable":true}}],ref:"input",staticClass:"c-text-input__input",class:[
                {
                    'c-text-input--error': _vm.error,
                    'c-text-input--has-icon': _vm.icon,
                    'c-text-input--input-not-empty': _vm.hasValue(_vm.value),
                    'c-text-input--has-clear': _vm.showClear,
                    'c-text-input--has-label': _vm.label,
                    'c-text-input--placeholder-shown': _vm.placeholderShown
                },
                _vm.inputClass
            ],attrs:{"id":_vm.id,"name":_vm.name,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"data-lpignore":_vm.ignoreLastPass,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"blur":_vm.onBlur}},'input',_vm.$attrs,false),_vm.listeners)),(_vm.label)?_c('label',{staticClass:"c-text-input__label",attrs:{"for":_vm.id,"title":_vm.label}},[_vm._v("\n            "+_vm._s(_vm.label)+"\n        ")]):_vm._e(),(_vm.icon)?_c('i',{staticClass:"c-text-input__icon absolute text-18",class:_vm.icon}):_vm._e(),_c('transition',{attrs:{"mode":"out-in","enter-active-class":"animated fadeIn u-anim-dur-300","leave-active-class":"animated fadeOut u-anim-dur-300"}},[(_vm.showClear && _vm.hasValue(_vm.value))?_c('button',{staticClass:"c-btn c-btn--icon-only c-text-input__field-clear",attrs:{"aria-label":_vm.$tr(_vm.$labels.Views.General.ClearField),"type":"button","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.clearInput($event)}}},[_c('i',{staticClass:"sanicon-ico-field-clear"})]):_vm._e()])],1),(_vm.description)?_c('div',{staticClass:"c-text-input__description mx-20 mt-5 text-15"},[_vm._v("\n        "+_vm._s(_vm.description)+"\n    ")]):_vm._e(),(_vm.showError && _vm.error)?_c('div',{staticClass:"mt-5 text-13 font-semibold text-red-700"},[_vm._v("\n        "+_vm._s(_vm.error)+"\n    ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }