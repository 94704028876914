
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class YTPlayer extends Vue {
    @Prop({ default: '', type: String }) videoId!: string;
    @Prop({ default: false, type: Boolean }) autoplay!: boolean;

    error = false;
    win = window as any;
    YTPlayer: YT.Player | null = null;
    isYTPlayerReady = false;
    userInteracted = false;
    YTPlayerWasVisible = false;

    mounted() {
        this.loadYTAPI();
    }

    @Watch('isYTPlayerReady')
    onYTPlayerReadyChange() {
        if (this.isYTPlayerReady && this.YTPlayer && this.YTPlayerWasVisible && this.autoplay) {
            this.YTPlayer.playVideo();
            this.YTPlayerWasVisible = false;
        }
    }

    onYTVisible() {
        this.YTPlayerWasVisible = true;

        if (this.isYTPlayerReady && this.YTPlayer && this.autoplay && !this.userInteracted) {
            this.YTPlayer.playVideo();
            this.YTPlayerWasVisible = false;
        }
    }

    onYTHidden() {
        if (this.isYTPlayerReady && this.YTPlayer) {
            if (!this.userInteracted) {
                this.YTPlayer.pauseVideo();
            }
        }
    }

    loadYTAPI() {
        if (this.win.YT) {
            this.initYT();
            return;
        }
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        if (firstScriptTag && firstScriptTag.parentNode) {
            // Add an event listener for the 'load' event to call initYoutube
            this.win.onYouTubeIframeAPIReady = this.initYT;
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
    }

    initYT() {
        const refPlayerElement = this.$refs.YTPlayer as HTMLElement;
        this.YTPlayer = new YT.Player(refPlayerElement, {
            width: '100%',
            videoId: this.videoId,
            events: {
                onReady: this.onYTPlayerReady,
                onVolumeChange: this.onYTPlayerVolumeChange,
                onError: this.onYTPlayerError
            }
        });
        this.isYTPlayerReady = true;
    }

    onYTPlayerReady(evt) {
        if (evt.target.playerInfo.videoData.video_id) {
            this.isYTPlayerReady = true;
            this.YTPlayer!.mute();
        } else {
            this.onYTPlayerError();
        }
    }

    onYTPlayerVolumeChange(evt) {
        if (!this.userInteracted && !evt.data.muted) {
            this.userInteracted = true;
        }
    }

    onYTPlayerError() {
        this.error = true;
    }
}
