

















































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    CreateUserFromAdminRequest,
    UpdateUserRelations,
    UserStoreViewObject,
    UserViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';
import AccountAccessesList from '@/project/user/AccountAccessesList.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import scrollService from '@/core/scroll/scroll.service';
import { headerHeight } from '@/core/responsive/breakpoints/breakpoints.service';
import RedirectException from '@/core/http/redirectException';
import tracking, { SignUpTypesEnum } from '@/core/tracking/tracking.service';
import UpdateUserPunchOut from '@/project/user/UpdateUserPunchOut.vue';

@Component({
    components: {
        UpdateUserPunchOut,
        ButtonSubmit,
        AccountAccessesList
    }
})
export default class CreateUserFromAdmin extends Vue {
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;

    userInformation: UserViewModel = {
        accountRelations: [],
        activeStatus: true,
        isDeveloper: false,
        isPunchOut: false,
        isEasySupplyAdmin: false,
        hasPunchOutCustomXslt: false,
        canEditUser: false,
        canEditDeveloperRole: false,
        canEditPunchOutRole: false,
        canEditActiveStatus: true,
        canEditUsername: true,
        canEditEasySupplyAdminRole: false,
        canEditTwentyFourSevenRole: false,
        canRemoveTwentyFourSevenRole: false,
        comments: '',
        emailAddress: '',
        identifier: '',
        name: '',
        orderReceiptsRecipients: '',
        primarySoldToPartnerId: '',
        receiveOrderReceipts: false,
        storeId: '',
        stores: [],
        username: '',
        webMobileNumber: '',
        isSubscribedToMarketingMails: false,
        hasTwentyFourSevenAccess: false
    };

    emptyUserInformation: UserViewModel = { ...this.userInformation };

    get createUserFromAdminPayload(): CreateUserFromAdminRequest {
        let isDeveloper = this.userInformation.canEditDeveloperRole ? this.userInformation.isDeveloper : null;
        let isPunchOut = this.userInformation.canEditPunchOutRole ? this.userInformation.isDeveloper : null;
        let isEasySupplyAdmin = this.userInformation.canEditEasySupplyAdminRole ? this.userInformation.isEasySupplyAdmin : null;
        return {
            active: this.userInformation.activeStatus,
            isDeveloper: isDeveloper,
            isPunchOut: isPunchOut,
            isEasySupplyAdmin: isEasySupplyAdmin,
            carbonCopyRecipients: this.userInformation.orderReceiptsRecipients,
            comments: this.userInformation.comments,
            email: this.userInformation.emailAddress,
            mobileNumber: this.userInformation.webMobileNumber,
            name: this.userInformation.name,
            primarySoldToPartnerId: this.userInformation.primarySoldToPartnerId === undefined ? '' : this.userInformation.primarySoldToPartnerId,
            receiveOrderReceipts: this.userInformation.receiveOrderReceipts,
            storeId: this.userInformation.storeId,
            updateUserRelations: this.userInformation.accountRelations.map(relation => {
                return {
                    canPurchase: relation.canPurchase.hasRole,
                    canSeeAccountInformation: relation.canSeeAccountInformation.hasRole,
                    canSeeGrossPrices: relation.canSeeGrossPrices.hasRole,
                    canSeeNetPrices: relation.canSeeNetPrices.hasRole,
                    hasAccess: relation.hasAccess.hasRole,
                    isAdminForAccount: relation.accountAccess as unknown as string === this.$tr(this.$labels.Views.EditUser.Tabs.Access.Level.Admin),
                    soldToPartnerId: relation.soldToPartnerId
                } as UpdateUserRelations;
            }),
            username: this.userInformation.username
        };
    }

    $refs!: {
        accountAccessesList: Vue;
        myStoreSelect: Vue;
    };

    created() {
        this.getUserInfoForCreate();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async validateForm() {
        await this.$validator.validate();
        await this.$refs.myStoreSelect.$validator.validate();
        await this.$refs.accountAccessesList.$validator.validate();

        if (this.vvErrors.items.length === 0) {
            this.submitForm();
        } else {
            const firstErrorElement = this.$el.querySelector('[aria-invalid=true]')! as HTMLElement;
            firstErrorElement.focus();
            if (firstErrorElement.tagName.toLowerCase() === 'select') {
                scrollService.scrollToElement(this.$refs.myStoreSelect.$el as HTMLElement, -(headerHeight(true) * 2), 0);
            }
        }
    }

    async submitForm() {
        this.pending = true;
        try {
            await Api.user.createUserFromAdmin(this.createUserFromAdminPayload).then(() => {
                this.showSuccessIndicator();
                this.resetForm();
                tracking.trackSignup(SignUpTypesEnum.ExtraWebUser);
            });
        } catch (e) {
            // Server here handles redirect
            if (e instanceof RedirectException) {
                // ignore
            } else {
                throw e;
            }
        } finally {
            this.pending = false;
        }
    }

    async getUserInfoForCreate() {
        this.pending = true;

        try {
            await Api.user.getUserForCreate().then(emptyUser => {
                Object.assign(this.userInformation, emptyUser);
                Object.assign(this.emptyUserInformation, emptyUser);
            });
        } finally {
            this.pending = false;
        }
    }

    onUserStoreChange(store: UserStoreViewObject) {
        this.userInformation.storeId = store.id;
    }

    resetForm(): void {
        this.userInformation = { ...this.emptyUserInformation };
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    private vvErrors!: any;
}
