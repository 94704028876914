































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Constants from '@/project/config/constants';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import {
    LineItemViewObject
} from '@/types/serverContract';

@Component
export default class LineItemImage extends Vue {
    @Prop({
        type: Object
    }) lineItem!: LineItemViewObject;

    get lineItemUrl(): string {
        return isNotNullEmptyOrUndefined(this.lineItem.url) ? this.lineItem.url : '';
    }

    get getThumbnailImage(): string {
        return this.lineItem && this.lineItem.thumbnailImage ? this.lineItem.thumbnailImage : Constants.ImageNotFound;
    }
}
