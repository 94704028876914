



















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import Api from '@/project/http/api';
import { ProjectFolderProductCatalogListViewObject } from '@/types/serverContract';

@Component({
    components: { SpinnerOverlay }
})
export default class ProjectFolderProductCatalogs extends Vue {
    productCatalogs: ProjectFolderProductCatalogListViewObject[] | null = null;
    pending: boolean = false;

    created(): void {
        this.getProductCatalogs();
    }

    async getProductCatalogs(): Promise<void> {
        this.pending = true;
        try {
            await Api.productCatalog.productCatalogs().then(productCatalogs => {
                this.productCatalogs = productCatalogs.productCatalogs;
            });
        } finally {
            this.pending = false;
        }
    }
}
