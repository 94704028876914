




import Vue from 'vue';
import Component from 'vue-class-component';
import aviou from '@/core/aviou.service';
import { Prop } from 'vue-property-decorator';

@Component
export default class AviouCatalog extends Vue {
    @Prop({
        type: String,
        required: true
    }) catalog!: string;

    created() {
        aviou.initAviou();
        this.$nextTick(() => {
            const body = document.body;
            const script = document.createElement('script');
            script.src = this.catalog;

            // Append the script to the body element
            body.appendChild(script);
        });
    }
}
