

























































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
    AddFavoriteAccountRequest,
    FavoriteSalesAccountViewObject,
    HelpDeskSalesAccountsViewObject,
    HelpDeskUserViewObjectSlim
} from '@/types/serverContract';
import {
    HelpDeskAction,
    HelpDeskGetter
} from '@/store/helpDesk';
import { ActiveFields } from './HelpDesk.vue';

@Component
export default class HelpDeskAccountLine extends Vue {
    @Prop({ required: true, type: Object }) salesAccount!: HelpDeskSalesAccountsViewObject;
    @Prop({ default: false, type: Boolean }) seeAllUsersSelected!: boolean;
    @Prop({ type: Object }) activeFields!: ActiveFields;

    @HelpDeskGetter favoriteAccounts!: FavoriteSalesAccountViewObject[];
    @HelpDeskAction addAccountToFavoriteAccounts!: (AddFavoriteAccountRequestPayload: AddFavoriteAccountRequest) => Promise<void>;

    showAllAccounts: boolean = false;

    get userDisabled(): boolean {
        return this.salesAccount.isActive || !this.activeFields.User;
    }

    get selectAccountDisabled(): boolean {
        return !this.salesAccount.isActive || (!this.salesAccount.anyActiveUsers && this.seeAllUsersSelected);
    }

    get accountDescription(): string {
        return `${this.salesAccount.soldToPartnerId} ${this.salesAccount.soldToPartnerName}`;
    }

    get accountExistsInFavoriteAccounts(): boolean {
        return this.favoriteAccounts.findIndex(favoriteAccount => favoriteAccount.salesAccountId === this.salesAccount.salesAccountId) >= 0;
    }

    @Watch('salesAccount')
    onAccountChange(): void {
        this.showAllAccounts = false;
    }

    selectAccount(): void {
        this.$emit('accountSelected', this.salesAccount);
    }

    selectProfile(): void {
        this.$emit('profileSelected', this.salesAccount);
    }

    selectUser(user: HelpDeskUserViewObjectSlim): void {
        this.$emit('userSelected', this.salesAccount, user);
    }

    addToFavoriteAccounts(): void {
        const payload: AddFavoriteAccountRequest = {
            salesAccountId: this.salesAccount.salesAccountId
        };

        this.addAccountToFavoriteAccounts(payload);
    }
}
