

















import Vue from 'vue';
import Component from 'vue-class-component';
import urlHelper from './urlHelper.service';
import { Prop, Watch } from 'vue-property-decorator';
import { SortByViewObject } from '@/types/serverContract';
import SelectCtrl from '@/project/form/SelectCtrl.vue';

@Component
export default class SearchSorting extends Vue {
    @Prop({
        type: Array,
        required: true
    }) options!: SortByViewObject[];

    @Prop({
        default: '',
        type: String
    }) activeKey!: string;

    @Prop({
        type: String,
        required: false
    }) label!: string;

    activeSorting: SortByViewObject | null = null;

    @Watch('activeKey', {
        immediate: true
    })
    onActiveKeyChange(value: string) {
        this.activeSorting = this.options.find(o => o.id === value) || null;
    }

    onChange(event) {
        urlHelper.setSortBy(event.target.value);
    }

    getValue() {
        const selectCtrl = this.$refs.select as SelectCtrl;
        const sortKey = selectCtrl.value['id'].toString();
        return sortKey;
    }
}
