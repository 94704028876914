import AcceptDataPolicy from '@/project/dataPolicy/AcceptDataPolicy.vue';
import AccountGroupAdministration from '@/components/organisms/AccountGroupAdministration/AccountGroupAdministration.vue';
import AccountStatements from '@/project/bookkeeping/AccountStatements.vue';
import AccountsForAdministrationList from '@/project/user/AccountsForAdministrationList.vue';
import AnchorNavigation from '@/project/navigation/anchor-navigation/AnchorNavigation.vue';
import ArticleCard from '@/project/articles/ArticleCard.vue';
import ArticleHeader from '@/components/molecules/ArticleHeader/ArticleHeader.vue';
import ArticleImage from '@/components/molecules/ArticleImage/ArticleImage.vue';
import ArticlePromotionCarousel from '@/components/templates/ArticlePromotionCarousel/ArticlePromotionCarousel.vue';
import ArticlesOverview from '@/project/articles/ArticlesOverview.vue';
import AviouCatalog from '@/project/aviou/AviouCatalog.vue';
import BasketArchetype from '@/project/basket/BasketArchetype.vue';
import Banner from '@/components/organisms/Banner/Banner.vue';
import BrandPageWrapper from '@/project/brandPage/BrandPageWrapper.vue';
import ButtonCtrl from '@/components/atoms/ButtonCtrl/ButtonCtrl.vue';
import CategoryButtons from '@/components/molecules/CategoryButtons/CategoryButtons.vue';
import CategoryPrintCatalog from '@/project/category/CategoryPrintCatalog.vue';
import CertificateOrderDetails from '@/project/myServices/CertificateOrderDetails.vue';
import CertificateOrderHistory from '@/project/myServices/CertificateOrderHistory.vue';
import ChangePassword from '@/project/authentication/ChangePassword.vue';
import CheckoutForm from '@/project/checkout/CheckoutForm.vue';
import CheckboxCtrl from '../form/CheckboxCtrl.vue';
import CleanCheckboxCtrl from '../form/CleanCheckboxCtrl.vue';
import ContactPersons from '@/project/contact/ContactPersons.vue';
import ContentDisplay from '@/components/atoms/ContentDisplay/ContentDisplay.vue';
import CookieBotDeclaration from '@/project/CookieDeclaration/CookieBotDeclaration.vue';
import CreateAccount from '@/project/user/CreateAccount.vue';
import CreateAccountTimeline from '@/project/user/CreateAccountTimeline.vue';
import CreateUser from '@/project/user/CreateUser.vue';
import CreateUserEntryBox from '@/project/contentModules/CreateUserEntryBox.vue';
import CreateUserFromAdmin from '@/project/user/CreateUserFromAdmin.vue';
import CreateWebUserTimeline from '@/project/user/CreateWebUserTimeline.vue';
import Dashboard from '@/project/dashboard/Dashboard.vue';
import DeliveryNoteHistoryList from '@/project/bookkeeping/DeliveryNoteHistoryList.vue';
import DropdownInputCtrl from '@/project/form/inputCtrl/DropdownInputCtrl.vue';
import EasySupplyProcuredProductsList from '@/project/easySupply/procuredProducts/EasySupplyProcuredProductsList.vue';
import EasySupplyStockList from '@/project/easySupply/EasySupplyStockList.vue';
import EasySupplyStockListEdit from '@/project/easySupply/EasySupplyStockListEdit.vue';
import EasySupplyStocklistReorder from '@/project/easySupply/EasySupplyStocklistReorder.vue';
import Expandable from '@/project/shared/Expandable.vue';
import FavoriteOrder from '@/project/favoriteOrders/FavoriteOrder.vue';
import FavoriteOrdersList from '@/project/favoriteOrders/FavoriteOrdersList.vue';
import FooterContactSection from '@/project/navigation/footer/FooterContactSection.vue';
import Heading from '@/components/atoms/Heading/Heading.vue';
import HistoricalAccountStatements from '@/project/bookkeeping/historical/HistoricalAccountStatements.vue';
import HistoricalDeliveryNotes from '@/project/bookkeeping/historical/HistoricalDeliveryNotes.vue';
import HistoricalInvoices from '@/project/bookkeeping/historical/HistoricalInvoices.vue';
import HistoricalOrderHistory from '@/project/bookkeeping/historical/HistoricalOrderHistory.vue';
import ImageTextButtonModule from '@/project/shared/modules/ImageTextButtonModule.vue';
import ImageWithCurtainOverlay from '@/components/molecules/ImageWithCurtainOverlay/ImageWithCurtainOverlay.vue';
import ImageWithFallback from '@/project/shared/ImageWithFallback.vue';
import InvoiceHistoryList from '@/project/bookkeeping/InvoiceHistoryList.vue';
import LatestOrders from '@/project/bookkeeping/LatestOrders.vue';
import LeftNavigation from '@/project/navigation/left-navigation/LeftNavigation.vue';
import LineInputCtrl from '@/project/form/inputCtrl/LineInputCtrl.vue';
import LoginPage from '@/project/authentication/LoginPage.vue';
import MarketingBanner from '@/project/marketing/MarketingBanner.vue';
import MultilineInputCtrl from '@/project/form/inputCtrl/MultilineInputCtrl.vue';
import MyServicesContentFrontPage from '@/project/myServices/MyServicesContentFrontPage.vue';
import MyServicesFunctionalFrontPage from '@/project/myServices/MyServicesFunctionalFrontPage.vue';
import MyServicesQuotations from '@/project/myServices/MyServicesQuotations.vue';
import MyServicesQuotationsDetails from '@/project/myServices/MyServicesQuotationsDetails.vue';
import NavItem from '@/project/navigation/left-navigation/NavItem.vue';
import OrderHistoryDetails from '@/project/bookkeeping/OrderHistoryDetails.vue';
import OrderHistoryList from '@/project/bookkeeping/OrderHistoryList.vue';
import OrderReceipt from '@/project/receipt/OrderReceipt.vue';
import PageHeader from '@/project/navigation/page-header/PageHeader.vue';
import ProductAlternativeProductsCarousel from '@/project/products/carousels/ProductAlternativeProductsCarousel.vue';
import ProductCarousel from '@/project/products/carousels/ProductCarousel.vue';
import ProductCatalogDetails from '@/project/myServices/projectFolder/ProductCatalogDetails.vue';
import ProductDetails from '@/project/products/ProductDetails.vue';
import ProductDetailsBom from '@/project/products/ProductDetailsBom.vue';
import ProductDetailsBomDescription from '@/project/products/ProductDetailsBomDescription.vue';
import ProductDetailsTabs from '@/project/products/ProductDetailsTabs.vue';
import ProductListGrid from '@/project/products/ProductListGrid.vue';
import ProductNewsTeaser from '@/project/teasers/productNewsTeaser.vue';
import ProductRelatedProductsCarousel from '@/project/products/carousels/ProductRelatedProductsCarousel.vue';
import ProductSparePartsCarousel from '@/project/products/carousels/ProductSparePartsCarousel.vue';
import ProjectAgreements from '@/project/myServices/projectFolder/ProjectAgreements.vue';
import ProjectFolderLinks from '@/project/myServices/projectFolder/ProjectFolderLinks.vue';
import ProjectFolderOffers from '@/project/myServices/projectFolder/ProjectFolderOffers.vue';
import ProjectFolderOrderHistoryQa from '@/project/myServices/ProjectFolderOrderHistoryQa.vue';
import ProjectFolderProductCatalogs from '@/project/myServices/projectFolder/ProjectFolderProductCatalogs.vue';
import ProjectOrderQuotationDetails from '@/project/myServices/projectFolder/ProjectOrderQuotationDetails.vue';
import ProjectOrderQuotationList from '@/project/myServices/projectFolder/ProjectOrderQuotationList.vue';
import RadioButtonCtrl from '../form/RadioButtonCtrl.vue';
import RaptorBrands from '@/project/recommendations/RaptorBrands.vue';
import RaptorCategories from '@/project/recommendations/RaptorCategories.vue';
import RaptorContentView from '@/project/recommendations/RaptorContentView.vue';
import RaptorCategoryView from '@/project/recommendations/RaptorCategoryView.vue';
import RaptorProductsForBasket from '@/project/recommendations/RaptorProductsForBasket.vue';
import RaptorProductsView from '@/project/recommendations/RaptorProductsView.vue';
import RichtextWithImage from '@/project/contentModules/RichtextWithImage.vue';
import ScannerData from '@/project/myServices/Scanner/ScannerData.vue';
import ScannerImport from '@/project/myServices/Scanner/ScannerImport.vue';
import ScannerListDetails from '@/project/myServices/Scanner/ScannerListDetails.vue';
import ScannerLists from '@/project/myServices/Scanner/ScannerLists.vue';
import SearchResult from '@/project/search/SearchResult.vue';
import SectionWrapper from '@/components/templates/SectionWrapper/SectionWrapper.vue';
import SelectCtrl from '@/project/form/SelectCtrl.vue';
import SecurityDatasheetsList from '@/project/myServices/SecurityDatasheetsList.vue';
import ShopEventsTeaser from '@/project/teasers/shopEventsTeaser.vue';
import SimpleFavoriteOrdersList from '@/project/favoriteOrders/SimpleFavoriteOrdersList.vue';
import SimpleTop100Products from '@/project/top100Products/SimpleTop100Products.vue';
import StoreDetail from '@/project/store/StoreDetail.vue';
import StoreList from '@/project/store/StoreList.vue';
import TextAreaCtrl from '../form/TextAreaCtrl.vue';
import TextInput from '../form/TextInput.vue';
import ToggleCtrl from '../form/ToggleCtrl.vue';
import ToggleShowNetPrices from '@/project/authentication/ToggleShowNetPrices.vue';
import Top100Products from '@/project/top100Products/Top100Products.vue';
import TrackAndTraceDetails from '@/project/myServices/TrackAndTraceDetails.vue';
import TrackAndTraceList from '@/project/myServices/TrackAndTraceList.vue';
import UpdateMyUser from '@/project/user/UpdateMyUser.vue';
import UpdateUserFromAdmin from '@/project/user/UpdateUserFromAdmin.vue';
import UsersAdministration from '@/project/user/UsersAdministration.vue';
import USPComponentCarousel from '@/components/templates/USPComponentCarousel/USPComponentCarousel.vue';
import VaColebrookWhite from '@/project/myServices/VA/VaColebrookWhite.vue';
import VaLift from '@/project/myServices/VA/VaLift.vue';
import VaPipeVolume from '@/project/myServices/VA/VaPipeVolume.vue';
import VaPromilleCalc from '@/project/myServices/VA/VaPromilleCalc.vue';
import VaRainWaterCassettes from '@/project/myServices/VA/VaRainWaterCassettes.vue';
import VaWaterbreak from '@/project/myServices/VA/VaWaterbreak.vue';
import Vue from 'vue';
import WebOffers from '@/project/webOffers/WebOffers.vue';
import CTAButton from '@/components/atoms/CTAButton/CTAButton.vue';

// Global components (so they can be used in CSHTML files as SPA content)
Vue.component('AcceptDataPolicy', AcceptDataPolicy);
Vue.component('AccountGroupAdministration', AccountGroupAdministration);
Vue.component('AccountStatements', AccountStatements);
Vue.component('AccountsForAdministrationList', AccountsForAdministrationList);
Vue.component('AnchorNavigation', AnchorNavigation);
Vue.component('ArticleCard', ArticleCard);
Vue.component('ArticleHeader', ArticleHeader);
Vue.component('ArticleImage', ArticleImage);
Vue.component('ArticlePromotionCarousel', ArticlePromotionCarousel);
Vue.component('ArticlesOverview', ArticlesOverview);
Vue.component('AviouCatalog', AviouCatalog);
Vue.component('BasketArchetype', BasketArchetype);
Vue.component('Banner', Banner);
Vue.component('BrandPageWrapper', BrandPageWrapper);
Vue.component('ButtonCtrl', ButtonCtrl);
Vue.component('CategoryButtons', CategoryButtons);
Vue.component('CategoryPrintCatalog', CategoryPrintCatalog);
Vue.component('CertificateOrderDetails', CertificateOrderDetails);
Vue.component('CertificateOrderHistory', CertificateOrderHistory);
Vue.component('ChangePassword', ChangePassword);
Vue.component('CheckboxCtrl', CheckboxCtrl);
Vue.component('CheckoutForm', CheckoutForm);
Vue.component('CleanCheckboxCtrl', CleanCheckboxCtrl);
Vue.component('ContactPersons', ContactPersons);
Vue.component('CTAButton', CTAButton);
Vue.component('ContentDisplay', ContentDisplay);
Vue.component('CookieBotDeclaration', CookieBotDeclaration);
Vue.component('CreateAccount', CreateAccount);
Vue.component('CreateAccountTimeline', CreateAccountTimeline);
Vue.component('CreateUser', CreateUser);
Vue.component('CreateUserEntryBox', CreateUserEntryBox);
Vue.component('CreateUserFromAdmin', CreateUserFromAdmin);
Vue.component('CreateWebUserTimeline', CreateWebUserTimeline);
Vue.component('Dashboard', Dashboard);
Vue.component('DeliveryNoteHistoryList', DeliveryNoteHistoryList);
Vue.component('DropdownInputCtrl', DropdownInputCtrl);
Vue.component('EasySupplyProcuredProductsList', EasySupplyProcuredProductsList);
Vue.component('EasySupplyStockList', EasySupplyStockList);
Vue.component('EasySupplyStockListEdit', EasySupplyStockListEdit);
Vue.component('EasySupplyStocklistReorder', EasySupplyStocklistReorder);
Vue.component('Expandable', Expandable);
Vue.component('FavoriteOrder', FavoriteOrder);
Vue.component('FavoriteOrdersList', FavoriteOrdersList);
Vue.component('FooterContactSection', FooterContactSection);
Vue.component('Heading', Heading);
Vue.component('HistoricalAccountStatements', HistoricalAccountStatements);
Vue.component('HistoricalDeliveryNotes', HistoricalDeliveryNotes);
Vue.component('HistoricalInvoices', HistoricalInvoices);
Vue.component('HistoricalOrderHistory', HistoricalOrderHistory);
Vue.component('ImageTextButtonModule', ImageTextButtonModule);
Vue.component('ImageWithCurtainOverlay', ImageWithCurtainOverlay);
Vue.component('ImageWithFallback', ImageWithFallback);
Vue.component('InvoiceHistoryList', InvoiceHistoryList);
Vue.component('LatestOrders', LatestOrders);
Vue.component('LeftNavigation', LeftNavigation);
Vue.component('LineInputCtrl', LineInputCtrl);
Vue.component('LoginPage', LoginPage);
Vue.component('MarketingBanner', MarketingBanner);
Vue.component('MultilineInputCtrl', MultilineInputCtrl);
Vue.component('MyServicesContentFrontPage', MyServicesContentFrontPage);
Vue.component('MyServicesFunctionalFrontPage', MyServicesFunctionalFrontPage);
Vue.component('MyServicesQuotations', MyServicesQuotations);
Vue.component('MyServicesQuotationsDetails', MyServicesQuotationsDetails);
Vue.component('NavItem', NavItem);
Vue.component('OrderHistoryDetails', OrderHistoryDetails);
Vue.component('OrderHistoryList', OrderHistoryList);
Vue.component('OrderReceipt', OrderReceipt);
Vue.component('PageHeader', PageHeader);
Vue.component('ProductAlternativeProductsCarousel', ProductAlternativeProductsCarousel);
Vue.component('ProductCarousel', ProductCarousel);
Vue.component('ProductCatalogDetails', ProductCatalogDetails);
Vue.component('ProductDetails', ProductDetails);
Vue.component('ProductDetailsBom', ProductDetailsBom);
Vue.component('ProductDetailsBomDescription', ProductDetailsBomDescription);
Vue.component('ProductDetailsTabs', ProductDetailsTabs);
Vue.component('ProductListGrid', ProductListGrid);
Vue.component('ProductNewsTeaser', ProductNewsTeaser);
Vue.component('ProductRelatedProductsCarousel', ProductRelatedProductsCarousel);
Vue.component('ProductSparePartsCarousel', ProductSparePartsCarousel);
Vue.component('ProjectAgreements', ProjectAgreements);
Vue.component('ProjectFolderLinks', ProjectFolderLinks);
Vue.component('ProjectFolderOffers', ProjectFolderOffers);
Vue.component('ProjectFolderOrderHistoryQa', ProjectFolderOrderHistoryQa);
Vue.component('ProjectFolderProductCatalogs', ProjectFolderProductCatalogs);
Vue.component('ProjectOrderQuotationDetails', ProjectOrderQuotationDetails);
Vue.component('ProjectOrderQuotationList', ProjectOrderQuotationList);
Vue.component('RadioButtonCtrl', RadioButtonCtrl);
Vue.component('RaptorBrands', RaptorBrands);
Vue.component('RaptorCategories', RaptorCategories);
Vue.component('RaptorCategoryView', RaptorCategoryView);
Vue.component('RaptorContentView', RaptorContentView);
Vue.component('RaptorProductsForBasket', RaptorProductsForBasket);
Vue.component('RaptorProductsView', RaptorProductsView);
Vue.component('RichtextWithImage', RichtextWithImage);
Vue.component('ScannerData', ScannerData);
Vue.component('ScannerImport', ScannerImport);
Vue.component('ScannerListDetails', ScannerListDetails);
Vue.component('ScannerLists', ScannerLists);
Vue.component('SearchResult', SearchResult);
Vue.component('SectionWrapper', SectionWrapper);
Vue.component('SelectCtrl', SelectCtrl);
Vue.component('SecurityDatasheetsList', SecurityDatasheetsList);
Vue.component('ShopEventsTeaser', ShopEventsTeaser);
Vue.component('SimpleFavoriteOrdersList', SimpleFavoriteOrdersList);
Vue.component('SimpleTop100Products', SimpleTop100Products);
Vue.component('StoreDetail', StoreDetail);
Vue.component('StoreList', StoreList);
Vue.component('TextAreaCtrl', TextAreaCtrl);
Vue.component('TextInput', TextInput);
Vue.component('ToggleCtrl', ToggleCtrl);
Vue.component('ToggleShowNetPrices', ToggleShowNetPrices);
Vue.component('Top100Products', Top100Products);
Vue.component('TrackAndTraceDetails', TrackAndTraceDetails);
Vue.component('TrackAndTraceList', TrackAndTraceList);
Vue.component('UpdateMyUser', UpdateMyUser);
Vue.component('UpdateUserFromAdmin', UpdateUserFromAdmin);
Vue.component('UsersAdministration', UsersAdministration);
Vue.component('USPComponentCarousel', USPComponentCarousel);
Vue.component('VaColebrookWhite', VaColebrookWhite);
Vue.component('VaLift', VaLift);
Vue.component('VaPipeVolume', VaPipeVolume);
Vue.component('VaPromilleCalc', VaPromilleCalc);
Vue.component('VaRainWaterCassettes', VaRainWaterCassettes);
Vue.component('VaWaterbreak', VaWaterbreak);
Vue.component('WebOffers', WebOffers);
