import httpService from '@/core/http/http.service';
import {
    DeleteProductCatalogLineRequest,
    GetProjectFolderProductCatalogRequest,
    ProjectFolderProductCatalogDetailViewModel,
    UpdateProductCatalogLineItemRequest,
    ProjectFolderLinkListViewModel,
    ProjectFolderProductCatalogListViewModel
} from '@/types/serverContract';

export default class ProductCatalogController {
    public async productCatalogs(): Promise<ProjectFolderProductCatalogListViewModel> {
        return httpService.get<ProjectFolderProductCatalogListViewModel>('productCatalogApi/productCatalogs');
    }

    public async productCatalogLinks(): Promise<ProjectFolderLinkListViewModel> {
        return httpService.get<ProjectFolderLinkListViewModel>('productCatalogApi/productCatalogLinks');
    }

    public async productCatalogDetails(payload: GetProjectFolderProductCatalogRequest): Promise<ProjectFolderProductCatalogDetailViewModel> {
        return httpService.get<ProjectFolderProductCatalogDetailViewModel>('productCatalogApi/productCatalog', payload);
    }

    public async deleteLineItem(payload: DeleteProductCatalogLineRequest): Promise<ProjectFolderProductCatalogDetailViewModel> {
        return httpService.post<ProjectFolderProductCatalogDetailViewModel>('productCatalogApi/deleteLineItem', payload);
    }

    public async updateLineItem(payload: UpdateProductCatalogLineItemRequest): Promise<ProjectFolderProductCatalogDetailViewModel> {
        return httpService.post<ProjectFolderProductCatalogDetailViewModel>('productCatalogApi/lineItem', payload);
    }
}
