// Depends on JSNLog which is probably loaded by serverside scripting.

import Vue from 'vue';
import StackTrace from 'stacktrace-js';
import logging from './logging.service';

function init() {
    function logStackframes(stackframes, errorObj, errorMsg, url?, lineNumber?, column?) {
        var stringifiedStack = stackframes
            .map(sf => {
                return sf.toString();
            })
            .join('\n');

        logging.exception(
            {
                msg: 'Exception! ' + stringifiedStack,
                errorMsg: errorMsg,
                url: url,
                lineNumber: lineNumber,
                column: column
            },
            errorObj
        );
    }

    function isProdMode() {
        return process.env.NODE_ENV === 'production';
    }

    Vue.config.errorHandler = function(err, vm, info) {
        if (isProdMode()) {
            StackTrace.fromError(err)
                .then(stackframes => logStackframes(stackframes, err, info))
                .catch(logging.error);
        } else {
            logging.error(err);
        }
    };

    window.onerror = function(errorMsg, url, lineNumber, column, errorObj) {
        if (isProdMode() && errorObj) {
            StackTrace.fromError(errorObj)
                .then(stackframes => logStackframes(stackframes, errorObj, errorMsg, url, lineNumber, column))
                .catch(logging.error);
            return true;
        } else {
            logging.error(errorObj);
        }
    };

    window.onunhandledrejection = function(event) {
        if (event && event.reason) {
            if (isProdMode()) {
                StackTrace.fromError(event.reason)
                    .then(stackframes => logStackframes(stackframes, event.reason.message, event.reason))
                    .catch(() => logging.error(event.reason));
            } else {
                logging.error(event);
            }
        }
    };
}

init();
