































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ProductTile from '@/project/products/ProductTile.vue';
import { ProductTileViewObject, RelatedProductsViewObject } from '@/types/serverContract';
import Api from '@/project/http/api';

@Component({
    components: {
        ProductTile
    }
})
export default class ProductRelatedProducts extends Vue {
    @Prop({
        required: true,
        type: Object
    }) relatedProductGroup!: RelatedProductsViewObject;

    @Prop({
        default: false,
        type: Boolean
    }) showHeader!: boolean;

    pending: boolean = false;
    relatedProducts: ProductTileViewObject[] | null = null;

    created() {
        this.getRelatedProducts();
    }

    @Watch('relatedProductGroup')
    onRelatedProductGroupChange(): void {
        this.getRelatedProducts();
    }

    async relatedProductsFromId() {
        if (!this.relatedProductGroup.productsIds) {
            return [];
        }

        this.pending = true;

        let products: ProductTileViewObject[] = [];
        try {
            products = await Api.products.getRelatedProducts(this.relatedProductGroup.productsIds);
        } finally {
            this.pending = false;
        }
        return products;
    }

    getRelatedProducts(): void {
        if (this.relatedProductGroup.productsIds.length >= 1) {
            this.relatedProductsFromId().then(products => {
                this.relatedProducts = products;
            });
        }
    }
}
