





















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import { FlexBoxGetter } from '@/store/flexBox';
import {
    LatestProductsRequest,
    ProductTileViewObject
} from '@/types/serverContract';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import Api from '@/project/http/api';
import SimpleTop100ProductTile from '@/project/top100Products/SimpleTop100ProductTile.vue';

@Component({
    components: {
        SpinnerOverlay,
        SimpleTop100ProductTile
    }
})
export default class SimpleTop100Products extends Vue {
    @Prop({
        type: Number,
        default: 6
    }) pageSize!: number;

    @AppGetter isLoggedIn!: boolean;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    pending: boolean = false;
    skeletonList: number[] = [];
    topProducts: ProductTileViewObject[] | null = null;

    created() {
        this.getTopProducts().then(() => {
            this.fillSkeletonList();
        });
    }

    async getTopProducts(): Promise<void> {
        this.pending = true;
        if (this.isLoggedIn) {
            try {
                const payload: LatestProductsRequest = {
                    maxElements: 6
                };

                await Api.products.LatestProducts(payload).then(LatestProductsResult => {
                    if (LatestProductsResult && LatestProductsResult.products) {
                        this.topProducts = LatestProductsResult.products.slice(0, this.pageSize);
                    }
                });
            } finally {
                this.pending = false;
            }
        }
    }

    fillSkeletonList() {
        if (this.topProducts) {
            let amountToCreate = this.pageSize - this.topProducts.length;

            for (let index = 0; index < amountToCreate; index++) {
                this.skeletonList.push(index);
            }
        }
    }
}
