





























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { ProductSecurityDatasheetsListViewModel } from '@/types/serverContract';
import SecurityDatasheetsListItem from '@/project/myServices/SecurityDatasheetsListItem.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: { SpinnerOverlay, SecurityDatasheetsListItem }
})
export default class SecurityDatasheetsList extends Vue {
    pending: boolean = false;
    securityDatasheets: ProductSecurityDatasheetsListViewModel | null = null;

    get hasDatasheets(): boolean {
        return this.securityDatasheets !== null && this.securityDatasheets.productDatasheets.length > 0;
    }

    created() {
        this.getSecurityDatasheets();
    }

    async getSecurityDatasheets(): Promise<void> {
        this.pending = true;
        try {
            this.securityDatasheets = await Api.securityDatasheets.getSecurityDatasheets();
        } finally {
            this.pending = false;
        }
    }
}
