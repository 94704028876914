








































import Vue from 'vue';
import Component from 'vue-class-component';
import { CheckoutGetter, CheckoutAction } from '@/store/checkout';
import { BasketGetter } from '@/store/basket';
import { Watch } from 'vue-property-decorator';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import DeliveryComment from '@/project/checkout/DeliveryComment.vue';
import DeliveryMethods from '@/project/checkout/DeliveryMethods.vue';
import DeliveryAddress from '@/project/checkout/DeliveryAddress.vue';
import DeliveryDate from '@/project/checkout/DeliveryDate.vue';
import DeliveryApproveOrder from '@/project/checkout/DeliveryApproveOrder.vue';
import {
    BasketViewModel,
    SubmitOrderRequest,
    OrderViewModel
} from '@/types/serverContract';
import DeliverySmsService from '@/project/checkout/DeliverySmsService.vue';
import DeliveryDigitalCommunication from '@/project/checkout/DeliveryDigitalCommunication.vue';
import raptorTracking from '@/core/tracking/raptorTracking.service';
import { FlexBoxGetter } from '@/store/flexBox';

@Component({
    components: {
        DeliverySmsService,
        DeliveryDigitalCommunication,
        DeliveryApproveOrder,
        DeliveryDate,
        DeliveryAddress,
        DeliveryMethods,
        DeliveryComment,
        SpinnerOverlay
    }
})
export default class CheckoutForm extends Vue {
    @BasketGetter basket!: BasketViewModel;
    @CheckoutGetter order!: OrderViewModel;
    @CheckoutGetter isOrderSubmitting!: boolean;

    @CheckoutAction setOrder!: (payload: OrderViewModel | {}) => void;
    @CheckoutAction setOrderSubmitting!: (payload: boolean) => void;
    @CheckoutAction submitOrder!: (payload: SubmitOrderRequest) => Promise<void>;
    @CheckoutAction setOrderApproved!: (payload: boolean) => boolean;
    @CheckoutAction setCommentValid!: (payload: boolean) => boolean;

    @FlexBoxGetter isInFlexBoxContext!: boolean;

    isStepLoading: boolean = false;
    smsServicePhoneNumber: string = '';
    digitalCommunicationEmail: string | null = null;
    digitalCommunicationPhone: string | null = null;

    get showDeliverySmsService(): boolean {
        return this.basket && this.basket.supportsSmsService;
    }

    get showDeliveryDigitalCommunication(): boolean {
        return this.basket && this.basket.deliveryAddress && this.basket.deliveryAddress.digitalCommunication.isEnabled;
    }

    created(): void {
        this.setInitialBasketData();
        this.setInitialDigitalCommunications();
    }

    @Watch('basket')
    setInitialBasketData(): void {
        if (this.isInFlexBoxContext) {
            this.setOrder({ ...this.basket, rawDeliveryDate: '', deliveryDate: '' });
        }
    }

    @Watch('basket.deliveryAddress.digitalCommunication.isEnabled')
    setInitialDigitalCommunications(): void {
        if (this.basket && this.isStepLoading && this.basket.deliveryAddress && this.basket.deliveryAddress.digitalCommunication.isEnabled) {
            if (this.digitalCommunicationEmail === null) {
                this.digitalCommunicationEmail = this.basket.deliveryAddress.digitalCommunication.email;
            }
            if (this.digitalCommunicationPhone === null) {
                this.digitalCommunicationPhone = this.basket.deliveryAddress.digitalCommunication.phone;
            }
        }
    }

    @Watch('basket.supportsSmsService')
    setInitialSmsServicePhoneNumber(): void {
        if (this.smsServicePhoneNumber === null && this.basket.supportsSmsService) {
            this.smsServicePhoneNumber = this.basket.smsServiceNumber;
        }
    }

    @Watch('isOrderSubmitting')
    submitCheckout() {
        if (this.isOrderSubmitting) {
            this.validateForm().then(isFormValid => {
                if (isFormValid) {
                    let payload: SubmitOrderRequest = {
                        smsServiceNumber: this.basket.supportsSmsService && this.smsServicePhoneNumber !== null ? this.smsServicePhoneNumber : '',
                        digitalCommunicationEmail: this.digitalCommunicationEmail,
                        digitalCommunicationPhone: this.digitalCommunicationPhone,
                        raptorSessionId: raptorTracking.raptorSessionId
                    };
                    this.setOrderApproved(false);
                    this.setOrderSubmitting(false);
                    this.submitOrder(payload);
                } else {
                    (this.$el.querySelector('.c-text-input--error') as HTMLInputElement)!.focus();
                    this.setOrderApproved(false);
                    this.setOrderSubmitting(false);
                }
            });
        }
    }

    isLoading(isStepLoading: boolean): void {
        this.isStepLoading = isStepLoading;
    }

    async validateForm() {
        return Promise.all(this.$children.map(comp => comp.$validator.validate())).then(v => v.every(ve => ve === true));
    }

    validateComment(isValid): void {
        this.setCommentValid(isValid);
    }
}
