











import { Component, Prop } from 'vue-property-decorator';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import ImageTextButtonModuleInnerContent from '@/project/shared/modules/ImageTextButtonModuleInnerContent.vue';
import ImageTextButtonModuleVerticalSplit from '@/project/shared/modules/ImageTextButtonModuleVerticalSplit.vue';
import { Mixins } from 'vue-mixin-decorator/lib/mixins';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoints.mixin';
import imageService from '@/core/image.service';
import ContentBanner from '@/components/molecules/Banner/ContentBanner.vue';
import HeroBanner from '@/components/molecules/Banner/HeroBanner.vue';
import VideoBanner from '@/components/molecules/Banner/VideoBanner.vue';
import LoginBanner from '@/components/molecules/Banner/LoginBanner.vue';
import { BANNER_TYPE, PROMO_TRACKING_EVENT } from '../../../core/enums/enums';
import { LinkViewModel } from '@/types/serverContract';
import { IPromoActionField } from '@/core/tracking/promotionTracking.g4-structure';
import promotionTrackingService from '@/core/tracking/promotionTracking.service';

@Component({
    components: {
        ImageTextButtonModuleInnerContent,
        ImageTextButtonModuleVerticalSplit,
        ContentBanner,
        HeroBanner,
        LoginBanner,
        VideoBanner
    }
})
export default class Banner extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @Prop({ default: BANNER_TYPE.HeroBanner, type: String }) bannerType!: string;

    @Prop({ default: '', type: String }) preheader!: string;
    @Prop({ default: '', type: String }) header!: string;
    @Prop({ default: '', type: String }) subheader!: string;

    @Prop({ default: '', type: String }) textColor!: string;
    @Prop({ default: '1269B0', type: String }) textBackgroundColor!: string;
    @Prop({ default: false, type: Boolean }) textAlignmentRight!: boolean;

    @Prop({ type: Object }) ctaButton!: LinkViewModel;
    @Prop({ default: true, type: Boolean }) showCtaButton!: boolean;

    @Prop({ default: '', type: String }) buttonColor!: string;

    @Prop({ default: '', type: String }) image!: string;
    @Prop({ type: Object }) imageLink!: LinkViewModel;

    @Prop({ default: '', type: String }) audience!: string;

    @Prop({ default: '', type: String }) videoUrl!: string;
    @Prop({ default: '', type: Boolean }) videoAutoplay!: boolean;
    @Prop({ default: false, type: Boolean }) fullWidth!: boolean;
    @Prop({ default: false, type: Boolean }) disableOverlay!: boolean;

    whiteColor: string = '#FFFFFF';
    defaultButtonTextColor: string = '#0C365C';

    get bannerProps() {
        let commonProps = {
            preheader: this.preheader,
            header: this.header,
            subheader: this.subheader,
            'text-background-color': this.textBackgroundColor,
            'cta-button': this.ctaButton,
            'show-cta-button': this.showCtaButton,
            image: this.image,
            'image-link': this.imageLink
        };

        switch (this.bannerType) {
            case this.$enums.BANNER_TYPE.ContentBanner:
                return {
                    ...commonProps,
                    'text-color': this.textColor,
                    'text-alignment-right': this.textAlignmentRight
                };
            case this.$enums.BANNER_TYPE.HeroBanner:
                return {
                    ...commonProps,
                    'text-color': this.textColor,
                    'text-alignment-right': this.textAlignmentRight,
                    'full-width': this.fullWidth,
                    'disable-overlay': this.disableOverlay
                };
            case this.$enums.BANNER_TYPE.VideoBanner:
                return {
                    ...commonProps,
                    'video-url': this.videoUrl,
                    'video-autoplay': this.videoAutoplay,
                    'text-alignment-right': this.textAlignmentRight
                };
            default:
                // For 'loginBanner' just return the common props
                return commonProps;
        }
    }

    get useBackgroundColorForContainer(): boolean {
        return isNotNullEmptyOrUndefined(this.textBackgroundColor) && !isNotNullEmptyOrUndefined(this.image);
    }

    get imageClasses(): string {
        return 'bg-center bg-cover bg-no-repeat';
    }

    get buttonTextColor(): string {
        if ('#' + this.buttonColor === this.whiteColor) {
            return this.defaultButtonTextColor;
        }
        return this.whiteColor;
    }

    get styleForWrapper(): Partial<CSSStyleDeclaration> | null {
        if (this.useBackgroundColorForContainer) {
            return {
                backgroundColor: `#${this.textBackgroundColor}`
            };
        }
        return { backgroundImage: `url('${this.getWebpCompatibleOptimizedSource}')` };
    }

    get getWebpCompatibleOptimizedSource(): string {
        return imageService.getWebpCompatibleOptimizedSource(this.image);
    }

    trackPromotionView(): void {
        const id = `${this.audience}${this.header}|frontpage|${this.image}`;
        const name = `${this.header}_${this.image}`;
        const creative = this.getCreativeName(this.ctaButton);

        const actionField: IPromoActionField = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_id: id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_name: name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            creative_name: creative
        };

        promotionTrackingService.TrackPromotion(
            PROMO_TRACKING_EVENT.PromotionImpression,
            actionField
        );
    }

    get trackingActionField(): IPromoActionField {
        const id = `${this.audience}${this.header}|frontpage|${this.image}`;
        const name = `${this.header}_${this.image}`;
        const creative = this.getCreativeName(this.ctaButton);

        return {
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_id: id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_name: name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            creative_name: creative
        };
    }

    getCreativeName(ctaButton: LinkViewModel) {
        if (!ctaButton || !ctaButton.name || ctaButton.name === '') {
            return undefined;
        }
        return ctaButton.name;
    }
}
