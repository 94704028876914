import { router } from '@/router';

type queryKey = 'orderNumber' | 'caseNumber' | 'toDate' | 'fromDate' | 'productIdType' | 'productId' | 'reference' | 'requisitionNumber' | 'invoiceNumber' | 'invoiceIdType';

const queryKeys = {
    orderNumber: 'orderNumber',
    caseNumber: 'caseNumber',
    toDate: 'toDate',
    fromDate: 'fromDate',
    productIdType: 'productIdType',
    productId: 'productId',
    reference: 'reference',
    requisitionNumber: 'requisitionNumber',
    invoiceNumber: 'invoiceNumber',
    invoiceIdType: 'invoiceIdType',
    page: 'page',
    orderId: 'id'
};

function getOrderId(): string {
    return router.currentRoute.query[queryKeys.orderId] as string;
}

function getPageNumber(): number {
    const value = parseInt(router.currentRoute.query[queryKeys.page] as string, 10);
    return !value || Number.isNaN(value) ? 1 : value;
}

function getSearchQuery(queryKey: queryKey): string {
    return router.currentRoute.query[queryKeys[queryKey]] as string || '';
}

function setSearchQuery(payload): void {
    const cleanQuery = Object.keys(payload).reduce((obj, key) => {
        if (payload[key]) {
            obj[key] = payload[key];
        }
        return obj;
    }, {});
    let query = { ...router.currentRoute.query };

    query = cleanQuery;

    router.replace({ query });
}

export default {
    getOrderId,
    getPageNumber,
    getSearchQuery,
    setSearchQuery
};
