



















































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { SynonymViewObject } from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import HelpDeskSynonymEditForm from '@/project/helpDesk/HelpDeskSynonymEditForm.vue';

@Component({
    components: {
        SpinnerOverlay,
        HelpDeskSynonymEditForm
    }
})
export default class HelpDeskSynonymList extends Vue {
    pending: boolean = false;
    synonyms: SynonymViewObject[] | null = null;
    showSynonymEditForm: boolean = false;
    synonymIdToEdit: any = null;

    created(): void {
        this.getSynonyms();
    }

    async getSynonyms(): Promise<void> {
        this.pending = true;
        try {
            await Api.searchManagement.getAllSynonyms().then(res => {
                this.synonyms = res.synonyms;
            });
        } finally {
            this.pending = false;
        }
    }

    async deleteSynonym(id: number): Promise<void> {
        this.pending = true;
        try {
            await Api.searchManagement.deleteSynonym(id).then(() => {
                this.getSynonyms();
            });
        } finally {
            this.pending = false;
        }
    }

    openSynonymEditForm(id: number) {
        this.showSynonymEditForm = true;
        this.synonymIdToEdit = id;
    }

    closeSynonymEditForm() {
        this.showSynonymEditForm = false;
        this.synonymIdToEdit = null;
    }

    confirmDeleteSynonym(synonym: SynonymViewObject) {
        let isConfirmed: boolean = window.confirm(this.confirmationMessage(synonym.synonym));
        if (isConfirmed) {
            this.deleteSynonym(synonym.id);
        }
    }

    confirmationMessage(synonym: string): string {
        return this.$tr(this.$labels.Views.Helpdesk.EditSearch.EditSynonyms.ConfirmDelete, synonym);
    }
}
