

























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { sortBy, take } from 'lodash-es';
import { FacetGroupViewObject, FacetValueViewObject } from '@/types/serverContract';
import urlHelper from './urlHelper.service';
import SearchFacetGroupFacetRange from '@/project/search/SearchFacetGroupFacetRange.vue';

@Component({
    components: {
        SearchFacetGroupFacetRange
    }
})
export default class SearchFacetGroup extends Vue {
    @Prop({
        required: true,
        type: Object
    }) facetGroup!: FacetGroupViewObject;

    @Prop({
        default: 0,
        type: Number
    }) totalResultCount!: number;

    facetFilterMinimum: number = 20; // No. of facets before user can filter
    collapseLimit: number = 7; // Max. no of facets before we collapse
    facetsVisibleWhenCollapsed: number = 4;
    filter: string = '';
    isExpanded: boolean = false;
    hasRangeValue: boolean = false;

    get shouldShowFilter(): boolean {
        return this.facetGroup.values.length >= this.facetFilterMinimum && !this.facetGroup.range;
    }

    get canExpand(): boolean {
        return this.facetGroup.values.length > this.collapseLimit;
    }

    get visibleFacets(): FacetValueViewObject[] {
        // Limit if over limit and not expanded
        let visibleFacets = this.facetGroup.values;

        if (this.filter) {
            visibleFacets = visibleFacets.filter(val => val.displayValue.toLowerCase().indexOf(this.filter.toLowerCase()) > -1);
        } else if (this.canExpand && !this.isExpanded) {
            visibleFacets = take(visibleFacets, this.facetsVisibleWhenCollapsed);
        }
        return visibleFacets;
    }

    sortByVolume(facets: FacetValueViewObject[]): FacetValueViewObject[] {
        let sortedFacets = sortBy(facets, facet => facet.count);
        sortedFacets.reverse();
        return sortedFacets;
    }

    get shouldShowExpandButton(): boolean {
        return this.canExpand && !this.filter;
    }

    $refs!: {
        searchFacetGroupFacetRange: SearchFacetGroupFacetRange;
    };

    @Watch('facetGroup', {
        immediate: true
    })
    validateUrlFacets(): void {
        const facetGroupUrlValues = urlHelper.getFacets()[this.facetGroup.key];
        const availableFacetGroupValues = this.facetGroup.values.map(value => value.value);
        if (facetGroupUrlValues) {
            facetGroupUrlValues.forEach(value => {
                if (!availableFacetGroupValues.includes(value)) {
                    urlHelper.updateFacetValue(this.facetGroup.key, value, false, false);
                }
            });
        }
    }

    facetValueEscape(facetValue: string): string {
        // facetvalues are like displaynames with all sorts of special characters in them
        return encodeURIComponent(facetValue);
    }

    facetChange(facet: FacetValueViewObject): void {
        urlHelper.updateFacetValue(this.facetGroup.key, facet.value, facet.selected);
    }

    toggleShowMore(): void {
        this.isExpanded = !this.isExpanded;
    }

    onHasRangeValueChange(hasRangeValue: boolean): void {
        this.hasRangeValue = hasRangeValue;
    }

    public reset(): void {
        this.filter = '';
        this.isExpanded = false;
        if (this.$refs.searchFacetGroupFacetRange !== undefined) {
            (this.$refs.searchFacetGroupFacetRange as any).resetFacetRange();
        }
    }
}
