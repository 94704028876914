





























































































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import FlexBoxDeliveryAddAddress from '@/project/flexBox/FlexBoxDeliveryAddAddress.vue';
import FlexBoxSelectedDeliveryAddress from '@/project/flexBox/FlexBoxSelectedDeliveryAddress.vue';
import { DeliveryAddressViewObject } from '@/types/serverContract';
import { trackAndStartFlexBoxMode } from '@/project/flexBox/flexBoxModeStart';

@Component({
    components: {
        FlexBoxDeliveryAddAddress,
        FlexBoxSelectedDeliveryAddress
    }
})
export default class FlexBoxForm extends Vue {
    @Prop({
        required: true
    }) showCreateDeliveryAddress!: boolean;

    @Prop({
        required: true
    }) showChangeDeliveryAddress!: boolean;

    @Prop({
        required: true
    }) selectedDeliveryAddress!: DeliveryAddressViewObject | null;

    @Prop({
        required: true
    }) pending!: boolean;

    @Prop({
        required: true
    }) sapAddressId!: string;

    mobileNumber: string = '';
    hasSapAddressError: boolean = false;

    get hasSapAddressId(): boolean {
        return this.sapAddressId !== '';
    }

    get showSapAddressError(): boolean {
        return this.hasSapAddressError && !this.hasSapAddressId;
    }

    get isSubmitDisabled(): boolean {
        let isFormComplete = this.mobileNumber && this.sapAddressId;
        return this.pending || this.vvErrors.items.length > 0 || !isFormComplete;
    }

    async submit(): Promise<void> {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.pending) {
            return;
        }

        if (!this.hasSapAddressId) {
            this.hasSapAddressError = true;
            return;
        }

        this.hasSapAddressError = false;
        this.$emit('setPending', true);

        try {
            await trackAndStartFlexBoxMode({
                sapAddressId: this.sapAddressId,
                phoneNumber: this.mobileNumber
            });
        } finally {
            this.$emit('setPending', false);
        }
    }

    onCreatedNewAddress(newAddress: DeliveryAddressViewObject) {
        this.$emit('newAddressCreated', newAddress);

        if (this.isSubmitDisabled) {
            this.setShowCreateDeliveryAddress(false);
        } else {
            this.submit();
        }
    }

    cancel(): void {
        this.$emit('cancel');
    }

    setShowCreateDeliveryAddress(newValue: boolean) {
        this.$emit('showCreateDeliveryAddress', newValue);
    }

    setShowChangeDeliveryAddress() {
        this.$emit('showChangeDeliveryAddress');
    }

    close() {
        this.$emit('close');
    }

    private vvErrors!: any;
}
