







































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import { DataPolicyRequest } from '@/types/serverContract';

@Component({
    components: { ButtonSubmit }
})
export default class AcceptDataPolicy extends Vue {
    hasAcceptedDataPolicy: boolean = true;
    pending: boolean = false;
    acceptDataPolicy: boolean = false;
    acceptDataPolicySuccess: boolean = false;
    successTimer = 3000;
    timeout: number | null = null;

    created(): void {
        this.getPolicyStatus();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async getPolicyStatus(): Promise<void> {
        try {
            await Api.dataPolicy.getDataPolicyStatus().then(dataPolicyResponse => {
                this.hasAcceptedDataPolicy = dataPolicyResponse.hasAcceptedDataPolicy;
            });
        } finally {

        }
    }

    async submitAcceptDataPolicy(): Promise<void> {
        if (this.acceptDataPolicySuccess) {
            return;
        }

        this.pending = true;

        try {
            const payload: DataPolicyRequest = {
                hasAcceptedDataPolicy: this.acceptDataPolicy
            };

            await Api.dataPolicy.setPolicy(payload).then(() => {
                this.showSuccessIndicator();
            });
        } finally {
            this.pending = false;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.acceptDataPolicySuccess = true;

        this.timeout = setTimeout(() => {
            this.hasAcceptedDataPolicy = true;
        }, this.successTimer);
    }
}
