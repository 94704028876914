
function isEscape(event: KeyboardEvent) {
    return event && (event.key === 'Escape' || event.key === 'Esc');
}
function isRightArrow(event: KeyboardEvent) {
    return event && (event.key === 'ArrowRight' || event.key === 'Right');
}
function isLeftArrow(event: KeyboardEvent) {
    return event && (event.key === 'ArrowLeft' || event.key === 'Left');
}
function isUpArrow(event: KeyboardEvent) {
    return event && (event.key === 'ArrowUp' || event.key === 'Up');
}
function isDownArrow(event: KeyboardEvent) {
    return event && (event.key === 'ArrowDown' || event.key === 'Down');
}
function isArrowKeyPress(event: KeyboardEvent) {
    return event && (
        isUpArrow(event) ||
        isRightArrow(event) ||
        isDownArrow(event) ||
        isLeftArrow(event)
    );
}
function isTab(event: KeyboardEvent) {
    return event && (event.key === 'Tab');
}
function isShiftTab(event: KeyboardEvent) {
    return event && (event.key === 'Tab' && event.shiftKey === true);
}
function isEnter(event: KeyboardEvent) {
    return event && (event.key === 'Enter');
};

export default {
    isEscape,
    isRightArrow,
    isLeftArrow,
    isUpArrow,
    isDownArrow,
    isArrowKeyPress,
    isEnter,
    isTab,
    isShiftTab
};
