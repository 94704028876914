










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProductGetter } from '@/store/product';
import {
    ProductDetailsViewObject,
    VariantDetailsViewObject,
    RelatedProductsViewObject
} from '@/types/serverContract';
import ProductCarousel from '@/project/products/carousels/ProductCarousel.vue';
import { PRODUCT_LIST_VIEW_TYPES } from '@/core/enums/productListViewTypes';

@Component({
    components: {
        ProductCarousel
    }
})
export default class ProductRelatedProductsCarousel extends Vue {
    @Prop({ default: true, type: Boolean }) showHeader!: boolean;
    @Prop({ default: true, type: Boolean }) showProductAttributes!: boolean;

    productListViewType: string = PRODUCT_LIST_VIEW_TYPES.RELATED_ITEM;

    @ProductGetter product!: ProductDetailsViewObject | null;
    @ProductGetter productSelectedVariant!: VariantDetailsViewObject | null;

    get getRelatedProducts(): RelatedProductsViewObject | null {
        return this.productSelectedVariant ? this.productSelectedVariant.relatedProducts : null;
    }
}
