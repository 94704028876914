






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import serverContext from '@/core/serverContext.service';
import tracking from '@/core/tracking/tracking.service';

@Component
export default class BrandDisplay extends Vue {
    @Prop({
        type: String,
        required: true
    }) brandImageUrl!: string;

    @Prop({
        type: String,
        required: true
    }) brandId!: string;

    isImageUrlValid: boolean = true;

    get formattedBrandName(): string {
        return this.brandId[0].toUpperCase() + this.brandId.substr(1);
    }

    get searchUrl(): string {
        return `${serverContext.sitePages.searchPage.url}?term=${this.brandId}`;
    }

    trackBrandClick() {
        tracking.trackFrontpageNavigation('Dine Brands', this.brandId);
    }

    imageUrlFailed() {
        this.isImageUrlValid = false;
    }
}
