


























































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import {
    OrderViewModel,
    DeliveryType, BasketViewModel, LineItemDeliveryViewObject, AllowanceChargeCalculationViewObject, DeliveryAllowanceChargesCalculationViewObject
} from '@/types/serverContract';
import { CheckoutGetter } from '@/store/checkout';
import { BasketGetter } from '@/store/basket';
import DeliveryApproveOrderLineItem from '@/project/checkout/DeliveryApproveOrderLineItem.vue';
import DeliveryApproveOrderSummary from '@/project/checkout/DeliveryApproveOrderSummary.vue';
import tracking from '@/core/tracking/tracking.service';
import { AppGetter } from '@/store/app';
import { FlexBoxGetter } from '@/store/flexBox';
import productTrackingService from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';
import flexBoxService from '@/project/flexBox/flexBox.service';
import { ICheckoutStepActionField } from '@/core/tracking/productTracking.g4-structure';

@Component({
    components: {
        DeliveryApproveOrderSummary,
        DeliveryApproveOrderLineItem
    }
})
export default class DeliveryApproveOrder extends Vue {
    @AppGetter canSeeNetPrices!: boolean;
    @AppGetter canSeeGrossPrices!: boolean;
    @CheckoutGetter order!: OrderViewModel;
    @CheckoutGetter isOrderApproved!: boolean;
    @BasketGetter basket!: BasketViewModel;
    @BasketGetter totalLines!: number;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    showInformationArea: boolean = false;
    hasTrackedToggleShowInformationAreaOnce: boolean = false;
    deliveryTypes = DeliveryType;

    get isSplitOrder(): boolean {
        return this.order.deliveries && this.order.deliveries.length > 1;
    }

    get isDeliveryDateUnknown(): boolean {
        return this.order.deliveries && this.order.deliveries.some(d => !d.hasDeliveryDate);
    }

    // is order date unknown Or is split order
    get isDeliveryDateUnknownOrSplitOrder(): boolean {
        return this.isDeliveryDateUnknown || this.isSplitOrder;
    }

    get lineItemsAsDeliveryLineItems(): LineItemDeliveryViewObject[] {
        let deliveryLineItems: LineItemDeliveryViewObject[] = [];
        this.basket.lineItems.forEach(d => {
            let lineItemDelivery: LineItemDeliveryViewObject = {
                deliveryAllowanceCharges: d.allowances,
                deliveryCharges: this.mapAllowanceChargesToDeliveryAllowanceCharges(d.charges),
                deliveryGrossTotal: d.lineGrossTotal,
                deliveryTotal: d.lineTotal,
                lineItem: d,
                quantity: d.quantity,
                showCertificate: false,
                unit: d.unit
            };
            lineItemDelivery.lineItem = d;
            deliveryLineItems.push(lineItemDelivery);
        });
        return deliveryLineItems;
    }

    mapAllowanceChargesToDeliveryAllowanceCharges(allowanceCharges: AllowanceChargeCalculationViewObject[]): DeliveryAllowanceChargesCalculationViewObject[] {
        return allowanceCharges.map(allowanceCharge => ({
            description: allowanceCharge.description,
            totalAmount: allowanceCharge.total
        }));
    }

    get hasRequiredData(): boolean {
        if (this.isInFlexBoxContext) {
            return this.basket && (this.basket.deliveryMethod !== undefined &&
            this.basket.deliveryMethod !== 0 &&
            this.basket.deliveryAddress !== undefined &&
            this.basket.deliveryAddress.addressName !== undefined);
        } else {
            return this.basket && (this.basket.deliveryMethod !== undefined &&
                        this.basket.deliveryMethod !== 0 &&
                        this.basket.deliveryAddress !== undefined &&
                        this.basket.deliveryAddress.addressName !== undefined &&
                        this.order.rawDeliveryDate !== undefined &&
                        this.order.rawDeliveryDate !== null &&
                        this.order.rawDeliveryDate !== '' &&
                        this.order.rawDeliveryDate !== '0001-01-01');
        }
    }

    get getDeliverySplitOrderMessage() {
        switch (this.order.deliveryMethod) {
            case this.deliveryTypes.BUTIK:
                return this.$tr(this.$labels.Views.Checkout.Delivery.ShippingTime.CanNotBePickedUpSimultaneously);
            case this.deliveryTypes.CC:
                return this.$tr(this.$labels.Views.Checkout.Delivery.ShippingTime.CCCanNotBePickedUpSimultaneously);
            default:
                if (this.order.deliveryMethod !== this.deliveryTypes.UNKNOWN) {
                    return this.$tr(this.$labels.Views.Checkout.Delivery.ShippingTime.CanNotBeDeliveredSimultaneously);
                }
        }
    }

    // basket.interceptor updates basket after orderSubmit, so make sure that is not the cause of the watcher firing
    private get isValidTrackingAction(): boolean {
        return this.basket && (this.basket.deliveryMethod !== undefined && this.totalLines > 0);
    }

    get deliveryText(): string {
        return flexBoxService.getFlexBoxInfoText(this.$labels.Views.FlexBox.TimeSpecific.OrderDeliveryIn.name, this.$labels.Views.FlexBox.TimeSpecific.OrderDeliveryIn.name);
    }

    @Watch('hasRequiredData')
    onHasRequiredDataChange(newVal) {
        if (newVal) {
            this.$emit('isLoading', false);
        }
    }

    @Watch('isOrderApproved')
    private onIsOrderApprovedChange() {
        this.trackCheckoutStep();
    }

    @Watch('basket.deliveryMethod')
    private onDeliveryMethodChange(newDeliverMethod, oldDeliveryMethod) {
        const isPageRefresh = oldDeliveryMethod === undefined;
        if (isPageRefresh) {
            this.trackCheckoutStep();
        } else {
            this.trackCheckoutOption();
        }
    }

    @Watch('isDeliveryDateUnknownOrSplitOrder')
    onDeliveryDateUnknownOrSplitOrderChange(newVal) {
        if (newVal !== undefined) {
            this.showInformationArea = newVal;
        }
    }

    private mounted() {
        this.trackCheckoutStep();
        this.showInformationArea = this.isSplitOrder;
    }

    toggleShowInformationArea(): void {
        this.showInformationArea = !this.showInformationArea;
        this.trackToggleShowInformationArea();
    }

    trackToggleShowInformationArea(): void {
        if (!this.hasTrackedToggleShowInformationAreaOnce) {
            const wasShownInitially: boolean = this.isSplitOrder;
            tracking.trackCheckoutToggleInformation(wasShownInitially);
            this.hasTrackedToggleShowInformationAreaOnce = true;
        }
    }

    private trackCheckoutStep(): void {
        if (this.basket) {
            if (!this.isValidTrackingAction) {
                return;
            }

            productTrackingService.TrackProducts(
                PRODUCT_TRACKING_EVENT.Checkout,
                productTrackingService.ToTrackedProducts(
                    this.basket.lineItems,
                    PRODUCT_TRACKING_TYPE.LineItemViewObject
                )
            );
        }
    }

    private trackCheckoutOption(): void {
        if (!this.isValidTrackingAction) {
            return;
        }
        const actionField: ICheckoutStepActionField = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            shipping_tier: this.basket.deliveryMethod
        };
        productTrackingService.TrackProducts(
            PRODUCT_TRACKING_EVENT.checkoutOption,
            productTrackingService.ToTrackedProducts(
                this.basket.lineItems,
                PRODUCT_TRACKING_TYPE.LineItemViewObject
            ),
            actionField
        );
    }
}
