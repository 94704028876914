





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DeleteEasySupplyGatewayRequest } from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class DeleteGateway extends Vue {
    @Prop({
        required: true,
        type: String
    }) hardwareIdentifier!: string;

    @Prop({
        required: true,
        type: Number
    }) warehouseDetailId!: number;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number = 0;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async deleteGateway(): Promise<void> {
        if (this.pending) return;
        this.pending = true;

        const payload: DeleteEasySupplyGatewayRequest = {
            easySupplyWarehouseId: this.warehouseDetailId,
            gatewayHardwareIdentifier: this.hardwareIdentifier
        };

        try {
            await Api.easySupply.deleteEasySupplyGateway(payload);
            this.showSuccessIndicator();
            this.$emit('updatedWarehouse');
            this.closeModal();
        } finally {
            this.pending = false;
        }
    }

    hideDeleteGateway(): void {
        this.$emit('hideDeleteGateway');
    }

    closeModal() {
        this.$emit('closeModal');
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }
}
