import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import { MaintenanceDocument } from '@/types/serverContract';
import { ReportsAction } from '@/store/reports';

@Mixin
export class ProjectFolderOrderHistoryMaintenanceMixin extends Vue {
    @ReportsAction addMaintenanceDocument!: (maintenanceDocument: MaintenanceDocument) => void;
    @ReportsAction deleteMaintenanceDocument!: (maintenanceDocument: MaintenanceDocument) => void;

    private maintenanceDoc: MaintenanceDocument | null = null;

    public toggleDocument(maintenanceDocument: MaintenanceDocument): void {
        this.maintenanceDoc = maintenanceDocument;

        this.$emit('toggleDocument', maintenanceDocument);
    }
}
