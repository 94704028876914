











































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AddOrUpdateSpecialOrderLineItemRequest } from '@/types/serverContract';
import { BasketGetter } from '@/store/basket';
import ProductAddToBasketLifeCycleIcons from '@/project/products/ProductAddToBasketLifeCycleIcons.vue';
import api from '@/project/http/api';

@Component({
    name: 'ProductAddToBasket',
    components: {
        ProductAddToBasketLifeCycleIcons
    }
})
export default class SpecialProductTileAddToBasket extends Vue {
    @BasketGetter isBasketBusy!: boolean;

    @Prop({
        type: Boolean,
        default: false
    }) compact!: boolean;

    @Prop({
        required: true,
        type: Object
    }) payload!: AddOrUpdateSpecialOrderLineItemRequest;

    @Prop({
        required: true,
        type: Number
    }) lineItemId!: number;

    @Prop({
        required: true,
        type: Number
    }) lineItemQuantity!: number;

    @Prop({
        required: true,
        type: String
    }) lineItemUnitCode!: string;

    @Prop({
        type: String
    }) label!: string;

    @Prop({
        type: String
    }) successLabel!: string;

    @Prop({
        type: Boolean,
        default: false
    }) disabled!: boolean;

    pending = false;
    success = false;
    successTimer = 3000;
    specialLineItemQuantity: number = this.lineItemQuantity;
    timeout: number | null = null;

    get buttonClasses() {
        return {
            'c-btn--success': this.success,
            'pl-10': this.compact,
            'pr-6': this.compact,
            'w-full': !this.compact
        };
    }

    get buttonIsDisabled() {
        return this.disabled || (this.isBasketBusy && !this.pending);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    specialLineItemQtyChanged(): void {
        this.$emit('specialLineItemQtyChanged', this.specialLineItemQuantity);
    }

    initAddToBasketRequest() {
        if (this.isBasketBusy || this.pending) {
            return;
        }

        this.pending = true;
        api.basket.addOrUpdateSpecialOrderLineItem(this.payload, '\'special-order\'')
            .then(() => { this.showSuccessIndicator(); })
            .finally(() => {
                this.pending = false;
            });
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }
}
