
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SortByViewObject } from '@/types/serverContract';
import urlHelper from '@/project/shared/services/urlHelperSimpleSearch.service';

@Component
export default class EasySupplySorting extends Vue {
    @Prop({
        type: Array,
        required: true
    })
    options!: SortByViewObject[];

    activeSorting: SortByViewObject | null = null;

    created() {
        this.activeSorting = this.getSelectedSorting();
    }

    getSelectedSorting(): SortByViewObject {
        let selectedOption = this.options.find(option => option.isSelected);
        if (!selectedOption) {
            throw new Error('No sorting option selected - server error');
        }
        return selectedOption;
    }

    onChange(event) {
        urlHelper.setSorting(event.target.value);
    }
}
