











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import DownloadCatalogForm from '@/project/shared/DownloadCatalogForm.vue';
import {
    BaseReportRequest,
    CategoryReportAsEmailRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';

@Component({
    components: {
        DownloadCatalogForm,
        ModalOverlay
    }
})
export default class CategoryPrintCatalog extends Vue {
    @AppGetter isLoggedIn!: boolean;

    @Prop({
        required: true,
        type: String
    }) categoryId!: string;

    showDownloadCatalogModal: boolean = false;
    pending: boolean = false;
    success: boolean = false;
    successEmail: string | null = null;
    successTimer: number = 4000;
    timeout: number | null = null;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async downloadCatalog(baseReportRequest: BaseReportRequest, emailAddressForReport: string) {
        const downloadCatalogRequest: CategoryReportAsEmailRequest = {
            emailAddress: emailAddressForReport,
            categoryId: this.categoryId,
            reportType: baseReportRequest.reportType,
            priceType: baseReportRequest.priceType,
            productNumberType: baseReportRequest.productNumberType
        };

        try {
            const reportAsEmailModel = await Api.reports.requestCategoryReportAsEmail(downloadCatalogRequest);
            this.successEmail = reportAsEmailModel.email;
            this.showSuccessIndicator();
        } finally {
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.closeDownloadCatalogModal();
            this.success = false;
            this.successEmail = null;
        }, this.successTimer);
    }

    closeDownloadCatalogModal() {
        this.showDownloadCatalogModal = false;
    }
}
