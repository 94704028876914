






































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    ItemViewObject,
    ShipmentSummaryViewObject
} from '@/types/serverContract';
import TrackAndTraceDetailsShipmentInfoGoodsLine from '@/project/myServices/TrackAndTraceDetailsShipmentInfoGoodsLine.vue';

@Component({
    components: { TrackAndTraceDetailsShipmentInfoGoodsLine }
})
export default class TrackAndTraceDetailsShipmentInfoGoods extends Vue {
    @Prop({
        required: true,
        type: Array
    }) shipmentGoods!: ItemViewObject[];

    @Prop({
        required: false,
        type: Object
    }) shipmentSummary!: ShipmentSummaryViewObject;
}
