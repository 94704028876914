import httpService from '@/core/http/http.service';
import {
    AddFavoriteOrderLineItemRequest,
    AddFavoriteOrderSpecialLineItemRequest,
    CopyFavoriteOrderRequest,
    CopyTop100ProductsToFavoriteOrderRequest,
    DeleteFavoriteOrderLineRequest,
    FavoriteOrderDetailViewModel,
    FavoriteOrdersViewModel,
    FavoriteOrdersImportResultViewModel,
    GetFavoriteOrderRequest,
    QuickAddFavoriteOrderLineItemRequest,
    UpdateFavoriteOrderLineItemRequest,
    UpdateFavoriteOrderSpecialLineItemRequest,
    FavoriteOrderSelectorViewModel,
    MergeFavoriteOrderRequest,
    PagedFavoriteOrdersRequest,
    PagedFavoriteOrdersViewModel,
    GetByVariantIdsRequest,
    FavoriteOrderImagesViewObject,
    CreateOrUpdateFavoriteOrderRequest,
    GetIdsInFavoriteOrderBySourceFieldRequest,
    FavoriteOrderProductIdsFilteredByCriteriaViewModel,
    GetFilteredFavoriteOrderByProductIdsRequest
} from '@/types/serverContract';

export default class FavoriteOrderController {
    public async getFavoriteOrders(): Promise<FavoriteOrdersViewModel> {
        return httpService.get('favoriteOrderApi/favoriteOrders');
    }

    public async getPagedFavoriteOrders(payload: PagedFavoriteOrdersRequest): Promise<PagedFavoriteOrdersViewModel> {
        return httpService.get('favoriteOrderApi/pagedFavoriteOrders', payload);
    }

    public async getFavoriteOrdersForSalesAccount(soldToPartnerId: string): Promise<FavoriteOrdersViewModel> {
        return httpService.get('favoriteOrderApi/favoriteOrdersForSalesAccount?soldToPartnerId=' + soldToPartnerId);
    }

    public async getFavoriteOrdersForSelector(): Promise<FavoriteOrderSelectorViewModel> {
        return httpService.get('favoriteOrderApi/favoriteOrdersForSelector');
    }

    public async deleteFavoriteOrder(id: number): Promise<FavoriteOrdersViewModel> {
        return httpService.delete('favoriteOrderApi/favoriteOrder/' + id);
    }

    public async createFavoriteOrder(payload: CreateOrUpdateFavoriteOrderRequest): Promise<FavoriteOrdersViewModel> {
        return httpService.put('favoriteOrderApi/favoriteOrder', payload);
    }

    public async getFavoriteOrder(payload: GetFavoriteOrderRequest): Promise<FavoriteOrderDetailViewModel> {
        return httpService.get('favoriteOrderApi/favoriteOrder', payload);
    }

    public async getFilteredFavoriteOrderByProductIds(payload: GetFilteredFavoriteOrderByProductIdsRequest): Promise<FavoriteOrderDetailViewModel> {
        return httpService.get('favoriteOrderApi/filteredFavoriteOrderByProductIds', payload);
    }

    public async getIdsInFavoriteOrderBySourceField(payload: GetIdsInFavoriteOrderBySourceFieldRequest): Promise<FavoriteOrderProductIdsFilteredByCriteriaViewModel> {
        return httpService.get('favoriteOrderApi/getIdsInFavoriteOrderBySourceField', payload);
    }

    public async updateFavoriteOrder(payload: CreateOrUpdateFavoriteOrderRequest): Promise<void> {
        return httpService.post('favoriteOrderApi/favoriteOrder', payload);
    }

    public async deleteLineItem(payload: DeleteFavoriteOrderLineRequest): Promise<void> {
        return httpService.post('favoriteOrderApi/deleteLineItem', payload);
    }

    public async importFavoriteOrder(payload: any): Promise<FavoriteOrdersImportResultViewModel> {
        return httpService.post('favoriteOrderApi/importFavoriteOrder', payload);
    }

    public async addToFavoriteOrder(payload: AddFavoriteOrderLineItemRequest): Promise<void> {
        return httpService.put('favoriteOrderApi/lineitem', payload);
    }

    public async deleteAllLineItems(favoriteOrderId: number): Promise<void> {
        return httpService.post('favoriteOrderApi/deleteAllLineItems', { favoriteOrderId });
    }

    public async copyFavoriteOrder(payload: CopyFavoriteOrderRequest): Promise<void> {
        return httpService.post('favoriteOrderApi/copyFavoriteOrder', payload);
    }

    public async mergeFavoriteOrder(payload: MergeFavoriteOrderRequest): Promise<void> {
        return httpService.post('favoriteOrderApi/mergeFavoriteOrder', payload);
    }

    public async quickAddLineItem(payload: QuickAddFavoriteOrderLineItemRequest, messagesId: string): Promise<void> {
        return httpService.put('favoriteOrderApi/quickAddLineItem ', payload, messagesId);
    }

    public async addSpecialLineItem(payload: AddFavoriteOrderSpecialLineItemRequest): Promise<void> {
        return httpService.put('favoriteOrderApi/specialLineItem ', payload);
    }

    public async updateLineItem(payload: UpdateFavoriteOrderLineItemRequest): Promise<void> {
        return httpService.post('favoriteOrderApi/lineitem', payload);
    }

    public async updateSpecialLineItem(payload: UpdateFavoriteOrderSpecialLineItemRequest): Promise<void> {
        return httpService.post('favoriteOrderApi/specialLineItem', payload);
    }

    public async copyTop100ProductsToFavoriteOrder(payload: CopyTop100ProductsToFavoriteOrderRequest): Promise<void> {
        return httpService.get<void>('favoriteOrderApi/copyTop100ProductsToFavoriteOrder', payload);
    }

    public async getThumbnailImages(payload: GetByVariantIdsRequest): Promise<FavoriteOrderImagesViewObject> {
        return httpService.post('favoriteOrderApi/getThumbnailImages', payload);
    }
}
