


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ProductImageViewObject } from '@/types/serverContract';
import Constants from '@/project/config/constants';

@Component
export default class ProductDetailsImagesThumbs extends Vue {
    @Prop({
        type: Array
    }) productImages!: ProductImageViewObject[];

    @Prop({
        type: String
    }) altText!: string;

    @Prop({
        type: Number
    }) activeIndex!: number;

    @Watch('activeIndex')
    private onActiveIndexChange(newValue: number) {
        this.setActiveSlider(newValue);
    }

    activeSlide: number = this.activeIndex;

    setActiveSlider(index: number): void {
        this.activeSlide = index;

        this.$emit('setActiveSlider', this.activeSlide);
    }

    getThumbnailImage(image: ProductImageViewObject): string {
        return image.productImageUrl ? image.productImageUrl : Constants.ImageNotFound;
    }
}
