
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'SpinnerElement'
})
export default class SpinnerElement extends Vue {
    @Prop({
        required: false,
        type: String,
        default: 'blue-500'
    })
    color;

    @Prop({
        required: false,
        type: String
    })
    size;

    get spinnerClass() {
        return this.size === 'small' ? 'spinner-element--small' : '';
    }

    get dotClass() {
        return this.color ? `bg-${this.color}` : 'bg-blue-500';
    }
}
