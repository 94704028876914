import { Vue, Component } from 'vue-property-decorator';
import { CtrlMixin } from './CtrlMixin.mixin';

@Component
export class FormOrchestrator extends Vue {
    setOtherFormsInactive: boolean = false;
    allCtrls: CtrlMixin[] = [];
    activeCtrl: CtrlMixin | null = null;
    isActive = false;

    init() {
        this.allCtrls.forEach(ctrlMixin => {
            this.$watch(() => ctrlMixin.Ctrl.isActive, () => {
                this.onActiveCtrlChanged(ctrlMixin);
            });
        });
    }

    get currentActive() {
        return this.allCtrls.find(ctrl => ctrl === this.activeCtrl);
    }

    exit() {
        this.allCtrls.forEach(ctrlMixin => {
            ctrlMixin.Ctrl.isActive = false;
        });
        this.activeCtrl = null;
        this.isActive = false;
    }

    onActiveCtrlChanged(selectedCtrl: CtrlMixin) {
        if (selectedCtrl !== this.activeCtrl && selectedCtrl.Ctrl.isActive) {
            if (this.setOtherFormsInactive) {
                this.allCtrls.forEach(ctrl => {
                    if (ctrl !== selectedCtrl) {
                        ctrl.Ctrl.isActive = false;
                    }
                });
            }
            this.activeCtrl = selectedCtrl;
        } else {
            this.activeCtrl = null;
        }
        this.isActive = this.allCtrls.some(ctrl => ctrl.Ctrl.isActive);
    }
}
