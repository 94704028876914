






































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    AccountGroupResponseSlimViewModel,
    AccountGroupResponseViewModel
} from '@/types/serverContract';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: {
        ModalOverlay,
        ButtonSubmit,
        SpinnerOverlay
    }
})
export default class AccountGroupListItem extends Vue {
    @Prop({ type: Object }) accountGroupList!: AccountGroupResponseSlimViewModel;

    firstAccountInGroup: AccountGroupResponseViewModel | null = null;
    firstSoldToPartnerName: string = '';
    showDeleteGroupOverlayOpen: boolean = false;
    pending: boolean = false;

    created() {
        this.getFirstAccountInGroup();
    }

    async getFirstAccountInGroup() {
        this.pending = true;

        try {
            this.firstAccountInGroup = await Api.accountGroup.Get(this.accountGroupList.id);
        } finally {
            this.pending = false;
        }

        if (this.firstAccountInGroup.salesAccounts) {
            this.firstSoldToPartnerName =
                this.firstAccountInGroup.salesAccounts &&
                this.firstAccountInGroup.salesAccounts.length
                    ? this.firstAccountInGroup.salesAccounts[0].soldToPartnerName
                    : '';
        }
    }

    toggleDeleteGroupOverlay() {
        this.showDeleteGroupOverlayOpen = !this.showDeleteGroupOverlayOpen;
    }

    editGroup() {
        this.$emit('edit', this.firstAccountInGroup);
    }

    deleteGroup() {
        this.$emit('delete', this.accountGroupList.id);
    }
}
