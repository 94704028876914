








































































































import Vue from 'vue';
import Component from 'vue-class-component';
import api from '@/project/http/api';
import {
    BasketViewModel,
    DeliveryAddressViewObject,
    DeliveryType,
    DeliveryViewModel,
    OrderViewModel,
    SetDeliveryTimeRequest
} from '@/types/serverContract';
import { BasketGetter, BasketAction } from '@/store/basket';
import { CheckoutGetter, CheckoutAction } from '@/store/checkout';
import { Watch } from 'vue-property-decorator';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import DatePickerCtrl from '@/project/form/DatePickerCtrl.vue';
import { dateParseFormat } from '@/project/config/utilities';
import dayjs from 'dayjs';
import { FlexBoxGetter } from '@/store/flexBox';
import bus from '@/core/bus';

@Component({
    components: {
        DatePickerCtrl,
        SpinnerOverlay
    }
})
export default class DeliveryDate extends Vue {
    @BasketGetter basket!: BasketViewModel;
    @BasketAction hasDeliveryAddressChanged!: (payload: DeliveryAddressViewObject | null) => Promise<boolean>;
    @BasketAction hasDeliveryMethodChanged!: (payload: DeliveryType | null) => Promise<boolean>;
    @CheckoutGetter order!: BasketViewModel;
    @CheckoutAction setOrder!: (payload: OrderViewModel | {}) => void;
    @CheckoutAction setOrderApproved!: (payload: boolean) => boolean;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    showSelectionArea: boolean = false;
    availableCalenderDates: Date[] | null = null;
    pendingDeliveryDate: boolean = false;
    currentDeliveryAddress: DeliveryAddressViewObject | null = null;
    selectedDeliveryMethod: DeliveryType | null = null;
    avaliableDelivery: DeliveryViewModel | null = null;
    selectDeliveryDatePayload: SetDeliveryTimeRequest = {
        deliveryTime: ''
    };

    get hasRequiredData(): boolean {
        return this.basket && (this.basket.deliveryMethod !== undefined &&
            this.basket.deliveryMethod !== 0 &&
            this.basket.deliveryAddress !== undefined &&
            this.basket.deliveryAddress.addressName !== undefined);
    }

    get hasDeliveryDateOnBasket(): boolean {
        return !!(this.order.rawDeliveryDate && this.order.rawDeliveryDate !== '0001-01-01' && this.basket.deliveryAddress !== undefined && this.basket.deliveryAddress.addressName !== undefined);
    }

    get selectedDateDisplayString(): string {
        return this.hasDeliveryDateOnBasket
            ? this.order.deliveryDate
            : '';
    }

    get isDeliveryMethodClickAndCollect(): boolean {
        return this.basket ? this.basket.deliveryMethod === DeliveryType.CC : false;
    }

    get isDeliveryDayOrNat(): boolean {
        return this.basket ? this.basket.deliveryMethod === DeliveryType.DAG || this.basket.deliveryMethod === DeliveryType.NAT : false;
    }

    get showDatePicker(): boolean {
        return this.showSelectionArea && this.selectDeliveryDatePayload.deliveryTime !== '';
    }

    created() {
        bus.on('DeliveryDate-onDeliveryAddressChanged', this.onDeliveryAddressChanged);
    }

    beforeDestroy() {
        bus.off('DeliveryDate-onDeliveryAddressChanged', this.onDeliveryAddressChanged);
    }

    @Watch('basket.deliveryMethod')
    async onDeliveryMethodChanged() {
        this.resetSelectedDateWhenMissingRequiredData();

        // if delivery method changed, then we need to reset the delivery time because the delivery time is all so based on the delivery method
        if (await this.hasDeliveryMethodChanged(this.selectedDeliveryMethod) || await this.hasDeliveryAddressChanged(this.currentDeliveryAddress)) {
            this.resetDeliveryTimeOnOrder();
            this.selectedDeliveryMethod = this.basket.deliveryMethod;
        }
    }

    async onDeliveryAddressChanged() {
        this.resetSelectedDateWhenMissingRequiredData();

        // if the delivery method is set cc or butik, then we need to reset the delivery time because the delivery time is based on the store open hours
        if (this.selectDeliveryDatePayload.deliveryTime && !this.isDeliveryDayOrNat) {
            this.selectDeliveryDatePayload.deliveryTime = '';
        }

        this.getDeliveryDates();
    }

    @Watch('selectDeliveryDatePayload.deliveryTime')
    async onDeliveryTimeChanged() {
        this.$emit('isLoading', true);
        this.setDeliveryDate();
    }

    @Watch('availableCalenderDates')
    disabledDates(): object {
        return {
            customPredictor: (date) => {
                if (this.availableCalenderDates) {
                    return !this.availableCalenderDates.find(acd => acd.toDateString() === date.toDateString());
                }
            }
        };
    }

    resetSelectedDateWhenMissingRequiredData() {
        if (!this.hasRequiredData && this.selectDeliveryDatePayload.deliveryTime) {
            this.selectDeliveryDatePayload.deliveryTime = '';
        }
    }

    changePendingDeliveryDate(pending: boolean) {
        this.pendingDeliveryDate = pending;
    }

    resetCurrentOrder(): void {
        this.setOrder({});
        this.setOrderApproved(false);
    }

    async setDeliveryDate() {
        if (!this.selectDeliveryDatePayload.deliveryTime) {
            return;
        }
        this.changePendingDeliveryDate(true);
        this.resetCurrentOrder();

        try {
            const deliveryInfo = await this.getDelivery();
            this.setOrder(deliveryInfo.order);
        } finally {
            this.changePendingDeliveryDate(false);
            this.showSelectionArea = false;
            this.avaliableDelivery = null;
        }
    }

    async getDelivery(): Promise<DeliveryViewModel> {
        if (this.avaliableDelivery) {
            return this.avaliableDelivery;
        }
        this.avaliableDelivery = await api.deliveryTimes.setDeliveryTime(this.selectDeliveryDatePayload);
        return this.avaliableDelivery;
    }

    async getDeliveryDates() {
        if (!this.hasRequiredData) {
            // If the component doesn't have the required data, the selectedDate will be reset
            this.selectDeliveryDatePayload.deliveryTime = '';
        } else {
            // If the component HAS required data AND the deliveryAddress has changed(or is not set) get the available deliveryDates and set the selectedDate from BE
            this.changePendingDeliveryDate(true);
            this.resetCurrentOrder();
            try {
                // We always need to get the cache anew when chaning delivery method
                this.avaliableDelivery = null;
                const deliveryInfo = await this.getDelivery();
                if (!deliveryInfo) {
                    return;
                }
                this.setOrder(deliveryInfo.order);
                this.availableCalenderDates = deliveryInfo.availableDeliveryDates.map(availableDate => new Date(availableDate.date));
                if (this.order.rawDeliveryDate && this.hasDeliveryDateOnBasket) {
                    const date: string = dayjs(this.order.rawDeliveryDate).format(dateParseFormat);
                    this.selectDeliveryDatePayload.deliveryTime = date;
                } else {
                    const date: string = dayjs(this.order.rawDeliveryDate).format(dateParseFormat);
                    this.selectDeliveryDatePayload.deliveryTime = date;
                    this.showSelectionArea = true;
                }
            } finally {
                this.currentDeliveryAddress = this.basket.deliveryAddress;
                this.changePendingDeliveryDate(false);
            }
        }
    }

    toggleShowSelectionArea(): void {
        this.showSelectionArea = !this.showSelectionArea;
    }

    resetDeliveryTimeOnOrder(): void {
        this.setOrder({ ...this.order, rawDeliveryDate: '', deliveryDate: '' });
    }
}
