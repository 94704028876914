import { render, staticRenderFns } from "./SearchPanelSuggestionsSlider.vue?vue&type=template&id=306658be&scoped=true"
import script from "./SearchPanelSuggestionsSlider.vue?vue&type=script&lang=ts"
export * from "./SearchPanelSuggestionsSlider.vue?vue&type=script&lang=ts"
import style0 from "./SearchPanelSuggestionsSlider.vue?vue&type=style&index=0&id=306658be&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "306658be",
  null
  
)

export default component.exports