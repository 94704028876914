


































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    model: {
        prop: 'modelValue',
        event: 'change'
    }
})
export default class CleanCheckbox extends Vue {
    id: string = '';

    @Prop(String) public label!: string;

    @Prop(String) public htmlLabel!: string;

    @Prop(String) public name!: string;

    @Prop({ default: true })
    public value;

    @Prop({ default: true })
    public trueValue;

    @Prop({ default: false })
    public falseValue;

    @Prop({ default: false })
    public modelValue!: boolean | Array<string>;

    created() {
        this.id = this.name + Math.round(Math.random() * 100000);
    }

    private updateValue(event) {
        let isChecked = event.target.checked;

        if (this.modelValue instanceof Array) {
            let newValue: any = [...this.modelValue];

            if (isChecked) {
                newValue.push(this.value);
            } else {
                newValue.splice(newValue.indexOf(this.value), 1);
            }

            this.$emit('change', newValue);
        } else {
            this.$emit('change', isChecked ? this.trueValue : this.falseValue);
        }
    }

    private get shouldBeChecked() {
        if (this.modelValue instanceof Array) {
            return this.modelValue.includes(this.value);
        }
        // Note that `true-value` and `false-value` are camelCase in the JS
        return this.modelValue === this.trueValue;
    }
}
