











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

/*
Inspired by this: https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
 */

@Component({
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    inject: ['$validator']
})
export default class CheckBoxCtrl extends Vue {
    id: string = '';

    @Prop(String) public label!: string;
    @Prop(String) public htmlLabel!: string;
    @Prop(String) public name!: string;
    @Prop(String) public constraints!: string;
    @Prop({ default: true }) public value;
    @Prop({ default: true }) public trueValue;
    @Prop({ default: false }) public falseValue;
    @Prop({ default: false }) public modelValue!: boolean | Array<string>;

    created() {
        this.id = this.name + Math.round(Math.random() * 100000);
    }

    private get field() {
        return this.vvFields[this.name];
    }

    private get error() {
        return this.vvErrors.first(this.name);
    }

    private updateValue(event) {
        let isChecked = event.target.checked;

        if (this.modelValue instanceof Array) {
            let newValue: any = [...this.modelValue];

            if (isChecked) {
                newValue.push(this.value);
            } else {
                newValue.splice(newValue.indexOf(this.value), 1);
            }

            this.$emit('change', newValue);
        } else {
            this.$emit('change', isChecked ? this.trueValue : this.falseValue);
        }

        if (this.constraints && this.field.validated && this.field.invalid) {
            this.validate();
        }
    }

    private get shouldBeChecked() {
        if (this.modelValue instanceof Array) {
            return this.modelValue.includes(this.value);
        }
        // Note that `true-value` and `false-value` are camelCase in the JS
        return this.modelValue === this.trueValue;
    }

    private get listeners() {
        const { change, ...listeners } = this.$listeners; // eslint-disable-line @typescript-eslint/no-unused-vars
        return listeners;
    }

    private validate() {
        this.$validator.validate(this.name);
    }

    // From Vue-validate
    private vvErrors!: any;
    private vvFields!: any;
}
