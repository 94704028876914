























import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import imageService from '@/core/image.service';

@Component
export default class BrandPageHeader extends Vue {
    @Prop({
        required: true,
        type: String
    }) brandName!: string;

    @Prop({
        type: String
    }) brandNameColor!: string;

    @Prop({
        type: String
    }) brandBackgroundColor!: string;

    @Prop({
        type: String
    }) logoUrl!: string;

    get formattedLogoUrl() {
        return `${this.logoUrl}?width=300`;
    }

    get hasLogo() {
        return this.logoUrl && this.logoUrl !== '';
    }

    get logoUrlWebP() {
        return imageService.getWebpSource(this.formattedLogoUrl);
    }
}
