




























































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import './round.filter';

@Component
export default class VaColebrookWhite extends Vue {
    roughness: number = 0.00025;
    internalDiameter: number = 104;
    drop: number = 10;
    fillPower: number = 1;
    cubicResult: number = 0;
    showColebrookCalculations: boolean = false;

    get waterDepth(): number {
        return ((this.internalDiameter / 1000) * this.fillPower);
    }

    get result(): number {
        return this.cubicResult * 1000;
    }

    created(): void {
        this.cubicResult = this.calculate();
    }

    calculate(): number {
        const b3 = this.internalDiameter / 1000;
        const b5 = this.drop / 1000;
        const j38 = (b3 * Math.sqrt(b3 * b5) * 1000000);
        const j39 = (3.71 * b3);
        const k38 = (0.74 / j38);
        const k39 = (this.roughness / j39);
        const l39 = (k38 + k39);
        const cubicResult = -(6.95 * this.log10(l39) * ((b3 * b3) * (Math.sqrt(b3 * b5))));

        this.cubicResult = cubicResult;
        return cubicResult;
    }

    log10(val): number {
        return Math.log(val) / Math.LN10;
    }

    toggle(): void {
        this.showColebrookCalculations = !this.showColebrookCalculations;
    }
}
