






























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import EasySupplyWarehouseListItem from '@/project/easySupply/EasySupplyWarehouseListItem.vue';
import {
    EasySupplyWarehouseDetailsListViewModel,
    EasySupplyWarehouseDetailsListItemViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { orderBy } from 'lodash-es';

type sortingDirection = 'asc' | 'desc';

@Component({
    components: {
        EasySupplyWarehouseListItem
    }
})
export default class EasySupplyStockList extends Vue {
    pending: boolean = false;
    easySupplyWarehouseListViewModel: EasySupplyWarehouseDetailsListViewModel | null = null;
    easySupplyWarehouseList: EasySupplyWarehouseDetailsListItemViewObject[] | null = null;
    createWarehouseUrl: string = '';
    activeSortingKey: string = '';
    activeSortingDirection: sortingDirection = 'asc';

    get showGatewayAssociated(): boolean {
        const gatewayCount = this.easySupplyWarehouseList
            ? this.easySupplyWarehouseList.filter(warehouse => warehouse.hasGateway).length
            : 0;
        return gatewayCount > 0;
    }

    created() {
        this.getEasySupplyWarehouseList();
    }

    async getEasySupplyWarehouseList() {
        this.pending = true;

        try {
            this.easySupplyWarehouseListViewModel = await Api.easySupply.getEasySupplyWarehouseDetailsList();
            if (this.easySupplyWarehouseListViewModel) {
                this.easySupplyWarehouseList = this.easySupplyWarehouseListViewModel.easySupplyWarehouseList;
                this.createWarehouseUrl = this.easySupplyWarehouseListViewModel.createWarehouseUrl;
            }
        } finally {
            this.pending = false;
        }
    }

    updateWarehouseList(newEasySupplyWarehouseList: EasySupplyWarehouseDetailsListItemViewObject[]): void {
        this.easySupplyWarehouseList = newEasySupplyWarehouseList;
    }

    sortList(sortingKey) {
        if (sortingKey === this.activeSortingKey) {
            this.toggleSortingDirection();
        } else {
            this.activeSortingKey = sortingKey;
            this.activeSortingDirection = 'asc';
        }

        if (this.easySupplyWarehouseList) {
            this.easySupplyWarehouseList = orderBy(this.easySupplyWarehouseList, item => item[sortingKey], this.activeSortingDirection) as EasySupplyWarehouseDetailsListItemViewObject[];
        }
    }

    toggleSortingDirection() {
        this.activeSortingDirection = this.activeSortingDirection === 'asc' ? 'desc' : 'asc';
    }
}
