






























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { SearchFeedbackRequest, SelectedFacetRequestObject } from '@/types/serverContract';
import urlHelper, { UrlFacets } from './urlHelper.service';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import tracking from '@/core/tracking/tracking.service';

export type searchExperience = '' | 'positive' | 'negative';

@Component({
    components: { ButtonSubmit }
})
export default class SearchFeedback extends Vue {
    @Prop({
        default: '',
        type: String
    }) categoryId!: string;

    @Prop({
        default: '',
        type: String
    }) activeSortBy!: string;

    @Prop({
        default: false,
        type: Boolean
    }) showOnlySurplus!: boolean;

    @Prop({
        default: 0,
        type: Number
    }) totalSearchResults!: number;

    @Prop({
        default: '',
        type: String
    }) brand!: string;

    selectedSearchExperience: searchExperience = '';
    feedbackText: string = '';
    pending: boolean = false;
    success: boolean = false;
    successTimer = 3000;
    timeout: number | null = null;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async submitSearchFeedback(): Promise<void> {
        this.pending = true;
        try {
            const searchFeedbackRequestPayload: SearchFeedbackRequest = {
                comment: this.feedbackText,
                searchRequest: {
                    categoryId: this.categoryId,
                    includeCategoryAggregations: this.categoryId !== '',
                    includeFacetAggregations: this.getSelectedFacets().length >= 1,
                    isFreeText: urlHelper.getTerm() !== null,
                    lastFacet: urlHelper.lastFacet(),
                    page: urlHelper.getPage(),
                    selectedFacets: this.getSelectedFacets().filter(facet => facet.key !== 'lastFacet'),
                    sortBy: this.activeSortBy,
                    term: this.getTerm(),
                    onlyShowSurplus: this.showOnlySurplus,
                    skipUniqueResultHandling: false,
                    skipVvsSearch: urlHelper.getSkipVvs(),
                    explain: false,
                    brand: this.brand
                },
                url: window.location.href,
                wasSuccessful: this.selectedSearchExperience === 'positive'
            };
            await Api.search.feedback(searchFeedbackRequestPayload).then(() => {
                this.showSuccessIndicator();
                this.trackSearchRating();
            });
        } finally {
            this.pending = false;
        }
    }

    getSelectedFacets(): SelectedFacetRequestObject[] {
        const searchFacets: UrlFacets = urlHelper.getFacets();
        return Object.keys(searchFacets).map(key => {
            return {
                key: key,
                values: searchFacets[key]
            } as SelectedFacetRequestObject;
        });
    }

    getTerm(): string {
        const urlHelperTerm = urlHelper.getTerm();
        return urlHelperTerm === null ? '' : urlHelperTerm;
    }

    setSearchExperience(searchExperience: searchExperience) {
        this.selectedSearchExperience = searchExperience;
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
            this.resetForm();
        }, this.successTimer);
    }

    resetForm(): void {
        this.selectedSearchExperience = '';
        this.feedbackText = '';
    }

    private trackSearchRating(): void {
        tracking.trackSearchRating(this.selectedSearchExperience, this.totalSearchResults);
    }
}
