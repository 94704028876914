

































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    AddQuotationToBasketRequest,
    AlternativeLineItemGroupViewObject,
    BasketViewModel,
    QuotationDetailsLineItemViewObject,
    QuotationItem
} from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import { BasketGetter } from '@/store/basket';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import Api from '@/project/http/api';

interface EmittedSelectedLine {
    indexId: number;
    selectedLine: QuotationDetailsLineItemViewObject;
}

@Component({
    components: {
        ButtonSubmit
    }
})
export default class ProjectOrderQuotationDetailsOrderLineWithOptions extends Vue {
    @AppGetter hideNetPrices!: boolean;
    @BasketGetter basket!: BasketViewModel;

    @Prop({
        required: true,
        type: String
    }) quotationId!: string;

    @Prop({
        required: true,
        type: Object
    }) orderLineWithOptions!: AlternativeLineItemGroupViewObject;

    @Prop({
        required: true,
        type: Number
    }) index!: number;

    @Prop({
        required: true,
        type: Boolean
    }) addToAllPending!: boolean;

    @Prop({
        required: true,
        type: Boolean
    }) addedToBasketFromParent!: boolean;

    success: boolean = false;
    pending: boolean = false;
    showErrorMsg: boolean = false;
    successTimer: number = 3000;
    orderLines: QuotationItem[] = [];
    selectedLine: QuotationDetailsLineItemViewObject | null = null;
    isAlreadyInBasket: boolean = false;
    timeout: number | null = null;

    get disableAddToBasket(): boolean {
        return this.showErrorMsg || this.isAlreadyInBasket || this.addToAllPending || this.addedToBasketFromParent || (this.selectedLine !== null && !this.selectedLine.allowOrdering);
    }

    mounted() {
        this.alreadyInBasket();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async addOrderLineToBasket() {
        this.pending = true;

        const payload: AddQuotationToBasketRequest = {
            quotationId: this.quotationId,
            lines: [
                {
                    lineId: this.selectedLine!.lineId,
                    quantity: this.selectedLine!.quantity
                }
            ]
        };

        try {
            await Api.basket.addQuotationToBasket(payload);
            this.showSuccessIndicator();
        } finally {
            this.pending = false;
        }
    }

    updateCurrentOrder(line: QuotationDetailsLineItemViewObject): void {
        this.selectedLine = {
            allowOrdering: line.allowOrdering,
            lineId: line.lineId,
            maxQuantity: line.maxQuantity,
            orderLineNetPrice: line.orderLineNetPrice,
            orderLineText: line.orderLineText,
            productId: line.productId,
            quantity: line.quantity,
            unit: line.unit,
            atpQuantity: line.atpQuantity
        };

        const emittedSelectedLine: EmittedSelectedLine = {
            selectedLine: this.selectedLine,
            indexId: this.index
        };

        this.$emit('updateOrderLinesWithOptions', emittedSelectedLine);
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;
        this.alreadyInBasket();
        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    hasMatch(orderLineId: string): boolean {
        return this.basket.lineItems.some(lineItem => lineItem.referenceDocument ? lineItem.referenceDocument.documentItem === orderLineId && lineItem.referenceDocument.documentId === this.quotationId : false);
    }

    alreadyInBasket(): void {
        let hasMatch = this.orderLineWithOptions.lines.find(orderLine => {
            return this.hasMatch(orderLine.lineId);
        });

        if (hasMatch) {
            this.isAlreadyInBasket = true;

            this.selectedLine = hasMatch;
        }
    }
}

