










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import {
    ProductTileSlimViewObject,
    RecommendationViewModel
} from '@/types/serverContract';
import ProductCarousel from '../products/carousels/ProductCarousel.vue';

@Component({
    components: {
        ProductCarousel
    }
})
export default class RaptorProductsForBasket extends Vue {
    @Prop({ type: String, required: true }) title!: string;
    @Prop({ type: String, required: true }) method!: string;

    pending: boolean = false;
    recommendationViewModel: RecommendationViewModel<ProductTileSlimViewObject> | null = null;

    get recommendationElements(): ProductTileSlimViewObject[] | null {
        return this.recommendationViewModel ? this.recommendationViewModel.elements : null;
    }

    created() {
        this.getRaptorProductsForBasket();
    }

    async getRaptorProductsForBasket() {
        this.recommendationViewModel = await Api.recommendation.recommendationForBasket();
    }
}
