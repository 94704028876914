/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/class-name-casing */
import { AddLineItemViewObject, AddToBasketRequest, AllowanceViewObject, CertificateViewObject, ChargeViewObject, DeliveryType, FavoriteOrderLineItemViewObject, LineItemViewObject, OrderViewModel, PriceViewObject, ProductDetailsViewObject, ProductTileSlimViewObject, ProductTileTrackingContext, ProductTileVariantViewObject, ProductTileViewObject, ScalePriceViewObject, StockStatusViewObject, UnitViewObject } from '@/types/serverContract';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '../enums/enums';
import trackingService from './tracking.service';
import serverContext from '../serverContext.service';
import { store } from '@/store';
import { router } from '@/router';
import { IItemEvent, ITransactionCompleteActionField, ICheckoutStepActionField } from './productTracking.g4-structure';

function getFormattedPrice(price: string): number {
    // Extract only numbers and comma from the input string
    const numbersOnly = price.replace(/[^0-9,]/g, '');

    // Replace the comma with a dot
    const replaced = numbersOnly.replace(/,/, '.');

    // Return the formatted string
    return parseFloat(replaced);
}

export class SlimTrackedProduct {
    id: string;
    brand: string;
    category: string;
    name: string;
    quantity: number;

    // optional fields
    climateCertificates?: boolean;
    scalaPrice?: boolean;

    lineReference?: boolean;
    variant?: string;

    fixedUnitCode?: string;
    price?: number;

    position?: number | null;
    list?: string;
    searchTerm?: string;

    constructor(init: Partial<SlimTrackedProduct> = {}) {
        Object.assign(this, init);
    }
}

export class SlimTrackedProduct_G4 {
    item_id: string;
    item_name: string;
    item_brand: string;
    item_category: string;
    quantity: number;

    // optional fields
    has_climate_certificates?: boolean;
    has_scala_price?: boolean;

    has_line_reference?: boolean;
    item_variant?: string;

    price?: number;

    index?: number | null;
    item_list_name?: string;

    constructor(init: Partial<SlimTrackedProduct_G4> = {}) {
        Object.assign(this, init);
    }
}

export class TrackedProduct extends SlimTrackedProduct {
    trackingContextObject?: ProductTileTrackingContext;
    priceObject?: TrackedPrice | null;
    constructor(init: Partial<TrackedProduct> = {}) {
        super(); // Add 'super' call to invoke the constructor of the base class
        Object.assign(this, init);
    }
}
function statusIfNotAvailable(index: string): string {
    return index === '' || index === undefined ? 'not available' : index;
}

function toSlimTrackedProduct_G4(product: TrackedProduct): SlimTrackedProduct_G4 {
    let slimProduct = new SlimTrackedProduct_G4({
        item_id: product.id,
        item_brand: statusIfNotAvailable(product.brand),
        item_category: product.category,
        item_name: product.name,
        quantity: product.quantity,
        has_climate_certificates: product.climateCertificates,
        has_scala_price: product.scalaPrice,
        has_line_reference: product.lineReference,
        item_variant: product.variant,
        price: product.price,
        index: product.position,
        item_list_name: product.list
    });

    // replace undefined fields with null
    const filteredProduct: Partial<SlimTrackedProduct> = Object.entries(slimProduct)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value !== undefined ? value : null }), {});

    return new SlimTrackedProduct_G4(filteredProduct);
}

function arrayToSlimTrackedProducts_G4(products: TrackedProduct[]): SlimTrackedProduct_G4[] {
    return products.map(product => toSlimTrackedProduct_G4(product));
}

function totalPriceFromProducts(products: TrackedProduct[]): number | undefined {
    if (products.some(product => product.price === undefined)) {
        return undefined;
    }
    return products.reduce((acc, product) => acc + (product.price || 0) * (product.quantity || 1), 0);
}

export class TrackedPrice implements PriceViewObject {
    allowances: AllowanceViewObject[];
    certificates: CertificateViewObject[];
    charges: ChargeViewObject[];
    grossPrice: string;
    hasScalePrices: boolean;
    isPriceServiceAvailable: boolean;
    netPrice: string;
    orderAllowances: AllowanceViewObject[];
    orderCharges: ChargeViewObject[];
    productId: string;
    scalePrices: ScalePriceViewObject[];
    stockStatus: StockStatusViewObject;
    trackingNetPrice: number;

    constructor(init: Partial<TrackedPrice> = {}) {
        Object.assign(this, init);
    }
}

export class TrackedTrackingContext implements ProductTileTrackingContext {
    list: string;
    position: number | null;
    searchTerm: string;

    constructor(init: Partial<TrackedTrackingContext> = {}) {
        Object.assign(this, init);
    }
}

export class OptionalTrackingData {
    storeId?: string;

    constructor(init: Partial<OptionalTrackingData> = {}) {
        Object.assign(this, init);
    }
}

export function GetFixedUnitVariantName(isFixedUnit: boolean, fixedUnitValue: number | null, fixedUnitCode: string | null, unitCode: string): string {
    return isFixedUnit ? `fixed_${fixedUnitValue}_${fixedUnitCode}` : unitCode;
}

export interface TrackProductClickViewModel {
    product: ProductTileViewObject;
    variant: ProductTileVariantViewObject;
}

class ProductTracking {
    constructor() {
        router.beforeEach((_to, _from, next) => {
            if (this.batchedProducts.length > 0) {
                this.TrackProducts(
                    PRODUCT_TRACKING_EVENT.ProductImpression,
                    this.batchedProducts
                );

                this.batchedProducts = [];
            }
            next();
        });
    }

    private batchedProducts: TrackedProduct[] = []

    // Set as the user type any search.
    lastTypedSearch: string;
    public setLastTypedTerm(term: string) {
        this.lastTypedSearch = term;
    }

    TrackBatchProduct(_event: PRODUCT_TRACKING_EVENT, _product: TrackedProduct) {
        const isDuplicate = this.batchedProducts.some(product => product.id === _product.id);
        if (isDuplicate) {
            return;
        }
        this.batchedProducts.push(_product);
    }

    TrackBatchProducts(_event: PRODUCT_TRACKING_EVENT, _product: TrackedProduct[]) {
        const existingIds = new Set(this.batchedProducts.map(product => product.id));
        const uniqueProducts = _product.filter(product => !existingIds.has(product.id));
        this.batchedProducts = [...this.batchedProducts, ...uniqueProducts];
    }

    TrackProduct(_event: PRODUCT_TRACKING_EVENT, _product: TrackedProduct, _actionfield: any = null, _optionalData: OptionalTrackingData | null = null) {
        this.TrackProducts(_event, [_product], _actionfield, _optionalData);
    }

    TrackProducts(_event: PRODUCT_TRACKING_EVENT, _products: TrackedProduct[], _actionfield: any = null, _optionalData: OptionalTrackingData| null = null) {
        _products.map(product => {
            if (product.priceObject) {
                product.price = getFormattedPrice(product.priceObject.netPrice);
            }
            if (product.trackingContextObject) {
                const list = this.getFormattedListNameFromProduct(_products);
                product.trackingContextObject.list = list === undefined ? '' : list;
                product.list = product.trackingContextObject.list;
                product.position = product.trackingContextObject.position;
            }
        });

        switch (_event) {
            case PRODUCT_TRACKING_EVENT.Pdp: // G3 = PDP, G4 = view_item
            case PRODUCT_TRACKING_EVENT.AddToCart: // G3 = addToCart, G4 = add_to_cart
            case PRODUCT_TRACKING_EVENT.Checkout: // G3 = checkout, G4 = begin_checkout
            case PRODUCT_TRACKING_EVENT.ProductClick: // G3 = productClick, G4 = select_item
            case PRODUCT_TRACKING_EVENT.ProductImpression: // G3 = productImpression, G4 = view_item_list
            case PRODUCT_TRACKING_EVENT.RemoveFromCart: // G3 = removeFromCart, G4 = remove_from_cart
                let itemEvent = this.createItemEvent(_products, _event);
                trackingService.track(itemEvent, true);
                break;
            case PRODUCT_TRACKING_EVENT.checkoutOption: // G3 = checkoutOption, G4 = add_shipping_info
                let checkoutOptionEvent = this.createCheckoutOptionEvent(_products, _actionfield);
                trackingService.track(checkoutOptionEvent, true);
                break;
            case PRODUCT_TRACKING_EVENT.TransactionComplete:
                let transactionCompleteEvent = this.createTransactionCompleteEvent(_products, _actionfield, _optionalData);
                trackingService.track(transactionCompleteEvent, true);
                break;
        }
    }

    createCheckoutOptionEvent(_products: TrackedProduct[], _actionField: ICheckoutStepActionField): IItemEvent {
        const event: IItemEvent = {
            event: 'add_shipping_info',

            ecommerce: {
                shipping_tier: this.getDeliveryType(_actionField.shipping_tier),
                currency: serverContext.currency,
                item_list_name: this.getFormattedListNameFromProduct(_products),
                value: totalPriceFromProducts(_products),
                items: arrayToSlimTrackedProducts_G4(_products)
            }
        };
        return event;
    }

    createTransactionCompleteEvent(_products: TrackedProduct[], _actionField: ITransactionCompleteActionField, _optionalData: OptionalTrackingData | null): IItemEvent {
        const event: IItemEvent = {
            event: 'purchase',
            ecommerce: {
                transaction_id: _actionField.transaction_id,
                storeMode: this.getStoreMode(_optionalData),
                affiliation: _actionField.affiliation,
                value: totalPriceFromProducts(_products),
                currency: serverContext.currency,
                requisition_number: _actionField.requisition_number,
                reference: _actionField.reference,
                case_number: _actionField.case_number,

                items: arrayToSlimTrackedProducts_G4(_products)
            }
        };
        return event;
    }

    createItemEvent(_products: TrackedProduct[], eventName: string): IItemEvent {
        let event: IItemEvent = {
            event: eventName,
            ecommerce: {
                currency: serverContext.currency,
                item_list_name: this.getFormattedListNameFromProduct(_products),
                value: totalPriceFromProducts(_products),
                items: arrayToSlimTrackedProducts_G4(_products)
            }
        };
        return event;
    }

    getSearchTerm(prodcuts: TrackedProduct[]): string | undefined {
        let trackingContext = this.getTrackingContext(prodcuts);
        let searchTerm = '';
        if (trackingContext && trackingContext!.searchTerm) {
            searchTerm = `${trackingContext!.searchTerm}|${this.lastTypedSearch}`;
        }
        return searchTerm === '' ? undefined : searchTerm;
    }

    getFixedUnitVariantName(isFixedUnit: boolean, fixedUnitValue: number | null, fixedUnitCode: string | null, unitCode: string): string {
        return isFixedUnit ? `fixed_${fixedUnitValue}_${fixedUnitCode}` : unitCode;
    }

    getStoreMode(data: OptionalTrackingData | null): string {
        if (!data) {
            return 'standard';
        }
        return data.storeId ? 'FlexBox_' + data.storeId : 'standard';
    }

    arrayHasData<T>(data: T[]): boolean | undefined {
        if (!data) {
            return undefined;
        }
        return data.length > 0;
    }

    priceHasScalePrice(data: PriceViewObject | null): boolean | undefined {
        if (!data) {
            return undefined;
        }
        return data.hasScalePrices;
    }

    hasDefaultUnit(defaultUnit: UnitViewObject): string | undefined {
        if (!defaultUnit) {
            return undefined;
        }
        return defaultUnit.unitCode;
    }

    getTrackingContext(products: TrackedProduct[]) {
        if (!products || products.length === 0 || products[0] === null) {
            return null;
        }
        return products[0].trackingContextObject;
    }

    getDeliveryType(delivery: DeliveryType): string {
        const trackingNameForUKNOWN = 'Intet valgt';
        return delivery === 0 ? trackingNameForUKNOWN : DeliveryType[delivery];
    }

    getFormattedListNameFromProduct(products: TrackedProduct[]): string | undefined {
        let context = this.getTrackingContext(products);

        if (!context) return undefined;

        return this.getFormattedListName(context.list);
    }

    getFormattedListName(list: string): string {
        if (list.indexOf('[pagetype]') > -1) {
            const pageType: string = store.getters['app/pageType'];
            list = list.replace('[pagetype]', pageType);
        }
        return list;
    }

    // TODO: Check if price, list and position are used in the tracking
    ToTrackedProduct<
        T extends
        AddToBasketRequest |
        AddLineItemViewObject |
        LineItemViewObject |
        ProductTileSlimViewObject |
        TrackProductClickViewModel |
        FavoriteOrderLineItemViewObject |
        ProductDetailsViewObject |
        OrderViewModel |
        ProductTileViewObject,
        Y extends
        PriceViewObject
    >(product: T | null,
        type: PRODUCT_TRACKING_TYPE,
        price: Y | null = null,
        optionalData: TrackedProduct | null = null
    ): TrackedProduct {
        let trackedProduct = new TrackedProduct();

        switch (type) {
            // AddToBasket
            case PRODUCT_TRACKING_TYPE.AddToBasketRequest:
                const AddToBasketRequest = product as AddToBasketRequest;
                if (AddToBasketRequest) {
                    trackedProduct = this.createTrackedProductFromAddToBasket(AddToBasketRequest, price);
                }
                break;
            case PRODUCT_TRACKING_TYPE.AddLineItemViewObject:
                const addLineItemViewObject = product as AddLineItemViewObject;
                if (addLineItemViewObject) {
                    trackedProduct = this.createTrackedProductFromAddLineItem(addLineItemViewObject, price);
                }
                break;
                // ProductClick
            case PRODUCT_TRACKING_TYPE.TrackProductClickViewModel:
                const trackProductClickViewModel = product as TrackProductClickViewModel;
                if (trackProductClickViewModel) {
                    trackedProduct = this.createTrackedProductFromTrackProductClick(trackProductClickViewModel, price);
                }
                break;
            case PRODUCT_TRACKING_TYPE.FavoriteOrderLineItemViewObject:
                const favoriteOrderLineItemViewObject = product as FavoriteOrderLineItemViewObject;
                if (favoriteOrderLineItemViewObject) {
                    trackedProduct = this.createTrackedProductFromFavoriteOrderLineItem(favoriteOrderLineItemViewObject, price);
                }
                break;
                // AddToBasket
            case PRODUCT_TRACKING_TYPE.ProductTileSlimViewObject:
                const productTileSlim = product as ProductTileSlimViewObject;
                if (productTileSlim) {
                    trackedProduct = this.createTrackedProductFromProductTileSlim(productTileSlim, price);
                }
                break;
            case PRODUCT_TRACKING_TYPE.LineItemViewObject:
                // Checkout && RemoveFromCart
                const lineItemProduct = product as LineItemViewObject;
                if (lineItemProduct) {
                    trackedProduct = this.createTrackedProductFromLineItem(lineItemProduct, price);
                }
                break;
            case PRODUCT_TRACKING_TYPE.ProductDetailsViewObject:
                // PDP
                const productDetails = product as ProductDetailsViewObject;
                if (productDetails) {
                    trackedProduct = this.createTrackedProductFromProductDetails(productDetails, price);
                }
                break;
            case PRODUCT_TRACKING_TYPE.ProductTileViewObject:
                // PDP
                const productTile = product as ProductTileViewObject;
                if (productTile) {
                    trackedProduct = this.createTrackedProductFromProductTile(productTile, price);
                }
                break;
        }

        if (optionalData) {
            trackedProduct = { ...trackedProduct, ...optionalData };
        }
        return trackedProduct;
    }

    ToTrackedProducts<Y extends PriceViewObject>(
        products: (any | null)[],
        type: PRODUCT_TRACKING_TYPE,
        price: Y | null = null,
        optionalData: TrackedProduct | null = null
    ): TrackedProduct[] {
        const trackedProducts: TrackedProduct[] = [];
        if (products && products.length) {
            products.forEach(product => {
                const trackedProduct = this.ToTrackedProduct(product, type, price, optionalData);
                trackedProducts.push(trackedProduct);
            });
        }

        return trackedProducts;
    }

    private createTrackedProductFromProductTile(product: ProductTileViewObject, price: PriceViewObject | null): TrackedProduct {
        const variant = product.variants[0];
        return new TrackedProduct({
            id: product.id,
            name: product.name,
            brand: variant.manufacturer,
            quantity: product.defaultQuantity,
            category: variant.primaryCategoryName,
            priceObject: price,
            climateCertificates: this.arrayHasData(variant ? variant.climateCertificates : []),
            trackingContextObject: variant.trackingContext
        });
    }

    private createTrackedProductFromProductDetails(product: ProductDetailsViewObject, price: PriceViewObject | null): TrackedProduct {
        const variant = product.variants && product.variants.length ? product.variants[0] : null;
        const brand = variant ? product.variants[0].manufacturer : '';
        const category = variant ? product.variants[0].primaryCategoryName : '';
        return new TrackedProduct({
            id: product.productId,
            quantity: product.defaultQuantity,
            name: product.displayName,
            brand: brand,
            category: category,
            priceObject: price,
            climateCertificates: this.arrayHasData(variant ? variant.climateCertificates : []),
            scalaPrice: this.priceHasScalePrice(price)
        });
    }

    private createTrackedProductFromFavoriteOrderLineItem(product: FavoriteOrderLineItemViewObject, price: PriceViewObject | null): TrackedProduct {
        return new TrackedProduct({
            id: product.productId,
            name: product.displayName,
            brand: product.manufacturer,
            quantity: product.quantity,
            category: product.primaryCategory ? product.primaryCategory.displayName : '',
            trackingContextObject: product.trackingContext,
            priceObject: price,
            scalaPrice: this.priceHasScalePrice(price)
        });
    }

    private createTrackedProductFromTrackProductClick(product: TrackProductClickViewModel, price: PriceViewObject | null): TrackedProduct {
        return new TrackedProduct({
            id: product.product.id,
            quantity: product.product.defaultQuantity,
            name: product.product.name,
            brand: product.variant.manufacturer,
            category: product.variant.primaryCategoryName,
            variant: this.hasDefaultUnit(product.product.defaultUnit),
            priceObject: price,
            trackingContextObject: product.variant.trackingContext,
            climateCertificates: this.arrayHasData(product.variant.climateCertificates),
            scalaPrice: this.priceHasScalePrice(price)
        });
    }

    private createTrackedProductFromAddLineItem(product: AddLineItemViewObject, price: PriceViewObject | null): TrackedProduct {
        return new TrackedProduct({
            id: product.productId,
            name: product.productName,
            brand: product.manufacturer,
            variant: product.unitCode,
            category: product.primaryCategoryName,
            quantity: product.quantity,
            priceObject: price,
            price: product.price,
            scalaPrice: this.priceHasScalePrice(price),
            trackingContextObject: product.trackingContext
        });
    }

    private createTrackedProductFromProductTileSlim(product: ProductTileSlimViewObject, price: PriceViewObject | null): TrackedProduct {
        return new TrackedProduct({
            id: product.variant.ids.variantId,
            name: product.name,
            brand: product.variant.manufacturer,
            quantity: product.defaultQuantity,
            category: product.variant.primaryCategoryName,
            variant: this.hasDefaultUnit(product.defaultUnit),
            priceObject: price,
            climateCertificates: this.arrayHasData(product.variant.climateCertificates),
            trackingContextObject: product.variant.trackingContext
        });
    }

    private createTrackedProductFromLineItem(product: LineItemViewObject, price: PriceViewObject | null): TrackedProduct {
        const fixedUnitValue = product.unit.isFixedUnit ? product.fixedUnit.value : null;
        const fixedUnitCode = product.unit.isFixedUnit ? product.fixedUnit.unitCode : null;
        return new TrackedProduct({
            id: product.productId,
            name: product.displayName,
            brand: product.manufacturer,
            variant: this.getFixedUnitVariantName(product.unit.isFixedUnit, fixedUnitValue, fixedUnitCode, product.unit.unitCode),
            category: product.primaryCategoryName,
            quantity: product.quantity,
            price: product.trackingUnitPrice,
            priceObject: price,
            lineReference: !!product.customerReference,
            climateCertificates: this.arrayHasData(product.climateCertificates),
            scalaPrice: this.priceHasScalePrice(price)
        });
    }

    private createTrackedProductFromAddToBasket(product: AddToBasketRequest, price: PriceViewObject | null): TrackedProduct {
        return new TrackedProduct({
            id: product.productId,
            quantity: product.quantity,
            priceObject: price,
            variant: this.getFixedUnitVariantName(product.isFixedUnit, product.fixedUnitValue, product.fixedUnitCode, product.unit),
            scalaPrice: this.priceHasScalePrice(price)
        });
    }
}
export default new ProductTracking();
