




















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
    BasketViewModel,
    LineItemViewObject,
    LoginInformation,
    UnitViewObject,
    UpdateLineItemRequest
} from '@/types/serverContract';
import { BasketGetter } from '@/store/basket';
import LineItem from '@/project/basket/LineItem.vue';
import serverContext from '@/core/serverContext.service';
import Api from '@/project/http/api';
import { AppGetter } from '@/store/app';
import { router } from '@/router';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import BasketHeader from '@/components/organisms/BasketHeader/BasketHeader.vue';
import BasketSummary from '@/project/basket/BasketSummary.vue';
import { FlexBoxGetter } from '@/store/flexBox';
import ToggleCtrl from '@/project/form/ToggleCtrl.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: {
        BasketSummary,
        BasketHeader,
        LineItem,
        ModalOverlay,
        SpinnerOverlay
    }
})
export default class BasketArchetype extends Vue {
    @AppGetter loginInformation!: LoginInformation;
    @AppGetter isPunchoutMode!: boolean;

    @BasketGetter basket!: BasketViewModel;

    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @FlexBoxGetter flexBoxPage!: string;
    @FlexBoxGetter flexBoxButtonText!: string;

    localToggledRight: boolean = false;
    customerReferenceHelpList: string[] = [];
    showConfirmationModal: boolean = false;
    cancelChange: boolean = false;
    pending: boolean = false;

    get showReferenceLines(): boolean {
        return this.localToggledRight;
    }

    get frontPageUrl(): string {
        return serverContext.sitePages.frontPage.url;
    }

    get checkoutPageUrl(): string {
        return serverContext.sitePages.checkoutPage.url;
    }

    get basketIsValid(): boolean {
        return this.basket && this.basket.isValid;
    }

    get punchOutCheckoutPageUrl(): string {
        return serverContext.sitePages.punchOutCheckoutPage.url;
    }

    get hasPunchOutProvider(): boolean | null {
        return (
            serverContext.loginInformation != null &&
            serverContext.loginInformation.punchOutProvider != null
        );
    }

    get hasCustomerReference(): boolean {
        const hasReference = this.basket.lineItems.some(
            lineItem =>
                lineItem.customerReference !== null &&
                lineItem.customerReference !== undefined &&
                lineItem.customerReference !== ''
        );
        return hasReference;
    }

    mounted() {
        Api.basket.removeAllowanceCharges();
    }

    updateToggle(toggledRight: boolean) {
        this.localToggledRight = toggledRight;
    }

    validateToggleRequest() {
        const BasketHasCustomerLineReference = this.basket.lineItems.some(
            lineItem =>
                lineItem.customerReference !== null &&
                lineItem.customerReference !== undefined &&
                lineItem.customerReference !== ''
        );

        if (!BasketHasCustomerLineReference) {
            const BasketLineRefenceToggle = this.$refs.toggleReferenceLines as ToggleCtrl;
            BasketLineRefenceToggle.updateValue(false);
            return;
        }
        this.showConfirmationModal = true;
    }

    onRefLinesDeletionModalConfirm() {
        const BasketLineRefenceToggle = this.$refs.toggleReferenceLines as ToggleCtrl;
        BasketLineRefenceToggle.updateValue(false);
        this.showConfirmationModal = false;
        this.deleteAllReference();
    }

    onRefLinesDeletionModalCancel() {
        this.showConfirmationModal = false;
    }

    toCheckout(): void {
        if (this.basket.lineItems.length) {
            router.push(this.checkoutPageUrl);
        }
    }

    customerReferenceHelpListUpdated() {
        const referenceArray = this.basket.lineItems
            .map(lineItem => lineItem.customerReference)
            .filter(reference => reference);
        return Array.from(referenceArray);
    }

    makeBasketLineItemPayload(lineItem: LineItemViewObject): UpdateLineItemRequest {
        const addCertificate = !!lineItem.certificate;
        const certificateCode: string | null = lineItem.certificateOptions
            ? lineItem.certificateOptions.certificateCode
            : null;
        const selectedUnit: UnitViewObject = lineItem.unit;
        const fixedUnitQty: number | null = lineItem.fixedUnit ? lineItem.fixedUnit.value : null;
        const stamping: boolean = lineItem.certificate ? lineItem.certificate.stamping : false;

        return {
            certificateCode: addCertificate ? certificateCode : null,
            fixedUnitValue: selectedUnit.isFixedUnit ? fixedUnitQty : null,
            fixedUnitCode: selectedUnit.isFixedUnit
                ? selectedUnit.fixedUnitQuantity.fixedUnitCode
                : null,
            isFixedUnit: selectedUnit.isFixedUnit ? selectedUnit.isFixedUnit : false,
            lineItemId: lineItem.lineIdentifier,
            quantity: lineItem.quantity,
            stamping: stamping,
            unit: selectedUnit.unitCode,
            customerReference: null
        };
    }

    async updateLineItem(updateLineItemRequest: UpdateLineItemRequest) {
        await Api.basket.updateBasketLineItem(updateLineItemRequest);
    }

    deleteAllReference() {
        // get all basket lineItems with a customerReference
        const lineItemsWithReference = this.basket.lineItems.filter(
            lineItem =>
                lineItem.customerReference !== null &&
                lineItem.customerReference !== undefined &&
                lineItem.customerReference !== ''
        );

        if (lineItemsWithReference.length > 0) {
            const apiPromises = lineItemsWithReference.map(lineItems =>
                this.updateLineItem(this.makeBasketLineItemPayload(lineItems))
            );
            this.pending = true;
            Promise.all(apiPromises)
                .then(() => {
                    this.localToggledRight = false;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.pending = false;
                    this.customerReferenceHelpListUpdated();
                });
        }
    }
}
