





























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { uuid } from '@/project/config/utilities';

/*
Inspired by this: https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
 */
@Component({
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    inject: ['$validator']
})
export default class RadioButton extends Vue {
    @Prop({
        type: String,
        default: ' '
    })
    public label!: string;

    @Prop({
        type: String,
        default: ''
    })
    description!: string;

    @Prop(String) public name!: string;

    @Prop(String) public constraints!: string;

    @Prop([String, Number, Boolean]) public value!: string | number;

    @Prop({ default: '' })
    public modelValue!: string;

    @Prop({
        type: Boolean,
        default: false
    })
    iconList!: boolean;

    @Prop({
        type: String,
        default: ''
    })
    icon!: string;

    get id() {
        return this.name + uuid();
    }

    get field() {
        return this.vvFields[this.name];
    }

    get error() {
        return this.vvErrors.first(this.name);
    }

    updateValue() {
        this.$emit('change', this.value);

        if (this.constraints && this.field.validated && this.field.invalid) {
            this.validate();
        }
    }

    get shouldBeChecked() {
        return this.modelValue === this.value;
    }

    get listeners() {
        const { change, ...listeners } = this.$listeners; // eslint-disable-line @typescript-eslint/no-unused-vars
        return listeners;
    }

    validate() {
        this.$validator.validate(this.name);
    }

    // From Vue-validate
    vvErrors!: any;
    vvFields!: any;
}
