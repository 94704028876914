import { render, staticRenderFns } from "./USPComponentCarousel.vue?vue&type=template&id=071cd914&scoped=true"
import script from "./USPComponentCarousel.vue?vue&type=script&lang=ts"
export * from "./USPComponentCarousel.vue?vue&type=script&lang=ts"
import style0 from "./USPComponentCarousel.vue?vue&type=style&index=0&id=071cd914&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "071cd914",
  null
  
)

export default component.exports