





















































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import urlHelper from './urlHelperFavoriteOrders.service';
import {
    FavoriteListMergeType,
    FavoriteOrderListItemViewObject,
    FavoriteOrdersViewModel,
    LoginInformation,
    SalesAccountViewObject
} from '@/types/serverContract';
import api from '@/project/http/api';
import { AppGetter } from '@/store/app';

@Component
export default class CopyFavoriteOrderForm extends Vue {
    sourceFavoriteOrderId: number | null = null;
    favoriteOrderMergeTypes = FavoriteListMergeType;
    selectedMergeType: FavoriteListMergeType = FavoriteListMergeType.New;
    newFavoriteOrderName: string = '';
    newFavoriteOrderIsShared: boolean = false;
    showFavoriteOrdersList: boolean = false;
    favoriteOrders: FavoriteOrdersViewModel | null = null;
    selectedFavoriteOrder: FavoriteOrderListItemViewObject | null = null;
    selectedAccount: SalesAccountViewObject | null = null;
    pending: boolean = false;

    @AppGetter loginInformation!: LoginInformation;

    get accountOptions(): SalesAccountViewObject[] | null {
        let salesAccounts = this.loginInformation.accounts.flatMap(x => x.salesAccounts);

        return salesAccounts;
    }

    get favoriteOrderOptions(): FavoriteOrderListItemViewObject[] {
        if (!this.sourceFavoriteOrderId) {
            return [];
        }

        const favoriteOrderOptions = this.favoriteOrders!.favoriteOrderList.filter(favoriteOrder => favoriteOrder.allowEdit && favoriteOrder.id !== this.sourceFavoriteOrderId).map(favoriteOrder => {
            return favoriteOrder;
        });
        return favoriteOrderOptions.length ? favoriteOrderOptions : [];
    }

    created() {
        this.sourceFavoriteOrderId = urlHelper.getFavoriteOrderId();
        this.selectedAccount = this.loginInformation.currentAccount;
        this.getFavoriteOrders(this.selectedAccount.soldToPartnerId);
    }

    @Watch('selectedMergeType')
    onChangeSelectedImportMergeType(): void {
        this.showFavoriteOrdersList = this.selectedMergeType !== FavoriteListMergeType.New;

        if (!this.showFavoriteOrdersList) {
            this.selectedFavoriteOrder = null;
        } else if (this.selectedFavoriteOrder === null) {
            if (this.favoriteOrderOptions.length > 0) {
                this.selectedFavoriteOrder = this.favoriteOrderOptions[0];
            }
        }
    }

    async getFavoriteOrders(soldToPartnerId: string) {
        if (soldToPartnerId) {
            api.favoriteOrder.getFavoriteOrdersForSalesAccount(soldToPartnerId).then(favoriteOrders => {
                this.favoriteOrders = favoriteOrders;
                if (this.favoriteOrderOptions.length > 0) {
                    this.selectedFavoriteOrder = this.favoriteOrderOptions[0];
                }
            });
        }
    }

    async validateForm() {
        if (this.selectedMergeType !== FavoriteListMergeType.New) {
            await (this.$refs['selectFavoriteOrder'] as Vue).$validator.validate();
        }
        await this.$validator.validate();
        await this.submitForm();
    }

    async submitForm() {
        if (this.vvErrors.items.length) {
            return;
        }

        if (!this.sourceFavoriteOrderId) {
            return;
        }

        if (!this.selectedAccount) {
            return;
        }

        this.pending = true;

        if (this.selectedMergeType === FavoriteListMergeType.New) {
            try {
                await api.favoriteOrder.copyFavoriteOrder({
                    sourceFavoriteOrderId: this.sourceFavoriteOrderId,
                    favoriteOrderName: this.newFavoriteOrderName,
                    shared: this.newFavoriteOrderIsShared,
                    targetSoldToPartnerId: this.selectedAccount.soldToPartnerId
                });
            } finally {
                this.$emit('copySuccess');
            }
        }

        if (this.selectedMergeType === FavoriteListMergeType.Merge) {
            if (!this.selectedFavoriteOrder) {
                return;
            }

            try {
                await api.favoriteOrder.mergeFavoriteOrder({
                    sourceFavoriteOrderId: this.sourceFavoriteOrderId,
                    targetFavoriteOrderId: this.selectedFavoriteOrder.id
                });
            } finally {
                this.$emit('copySuccess');
            }
        }
    }

    onChangeSelectedFavoriteOrder(event) {
        this.selectedFavoriteOrder = this.favoriteOrderOptions!.find(favoriteOrder => favoriteOrder.id === parseInt(event.target.value, 10))!;
    }

    onChangeSelectedAccount(event) {
        let soldToPartnerId = event.target.value;

        if (soldToPartnerId) {
            let selectedAccount = this.accountOptions!.find(account => account.soldToPartnerId === soldToPartnerId);
            if (selectedAccount) {
                this.selectedAccount = selectedAccount;
            }
            this.getFavoriteOrders(soldToPartnerId);
        }
    }

    // From Vue-validate
    private vvErrors!: any;
}
