
import { Component, Prop } from 'vue-property-decorator';
import StatusLabel from './StatusLabel.vue';

export enum StatusId {
    // REcieved
    Cancelled = 'CA',
    // Partially Sent
    Expedited = 'EX',
    NaN = 'NaN',
    // SEnt
    PartiallySent = 'PS', // EXpedited
    Recieved = 'RE',
    // No value
    Sent = 'SE' // CAncled
}

class OrderStyle {
    color: string = '';
    icon: string = '';

    constructor(data: { color: string; icon: string}) {
        this.color = data.color;
        this.icon = data.icon;
    }
}

@Component({
})

export default class OrderStatusLabel extends StatusLabel {
    @Prop({ default: StatusId.Sent }) statusId;

    SentStyle: OrderStyle = new OrderStyle({
        color: 'bg-green-050-transparent',
        icon: 'text-blue-690 sanicon-ico-small-delivery-truck'
    });

    PartiallySentStyle: OrderStyle = new OrderStyle({
        color: 'bg-grey-200',
        icon: 'text-blue-690 sanicon-ico-check-mark-bold'
    });

    ExpeditedStyle: OrderStyle = new OrderStyle({
        color: 'bg-grey-200',
        icon: 'text-blue-690 sanicon-ico-small-parcel'
    });

    RecievedStyle: OrderStyle = new OrderStyle({
        color: 'bg-grey-200',
        icon: 'text-blue-690 sanicon-ico-check-mark-bold'
    });

    CancelledStyle: OrderStyle = new OrderStyle({
        color: 'bg-red-050-transparent',
        icon: 'text-blue-690 sanicon-ico-close-bold'
    });

    StyleFromStatus =
    {
        [StatusId.Sent]: this.SentStyle,
        [StatusId.PartiallySent]: this.PartiallySentStyle,
        [StatusId.Expedited]: this.ExpeditedStyle,
        [StatusId.Recieved]: this.RecievedStyle,
        [StatusId.Cancelled]: this.CancelledStyle
    };

    get setBgClass() {
        return this.StyleFromStatus[this.statusId].color;
    }

    get setIconClass() {
        return this.StyleFromStatus[this.statusId].icon;
    }
}
