




















import Vue from 'vue';
import Component from 'vue-class-component';
import { ClientMessage } from '@/types/serverContract';
import { Prop } from 'vue-property-decorator';

@Component
export default class ErrorMessages extends Vue {
    @Prop({
        type: Array
    }) messages!: ClientMessage[];
}
