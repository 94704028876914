











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import {
    ProductTileSlimViewObject,
    RecommendationParameter,
    RecommendationProductRequest,
    RecommendationViewModel
} from '@/types/serverContract';
import ProductCarousel from '../products/carousels/ProductCarousel.vue';

@Component({
    components: {
        ProductCarousel
    }
})
export default class RaptorProductsView extends Vue {
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: String, required: true }) method!: string;
    @Prop({ type: Array, required: true }) parameters!: RecommendationParameter[];
    @Prop({ type: Number, default: 10 }) maxElements!: number;
    @Prop({ default: true, type: Boolean }) showProductAttributes!: boolean;

    recommendationViewModel: RecommendationViewModel<ProductTileSlimViewObject> | null = null;

    get recommendationElements(): ProductTileSlimViewObject[] | null {
        return this.recommendationViewModel ? this.recommendationViewModel.elements : null;
    }

    created() {
        this.getRecommendationProducts();
    }

    async getRecommendationProducts() {
        const payload: RecommendationProductRequest = {
            maxElements: this.maxElements,
            method: this.method,
            parameters: this.parameters
        };
        this.recommendationViewModel = await Api.recommendation.recommendationProducts(payload);
    }
}
