










































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { FirstLevelCategoryViewObject } from '@/types/serverContract';
import ButtonCheckBox from '@/project/form/ButtonCheckBox.vue';
import { ProjectFolderOrderHistoryMaintenanceMixin } from '@/project/myServices/projectFolderOrderHistoryMaintenanceMixin.mixin';
import ProjectFolderOrderHistoryCategory from '@/project/myServices/ProjectFolderOrderHistoryCategory.vue';

@Component({
    components: { ProjectFolderOrderHistoryCategory, ButtonCheckBox }
})
export default class ProjectFolderOrderHistoryFirstLevel extends Mixins<ProjectFolderOrderHistoryMaintenanceMixin>(ProjectFolderOrderHistoryMaintenanceMixin) {
    @Prop({
        type: Object,
        required: true
    }) firstLevelCategory!: FirstLevelCategoryViewObject;

    isChecked: boolean = true;

    setParentChecked(isCheckedFromChild: boolean): void {
        this.isChecked = isCheckedFromChild;
    }

    setChecked(): void {
        this.isChecked = !this.isChecked;
        this.setChildChecked();
        this.sendDocList();
    }

    sendDocList(): void {
        this.firstLevelCategory.categories.forEach((category) => {
            category.variantsWithDocuments.forEach((variant) => {
                variant.documents.forEach((document) => {
                    if (this.isChecked) {
                        this.addMaintenanceDocument(document);
                    } else {
                        this.deleteMaintenanceDocument(document);
                    }
                });
            });
        });
    }

    setChildChecked(): void {
        this.firstLevelCategory.categories.forEach((cat, ix) => {
            const categoryComp: any = this.$refs[`categoryComp--${ix}`];

            categoryComp[0].forceCategoryCheck(this.isChecked);
        });
    }
}
