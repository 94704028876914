





























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: { SpinnerOverlay }
})
export default class ForgotPasswordPanel extends Vue {
    @Prop({ type: Boolean, default: false }) fromNavigation!: boolean;

    autofocusEnabledEmail = true;
    autofocusEnabledUsername = false;
    success = false;
    usernameRequired = false;
    username = '';
    email = '';
    pending = false;
    timeout: number | null = null;
    screenWidth: number = window.innerWidth;

    created() {
        window.addEventListener('resize', this.handleResize);
    }

    destroyed() {
        window.removeEventListener('resize', this.handleResize);

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    submit() {
        if (!this.success) {
            this.forgotPassword();
        } else {
            this.$emit('done');
        }
    }

    async forgotPassword() {
        this.pending = true;
        this.success = false;
        this.autofocusEnabledUsername = false;

        try {
            const result = await Api.authentication.forgotPassword(this.email, this.username, 'forgot-pw');
            this.usernameRequired = result.hasMultipleAccounts;
            this.success = result.passwordWasResend;

            if (this.usernameRequired) {
                this.timeout = setTimeout(() => {
                    this.autofocusEnabledUsername = true;
                }, 200);
            }
        } catch (e) {
            // Ok for validation-msgs.
        } finally {
            this.pending = false;
        }
    }

    get subHeaderText() {
        if (this.isSmallScreen()) {
            return this.$tr(this.$labels.Views.Login.ForgotPassword.ShorterSubHeader);
        }
        return this.$tr(this.$labels.Views.Login.ForgotPassword.SubHeader);
    }

    get SmallScreenCheck() {
        if (this.isSmallScreen()) {
            return true;
        }
        return false;
    }

    isSmallScreen() {
        const breakpoint = 769;
        return this.screenWidth < breakpoint;
    }

    handleResize() {
        this.screenWidth = window.innerWidth;
    }
}
