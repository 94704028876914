









































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ProductImageViewObject } from '@/types/serverContract';
import FlickityWrap from '@/core/FlickityWrap.vue';

@Component
export default class ProductDetailsImagesCarousel extends Vue {
    @Prop({ required: true, type: Array }) productImages!: ProductImageViewObject[];
    @Prop({ required: true, type: String }) altText!: string;
    @Prop({ required: true, type: Number }) activeIndex!: number;
    @Prop({ default: false, type: Boolean }) isOverlay!: boolean;

    activeSlide: number = this.activeIndex;
    flickityOptions = {
        imagesLoaded: true,
        prevNextButtons: this.hasMultipleImages,
        pageDots: this.hasMultipleImages,
        initialIndex: this.activeSlide
    };

    get hasMultipleImages(): boolean {
        return this.productImages && this.productImages.length > 1;
    }

    get hasOneImage(): boolean {
        return this.productImages && this.productImages.length === 1;
    }

    $refs!: {
        flickity: FlickityWrap;
    };

    @Watch('activeIndex')
    private onActiveIndexChange(index: number) {
        (this.$refs.flickity as any).select(index, false, false);
    }

    onChange(index: number): void {
        this.$emit('sliderChange', index);
    }

    productImageUrl(image: ProductImageViewObject): string {
        return this.isOverlay ? image.enlargedImageUrl : image.productImageUrl;
    }
}
