






























































import Vue from 'vue';
import {
    Component,
    Prop
} from 'vue-property-decorator';
import { HelpDeskUserViewObject } from '@/types/serverContract';
import HelpDeskUserLine from '@/project/helpDesk/HelpDeskUserLine.vue';

@Component({
    components: {
        HelpDeskUserLine
    }
})

export default class HelpDeskUsers extends Vue {
    @Prop({
        required: true,
        type: Array
    }) users!: HelpDeskUserViewObject[];

    @Prop({
        default: '',
        type: String
    }) searchSoldToPartnerId!: string;

    selectUser(selectedUser: HelpDeskUserViewObject): void {
        this.$emit('userSelected', selectedUser);
    }
}
