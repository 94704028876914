



































import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import {
    CertificateViewObject
} from '@/types/serverContract';
import AddToFavoriteOrderForm from '@/project/favoriteOrders/AddToFavoriteOrderForm.vue';
import { IUnitData } from '@/project/products/productHelper.utils';

@Component({
    components: {
        ModalOverlay,
        AddToFavoriteOrderForm
    }
})
export default class AddToFavoriteOrder extends Vue {
    @Prop({
        required: true,
        type: Array
    }) orderableUnits!: IUnitData[];

    @Prop({
        required: true,
        type: String
    }) variantId!: string;

    @Prop({
        required: true,
        type: String
    }) selectedUnit!: string;

    @Prop({
        required: true,
        type: String
    }) variantName!: string;

    @Prop({
        default: () => {},
        type: Object
    }) certificate!: CertificateViewObject;

    @Prop({
        default: false,
        type: Boolean
    }) setCertificate!: boolean;

    showModal: boolean = false;

    toggleModal(): void {
        this.showModal = !this.showModal;
    }
}
