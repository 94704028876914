
























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: { SpinnerOverlay }
})
export default class MustChangePasswordPanel extends Vue {
    @Prop({
        type: String,
        required: true
    }) username!: string;

    showSpinner = false;
    newPassword = '';
    confirmPassword = '';

    get isPasswordMismatch(): boolean {
        if (this.newPassword !== this.confirmPassword) {
            return true;
        }
        return false;
    }

    get mustMatchMessage() {
        return this.$tr(this.$labels.Views.Login.ChangePassword.PasswordMustMatch);
    }

    $refs!: {
        newPasswordInput: HTMLInputElement;
    };

    async changePassword() {
        if (!this.validatePasswordMatch()) return;

        try {
            this.showSpinner = true;
            await Api.authentication.mustChangePassword(this.newPassword, this.confirmPassword, this.username, 'must-change-pw');
        } catch (e) {
            // Ok for validation-msgs.
        } finally {
            this.showSpinner = false;
        }
    }

    focusNewPasswordInput() {
        this.$refs.newPasswordInput.focus();
    }

    validatePasswordMatch(): boolean {
        return this.newPassword === this.confirmPassword;
    }

    private vvErrors!: any;
}
