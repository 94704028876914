import { MetadataViewModel } from '@/types/serverContract';
import $ from 'cash-dom';

class DomService {
    private static VIEWPORT = 'viewport';
    private static TITLE = 'title';
    private static KEYWORDS = 'keywords';
    private static DESCRIPTION = 'description';
    private static ROBOTS = 'robots';

    public toggleElementClass(selector: string, targetClass: string) {
        const element = document.querySelector(selector);
        if (!element) {
            return;
        }
        const hasClass = element.classList.contains(targetClass);
        hasClass ? element.classList.remove(targetClass) : element.classList.add(targetClass);
    }

    public updateMetaData(metadata: MetadataViewModel) {
        this.setCanonicalUrl(metadata.url);
        this.setMetaDescription(metadata.description);
        this.setPageTitle(metadata.pageTitle);
    }

    public setPageTitle(title: string): void {
        document.title = title;
    }

    public setMetaDescription(description: string) {
        return this.setContent(DomService.DESCRIPTION, description);
    }

    public setMetaKeywords(keywords: string) {
        return this.setContent(DomService.KEYWORDS, keywords);
    }

    public getMetaViewport() {
        return this.getMetaElement(DomService.VIEWPORT).content;
    }

    public updateCheckoutView(isCheckoutView): void {
        $('html').toggleClass('checkout-view', isCheckoutView);
    }

    public setMetaViewport(content: string) {
        return this.setContent(DomService.VIEWPORT, content);
    }

    public setCanonicalUrl(canonicalUrl: string): void {
        const canonicalUrlElement = this.getCanonicalUrlElement();
        canonicalUrlElement.href = this.sanitizeInput(canonicalUrl);
    }

    private setContent(tagName: string, content: string): HTMLMetaElement {
        const tag = this.getMetaElement(tagName);
        tag.content = this.sanitizeInput(content);
        return tag;
    }

    private sanitizeInput(input: string): string {
        return input || '';
    }

    private getCanonicalUrlElement(): HTMLLinkElement {
        return this.getOrCreate<HTMLLinkElement>('link[rel=canonical]', () => {
            const element = document.createElement('link');
            element.rel = 'canonical';
            const headElement = this.getHeadElement();
            if (headElement) {
                headElement.appendChild(element);
            }
            return element;
        });
    }

    private getHeadElement(): HTMLHeadElement | null {
        return document.querySelector('head');
    }

    private getMetaElement(tagName: string): HTMLMetaElement {
        return this.getOrCreate<HTMLMetaElement>('meta[name="' + tagName + '"]', () => {
            const element = document.createElement('meta');
            element.name = tagName;
            const headElement = this.getHeadElement();
            if (headElement) {
                headElement.appendChild(element);
            }
            return element;
        });
    }

    private getOrCreate<TElement extends HTMLElement>(selector: string, create?: () => TElement): TElement {
        const element = document.querySelector(selector) as TElement;
        if (element) {
            return element;
        }
        if (create) {
            return create();
        }
        throw new Error(`Provide create param when element ${selector} cannot be found.`);
    }
}

export default new DomService();
