


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import sortBy from 'lodash-es/sortBy';
import keys from 'lodash-es/keys';
import zipObject from 'lodash-es/zipObject';
import map from 'lodash-es/map';
import breakpointService from '../breakpoints/breakpoints.service';
import loggingService from '../../logging.service';

export interface IResponsiveImageWidthOnScreenConfig {
    [key: string]: number;
}

@Component
export default class ResponsiveImage extends Vue {
    @Prop({ type: String, default: '' })
    private imageUrl!: string;

    @Prop({ type: [Object, Number], default: 100 })
    private widthOnScreen!: IResponsiveImageWidthOnScreenConfig | number;

    @Prop({ type: Number, default: 16 / 9 })
    private aspectRatio!: number;

    @Prop({ type: Number, default: 200 })
    private offset!: number;

    @Prop({ type: Boolean, default: false })
    private lowsrc!: boolean;

    @Prop({ type: String, default: 'transparent' })
    private bgColor!: string;

    @Prop({ type: Number, default: 75, validator: (value: number) => value >= 1 && value <= 100 })
    private quality!: string;

    @Prop({ type: String, default: '' })
    private alt!: string;

    @Prop({ type: String, default: 'crop', validator: (value: string) => ['crop', 'pad'].indexOf(value) !== -1 })
    private mode!: string;

    @Prop({ type: String, default: 'jpg', validator: (value: string) => ['jpg', 'png'].indexOf(value) !== -1 })
    private format!: string;

    private sizes: Array<number> = [320, 640, 1024, 1600];

    private lazyInitialized: boolean = false;
    private lazyLoaded: boolean = false;
    private hasSrcsetSupport: boolean = true;

    private observer: IntersectionObserver | undefined;

    private get src(): string {
        if (!this.hasSrcsetSupport) {
            return this.fallbacksrc;
        }

        let lowSrc = '';

        if (this.lowsrc) {
            let lowSrcWidth = 100;
            let lowSrcQuality = 5;
            lowSrc = this.formatImageUrl(lowSrcWidth, this.heightFromWidth(lowSrcWidth), lowSrcQuality);
        } else {
            lowSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        }

        return lowSrc;
    }

    private get srcset(): string {
        let srcset = '';
        if (this.lazyInitialized) {
            srcset = this.sizes
                .map(width => {
                    return this.formatImageUrl(width, this.heightFromWidth(width)) + ` ${width}w`;
                })
                .join(', ');
        }
        return srcset;
    }

    private get fallbacksrc(): string {
        let width = this.sizes[this.sizes.length - 2]; // second largest
        return this.formatImageUrl(width, this.heightFromWidth(width));
    }

    private get sizesFromWidthOnScreen(): string {
        let sizes = '';

        if (typeof this.widthOnScreen === 'object') {
            sizes = map(this.sortConfigBySize(this.widthOnScreen), (width, screen) => {
                if (!breakpointService.getBreakpoint(screen)) {
                    let errorMessage = `ResponsiveImage: Breakpoint ${screen} is not defined for imageUrl ${
                        this.imageUrl
                    }`;
                    loggingService.debug(errorMessage);
                    loggingService.warn(errorMessage);
                }
                return `(min-width: ${breakpointService.getBreakpoint(screen).min}px) ${width}vw`;
            }).join(',');
            sizes += ', 100vw';
        } else {
            sizes = `${this.widthOnScreen}vw`;
        }

        return sizes;
    }

    private get style(): object {
        let padding = `${(100 / this.aspectRatio).toFixed(2)}%`;
        let style = { paddingTop: padding, background: `#${this.normalizedBgColor}` };

        return style;
    }

    private heightFromWidth(width) {
        return Math.round(width / this.aspectRatio);
    }

    private formatImageUrl(width: number, height: number, quality?: number): string {
        const firstSeparator = !this.imageUrl || this.imageUrl.indexOf('?') === -1 ? '?' : '&';
        return `${this.imageUrl}${firstSeparator}format=${
            this.format
        }&width=${width}&height=${height}&quality=${quality || this.quality}&mode=${this.mode}&bgcolor=${
            this.normalizedBgColor === 'transparent' ? '' : this.normalizedBgColor
        }`;
    }

    private sortConfigBySize(config: IResponsiveImageWidthOnScreenConfig) {
        let sortedKeys = sortBy(keys(config), key => {
            return config[key];
        });

        return zipObject(
            sortedKeys,
            map(sortedKeys, function(key) {
                return config[key];
            })
        );
    }

    private handleLoad(): void {
        if (this.lazyInitialized) {
            this.lazyLoaded = true;
        }
    }

    private get normalizedBgColor(): string {
        return this.bgColor.replace('#', '');
    }

    private observe(): void {
        let options = { rootMargin: `${this.offset}px` };
        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.lazyInitialized = true;
                    this.unObserve();
                }
            });
        }, options);
        this.observer.observe(this.$el);
    }

    private unObserve(): void {
        if (this.observer) {
            this.observer.unobserve(this.$el);
            this.observer.disconnect();
        }
    }

    private mounted(): void {
        this.hasSrcsetSupport = this.$refs.image ? 'sizes' in this.$refs.image : true;
        this.hasSrcsetSupport && this.observe();
    }

    private destroyed() {
        this.unObserve();
    }
}
