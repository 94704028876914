




















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { OrderHistoryListViewModel } from '@/types/serverContract';
import CertificateOrderHistorySearchForm from '@/project/myServices/CertificateOrderHistorySearchForm.vue';
import CertificateOrderHeader from '@/project/myServices/CertificateOrderHeader.vue';

@Component({
    components: { CertificateOrderHeader, CertificateOrderHistorySearchForm }
})
export default class CertificateOrderHistory extends Vue {
    certificateOrders: OrderHistoryListViewModel | null = null;

    updateOrderHistoryOrders(searchOrderHistoryResult: OrderHistoryListViewModel): void {
        this.certificateOrders = searchOrderHistoryResult;
    }
}
