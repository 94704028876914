






























































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
    ProductTileViewObject,
    PriceViewObject,
    BasketViewModel,
    ProductTileTrackingContext
} from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import ProductCo2Info from '@/project/products/ProductCo2Info.vue';
import ProductPrices from '@/project/products/ProductPrices.vue';
import ProductVariantPicker from '@/project/products/ProductVariantPicker.vue';
import ProductTileSkeletonAddToBasket from '@/project/shared/skeleton/ProductTile/ProductTileSkeletonAddToBasket.vue';
import ProductTileSkeletonPrice from '@/project/shared/skeleton/ProductTile/ProductTileSkeletonPrice.vue';
import ProductAddToBasket from '@/project/products/ProductAddToBasket.vue';
import ProductVariantIds from '@/project/products/ProductVariantIds.vue';
import ProductTileWrapper from '@/project/products/ProductTileWrapper.vue';
import Accordion from '@/project/shared/Accordion.vue';
import Api from '@/project/http/api';
import { BasketGetter } from '@/store/basket';
import scrollService from '@/core/scroll/scroll.service';

@Component({
    components: {
        ProductVariantIds,
        ProductAddToBasket,
        ProductTileSkeletonPrice,
        ProductTileSkeletonAddToBasket,
        ProductPrices,
        ProductVariantPicker,
        ProductCo2Info,
        ProductTileWrapper,
        Accordion
    }
})
// Changes made to this component should also be made to SearchProductNudgingTile.vue
export default class ProductNudgingTile extends Vue {
    @Prop({
        required: false,
        type: Object
    })
    trackingContext!: ProductTileTrackingContext;

    @Prop({
        required: true,
        type: String
    }) productId!: string;

    @Prop({
        default: false,
        type: Boolean
    }) isWide!: boolean;

    @Prop({
        required: false,
        type: String
    }) raptorMethod!: string;

    @Prop({
        required: false,
        type: String
    }) raptorEvent!: string;

    @Prop({
        default: false,
        type: Boolean
    }) selectDefaultVariant!: boolean;

     @Prop({
         default: false,
         type: Boolean
     }) defaultOpen!: boolean;

     @Prop({
         default: false,
         type: Boolean
     }) nudgingShow!: boolean;

    @Prop({
        default: false,
        type: Boolean
    }) removeWhenAdded!: boolean;

    @AppGetter pageType!: string;
    @AppGetter isLoggedIn!: boolean;
    @BasketGetter basket!: BasketViewModel;
    show: boolean = false;
    customShow: boolean = this.nudgingShow;
    recommendedProducts: ProductTileViewObject[] | null = null;
    recommendedProduct: ProductTileViewObject | null = null;
    alreadyLoaded: boolean = false;

    get nudgingTrackingContext() {
        if (this.trackingContext == null) {
            return null;
        }
        let trackingContextCopy: ProductTileTrackingContext = { ...this.trackingContext };
        trackingContextCopy.list = this.trackingContext.list.replace(/\/[^/]*\/?$/, '/nudgingForSales');
        return trackingContextCopy;
    }

    onCustomShowUpdate(newValue) {
        this.$emit('update:nudgingShow', newValue);
    }

    @Watch('basket', { immediate: true }) // Wait for the basket to load before checking if the product is in it. If the basket is already loaded, check immediately.
    onBasketChanged() {
        if (this.isLoggedIn && !this.alreadyLoaded) {
            Api.recommendedProducts.getRecommendedProducts([this.productId]).then((recommendedProductsViewModel) => {
                this.alreadyLoaded = true;
                // take the first product recommended for the current product put into basket, which is not already in the basket
                if (recommendedProductsViewModel && recommendedProductsViewModel.length > 0 && recommendedProductsViewModel[0].recommendedProducts.length > 0) {
                    const notInBasket = recommendedProductsViewModel[0].recommendedProducts.filter(product => !this.isInBasket(product));
                    this.recommendedProduct = notInBasket[0];
                }
            }).finally(() => {
                setTimeout(() => {
                    // say to the parent component if we have a recommended product
                    this.$nextTick(() => {
                        this.$emit('hasRecommendedProduct', !!this.recommendedProduct);
                    });
                }, 200);
            });
        }
    }

    isInBasket(product: ProductTileViewObject) {
        return this.basket.lineItems.filter(lineItem => lineItem.productId === product.id).length > 0;
    }

    toggleShow() {
        const accordion = this.$refs.Accordion as Accordion;
        accordion.toggleShow();
    }

    onAddedToBasket() {
        if (this.removeWhenAdded) {
            this.$emit('removeNudging', true);
            this.scrollToJustAddedProduct();
        }
    }

    scrollToJustAddedProduct() {
        const AccordionComponent = this.$refs.Accordion as Accordion;
        const AccordionElement = AccordionComponent.$el as HTMLElement;
        const ScrollTargetContainer = AccordionElement.closest('.list-reset') as HTMLElement;

        // scroll to the top to see the new added product
        this.$nextTick(() => {
            scrollService.scrollToElement(ScrollTargetContainer, -100, 200);
        });
    }

    async priceLoaded(price: PriceViewObject) {
        this.$emit('priceLoaded', price);
    }
}
