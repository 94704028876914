



























































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import FavoritesOrdersListModal from '@/project/favoriteOrders/FavoritesOrdersListModal.vue';
import Accordion from '@/project/shared/Accordion.vue';
import { IChooseFavoriteOrderListModel } from '@/project/favoriteOrders/addToFavoriteOrder.service';

@Component({
    components: {
        FavoritesOrdersListModal,
        Accordion
    }
})
export default class AddToFavoriteOrderCreateOrChooseOrder extends Vue {
    favoriteOrderId: string = '';
    shared: boolean = false;
    newFavoriteName: string = '';
    accountHasNoFavoriteOrders: boolean = false;
    addToFavoriteOrderPayload: IChooseFavoriteOrderListModel = {
        newFavoriteOrderName: '',
        shared: false,
        targetFavoriteOrderId: null,
        activeTab: 'existingList'
    };

    @Watch('addToFavoriteOrderPayload', {
        deep: true
    })
    onPayloadChange(): void {
        const payload: IChooseFavoriteOrderListModel = {
            newFavoriteOrderName: this.addToFavoriteOrderPayload.activeTab === 'newList' ? this.addToFavoriteOrderPayload.newFavoriteOrderName : '',
            shared: this.addToFavoriteOrderPayload.activeTab === 'newList' ? this.addToFavoriteOrderPayload.shared : false,
            targetFavoriteOrderId: this.addToFavoriteOrderPayload.activeTab === 'existingList' ? this.addToFavoriteOrderPayload.targetFavoriteOrderId : null,
            activeTab: this.addToFavoriteOrderPayload.activeTab
        };
        this.$emit('change', payload);
    }

    public async validateForm(): Promise<boolean> {
        return this.$validator.validate();
    }

    updateFavoriteOrderId(favoriteOrderId: string): void {
        this.addToFavoriteOrderPayload.targetFavoriteOrderId = parseInt(favoriteOrderId);
    }

    onNoFavoriteOrdersOnAccount(): void {
        this.addToFavoriteOrderPayload.activeTab = 'newList';
        this.accountHasNoFavoriteOrders = true;
    }

    beforeEnter(el) {
        el.style.height = '0';
    }

    enter(el) {
        el.style.height = el.scrollHeight + 'px';
    }

    afterEnter(el) {
        // Set height to auto when expanded, this will allow resize of element and not hiding content.
        el.style.overflow = 'visible';
        el.style.height = 'auto';
    }

    beforeLeave(el) {
        // As height is set to auto, set height to a pixel value
        el.style.overflow = 'hidden';
        el.style.height = el.scrollHeight + 'px';
    }

    leave(el) {
        this.$nextTick(() => {
            // Trigger the animation with next tick.
            el.style.height = '0';
        });
    }
}
