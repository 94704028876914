import { router } from '@/router';
import { store } from '@/store';
import {
    BaseApiResponse,
    DeliveryType,
    LineItemViewObject,
    LoginInformation,
    ProductSearchResponse,
    ProductTileSlimViewObject,
    ProductTileTrackingContext,
    FavoriteOrderLineItemViewObject,
    SearchAsYouTypeResponse
} from '@/types/serverContract';
import { termKey } from '@/project/search/urlHelper.service';
import serverContext from '@/core/serverContext.service';
import { Route } from 'vue-router';
import { AxiosResponse } from 'axios';
// @ts-ignore
import { searchExperience } from '@/project/search/SearchFeedback.vue';
import productTrackingService from './productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '../enums/enums';

declare global {
    interface Window {
        dataLayer: Array<DataLayerEntry>;
    }
}

export interface DataLayerEntry { [key: string]: any }

export enum SignUpTypesEnum {
    ExtraWebUser = 'Extra web user',
    NewCustomer = 'New customer'
}

export class Tracking {
    constructor() {
        router.beforeEach((_to, _from, next) => {
            if (this.batchedFavoriteOrderLineItem.length) {
                productTrackingService.TrackProducts(
                    PRODUCT_TRACKING_EVENT.ProductImpression,
                    productTrackingService.ToTrackedProducts(
                        [...this.batchedFavoriteOrderLineItem],
                        PRODUCT_TRACKING_TYPE.FavoriteOrderLineItemViewObject
                    )
                );
                this.batchedFavoriteOrderLineItem = [];
            }

            if (this.batchedProductTileSlim.length) {
                productTrackingService.TrackProducts(
                    PRODUCT_TRACKING_EVENT.ProductImpression,
                    productTrackingService.ToTrackedProducts(
                        [...this.batchedProductTileSlim],
                        PRODUCT_TRACKING_TYPE.ProductTileSlimViewObject
                    )
                );
                this.batchedProductTileSlim = [];
            }
            next();
        });
    }

    public getAndTrackImpressionsFromSlimTileProducts(products: ProductTileSlimViewObject[]) {
        this.batchedProductTileSlim = [...this.batchedProductTileSlim, ...products];
    }

    public getAndTrackImpressionsFromFavoriteOrderLineItem(products: FavoriteOrderLineItemViewObject[]) {
        this.batchedFavoriteOrderLineItem = [...this.batchedFavoriteOrderLineItem, ...products];
    }

    batchedProductTileSlim: ProductTileSlimViewObject[] = []
    batchedFavoriteOrderLineItem: FavoriteOrderLineItemViewObject[] = []

    private lastTypedSearch: string = '';
    private lastSearchedTerm: string = '';

    public track(trackingEvent: DataLayerEntry, clearFlag: boolean = false): void {
        if (!window.dataLayer) {
            return;
        }

        if (clearFlag) {
            window.dataLayer.push({ ecommerce: null });
        }

        window.dataLayer.push(trackingEvent);
    }

    public trackNamedEvent(eventName: INamedTrackingEvents, data?: any): void{
        eventName && this[eventName] && this[eventName](data);
    }

    public initTracking(): void {
        router.afterEach((to: Route, from: Route) => {
            if (to.path !== from.path) {
                setTimeout(this.clearLastSearchedTerm.bind(this), 100);
            }
        });
    }

    public trackUserData(): void {
        const info: LoginInformation = store.getters['app/loginInformation'];
        const event: IUserDataTrackingEvent = {
            event: 'variables',
            loggedinState: info != null ? 'loggedin' : 'loggedout',
            audiences: serverContext.audiences,
            ...(info && {
                userId: info.userId,
                ...(info.store && { AccountStore: info.store.id }),
                ...(info.primaryContact && { PrimaryContact: info.primaryContact.initials }),
                ...(info.currentAccount && {
                    CurrentAccount: info.currentAccount.soldToPartnerId,
                    AccountCategory: info.currentAccount.customerGroup,
                    BillToPartnerId: info.currentAccount.billToPartnerId,
                    PriceGroup: info.currentAccount.priceGroup,
                    ZAContactTypes: info.currentAccount.zaContactTypes,
                    storeMode: info.basketStoreId ? 'FlexBox_' + info.basketStoreId : 'standard'
                })
            })
        };
        this.track(event);
    }

    public trackPageView(isNotFound: boolean): void{
        this.track({
            event: 'pageview'
        });
        if (isNotFound) {
            this.trackNotFound();
        }
    }

    public trackNotFound(): void{
        const event: ISimpleEvent = {
            event: '404'
        };
        this.track(event);
    }

    public trackSignup(signupType: SignUpTypesEnum): void {
        this.track({ event: 'sign_up', signupType });
    }

    public trackCheckoutToggleInformation(wasShownInitially: boolean): void{
        const eventAction = wasShownInitially ? 'Luk autoaaben' : 'Aaben';
        const event: IClientTrackingEvent = {
            event: 'Deliveryoverview',
            eventCategory: 'Leveringsoversigt',
            eventAction
        };
        this.track(event);
    }

    public trackStartFlexBox(didActivateFlexBoxMode: boolean): void {
        const eventAction = 'Activate';
        const eventLabel = didActivateFlexBoxMode ? 'Yes' : 'No';
        const event: IClientTrackingEvent = {
            event: 'Activate FlexBox overlay',
            eventCategory: 'FlexBox',
            eventLabel: eventLabel,
            eventAction: eventAction
        };
        this.track(event);
    }

    public trackFrontpageNavigation(eventAction: string, eventLabel: string): void {
        const event: IClientTrackingEvent = {
            event: 'GAEvent',
            eventAction: eventAction,
            eventCategory: 'Forsidenavigation',
            eventLabel: eventLabel
        };

        this.track(event);
    }

    public trackStopFlexBox(didStopFlexBoxMode: boolean): void {
        const eventAction = 'Switch to normal';
        const eventLabel = didStopFlexBoxMode ? 'Yes' : 'No';
        const event: IClientTrackingEvent = {
            event: 'FlexBox goto normal overlay',
            eventCategory: 'FlexBox',
            eventLabel: eventLabel,
            eventAction: eventAction
        };
        this.track(event);
    }

    public trackFlexBoxButtonPress(button: string, didActivate: boolean): void {
        const eventAction = button;
        const eventLabel = didActivate ? 'activate' : 'deactivate';
        const event: IClientTrackingEvent = {
            event: 'FlexBox button',
            eventCategory: 'FlexBox',
            eventLabel: eventLabel,
            eventAction: eventAction
        };
        this.track(event);
    }

    public trackSearchResult(response: AxiosResponse<BaseApiResponse<ProductSearchResponse>>): void {
        const term = response.config.params[termKey];

        // If there is a term, its a text search
        if (term) {
            // Track only new searches, not filtering and paging
            if (term === this.lastSearchedTerm) {
                return;
            }
            this.lastSearchedTerm = term;
            const typed = this.lastTypedSearch;
            const eventAction = `${term}|${typed}`;
            const event: IClientTrackingEvent = {
                event: 'SearchResult',
                eventCategory: 'Search Result',
                eventAction,
                eventLabel: `${response.data.model.totalResults}`,
                eventValue: undefined
            };
            this.track(event);
        }
    }

    public trackSearchAsYouTypeResult(response: AxiosResponse<BaseApiResponse<SearchAsYouTypeResponse>>): void {
        const term = response.data.model.term;

        // If this is exactly what the user typed, track the suggest event
        if (term === this.lastTypedSearch) {
            const event: IClientTrackingEvent = {
                event: 'SearchSuggest',
                eventCategory: 'Search Suggest',
                eventAction: term,
                eventLabel: `${response.data.model.totalResults}`,
                eventValue: undefined
            };
            this.track(event);
        } else {
            // Otherwise, the user has clicked a suggestion, so we track that
            const event: IClientTrackingEvent = {
                event: 'SearchSuggestClick',
                eventCategory: term,
                eventAction: `${term}|${this.lastTypedSearch}`,
                eventLabel: `${response.data.model.totalResults}`,
                clickType: 'SuggestedWords'
            };
            this.track(event);
        }
    }

    public trackCategorySuggestionClick(category: string, term: string, totalResults: string): void {
        const event: IClientTrackingEvent = {
            event: 'SearchSuggestClick',
            eventCategory: category,
            eventAction: `${term}|${this.lastTypedSearch}`,
            eventLabel: totalResults,
            clickType: 'CategoryLink'
        };
        this.track(event);
    }

    public trackPromotedLinkClick(title: string, term: string, totalResults: string): void {
        const event: IClientTrackingEvent = {
            event: 'SearchSuggestClick',
            eventCategory: title,
            eventAction: `${term}|${this.lastTypedSearch}`,
            eventLabel: totalResults,
            clickType: 'PromotedResult'

        };
        this.track(event);
    }

    public trackSearchRating(experience: searchExperience, totalSearchResults: number): void {
        const eventLabel = `${this.lastSearchedTerm}|${this.lastTypedSearch}`;
        const event: IClientTrackingEvent = {
            event: 'RatingofSearch',
            eventCategory: 'Rating of search',
            eventAction: experience,
            eventLabel,
            eventValue: `${totalSearchResults}`
        };
        this.track(event);
    }

    public trackSearchNavigation(action: string, totalSearchResults: number): void {
        const eventAction: string = action;
        const eventLabel = `${this.lastSearchedTerm}|${this.lastTypedSearch}`;
        const event: IClientTrackingEvent = {
            event: 'SearchNavigation',
            eventCategory: 'Search Navigation',
            eventAction,
            eventLabel,
            eventValue: `${totalSearchResults}`
        };
        this.track(event);
    }

    public setLastTypedTerm(term: string) {
        this.lastTypedSearch = term;
    }

    public getLastTypedTerm(): string {
        return this.lastTypedSearch;
    }

    public trackCreateUserFormStart(name: string): void {
        const event: ICreateUserForm = {
            event: 'form_start',
            'form_type': 'Sign up',
            'form_name': name
        };
        this.track(event);
    }

    public trackCreateUserFormSubmit(type: string, name: string): void {
        const event: ICreateUserForm = {
            event: 'form_submit',
            'form_type': type,
            'form_name': name
        };
        this.track(event);
    }

    public trackCreateUserFormSuccess(type: string, name: string): void {
        const event: ICreateUserForm = {
            event: 'form_success',
            'form_type': type,
            'form_name': name
        };
        this.track(event);
    }

    public trackCreateUserFormError(type: string, name: string, errorMessage: string): void {
        const event: ICreateUserForm = {
            event: 'form_error',
            'form_type': type,
            'form_name': name,
            'error_message': errorMessage
        };
        this.track(event);
    }

    public trackCreateUserFormStep(type: string, name: string, stepName: string, stepNumber: number): void {
        const event: ICreateUserForm = {
            event: 'form_step',
            'form_type': type,
            'form_name': name,
            'step_name': stepName,
            'step_number': stepNumber
        };
        this.track(event);
    }

    public trackLineReferenceInteraction(productId): void {
        const event: ILineReferenceInteraction = {
            event: 'lineReference',
            productID: productId
        };
        this.track(event);
    }

    private clearLastSearchedTerm(): void {
        this.lastSearchedTerm = '';
    }

    private getTrackingProductsFromLineItems(lineItems: LineItemViewObject[]): ILineItemTrackingProduct[] {
        let products: ILineItemTrackingProduct[] = [];
        lineItems.forEach(li => {
            const fixedUnitValue = li.unit.isFixedUnit ? li.fixedUnit.value : null;
            const fixedUnitCode = li.unit.isFixedUnit ? li.fixedUnit.unitCode : null;
            products.push({
                id: li.productId,
                name: li.displayName,
                variant: this.getFixedUnitVariantName(li.unit.isFixedUnit, fixedUnitValue, fixedUnitCode, li.unit.unitCode),
                brand: li.manufacturer,
                category: li.primaryCategoryName,
                price: li.trackingUnitPrice,
                quantity: li.quantity,
                lineReference: !!li.customerReference
            });
        });
        return products;
    }

    private getDeliveryOptionName(delivery: DeliveryType): string {
        const trackingNameForUKNOWN = 'Intet valgt';
        return delivery === 0 ? trackingNameForUKNOWN : DeliveryType[delivery];
    }

    private getFixedUnitVariantName(isFixedUnit: boolean, fixedUnitValue: number | null, fixedUnitCode: string | null, unitCode: string): string {
        return isFixedUnit ? `fixed_${fixedUnitValue}_${fixedUnitCode}` : unitCode;
    }

    public formatListName(context: ProductTileTrackingContext): string {
        if (!context) return '';

        let listName = context.list;
        if (listName.indexOf('[pagetype]') > -1) {
            const pageType: string = store.getters['app/pageType'];
            listName = listName.replace('[pagetype]', pageType);
        }

        return listName;
    }

    public trackNavigationClick({ type, clickText }: {clickText: string; type: NavigationClicksTypes}) {
        this.track({ event: 'navigationClick', type, click_text: clickText }); // eslint-disable-line @typescript-eslint/camelcase
    }

    public trackNudgingForSalesExpand(price: string, productId: string, listName: string): void {
        const event: ISimpleProduct = {
            event: 'nudgingForSalesOpen',
            price: price,
            productID: productId,
            currency: this.getCurrency(price),
            listName: listName
        };
        this.track(event);
    }

    getCurrency(input: string): string {
        const inputLower = input.toLowerCase(); // Convert input to lowercase for case-insensitive matching
        if (inputLower.includes('kr')) {
            return 'DKK';
        } else if (inputLower.includes('usd')) {
            return 'USD';
        }
        return 'Unknown currency';
    }
}

/**
 * TODO: When/if updated typescript to 4.1+ we should change this to template literals as the following
 * type navigationSidebarClickTypes = `Left navigation bar - ${'entrance' | 'submenu_1' | 'submenu_2' | 'submenu_3'}`;
*/
type NavigationSidebarClickTypes = 'Left navigation bar - entrance' |
'Left navigation bar - submenu_1' |
'Left navigation bar - submenu_2' |
'Left navigation bar - submenu_3' ;

export type NavigationClicksTypes = 'User menu' | 'Top menu' | 'footer' | NavigationSidebarClickTypes;

export type INamedTrackingEvents = 'pageview';

export interface ILineItemTrackingProduct {
    brand: string;
    category: string;
    id: string;
    lineReference?: boolean;
    name: string;
    price: number | null;
    quantity: number;
    variant: string | null;
}

export interface ISimpleProduct {
    currency: string;
    event: string;
    listName: string;
    price: string;
    productID: string;
}

export interface ICreateUserForm {
    'error_message'?: string;
    event: string;
    'form_name': string;
    'form_type': string;
    'step_name'?: string;
    'step_number'?: number | string;
}

export interface ILineReferenceInteraction {
    event: string;
    productID: string;
}

interface IUserDataTrackingEvent {
    AccountCategory?: string;
    AccountRegion?: string;
    AccountSegment?: string;
    AccountStore?: string;
    audiences: string;
    BillToPartnerId?: string;
    CurrentAccount?: string;
    event: string;
    loggedinState: string;
    PriceGroup?: string;
    PrimaryContact?: string;
    userId?: number;
    ZAContactTypes?: string;
}

interface IClientTrackingEvent {
    clickType?: string;
    event: string;
    eventAction?: string;
    eventCategory: string;
    eventLabel?: string;
    eventValue?: string | number;
}

interface ISimpleEvent {
    event: string;
}

export default new Tracking();
