




















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BrandPromotionViewModel } from '@/types/serverContract';
import VideoPlayer from '@/components/atoms/video/VideoPlayer.vue';
import ImageWithLink from '@/project/shared/ImageWithLink.vue';
import { IPromoActionField } from '@/core/tracking/promotionTracking.g4-structure';
import promotionTrackingService from '@/core/tracking/promotionTracking.service';
import { MEDIA_TYPE, PROMO_TRACKING_EVENT } from '@/core/enums/enums';

@Component({
    components: {
        ImageWithLink,
        VideoPlayer
    }
})
export default class SearchBrandPromotion extends Vue {
    @Prop({ type: Object }) promotion!: BrandPromotionViewModel;
    @Prop({ type: String }) brand!: string;

    trackPromotionClick(type: string): void {
        const url = type === MEDIA_TYPE.Image ? this.promotion.imagePromotion.imageUrl : this.promotion.videoUrl;

        const id = `${this.brand}|brandpage|${url}`;
        const name = `${this.brand}`;
        const creative = type;

        const actionField: IPromoActionField = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_id: id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_name: name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            creative_name: creative
        };

        promotionTrackingService.TrackPromotion(
            PROMO_TRACKING_EVENT.PromotionClick,
            actionField
        );
    }

    trackPromotionViewImage(): void {
        this.trackPromotionView(MEDIA_TYPE.Image);
    }

    trackPromotionViewVideo(): void {
        this.trackPromotionView(MEDIA_TYPE.Video);
    }

    trackPromotionView(type: string): void {
        const url = type === MEDIA_TYPE.Image ? this.promotion.imagePromotion.imageUrl : this.promotion.videoUrl;

        const id = `${this.brand}|brandpage|${url}`;
        const name = `${this.brand}`;
        const creative = type;

        const actionField: IPromoActionField = {
        // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_id: id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_name: name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            creative_name: creative
        };

        promotionTrackingService.TrackPromotion(
            PROMO_TRACKING_EVENT.PromotionImpression,
            actionField
        );
    }
}
