


















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
    ProductTileViewObject,
    PriceViewObject,
    LoginInformation,
    QuickAddToBasketRequest,
    QuickAddToBasketResultViewModel
} from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import { FlexBoxGetter } from '@/store/flexBox';
import ProductTileWrapper from '@/project/products/ProductTileWrapper.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import Api from '@/project/http/api';
import productTrackingService from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';

@Component({
    components: {
        ProductTileWrapper,
        ButtonSubmit
    }
})
export default class SimpleTop100ProductTile extends Vue {
    @Prop({
        required: true,
        type: Object
    })
    product!: ProductTileViewObject;

    @AppGetter loginInformation!: LoginInformation;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    addToBasketPending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async priceLoaded(price: PriceViewObject) {
        this.$emit('priceLoaded', price);
    }

    async addToBasket() {
        this.addToBasketPending = true;

        const quickAddToBasketRequest: QuickAddToBasketRequest = {
            productId: this.product.id,
            quantity: this.product.defaultQuantity
        };

        try {
            let added: QuickAddToBasketResultViewModel = await Api.basket.quickAddToBasket(
                quickAddToBasketRequest,
                ''
            );
            if (added.addedLine) {
                productTrackingService.TrackProduct(
                    PRODUCT_TRACKING_EVENT.AddToCart,
                    productTrackingService.ToTrackedProduct(
                        added.addedLine,
                        PRODUCT_TRACKING_TYPE.AddLineItemViewObject
                    )
                );
            }
            this.showSuccessIndicator();
        } finally {
            this.addToBasketPending = false;
        }
    }

    showSuccessIndicator() {
        this.addToBasketPending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    // This function to be passed to the tracking directive
    public trackCurrentProduct() {
        // Passed a single product in a list to avoid breaking changes in tracking, ultimately this should be changed
        productTrackingService.TrackBatchProduct(
            PRODUCT_TRACKING_EVENT.ProductImpression,
            productTrackingService.ToTrackedProduct(
                this.product,
                PRODUCT_TRACKING_TYPE.ProductTileViewObject
            )
        );
    }
}
