import { render, staticRenderFns } from "./SearchFacets.vue?vue&type=template&id=f60bd2bc&scoped=true"
import script from "./SearchFacets.vue?vue&type=script&lang=ts"
export * from "./SearchFacets.vue?vue&type=script&lang=ts"
import style0 from "./SearchFacets.vue?vue&type=style&index=0&id=f60bd2bc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f60bd2bc",
  null
  
)

export default component.exports