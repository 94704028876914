




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import serverContext from '@/core/serverContext.service';

@Component
export default class CookieBotDeclaration extends Vue {
    public $refs!: {
        scriptHolder: HTMLElement;
    };

    mounted() {
        let isEnabled = serverContext.cookieBotContextData.enabled;
        let cookieBotKey = serverContext.cookieBotContextData.key;
        let cookieBotLanguage = serverContext.cookieBotContextData.languageCode;
        if (isEnabled && cookieBotKey) {
            let scriptEl = document.createElement('script');
            scriptEl.setAttribute('id', 'CookieDeclaration');
            scriptEl.setAttribute('data-culture', cookieBotLanguage);
            scriptEl.setAttribute('src', 'https://consent.cookiebot.com/' + cookieBotKey + '/cd.js');
            scriptEl.setAttribute('type', 'text/javascript');
            scriptEl.async = true;
            this.$refs.scriptHolder.appendChild(scriptEl);
        }
    }
}
