



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import bus from '../bus';
import { ValidationErrorEvent, ValidationErrorEventKey } from './validationErrors.interceptor';
import { ClientMessage } from '@/types/serverContract';

@Component
export default class WebApiMessage extends Vue {
    private messages: ClientMessage[] = [];

    @Prop({
        default: 'text-red-500',
        type: String
    }) textColorClass!: string;

    @Prop({
        required: true
    })
    private id!: string;

    private created() {
        bus.on(ValidationErrorEventKey, this.onMessages);
    }

    private onMessages(validationErrorEvent: ValidationErrorEvent) {
        if (validationErrorEvent.messagesId === this.id) {
            this.messages = validationErrorEvent.messages;
            if (validationErrorEvent.handled) {
                validationErrorEvent.handled();
            }
        }
    }

    private destroyed() {
        bus.off(ValidationErrorEventKey, this.onMessages);
    }
}
