



















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ClientMessageEx, MessagesGetter, MessagesAction } from '@/store/messages';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import ApiMessages from '@/project/messages/ApiMessages.vue';
import { AppAction } from '@/store/app';
import { FocusLoop } from '@vue-a11y/focus-loop';

@Component({
    components: {
        ApiMessages,
        OffCanvasOverlay,
        FocusLoop
    }
})
export default class ModalOverlay extends Vue {
    @MessagesGetter private apiMessages!: ClientMessageEx[];
    @AppAction setIsModalOpen!: (isOpen: boolean) => void;
    @MessagesAction private clearApiMessage!: (id: string) => void;

    @Prop({ required: true, default: false, type: Boolean }) show!: boolean;
    @Prop({
        default: 'medium',
        validator: (value: string) => ['small', 'medium', 'large'].indexOf(value) > -1,
        type: String
    })
    size!: string;

    @Prop({ default: '', type: String }) heading!: string;
    @Prop({ default: '', type: String }) headerClass!: string;
    @Prop({ default: '', type: String }) additionalHeading!: string;
    @Prop({ default: '', type: String }) modalClass!: string;
    @Prop({ default: true, type: Boolean }) showCloseIcon!: boolean;
    @Prop({ default: '', type: String }) bodyClass!: string;
    @Prop({ type: Boolean, default: true }) closeOnClickOutside!: boolean;
    @Prop({ type: Boolean, default: true }) disableBodyScroll!: boolean;
    @Prop({ type: String }) outerClass!: string;

    get outerClasses(): string {
        return this.outerClass;
    }

    get showModal(): boolean {
        return this.show;
    }

    public $refs!: {
        offCanvasOverlay: OffCanvasOverlay;
    };

    created() {
        this.$on('closeModal', this.closeModal);
    }

    closeModal(): void {
        this.setIsModalOpen(false);
        this.$emit('update:show', false);
        this.$emit('close');
        this.clearApiMessages();
    }

    onModalOpen(): void {
        this.setIsModalOpen(true);
        this.clearApiMessages();
    }

    clearApiMessages(): void {
        this.apiMessages.forEach(msg => {
            this.clearApiMessage(msg.id);
        });
    }
}
