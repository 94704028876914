
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ImageWithCurtainOverlay extends Vue {
    @Prop({ required: true, type: String }) source!: string;
    @Prop({ default: '', type: String }) webpSource!: string;
    @Prop({ default: '', type: String }) sourceSet!: string;
    @Prop({ default: '', type: String }) classes!: string;
    @Prop({ default: '', type: String }) alt!: string;
    @Prop({ default: '', type: String }) title!: string;
    @Prop({ type: String, default: 'eager', validator: (value: string) => ['eager', 'lazy'].indexOf(value) > -1 }) loading!: string;

    @Prop({ default: 'bg-transparent-black', type: String }) curtainClasses!: string;
    @Prop({ default: 'relative', type: String }) containerClasses!: string;

    hasError: boolean = false;

    onError(): void {
        this.hasError = true;
        this.$emit('error');
    }
}
