var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.imageError)?_c('div',{staticClass:"c-base-banner",class:{'-text-right': _vm.textAlignmentRight,
             '-text-left': !_vm.textAlignmentRight,
             'grid': !_vm.fullWidth}},[(!_vm.fullWidth)?_c('div',{staticClass:"__text-container flex flex-col w-full",style:(_vm.textContainerStyle)},[_c('div',{staticClass:"font-ahlsell-sans self-start w-full"},[_c('div',{class:_vm.colorMode === _vm.$enums.BRIGHTNESS_MODE.Dark ? 'text-white' : 'text-marine'},[_vm._t("text")],2)]),(_vm.showCtaButton)?_c('div',{staticClass:"md:flex flex-col justify-end flex-auto pt-32",class:{
                'hidden': !_vm.ctaButton.url,
                'flex': _vm.ctaButton.url
            }},[_c('div',{staticClass:"flex justify-end w-full",class:{'invisible': !_vm.ctaButton.url}},[_c('ButtonCtrl',{staticClass:"self-end px-48",attrs:{"variant":_vm.$enums.BUTTON_VARIANTS.BLACK,"to":_vm.ctaButton.url,"title":_vm.ctaButton.name,"target":_vm.ctaButton.target},nativeOn:{"click":function($event){return _vm.trackPromotionClick($event)}}},[_vm._v("\n                    "+_vm._s(_vm.ctaButton.name)+"\n                ")])],1)]):_vm._e()]):_vm._e(),(!_vm.onMobileAndNoImage)?_c('div',{ref:"baseBannerImageContainer",staticClass:"__image-container md:h-auto relative w-full",class:{ '-has-no-image': !_vm.image, 'bg-no-repeat bg-cover' : _vm.image }},[_c(_vm.getImageContainerComponentType,_vm._b({tag:"component",class:!_vm.image ? ['__no-image-container flex flex-col justify-center', _vm.getBackgroundColorIfNoImage]: []},'component',_vm.image ? _vm.imageProps : {},false),[_c('div',{staticClass:"__image-container-text",class:_vm.colorMode === _vm.$enums.BRIGHTNESS_MODE.Dark ? 'text-white' : 'text-marine'},[_vm._t("imageText")],2),(_vm.imageLink)?_c('div',{staticClass:"z-2 absolute inset-0"},[_c('RouterLinkConditional',{staticClass:"absolute inset-0",attrs:{"to":_vm.imageLink.url,"title":_vm.imageLink.name,"target":_vm.imageLink.target},nativeOn:{"click":function($event){return _vm.trackPromotionClick($event)}}})],1):_vm._e(),(_vm.showCtaButton && _vm.fullWidth)?_c('div',{staticClass:"pt-28 relative",class:{
                    'hidden': !_vm.ctaButton.url,
                    'flex': _vm.ctaButton.url
                }},[_c('div',{staticClass:"z-scale-price flex justify-start w-full",class:{'invisible': !_vm.ctaButton.url}},[_c('ButtonCtrl',{staticClass:"-dark-text __cta-button self-end px-48",attrs:{"variant":_vm.$enums.BUTTON_VARIANTS.TERTIARY,"to":_vm.ctaButton.url,"title":_vm.ctaButton.name,"target":_vm.ctaButton.target},nativeOn:{"click":function($event){return _vm.trackPromotionClick($event)}}},[_vm._v("\n                        "+_vm._s(_vm.ctaButton.name)+"\n                    ")])],1)]):_vm._e()])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }