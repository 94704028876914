







































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import tracking from '@/core/tracking/tracking.service';

@Component({
})
export default class CreateWebUserTimeline extends Vue {
    @Prop({ type: Number, default: 1 }) step!: number;

    mounted() {
        if (this.step === 2) {
            tracking.trackCreateUserFormSuccess('Sign up', 'Extra web user');
        }
    }
}
