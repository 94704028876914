import SearchController from '@/project/http/SearchController';
import AuthenticationController from '@/project/http/AuthenticationController';
import ShopEventsController from '@/project/http/ShopEventsController';
import ProductNewsTeaserController from '@/project/http/ProductNewsTeaserController';
import ContentController from '@/project/http/ContentController';
import BasketController from '@/project/http/BasketController';
import UserController from '@/project/http/UserController';
import PricesController from '@/project/http/PricesController';
import BuyerItemController from '@/project/http/BuyerItemController';
import AddressLookupController from '@/project/http/AddressLookupController';
import ProductsController from '@/project/http/ProductsController';
import FavoriteOrderController from '@/project/http/FavoriteOrderController';
import ReportsController from '@/project/http/ReportsController';
import StoreController from '@/project/http/StoreController';
import OrderHistoryController from '@/project/http/OrderHistoryController';
import QuotationsController from '@/project/http/QuotationsController';
import InvoiceController from '@/project/http/InvoiceController';
import ContactController from '@/project/http/ContactController';
import RecommendationController from '@/project/http/RecommendationController';
import ClimateCertificatesController from '@/project/http/ClimateCertificatesController';
import RecommendedProductsController from '@/project/http/RecommendedProductsController';
import EasySupplyController from '@/project/http/EasySupplyController';
import TrackAndTraceController from '@/project/http/TrackAndTraceController';
import AccountController from '@/project/http/AccountController';
import AccountGroupController from '@/project/http/AccountGroupController';
import ServicesController from '@/project/http/ServicesController';
import SecurityDatasheetsController from '@/project/http/SecurityDatasheetsController';
import AgreementController from '@/project/http/AgreementController';
import ProjectOrderQuotationController from '@/project/http/ProjectOrderQuotationController';
import ScannerController from '@/project/http/ScannerController';
import ScannerListsController from '@/project/http/ScannerListsController';
import DashboardController from '@/project/http/DashboardController';
import ProductCatalogController from '@/project/http/ProductCatalogController';
import ProjectAgreementController from '@/project/http/ProjectAgreementController';
import HelpDeskController from '@/project/http/HelpDeskController';
import DataPolicyController from '@/project/http/DataPolicyController';
import ProjectFolderOffersController from '@/project/http/ProjectFolderOffersController';
import DeliveryNotesController from '@/project/http/DeliveryNotesController';
import DeliveryMethodController from '@/project/http/DeliveryMethodController';
import DeliveryAddressController from '@/project/http/DeliveryAddressController';
import DeliveryTimesController from '@/project/http/DeliveryTimesController';
import HistoricalDataController from '@/project/http/HistoricalDataController';
import SearchManagementController from '@/project/http/SearchManagementController';
import FlexBoxController from '@/project/http/FlexBoxController';
import HubSpotController from '@/project/http/HubSpotController';
import CampaignController from '@/project/http/CampaignController';
import UnitController from '@/project/http/UnitController';

export default class Api {
    public static search = new SearchController();
    public static authentication = new AuthenticationController();
    public static shopEvents = new ShopEventsController();
    public static productNewsTeaser = new ProductNewsTeaserController();
    public static content = new ContentController();
    public static basket = new BasketController();
    public static user = new UserController();
    public static prices = new PricesController();
    public static buyerItem = new BuyerItemController();
    public static addressLookup = new AddressLookupController();
    public static products = new ProductsController();
    public static favoriteOrder = new FavoriteOrderController();
    public static reports = new ReportsController();
    public static stores = new StoreController();
    public static orderHistory = new OrderHistoryController();
    public static quotations = new QuotationsController();
    public static invoices = new InvoiceController();
    public static contacts = new ContactController();
    public static recommendation = new RecommendationController();
    public static climateCertificatesController = new ClimateCertificatesController();
    public static recommendedProducts = new RecommendedProductsController();
    public static easySupply = new EasySupplyController();
    public static trackAndTrace = new TrackAndTraceController();
    public static account = new AccountController();
    public static accountGroup = new AccountGroupController();
    public static services = new ServicesController();
    public static securityDatasheets = new SecurityDatasheetsController();
    public static agreements = new AgreementController();
    public static projectOrderQuotation = new ProjectOrderQuotationController();
    public static scanner = new ScannerController();
    public static scannerLists = new ScannerListsController();
    public static dashboard = new DashboardController();
    public static productCatalog = new ProductCatalogController();
    public static projectAgreement = new ProjectAgreementController();
    public static helpDesk = new HelpDeskController();
    public static dataPolicy = new DataPolicyController();
    public static projectFolderOffers = new ProjectFolderOffersController();
    public static deliveryNotes = new DeliveryNotesController();
    public static deliveryMethod = new DeliveryMethodController();
    public static deliveryAddress = new DeliveryAddressController();
    public static deliveryTimes = new DeliveryTimesController();
    public static historicalData = new HistoricalDataController();
    public static searchManagement = new SearchManagementController();
    public static flexBox = new FlexBoxController();
    public static hubSpot = new HubSpotController();
    public static campaign = new CampaignController();
    public static unit = new UnitController();
}
