








































































































import Vue from 'vue';
import api from '@/project/http/api';
import { PagedFavoriteOrdersRequest, PagedFavoriteOrdersViewModel } from '@/types/serverContract';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import { Component, Prop } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import serverContextService from '@/core/serverContext.service';
import { FlexBoxGetter } from '@/store/flexBox';
import tracking from '@/core/tracking/tracking.service';
import translateFilter from '@/core/translation/translate.filter';

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class SimpleFavoriteOrdersList extends Vue {
    @Prop({
        required: false,
        type: Number
    }) pageSize!: number;

    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @AppGetter isLoggedIn!: boolean;

    favoriteOrdersLink = serverContextService.sitePages.favoriteOrdersPage.url;
    pagedFavoriteOrders: PagedFavoriteOrdersViewModel | null = null;
    pending: boolean = false;
    skeletonList: number[] = [];

    created() {
        this.getFavoriteOrders().then(() => this.fillSkeletonList());
    }

    fillSkeletonList() {
        if (!this.pageSize) {
            this.skeletonList = [];
        }

        if (this.pagedFavoriteOrders && this.pagedFavoriteOrders.favoriteOrders) {
            let amountToCreate = this.pageSize - this.pagedFavoriteOrders.favoriteOrders.length;

            // if there are no favorite lists then push '-1' to show different element for that index in template
            if (amountToCreate === this.pageSize) {
                this.skeletonList.push(-1);
                amountToCreate--;
            }

            for (let index = 0; index < amountToCreate; index++) {
                this.skeletonList.push(index);
            }
        }
    }

    trackFavoriteOrderClick(orderLabel: string, translateLabel: boolean) {
        if (translateLabel) {
            orderLabel = translateFilter(orderLabel);
        }

        tracking.trackFrontpageNavigation('Favoritlister', orderLabel);
    }

    async getFavoriteOrders() {
        if (this.isLoggedIn) {
            this.pending = true;
            try {
                const request: PagedFavoriteOrdersRequest = {
                    page: 1,
                    pageSize: this.pageSize
                };

                this.pagedFavoriteOrders = await api.favoriteOrder.getPagedFavoriteOrders(request);
            } finally {
                this.pending = false;
            }
        }
    }
}
