

































import { Component, Prop } from 'vue-property-decorator';
import InputCtrl from './InputCtrl.vue';
import InputCtrlData from './InputCtrlData';

@Component({
    components: { InputCtrl }
})
export default class MultilineInputCtrl extends InputCtrlData {
    @Prop({ default: true, type: Boolean }) ignoreLastPass!: boolean;
    @Prop({ required: false, type: [String, Number] }) value!: string | number;
    @Prop({ default: '', type: String }) inputClass!: string; // Optional styling classes for the input field
}
