













































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import {
    GetScannerListRequest,
    ScannerListDetailViewModel,
    SortByViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import QueryPaging from '@/project/shared/QueryPaging.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import urlHelper from '../../favoriteOrders/urlHelperFavoriteOrders.service';
import scrollService from '@/core/scroll/scroll.service';
import ScannerListProductTile from '@/project/myServices/Scanner/ScannerListProductTile.vue';
import ScannerListActionLinks from '@/project/myServices/Scanner/ScannerListActionLinks.vue';
import AddScannerListToBasket from '@/project/myServices/Scanner/AddScannerListToBasket.vue';
import ScannerListDetailsSearch from '@/project/myServices/Scanner/ScannerListDetailsSearch.vue';
import ScannerListSorting from '@/project/myServices/Scanner/ScannerListSorting.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component({
    components: {
        ScannerListSorting,
        ScannerListDetailsSearch,
        AddScannerListToBasket,
        ScannerListActionLinks,
        ScannerListProductTile,
        SpinnerOverlay,
        QueryPaging
    }
})
export default class ScannerListDetails extends Vue {
    pending: boolean = false;
    scannerListInfo: ScannerListDetailViewModel | null = null;
    currentPage: number = 1;

    get hasScannerListInfo(): boolean {
        return isNotNullEmptyOrUndefined(this.scannerListInfo);
    }

    get sortingOptions(): SortByViewObject[] | null {
        return this.scannerListInfo ? this.scannerListInfo.sortingOptions : null;
    }

    public $refs!: {
        ScannerListProductTileList: HTMLElement;
    };

    created() {
        this.getListInfo();
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            return;
        }
        this.getListInfo().then(() => scrollService.scrollToElement(this.$refs.ScannerListProductTileList, -100));
    }

    async getListInfo() {
        this.pending = true;
        try {
            const searchPayload: GetScannerListRequest = {
                lineSearchTerm: urlHelper.getSearchTerm(),
                page: urlHelper.getPageNumber(),
                scannerListId: urlHelper.getFavoriteOrderId(),
                sortBy: urlHelper.getSorting()
            };
            this.scannerListInfo = await Api.scannerLists.scannerList(searchPayload);
            this.currentPage = urlHelper.getPageNumber();
        } finally {
            this.pending = false;
        }
    }

    updateScannerList() {
        this.getListInfo();
    }
}
