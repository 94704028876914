export interface OptionalOptions {
    font?: string;
    fontSize?: string;
    fontWeight?: string;
    lineHeight?: string;
    margin?: number;
    width?: string;
    wordBreak?: string;
}

interface Options {
    font: string;
    fontSize: string;
    fontWeight: string;
    lineHeight: string;
    margin: number;
    width: string;
    wordBreak: string;
}

export interface Size {
    height: number;
    width: number;
}

function replaceLastThreeCharsWithEllipsis(text) {
    if (text.length <= 3) {
        return '...';
    } else {
        return text.slice(0, -3) + '...';
    }
}

function createDummyElement(text: string, options: Options): HTMLElement {
    const element = document.createElement('div');
    const textNode = document.createTextNode(text);

    element.appendChild(textNode);

    element.style.fontFamily = options.font;
    element.style.fontSize = options.fontSize;
    element.style.fontWeight = options.fontWeight;
    element.style.lineHeight = options.lineHeight;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.left = '-999px';
    element.style.top = '-999px';
    element.style.width = options.width;
    element.style.height = 'auto';
    element.style.wordBreak = options.wordBreak;

    document.body.appendChild(element);

    return element;
}

function destroyElement(element: HTMLElement): void {
    element.parentNode!.removeChild(element);
}

const cache = {};

export function calculateTextSize(text: string, options: OptionalOptions = {}): Size {
    const cacheKey = JSON.stringify({ text: text, options: options });

    if (cache[cacheKey]) {
        return cache[cacheKey];
    }

    // prepare options
    options.font = options.font || 'Ahlsell Sans';
    options.fontSize = options.fontSize || '16px';
    options.fontWeight = options.fontWeight || 'normal';
    options.lineHeight = options.lineHeight || 'normal';
    options.width = options.width || 'auto';
    options.wordBreak = options.wordBreak || 'normal';
    options.margin = options.margin || 0;

    const element = createDummyElement(text, options as Options);

    const size = {
        width: element.offsetWidth + options.margin,
        height: element.offsetHeight
    };

    destroyElement(element);

    cache[cacheKey] = size;

    return size;
}

export function calculateAvailableText(text: string, width: number, height: number, options: OptionalOptions = {}) {
    // Set default options

    options.font = options.font || 'Ahlsell Sans';
    options.fontSize = options.fontSize || '16px';
    options.fontWeight = options.fontWeight || 'normal';
    options.lineHeight = options.lineHeight || 'normal';
    options.width = width + 'px' || 'auto';
    options.wordBreak = options.wordBreak || 'normal';

    const element = createDummyElement(text, options as Options);
    // Set initial font size
    element.style.fontSize = options.fontSize;

    // Set initial text
    element.textContent = text;

    let textTruncated = false;
    // Try fitting text until it no longer fits
    while (element.offsetWidth > width || element.offsetHeight > height) {
        textTruncated = true;
        text = text.slice(0, -1); // Remove one character from the end
        element.textContent = text;

        // Break loop if text becomes empty
        if (text.length === 0) {
            break;
        }
    }

    let textContent = element.textContent;
    destroyElement(element);
    if (textTruncated) {
        return replaceLastThreeCharsWithEllipsis(textContent);
    }
    return textContent;
}
