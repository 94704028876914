import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import Constants from '@/project/config/constants';

Vue.use(VueGoogleMaps, {
    load: {
        key: Constants.GoogleApiKey,
        library: 'places'
    }
});
