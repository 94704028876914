














































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
    AddOrUpdateSpecialOrderLineItemRequest,
    SpecialLineItemUnitsViewModel,
    UnitViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';
import { BasketGetter } from '@/store/basket';
import scrollService from '@/core/scroll/scroll.service';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        SpinnerElement,
        ButtonSubmit
    }
})
export default class SpecialOrderItemForm extends Vue {
    @BasketGetter specialOrderItemForm!: AddOrUpdateSpecialOrderLineItemRequest;
    @Prop({
        type: Boolean,
        required: true
    })
    isActive!: boolean;

    unitOptions: SpecialLineItemUnitsViewModel | null = null;
    selectedUnit: UnitViewObject | null = null;
    unitsPending: boolean = false;
    pending: boolean = false;
    updatingExistingSpecialOrderItem: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;

    specialOrderItemFormModel: AddOrUpdateSpecialOrderLineItemRequest = {
        lineItemId: null,
        manufacturer: '',
        productItemNumber: '',
        productText: '',
        quantity: 1,
        supplementaryProductText: '',
        unitCode: '',
        caseInformation: null
    };

    vvFields!: any;

    get buttonLabel(): string {
        return this.updatingExistingSpecialOrderItem
            ? this.$tr(this.$labels.Views.SpecialOrder.Save)
            : this.$tr(this.$labels.Views.SpecialOrder.Add);
    }

    get isFieldsValid(): boolean {
        return !Object.values(this.vvFields).some((field: any) => field.invalid);
    }

    public $refs!: {
        specialOrderItemFormElement: HTMLElement;
    };

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    @Watch('specialOrderItemForm')
    onExistingSpecialOrderItemChange(value: AddOrUpdateSpecialOrderLineItemRequest) {
        if (!value) {
            return;
        }

        this.specialOrderItemFormModel = {
            lineItemId: value.lineItemId,
            manufacturer: value.manufacturer,
            productItemNumber: value.productItemNumber,
            productText: value.productText,
            quantity: value.quantity,
            supplementaryProductText: value.supplementaryProductText,
            unitCode: value.unitCode,
            caseInformation: null
        };

        this.updatingExistingSpecialOrderItem = true;

        scrollService.scrollToElement(this.$refs.specialOrderItemFormElement, -150);

        this.$emit('setActive');
        if (this.isActive) {
            this.setSelectedUnit();
        }
    }

    @Watch('isActive')
    async getUnitOptions() {
        this.unitsPending = true;
        try {
            if (this.unitOptions === null) {
                this.unitOptions = await Api.unit.specialLineItemUnits();
            }

            this.setSelectedUnit();
        } finally {
            this.unitsPending = false;
        }
    }

    async saveSpecialOrderItem() {
        this.pending = true;
        try {
            await Api.basket.addOrUpdateSpecialOrderLineItem(
                this.specialOrderItemFormModel,
                'special-order'
            );
            this.showSuccessIndicator();
        } finally {
            this.pending = false;
            this.updatingExistingSpecialOrderItem = false;

            this.resetForm();
        }
    }

    setSelectedUnit() {
        if (this.unitOptions === null) {
            return;
        }

        if (this.specialOrderItemFormModel.unitCode === '') {
            const unit = this.unitOptions.units[0];
            this.selectedUnit = unit;
            this.specialOrderItemFormModel.unitCode = unit.unitCode;
        } else {
            this.selectedUnit =
                this.unitOptions.units.find(
                    x => x.unitCode === this.specialOrderItemFormModel.unitCode
                ) || this.unitOptions.units[0];
        }
    }

    updateUnit(value: UnitViewObject) {
        this.specialOrderItemFormModel.unitCode = value.unitCode;
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
            this.$emit('done');
            this.resetForm();
        }, this.successTimer);
    }

    resetForm(): void {
        this.specialOrderItemFormModel = {
            lineItemId: null,
            manufacturer: '',
            productItemNumber: '',
            productText: '',
            quantity: 1,
            supplementaryProductText: '',
            unitCode: this.selectedUnit!.unitCode,
            caseInformation: null
        };
    }
}
