




























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EasySupplyOrderLineViewObject } from '@/types/serverContract';

@Component
export default class EasySupplyStocklistReorderLineItem extends Vue {
    @Prop({
        type: Object,
        required: true
    }) orderLineItem!: EasySupplyOrderLineViewObject;

    deleteOrderLine() {
        this.$emit('orderLineDeleted', this.orderLineItem);
    }
}
