import { router } from '@/router';
import { Route } from 'vue-router';
import { some } from 'lodash-es';

const lastFacetKey = 'lastFacet';
export const termKey = 'term';
const pageKey = 'page';
export const categoryIdKey = 'categoryId';
const sortByKey = 'sortBy';
const explainKey = 'explain';
const skipVvsKey = 'skipVvsSearch';
const nonFacetKeys = [termKey, categoryIdKey, pageKey, sortByKey, explainKey, skipVvsKey];

function updateRangeFacetValue(facetGroupKey: string, rangeFacetExtension: string, value: string) {
    const query = { ...router.currentRoute.query };

    // Remove if empty otherwise set value
    if (value === '') {
        delete query[facetGroupKey + rangeFacetExtension];
    } else {
        query[facetGroupKey + rangeFacetExtension] = value;
    }

    // Set page to 1
    query[pageKey] = 1 + '';
    query[lastFacetKey] = facetGroupKey;

    router.replace({ query });
}

function updateFacetValue(facetGroupKey: string, value: string, selected: boolean, updateLastFacet: boolean = true) {
    let facetValues = router.currentRoute.query[facetGroupKey];

    // Convert to array for all cases
    if (!facetValues) {
        facetValues = [];
    } else if (!(facetValues instanceof Array)) {
        facetValues = [facetValues];
    } else {
        // Create new array or changes wont be detected
        facetValues = [...facetValues];
    }

    // Add or remove
    if (selected) {
        facetValues.push(value);
    } else {
        facetValues = facetValues.filter(v => v !== value);
    }

    // Update query
    const query = { ...router.currentRoute.query };

    // Set page to 1
    query[pageKey] = 1 + '';

    query[facetGroupKey] = facetValues;
    if (updateLastFacet) {
        query[lastFacetKey] = facetGroupKey;
    }
    router.replace({ query });
}

function resetFacets() {
    // Remove all keys related to facets
    const query = { ...router.currentRoute.query };
    Object
        .keys(query)
        .filter(key => !nonFacetKeys.includes(key))
        .forEach(key => {
            delete query[key];
        });

    // Reset page
    delete query[pageKey];

    router.replace({ query });
}

export interface UrlFacets {[key: string]: string[]}

function getFacets(): UrlFacets {
    const query = { ...router.currentRoute.query };

    // Remove nonfacets
    nonFacetKeys.forEach(key => delete query[key]);

    // Ensure all values are arrays
    Object
        .keys(query)
        .forEach(key => {
            query[key] = query[key] instanceof Array ? query[key] : [query[key]] as string[];
        });
    return query as UrlFacets;
}

function lastFacet(): string {
    return router.currentRoute.query[lastFacetKey] as string;
}

function noOfFacetsSelected(): number {
    const facetsWithLastFacet = getFacets();
    return Object
        .keys(facetsWithLastFacet)
        .reduce((sum, key) => {
            if (key === lastFacetKey) return sum;
            return sum + facetsWithLastFacet[key].length;
        }, 0);
}

function getPage(): number {
    const value = parseInt(router.currentRoute.query[pageKey] as string, 10);
    return !value || Number.isNaN(value) ? 1 : value;
}

function setPaging(page: number): void {
    const query = { ...router.currentRoute.query };
    query[pageKey] = page + '';

    router.replace({ query });
}

function getTerm(): string | null {
    return router.currentRoute.query[termKey] as string || null;
}

function getExplain(): boolean {
    let value = router.currentRoute.query[explainKey] as string || null;

    if (value === null) {
        return false;
    }

    return value.toLowerCase() === 'true';
}

function getCategoryId(): string | null {
    return router.currentRoute.query[categoryIdKey] as string || null;
}

function getSkipVvs(): boolean {
    return (router.currentRoute.query[skipVvsKey] as string || null) === 'true';
}

function setTerm(term: string): void {
    const query = { ...router.currentRoute.query };

    query[termKey] = term;

    // Reset page
    delete query[pageKey];

    router.replace({ query });
}

function setCategoryId(categoryId: string): void {
    const query = { ...router.currentRoute.query };

    query[categoryIdKey] = categoryId;

    // Reset page
    delete query[pageKey];

    router.replace({ query });
}

function setSkipVvs(skipVvs: boolean): void {
    const query = { ...router.currentRoute.query };

    query[skipVvsKey] = skipVvs ? 'true' : 'false';

    // Reset page
    delete query[pageKey];

    router.replace({ query });
}

function getSortBy(): string | null {
    return router.currentRoute.query[sortByKey] as string || null;
}

function lastChangeWasNotFacet(route: Route, oldRoute: Route): boolean {
    function normalize(key: string, value: string): string {
        // Treat no pageno as page 1
        return key === pageKey && !value ? '1' : value;
    }

    // Returns true if any non-facet query-params was changed
    return some(nonFacetKeys, key =>
        normalize(key, route.query[key] as string) !== normalize(key, oldRoute.query[key] as string));
}

function setSortBy(sortBy: string): void {
    const query = { ...router.currentRoute.query };

    query[sortByKey] = sortBy;

    // Reset page
    delete query[pageKey];

    router.replace({ query });
}

export default {
    updateRangeFacetValue,
    updateFacetValue,
    resetFacets,
    getFacets,
    lastFacet,
    noOfFacetsSelected,
    getPage,
    setPaging,
    getTerm,
    getExplain,
    setTerm,
    getSortBy,
    setSortBy,
    lastChangeWasNotFacet,
    getCategoryId,
    setCategoryId,
    getSkipVvs,
    setSkipVvs
};
