


























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import scannerService from '@/project/myServices/Scanner/scanner.service';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import { router } from '@/router';
import { ScannerSetupViewModel } from '@/types/serverContract';
import ProductAddToBasketLifeCycleIcons from '@/project/products/ProductAddToBasketLifeCycleIcons.vue';
import Api from '@/project/http/api';

@Component({
    components: {
        ProductAddToBasketLifeCycleIcons,
        SpinnerOverlay
    }
})
export default class ScannerImport extends Vue {
    @Prop({
        required: true,
        type: String
    }) requiredVersion!: string;

    @Prop({
        required: true,
        type: String
    }) installerUrl!: string;

    @Prop({
        required: true,
        type: String
    }) scannerInstalledText!: string;

    pending: boolean = false;
    initialDownloadNeeded: boolean = false;
    scannerVersion: string = '';
    pollReadScannerData: boolean = false;
    scannerReadError: boolean = false;
    scannerSetup: ScannerSetupViewModel | null = null;
    startReadTimeout: number | null = null;
    stopReadTimeout: number | null = null;

    get isDownloadRequired(): boolean {
        return this.scannerVersion === '' ? true : this.scannerVersion < this.requiredVersion;
    }

    created() {
        this.getScannerSetup().then(() => {
            this.getScannerVersion();
        });
    }

    destroyed() {
        if (this.startReadTimeout) {
            clearTimeout(this.startReadTimeout);
        }
        if (this.stopReadTimeout) {
            clearTimeout(this.stopReadTimeout);
        }
    }

    async getScannerSetup(): Promise<void> {
        this.pending = true;
        try {
            await Api.scanner.scannerSetup().then(scannerSetup => {
                this.scannerSetup = scannerSetup;
            });
        } finally {
            this.pending = false;
        }
    }

    async getScannerVersion(): Promise<void> {
        if (this.scannerSetup === null) {
            return;
        }
        this.pending = true;
        await scannerService.getScannerVersion(this.scannerSetup.versionEndpoint).then(versionNumber => {
            this.scannerVersion = versionNumber;
        }).catch(() => {
            this.initialDownloadNeeded = true;
        }).finally(() => {
            this.pending = false;
        });
    }

    readFromScanner(): void {
        if (this.scannerSetup === null) {
            return;
        }
        scannerService.getScannerData(this.scannerSetup.scannerResultEndpoint).then(scannerResult => {
            switch (scannerResult) {
                case 'Success': {
                    router.push(this.scannerSetup!.successUrl);
                    break;
                }
                case 'Error': {
                    if (this.pollReadScannerData) {
                        this.startReadTimeout = setTimeout(this.readFromScanner, 2000);
                    }
                    break;
                }
                case 'ScannerNotFound': {
                    this.scannerReadError = true;
                    this.stopReadFromScanner();
                    break;
                }
            }
        }).finally(() => {
            this.pending = false;
        });
    }

    startReadFromScanner(): void {
        this.pending = true;
        this.pollReadScannerData = true;
        this.scannerReadError = false;

        this.readFromScanner();

        this.stopReadTimeout = setTimeout(() => this.stopReadFromScanner(), 30000);
    }

    stopReadFromScanner(): void {
        this.pending = false;
        this.pollReadScannerData = false;
        this.scannerReadError = true;
    }
}
