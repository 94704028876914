
















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { uuid } from '@/project/config/utilities';
import VueTimepicker from 'vue2-timepicker';

@Component({
    components: {
        VueTimepicker
    },
    inject: ['$validator']
})
export default class TimePickerCtrl extends Vue {
    @Prop({
        type: String,
        required: true
    }) value!: string;

    @Prop({
        type: String,
        required: true
    }) name!: string;

    @Prop({
        type: String
    }) label!: string;

    @Prop({
        type: String
    }) constraints!: string;

    @Prop({
        type: Boolean,
        default: false
    }) showSeconds!: boolean;

    @Prop({
        type: Number,
        default: 5
    }) minuteInterval!: number;

    @Prop({
        type: Number,
        default: 10
    }) secondInterval!: number;

    isFocused: boolean = false;

    get id() {
        return this.name + uuid();
    }

    get field() {
        return this.vvFields[this.name];
    }

    get required() {
        return this.field && this.field.required;
    }

    get error() {
        return this.vvErrors.first(this.name);
    }

    get listeners() {
        const { input, blur, ...listeners } = this.$listeners; // eslint-disable-line @typescript-eslint/no-unused-vars
        return listeners;
    }

    get format() {
        return this.showSeconds ? 'HH:mm:ss' : 'HH:mm';
    }

    onFocus() {
        this.isFocused = true;
    }

    onInput(ev) {
        this.$emit('input', ev);

        if (this.field.validated && this.field.invalid) {
            this.validate();
        }
    }

    onBlur(ev) {
        this.$emit('blur', ev);

        if (this.field.dirty) {
            this.validate();
        }

        this.isFocused = false;
    }

    validate() {
        this.$validator.validate(this.name);
    }

    // From Vue-validate
    private vvErrors!: any;

    // From Vue-validate
    private vvFields!: any;
}
