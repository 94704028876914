































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CategoryScopeViewObject } from '@/types/serverContract';

@Component
export default class WebOffersCategories extends Vue {
    @Prop({
        required: true,
        type: Array
    }) categoryScopes!: CategoryScopeViewObject[];

    selectCategory(categoryId: string): void {
        this.$emit('categorySelected', categoryId);
    }
}
