import httpService from '@/core/http/http.service';
import {
    GetProjectOrderQuotationRequest,
    ProjectOrderQuotationListViewModel,
    QuotationDetailsViewModel,
    SearchProjectOrderQuotationsRequest
} from '@/types/serverContract';

export default class ProjectOrderQuotationController {
    public async search(payload: SearchProjectOrderQuotationsRequest): Promise<ProjectOrderQuotationListViewModel> {
        return httpService.get<ProjectOrderQuotationListViewModel>('projectOrderQuotationApi/search', payload);
    }

    public async projectOrderQuotationDetails(payload: GetProjectOrderQuotationRequest): Promise<QuotationDetailsViewModel> {
        return httpService.get<QuotationDetailsViewModel>('projectOrderQuotationApi/projectOrderQuotation', payload);
    }
}
