













































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import CopyFavoriteOrderForm from '@/project/favoriteOrders/CopyFavoriteOrderForm.vue';
import { downloadApi } from '@/project/config/utilities';
import keyboardService from '@/core/keyCodes';
import DownloadCatalogForm from '@/project/shared/DownloadCatalogForm.vue';
import {
    BaseReportRequest,
    DownloadFavoriteOrderAsCsvRequest,
    FavoriteReportAsEmailRequest
} from '@/types/serverContract';
import urlHelper from '@/project/favoriteOrders/urlHelperFavoriteOrders.service';

@Component({
    components: {
        DownloadCatalogForm,
        CopyFavoriteOrderForm,
        SpinnerOverlay,
        ModalOverlay
    }
})
export default class FavoriteOrderActionLinks extends Vue {
    @Prop({
        required: true,
        type: Number
    }) favoriteOrderId!: number;

    @Prop({
        required: true,
        type: Boolean
    }) allowEdit!: boolean;

    pendingDeleteAllItems: boolean = false;
    showConfirmDeleteAllItems: boolean = false;

    showCopyFavoriteOrderModal: boolean = false;

    showDownloadFavoriteOrderModal: boolean = false;
    pending: boolean = false;
    success: boolean = false;
    successEmail: string | null = null;
    successTimer: number = 4000;
    timeout: number | null = null;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async deleteAllLineItems() {
        this.pendingDeleteAllItems = true;
        try {
            this.closeConfirmDeleteAllItems();
            await api.favoriteOrder.deleteAllLineItems(this.favoriteOrderId).then(() => this.$emit('updateFavoriteOrder'));
        } finally {
            this.pendingDeleteAllItems = false;
        }
    }

    downloadAsCsv() {
        const downloadFavoriteAsCsvRequest: DownloadFavoriteOrderAsCsvRequest = {
            sortBy: urlHelper.getSorting(),
            favoriteOrderId: this.favoriteOrderId,
            lineSearchTerm: urlHelper.getSearchTerm()
        };
        downloadApi('api/FavoriteOrderApi/DownloadAsCsv', downloadFavoriteAsCsvRequest);
    }

    closeCopyFavoriteOrderModal() {
        this.showCopyFavoriteOrderModal = false;
    }

    openCopyFavoriteOrderModal() {
        this.showCopyFavoriteOrderModal = true;
    }

    openConfirmDeleteAllItems() {
        this.showConfirmDeleteAllItems = true;
        document.addEventListener('keyup', this.keyHandler);
    }

    closeConfirmDeleteAllItems() {
        this.showConfirmDeleteAllItems = false;
        document.removeEventListener('keyup', this.keyHandler);
    }

    keyHandler(event) {
        if (keyboardService.isEnter(event)) {
            this.deleteAllLineItems();
        }
    }

    openDownloadFavoriteOrderModal() {
        this.showDownloadFavoriteOrderModal = true;
    }

    async sendCatalog(baseReportRequest: BaseReportRequest, emailAddressForReport: string) {
        this.pending = true;

        const favoriteReportAsEmailRequest: FavoriteReportAsEmailRequest = {
            term: urlHelper.getSearchTerm(),
            sortBy: urlHelper.getSorting(),
            favoriteOrderId: this.favoriteOrderId,
            reportType: baseReportRequest.reportType,
            priceType: baseReportRequest.priceType,
            productNumberType: baseReportRequest.productNumberType,
            emailAddress: emailAddressForReport
        };

        try {
            const reportAsEmailModel = await api.reports.requestFavoriteOrderReport(favoriteReportAsEmailRequest);
            this.successEmail = reportAsEmailModel.email;
            this.showSuccessIndicator();
        } finally {
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.closeDownloadFavoriteOrderModal();
            this.success = false;
            this.successEmail = null;
        }, this.successTimer);
    }

    closeDownloadFavoriteOrderModal() {
        this.showDownloadFavoriteOrderModal = false;
    }
}
