







































import { Component, Prop } from 'vue-property-decorator';
import BaseBanner from '@/components/molecules/Banner/BaseBanner.vue';
import VideoPlayer from '@/components/atoms/video/VideoPlayer.vue';

@Component({
    components: {
        VideoPlayer,
        BaseBanner
    }
})
export default class VideoBanner extends BaseBanner {
    @Prop({ default: '', type: String }) videoUrl!: string;
    @Prop({ default: true, type: Boolean }) videoAutoplay!: boolean;

    get headingClasses(): string {
        return (!this.subheader && !this.preheader) ? 'text-32 md:text-40' : 'text-24 md:text-28 mb-0 pt-8';
    }
}
