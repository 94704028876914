


























import Vue from 'vue';
import { BasketGetter } from '@/store/basket';
import serverContext from '@/core/serverContext.service';
import Component from 'vue-class-component';
import {
    IFeatureToggling
} from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import { FlexBoxGetter } from '@/store/flexBox';
import tracking from '@/core/tracking/tracking.service';

@Component
export default class MiniBasket extends Vue {
    @BasketGetter totalLines!: number;
    @AppGetter featureToggling!: IFeatureToggling;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    get basketPageUrl(): string {
        return serverContext.sitePages.basketPage.url;
    }

    navigationTracking() {
        tracking.trackNavigationClick({ type: 'Top menu', clickText: 'Cart' });
    }
}
