
import { Component, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { BreakpointsMixin } from './breakpoints.mixin';
import breakpointsService from './breakpoints.service';
import map from 'lodash-es/map';

@Component({
    mixins: [BreakpointsMixin]
})
export default class Breakpoint extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    // Use prop sizes:  sizes='sm' or 'sm,md' or 'min-md' or 'max-lg'
    // Or skip 'sizes' and just define as many slots you want with their 'name' set to breakpoints:
    // <breakpoints>
    //   <div slot="sm">
    //     xx
    //   </div>
    //   <div slot="min-md">
    //     yy
    //   </div>
    // </breakpoints>
    @Prop(String) private sizes!: string;

    private render() {
        if (this.sizes) {
            return this.visible(this.sizes) ? this.$slots.default![0] : undefined;
        } else {
            const visibleSlots = map(this.$slots, (slot, key) => {
                return {
                    visible: this.visible(key),
                    slot
                };
            })
                .filter(x => x.visible)
                .map(x => x.slot);
            return visibleSlots.length ? visibleSlots![0]![0] : undefined;
        }
    }

    private visible(sizes: string): boolean {
        const sizesAsArray = sizes.split(',').map(l => l.trim());
        let finalSizes: string[] = [];

        function stripPrefix(size: string): string {
            return size.substr(size.indexOf('-') + 1);
        }

        for (let size of sizesAsArray) {
            // When up or down, add missing breakpoints
            if (size.startsWith('min-') || size.startsWith('max-')) {
                const pureSize = stripPrefix(size);
                // These are ordered by size
                const breakpointKeys = breakpointsService.getBreakpoints().map(bp => bp.name);
                const index = breakpointKeys.indexOf(pureSize);
                const keysToTake = size.startsWith('max-')
                    ? breakpointKeys.slice(0, index + 1)
                    : breakpointKeys.slice(index);
                finalSizes = [...finalSizes, ...keysToTake];
            } else {
                finalSizes.push(size);
            }
        }
        return finalSizes.some(size => this.breakpointIsActive[size]);
    }
}
