












































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
    EasySupplyWarehouseLineViewObject,
    UpdateEasySupplyWarehouseLineRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class SmartButton extends Vue {
    @Prop({
        required: true,
        type: Object
    }) warehouseLine!: EasySupplyWarehouseLineViewObject;

    @Prop({
        required: true,
        type: String
    }) statusIndicator!: string;

    @Prop({
        required: true,
        type: String
    }) locationDescription!: string;

    @Prop({
        required: true,
        type: String
    }) reorderQuantity!: string;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number = 0;
    reorderThreshold: number | null = 0;

    created(): void {
        this.setReorderThreshold();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    @Watch('warehouseLine')
    onWarehouseLineChange(): void {
        this.setReorderThreshold();
    }

    async editLine() {
        if (this.pending) return;

        this.pending = true;

        const payload: UpdateEasySupplyWarehouseLineRequest = {
            easySupplyWarehouseId: this.warehouseLine!.easySupplyWarehouseId,
            easySupplyWarehouseLineId: this.warehouseLine!.id,
            locationDescription: this.locationDescription,
            reorderQuantity: parseInt(this.reorderQuantity, 10),
            reorderThreshold: this.reorderThreshold
        };

        try {
            await Api.easySupply.editEasySupplyWarehouseLine(payload);
            this.showSuccessIndicator();
            this.$emit('updatedWarehouse');
            this.closeModal();
        } finally {
            this.pending = false;
        }
    }

    setReorderThreshold() {
        this.reorderThreshold = this.warehouseLine && isNotNullEmptyOrUndefined(this.warehouseLine.reorderThreshold) ? this.warehouseLine.reorderThreshold : 0;
    }

    showDeleteSmartButton(): void {
        this.$emit('showDeleteSmartButton');
    }

    closeModal() {
        this.$emit('closeModal');
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors!: any;
}
