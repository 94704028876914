// import { raf, caf } from 'request-animation-frame-polyfill';

class ScrollService {
    private throttleDelay: number = 500;

    public scrollToTop(container: HTMLElement = document.documentElement) {
        container.scrollTo(0, 0);
    }

    public scrollToElement(element: HTMLElement, offset: number = 0, duration: number = 200, container: HTMLElement = document.documentElement) {
        duration === 0
            ? this.simpleScroll(element.offsetTop + offset, container)
            : this.animateScroll(element.offsetTop + offset, container.scrollTop, container, duration);
    }

    public scrollIntoView(element: HTMLElement, alignTo: boolean = true) {
        element.scrollIntoView(alignTo);
    }

    private simpleScroll(to: number, container: HTMLElement) {
        container.scrollTo(0, to);
    }

    private animateScroll(to: number, from: number, container: HTMLElement, duration: number) {
        const diff = to - from;
        let start;
        window.requestAnimationFrame(function step(timestamp) {
            if (!start) {
                start = timestamp;
            }
            const time = timestamp - start;
            const percent = Math.min(time / duration, 1);

            container.scrollTo(0, from + diff * percent);

            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        });
    }

    public onScroll(callback: (event: { event: Event; isTop: boolean; isUp: boolean }) => void) {
        let isThrottled = false;
        let previousDistanceFromTop = document.documentElement.scrollTop;
        let previousScrollIsUp = false;

        window.addEventListener('scroll', (event) => {
            const scrollDistanceFromTop = document.documentElement.scrollTop;
            const isTop = scrollDistanceFromTop <= 0;
            const isUp = scrollDistanceFromTop < previousDistanceFromTop;
            const modifiedEvent = { event, isTop, isUp };
            previousDistanceFromTop = scrollDistanceFromTop;

            if (previousScrollIsUp !== isUp) {
                previousScrollIsUp = isUp;
                callback(modifiedEvent);
                return;
            }
            if (isTop) {
                callback(modifiedEvent);
                return;
            }
            if (!isThrottled) {
                previousScrollIsUp = isUp;
                callback(modifiedEvent);
                isThrottled = true;
                setTimeout(() => {
                    isThrottled = false;
                }, this.throttleDelay);
            }
        });
    }
}
// t = current time
// b = start value
// c = change in value
// d = duration
(Math as any).easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) {
        return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
};

export default new ScrollService();
