
import { Component, Vue, Prop } from 'vue-property-decorator';
import { RevenueViewModel } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';

interface ChartDataSetViewModel {
    backgroundColor?: string[] | string;
    borderColor?: string[] | string;
    borderWidth?: number | string;
    data: string | number[];
    hidden?: boolean;
    label: string;
}

@Component
export default class DashboardRevenueChart extends Vue {
    @Prop({
        required: true,
        type: Object
    }) revenueData!: RevenueViewModel;

    @Prop({
        default: 'DKK',
        type: String
    }) revenueLabel!: string;

    baseChartOptions: object = {
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value) => {
                        return `${value} ${this.revenueLabel}`;
                    }
                }
            }]
        },
        tooltips: {
            enabled: true,
            callbacks: {
                label: (tooltipItem) => {
                    return `${new Intl.NumberFormat(serverContext.Localization.language, { maximumFractionDigits: 0 }).format(parseFloat(tooltipItem.yLabel))} ${this.revenueLabel}`;
                }
            }
        },
        width: null,
        height: null,
        responsive: true,
        aspectRatio: 4,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0
    };

    get chartLabels(): string[] {
        return this.revenueData.revenues.map(revenue => {
            return revenue.label;
        });
    }

    get chartDataset(): ChartDataSetViewModel[] {
        return [{
            label: this.revenueLabel,
            data: this.revenueData.revenues.map(revenue => revenue.revenue),
            backgroundColor: '#0093ce'
        }];
    }

    get chartDatasetCollection(): object {
        return {
            labels: this.chartLabels,
            datasets: this.chartDataset
        };
    }

    render() {
        return this.$scopedSlots.default!({
            chartDataset: this.chartDatasetCollection,
            chartOptions: this.baseChartOptions
        });
    }
}
