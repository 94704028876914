import httpService from '@/core/http/http.service';
import {
    ForgotPasswordRequest,
    ForgotPasswordResponse,
    LoginRequest,
    ResetPasswordRequest,
    MustChangePasswordRequest
} from '@/types/serverContract';

export default class AuthenticationController {
    public async login(username: string, password: string, currentUrl: string, messagesId: string): Promise<void> {
        return httpService.post<void>('authenticationApi/login', { username, password, currentUrl } as LoginRequest, messagesId);
    }

    public async loginWithRedirectToRoot(username: string, password: string, messagesId: string): Promise<void> {
        return httpService.post<void>('authenticationApi/login', { username, password } as LoginRequest, messagesId);
    }

    public async logout(): Promise<void> {
        return httpService.post<void>('authenticationApi/logout');
    }

    public async changeAccount(soldToPartnerId: string): Promise<void> {
        return httpService.post<void>('authenticationApi/changeAccount', { soldToPartnerId });
    }

    public async forgotPassword(email: string, username: string | null, messagesId: string): Promise<ForgotPasswordResponse> {
        return httpService.post<ForgotPasswordResponse>('authenticationApi/forgotPassword', { email, username } as ForgotPasswordRequest, messagesId);
    }

    public async resetPassword(key: string, newPassword: string, messagesId: string): Promise<void> {
        return httpService.post<void>('authenticationApi/resetPassword', { key, newPassword } as ResetPasswordRequest, messagesId);
    }

    public async mustChangePassword(newPassword: string, confirmPassword: string, userName: string, messagesId: string): Promise<void> {
        return httpService.post<void>('authenticationApi/mustChangePassword', { newPassword, confirmPassword, userName } as MustChangePasswordRequest, messagesId);
    }
}
