



























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    AddEasySupplyProcuredProductToWarehouseRequest,
    EasySupplyWarehouseLineProcuredProductViewObject
} from '@/types/serverContract';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import Api from '@/project/http/api';

@Component({
    components: { ButtonSubmit }
})
export default class AddProcuredProductsToWarehouseItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) warehouse!: EasySupplyWarehouseLineProcuredProductViewObject;

    @Prop({
        required: true,
        type: Number
    }) procuredProductId!: number;

    location: string = this.warehouse && this.warehouse.location ? this.warehouse.location : '';
    productQuantity: string = this.warehouse ? this.warehouse.quantity.toString() : '0';
    pending: boolean = false;
    success: boolean = false;
    successTimer = 2500;
    timeout: number | null = null;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async addToWarehouse() {
        await this.$validator.validate();
        if (this.$validator.errors.any()) {
            return;
        }

        this.pending = true;

        const payload: AddEasySupplyProcuredProductToWarehouseRequest = {
            easySupplyWarehouseId: this.warehouse.warehouseId,
            procuredProductId: this.procuredProductId,
            quantity: parseInt(this.productQuantity, 10),
            location: this.location
        };

        try {
            await Api.easySupply.addProcuredProductToWarehouse(payload);
            this.showSuccessIndicator();
        } finally {
            this.pending = false;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }
}
