






















































































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    UpdateCurrentUserRequest,
    UserStoreViewObject,
    UserViewModel,
    IFeatureToggling
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { AppGetter } from '@/store/app';
import AccountAccessesList from '@/project/user/AccountAccessesList.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import UpdateMyUserChangePassword from '@/project/user/UpdateMyUserChangePassword.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import UpdateUserPunchOut from '@/project/user/UpdateUserPunchOut.vue';
import scrollToFirstInputError from '@/project/user/scroll-to-first-input-error';
import UpdateMailSubscriptions from '@/project/user/UpdateMailSubscriptions.vue';

@Component({
    components: {
        UpdateUserPunchOut,
        ButtonSubmit,
        UpdateMyUserChangePassword,
        SpinnerOverlay,
        AccountAccessesList,
        UpdateMailSubscriptions
    }
})
export default class UpdateMyUser extends Vue {
    @AppGetter featureToggling!: IFeatureToggling;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    hasInitialPayload: boolean = false;
    timeout: number | null = null;
    setTwentyFourSevenAccess: boolean = false;
    userInformation: UserViewModel = {
        accountRelations: [],
        activeStatus: false,
        isDeveloper: false,
        isPunchOut: false,
        isEasySupplyAdmin: false,
        hasPunchOutCustomXslt: false,
        canEditUser: false,
        canEditDeveloperRole: false,
        canEditPunchOutRole: false,
        canEditActiveStatus: false,
        canEditUsername: false,
        canEditEasySupplyAdminRole: false,
        canEditTwentyFourSevenRole: false,
        canRemoveTwentyFourSevenRole: false,
        comments: '',
        emailAddress: '',
        identifier: '',
        name: '',
        orderReceiptsRecipients: '',
        primarySoldToPartnerId: '',
        receiveOrderReceipts: false,
        storeId: '',
        stores: [],
        username: '',
        webMobileNumber: '',
        isSubscribedToMarketingMails: false,
        hasTwentyFourSevenAccess: false
    };

    get updateMyUserPayload(): UpdateCurrentUserRequest {
        const {
            canEditActiveStatus,
            canEditDeveloperRole,
            canEditPunchOutRole,
            canEditEasySupplyAdminRole,
            canEditTwentyFourSevenRole,
            canRemoveTwentyFourSevenRole,
            activeStatus,
            isDeveloper,
            isPunchOut,
            isEasySupplyAdmin,
            orderReceiptsRecipients,
            comments,
            emailAddress,
            webMobileNumber,
            name,
            primarySoldToPartnerId,
            receiveOrderReceipts,
            storeId,
            accountRelations,
            username
        } = this.userInformation;

        const twentyFourSevenAccess = canEditTwentyFourSevenRole || (canRemoveTwentyFourSevenRole && this.setTwentyFourSevenAccess !== true)
            ? this.setTwentyFourSevenAccess
            : null;

        const updateUserRelations = accountRelations
            .filter(relation => !relation.isReadOnly)
            .map(relation => ({
                canPurchase: relation.canPurchase.canBeEdited ? relation.canPurchase.hasRole : null,
                canSeeAccountInformation: relation.canSeeAccountInformation.canBeEdited ? relation.canSeeAccountInformation.hasRole : null,
                canSeeGrossPrices: relation.canSeeGrossPrices.canBeEdited ? relation.canSeeGrossPrices.hasRole : null,
                canSeeNetPrices: relation.canSeeNetPrices.canBeEdited ? relation.canSeeNetPrices.hasRole : null,
                hasAccess: relation.hasAccess.canBeEdited ? relation.hasAccess.hasRole : null,
                isAdminForAccount: relation.hasAccess.canBeEdited ? relation.accountAccess === this.$tr(this.$labels.Views.EditUser.Tabs.Access.Level.Admin) : null,
                soldToPartnerId: relation.soldToPartnerId,
                canPurchaseWithApproval: null,
                approverIdentifier: '' // Should be an admin user identifier
            }));

        return {
            active: canEditActiveStatus ? activeStatus : null,
            isDeveloper: canEditDeveloperRole ? isDeveloper : null,
            isPunchOut: canEditPunchOutRole ? isPunchOut : null,
            isEasySupplyAdmin: canEditEasySupplyAdminRole ? isEasySupplyAdmin : null,
            hasTwentyFourSevenAccess: twentyFourSevenAccess,
            carbonCopyRecipients: orderReceiptsRecipients,
            comments,
            email: emailAddress,
            mobileNumber: webMobileNumber,
            name,
            primarySoldToPartnerId,
            receiveOrderReceipts,
            storeId,
            updateUserRelations,
            username
        };
    }

    get isFormDirty() {
        return Object.keys(this.vvFields).some(key => this.vvFields[key].dirty);
    }

    get disableTwentyFourSevenAccessCheckbox() {
        return !this.userInformation.canEditTwentyFourSevenRole && this.userInformation.canRemoveTwentyFourSevenRole && !this.userInformation.hasTwentyFourSevenAccess;
    }

    get showRemoveMessage(): boolean {
        return !this.userInformation.canEditTwentyFourSevenRole && !this.showTwentyFourSevenHelpMessage ? (this.userInformation.canRemoveTwentyFourSevenRole && !this.setTwentyFourSevenAccess) : false;
    }

    get showTwentyFourSevenHelpMessage(): boolean {
        return !this.userInformation.canEditTwentyFourSevenRole ? (this.userInformation.canRemoveTwentyFourSevenRole && !this.userInformation.hasTwentyFourSevenAccess) : false;
    }

    get accessMessage(): string {
        return this.userInformation.hasTwentyFourSevenAccess ? this.$tr(this.$labels.Views.EditUser.TwentyFourSeven.HasAccess) : this.$tr(this.$labels.Views.EditUser.TwentyFourSeven.NoAccess);
    }

    $refs!: {
        myStoreSelect: Vue;
        updateUserPunchOut: UpdateUserPunchOut;
    };

    created() {
        this.getCurrentUser().then(() => {
            this.hasInitialPayload = true;
            this.setTwentyFourSevenAccess = this.userInformation.hasTwentyFourSevenAccess;
        });
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async validateForm() {
        await this.$validator.validate();

        if (this.vvErrors.items.length === 0) {
            await this.submitForm();
        } else {
            scrollToFirstInputError(this.$el, (this.$refs.myStoreSelect.$el as HTMLElement));
        }
    }

    async submitForm() {
        this.pending = true;
        try {
            this.userInformation = await Api.user.updateMyUser(this.updateMyUserPayload);
            this.showSuccessIndicator();
        } finally {
            this.pending = false;
        }
    }

    async getCurrentUser() {
        this.pending = true;
        try {
            await Api.user.getCurrentUserForUpdate().then(initialUserInformation => {
                Object.assign(this.userInformation, initialUserInformation);
            });
        } finally {
            this.pending = false;
        }
    }

    async onUploadPunchOutFile(): Promise<void> {
        if (this.isFormDirty) {
            await this.submitForm().then(() => {
                this.$validator.reset();
            });
        }
        (this.$refs.updateUserPunchOut as any).handlePunchOutFile();
    }

    onUserStoreChange(store: UserStoreViewObject) {
        this.userInformation.storeId = store.id;
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    private vvFields!: any;
    private vvErrors!: any;
}
