





























































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import {
    ProductIdType,
    SearchOrderHistoryRequest
} from '@/types/serverContract';
import dayjs, { Dayjs } from 'dayjs';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import BookkeepingUrlHelper from './BookkeepingUrlHelper.service';
import { dateParseFormat } from '@/project/config/utilities';
import { clone } from 'lodash-es';
import DatePickerCtrl from '@/project/form/DatePickerCtrl.vue';
import { Route } from 'vue-router';
import { KeyValue } from '@/project/config/models';

@Component({
    components: {
        DatePickerCtrl,
        SpinnerOverlay
    }
})
export default class OrderHistorySearchForm extends Vue {
    optionsProductIdTypeChange: KeyValue[] = Object.keys(ProductIdType)
        .filter((key: string): boolean => isNaN(Number(key)))
        .map((val: string): KeyValue => {
            return {
                key: val,
                value: this.$tr(this.$labels.Views.Bookkeeping.Invoices.ProductIdType[val])
            };
        });

    pending: boolean = false;
    selectedProductIdType: KeyValue | null = null;
    searchOrderHistoryRequestPayload: SearchOrderHistoryRequest = {
        caseNumber: BookkeepingUrlHelper.getSearchQuery('caseNumber'),
        fromDate: BookkeepingUrlHelper.getSearchQuery('fromDate') === '' ? dayjs().subtract(6, 'month').format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('fromDate'),
        orderNumber: BookkeepingUrlHelper.getSearchQuery('orderNumber'),
        page: BookkeepingUrlHelper.getPageNumber(),
        pageSize: 20,
        productId: BookkeepingUrlHelper.getSearchQuery('productId'),
        productIdType: BookkeepingUrlHelper.getSearchQuery('productIdType') === '' ? ProductIdType.Sani : (BookkeepingUrlHelper.getSearchQuery('productIdType') as unknown as ProductIdType),
        reference: BookkeepingUrlHelper.getSearchQuery('reference'),
        toDate: BookkeepingUrlHelper.getSearchQuery('toDate') === '' ? dayjs().format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('toDate'),
        requisitionNumber: BookkeepingUrlHelper.getSearchQuery('requisitionNumber'),
        onlyCertificateOrders: false
    };

    get isDateSpanTooLong(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) > 6;
    }

    get isDateSpanTooShort(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) < 0;
    }

    get dayjsFromDate(): Dayjs {
        return dayjs(this.searchOrderHistoryRequestPayload.fromDate);
    }

    get dayjsToDate(): Dayjs {
        return dayjs(this.searchOrderHistoryRequestPayload.toDate);
    }

    created() {
        this.searchOrderHistory();
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            return;
        }
        const pageChanged = this.searchOrderHistoryRequestPayload.page !== BookkeepingUrlHelper.getPageNumber();
        if (pageChanged) {
            this.searchOrderHistoryRequestPayload.page = BookkeepingUrlHelper.getPageNumber();
            this.searchOrderHistory();
        }
    }

    @Watch('searchOrderHistoryRequestPayload.fromDate')
    setFromDate(): void {
        if (this.isDateSpanTooLong) {
            this.searchOrderHistoryRequestPayload.toDate = this.dayjsFromDate.clone().add(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.searchOrderHistoryRequestPayload.toDate = clone(this.searchOrderHistoryRequestPayload.fromDate);
        }
    }

    @Watch('searchOrderHistoryRequestPayload.toDate')
    setToDate(): void {
        if (this.isDateSpanTooLong) {
            this.searchOrderHistoryRequestPayload.fromDate = this.dayjsToDate.clone().subtract(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.searchOrderHistoryRequestPayload.fromDate = clone(this.searchOrderHistoryRequestPayload.toDate);
        }
    }

    async searchOrderHistory(): Promise<void> {
        this.pending = true;
        try {
            await Api.orderHistory.search(this.searchOrderHistoryRequestPayload).then(searchOrderHistoryResult => {
                this.$emit('searchSuccess', searchOrderHistoryResult);
                BookkeepingUrlHelper.setSearchQuery({
                    caseNumber: this.searchOrderHistoryRequestPayload.caseNumber,
                    fromDate: this.searchOrderHistoryRequestPayload.fromDate,
                    orderNumber: this.searchOrderHistoryRequestPayload.orderNumber,
                    productId: this.searchOrderHistoryRequestPayload.productId,
                    productIdType: this.searchOrderHistoryRequestPayload.productIdType,
                    reference: this.searchOrderHistoryRequestPayload.reference,
                    toDate: this.searchOrderHistoryRequestPayload.toDate,
                    requisitionNumber: this.searchOrderHistoryRequestPayload.requisitionNumber,
                    page: this.searchOrderHistoryRequestPayload.page
                });
            });
        } finally {
            this.pending = false;
        }
    }

    onProductIdTypeChange(chosenProductIdType: any): void {
        this.searchOrderHistoryRequestPayload.productIdType = (ProductIdType[chosenProductIdType.key] as unknown as ProductIdType);
        this.selectedProductIdType = this.optionsProductIdTypeChange[this.searchOrderHistoryRequestPayload.productIdType];
    }

    submitForm(): void {
        this.searchOrderHistoryRequestPayload.page = 1;
        this.searchOrderHistory();
    }
}
