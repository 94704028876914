import httpService from '@/core/http/http.service';
import {
    ConfirmUserDeletionRequest,
    CreateUserFromAdminRequest,
    CreateUserRequest,
    DeleteUserRequest,
    DeleteUserResultViewModel,
    UpdateCurrentUserRequest,
    UpdateMyPasswordRequest,
    UpdateUserPasswordRequest,
    UpdateUserRequest,
    UserViewModel,
    UserCreationViewModel
} from '@/types/serverContract';

export default class UserController {
    public async createUser(payLoad: CreateUserRequest, messagesId: string): Promise<void> {
        return httpService.post<void>('userApi/createUser', payLoad as CreateUserRequest, messagesId);
    }

    public async createWebUser(payload: CreateUserRequest, messagesId: string) {
        return httpService.post('userApi/CreateWebUser', payload, messagesId).then(response => {
            Promise.resolve(response);
        }).catch(e => {
            if (e.isRedirect) {
                Promise.resolve(e);
                return;
            }

            if (e.data && e.data.clientMessage && e.data.clientMessage.messages[0].message) {
                throw e.data.clientMessage.messages[0].message;
            } else if (e.data && e.data.exceptionMessage) {
                throw e.data.exceptionMessage;
            }
            throw e.data.message;
        });
    }

    public async getCurrentUserForUpdate(): Promise<UserViewModel> {
        return httpService.get<UserViewModel>('userApi/CurrentUserForUpdate');
    }

    public async getUserForCreate(): Promise<UserViewModel> {
        return httpService.get<UserViewModel>('userApi/userForCreate');
    }

    public async getUserCreationOptions(): Promise<UserCreationViewModel> {
        return httpService.get<UserCreationViewModel>('userApi/userCreationOptions');
    }

    public async getUserForUpdate(identifier: string): Promise<UserViewModel> {
        return httpService.get<UserViewModel>('userApi/userForUpdate', { identifier });
    }

    public async update(payload: UpdateUserRequest): Promise<UserViewModel> {
        return httpService.post<UserViewModel>('userApi/UpdateUser', payload);
    }

    public async updateMyUser(payload: UpdateCurrentUserRequest): Promise<UserViewModel> {
        return httpService.post<UserViewModel>('userApi/UpdateCurrentUser', payload);
    }

    public async createUserFromAdmin(payload: CreateUserFromAdminRequest): Promise<void> {
        return httpService.post<void>('userApi/createUserByAdmin', payload);
    }

    public async changeMyPassword(payload: UpdateMyPasswordRequest): Promise<void> {
        return httpService.post<void>('userApi/changeMyPassword', payload);
    }

    public async changeUserPassword(payload: UpdateUserPasswordRequest): Promise<void> {
        return httpService.post<void>('userApi/changeUserPassword', payload);
    }

    public async deleteUser(payload: DeleteUserRequest): Promise<DeleteUserResultViewModel> {
        return httpService.delete<DeleteUserResultViewModel>('userApi/deleteUser', payload);
    }

    public async confirmUserDeletion(payload: ConfirmUserDeletionRequest): Promise<void> {
        return httpService.post<void>('userApi/confirmUserDeletion', payload);
    }

    public async addXsltToUser(identifier: string, payload: any): Promise<void> {
        return httpService.post('userApi/addXsltToUser', payload, undefined, { identifier });
    }

    public async removeXsltFromUser(identifier: string): Promise<void> {
        return httpService.post('userApi/removeXsltFromUser', undefined, undefined, { identifier });
    }
}
