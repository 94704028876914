




































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ProductTileViewObject, BasketViewModel, PriceViewObject, ProductTileTrackingContext } from '@/types/serverContract';
import ProductVariantIds from '@/project/products/ProductVariantIds.vue';
import { AppGetter } from '@/store/app';
import ProductPrices from '@/project/products/ProductPrices.vue';
import ProductTileWrapper from '@/project/products/ProductTileWrapper.vue';
import ProductInventory from '@/project/products/ProductInventory.vue';
import ProductTileSkeletonPrice from '@/project/shared/skeleton/ProductTile/ProductTileSkeletonPrice.vue';
import ProductVariantPicker from '@/project/products/ProductVariantPicker.vue';
import ProductAddToBasket from '@/project/products/ProductAddToBasket.vue';
import ScalePricesCompact from '@/project/products/ScalePricesCompact.vue';
import ProductCo2Info from '@/project/products/ProductCo2Info.vue';
import ProductNudgingTile from '../products/ProductNudgingTile.vue';
import Api from '@/project/http/api';
import Accordion from '@/project/shared/Accordion.vue';
import { BasketGetter } from '@/store/basket';

@Component({
    components: {
        ProductCo2Info,
        ProductAddToBasket,
        ProductVariantPicker,
        ProductTileSkeletonPrice,
        ProductInventory,
        ProductTileWrapper,
        ProductVariantIds,
        ProductPrices,
        ProductNudgingTile,
        ScalePricesCompact,
        Accordion
    }
})
// Changes made to this component should also be made to ProductNudgingTile.vue
export default class SearchProductNudgingTile extends Vue {
    @Prop({
        required: false,
        type: Object
    })
    trackingContext!: ProductTileTrackingContext;

    @Prop({
        required: true,
        type: String
    }) productId!: string;

    @Prop({
        default: false,
        type: Boolean
    }) isWide!: boolean;

    @Prop({
        required: false,
        type: String
    }) raptorMethod!: string;

    @Prop({
        required: false,
        type: String
    }) raptorEvent!: string;

    @Prop({
        default: false,
        type: Boolean
    }) selectDefaultVariant!: boolean;

    @Prop({
        default: false,
        type: Boolean
    }) defaultOpen!: boolean;

    @Prop({
        default: false,
        type: Boolean
    }) nudgingShow!: boolean;

    @AppGetter isLoggedIn!: boolean;
    @BasketGetter basket!: BasketViewModel;
    show: boolean = false;
    customShow: boolean = this.nudgingShow;
    recommendedProducts: ProductTileViewObject[] | null = null;
    recommendedProduct: ProductTileViewObject | null = null;
    alreadyLoaded: boolean = false;

    onCustomShowUpdate(newValue) {
        this.$emit('update:nudgingShow', newValue);
    }

    get nudgingTrackingContext() {
        if (this.trackingContext == null) {
            return null;
        }
        let trackingContextCopy: ProductTileTrackingContext = { ...this.trackingContext };
        trackingContextCopy.list = this.trackingContext.list.replace(/\/[^/]*\/?$/, '/nudgingForSales');
        return trackingContextCopy;
    }

    @Watch('basket', { immediate: true }) // Wait for the basket to load before checking if the product is in it. If the basket is already loaded, check immediately.
    onBasketChanged() {
        if (this.isLoggedIn && !this.alreadyLoaded) {
            Api.recommendedProducts.getRecommendedProducts([this.productId]).then((recommendedProductsViewModel) => {
                this.alreadyLoaded = true;
                // take the first product recommended for the current product put into basket, which is not already in the basket
                if (recommendedProductsViewModel && recommendedProductsViewModel.length > 0 && recommendedProductsViewModel[0].recommendedProducts.length > 0) {
                    const notInBasket = recommendedProductsViewModel[0].recommendedProducts.filter(product => !this.isInBasket(product));
                    this.recommendedProduct = notInBasket[0];
                }
            }).finally(() => {
                setTimeout(() => {
                    // say to the parent component if we have a recommended product
                    this.$nextTick(() => {
                        this.$emit('hasRecommendedProduct', !!this.recommendedProduct);
                    });
                }, 200);
            });
        }
    }

    isInBasket(product: ProductTileViewObject) {
        return this.basket.lineItems.filter(lineItem => lineItem.productId === product.id).length > 0;
    }

    toggleShow(container: HTMLElement | undefined) {
        const accordion = this.$refs.Accordion as Accordion;
        accordion.toggleShow(container);
    }

    async priceLoaded(price: PriceViewObject) {
        this.$emit('priceLoaded', price);
    }
}
