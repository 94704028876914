
































import Vue from 'vue';
import Component from 'vue-class-component';
import { AppGetter } from '@/store/app';
import { LinkItem } from '@/types/serverContract';

@Component({
    name: 'breadcrumb'
})
export default class Breadcrumb extends Vue {
    @AppGetter public breadcrumb!: LinkItem[];

    get realBreadcrumb(): LinkItem[] {
        // Don't show if only 1 level
        return this.breadcrumb == null || this.breadcrumb.length <= 1 ? [] : this.breadcrumb;
    }
}
