




































































































import Vue from 'vue';
import {
    Component,
    Prop
} from 'vue-property-decorator';
import {
    CreateEasySupplyProcuredProductRequest,
    EasySupplyProcuredProductViewObject,
    ProcuredProductUnitsViewModel, UnitViewObject, UpdateEasySupplyProcuredProductRequest
} from '@/types/serverContract';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import Api from '@/project/http/api';
import EasySupplyProcuredProductUsedIn from '@/project/easySupply/procuredProducts/EasySupplyProcuredProductUsedIn.vue';

@Component({
    components: {
        EasySupplyProcuredProductUsedIn,
        ButtonSubmit
    }
})
export default class CreateOrUpdateProcuredProduct extends Vue {
    @Prop({
        default: null,
        type: Object
    }) procuredProduct!: EasySupplyProcuredProductViewObject;

    procuredProductUnits: ProcuredProductUnitsViewModel | null = null;
    selectedUnit: UnitViewObject | null = null;
    pending: boolean = false;
    unitsPending: boolean = false;
    customId: string = this.procuredProduct ? this.procuredProduct.customId : '';
    supplierId: string = this.procuredProduct ? this.procuredProduct.supplierId : '';
    supplierProductId: string = this.procuredProduct ? this.procuredProduct.supplierProductId : '';
    name: string = this.procuredProduct ? this.procuredProduct.name : '';
    description: string = this.procuredProduct ? this.procuredProduct.description : '';
    success: boolean = false;

    get canEdit(): boolean {
        if (!this.procuredProduct) { return true; }

        return this.procuredProduct.canEdit;
    }

    get buttonLabel(): string {
        return this.procuredProduct ? this.$tr(this.$labels.Views.SpecialOrder.Save) : this.$tr(this.$labels.Views.SpecialOrder.Add);
    }

    created() {
        this.getProcuredProductUnits();
        this.selectedUnit = this.procuredProduct.unit;
    }

    async getProcuredProductUnits() {
        this.unitsPending = true;
        try {
            this.procuredProductUnits = await Api.easySupply.procuredProductUnits();
        } finally {
            this.unitsPending = false;
        }
    }

    async updateProcuredProduct() {
        this.pending = true;

        const payload: UpdateEasySupplyProcuredProductRequest = {
            procuredProductId: this.procuredProduct!.id,
            customId: this.customId,
            supplierId: this.supplierId,
            supplierProductId: this.supplierProductId,
            name: this.name,
            description: this.description,
            unitCode: this.selectedUnit!.unitCode
        };

        try {
            const newEasySupplyProcuredProductsListViewModel = await Api.easySupply.updateEasySupplyProcuredProduct(payload);
            this.closeModal();
            this.$emit('updatedProcuredProductsModel', newEasySupplyProcuredProductsListViewModel);
        } finally {
            this.pending = false;
        }
    }

    async createProcuredProduct() {
        this.pending = true;

        const payload: CreateEasySupplyProcuredProductRequest = {
            customId: this.customId,
            supplierId: this.supplierId,
            supplierProductId: this.supplierProductId,
            name: this.name,
            description: this.description,
            unitCode: this.selectedUnit!.unitCode
        };

        try {
            const newEasySupplyProcuredProductsListViewModel = await Api.easySupply.createEasySupplyProcuredProduct(payload);
            this.closeModal();
            this.$emit('updatedProcuredProductsModel', newEasySupplyProcuredProductsListViewModel);
        } finally {
            this.pending = false;
        }
    }

    submit() {
        if (this.procuredProduct) {
            this.updateProcuredProduct();
        } else {
            this.createProcuredProduct();
        }
    }

    closeModal(): void {
        this.$emit('closeModal');
    }
}
