






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import { CopyTop100ProductsToFavoriteOrderRequest } from '@/types/serverContract';
import AddToFavoriteOrderCreateOrChooseOrder from '@/project/favoriteOrders/AddToFavoriteOrderCreateOrChooseOrder.vue';
import { IChooseFavoriteOrderListModel } from '@/project/favoriteOrders/addToFavoriteOrder.service';

@Component({
    components: {
        AddToFavoriteOrderCreateOrChooseOrder,
        ButtonSubmit
    }
})
export default class AddTop100ProductsToFavoriteOrderForm extends Vue {
    @Prop({
        required: true,
        type: Number
    }) monthsBack!: number;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    isExistingListChosen: boolean = false;
    timeout: number | null = null;
    copyTop100ProductsToFavoriteOrderPayload: CopyTop100ProductsToFavoriteOrderRequest = {
        favoriteOrderName: '',
        monthsBack: this.monthsBack,
        shared: false,
        targetFavoriteOrderId: null
    };

    get buttonLabel(): string {
        return this.isExistingListChosen ? this.$tr(this.$labels.Views.ProductDetails.AddToFavoriteOrder) : this.$tr(this.$labels.Views.FavoriteOrder.CreateAndAdd);
    }

    get buttonSuccessLabel(): string {
        return this.isExistingListChosen ? this.$tr(this.$labels.Views.FavoriteOrder.ItemsAdded) : this.$tr(this.$labels.Views.FavoriteOrder.Created);
    }

    $refs!: {
        AddToFavoriteOrderCreateOrChooseOrder: AddToFavoriteOrderCreateOrChooseOrder;
    };

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async submit() {
        const isFormValid = await (this.$refs.AddToFavoriteOrderCreateOrChooseOrder as any).validateForm();
        if (!isFormValid) {
            return;
        }
        this.pending = true;

        try {
            await Api.favoriteOrder.copyTop100ProductsToFavoriteOrder(this.copyTop100ProductsToFavoriteOrderPayload);
            this.showSuccessIndicator();
        } finally {
            this.pending = false;
        }
    }

    updateFavoriteOrderList(payload: IChooseFavoriteOrderListModel): void {
        this.copyTop100ProductsToFavoriteOrderPayload.favoriteOrderName = payload.newFavoriteOrderName;
        this.copyTop100ProductsToFavoriteOrderPayload.shared = payload.shared;
        this.copyTop100ProductsToFavoriteOrderPayload.targetFavoriteOrderId = payload.targetFavoriteOrderId;

        this.isExistingListChosen = payload.activeTab === 'existingList';
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
            this.$emit('closeModal');
        }, this.successTimer);
    }
}
