








































































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import scrollService from '@/core/scroll/scroll.service';
import './round.filter';

@Component
export default class VaRainWaterCassettes extends Vue {
    cavityProcent: number = 0.96;
    // Sand
    sandAreal: number = 300;
    sandHeight: number = 0.4;
    sandWidth: number = 0.5;
    sandNumber: number = 0.001;
    sandResult: number = 0;
    showSandCalculations: boolean = false;
    // Ds
    dsAreal: number = 200;
    dsCoefficient: number = 1;
    dsIntensity: number = 0.014;
    dsResult: number = 0;
    showDsCalculations: boolean = false;

    // Sand
    get sandTimeEmpty(): number {
        return this.getTimeEmpty(this.sandWidth, this.cavityProcent, this.sandNumber);
    }

    get sandFascineVolume(): number {
        return this.getFascineVol(9.1, this.sandAreal, this.sandNumber);
    }

    get sandLength(): number {
        return this.getLength(this.sandHeight, this.sandWidth, this.cavityProcent, this.sandFascineVolume);
    }

    public $refs!: {
        calcDS440Element: HTMLElement;
        calcSandElement: HTMLElement;
    };

    created(): void {
        this.sandResult = this.calculateSand();
        this.dsResult = this.calculateDs();
    }

    // Sand
    calculateSand(): number {
        const sandResult = (((this.sandHeight * this.sandWidth) * this.sandLength) / 0.2);
        this.sandResult = Math.ceil(sandResult);
        return sandResult;
    };

    toggleSand(): void {
        this.showSandCalculations = !this.showSandCalculations;
    }

    // Ds
    calculateDs(): number {
        const b51 = (this.dsAreal * this.dsCoefficient * this.dsIntensity * 600);
        const dsResult = Math.ceil(b51 / 200);
        this.dsResult = dsResult;
        return dsResult;
    };

    toggleDs(): void {
        this.showDsCalculations = !this.showDsCalculations;
    }

    getTimeEmpty(width, cavity, number): number {
        return (width * cavity / number);
    };

    getFascineVol(number1, areal, number): number {
        return (number1 * number * areal);
    };

    getLength = function(height, width, cavity, faskinevol) {
        return (faskinevol / (height * width * cavity));
    };

    goto(element: HTMLElement): void {
        scrollService.scrollToElement(element);
    };
}
