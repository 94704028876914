










































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { OrderDetailsLineViewObject } from '@/types/serverContract';

    @Component
export default class OrderHistoryDetailsLineItem extends Vue {
        @Prop({
            required: true,
            type: Object
        }) lineItem!: OrderDetailsLineViewObject;
}
