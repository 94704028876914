









import Vue, { VNodeData } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

enum LinkType {
    ExternalLink = 'ExternalLink',
    InternalLink = 'InternalLink',
    PassThrough = 'PassThrough'
}

@Component
export default class RouterLinkConditional extends Vue {
    @Prop({ type: String, default: '' }) to!: string;
    @Prop({ type: Boolean, default: false }) isDownload!: boolean;
    @Prop(String) tag!: string;
    @Prop(String) target!: string;
    @Prop({ type: Boolean, default: false }) preventDefault!: boolean;

    get isExternal(): boolean {
        const external = RegExp('^((f|ht)tps?:)?//(?!' + location.host + ')') || this.target === '_blank'; // Be aware of url formatting (mjo)

        return external.test(this.to);
    }

    get isExternalScheme(): boolean {
        const externalScheme = RegExp('^(?:data|chrome|mailto|tel|sms|callto|mms|skype):.+$');
        return externalScheme.test(this.to);
    }

    get isActive(): boolean {
        return this.$route.path === this.to;
    }

    get linkType(): LinkType {
        if (this.isExternal || this.isExternalScheme || this.isDownload) {
            return LinkType.ExternalLink;
        }
        if (this.to) {
            return LinkType.InternalLink;
        }
        return LinkType.PassThrough;
    }

    get element(): string {
        if (!this.to || this.preventDefault) {
            return this.tag ? this.tag : 'div';
        }

        if (this.linkType === LinkType.ExternalLink) {
            return 'a';
        }

        return 'router-link';
    }

    get childData(): VNodeData {
        return this.createLinkChildData(this.linkType).attrs || {};
    }

    createLinkChildData(type: LinkType): VNodeData {
        switch (type) {
            case LinkType.PassThrough:
                return {};
            case LinkType.ExternalLink:
                return {
                    attrs: {
                        href: this.to,
                        rel: this.target === '_blank' ? '' : 'noopener noreferrer',
                        target: this.target,
                        download: this.to
                    }
                };
            case LinkType.InternalLink:
                return {
                    attrs: {
                        to: this.to.indexOf('http') === 0 ? this.getRelativeUrl(this.to) : this.to
                    }
                };
        }
    }

    getRelativeUrl(url: string) {
        let a = window.document.createElement('a');
        a.href = url;

        return a.pathname + a.search + a.hash;
    }

    mounted() {
        this.$emit('mounted');
    }
};
