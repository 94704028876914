























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    ScalePricePromotionInformationViewObject,
    AddToBasketRequest,
    BasketViewModel,
    UpdateLineItemRequest
} from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import { BasketAction, BasketGetter } from '@/store/basket';
import api from '@/project/http/api';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';

@Component({
    components: { SpinnerElement }
})
export default class ScalePriceToPromote extends Vue {
  @Prop({
      required: true,
      type: Object
  })
    scalePriceToPromote!: ScalePricePromotionInformationViewObject;

  @Prop({
      type: String,
      default: 'up',
      validator: (value: string) => ['up', 'down'].indexOf(value) > -1
  })
  arrowPosition!: string;

  @Prop({
      required: false,
      type: Object
  })
  addToBasketPayload!: AddToBasketRequest;

  @Prop({
      required: false,
      type: Object
  })
  basketLineItemPayload!: UpdateLineItemRequest;

  @AppGetter hideNetPrices!: boolean;
  @AppGetter canSeeNetPrices!: boolean;
  @AppGetter canSeeGrossPrices!: boolean;

  @BasketGetter isBasketBusy!: boolean;
  @BasketAction addToBasket!: (
      addToBasketPayload: AddToBasketRequest,
  ) => Promise<BasketViewModel>;

  pending = false;
  success = false;
  successTimer = 3000;
  timeout: number | null = null;

  get promotionText(): string {
      return this.$tr(this.$labels.Product.ScalePrice.PromotionText, this.scalePriceToPromote.quantity.toString(), this.scalePriceToPromote.displayUnit, this.discountPercentageToShow);
  }

  get shouldShowNetPrice(): boolean {
      return !this.hideNetPrices && this.canSeeNetPrices;
  }

  get discountPercentageToShow(): string {
      if (this.shouldShowNetPrice) {
          return this.scalePriceToPromote.netPriceDiscountPercentage;
      } else if (this.canSeeGrossPrices) {
          return this.scalePriceToPromote.grossPriceDiscountPercentage;
      }
      return '';
  }

  destroyed() {
      if (this.timeout) {
          clearTimeout(this.timeout);
      }
  }

  addToOrUpdateBasket(): void {
      if (this.addToBasketPayload) {
          this.initAddToBasket();
      }
      if (this.basketLineItemPayload) {
          this.updateLineItem();
      }
      this.showSuccessIndicator();
  }

  showSuccessIndicator() {
      this.success = true;

      this.timeout = setTimeout(() => {
          this.success = false;
          this.closeScalePriceToPromote();
      }, this.successTimer);
  }

  closeScalePriceToPromote(): void {
      this.$emit('closeScalePriceToPromote');
  }

  async updateLineItem() {
      this.pending = true;
      try {
          this.basketLineItemPayload.unit = this.scalePriceToPromote.unit;
          this.basketLineItemPayload.quantity += this.scalePriceToPromote.quantity;

          await api.basket.updateBasketLineItem(this.basketLineItemPayload);
      } finally {
          this.pending = false;
      }
  }

  async initAddToBasket() {
      if (this.isBasketBusy || this.pending) {
          return;
      }

      this.pending = true;
      try {
          this.addToBasketPayload.unit = this.scalePriceToPromote.unit;
          this.addToBasketPayload.quantity = this.scalePriceToPromote.quantity;

          await this.addToBasket(this.addToBasketPayload);
      } finally {
          this.pending = false;
      }
  }
}
