import httpService from '@/core/http/http.service';
import {
    GetPriceRequest,
    PriceViewModel
} from '@/types/serverContract';

export default class PricesController {
    public async prices(payload: GetPriceRequest): Promise<PriceViewModel> {
        return httpService.post('priceApi/prices', payload);
    }
}
