import KeyBy from 'lodash-es/keyBy';
import { PriceRequest, PriceViewObject } from '@/types/serverContract';
import Api from '@/project/http/api';
import { AbstractBulkFetch, BulkFetchItemListener } from '@/core/bulk-fetch/AbstractBulkFetch';

class PriceService extends AbstractBulkFetch<PriceRequest, PriceViewObject> {
    async bulkGetPrices(itemListeners: BulkFetchItemListener<PriceRequest, PriceViewObject>[]): Promise<{ [key: string]: PriceViewObject }> {
        const requests = itemListeners.map(itemListener => itemListener.fetchItem.requestData);
        const result = await Api.prices.prices({ priceRequests: requests });
        return KeyBy<PriceViewObject>(result.prices, 'productId');
    }

    async getPrice(request: PriceRequest): Promise<PriceViewObject | null> {
        return this.requestData({
            requestData: request,
            requestId: request.id
        }).catch(err => {
            console.error(err);
            return null;
        });
    }
}

export default new PriceService('Price');
