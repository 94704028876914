











































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VariantIdsViewObject } from '@/types/serverContract';
import CopyTooltip from '@/project/tooltip/copyTooltip.vue';

@Component({
    components: {
        CopyTooltip
    }
})
export default class ProductVariantIds extends Vue {
    @Prop({ type: Object }) variantIds!: VariantIdsViewObject;
    @Prop({ default: false, type: Boolean }) usePrioritizedIds!: boolean;
    @Prop({ default: false, type: Boolean }) onlyOnePrioritizedId!: boolean;

    get colorClass(): string {
        if (!this.$el || !this.$el.classList) {
            return '';
        }

        return this.$el.classList.contains('text-') ? '' : 'text-grey-700';
    }
}
