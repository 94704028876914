

































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { InvoiceHistoryListViewModel } from '@/types/serverContract';
import InvoiceHistorySearchForm from '@/project/bookkeeping/InvoiceHistorySearchForm.vue';
import InvoiceHeader from '@/project/bookkeeping/InvoiceHeader.vue';
import QueryPaging from '@/project/shared/QueryPaging.vue';
import BookkeepingUrlHelper from './BookkeepingUrlHelper.service';
import scrollService from '@/core/scroll/scroll.service';
import { headerHeight } from '@/core/responsive/breakpoints/breakpoints.service';

@Component({
    components: {
        QueryPaging,
        InvoiceHeader,
        InvoiceHistorySearchForm
    }
})
export default class InvoiceHistoryList extends Vue {
    invoiceHistory: InvoiceHistoryListViewModel | null = null;
    currentPage: number = 1;

    public $refs!: {
        InvoiceHeaderList: HTMLElement;
    };

    updateInvoiceHistoryHeaders(searchDeliveryNoteResult: InvoiceHistoryListViewModel): void {
        const isInitialSearch = this.invoiceHistory === null;
        this.invoiceHistory = searchDeliveryNoteResult;
        this.currentPage = BookkeepingUrlHelper.getPageNumber();
        if (!isInitialSearch) {
            scrollService.scrollToElement(this.$refs.InvoiceHeaderList, -headerHeight(true));
        }
    }
}
