import httpService from '@/core/http/http.service';
import {
    RevenueRequestType,
    RevenueViewModel
} from '@/types/serverContract';

export default class DashboardController {
    public async Revenue(type: RevenueRequestType): Promise<RevenueViewModel> {
        return httpService.get<RevenueViewModel>('dashboardApi/revenue', { type });
    }

    public async RevenueByCategories(type: RevenueRequestType): Promise<RevenueViewModel> {
        return httpService.get<RevenueViewModel>('dashboardApi/revenueByCategories', { type });
    }

    public async RevenueByChannel(type: RevenueRequestType): Promise<RevenueViewModel> {
        return httpService.get<RevenueViewModel>('dashboardApi/revenueByChannel', { type });
    }
}
