import Vue, { VNodeDirective } from 'vue';
import tracking, {
    DataLayerEntry,
    INamedTrackingEvents
} from '@/core/tracking/tracking.service';
import $ from 'cash-dom';

Vue.directive('track-on-click', {
    bind: function(el: HTMLElement, binding: VNodeDirective) {
        function clickHandler() {
            tracking.track(binding.value as DataLayerEntry);
        }
        $(el).on('click.trackOnClick', clickHandler);
    },
    unbind: function(el: HTMLElement) {
        $(el).off('click.trackOnClick');
    }
});

Vue.directive('track-on-init', {
    bind: function(el: HTMLElement, binding: VNodeDirective) {
        if (binding.arg) {
            tracking.trackNamedEvent(
                binding.arg as INamedTrackingEvents,
                binding.value
            );
        } else {
            tracking.track(binding.value as DataLayerEntry);
        }
    }
});

const observers = new Map();

Vue.directive('track-product', {
    inserted(el, binding) {
        let fired = false;

        const observerCallback = (entries) => {
            entries.forEach(entry => {
                if (!fired && entry.isIntersecting) {
                    // If element is in viewport, set a debounce before firing binding.value
                    if (observers.has(el) && observers.get(el).debounceTimeout) {
                        clearTimeout(observers.get(el).debounceTimeout);
                    }

                    const debounceTimeout = setTimeout(() => {
                        fired = true;
                        binding.value();
                    }, 1000); // 1 second delay as before

                    observers.set(el, {
                        observer, // eslint-disable-line @typescript-eslint/no-use-before-define
                        debounceTimeout
                    });
                }
            });
        };

        // Setting up the IntersectionObserver
        const options = {
            root: null, // using the viewport
            rootMargin: '0px',
            threshold: 0.9 // 90% visibility
        };

        const observer = new IntersectionObserver(observerCallback, options);
        observer.observe(el);

        observers.set(el, {
            observer
        });
    },
    unbind(el) {
        if (observers.has(el)) {
            if (observers.get(el).debounceTimeout) {
                clearTimeout(observers.get(el).debounceTimeout);
            }
            observers.get(el).observer.disconnect();
            observers.delete(el);
        }
    }
});
