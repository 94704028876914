var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"o-grid"},[_c('SelectCtrl',{staticClass:"o-grid-item w-full md:w-1/2",attrs:{"value":_vm.revenueRequestTypes[_vm.selectedRevenueRequestType],"label":_vm.$tr(_vm.$labels.Views.Dashboard.TimeIntervalHeader),"options":_vm.revenueRequestTypes,"name":"RevenueRequestType"},on:{"input":_vm.onRevenueTypesChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v(_vm._s(_vm.$tr(_vm.$labels.Views.Dashboard.TimeInterval[option])))])]}}])})],1),_c('div',{staticClass:"o-grid mt-0"},[_c('DashboardRevenueChart',{attrs:{"revenue-data":_vm.revenueByChannel,"revenue-label":"%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var chartDataset = ref.chartDataset;
var chartOptions = ref.chartOptions;
return [_c('div',{staticClass:"o-grid-item w-full md:w-1/2"},[_c('div',{staticClass:"p-20 border border-grey-400"},[_c('p',{staticClass:"font-semibold mb-10"},[_vm._v("\n                        "+_vm._s(_vm.$tr(_vm.$labels.Views.Dashboard.Graphs.Header.RevenueByChannel))+"\n                    ")]),(!_vm.pendingRevenueByChannel)?_c('BarChart',{staticClass:"c-revenue-chart-wrapper",attrs:{"chart-data":chartDataset,"chart-options":chartOptions}}):_vm._e()],1)])]}}])}),_c('DashboardRevenueChart',{attrs:{"revenue-data":_vm.revenueByCategories},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var chartDataset = ref.chartDataset;
var chartOptions = ref.chartOptions;
return [_c('div',{staticClass:"o-grid-item w-full md:w-1/2"},[_c('div',{staticClass:"p-20 border border-grey-400"},[_c('p',{staticClass:"font-semibold mb-10"},[_vm._v("\n                        "+_vm._s(_vm.$tr(_vm.$labels.Views.Dashboard.Graphs.Header.RevenueByCategories))+"\n                    ")]),(!_vm.pendingRevenueByCategories)?_c('BarChart',{staticClass:"c-revenue-chart-wrapper",attrs:{"chart-data":chartDataset,"chart-options":chartOptions}}):_vm._e()],1)])]}}])}),_c('DashboardRevenueChart',{attrs:{"revenue-data":_vm.revenue},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var chartDataset = ref.chartDataset;
var chartOptions = ref.chartOptions;
return [_c('div',{staticClass:"o-grid-item w-full md:w-1/2"},[_c('div',{staticClass:"p-20 border border-grey-400"},[_c('p',{staticClass:"font-semibold mb-10"},[_vm._v("\n                        "+_vm._s(_vm.$tr(_vm.$labels.Views.Dashboard.Graphs.Header.Revenue))+"\n                    ")]),(!_vm.pendingRevenue)?_c('BarChart',{staticClass:"c-revenue-chart-wrapper",attrs:{"chart-data":chartDataset,"chart-options":chartOptions}}):_vm._e(),_c('p',{staticClass:"font-semibold"},[_vm._v("\n                        "+_vm._s(_vm.$tr(_vm.$labels.Views.Dashboard.Graphs.Labels.Total))+": "+_vm._s(_vm.revenue.totalRevenue)+"\n                    ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }