

















import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { AppGetter } from '../../store/app';
import bus from '../bus';
import { SpaPageReadyEventKey } from '../../router';

const AnimationInEvent = 'fadeIn';

@Component({
    name: 'ServerView'
})
export default class ServerView extends Vue {
    @AppGetter private hasSpaPage!: boolean;
    @AppGetter private content!: string;
    @AppGetter private isLoadingSpaPage!: boolean;

    private pageDataState: 'done' | 'awaitingNewPage' | 'newPageReceived' = 'done';
    private timerState: 'done' | 'awaitingAnimationOut' | 'awaitingMinimumAnimationTime' = 'done';
    private animationOutFiredTimeout: number | null = null;
    private minimumAnimationTimeout: number | null = null;

    private get isChangingPage(): boolean {
        return this.timerState !== 'done' || this.pageDataState !== 'done';
    }

    private get animationClasses() {
        return `animated u-anim-dur-300 ${this.isLoadingSpaPage ? 'fadeOut' : AnimationInEvent}`;
    }

    private get page() {
        if (!this.hasSpaPage) {
            return null;
        }

        /*
            Vue does not render spaces in between tags correctly so we need to replace them with &nbsp;
        */
        function preserveSpaces(html: string) {
            return html.replace(/>\s<\//g, '>&nbsp;</');
        }

        /*
            Vue does not decode html in attributes. Known bug: https://github.com/vuejs/vue/issues/8805
         */
        function decodeAttrs(html: string) {
            function escapeQuote(html: string) {
                html = html.replace(/&quot;/gi, '__$__');
                html = html.replace(/&#39;/gi, '__%__');
                return html;
            }

            function deescapeQuote(html: string) {
                html = html.replace(/__\$__/g, '&quot;');
                html = html.replace(/__%__/g, '&#39;');
                return html;
            }

            const textArea = document.createElement('textarea');
            textArea.innerHTML = escapeQuote(html);
            const deescapedHtml = deescapeQuote(textArea.innerText);
            return preserveSpaces(deescapedHtml);
        }

        return {
            template: `<div>${decodeAttrs(this.content)}</div>`
        };
    }

    mounted() {
        this.renderNewPage();
    }

    private destroyed() {
        if (this.animationOutFiredTimeout) {
            clearTimeout(this.animationOutFiredTimeout);
        }
        if (this.minimumAnimationTimeout) {
            clearTimeout(this.minimumAnimationTimeout);
        }
    }

    @Watch('isLoadingSpaPage')
    onIsFetchingSpaPageChange(isFetching: boolean) {
        if (isFetching) {
            this.pageDataState = 'awaitingNewPage';
            this.timerState = 'awaitingAnimationOut';
            this.animationOutFiredTimeout = setTimeout(() => this.animationOutFired(), 200); // Delay before we replace page (if new page ready).
            this.minimumAnimationTimeout = setTimeout(() => this.minimumAnimationTimeFired(), 500); // Animation out + animation in time.
        }
    }

    private animationOutFired() {
        this.timerState = 'awaitingMinimumAnimationTime';
        if (this.pageDataState === 'newPageReceived') {
            this.renderNewPage();
        }
    }

    @Watch('content')
    onSpaStoreJsonContentChange() {
        this.pageDataState = 'newPageReceived';
        if (this.timerState === 'awaitingMinimumAnimationTime' || this.timerState === 'done') {
            this.renderNewPage();
        }
    }

    private renderNewPage() {
        // this.localJsonContent = this.spaStoreJsonContent;
        this.$nextTick(() => {
            // Page should be rendered.
            this.pageDataState = 'done';
            // Fire event for router (scrolling).
            bus.emit(SpaPageReadyEventKey);
        });
    }

    private minimumAnimationTimeFired() {
        this.timerState = 'done';
    }
}
