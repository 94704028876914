


































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getRelativeHeaderTag } from '@/core/accessibility/getRelativeHeaderTag.ts';

@Component
export default class ArticleHeader extends Vue {
    @Prop({ type: String, default: '' }) heading!: string;
    @Prop({
        default: 'h2',
        type: String,
        validator: (value: string) => ['h1', 'h2', 'h3'].includes(value)
    })
    headingStyle!: string;

    @Prop({ type: String, default: '' }) subHeading!: string;
    @Prop({ type: String, default: '' }) bodyText!: string;
    @Prop({ type: String, default: '' }) linkUrl!: string;
    @Prop({ type: String, default: '_self' }) linkTarget!: string;
    @Prop({ type: String, default: '' }) linkText!: string;

    subHeaderTag: string = getRelativeHeaderTag(this.headingStyle);
}
