import httpService from '@/core/http/http.service';
import {
    EmailOptOutRequest,
    MailSubscriptionViewModel

} from '@/types/serverContract';

export default class HubSpotController {
    public async unsubscribeAll(payload: EmailOptOutRequest): Promise<MailSubscriptionViewModel> {
        return httpService.put('hubSpotApi/optOutEmail', payload);
    }
}
