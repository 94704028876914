















































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PriceServiceMixin } from '@/project/products/PriceServiceMixin.mixin';
import { PriceViewObject } from '@/types/serverContract';

@Component
export default class ScalePricesCompact extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({
        type: Object,
        required: true
    }) currentPrice!: PriceViewObject;

    @Prop({
        type: String,
        default: ''
    }) tableClasses!: string;

    showScalePrices: boolean = false;

    toggleShowScalePrices(): void {
        this.showScalePrices = !this.showScalePrices;
    }
}
