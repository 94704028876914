import debounce from 'lodash-es/debounce';
import Deferred from '@/core/async/Deferred';

export interface IBulkFetchItem<T> {
    requestData: T;
    requestId: string;
}

export class BulkFetchItemListener<TReq, T> {
    fetchItem: IBulkFetchItem<TReq>;
    deferred: Deferred<T>[];

    constructor(fetchItem: IBulkFetchItem<TReq>, deferred: Deferred<T>) {
        this.fetchItem = fetchItem;
        this.deferred = [deferred];
    }
}

export abstract class AbstractBulkFetch<TReq, T> {
    protected activeListenersById: Map<string, BulkFetchItemListener<TReq, T>> = new Map<string, BulkFetchItemListener<TReq, T>>();
    protected debouncedFetch = debounce(this.fetch, 100);

    constructor(protected name: string) { }

    public async requestData(item: IBulkFetchItem<TReq>): Promise<T> {
        const deferred = new Deferred<T>();

        const listeners = this.activeListenersById.get(item.requestId);
        if (listeners) {
            listeners.deferred.push(deferred);
        } else {
            const itemListener = new BulkFetchItemListener<TReq, T>(item, deferred);
            this.activeListenersById.set(item.requestId, itemListener);
        }

        this.debouncedFetch();
        return deferred.promise;
    }

    private async fetch() {
        const listeners = this.activeListenersById;
        this.activeListenersById = new Map<string, BulkFetchItemListener<TReq, T>>();

        try {
            const requests = Array.from(listeners.values());
            const result = await this.bulkGetPrices(requests);
            this.handleResultFromServer(listeners, result);
        } catch (e) {
            this.handleResultFromServer(listeners, null);
        }
    }

    private handleResultFromServer(listeners: Map<string, BulkFetchItemListener<TReq, T>>, result: { [key: string]: T } | null) {
        listeners.forEach((listener: BulkFetchItemListener<TReq, T>, id: string) => {
            const data = result && result[id];
            if (data) {
                listener.deferred.forEach(l => l.resolve(data));
            } else {
                listener.deferred.forEach(l => l.reject(`[${this.name}]: Unable to find a result for id: ${id}`));
            }
        });
    }

    protected abstract bulkGetPrices(requests: BulkFetchItemListener<TReq, T>[]): Promise<{ [key: string]: T }>;
}
