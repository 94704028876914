





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface Context {
    action: string;
    disabled?: boolean;
    icon: string;
    name: string;
}

@Component
export default class ContextMenu extends Vue {
    @Prop({ default: () => ({}) }) readonly context: Context[];

    showContextMenu: boolean = false;

    get contextMenuClasses() {
        return {
            'context-menu': true,
            'context-menu--open': this.showContextMenu
        };
    }

    toggleContextMenu() {
        this.showContextMenu = !this.showContextMenu;
    }

    triggerAction(action) {
        this.$emit('action', action);
        this.closeContextMenu();
    }

    closeContextMenu() {
        this.showContextMenu = false;
    }
}
