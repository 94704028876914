




















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CallbackPaging extends Vue {
    @Prop({
        type: Number,
        required: true
    }) currentPage!: number;

    @Prop({
        type: Number,
        required: true
    }) totalPages!: number;

    pageLink(page: number): void {
        this.$emit('changePage', this.normalizePageNo(page));
    }

    pageRelation(page: number): string {
        page = this.normalizePageNo(page);
        const result: string[] = [];
        if (page === 1) {
            result.push('first');
        }
        if (page === this.currentPage - 1) {
            result.push('prev');
        }
        if (page === this.currentPage + 1) {
            result.push('next');
        }
        if (page === this.totalPages) {
            result.push('last');
        }
        return result.join(',');
    }

    normalizePageNo(page: number) {
        return Math.min(Math.max(page, 1), this.totalPages);
    }
}
