


























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    SynonymListViewModel,
    CreateSynonymRequest,
    SynonymViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import HelpDeskDuplicateSynonymList from '@/project/helpDesk/HelpDeskDuplicateSynonymList.vue';

@Component({
    components: {
        ButtonSubmit,
        HelpDeskDuplicateSynonymList
    }
})
export default class HelpDeskAddSynonym extends Vue {
    synonym: string = '';
    description: string = '';
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    showDuplicatesList: boolean = false;
    duplicateSynonyms: SynonymViewObject[] | null = null;
    timeout: number | null = null;

    get examplesText(): string {
        return this.$tr(this.$labels.Views.Helpdesk.EditSearch.EditSynonyms.ExamplesText);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async createSynonym(): Promise<void> {
        this.pending = true;
        const payload: CreateSynonymRequest = {
            description: this.description,
            synonym: this.synonym
        };

        try {
            await Api.searchManagement.createSynonym(payload).then(() => {
                this.showSuccessIndicator();
                this.resetPayload();
            });
        } finally {
            this.pending = false;
        }
    }

    async submitForm() {
        if (this.vvErrors.items.length) {
            return;
        }

        this.pending = true;
        let duplicates: SynonymListViewModel = await Api.searchManagement.getPossibleDuplicates(this.synonym);

        if (duplicates && duplicates.synonyms && duplicates.synonyms.length && duplicates.synonyms.length > 0) {
            this.showDuplicatesList = true;
            this.duplicateSynonyms = duplicates.synonyms;
            this.pending = false;
        } else {
            this.createSynonym();
        }
    }

    closeDuplicatesList(createSynonym: boolean) {
        this.showDuplicatesList = false;
        this.duplicateSynonyms = null;

        if (createSynonym) {
            this.createSynonym();
        }
    }

    resetPayload() {
        this.synonym = '';
        this.description = '';
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors!: any;
}
