
















































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import {
    EasySupplyProcuredProductsListViewModel,
    EasySupplyProcuredProductViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { filter, orderBy } from 'lodash-es';
import EasySupplyProcuredProduct from '@/project/easySupply/procuredProducts/EasySupplyProcuredProduct.vue';
import CreateOrUpdateProcuredProduct from '@/project/easySupply/procuredProducts/CreateOrUpdateProcuredProduct.vue';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';

type sortingDirection = 'asc' | 'desc';

@Component({
    components: { CreateOrUpdateProcuredProduct, EasySupplyProcuredProduct, ButtonSubmit, ModalOverlay }
})
export default class EasySupplyProcuredProductsList extends Vue {
    searchTerm: string = '';
    procuredProductsListViewModel: EasySupplyProcuredProductsListViewModel | null = null;
    activeSortingKey: string = '';
    activeSortingDirection: sortingDirection = 'asc';
    pending: boolean = false;
    showCreateOrUpdateModal: boolean = false;

    get filteredProcuredProducts(): EasySupplyProcuredProductViewObject[] {
        if (this.searchTerm !== '') {
            return filter(this.procuredProductsListViewModel!.procuredProductViewObjects, procuredProduct => Object.values([procuredProduct.name, procuredProduct.customId, procuredProduct.supplierProductId, procuredProduct.supplierId]).join('').toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0);
        } else {
            return this.procuredProductsListViewModel!.procuredProductViewObjects;
        }
    }

    created() {
        this.getProcuredProducts();
    }

    async getProcuredProducts() {
        this.pending = true;

        try {
            this.procuredProductsListViewModel = await Api.easySupply.getProcuredProducts();
        } finally {
            this.pending = false;
        }
    }

    searchProcuredProducts(): void {

    }

    updateModel(newModel: EasySupplyProcuredProductsListViewModel): void {
        this.procuredProductsListViewModel = newModel;
    }

    sortFavoriteOrdersList(sortingKey: string) {
        if (sortingKey === this.activeSortingKey) {
            this.toggleSortingDirection();
        } else {
            this.activeSortingKey = sortingKey;
            this.activeSortingDirection = 'asc';
        }

        if (this.procuredProductsListViewModel) {
            this.procuredProductsListViewModel.procuredProductViewObjects = orderBy(this.procuredProductsListViewModel.procuredProductViewObjects, item => item[sortingKey], this.activeSortingDirection) as EasySupplyProcuredProductViewObject[];
        }
    }

    toggleSortingDirection() {
        this.activeSortingDirection = this.activeSortingDirection === 'asc' ? 'desc' : 'asc';
    }

    closeModal() {
        this.showCreateOrUpdateModal = false;
    }
}
