/* eslint-disable typescript-sort-keys/interface */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/class-name-casing */
import { PROMO_TRACKING_EVENT } from '../enums/enums';
import { IPromoActionField, IPromoEvent } from './promotionTracking.g4-structure';
import trackingService from './tracking.service';

class PromotionTracking {
    TrackPromotion(_event: PROMO_TRACKING_EVENT, _actionfield: any = null) {
        switch (_event) {
            case PROMO_TRACKING_EVENT.PromotionClick: // G3 = PDP, G4 = view_item
            case PROMO_TRACKING_EVENT.PromotionImpression: // G3 = addToCart, G4 = add_to_cart
                let promoEvent = this.createPromoEvent(_event, _actionfield);
                trackingService.track(promoEvent);
                break;
        }
    }

    createPromoEvent(_event: PROMO_TRACKING_EVENT, _actionField: IPromoActionField): IPromoEvent {
        let promoEvent: IPromoEvent = {
            event: _event,
            ecommerce: {
                creative_name: _actionField.creative_name,
                promotion_id: _actionField.promotion_id,
                promotion_name: _actionField.promotion_name
            }
        };

        return promoEvent;
    }
}

export default new PromotionTracking();
