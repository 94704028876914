

































import Vue from 'vue';
import Component from 'vue-class-component';
import { ArticleViewObject } from '@/types/serverContract';
import { Prop } from 'vue-property-decorator';

@Component
export default class ArticleCard extends Vue {
    @Prop({
        type: Object,
        required: true
    }) article!: ArticleViewObject;
}
