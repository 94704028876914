





































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    DeliveryAddressViewObject,
    DeliveryAddressViewModel,
    DeliveryType
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { filter } from 'lodash-es';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class AddOrUpdateGatewayDeliveryAddress extends Vue {
    @Prop({
        type: Object
    }) deliveryAddress!: DeliveryAddressViewObject;

    @Prop({
        type: Object
    }) oldDeliveryAddresses!: DeliveryAddressViewModel;

    @Prop({
        type: Number,
        default: DeliveryType.DAG
    }) deliveryType!: DeliveryType;

    setNightDeliveryType: boolean = this.deliveryType === DeliveryType.NAT;
    pending: boolean = false;
    deliveryAddresses: DeliveryAddressViewModel | null = null;
    selectedDeliveryAddress: DeliveryAddressViewObject | null = null;
    showSelectionArea: boolean = false;
    searchTerm: string = '';
    showAllAddress: boolean = false;
    deliveryAddressesLimit: number = 12;

    get deliveryAddressesToDisplay(): DeliveryAddressViewObject[] {
        return this.showAllAddress ? this.filteredDeliveryAddress : this.filteredDeliveryAddress.slice(0, this.deliveryAddressesLimit);
    }

    get filteredDeliveryAddress(): DeliveryAddressViewObject[] {
        let filterResult = [...this.deliveryAddresses!.addresses];
        if (this.searchTerm !== '') {
            filterResult = filter(this.deliveryAddresses!.addresses, adr => {
                // Excluding id from being hit by filtering
                const filterAddress = { ...adr };
                filterAddress.id = '';
                return Object.values(filterAddress).join('').toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0;
            });
        }
        return filterResult;
    }

    get chosenDeliveryType(): DeliveryType {
        // this would be extended if more Delivery Type is needed
        return this.setNightDeliveryType ? DeliveryType.NAT : DeliveryType.DAG;
    }

    created(): void {
        this.getDeliveryAddress(true);
    }

    async getDeliveryAddress(selectDeliveryAddress: boolean = false): Promise<void> {
        this.selectedDeliveryAddress = null;
        let isNightDeliveryType = false;
        if (!this.pending) {
            try {
                this.pending = true;
                isNightDeliveryType = this.chosenDeliveryType === DeliveryType.NAT;
                await Api.deliveryAddress.getDeliveryAddresses(this.chosenDeliveryType).then(deliveryAddresses => {
                    this.deliveryAddresses = deliveryAddresses;

                    if (this.deliveryAddresses.addresses.length === 1 && selectDeliveryAddress) {
                    // if there's only one delivery address, select it
                        this.selectDeliveryAddress(this.deliveryAddresses.addresses[0]);
                    } else if (this.oldDeliveryAddresses) {
                    // if a new address was created, select it
                        let newAddress: DeliveryAddressViewObject[] = this.deliveryAddresses.addresses
                            .filter(adr => this.oldDeliveryAddresses!.addresses
                                .every(oldAdr => oldAdr.id !== adr.id)
                            );
                        if (newAddress && newAddress.length === 1) {
                            this.selectDeliveryAddress(newAddress[0]);
                        } else {
                            this.showSelectionArea = true;
                        }
                    } else if (this.deliveryAddress && selectDeliveryAddress) {
                        // select delivery address from parent
                        this.selectDeliveryAddress(this.deliveryAddress);
                    } else {
                        this.showSelectionArea = true;
                    }
                });
            } finally {
                this.pending = false;
                this.setNightDeliveryType = isNightDeliveryType;
            }
        }
    }

    selectDeliveryAddress(address: DeliveryAddressViewObject): void {
        if (this.selectedDeliveryAddress === address) {
            this.showSelectionArea = false;
            return;
        }

        this.selectedDeliveryAddress = address;
        this.$emit('selectDeliveryAddress', address);
        this.showSelectionArea = false;
    }

    toggleShowSelectionArea(): void {
        this.showSelectionArea = !this.showSelectionArea;
    }

    toggleShowAllAddress(): void {
        this.showAllAddress = !this.showAllAddress;
    }

    showCreateDeliveryAddress(): void {
        this.$emit('showCreateDeliveryAddress', this.deliveryAddresses);
    }
}
