






































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import tracking from '@/core/tracking/tracking.service';

@Component({
})
export default class CreateAccountTimeline extends Vue {
    @Prop({ type: String, default: '' }) trackingType!: string;
    @Prop({ type: String, default: '' }) trackingEvent!: string;
    @Prop({ type: Number, default: 1 }) public step!: number;

    @Watch('step', {
        immediate: true
    })

    trackStep() {
        const stepName: string = this.step === 1
            ? this.$tr(this.$labels.Views.CreateAccount.Timeline.FirmUserData)
            : this.$tr(this.$labels.Views.CreateAccount.Timeline.ConfirmEmail);
        tracking.trackCreateUserFormStep(this.trackingType, this.trackingEvent, stepName, this.step);
    }
}
