



















































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    VariantDetailsViewObject,
    StoreStockQuantityViewObject,
    ProductTileViewObject,
    ProductDetailsViewObject
} from '@/types/serverContract';
import ProductDetailsSpecs from '@/project/products/ProductDetailsSpecs.vue';
import ProductRelatedProducts from '@/project/products/ProductRelatedProducts.vue';
import ProductStore from '@/project/products/ProductStore.vue';
import { AppGetter } from '@/store/app';
import { ProductGetter } from '@/store/product';
import Accordion from '@/project/shared/Accordion.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import ProductTile from '@/project/products/ProductTile.vue';
import scrollService from '@/core/scroll/scroll.service';
import bus from '@/core/bus';

type tabs = 'productSpecs' | 'productsStock';

@Component({
    components: {
        ProductTile,
        Accordion,
        ProductDetailsSpecs,
        ProductRelatedProducts,
        ProductStore
    }
})
export default class ProductDetailsTabs extends Vue {
    @Prop({
        default: () => [],
        type: Array
    }) productBomComponents!: ProductTileViewObject[];

    @ProductGetter productStoreStocks!: StoreStockQuantityViewObject[] | null;
    @ProductGetter productSelectedVariant!: VariantDetailsViewObject | null;
    @ProductGetter product!: ProductDetailsViewObject | null;
    @AppGetter isLoggedIn!: boolean;

    scrollToProductDetails() {
        this.$nextTick(() => {
            const target = document.getElementById('product-details');
            if (!target) return;

            scrollService.scrollToElement(target as HTMLElement);
        });
    }

    created() {
        bus.on('open-product-details-tab', (tabToOpen) => { this.setActiveTab(tabToOpen); });
    }

    productHasData: boolean = false;
    active: tabs = 'productSpecs';
    get productDescriptionHeader(): string {
        return this.$tr(this.$labels.Views.ProductDetails.Description);
    }

    get hasRelatedProducts(): boolean {
        return isNotNullEmptyOrUndefined(this.productSelectedVariant) &&
            (this.productSelectedVariant!.relatedProducts.productsIds.length >= 1 ||
            this.productSelectedVariant!.spareParts.productsIds.length >= 1);
    }

    get hasAlternativeProducts(): boolean {
        return isNotNullEmptyOrUndefined(this.productSelectedVariant) &&
           this.productSelectedVariant!.alternativeProducts.productsIds.length >= 1;
    }

    get hasRelatedProductsAndSpareParts(): boolean {
        return isNotNullEmptyOrUndefined(this.productSelectedVariant) &&
            (this.productSelectedVariant!.relatedProducts.productsIds.length >= 1 &&
            this.productSelectedVariant!.spareParts.productsIds.length >= 1);
    }

    get isBomProduct(): boolean {
        return this.productBomComponents.length >= 1;
    }

    public setActiveTab(tabName: tabs): void {
        this.active = tabName;
    }
}
