import {
    QuickAddToBasketRequest,
    PriceRequest,
    PriceViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import priceService from '@/project/products/price.service';

declare global {
    interface Window {
        catalogueAddToBasket(productId: string): void;
        getPrice(priceRequests: PriceRequest[]): Promise<PriceViewObject[]>;
    }
}

export class Aviou {
    public initAviou(): void {
        window.catalogueAddToBasket = (productId) => {
            try {
                this.quickAddToBasket(productId);
            } catch {}
        };

        window.getPrice = async(priceRequests): Promise<PriceViewObject[]> => {
            let prices: PriceViewObject[] = [];
            for (const req of priceRequests) {
                let price = await this.getPrice(req);
                prices.push(price);
            }

            return prices;
        };
    }

    private async quickAddToBasket(productId): Promise<void> {
        const quickAddToBasketRequest: QuickAddToBasketRequest = {
            productId: productId,
            quantity: 1
        };

        try {
            await Api.basket.quickAddToBasket(quickAddToBasketRequest, '');
        } finally { }
    }

    private async getPrice(req: PriceRequest): Promise<PriceViewObject> {
        let price;
        try {
            price = await priceService.getPrice(req);
        } finally { }
        return price;
    }
}

export default new Aviou();
