









































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ScannerListViewObject } from '@/types/serverContract';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import keyboardService from '@/core/keyCodes';
import Api from '@/project/http/api';

@Component({
    components: {
        ModalOverlay
    }
})
export default class ScannerListLineItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) scannerList!: ScannerListViewObject;

    showConfirmationModal: boolean = false;

    get isSharedText(): string {
        return this.scannerList.shared ? this.$tr(this.$labels.Views.FavoriteOrder.IsShared) : this.$tr(this.$labels.Views.FavoriteOrder.IsNotShared);
    }

    async deleteScannerList() {
        this.closeConfirmationModal();
        if (this.scannerList.allowDelete) {
            await Api.scannerLists.deleteScannerList(this.scannerList.id).then(newScannerLists => {
                this.$emit('scannerListDeleted', newScannerLists);
            });
        }
    }

    openConfirmationModal() {
        this.showConfirmationModal = true;
        document.addEventListener('keyup', this.keyHandler);
    }

    closeConfirmationModal() {
        this.showConfirmationModal = false;
        document.removeEventListener('keyup', this.keyHandler);
    }

    keyHandler(event) {
        if (keyboardService.isEnter(event)) {
            this.deleteScannerList();
        }
    }
}
