





































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProjectOrderQuotationHeaderViewObject } from '@/types/serverContract';

@Component
export default class ProjectOrderQuotationHeader extends Vue {
    @Prop({
        required: true,
        type: Object
    }) quotationHeader!: ProjectOrderQuotationHeaderViewObject;
}
