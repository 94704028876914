



















import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
    PriceServiceMixin,
    PriceServiceViewObject
} from '@/project/products/PriceServiceMixin.mixin';

@Component
export default class OrderSummaryTotal extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({
        default: '',
        type: String
    }) netTotal!: string;

    @Prop({
        default: '',
        type: String
    }) grossTotal!: string;

    price: PriceServiceViewObject = {
        netPrice: this.netTotal,
        grossPrice: this.grossTotal
    };
}
