

































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
    ProductTileViewObject,
    PriceViewObject,
    ProductTileTrackingContext
} from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import ProductCo2Info from '@/project/products/ProductCo2Info.vue';
import ProductPrices from '@/project/products/ProductPrices.vue';
import ProductVariantPicker from '@/project/products/ProductVariantPicker.vue';
import ProductTileSkeletonAddToBasket from '@/project/shared/skeleton/ProductTile/ProductTileSkeletonAddToBasket.vue';
import ProductTileSkeletonPrice from '@/project/shared/skeleton/ProductTile/ProductTileSkeletonPrice.vue';
import ProductAddToBasket from '@/project/products/ProductAddToBasket.vue';
import ProductVariantIds from '@/project/products/ProductVariantIds.vue';
import ProductTileWrapper from '@/project/products/ProductTileWrapper.vue';
import EcoCertificationsBadges from '@/project/products/EcoCertificationsBadges.vue';
import tracking from '@/core/tracking/tracking.service';
import ProductStorageStatusModal from '@/project/products/ProductStorageStatusModal.vue';
import ProductNudgingTile from '@/project/products/ProductNudgingTile.vue';
import scrollService from '@/core/scroll/scroll.service';
import serverContext from '@/core/serverContext.service';
import productTrackingService from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';

@Component({
    components: {
        ProductVariantIds,
        ProductAddToBasket,
        ProductTileSkeletonPrice,
        ProductTileSkeletonAddToBasket,
        ProductPrices,
        ProductVariantPicker,
        ProductCo2Info,
        ProductTileWrapper,
        EcoCertificationsBadges,
        ProductNudgingTile,
        ProductStorageStatusModal
    }
})
export default class ProductTile extends Vue {
    @Prop({
        required: true,
        type: Object
    }) product!: ProductTileViewObject;

    @Prop({
        default: false,
        type: Boolean
    }) isWide!: boolean;

    @Prop({
        required: false,
        type: String
    }) raptorMethod!: string;

    @Prop({
        required: false,
        type: String
    }) raptorEvent!: string;

    @Prop({
        default: false,
        type: Boolean
    }) selectDefaultVariant!: boolean;

    @AppGetter isLoggedIn!: boolean;

    nudgingShow: boolean = false;
    showNudgingProductIfAny: boolean = true;
    renderNudgingProduct: boolean = false;
    showBorderIfNudging: boolean = false;
    loadedPrice: PriceViewObject;

    onAddedToBasket() {
        // if there was no nudging product then showNudgingProductIfAny will be set to false in onNudgingHaveRecommendedProduct
        if (!this.renderNudgingProduct && this.showNudgingProductIfAny) {
            // load nudging product if any
            this.renderNudgingProduct = true;
        } else if (this.showNudgingProductIfAny) {
            // if adding multiple items to the basket and we know there is a nudging product then scroll to it
            this.scrollToNudgingProduct();
        }
    }

    onNudgingShowUpdate(value: boolean, price: PriceViewObject, trackingContext: ProductTileTrackingContext) {
        this.nudgingShow = value;
        if (!this.nudgingShow) {
            return;
        }
        if (price) {
            tracking.trackNudgingForSalesExpand(price.netPrice, this.product.id, trackingContext ? tracking.formatListName(trackingContext) : '');
        }
    }

    onNudgingHasRecommendedProduct(value: boolean) {
        // if nudging have recommended product we want to scroll to it
        this.showNudgingProductIfAny = value;
        this.showBorderIfNudging = value;
        if (this.showNudgingProductIfAny) {
            this.scrollToNudgingProduct();
        }
    }

    scrollToNudgingProduct() {
        // scroll to nudging product
        if (this.nudgingShow) {
            const ProductNudgingTileComponent = this.$refs.ProductNudgingTile as ProductNudgingTile;
            scrollService.scrollToElement(ProductNudgingTileComponent.$el as HTMLElement);
        } else {
            const ProductNudgingTileComponent = this.$refs.ProductNudgingTile as ProductNudgingTile;
            ProductNudgingTileComponent.toggleShow();
        }
    }

    get nudgePLPVisibility(): boolean {
        return this.renderNudgingProduct && this.showNudgingProductIfAny && serverContext.nudgeOnPLP;
    }

    async priceLoaded(price: PriceViewObject) {
        this.$emit('priceLoaded', price);
        this.loadedPrice = price;
    }

    // This function to be passed to the tracking directive
    public trackCurrentProduct() {
        // Passed a single product in a list to avoid breaking changes in tracking, ultimately this should be changed
        productTrackingService.TrackBatchProduct(
            PRODUCT_TRACKING_EVENT.ProductImpression,
            productTrackingService.ToTrackedProduct(
                this.product,
                PRODUCT_TRACKING_TYPE.ProductTileViewObject,
                this.loadedPrice
            )
        );
    }
}
