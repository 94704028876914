







































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import {
    GetProjectFolderItemsRequest,
    ProjectFolderItemType,
    ProjectFolderLinkListViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';

@Component({
    components: { SpinnerOverlay }
})
export default class HelpDeskAdministerProjectFolderEditProjectAgreements extends Vue {
    @Prop({
        required: true,
        type: Number
    }) salesAccountId!: number;

    file: File | null = null;
    title: string = '';
    pending: boolean = false;
    projectAgreementsList: ProjectFolderLinkListViewModel | null = null;

    $refs!: {
        importCSVFile: HTMLInputElement;
    };

    created(): void {
        this.getProjectAgreements();
    }

    async getProjectAgreements(): Promise<void> {
        this.pending = true;

        try {
            const payload: GetProjectFolderItemsRequest = {
                salesAccountId: this.salesAccountId,
                type: ProjectFolderItemType.Agreement
            };
            await Api.helpDesk.getProjectFolderItems(payload).then(projectAgreements => {
                if (projectAgreements) {
                    this.projectAgreementsList = projectAgreements;
                } else {
                    this.projectAgreementsList = {
                        links: []
                    };
                }
            });
        } finally {
            this.pending = false;
        }
    }

    async submitForm(): Promise<void> {
        if (this.vvErrors.items.length || this.file === null) {
            return;
        }
        this.pending = true;
        try {
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('title', this.title);
            formData.append('salesAccountId', this.salesAccountId.toString());
            formData.append('type', ProjectFolderItemType.Agreement.toString());

            await Api.helpDesk.uploadProjectFolderItem(formData).then(projectAgreements => {
                this.projectAgreementsList = projectAgreements;
            });
        } finally {
            this.resetForm();
            this.pending = false;
        }
    }

    async deleteProjectFolderItem(projectAgreementId: number): Promise<void> {
        this.pending = true;

        try {
            await Api.helpDesk.deleteProjectFolderItem(projectAgreementId).then(() => {
                this.getProjectAgreements();
            });
        } finally {
            this.pending = false;
        }
    }

    resetForm(): void {
        this.file = null;
        (this.$refs.importCSVFile as HTMLInputElement).value = '';
        this.title = '';
    }

    onFileChange() {
        this.file = this.$refs.importCSVFile.files![0];
    }

    goBack(): void {
        this.$emit('goBack');
    }

    vvErrors!: any;
}
