


















































import { Component, Prop, Watch } from 'vue-property-decorator';
import { uuid } from '@/project/config/utilities';
import InputCtrlData from './InputCtrlData';
import { calculateTextSize } from '@/project/config/calculateTextSize';

export interface CustomValidation {
    message: string;
    validator: () => boolean;
}

@Component({
    inject: ['$validator']
})
export default class InputCtrl extends InputCtrlData {
    isFocused: boolean = false;
    hasContent: boolean = false;
    isFirstTick: boolean = true;

    @Prop({
        type: Object,
        default: () => ({
            validator: () => true,
            message: ''
        } as CustomValidation)
    }) public customValidate!: CustomValidation;

    timeout: number | null = null;
    labelSize = calculateTextSize('', {})

    legendRightMargin: number = 6;
    form: HTMLInputElement | null = null;

    get id() {
        return this.name + uuid();
    }

    get valid() {
        if (!this.field || !this.field.validated) {
            return false;
        }

        return this.field.valid && this.customValidate.validator();
    }

    get invalid() {
        if (!this.validated) {
            return false;
        }
        return this.field.invalid;
    }

    get validated() {
        return !(!this.field || !this.field.validated);
    }

    get error(): string | null {
        return this.vvErrors.first(this.name) ||
            (this.validated && !this.customValidate.validator() ? this.customValidate.message : null);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    mounted() {
        const formSlot = this.$slots['form-field'];
        this.form = formSlot![0].elm! as HTMLInputElement;

        this.form.addEventListener('focus', this.focusGained);
        this.form.addEventListener('change', this.inputChanged);
        this.form.addEventListener('blur', this.focusLost);

        this.updateDynamicStyle(true);
    }

    @Watch('autofocus', { immediate: true })
    onAutofocusChange(value: boolean) {
        if (!value) {
            return;
        }
        try {
            this.timeout = setTimeout(() => this.form!.focus(), 400);
        } catch (e) {} // Safari sometimes
    }

    @Watch('error')
    onErrorChange(value: string) {
        this.$emit('update:error', value);
    }

    public focus() {
        (this.$refs['input'] as HTMLInputElement).focus();
    }

    focusGained() {
        this.updateDynamicStyle(false);
    }

    inputChanged() {
        this.focusGained();
        if (this.field.validated && this.field.invalid) {
            this.validate();
        }
    }

    propagateClick() {
        this.form!.focus();
    }

    hasValue(form: HTMLInputElement) {
        if (form.validity && form.validity.badInput === true) {
            // When the input is bad, form.value is 0. Therefor we check this
            // form.validity.valueMissing does not work for the first tick for some reason if Firefox.
            return true;
        }
        return form.value !== '' && form.value != null;
    }

    async updateDynamicStyle(firstTick: boolean) {
        const hasValue = this.hasValue(this.form!);
        const isPlaceholderShown = this.placeholderShown !== undefined && this.placeholderShown;
        const isInputFocused = this.form !== undefined && this.form!.matches(':focus');
        const isFirstTick = hasValue && firstTick;

        this.hasContent = !!(hasValue || isPlaceholderShown || isFirstTick);

        this.isFocused = !!isInputFocused;

        if ((this.isFocused && !this.isNullorWhitespace(this.label)) ||
        (this.hasContent && !this.isNullorWhitespace(this.label))) {
            await this.setlabelWidth();
            (this.$refs.legend as HTMLElement).style.width = this.labelSize.width + 'px';
        } else {
            (this.$refs.legend as HTMLElement).style.width = '0px';
        }
        this.isFirstTick = firstTick;
    }

    isNullorWhitespace(value) {
        return (value == null || (typeof value === 'string' && value.trim().length === 0));
    }

    setlabelWidth() {
        if (this.labelSize.width === 0) {
            var text = this.label + (this.required ? '*' : '');
            this.labelSize = calculateTextSize(text, {
                font: 'Ahlsell Sans',
                fontSize: '12px',
                margin: this.legendRightMargin
            });
        }
    }

    focusLost(ev) {
        this.$emit('blur', ev);
        this.updateDynamicStyle(false);

        if (this.field.dirty) {
            this.validate();
        }
    }

    validate() {
        this.$validator.validate(this.name);
    }

    // From Vue-validate
    private vvErrors!: any;
}
