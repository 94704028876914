






























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    HelpDeskUserViewObject,
    AssignedSalesAccountToUserViewObject,
    AssignedAccountsToUserRequest,
    AssignUserToSalesAccountRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        SpinnerOverlay,
        ButtonSubmit
    }
})
export default class HelpDeskAssignUserToAccount extends Vue {
    @Prop({
        required: true,
        type: Object
    }) selectedUser!: HelpDeskUserViewObject;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    assignedSalesAccounts: AssignedSalesAccountToUserViewObject[] | null = null;
    accountNumber: string = '';
    phoneNumber: string = '';
    timeout: number | null = null;

    created(): void {
        this.getSalesAccountsAssignedToUser();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async getSalesAccountsAssignedToUser(): Promise<void> {
        this.pending = true;
        const payload: AssignedAccountsToUserRequest = {
            userId: this.selectedUser.id
        };
        try {
            await Api.helpDesk.salesAccountsAssignedToUser(payload).then(res => {
                this.assignedSalesAccounts = res.assignedSalesAccounts;
            });
        } finally {
            this.pending = false;
        }
    }

    async assignUserToSalesAccount(): Promise<void> {
        this.pending = true;
        const payload: AssignUserToSalesAccountRequest = {
            soldToPartnerId: this.accountNumber,
            userId: this.selectedUser.id,
            phoneNumber: this.phoneNumber
        };
        try {
            await Api.helpDesk.assignUserToSalesAccount(payload).then(res => {
                this.showSuccessIndicator();
                this.assignedSalesAccounts = res.assignedSalesAccounts;
                this.resetPayload();
            });
        } finally {
            this.pending = false;
        }
    }

    resetPayload() {
        this.accountNumber = '';
        this.phoneNumber = '';
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    goBack(): void {
        this.$emit('goBack');
    }
}
