



























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NavigationItem } from '@/types/serverContract';

@Component
export default class LeftNavigation extends Vue {
    @Prop({
        type: Object,
        required: true
    }) navigation!: NavigationItem;

    @Prop({
        default: '',
        type: String
    }) navListClass!: string;

    @Prop({
        default: '',
        type: String
    }) headerClass!: string;
}
