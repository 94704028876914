







































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import {
    EasySupplyOrderLineViewObject,
    DeleteEasySupplyOrderLineRequest,
    EasySupplyOrderLinesRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import EasySupplyStocklistReorderLineItem from '@/project/easySupply/EasySupplyStocklistReorderLineItem.vue';
import EasySupplyStocklistReorderQuickAddForm from '@/project/easySupply/EasySupplyStocklistReorderQuickAddForm.vue';

@Component({
    components: {
        ButtonSubmit,
        EasySupplyStocklistReorderLineItem,
        EasySupplyStocklistReorderQuickAddForm
    }
})
export default class EasySupplyStocklistReorder extends Vue {
    deletePending: boolean = false;
    orderLinesPending: boolean = false;
    addToBasketPending: boolean = false;
    addToBasketSuccess: boolean = false;
    easySupplyOrderLineList: EasySupplyOrderLineViewObject[] | null = null;

    get easySupplyWarehouseId(): number {
        const id = this.$route.query.id as string;

        return parseInt(id, 10);
    }

    created() {
        this.getOrderLines();
    }

    async getOrderLines() {
        if (this.orderLinesPending) return;

        this.orderLinesPending = true;
        const payload: EasySupplyOrderLinesRequest = {
            easySupplyWarehouseId: this.easySupplyWarehouseId,
            manualOrder: true
        };

        try {
            const orderLineViewModel = await Api.easySupply.getEasySupplyOrderLines(payload);
            this.easySupplyOrderLineList = orderLineViewModel.easySupplyOrderLineList;
        } finally {
            this.orderLinesPending = false;
        }
    }

    async addAllToBasket() {
        if (this.addToBasketPending) return;

        this.addToBasketPending = true;

        try {
            await Api.basket.addEasySupplyOrderLines(this.easySupplyWarehouseId).then(() => {
                this.getOrderLines();
            });
        } finally {
            this.addToBasketPending = false;
        }
    }

    async deleteOrderLine(orderLine: EasySupplyOrderLineViewObject) {
        if (this.deletePending) return;

        this.deletePending = true;

        const payload: DeleteEasySupplyOrderLineRequest = {
            easySupplyOrderLineId: orderLine.id,
            easySupplyWarehouseId: this.easySupplyWarehouseId
        };

        try {
            const newEasySupplyOrderLinesViewModel = await Api.easySupply.deleteEasySupplyOrderLine(payload);
            this.easySupplyOrderLineList = newEasySupplyOrderLinesViewModel.easySupplyOrderLineList;
        } finally {
            this.deletePending = false;
        }
    }

    updateEasySupplyOrderLineList(easySupplyOrderLineList: EasySupplyOrderLineViewObject[] | null): void {
        this.easySupplyOrderLineList = easySupplyOrderLineList;
    }
}
