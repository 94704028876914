












































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    model: {
        event: 'change'
    },
    inject: ['$validator']
})
export default class ToggleCtrl extends Vue {
    // text
    @Prop(String) public label!: string;
    @Prop(String) public offLabel!: string;
    // base props
    @Prop(String) public name!: string;
    @Prop(String) public constraints!: string;
    // styling
    @Prop({ type: String, default: '' }) public dotColor!: string;
    @Prop({ type: String, default: '' }) public bodyColor!: string;
    @Prop({ type: String, default: 'border-cadet-grey' }) public borderColor!: string;
    @Prop({ type: Number, default: 3 }) public toggleMargins!: number;
    @Prop({ default: false }) public ToggledRight;
    @Prop({ default: false }) public ValidateToggleRequestLeftToRight!: boolean;
    @Prop({ default: false }) public ValidateToggleRequestRightToLeft!: boolean;
    @Prop({ default: '' }) public ToggledLeftHyperlink!: string;
    @Prop({ default: '' }) public ToggledRightHyperlink!: string;

    // data
    id: string = '';
    currentConfirmToggle: boolean = false;
    minToggleSize: number = 18;
    localToggledRight: boolean = false;
    isHoveredLeft: boolean = false;
    isHoveredRight: boolean = false;

    // content margins
    contentLeftWidth: number = -1;
    contentRightWidth: number = -1;
    contentTotalWidth: number = -1;
    contentTotalHeight: number = -1;

    // content names
    contentLeft: string = 'content-left';
    contentRight: string = 'content-right';
    contentDot: string = 'content-dot';
    dotRef: string = 'dot-ref';
    toggleRef: string = 'toggle-ref';

    // dymaic slot margins
    dynamicMargins() {
        return {
            marginRight: `${this.toggleMargins * 2}px`,
            marginLeft: `${this.toggleMargins}px`
        };
    }

    get displayLabel() {
        return this.localToggledRight ? this.offLabel : this.label || this.offLabel;
    }

    get leftUrl(): string {
        return !this.localToggledRight ? this.ToggledLeftHyperlink : '';
    }

    get rightUrl(): string {
        return this.localToggledRight ? this.ToggledRightHyperlink : '';
    }

    get hasUrl() {
        return this.localToggledRight ? this.rightUrl : this.leftUrl;
    }

    get isHovered() {
        var leftHoveredActive = this.isHoveredLeft && !this.localToggledRight;
        var rightHoveredActive = this.isHoveredRight && this.localToggledRight;
        return leftHoveredActive || rightHoveredActive;
    }

    created() {
        this.localToggledRight = this.ToggledRight;
    }

    mounted() {
        this.id = this.name + Math.round(Math.random() * 100000);
        this.init();
        this.updateValue(this.localToggledRight);
    }

    init() {
        this.$nextTick(() => {
            this.prepareToggleWidthAndHeight();
            this.setToggleStyle();
            this.setDotStyle();
        });
    }

    prepareToggleWidthAndHeight() {
        this.contentLeftWidth =
            this.baseWidth(this.getContentWidth(this.contentLeft)) + this.toggleMargins * 2;
        this.contentRightWidth =
            this.baseWidth(this.getContentWidth(this.contentRight)) + this.toggleMargins * 2;

        const leftHeight = this.baseHeight(this.getContentHeight(this.contentLeft));
        const rightHeight = this.baseHeight(this.getContentHeight(this.contentRight));

        this.contentTotalWidth = this.contentLeftWidth + this.contentRightWidth;
        this.contentTotalHeight = leftHeight > rightHeight ? leftHeight : rightHeight;
    }

    setToggleStyle() {
        const toggleStyle = {
            'min-width': this.contentTotalWidth + 'px',
            'min-height': this.contentTotalHeight + this.toggleMargins * 2 + 'px'
        };
        const toggleRef = this.$refs[this.toggleRef] as HTMLElement;
        Object.assign(toggleRef.style, toggleStyle);
    }

    setDotStyle() {
        const width =
            (this.localToggledRight ? this.contentRightWidth : this.contentLeftWidth) -
            this.toggleMargins * 2;
        const position = this.localToggledRight
            ? this.contentTotalWidth - this.contentRightWidth - 2
            : 0;
        const dotStyle = {
            'min-width': width + 'px',
            'min-height': this.contentTotalHeight + 'px',
            left: position + this.toggleMargins + 'px'
        };
        const dotRef = this.$refs[this.dotRef] as HTMLElement;
        Object.assign(dotRef.style, dotStyle);
    }

    baseWidth(value: number): number {
        return value < this.minToggleSize ? this.minToggleSize : value;
    }

    baseHeight(value: number): number {
        return value < this.minToggleSize ? this.minToggleSize : value;
    }

    getContentHeight(slot: string): number {
        const slotElements = this.$slots[slot];
        if (!slotElements || !slotElements[0] || !slotElements[0].elm) {
            return -1;
        }
        const elm = slotElements[0].elm as HTMLElement;
        return elm.clientHeight;
    }

    getContentWidth(slot: string): number {
        const slotElements = this.$slots[slot];
        if (!slotElements || !slotElements[0] || !slotElements[0].elm) {
            return -1;
        }
        const elm = slotElements[0].elm as HTMLElement;

        return elm.clientWidth;
    }

    validateRequestedUpdate() {
        if (this.ValidateToggleRequestRightToLeft && this.localToggledRight) {
            this.$emit('validate-toggle-request'); // When using this, updateValue has to be called manually after validation
            return;
        }
        if (this.ValidateToggleRequestLeftToRight && !this.localToggledRight) {
            this.$emit('validate-toggle-request'); // When using this, updateValue has to be called manually after validation
            return;
        }
        this.updateValue(!this.localToggledRight);
    }

    updateValue(isToggledRight: boolean) {
        this.localToggledRight = isToggledRight;

        this.setDotStyle();

        this.$emit('toggle-updated', this.localToggledRight);

        if (this.constraints) {
            this.validate();
        }
    }

    validate() {
        this.$validator.validate(this.name);
    }
}
