

























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FlexBoxGetter } from '@/store/flexBox';
import { BasketGetter } from '@/store/basket';
import { BasketViewModel } from '@/types/serverContract';

@Component
export default class SearchNoResultsFound extends Vue {
    @Prop(String)
    term!: string

    @FlexBoxGetter isInFlexBoxContext!: boolean;

    @BasketGetter basket!: BasketViewModel;
}
