































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BasketViewModel } from '@/types/serverContract';
import { BasketGetter } from '@/store/basket';

@Component
export default class DeliverySmsService extends Vue {
    @Prop({
        required: true,
        type: String
    }) smsServiceNumber!: string;

    @BasketGetter basket!: BasketViewModel;

    phoneNumber: string = this.smsServiceNumber;

    get hasRequiredData(): boolean {
        return this.basket.deliveryMethod !== undefined &&
            this.basket.deliveryMethod !== 0 &&
            this.basket.deliveryAddress !== undefined &&
            this.basket.deliveryAddress.addressName !== undefined;
    }

    setSmsServiceNumber(): void {
        this.$emit('update:smsServiceNumber', this.phoneNumber);
    }
}
