





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DeliveryNoteLineItemViewObject } from '@/types/serverContract';

@Component
export default class TrackAndTraceDetailsLineItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) lineItem!: DeliveryNoteLineItemViewObject;
}
