









































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import scrollService from '@/core/scroll/scroll.service';

@Component
export default class Accordion extends Vue {
    @Prop({
        default: 'sanicon-ico-arrow-nav-forward text-10',
        type: String
    }) iconName!: string;

    @Prop({
        default: false,
        type: Boolean
    }) fullWidth!: boolean;

    @Prop({
        default: false,
        type: Boolean
    }) disableScrollTo!: boolean;

    @Prop({
        default: false,
        type: Boolean
    }) defaultOpen!: boolean;

    @Prop({
        default: '',
        type: String
    }) headerClasses!: string;

    @Prop({
        type: String
    }) headerId!: string;

    @Prop({
        default: false,
        type: Boolean
    }) customShow!: boolean;

    @Prop({
        default: false,
        type: Boolean
    }) markActive!: boolean;

    @Prop({
        default: '',
        type: String
    }) bodyClass!: string;

    $refs!: {
        accordionSection: HTMLElement;
    };

    show: boolean = this.customShow;

    created() {
        if (this.defaultOpen) {
            this.show = true;
            this.$nextTick(() => {
                this.afterEnter(this.$refs.accordionSection);
            });
        }
    }

    @Watch('customShow')
    onCustomShowChange(newValue) {
        if (!newValue) {
            this.show = newValue;
        }
    }

    toggleShow(container: HTMLElement | undefined = undefined): void {
        this.show = !this.show;
        if (!this.disableScrollTo) {
            scrollService.scrollToElement(this.$refs.accordionSection, -100, 200, container);
        }
        if (this.show) {
            this.$emit('isOpen', this.headerId);
        }

        this.$emit('update:customShow', this.show);
    }

    beforeEnter(el) {
        el.style.height = '0';
        el.style.overflow = 'hidden';
    }

    enter(el) {
        el.style.height = el.scrollHeight + 'px';
    }

    afterEnter(el) {
        // Set height to auto when expanded, this will allow resizing of internal elements and not hiding content.
        el.style.height = 'auto';
        el.style.overflow = 'visible';
    }

    beforeLeave(el): Promise<void> {
        // As height is set to auto, set height to a pixel value
        return new Promise<void>(resolve => {
            el.style.height = el.scrollHeight + 'px';
            el.style.overflow = 'hidden';

            return resolve();
        }).then(() => {
            setTimeout(() => {
                el.style.height = '0';
            }, 0);
        });
    }
};
