




















import Vue from 'vue';
import Component from 'vue-class-component';
import { FlexBoxGetter, FlexBoxAction } from '@/store/flexBox';
import ToggleCtrl from '@/project/form/ToggleCtrl.vue';

@Component
export default class MobileMenuFlexboxToggle extends Vue {
    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @FlexBoxAction setShowFlexBoxModal!: (show: boolean) => void;

    OnToggleValidated() {
        this.setShowFlexBoxModal(true);
    }

    PropagateInput() {
        const toggle = this.$refs.toggle as ToggleCtrl;
        toggle.validateRequestedUpdate();
    }
}
