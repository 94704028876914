












































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AccountAccesses from '@/project/user/AccountAccesses.vue';
import {
    AccountRelationViewObject,
    UserViewModel
} from '@/types/serverContract';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import AddAccountForm from '@/project/user/AddAccountForm.vue';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';

@Component({
    components: {
        AddAccountForm,
        AccountAccesses,
        ModalOverlay,
        SpinnerElement
    }
})
export default class AccountAccessesList extends Vue {
    @Prop({
        required: true,
        type: Array
    }) userAccountRelations!: AccountRelationViewObject[];

    @Prop({
        required: true,
        type: String
    }) primarySoldToPartnerId!: string;

    @Prop({
        default: true,
        type: Boolean
    }) showAddAccount!: boolean;

    pending: boolean = false;
    localPrimarySoldToPartnerId: string = this.primarySoldToPartnerId;
    showUserRelations: boolean = false;
    showAddAccountModal: boolean = false;
    showAccountAddedMessage: boolean = false;
    timeout: number | null = null;

    get primaryAccount(): AccountRelationViewObject | undefined {
        return this.userAccountRelations.find(accountRelation => accountRelation.soldToPartnerId === this.primarySoldToPartnerId);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    @Watch('localPrimarySoldToPartnerId')
    onPrimaryAccountChanged() {
        this.$emit('update:primarySoldToPartnerId', this.localPrimarySoldToPartnerId);
    }

    showSpinner() {
        this.pending = true;
    }

    toggleShowUserRelations() {
        this.showUserRelations = !this.showUserRelations;

        if (this.pending === true) {
            this.pending = false;
        }
    }

    openAddAccountModal() {
        this.showAddAccountModal = true;
    }

    closeAddAccountModal() {
        this.showAddAccountModal = false;
    }

    handleAddAccountSubmit(userViewModelInfo: UserViewModel) {
        this.$emit('update:userAccountRelations', userViewModelInfo.accountRelations);
        this.closeAddAccountModal();
        this.showAccountAddedMessage = true;
        this.timeout = setTimeout(() => {
            this.showAccountAddedMessage = false;
        }, 3000);
    }
}
