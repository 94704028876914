






















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import EasySupplyStockListEditForm from '@/project/easySupply/EasySupplyStockListEditForm.vue';
import {
    AddEasySupplyWarehouseLineRequest,
    EasySupplyWarehouseListItemViewObject,
    UnitViewObject,
    EasySupplyWarehouseFormOptionsViewObject
} from '@/types/serverContract';

@Component({
    components: {
        ButtonSubmit,
        EasySupplyStockListEditForm
    }
})
export default class AddToEasySupplyWarehouseForm extends Vue {
    @Prop({
        required: true,
        type: Array
    }) orderableUnits!: UnitViewObject[];

    @Prop({
        required: true,
        type: String
    }) variantId!: string;

    hasUneditableWarehouses: boolean = false;
    easySupplyWarehouseList: EasySupplyWarehouseListItemViewObject[] | null = null;
    selectedEasySupplyWareHouse: EasySupplyWarehouseListItemViewObject | null = null;
    location: string = '';
    quantity: number = 1;
    selectedOrderableUnit: UnitViewObject | null = null;
    getEasySupplyWarehousePending: boolean = false;
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    addToEasySupplyWarehouseLineId: string = 'add-to-easy-supply-warehouse-form';
    showCreateNewWarehouse: boolean = false;
    formOptions: EasySupplyWarehouseFormOptionsViewObject | null = null;
    showGoBackButton: boolean = false;
    timeout: number | null = null;

    created() {
        this.getCreateOptions();
        this.getEasySupplyWarehouseList().then(() => {
            this.showGoBackButton = !!(this.easySupplyWarehouseList && this.easySupplyWarehouseList.length > 0);
        });
        this.selectedOrderableUnit = this.orderableUnits ? this.orderableUnits[0] : null;
    }

    get hasEditableWarehouses() {
        return this.easySupplyWarehouseList && this.easySupplyWarehouseList.length;
    }

    get onlyHasUneditableWarehouses() {
        return !this.hasEditableWarehouses && this.hasUneditableWarehouses;
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    showCreateNewEasySupply() {
        this.showCreateNewWarehouse = true;
        this.$emit('showCreateNewWarehouse', true);
    }

    async getCreateOptions() {
        try {
            let model = await Api.easySupply.getEasySupplyWarehouseCreateOptions();
            this.formOptions = model.formOptions;
        } finally { }
    }

    async getEasySupplyWarehouseList() {
        this.getEasySupplyWarehousePending = true;
        try {
            let easySupplyWarehouseListViewModel = await Api.easySupply.getAddToEasySupplyWarehouseList();
            if (easySupplyWarehouseListViewModel && easySupplyWarehouseListViewModel.easySupplyWarehouseList) {
                this.easySupplyWarehouseList = easySupplyWarehouseListViewModel.easySupplyWarehouseList;
                this.hasUneditableWarehouses = easySupplyWarehouseListViewModel.hasUneditableWarehouses;
                if (this.easySupplyWarehouseList.length === 0) {
                    this.showCreateNewEasySupply();
                } else {
                    this.selectedEasySupplyWareHouse = this.easySupplyWarehouseList[0];
                }
            }
        } finally {
            this.getEasySupplyWarehousePending = false;
        }
    }

    async addToEasySupplyWarehouseLine() {
        if (this.vvErrors.items.length > 0 || !this.selectedEasySupplyWareHouse || !this.selectedOrderableUnit) {
            return;
        }

        this.pending = true;
        const payload: AddEasySupplyWarehouseLineRequest = {
            easySupplyWarehouseId: this.selectedEasySupplyWareHouse.id,
            variantId: this.variantId,
            reorderQuantity: this.quantity,
            reorderQuantityUnit: this.selectedOrderableUnit.unitCode,
            locationDescription: this.location
        };

        try {
            await Api.easySupply.addToEasySupplyWarehouseLine(payload, this.addToEasySupplyWarehouseLineId);
            this.showSuccessIndicator();
        } finally {
            this.pending = false;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
            this.$emit('closeModal');
        }, this.successTimer);
    }

    onCreateAndAdd(): void {
        this.getEasySupplyWarehouseList().then(() => {
            this.addToEasySupplyWarehouseLine();
        });
    }

    onGoBack(): void {
        this.showCreateNewWarehouse = false;
        this.$emit('showCreateNewWarehouse', false);
    }

    private vvErrors!: any;
}
