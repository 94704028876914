

















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component({
    components: {
        ButtonSubmit
    },
    inject: ['$validator']
})
export default class UpdateUserPunchOut extends Vue {
    @Prop({
        required: true,
        type: Boolean
    }) isPunchOutAccount!: boolean;

    @Prop({
        default: '',
        type: String
    }) userId!: string;

    @Prop({
        required: false,
        default: false,
        type: Boolean
    }) hasPunchOutFile!: boolean;

    selectedXsltFile: File | null = null;
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;

    // We have decided not to show the form when creating a user.
    get showPunchOutFileForm(): boolean {
        return this.isPunchOutAccount && this.userId !== '';
    }

    get hasSelectedXsltFile(): boolean {
        return isNotNullEmptyOrUndefined(this.selectedXsltFile);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async uploadPunchOutFile(): Promise<void> {
        if (this.vvErrors.items.length || this.userId === '') {
            return;
        }
        this.pending = true;
        try {
            const formData = new FormData();
            formData.append('file', this.selectedXsltFile!);

            await Api.user.addXsltToUser(this.userId, formData).then(() => {
                this.showSuccessIndicator();
            });
        } finally {
            this.pending = false;
        }
    }

    async removePunchOutFile(): Promise<void> {
        if (this.vvErrors.items.length || this.userId === '') {
            return;
        }
        this.pending = true;
        try {
            await Api.user.removeXsltFromUser(this.userId).then(() => {
                this.showSuccessIndicator();
            });
        } finally {
            this.pending = false;
        }
    }

    public handlePunchOutFile(): void {
        if (this.hasSelectedXsltFile) {
            this.uploadPunchOutFile();
        } else {
            this.removePunchOutFile();
        }
    }

    initiateUpdatePunchOutStatus(): void {
        this.$emit('uploadPunchOutFile');
    }

    onIsPunchOutAccountChange(isPunchOutAccount: boolean): void {
        this.$emit('update:isPunchOutAccount', isPunchOutAccount);
    }

    onPunchOutFileChange(): void {
        this.selectedXsltFile = (this.$refs.importCSVFile as HTMLInputElement).files![0];
    }

    showSuccessIndicator(): void {
        this.pending = false;
        this.success = true;

        this.$emit('success');

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    private vvErrors!: any;
}
