



















import Vue from 'vue';
import Component from 'vue-class-component';
import urlHelper from './urlHelperFavoriteOrders.service';
import { Prop } from 'vue-property-decorator';
import { SortByViewObject } from '@/types/serverContract';

@Component
export default class FavoriteOrderSorting extends Vue {
    @Prop({ type: Array, required: true }) options!: SortByViewObject[];
    @Prop({ type: Boolean, default: false }) disabled!: boolean;

    activeSorting: SortByViewObject | null = null;

    created() {
        this.activeSorting = this.getSelectedSorting();
    }

    getSelectedSorting(): SortByViewObject {
        let selectedOption = this.options.find(option => option.isSelected);
        if (!selectedOption) {
            throw new Error('No sorting option selected - server error');
        }
        return selectedOption;
    }

    onChange(event) {
        urlHelper.setSorting(event.target.value);
    }
}
