import httpService from '@/core/http/http.service';
import {
    ScannerDataListViewModel,
    ScannerSetupViewModel
} from '@/types/serverContract';

export default class ScannerController {
    public async scannerSetup(): Promise<ScannerSetupViewModel> {
        return httpService.get<ScannerSetupViewModel>('scannerApi/scannerSetup');
    }

    public async processScannerData(): Promise<ScannerDataListViewModel> {
        return httpService.post<ScannerDataListViewModel>('scannerApi/processScannerData');
    }
}
