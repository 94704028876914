











































































































































































































import Vue from 'vue';
import {
    Component,
    Prop,
    Watch
} from 'vue-property-decorator';
import {
    EasySupplyWarehouseDetailViewModel, KeyAndValue,
    ProductListReportAsEmailRequest,
    ReportOptionsViewModel, ReportType
} from '@/types/serverContract';
import {
    IEasySupplyPrintLabelsStepProgress,
    IEasySupplyPrintLabelsStep,
    IEasySupplyPrintLabelsStepNames,
    IEasySupplyPrintLabelsStepsCollection
} from '@/project/easySupply/printLabels/EasySupplyPrintLabels.service';
import EasySupplyPrintLabelsStepHeader from '@/project/easySupply/printLabels/EasySupplyPrintLabelsStepHeader.vue';
import EasySupplyPrintLabelsProductsStep from '@/project/easySupply/printLabels/EasySupplyPrintLabelsProductsStep.vue';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        SpinnerOverlay,
        EasySupplyPrintLabelsProductsStep,
        EasySupplyPrintLabelsStepHeader,
        ButtonSubmit
    }
})
export default class EasySupplyPrintLabels extends Vue {
    @Prop({
        required: true,
        type: Object
    }) warehouseDetails!: EasySupplyWarehouseDetailViewModel;

    emailAddressForReport = '';

    steps: IEasySupplyPrintLabelsStepsCollection = {
        products: {
            name: IEasySupplyPrintLabelsStepNames.products,
            chooseLabel: '1. ' + this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.ChooseProducts.Text),
            chosenLabel: '1. ' + this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.ChosenProducts),
            hasChoice: false,
            isActive: true
        },
        reportType: {
            name: IEasySupplyPrintLabelsStepNames.reportType,
            chooseLabel: '2. ' + this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.ChooseTemplate),
            chosenLabel: '2. ' + this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.ChosenTemplate),
            hasChoice: false,
            isActive: false
        },
        priceType: {
            name: IEasySupplyPrintLabelsStepNames.priceType,
            chooseLabel: '3. ' + this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.ChoosePriceType),
            chosenLabel: '3. ' + this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.ChosenPriceType),
            hasChoice: false,
            isActive: false
        },
        productNumberType: {
            name: IEasySupplyPrintLabelsStepNames.productNumberType,
            chooseLabel: '4. ' + this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.ChooseProductNumberType),
            chosenLabel: '4. ' + this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.ChosenProductNumberType),
            hasChoice: false,
            isActive: false
        },
        emailAddress: {
            name: IEasySupplyPrintLabelsStepNames.emailAddress,
            chooseLabel: '5. ' + this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.ChooseEmailAddress),
            chosenLabel: '5. ' + this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.ChosenEmailAddress),
            hasChoice: false,
            isActive: false
        }
    };

    reportOptions: ReportOptionsViewModel | null = null;
    pending: boolean = false;
    selectedLabelPrinterReportType: KeyAndValue<ReportType, string> | null = null;

    get stepsArray(): IEasySupplyPrintLabelsStep[] {
        return Object.values(this.steps).map(step => step);
    }

    get activeStepIndex(): number {
        return this.stepsArray.findIndex(step => step.isActive);
    }

    get stepProgress(): IEasySupplyPrintLabelsStepProgress {
        return {
            currentStepNumber: (this.activeStepIndex + 1).toString(),
            totalSteps: this.stepsArray.length.toString()
        };
    }

    get payload(): ProductListReportAsEmailRequest {
        return {
            variantIds: this.steps.products.choice,
            reportType: this.steps.reportType.choice.key,
            priceType: this.steps.priceType.choice.key,
            productNumberType: this.steps.productNumberType.choice.key,
            emailAddress: this.emailAddressForReport
        };
    }

    created(): void {
        this.$emit('stepChange', this.stepProgress);
        this.getReportOptions();
    }

    @Watch('steps.reportType.isActive')
    onShowReportTypeStep(): void {
        if (!this.steps.reportType.hasChoice && this.reportOptions !== null) {
            this.steps.reportType.choice = this.reportOptions.labelPrinterReportTypes[0];
            this.selectedLabelPrinterReportType = this.reportOptions.labelPrinterReportTypes[0];
            this.steps.reportType.hasChoice = true;
        }
    }

    @Watch('steps.priceType.isActive')
    onShowPriceTypeStep(): void {
        if (!this.steps.priceType.hasChoice && this.reportOptions !== null) {
            this.steps.priceType.choice = this.reportOptions.priceTypes[0];
            this.steps.priceType.hasChoice = true;
        }
    }

    @Watch('steps.productNumberType.isActive')
    onShowProductNumberTypeStep(): void {
        if (!this.steps.productNumberType.hasChoice && this.reportOptions !== null) {
            this.steps.productNumberType.choice = this.reportOptions.productNumberTypes[0];
            this.steps.productNumberType.hasChoice = true;
        }
    }

    @Watch('steps.emailAddress.isActive')
    onShowEmailAddressStep(): void {
        this.steps.emailAddress.choice = this.emailAddressForReport;
        this.steps.emailAddress.hasChoice = true;
    }

    async getReportOptions(): Promise<void> {
        this.pending = true;
        try {
            this.reportOptions = await Api.reports.reportOptions();
            this.emailAddressForReport = this.reportOptions.emailAddressForReport;
        } finally {
            this.pending = false;
        }
    }

    gotoStep(step: IEasySupplyPrintLabelsStep | 'next'): void {
        if (this.activeStepIndex !== undefined) {
            if (step === 'next') {
                step = this.stepsArray[this.activeStepIndex + 1];
            }
            this.stepsArray[this.activeStepIndex].isActive = false;
        }
        (step as IEasySupplyPrintLabelsStep).isActive = true;

        this.$emit('stepChange', this.stepProgress);
    }

    onChosenProductsChange(chosenProducts: string[]): void {
        this.steps.products.choice = chosenProducts;
        this.steps.products.hasChoice = chosenProducts.length >= 1;
    }

    onReportTypeChange(chosenReportType: KeyAndValue<ReportType, string>): void {
        this.steps.reportType.choice = chosenReportType;
        this.selectedLabelPrinterReportType = chosenReportType;
    }

    onPriceTypeChange(chosenPriceTypeNo: number): void {
        this.steps.priceType.choice = this.reportOptions!.priceTypes[chosenPriceTypeNo];
    }

    onProductNumberTypeChange(chosenPriceTypeNo: number): void {
        this.steps.productNumberType.choice = this.reportOptions!.priceTypes[chosenPriceTypeNo];
    }

    onEmailForReportChange(chosenEmailForReport: string): void {
        this.steps.emailAddress.choice = chosenEmailForReport;
        this.steps.emailAddress.hasChoice = chosenEmailForReport != null;
    }

    submitPrintLabels(): void {
        this.$emit('sendLabels', this.payload);
    }
}
