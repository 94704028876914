














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import {
    CategoryHitViewObject,
    RecommendationCategoryRequest,
    RecommendationParameter,
    RecommendationViewModel
} from '@/types/serverContract';
import RecommendationsCategoriesSlider from '@/project/recommendations/RecommendationsCategoriesSlider.vue';

@Component({
    components: {
        RecommendationsCategoriesSlider
    }
})

export default class RaptorCategoryView extends Vue {
    @Prop({
        type: String
    }) title!: string;

    @Prop({
        type: String,
        required: true
    }) method!: string;

    @Prop({
        type: Array,
        required: true
    }) parameters!: RecommendationParameter[];

    @Prop({
        type: Number
    }) maxElements!: number;

    pending: boolean = false;
    recommendationViewModel: RecommendationViewModel<CategoryHitViewObject> | null = null;

    get recommendationElements(): CategoryHitViewObject[] | null {
        return this.recommendationViewModel ? this.recommendationViewModel.elements : null;
    }

    created() {
        this.getRaptorCategories();
    }

    async getRaptorCategories() {
        this.pending = true;

        const payload: RecommendationCategoryRequest = {
            maxElements: this.maxElements,
            method: this.method,
            parameters: this.parameters
        };

        try {
            this.recommendationViewModel = await Api.recommendation.recommendationCategory(payload);
        } finally {
            this.pending = false;
        }
    }
}
