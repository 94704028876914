import Vue from 'vue';

import App from '@/App.vue';
import StoreSync from '../StoreSync.vue';
import Breakpoints from '../responsive/breakpoints/Breakpoints.vue';
import ResponsiveImage from '../responsive/image/ResponsiveImage.vue';
import FlickityWrap from '@/core/FlickityWrap.vue';
import WebApiMessage from '@/core/http/WebApiMessage.vue';
import RouterLinkConditional from '@/core/routerLinkConditional.vue';

// Global components (so they can appear on markup from server)
Vue.component('App', App);
Vue.component('StoreSync', StoreSync);
Vue.component('Breakpoints', Breakpoints);
Vue.component('ResponsiveImage', ResponsiveImage);
Vue.component('FlickityWrap', FlickityWrap);
Vue.component('WebApiMessage', WebApiMessage);
Vue.component('RouterLinkConditional', RouterLinkConditional);
