














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';

@Component({
    components: { SpinnerElement }
})
export default class Spinner extends Vue {
    @Prop({ required: false, type: Boolean }) show;
    @Prop({ required: false, type: Number, default: 250 }) delay;
    @Prop({ required: false, type: Number, default: 0 }) minDuration;
    @Prop({ default: true, type: Boolean }) usePortal!: boolean;
    @Prop({ required: false, default: false, type: Boolean }) showAboveOverlay;

    doShow = false;
    startTime = 0;
    timeout: number | null = null;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    @Watch('show', {
        immediate: true
    })
    onShowChange(value: boolean) {
        this.change(value);
    }

    change(value: boolean) {
        // Only show after delay, and if shown, show for at least minDuration.
        if (value) {
            this.timeout = setTimeout(() => {
                if (this.show) {
                    this.doShow = true;
                    this.startTime = new Date().valueOf();
                }
            }, this.delay);
        } else {
            const timeShown = new Date().valueOf() - this.startTime;
            const restTime = Math.max(0, this.minDuration - timeShown);
            this.timeout = setTimeout(() => {
                if (!this.show) {
                    this.doShow = false;
                }
            }, restTime);
        }
    }
}

