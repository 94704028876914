









































































































































































































































































































































































import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import Api from '@/project/http/api';
import {
    BaseReportRequest,
    CategoryHitViewObject,
    FacetGroupViewObject,
    ProductSearchResponse,
    ProductTileViewObject,
    SearchReportAsEmailRequest,
    SortByViewObject,
    CategoryScopeViewObject,
    ProductSearchRequest,
    BrandPromotionViewModel
} from '@/types/serverContract';
import {
    Prop,
    Watch
} from 'vue-property-decorator';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import urlHelper from './urlHelper.service';
import SearchFacets from './SearchFacets.vue';
import QueryPaging from '@/project/shared/QueryPaging.vue';
import ProductTile from '@/project/products/ProductTile.vue';
import { Route } from 'vue-router';
import SearchSorting from '@/project/search/SearchSorting.vue';
import SearchDidYouMean from '@/project/search/SearchDidYouMean.vue';
import SearchCategories from '@/project/search/SearchCategories.vue';
import scrollService from '@/core/scroll/scroll.service';
import SearchNoResultsFound from '@/project/search/SearchNoResultsFound.vue';
import SearchProductPromotions from '@/project/search/SearchProductPromotions.vue';
import ProductTileSkeleton from '@/project/shared/skeleton/ProductTile/ProductTileSkeleton.vue';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import DownloadCatalogForm from '@/project/shared/DownloadCatalogForm.vue';
import WebOffersCategories from '@/project/webOffers/WebOffersCategories.vue';
import SearchFeedback from '@/project/search/SearchFeedback.vue';
import { AppGetter } from '@/store/app';
import { FlexBoxGetter, FlexBoxAction } from '@/store/flexBox';
import tracking from '@/core/tracking/tracking.service';
import { downloadApi } from '@/project/config/utilities';
import Cookies from 'js-cookie';
import SearchBrandPromotion from '@/project/search/SearchBrandPromotion.vue';

@Component({
    components: {
        SearchBrandPromotion,
        SearchFeedback,
        ProductTileSkeleton,
        SearchProductPromotions,
        SearchNoResultsFound,
        SearchCategories,
        SearchDidYouMean,
        ProductTile,
        QueryPaging,
        OffCanvasOverlay,
        SpinnerOverlay,
        SearchFacets,
        SearchSorting,
        ModalOverlay,
        DownloadCatalogForm,
        WebOffersCategories
    }
})
export default class SearchResult extends Vue {
    @Prop({ default: '', type: String }) categoryId!: string;
    @Prop({ default: false, type: Boolean }) showSearchFeedback!: boolean;
    @Prop({ default: '', type: String }) categoryName!: string;
    @Prop({ default: false, type: Boolean }) showOnlySurplus!: boolean;
    @Prop({ default: false, type: Boolean }) includeCategoryAggregations!: boolean;
    @Prop({ default: '', type: String }) brand!: string;
    @Prop({ default: () => null, type: Object as PropType<BrandPromotionViewModel> }) brandPromotion!: BrandPromotionViewModel;

    @AppGetter isLoggedIn!: boolean;

    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @FlexBoxAction setShowFlexBoxModal!: (show: boolean) => void;

    pendingSearch: boolean = true;
    currentPage: number = 1;
    noOfFacetsSelected: number = urlHelper.noOfFacetsSelected();
    mobileFacetsOpen: boolean = false;
    searchResponse: ProductSearchResponse | null = null;
    activeSortByKey: string | null = null;
    searchTerm: string | null = null;
    showDownloadCatalogModal: boolean = false;
    successTimer: number = 4000;
    pending: boolean = false;
    success: boolean = false;
    successEmail: string | null = null;
    webOffersCategoryScopes: CategoryScopeViewObject[] | null = null;
    webOffersCategoryId: string = this.categoryId;
    timeout: number | null = null;
    showSetDefaultButton: boolean = true;

    get showSearchHeader(): boolean {
        return !this.showNoResults && this.brand === '';
    }

    get noResultsViewActivated(): any {
        return (this.showNoResults || this.spellCheckedTerm) && this.brand === '';
    }

    get noResultsHelpViewActivated(): boolean {
        return this.showNoResults && this.brand === '';
    }

    get termSuggestions(): string[] {
        return this.searchResponse && this.searchResponse.suggestions ? this.searchResponse.suggestions
            .map(s => s.term) : [];
    }

    get categories(): CategoryHitViewObject[] {
        return this.searchResponse ? this.searchResponse.categoryHits : [];
    }

    get spellCheckedTerm(): string {
        return this.searchResponse ? this.searchResponse.spellCheckedTerm : '';
    }

    get allowEasySupplyCsvDownload(): boolean {
        return this.searchResponse != null && this.searchResponse.allowEasySupplyCsvDownload;
    }

    get facetGroups(): FacetGroupViewObject[] {
        return this.searchResponse ? this.searchResponse.facets : [];
    }

    get products(): ProductTileViewObject[] {
        const results = this.searchResponse ? this.searchResponse.products : [];

        if (!results.length) {
            return results;
        }

        return this.maybeAddBrandPromotion(results);
    }

    get hasVvsResult(): boolean {
        return this.searchResponse ? this.searchResponse.products.length > 0 && this.searchResponse.isVvsResult : false;
    }

    get hasRegularResult(): boolean {
        return this.searchResponse ? this.searchResponse.products.length > 0 && !this.searchResponse.isVvsResult : false;
    }

    get recommendedProducts(): ProductTileViewObject[] {
        return this.searchResponse ? this.searchResponse.recommendedProducts : [];
    }

    get maxRecommendedProducts(): number {
        return this.searchResponse ? this.searchResponse.maxRecommendedProducts : 0;
    }

    get totalPages(): number {
        return this.searchResponse ? this.searchResponse.totalPages : 1;
    }

    get sortingOptions(): SortByViewObject[] {
        return this.searchResponse ? this.searchResponse.sortingOptions : [];
    }

    get getActiveSortByKey(): string | null {
        if (!this.sortingOptions) return null;

        let defaultOption = this.sortingOptions.find(option => option.isSelected);

        if (!defaultOption) {
            throw new Error('No sorting option selected - server error');
        }
        return defaultOption.id;
    }

    removeDefaultSortLabel() {
        let cookieDefault = Cookies.get('selectedsearchsorting');
        let defaultOption = this.sortingOptions.find(option => option.id === cookieDefault);
        if (defaultOption) {
            defaultOption.displayName = defaultOption.displayName.replace(this.$tr(this.$labels.Views.Search.Sorting.SelectedStandard), '');
        }
    }

    setDefaultSortLabel() {
        let cookieDefault = Cookies.get('selectedsearchsorting');
        let defaultOption = this.sortingOptions.find(option => option.id === cookieDefault);
        if (defaultOption) {
            defaultOption.displayName += ' ' + this.$tr(this.$labels.Views.Search.Sorting.SelectedStandard);
        }
    }

    get isCurrentDefaultSort(): boolean {
        let cookieDefault = Cookies.get('selectedsearchsorting');
        return cookieDefault === this.activeSortByKey;
    }

    get totalResults(): number {
        return this.searchResponse ? this.searchResponse.totalResults : 0;
    }

    get isSearch(): boolean {
        return !this.categoryId && !this.showOnlySurplus;
    }

    get showNoResults() {
        return this.isSearch && this.totalResults === 0 && !this.pendingSearch;
    }

    get showExplanation() {
        return this.searchResponse && this.searchResponse.searchExplanation && !this.pendingSearch;
    }

    get showPagingDidYouMeanSearchFeedbackSection(): boolean {
        return this.totalPages > 1 || this.showSearchFeedback || this.termSuggestions.length > 0;
    }

    created() {
        this.search();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path === oldRoute.path) {
            if (urlHelper.lastChangeWasNotFacet(route, oldRoute)) {
                // Scroll to top for all changes but facets
                scrollService.scrollToTop();
                this.searchTerm = urlHelper.getTerm();
            }
            this.search();
        }
    }

    saveNewDefaultSorting() {
        const sortSelectComponent = this.$refs.sortSelect as SearchSorting;
        const newDefaultSortKey = sortSelectComponent.getValue();
        this.removeDefaultSortLabel();

        Cookies.set('selectedsearchsorting', newDefaultSortKey, { sameSite: 'none', secure: true });

        this.setDefaultSortLabel();

        this.showSetDefaultButton = false;
    }

    async search() {
        try {
            this.pendingSearch = true;
            const searchPayload: ProductSearchRequest | any = {
                term: urlHelper.getTerm() || '',
                categoryId: this.webOffersCategoryId || urlHelper.getCategoryId(),
                selectedFacets: urlHelper.getFacets() || [],
                lastFacet: urlHelper.lastFacet(),
                page: urlHelper.getPage(),
                sortBy: urlHelper.getSortBy() || '',
                onlyShowSurplus: this.showOnlySurplus,
                includeCategoryAggregations: this.includeCategoryAggregations,
                includeFacetAggregations: true,
                skipVvsSearch: urlHelper.getSkipVvs(),
                explain: urlHelper.getExplain(),
                brand: this.brand
            };
            await Api.search.search(searchPayload).then(res => {
                this.searchResponse = res;
                this.currentPage = urlHelper.getPage();
                this.activeSortByKey = this.getActiveSortByKey;
                this.showSetDefaultButton = true;
                this.setDefaultSortLabel();
                this.searchTerm = urlHelper.getTerm();
                if (this.showOnlySurplus && !this.webOffersCategoryId) {
                    this.webOffersCategoryScopes = this.searchResponse.categoryScopes;
                }
            });
        } finally {
            if (this.searchResponse !== null) {
                // SearchResponse is null when we have search for a productId, because in that case the endpoint make a ClientRedirect and does not return anything
                this.pendingSearch = false;
            }
        }
    }

    async downloadCsv() {
        try {
            const downloadCsvPayload: ProductSearchRequest | any = {
                term: urlHelper.getTerm() || '',
                categoryId: this.webOffersCategoryId || urlHelper.getCategoryId(),
                selectedFacets: urlHelper.getFacets(),
                lastFacet: urlHelper.lastFacet(),
                page: urlHelper.getPage(),
                sortBy: urlHelper.getSortBy() || '',
                onlyShowSurplus: this.showOnlySurplus,
                includeCategoryAggregations: this.includeCategoryAggregations,
                includeFacetAggregations: true,
                skipVvsSearch: urlHelper.getSkipVvs(),
                explain: urlHelper.getExplain()
            };

            downloadApi('api/easySupplyApi/downloadSearchResultAsCsv', downloadCsvPayload);
        } finally {
        }
    }

    async downloadCatalog(baseReportRequest: BaseReportRequest, emailAddressForReport: string) {
        const downloadCatalogRequest: SearchReportAsEmailRequest = {
            emailAddress: emailAddressForReport,
            reportType: baseReportRequest.reportType,
            priceType: baseReportRequest.priceType,
            productNumberType: baseReportRequest.productNumberType
        };

        try {
            const reportAsEmailModel = await Api.reports.requestSearchReportAsEmail(downloadCatalogRequest,
                (!this.showNoResults && this.spellCheckedTerm) ? this.spellCheckedTerm : urlHelper.getTerm(),
                this.webOffersCategoryId || urlHelper.getCategoryId(),
                urlHelper.getFacets(),
                urlHelper.lastFacet(),
                urlHelper.getPage(),
                this.activeSortByKey || urlHelper.getSortBy(),
                this.showOnlySurplus,
                urlHelper.getSkipVvs()
            );
            this.successEmail = reportAsEmailModel.email;
            this.showSuccessIndicator();
        } finally {
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.closeDownloadCatalogModal();
            this.success = false;
            this.successEmail = null;
        }, this.successTimer);
    }

    closeDownloadCatalogModal() {
        this.showDownloadCatalogModal = false;
    }

    openMobileFacets() {
        this.mobileFacetsOpen = true;
        this.search();
    }

    facetsChange() {
        this.noOfFacetsSelected = urlHelper.noOfFacetsSelected();
    }

    newTerm(term: string) {
        urlHelper.setTerm(term);
    }

    skipVvsSearch() {
        urlHelper.setSkipVvs(true);
    }

    onWebOffersCategorySelected(categoryId: string): void {
        this.webOffersCategoryId = categoryId;
        urlHelper.setCategoryId(categoryId);
    }

    copyToClipboard(): void {
        if (this.searchResponse && this.searchResponse.searchExplanation) {
            const selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = this.searchResponse.searchExplanation;
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            document.body.removeChild(selBox);
        }
    }

    showFlexBoxModal() {
        tracking.trackFlexBoxButtonPress('No search results button', true);
        this.setShowFlexBoxModal(true);
    }

    private maybeAddBrandPromotion(products: ProductTileViewObject[]): any[] {
        if (!this.brandPromotion) {
            return products;
        }

        if (this.currentPage > 1) {
            return products;
        }

        if (this.totalResults < 1) {
            return products;
        }

        const existingIndex = products.findIndex(product => product.hasOwnProperty('videoUrl') || product.hasOwnProperty('imagePromotion'));

        if (existingIndex === -1) {
            products.splice(this.promotionPositionInResults(), 0, this.brandPromotion as any);
        }

        return products;
    }

    private promotionPositionInResults() {
        if (this.totalResults < 3) {
            return this.totalResults;
        }

        const insertAsThirdResultByDefault = 2;

        return insertAsThirdResultByDefault;
    }
}
