import httpService from '@/core/http/http.service';
import {
    CategoryReportAsEmailRequest,
    EasySupplyWarehouseReportAsEmailRequest,
    MaintenanceReportOptionsRequest,
    MaintenanceReportTreeViewModel, ProductListReportAsEmailRequest,
    ProductSearchRequest,
    ReportAsEmailViewModel,
    ReportOptionsViewModel,
    RequestMaintenanceReportAsEmailRequest,
    SearchReportAsEmailRequest,
    FavoriteReportAsEmailRequest,
    ProductCatalogReportAsEmailRequest
} from '@/types/serverContract';
import { UrlFacets } from '@/project/search/urlHelper.service';

export default class ReportsController {
    public async reportOptions(): Promise<ReportOptionsViewModel> {
        return httpService.get('reportsApi/reportOptions');
    }

    public async maintenanceReportOptions(payload: MaintenanceReportOptionsRequest): Promise<MaintenanceReportTreeViewModel> {
        return httpService.get<MaintenanceReportTreeViewModel>('reportsApi/maintenanceReportOptions', payload);
    }

    public async requestMaintenanceReportAsEmail(payload: RequestMaintenanceReportAsEmailRequest): Promise<ReportAsEmailViewModel> {
        return httpService.post<ReportAsEmailViewModel>('reportsApi/requestMaintenanceReportAsEmail', payload);
    }

    public async requestEasySupplyWarehouseReportAsEmail(payload: EasySupplyWarehouseReportAsEmailRequest): Promise<ReportAsEmailViewModel> {
        return httpService.post<ReportAsEmailViewModel>('reportsApi/requestEasySupplyWarehouseReportAsEmail', payload);
    }

    public async requestProductListReportAsEmail(payload: ProductListReportAsEmailRequest): Promise<ReportAsEmailViewModel> {
        return httpService.post<ReportAsEmailViewModel>('reportsApi/requestProductListReportAsEmail', payload);
    }

    public async requestFavoriteOrderReport(payload: FavoriteReportAsEmailRequest): Promise<ReportAsEmailViewModel> {
        return httpService.post<ReportAsEmailViewModel>('reportsApi/requestFavoriteOrderReport', payload);
    }

    public async requestProductCatalogReport(payload: ProductCatalogReportAsEmailRequest): Promise<ReportAsEmailViewModel> {
        return httpService.post<ReportAsEmailViewModel>('reportsApi/productCatalogReportAsEmail', payload);
    }

    public async requestSearchReportAsEmail(payload: SearchReportAsEmailRequest, term: string | null, categoryId: string | null, selectedFacets: UrlFacets, lastFacet: string, page: number, sortBy: string | null,
        onlyShowSurplus: boolean, skipVvsSearch: boolean): Promise<ReportAsEmailViewModel> {
        let params = {
            term,
            categoryId,
            ...selectedFacets,
            lastFacet,
            page,
            sortBy,
            onlyShowSurplus,
            includeCategoryAggregations: false,
            includeFacetAggregations: false,
            skipVvsSearch,
            explain: false
        } as ProductSearchRequest;
        return httpService.post<ReportAsEmailViewModel>('reportsApi/requestSearchReportAsEmail', payload, '', params);
    }

    public async requestCategoryReportAsEmail(payload: CategoryReportAsEmailRequest): Promise<ReportAsEmailViewModel> {
        return httpService.post<ReportAsEmailViewModel>('reportsApi/requestCategoryReportAsEmail', payload);
    }
}
