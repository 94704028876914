
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BasketGetter } from '@/store/basket';
import api from '@/project/http/api';
import ProductAddToBasketLifeCycleIcons from '@/project/products/ProductAddToBasketLifeCycleIcons.vue';

@Component({
    components: { ProductAddToBasketLifeCycleIcons }
})
export default class AddFavoriteOrderToBasket extends Vue {
    @Prop({
        required: true,
        type: Number
    }) favoriteOrderId!: number;

    @BasketGetter isBasketBusy!: boolean;

    pending: boolean = false;
    success: boolean = false;
    timeout: number | null = null;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async addAllLineItemsToBasket() {
        if (this.isBasketBusy || this.pending) {
            return;
        }

        this.pending = true;
        try {
            await api.basket.addFavoriteOrderToBasket(this.favoriteOrderId)
                .then(() => { this.showSuccessIndicator(); });
        } finally {
            this.pending = false;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, 3000);
    }
}
