import httpService from '@/core/http/http.service';
import {
    ArticleViewObject,
    CategoryHitViewObject,
    ContentRecommendationForProductRequest,
    ProductTileSlimViewObject,
    RecommendationForCategoriesRequest,
    RecommendationForCategoryByCustomerCategoryRequest,
    RecommendationForCategoryRequest,
    RecommendationForContentRequest,
    RecommendationForCustomerCategoryRequest,
    RecommendationForProductRequest,
    RecommendationRequest,
    RecommendationProductRequest,
    RecommendationContentRequest,
    RecommendationCategoryRequest,
    RecommendationViewModel,
    RecommendationBrandViewObject,
    RecommendationForBrandsRequest,
    ProductRecommendationForBrandRequest
} from '@/types/serverContract';

export default class RecommendationController {
    public async recommendationForBasket(): Promise<RecommendationViewModel<ProductTileSlimViewObject>> {
        return httpService.get<RecommendationViewModel<ProductTileSlimViewObject>>('/recommendationApi/recommendationForBasket');
    }

    public async recommendation(payload: RecommendationRequest): Promise<RecommendationViewModel<ProductTileSlimViewObject>> {
        return httpService.get<RecommendationViewModel<ProductTileSlimViewObject>>('/recommendationApi/recommendation', payload);
    }

    public async recommendationForProduct(payload: RecommendationForProductRequest): Promise<RecommendationViewModel<ProductTileSlimViewObject>> {
        return httpService.get<RecommendationViewModel<ProductTileSlimViewObject>>('/recommendationApi/recommendationForProduct', payload);
    }

    public async recommendationForBrand(payload: ProductRecommendationForBrandRequest): Promise<RecommendationViewModel<ProductTileSlimViewObject>> {
        return httpService.get<RecommendationViewModel<ProductTileSlimViewObject>>('/recommendationApi/productRecommendationForBrand', payload);
    }

    public async recommendationForBrands(payload: RecommendationForBrandsRequest): Promise<RecommendationViewModel<RecommendationBrandViewObject>> {
        return httpService.get<RecommendationViewModel<RecommendationBrandViewObject>>('/recommendationApi/recommendationForBrands', payload);
    }

    public async recommendationForCategory(payload: RecommendationForCategoryRequest): Promise<RecommendationViewModel<ProductTileSlimViewObject>> {
        return httpService.get<RecommendationViewModel<ProductTileSlimViewObject>>('/recommendationApi/recommendationForCategory', payload);
    }

    public async recommendationForCustomerCategory(payload: RecommendationForCustomerCategoryRequest): Promise<RecommendationViewModel<ProductTileSlimViewObject>> {
        return httpService.get<RecommendationViewModel<ProductTileSlimViewObject>>('/recommendationApi/recommendationForCustomerCategory', payload);
    }

    public async recommendationForCategoryByCustomerCategory(payload: RecommendationForCategoryByCustomerCategoryRequest): Promise<RecommendationViewModel<ProductTileSlimViewObject>> {
        return httpService.get<RecommendationViewModel<ProductTileSlimViewObject>>('/recommendationApi/recommendationForCategoryByCustomerCategory', payload);
    }

    public async recommendationCategories(payload: RecommendationForCategoriesRequest): Promise<RecommendationViewModel<CategoryHitViewObject>> {
        return httpService.get<RecommendationViewModel<CategoryHitViewObject>>('/recommendationApi/recommendationForCategories', payload);
    }

    public async recommendationForContent(payload: RecommendationForContentRequest): Promise<RecommendationViewModel<ProductTileSlimViewObject>> {
        return httpService.get<RecommendationViewModel<ProductTileSlimViewObject>>('/recommendationApi/recommendationForContent', payload);
    }

    public async recommendationContentForProduct(payload: ContentRecommendationForProductRequest): Promise<RecommendationViewModel<ArticleViewObject>> {
        return httpService.get<RecommendationViewModel<ArticleViewObject>>('/recommendationApi/contentRecommendationForProduct', payload);
    }

    public async recommendationProducts(payload: RecommendationProductRequest): Promise<RecommendationViewModel<ProductTileSlimViewObject>> {
        return httpService.post<RecommendationViewModel<ProductTileSlimViewObject>>('/recommendationApi/recommendationProducts', payload);
    }

    public async recommendationContent(payload: RecommendationContentRequest): Promise<RecommendationViewModel<ArticleViewObject>> {
        return httpService.post<RecommendationViewModel<ArticleViewObject>>('/recommendationApi/recommendationContent', payload);
    }

    public async recommendationCategory(payload: RecommendationCategoryRequest): Promise<RecommendationViewModel<CategoryHitViewObject>> {
        return httpService.post<RecommendationViewModel<CategoryHitViewObject>>('/recommendationApi/recommendationCategories', payload);
    }
}
