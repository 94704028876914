




















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { ProjectFolderLinkListViewModel } from '@/types/serverContract';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: { SpinnerOverlay }
})
export default class ProjectFolderLinks extends Vue {
    projectFolderLinks: ProjectFolderLinkListViewModel | null = null;
    pending: boolean = false;

    created(): void {
        this.getProductCatalogLinks();
    }

    async getProductCatalogLinks(): Promise<void> {
        this.pending = true;
        try {
            await Api.productCatalog.productCatalogLinks().then(productCatalogLinks => {
                this.projectFolderLinks = productCatalogLinks;
            });
        } finally {
            this.pending = false;
        }
    }
}
