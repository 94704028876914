import { BRIGHTNESS_MODE } from '@/core/enums/enums';

export class Helper {
    toTailwindClass(prefix: string, value: number | string) {
        return prefix + '-' + value;
    }

    hexToColor: { [key: string]: string } = {
        '#0073B6': 'ahlsell-blue',
        '#009FE3': 'cyan',
        '#023D62': 'marine',
        '#1269B0': 'ahlsell-unity-blue',
        '#5A7412': 'ahlsell-unity-green',
        '#80ADD2': 'ahlsell-blue-60',
        '#94A3B8': 'blue-blouse',
        '#A1C2DD': 'ahlsell-blue-45',
        '#B0DD34': 'limegreen',
        '#BF5B00': 'ahlsell-unity-orange',
        '#C1D6E8': 'ahlsell-blue-30',
        '#E0EAF2': 'ahlsell-blue-15',
        '#E5E5E5': 'cold-morning',
        '#F4F4F4': 'lighthouse',
        '#FFFFFF': 'white',
        '#E2F2F8': 'blue-200'
    };

    lightOrDark(color): BRIGHTNESS_MODE {
        // Variables for red, green, blue values
        var r, g, b, brightnessEstimation;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            // If RGB --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

            r = color[1];
            g = color[2];
            b = color[3];
        } else {
            // If hex --> Convert it to RGB: http://gist.github.com/983661
            color = +('0x' + color.slice(1).replace(
                color.length < 5 && /./g, '$&$&'));

            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        brightnessEstimation = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        // Using the HSP value, determine whether the color is light or dark
        if (brightnessEstimation > 127.5) {
            return BRIGHTNESS_MODE.Light;
        }
        return BRIGHTNESS_MODE.Dark;
    }
}
