


























































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import Api from '@/project/http/api';
import {
    GetProductCatalogsRequest,
    HelpDeskProductCatalogListViewModel,
    FavoriteOrderImportLineErrorViewObject
} from '@/types/serverContract';

@Component({
    components: { SpinnerOverlay }
})
export default class HelpDeskAdministerProjectFolderEditProductCatalog extends Vue {
    @Prop({
        required: true,
        type: Number
    }) salesAccountId!: number;

    file: File | null = null;
    title: string = '';
    pending: boolean = false;
    productCatalogList: HelpDeskProductCatalogListViewModel | null = null;
    importResultErrors: FavoriteOrderImportLineErrorViewObject[] = [];

    get requirementsExplanation(): string {
        return this.$tr(this.$labels.Views.FavoriteOrder.ImportFavoriteOrderView.RequirementsExplanation);
    }

    $refs!: {
        importCSVFile: HTMLInputElement;
    };

    created(): void {
        this.getProductCatalogs();
    }

    async getProductCatalogs(): Promise<void> {
        this.pending = true;

        try {
            const payload: GetProductCatalogsRequest = {
                salesAccountId: this.salesAccountId
            };
            await Api.helpDesk.getProductCatalogs(payload).then(productCatalogs => {
                if (productCatalogs) {
                    this.productCatalogList = productCatalogs;
                } else {
                    this.productCatalogList = {
                        productCatalogs: []
                    };
                }
            });
        } finally {
            this.pending = false;
        }
    }

    async submitForm(): Promise<void> {
        if (this.vvErrors.items.length || this.file === null) {
            return;
        }
        this.pending = true;
        try {
            this.importResultErrors = [];
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('title', this.title);
            formData.append('salesAccountId', this.salesAccountId.toString());

            await Api.helpDesk.uploadProductCatalog(formData).then(productCatalogs => {
                this.productCatalogList = productCatalogs.catalogs;
                if (productCatalogs.errors && productCatalogs.errors.length) {
                    this.importResultErrors = productCatalogs.errors;
                }
            });
        } finally {
            this.resetForm();
            this.pending = false;
        }
    }

    async deleteProductCatalog(productCatalogId: number): Promise<void> {
        this.pending = true;

        try {
            await Api.helpDesk.deleteProductCatalog(productCatalogId).then(() => {
                this.getProductCatalogs();
            });
        } finally {
            this.pending = false;
        }
    }

    resetForm(): void {
        this.file = null;
        (this.$refs.importCSVFile as HTMLInputElement).value = '';
        this.title = '';
    }

    onFileChange() {
        this.file = this.$refs.importCSVFile.files![0];
    }

    goBack(): void {
        this.$emit('goBack');
    }

    vvErrors!: any;
}
