import { render, staticRenderFns } from "./BrandDisplay.vue?vue&type=template&id=c7667910&scoped=true"
import script from "./BrandDisplay.vue?vue&type=script&lang=ts"
export * from "./BrandDisplay.vue?vue&type=script&lang=ts"
import style0 from "./BrandDisplay.vue?vue&type=style&index=0&id=c7667910&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7667910",
  null
  
)

export default component.exports