








































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import {
    BasketViewModel,
    DeliveryMethodsViewModel,
    OrderViewModel,
    DeliveryMethodViewObject,
    DeliveryType
} from '@/types/serverContract';
import { BasketGetter } from '@/store/basket';
import { CheckoutGetter, CheckoutAction } from '@/store/checkout';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import serverContext from '@/core/serverContext.service';
import { FlexBoxGetter } from '@/store/flexBox';
import flexBoxService from '@/project/flexBox/flexBox.service';
import bus from '@/core/bus';

const deliveryIconFromDeliveryId: { [deliveryId: string]: string} = {
    'DAG': 'sanicon-leveringsform-outline',
    'NAT': 'sanicon-leveringsform-nat-outline',
    'BUTIK': 'sanicon-collect-in-store-outline',
    'FLEXBOX': 'sanicon-flexbox',
    'CC': 'sanicon-click-collect-outline'
};

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class DeliveryMethods extends Vue {
    @BasketGetter basket!: BasketViewModel;
    @CheckoutGetter isCommentValid!: boolean;
    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @CheckoutAction setOrder!: (payload: OrderViewModel | {}) => void;
    @CheckoutAction setOrderApproved!: (payload: boolean) => boolean;

    deliveryMethods: DeliveryMethodsViewModel | null = null;
    currentDeliveryMethod: DeliveryMethodViewObject | null = null;
    pendingGetDeliveryMethods: boolean = false;
    currentDeliveryIcon: string = 'sanicon-delivery-day opacity-0';
    hasChosenDeliveryMethod: boolean = false;
    showSelectDeliveryMethod: boolean = false;
    distributionUrl: string = serverContext.sitePages.distributionPage ? serverContext.sitePages.distributionPage.url : '';
    flexBoxDisplayName: string = 'FlexBox';

    get deliveryText(): string {
        return flexBoxService.getFlexBoxInfoText(this.$labels.Views.FlexBox.TimeSpecific.OrderDeliveryIn.name, this.$labels.Views.FlexBox.TimeSpecific.OrderDeliveryIn.name);
    }

    get deliveryMethodDisplayName(): string {
        return this.currentDeliveryIcon === 'sanicon-flexbox' ? this.flexBoxDisplayName : this.currentDeliveryMethod ? this.currentDeliveryMethod.displayName : '';
    }

    async mounted() {
        if (this.isInFlexBoxContext) {
            this.setCurrentDeliveryMethodFromBasket();
        } else {
            this.getDeliveryMethods();
        }
    }

    removeSelectedMethod() {
        this.setOrder({});
        this.setOrderApproved(false);
        this.currentDeliveryMethod = null;
        this.hasChosenDeliveryMethod = false;
        this.showSelectDeliveryMethod = true;
        this.$emit('isLoading', false);
    }

    setCurrentDeliveryMethodFromBasket() {
        if (!this.basket || this.basket.deliveryMethod === undefined || this.basket.deliveryMethod === 0) {
            this.showSelectDeliveryMethod = true;
            this.$emit('isLoading', false);
        } else if (this.basket.deliveryMethod === DeliveryType.FLEXBOX && !this.currentDeliveryMethod) {
            this.setFlexBoxAsCurrentDeliveryMethod();
        } else if (this.deliveryMethods) {
            this.updateCurrentDeliveryMethod(this.deliveryMethods.options.find(deliveryMethod => DeliveryType[deliveryMethod.id] === this.basket.deliveryMethod));
        }
    }

    async getDeliveryMethods() {
        this.pendingGetDeliveryMethods = true;
        this.$emit('isLoading', true);
        try {
            this.deliveryMethods = await Api.deliveryMethod.getDeliveryMethods();
        } catch (e) {
            this.$emit('isLoading', false);
        } finally {
            if (this.isCommentValid) {
                this.setCurrentDeliveryMethodFromBasket();
            } else {
                // DeliveryMethods Basket is not valid because requisition nr. was removed, clearing order and setting orderApproved to false;
                this.removeSelectedMethod();
            }
            this.pendingGetDeliveryMethods = false;
        }
    }

    toggleShowSelectDeliveryMethod() {
        this.showSelectDeliveryMethod = !this.showSelectDeliveryMethod;
    }

    deliveryIcon(deliveryId: string): string | undefined {
        if (!deliveryId) {
            return;
        }
        return deliveryIconFromDeliveryId[deliveryId];
    }

    async updateCurrentDeliveryMethod(deliveryMethodOption: DeliveryMethodViewObject | undefined): Promise<void> {
        if (deliveryMethodOption === undefined || !deliveryMethodOption.enabled || !this.isCommentValid) return;

        if (this.currentDeliveryMethod !== deliveryMethodOption) {
            this.pendingGetDeliveryMethods = true;
            this.$emit('isLoading', true);
            try {
                await Api.deliveryMethod.updateDeliveryMethod(deliveryMethodOption.id);
            } finally {
                this.currentDeliveryMethod = deliveryMethodOption;
                this.currentDeliveryIcon = deliveryIconFromDeliveryId[this.currentDeliveryMethod.id];
                this.hasChosenDeliveryMethod = true;
                this.pendingGetDeliveryMethods = false;
                this.showSelectDeliveryMethod = false;
                bus.emit('DeliveryAddress-onDeliveryMethodChanged');
            }
        }
    }

    setFlexBoxAsCurrentDeliveryMethod(): void {
        this.currentDeliveryIcon = deliveryIconFromDeliveryId['FLEXBOX'];
        this.hasChosenDeliveryMethod = true;
        this.showSelectDeliveryMethod = false;
    }
}
