































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    SynonymDetailedViewModel,
    GetSynonymByIdRequest,
    UpdateSynonymRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class HelpDeskSynonymEditForm extends Vue {
    @Prop({
        required: true,
        type: Number
    }) synonymId!: number;

    synonym: SynonymDetailedViewModel | null = null;
    pending: boolean = false;

    get examplesText(): string {
        return this.$tr(this.$labels.Views.Helpdesk.EditSearch.EditSynonyms.ExamplesText);
    }

    created(): void {
        this.getSynonymById();
    }

    async getSynonymById(): Promise<void> {
        this.pending = true;
        const payload: GetSynonymByIdRequest = {
            id: this.synonymId
        };

        try {
            await Api.searchManagement.getSynonymById(payload).then(res => {
                this.synonym = res;
            });
        } finally {
            this.pending = false;
        }
    }

    async updateSynonym(): Promise<void> {
        if (!this.synonym) {
            return;
        }
        this.pending = true;
        const payload: UpdateSynonymRequest = {
            id: this.synonymId,
            description: this.synonym.description,
            synonym: this.synonym.synonym
        };

        try {
            await Api.searchManagement.updateSynonym(payload).then(() => {
                this.$emit('updateSynonyms');
                this.goBack();
            });
        } finally {
            this.pending = false;
        }
    }

    submitForm() {
        if (this.vvErrors.items.length) {
            return;
        }
        this.updateSynonym();
    }

    isActiveMessage(active: boolean): string {
        if (active) {
            return this.$tr(this.$labels.Views.Helpdesk.EditSearch.EditSynonyms.IsActive);
        } else {
            return this.$tr(this.$labels.Views.Helpdesk.EditSearch.EditSynonyms.IsNotActive);
        }
    }

    createdMessage(synonym: SynonymDetailedViewModel): string {
        return this.$tr(this.$labels.Views.Helpdesk.EditSearch.EditSynonyms.Created, synonym.createdDate, synonym.createdBy);
    }

    changedMessage(synonym: SynonymDetailedViewModel): string {
        return this.$tr(this.$labels.Views.Helpdesk.EditSearch.EditSynonyms.Changed, synonym.changedDate, synonym.changedBy);
    }

    goBack(): void {
        this.$emit('goBack');
    }

    vvErrors!: any;
}
