
































































import Vue from 'vue';
import {
    Component,
    Prop,
    Watch
} from 'vue-property-decorator';
import {
    EasySupplyWarehouseLineProcuredProductViewObject,
    EasySupplyWarehouseLinesForProcuredProductRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { Route } from 'vue-router';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class EasySupplyProcuredProductUsedIn extends Vue {
    @Prop({
        required: true,
        type: Number
    }) procuredProductId!: number;

    pending: boolean = false;
    easySupplyWarehouseList: EasySupplyWarehouseLineProcuredProductViewObject[] | null = null;

    get showSmartButton(): boolean {
        const smartButtonCount = this.easySupplyWarehouseList
            ? this.easySupplyWarehouseList.filter(warehouse => warehouse.smartButtonHardwareIdentifier).length
            : 0;
        return smartButtonCount > 0;
    }

    created() {
        this.getEasySupplyWarehouseList();
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            this.$emit('goToWarehouse');
        }
    }

    get ProcuredProductNotUsed(): boolean {
        return this.easySupplyWarehouseList !== null && this.easySupplyWarehouseList.length === 0;
    }

    async getEasySupplyWarehouseList() {
        this.pending = true;

        try {
            const payload: EasySupplyWarehouseLinesForProcuredProductRequest = {
                procuredProductId: this.procuredProductId,
                returnAllWarehouses: false
            };
            await Api.easySupply.easySupplyWarehouseLinesForProcuredProduct(payload).then(warehousesList => {
                this.easySupplyWarehouseList = warehousesList.warehouseLines;
            });
        } finally {
            this.pending = false;
        }
    }
}
