































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import './round.filter';

@Component
export default class VaPipeVolume extends Vue {
    volumeDiameter: number = 0.6;
    volumeLength: number = 15;
    litreResult: number = 0;
    showPipeVolumeCalculations: boolean = false;

    get cubicResult(): number {
        return this.litreResult / 1000;
    }

    created(): void {
        this.litreResult = this.calculateVolume();
    }

    calculateVolume(): number {
        const c3 = this.volumeDiameter;
        const c5 = this.volumeLength;

        const e3 = (c3 / 2);
        const f3 = (e3 * e3);
        const g3 = (Math.PI * f3 * 1000);

        const litreResult = (g3 * c5);
        this.litreResult = Math.ceil(litreResult);

        return litreResult;
    };

    toggle(): void {
        this.showPipeVolumeCalculations = !this.showPipeVolumeCalculations;
    }
}
