import httpService from '@/core/http/http.service';
import {
    QuotationDetailsViewModel,
    QuotationListViewModel,
    SearchQuotationsRequest
} from '@/types/serverContract';

export default class QuotationsController {
    public async searchQuotations(payload: SearchQuotationsRequest): Promise<QuotationListViewModel> {
        return httpService.get<QuotationListViewModel>('quotationApi/search', payload);
    }

    public async getQuotation(orderId: string): Promise<QuotationDetailsViewModel> {
        return httpService.get<QuotationDetailsViewModel>('quotationApi/quotation', { orderId });
    }
}
