










































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EventViewObject } from '@/types/serverContract';
import TrackAndTraceDetailsShipmentInfoEventLine from '@/project/myServices/TrackAndTraceDetailsShipmentInfoEventLine.vue';

@Component({
    components: { TrackAndTraceDetailsShipmentInfoEventLine }
})
export default class TrackAndTraceDetailsShipmentInfoEvents extends Vue {
    @Prop({
        required: true,
        type: Array
    }) shipmentEvents!: EventViewObject[];

    showAllShipmentEvents: boolean = false;

    setShowAllShipmentEvents(showAll: boolean): void {
        this.showAllShipmentEvents = showAll;
    }
}
