



























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { QuickAddFavoriteOrderLineItemRequest } from '@/types/serverContract';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import TextInput from '@/project/form/TextInput.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import keyboardService from '@/core/keyCodes';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class FavoriteOrderQuickAddLineItem extends Vue {
    @Prop({
        type: Number,
        required: true
    }) favoriteOrderId!: number;

    productId: string = '';
    productIdError = '';
    quantity: string = '1';
    quantityError = '';
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;

    get hasProductIdError(): boolean {
        return isNotNullEmptyOrUndefined(this.productIdError);
    }

    get hasQuantityError(): boolean {
        return isNotNullEmptyOrUndefined(this.quantityError);
    }

    get hasFormError(): boolean {
        return this.hasProductIdError || this.hasQuantityError;
    }

    $refs!: {
        productQuantity: TextInput;
    };

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    // We have to override pressing the enter key on key down because IE changes focus to the forms submit button when pressing the "Enter" key in an input field
    // This means that in IE both "document.activeElement" and "document.querySelector(':focus')" are both the button when the form is submitted
    handleProductIdKeyDown(event: KeyboardEvent) {
        if (keyboardService.isEnter(event)) {
            event.preventDefault();
            (this.$refs.productQuantity.$refs.input as HTMLInputElement).select();
        }
    }

    async quickAddLineItem() {
        this.pending = true;
        const payload: QuickAddFavoriteOrderLineItemRequest = {
            favoriteOrderId: this.favoriteOrderId,
            productId: this.productId.trim(),
            quantity: parseInt(this.quantity, 10)
        };

        try {
            await Api.favoriteOrder.quickAddLineItem(payload, 'quick-add-to-favorite-order').then(() => {
                this.showSuccessIndicator();
                this.$emit('updateFavoriteOrder');
            });
        } finally {
            this.pending = false;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
            this.$emit('closeModal');
            this.resetForm();
        }, this.successTimer);
    }

    resetForm(): void {
        this.productId = '';
        this.quantity = '1';
        this.$validator.reset();
    }
}

