











































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import api from '@/project/http/api';
import DownloadCatalogForm from '@/project/shared/DownloadCatalogForm.vue';
import {
    BaseReportRequest,
    ProductCatalogReportAsEmailRequest
} from '@/types/serverContract';
import urlHelper from '@/project/shared/services/urlHelperSimpleSearch.service';

@Component({
    components: {
        DownloadCatalogForm,
        ModalOverlay
    }
})
export default class ProductCatalogDetailsActionLinks extends Vue {
    pendingDownloadCatalog: boolean = false;
    showDownloadProductCatalogModal: boolean = false;
    pending: boolean = false;
    success: boolean = false;
    successEmail: string | null = null;
    successTimer: number = 4000;
    timeout: number | null = null;

    productCatalogId = urlHelper.getId();

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    openDownloadProductCatalogModal() {
        this.showDownloadProductCatalogModal = true;
        this.pendingDownloadCatalog = true;
    }

    async sendCatalog(baseReportRequest: BaseReportRequest, emailAddressForReport: string) {
        this.pending = true;

        const productCatalogReportRequest: ProductCatalogReportAsEmailRequest = {
            term: urlHelper.getSearchTerm(),
            sortBy: urlHelper.getSorting(),
            productCatalogId: this.productCatalogId,
            reportType: baseReportRequest.reportType,
            priceType: baseReportRequest.priceType,
            productNumberType: baseReportRequest.productNumberType,
            emailAddress: emailAddressForReport
        };

        try {
            const reportAsEmailModel = await api.reports.requestProductCatalogReport(productCatalogReportRequest);
            this.successEmail = reportAsEmailModel.email;
            this.showSuccessIndicator();
        } finally {
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.closeDownloadProductCatalogModal();
            this.success = false;
            this.successEmail = null;
        }, this.successTimer);
    }

    closeDownloadProductCatalogModal() {
        this.showDownloadProductCatalogModal = false;
        this.pendingDownloadCatalog = false;
    }
}
