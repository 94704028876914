























































































































import Component from 'vue-class-component';
import PageHeaderMobileMenu from './PageHeaderMobileMenu.vue';
import SideMenu from './SideMenu.vue';
import { Prop, Watch, Mixins } from 'vue-property-decorator';
import {
    IFeatureToggling,
    NavigationViewModel,
    LinkViewModel,
    LoginInformation
} from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';
import SearchPanel from '@/project/search/SearchPanel.vue';
import AuthenticationDesktopToggle from '@/project/authentication/AuthenticationDesktopToggle.vue';
import { AppGetter } from '@/store/app';
import MiniBasket from '@/project/basket/MiniBasket.vue';
import SiteSvgLogo from '@/project/navigation/page-header/SiteSvgLogo.vue';
import tracking, { NavigationClicksTypes } from '@/core/tracking/tracking.service';
import SelectAccountPanel from '@/project/authentication/SelectAccountPanel.vue';
import DropdownCtrl from '@/project/form/Dropdown/DropdownCtrl.vue';
import DropdownCtrlContent from '@/project/form/Dropdown/DropdownCtrlContent.vue';
import AccountAvatarCircle from '@/project/navigation/page-header/AccountAvatarCircle.vue';
import AccountAvatarMenu from '@/project/navigation/page-header/AccountAvatarMenu.vue';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoints.mixin';

@Component({
    components: {
        MiniBasket,
        AuthenticationDesktopToggle,
        PageHeaderMobileMenu,
        SideMenu,
        SearchPanel,
        SiteSvgLogo,
        SelectAccountPanel,
        DropdownCtrl,
        DropdownCtrlContent,
        AccountAvatarCircle,
        AccountAvatarMenu
    }
})
export default class PageHeaderTop extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @Prop({ required: true, type: Object }) navigation!: NavigationViewModel;
    @Prop({ default: false, type: Boolean }) hasCustomLogo!: boolean;
    @Prop({ default: '', type: String }) customLogoUrl!: string;
    @Prop({ default: false, type: Boolean }) hasShopLink!: boolean;
    @Prop({ default: () => {}, type: Object }) shopLink!: LinkViewModel;

    @AppGetter isLoggedIn!: boolean;
    @AppGetter isLoginPanelActive!: boolean;
    @AppGetter featureToggling!: IFeatureToggling;
    @AppGetter loginInformation!: LoginInformation;

    multiLevelPushMenuOpen = false;
    multiLevelPushMenuVisible = false;
    mobileMenuOpen = false;
    mobileMenuVisible = false;
    forceShowLogin = false;
    searchActive = false;

    get frontpageUrl(): string {
        return serverContext.sitePages.frontPage.url;
    }

    iconTransitionEnter(iconDirection) {
        const direction = iconDirection ? 'right' : 'left';
        return `u-rotate-in-${direction} u-anim-dur-150`;
    }

    iconTransitionLeave(iconDirection) {
        const direction = iconDirection ? 'right' : 'left';
        return `u-rotate-out-${direction} u-anim-dur-150`;
    }

    get isImpersonating(): boolean {
        return this.isLoggedIn && this.loginInformation !== null && this.loginInformation.isImpersonating;
    }

    get shouldShowLogin(): boolean {
        return this.featureToggling.loginEnabled && !this.isLoggedIn;
    }

    @Watch('mobileMenuOpen')
    onMobileMenuOpen(value: true) {
        if (!value) {
            this.forceShowLogin = false;
        }
    }

    toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
    }

    toggleMultiLevelPushMenu() {
        this.multiLevelPushMenuOpen = !this.multiLevelPushMenuOpen;
        if (this.multiLevelPushMenuOpen) {
            tracking.trackNavigationClick({ type: 'Left navigation bar - entrance', clickText: '' });
        }
    }

    updateMultiLevelPushMenuVisibility(show: boolean) {
        this.multiLevelPushMenuOpen = show;
        this.multiLevelPushMenuVisible = show;
    }

    closeMobileMenu() {
        this.mobileMenuOpen = false;
    }

    doShowLogin() {
        this.forceShowLogin = true;
        this.mobileMenuOpen = true;
    }

    handleSearchOverlayState(state: boolean): void {
        this.searchActive = state;
        this.$emit('searchState', state);
    }

    navigationTracking(typeName: NavigationClicksTypes = 'Top menu', clickText = 'Sanistaal Logo') {
        tracking.trackNavigationClick({ type: typeName, clickText: clickText });
    }
}
