











































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { FavoriteOrderSelectorItemViewObject } from '@/types/serverContract';
import Api from '@/project/http/api';

@Component
export default class FavoritesOrdersListModal extends Vue {
    favoriteOrdersPending: boolean = false;
    favoriteOrdersListSliced: FavoriteOrderSelectorItemViewObject[] | null = null;
    favoriteOrdersList: FavoriteOrderSelectorItemViewObject[] | null = null;
    favoriteOrderId: string = '';
    showingAllFavorites: boolean = false;

    created() {
        this.getFavoriteOrders();
    }

    @Watch('favoriteOrderId')
    favoriteOrderIdChanged(newValue: string) {
        this.$emit('favoriteOrderIdUpdated', newValue);
    }

    async getFavoriteOrders() {
        this.favoriteOrdersPending = true;
        try {
            await Api.favoriteOrder.getFavoriteOrdersForSelector().then(favoriteOrders => {
                if (favoriteOrders.favoriteOrderList.length >= 1) {
                    this.favoriteOrdersList = favoriteOrders.favoriteOrderList;

                    this.favoriteOrdersListSliced = this.favoriteOrdersList.slice(0, 10);
                    this.favoriteOrderId = this.favoriteOrdersList[0].id.toString();
                } else {
                    this.$emit('noFavoriteOrdersOnAccount');
                }
            });
        } finally {
            this.favoriteOrdersPending = false;
        }
    }

    showAllFavorites(): void {
        this.favoriteOrdersListSliced = this.favoriteOrdersList;
        this.showingAllFavorites = true;
    }
}
