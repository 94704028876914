























































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
    EasySupplyGatewayDetailViewObject,
    DeliveryAddressViewObject,
    Weekday
} from '@/types/serverContract';
import AddOrUpdateGateway from '@/project/easySupply/gateway/AddOrUpdateGateway.vue';
import ReplaceGateway from '@/project/easySupply/gateway/ReplaceGateway.vue';
import DeleteGateway from '@/project/easySupply/gateway/DeleteGateway.vue';
import UpdateGatewayFirmware from '@/project/easySupply/gateway/UpdateGatewayFirmware.vue';
import UpdateSmartButtonsFirmware from '@/project/easySupply/gateway/UpdateSmartButtonsFirmware.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';

@Component({
    components: {
        AddOrUpdateGateway,
        ReplaceGateway,
        DeleteGateway,
        UpdateGatewayFirmware,
        UpdateSmartButtonsFirmware,
        ButtonSubmit,
        ModalOverlay
    }
})
export default class EasySupplyStockListEditGateway extends Vue {
    @Prop({
        type: Object
    }) gateway!: EasySupplyGatewayDetailViewObject;

    @Prop({
        required: true,
        type: Number
    }) warehouseDetailId!: number;

    @Prop({
        required: true,
        type: Boolean
    }) allowManageGateway!: boolean;

    @Prop({
        required: true,
        type: Boolean
    }) allowUpdateFirmware!: boolean;

    showModal: boolean = false;
    isReplaceGateway: boolean = false;
    isUpdateGatewayFirmware: boolean = false;
    isUpdateSmartButtonsFirmware: boolean = false;
    isDeleteGateway: boolean = false;

    weekdaysAllowedToSendOrder: string = '';
    weekdays: any[] = [{
        day: Weekday.Monday,
        label: this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.Weekdays.Monday),
        selected: false
    }, {
        day: Weekday.Tuesday,
        label: this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.Weekdays.Tuesday),
        selected: false
    }, {
        day: Weekday.Wednesday,
        label: this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.Weekdays.Wednesday),
        selected: false
    }, {
        day: Weekday.Thursday,
        label: this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.Weekdays.Thursday),
        selected: false
    }, {
        day: Weekday.Friday,
        label: this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.Weekdays.Friday),
        selected: false
    }];

    get selectedDeliveryAddress(): DeliveryAddressViewObject | null {
        return this.gateway ? this.gateway.selectedDeliveryAddress : null;
    }

    get modalHeadingLabel(): string {
        if (!this.gateway) {
            return this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.AddGateway);
        } else {
            if (this.isReplaceGateway) {
                return this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.ReplaceGateway);
            } else if (this.isUpdateGatewayFirmware) {
                return this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.UpdateGatewayFirmware);
            } else if (this.isUpdateSmartButtonsFirmware) {
                return this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.UpdateSmartButtonsFirmware);
            } else {
                return this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.ReconfigureGateway);
            }
        }
    }

    get isAddOrUpdateGateway(): boolean {
        return !this.isReplaceGateway && !this.isDeleteGateway && !this.isUpdateGatewayFirmware && !this.isUpdateSmartButtonsFirmware;
    }

    created() {
        this.setSelectedWeekdays();
        this.setWeekdaysAllowedToSendOrder();
    }

    @Watch('gateway')
    onUpdatedGateway(): void {
        this.setSelectedWeekdays();
        this.setWeekdaysAllowedToSendOrder();
    }

    setSelectedWeekdays(): void {
        this.weekdays.forEach(weekday => {
            if (this.gateway && this.gateway.weekdaysAllowedToSendOrder.length && this.gateway.weekdaysAllowedToSendOrder.findIndex(x => x === weekday.day) > -1) {
                weekday.selected = true;
            } else {
                weekday.selected = false;
            }
        });
    }

    setWeekdaysAllowedToSendOrder(): void {
        this.weekdaysAllowedToSendOrder = this.weekdays
            .filter(weekday => weekday.selected)
            .map(weekday => { return weekday.label; })
            .join(', ');
    }

    openModal() {
        this.showModal = true;
    }

    closeModal() {
        this.resetForm();
        this.showModal = false;
    }

    resetForm(): void {
        this.setSelectedWeekdays();
        this.isReplaceGateway = false;
        this.isDeleteGateway = false;
        this.isUpdateGatewayFirmware = false;
        this.isUpdateSmartButtonsFirmware = false;
    }

    onUpdatedWarehouse(): void {
        this.$emit('updatedWarehouse');
    }

    showUpdateGatewayFirmware(): void {
        this.isUpdateGatewayFirmware = true;
    }

    hideUpdateGatewayFirmware(): void {
        this.isUpdateGatewayFirmware = false;
    }

    showUpdateSmartButtonsFirmware(): void {
        this.isUpdateSmartButtonsFirmware = true;
    }

    hideUpdateSmartButtonsFirmware(): void {
        this.isUpdateSmartButtonsFirmware = false;
    }

    showReplaceGateway(): void {
        this.isReplaceGateway = true;
    }

    hideReplaceGateway(): void {
        this.isReplaceGateway = false;
    }

    showDeleteGateway(): void {
        this.isDeleteGateway = true;
    }

    hideDeleteGateway(): void {
        this.isDeleteGateway = false;
    }
}
