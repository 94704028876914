





















import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import urlHelper from '../../favoriteOrders/urlHelperFavoriteOrders.service';

@Component
export default class ScannerListDetailsSearch extends Vue {
    searchTerm: string = '';

    @Watch('$route', {
        immediate: true
    })
    onRouteChange() {
        this.searchTerm = urlHelper.getSearchTerm() !== null ? urlHelper.getSearchTerm()! : '';
    }

    setSearchTerm() {
        urlHelper.setSearchTerm(this.searchTerm);
    }
}
