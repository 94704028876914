













































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import api from '@/project/http/api';
import {
    AddCommentsRequest,
    BasketViewModel,
    CaseInformationRequest,
    LoginInformation,
    SalesAccountViewObject
} from '@/types/serverContract';
import { BasketGetter } from '@/store/basket';
import { AppGetter } from '@/store/app';
import { debounce, isUndefined } from 'lodash-es';

const SuggestDebounceDelay = 500;

@Component
export default class DeliveryComment extends Vue {
    @BasketGetter basket!: BasketViewModel;
    @AppGetter loginInformation!: LoginInformation;

    showMaskExplanation: boolean = false;
    isCommentDirty: boolean = false;
    basketComment: AddCommentsRequest = {
        comment: '',
        caseInformation: {
            caseNumber: '',
            reference: '',
            requisitionNumber: ''
        } as CaseInformationRequest
    };

    private debouncedAddDeliveryComment = debounce(this.addCommentToOrder, SuggestDebounceDelay);

    get currentActiveAccount(): SalesAccountViewObject | null {
        return this.loginInformation ? this.loginInformation.currentAccount : null;
    }

    get hasRequisitionNumberMask(): boolean {
        return this.currentActiveAccount !== null &&
            this.currentActiveAccount.requisitionNumber.mask !== null &&
            !isUndefined(this.currentActiveAccount.requisitionNumber.mask) &&
            this.currentActiveAccount.requisitionNumber.mask !== '';
    }

    get hasCaseNumberMask(): boolean {
        return this.currentActiveAccount !== null &&
            this.currentActiveAccount.caseNumber.mask !== null &&
            !isUndefined(this.currentActiveAccount.caseNumber.mask) &&
            this.currentActiveAccount.caseNumber.mask !== '';
    }

    get hasReferenceContactPersonMask(): boolean {
        return this.currentActiveAccount !== null &&
            this.currentActiveAccount.referenceContactPerson.mask !== null &&
            !isUndefined(this.currentActiveAccount.referenceContactPerson.mask) &&
            this.currentActiveAccount.referenceContactPerson.mask !== '';
    }

    get isRequired(): boolean {
        return this.currentActiveAccount !== null &&
            (this.currentActiveAccount.requisitionNumber.required ||
            this.currentActiveAccount.caseNumber.required ||
            this.currentActiveAccount.referenceContactPerson.required);
    }

    mounted() {
        this.onBasketReady(this.basket);

        if (!this.isRequired) {
            this.$emit('validateComment', true);
        }
    }

    @Watch('basket')
    onBasketReady(val) {
        if (!this.isCommentDirty) {
            this.basketComment = {
                comment: val.comment || '',
                caseInformation: {
                    caseNumber: val.caseNumber || '',
                    reference: val.reference || '',
                    requisitionNumber: val.requisitionNumber || ''
                } as CaseInformationRequest
            } as AddCommentsRequest;
        }
    }

    async onFieldBlur() {
        this.debouncedAddDeliveryComment();
    }

    async onFieldInput() {
        if (!this.isCommentDirty) {
            this.isCommentDirty = true;
        }

        await this.$validator.validate();
        if (this.$validator.errors.any()) {
            this.$emit('validateComment', false);
        } else {
            this.$emit('validateComment', true);
        }
    }

    async addCommentToOrder() {
        await this.$validator.validate();

        if (this.$validator.errors.any()) {
            this.$emit('validateComment', false);
            return;
        }
        await api.basket.addCommentToBasket(this.basketComment, 'addBasketComment');
        this.$emit('validateComment', true);
    }

    setFieldMask(fieldName, fieldMask) {
        this.basketComment.caseInformation[fieldName] = fieldMask;
        (this.$refs[fieldName + 'Ref'] as HTMLElement).focus();
        this.debouncedAddDeliveryComment();
    }

    toggleMaskExplanation(): void {
        this.showMaskExplanation = !this.showMaskExplanation;
    }
}
