














import Vue from 'vue';
import Component from 'vue-class-component';
import { ArticleViewObject } from '@/types/serverContract';
import { Prop } from 'vue-property-decorator';
import ArticleCard from '@/project/articles/ArticleCard.vue';
@Component({
    components: { ArticleCard }
})
export default class ArticlesResult extends Vue {
    @Prop({
        type: Array,
        required: true
    }) articles!: ArticleViewObject[];
}
