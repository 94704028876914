




























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    EasySupplyWarehouseLineViewObject,
    DeleteEasySupplySmartButtonRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class DeleteSmartButton extends Vue {
    @Prop({
        required: true,
        type: Object
    }) warehouseLine!: EasySupplyWarehouseLineViewObject;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number = 0;

    get deleteSmartButtonWarning(): string {
        return this.$tr(this.$labels.Views.Services.EasySupply.Edit.Smartbutton.Warning);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async deleteSmartButton() {
        if (this.pending) return;

        this.pending = true;

        const payload: DeleteEasySupplySmartButtonRequest = {
            smartButtonHardwareIdentifier: this.warehouseLine.smartButton.smartButtonHardwareIdentifier,
            easySupplyWarehouseId: this.warehouseLine.easySupplyWarehouseId
        };

        try {
            await Api.easySupply.deleteEasySupplySmartButton(payload);
            this.showSuccessIndicator();
            this.$emit('updatedWarehouse');
            this.closeModal();
        } finally {
            this.pending = false;
        }
    }

    hideDeleteSmartButton(): void {
        this.$emit('hideDeleteSmartButton');
    }

    closeModal() {
        this.$emit('closeModal');
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors!: any;
}
