















import { Link } from '@/types/serverContract';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CTAButton extends Vue {
    @Prop({ type: Object, required: true }) destination!: Link;
    @Prop({ type: Boolean, required: true }) isDownload!: boolean;
}
