import qs from 'querystring';
import serverContext from '@/core/serverContext.service';
import dayjs from 'dayjs';

export const dateParseFormat = 'YYYY/MM/DD';

export function stringReplaceAll(target: string, find: string | string[], replacement: string): string {
    if (typeof find === 'string') {
        return target.split(find).join(replacement);
    } else {
        let newString = target;
        find.forEach(value => {
            newString = newString.split(value).join(replacement);
        });
        return newString;
    }
}

export function stringReplaceLast(target: string, find: string, replacement: string): string {
    const lastIndex = target.lastIndexOf(find);

    if (lastIndex === -1) {
        return target;
    }
    const beginString = target.substring(0, lastIndex);
    const endString = target.substring(lastIndex + find.length);

    return beginString + replacement + endString;
}

export function isNullOrWhitespace(input: string | undefined): boolean {
    return input === null || input === undefined || input.trim() === '';
}

export function isNotNullEmptyOrUndefined(input: any): boolean {
    if (typeof input === 'string') {
        return input !== '';
    }
    return input !== null && input !== undefined && input !== {};
}

export function downloadApi(apiUrl: string, payload: any): void {
    let queryString = qs.stringify(payload);

    if (payload && payload.selectedFacets) {
        const params = new URLSearchParams(queryString);
        var facets = Object.keys(payload.selectedFacets).map(function(key) {
            if (isNotNullEmptyOrUndefined(params.get(key))) {
                return '';
            }
            return key + '=' + payload.selectedFacets[key];
        }).join('&');

        queryString += '&' + facets;
    }

    const link = document.createElement('a');
    link.href = `${apiUrl}?${queryString}&siteName=${serverContext.Localization.siteName}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export function formatDateForApi(date: Date): string {
    return dayjs(date).format('YYYY/MM/DD');
}

export function uuid() {
    // @ts-ignore
    let crypto = window.crypto || window.msCrypto;
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function addBracketsToText(text: string): string {
    return `(${text})`;
}

export function debounce(func: Function, wait: number): Function {
    let timeout: any;

    return function executedFunction(...args: any[]): void {
        const later = (): void => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

export function throttle(func: Function, wait: number): Function {
    let isThrottled = false;

    return function executedFunction(...args: any[]): void {
        if (!isThrottled) {
            func(...args);
            isThrottled = true;
            setTimeout(() => {
                isThrottled = false;
            }, wait);
        }
    };
}
