












import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Heading extends Vue {
    @Prop({ default: '', type: String }) title!: string;
    @Prop({ default: 'h2', type: String, validator: (value: string) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value) }) level!: string;
    @Prop({ default: '', type: String }) classes!: string; // we need this to override default styles

    defaultClasses = [`${this.level}-style`];

    get activeClasses() {
        return this.classes || this.defaultClasses;
    }
}
