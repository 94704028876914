








































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EasySupplyWarehouseDetailsListItemViewObject } from '@/types/serverContract';
import Api from '@/project/http/api';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import keyboardService from '@/core/keyCodes';

@Component({
    components: {
        ButtonSubmit,
        ModalOverlay
    }
})
export default class EasySupplyWarehouseListItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) easySupplyWarehouse!: EasySupplyWarehouseDetailsListItemViewObject;

    pending: boolean = false;
    showConfirmationModal: boolean = false;

    get hasGatewayLabel(): string {
        return this.easySupplyWarehouse.hasGateway ? this.$tr(this.$labels.Views.Services.EasySupply.StockList.HasGateway) : '';
    }

    openConfirmationModal() {
        this.showConfirmationModal = true;
        document.addEventListener('keyup', this.keyHandler);
    }

    closeConfirmationModal() {
        this.showConfirmationModal = false;
        document.removeEventListener('keyup', this.keyHandler);
    }

    keyHandler(event) {
        if (keyboardService.isEnter(event)) {
            this.deleteEasySupply();
        }
    }

    async deleteEasySupply() {
        this.pending = true;

        try {
            this.closeConfirmationModal();
            const newEasySupplyWarehouseListViewModel = await Api.easySupply.deleteEasySupplyWarehouse(this.easySupplyWarehouse.id);
            this.$emit('warehouseDeleted', newEasySupplyWarehouseListViewModel.easySupplyWarehouseList);
        } finally {
            this.pending = false;
        }
    }
}
