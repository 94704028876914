















































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { RevenueRequestType, RevenueViewModel } from '@/types/serverContract';
import Api from '@/project/http/api';
import DashboardRevenueChart from '@/project/dashboard/DashboardRevenueChart.vue';
const BarChart = () => import('@/project/shared/charts/BarChart.vue');

@Component({
    components: {
        BarChart,
        DashboardRevenueChart
    }
})
export default class Dashboard extends Vue {
    private stringIsNumber = value => !isNaN(Number(value));
    revenueRequestTypes: Array<object> = Object.keys(RevenueRequestType)
        .filter(this.stringIsNumber)
        .map(key => RevenueRequestType[key]);

    selectedRevenueRequestType: RevenueRequestType =
    RevenueRequestType.ThisYear;

    pending: boolean = false;
    pendingRevenue: boolean = false;
    pendingRevenueByCategories: boolean = false;
    pendingRevenueByChannel: boolean = false;
    revenue: RevenueViewModel = {
        revenues: [],
        totalRevenue: 0
    };

    revenueByCategories: RevenueViewModel = {
        revenues: [],
        totalRevenue: 0
    };

    revenueByChannel: RevenueViewModel = {
        revenues: [],
        totalRevenue: 0
    };

    created(): void {
        this.getAllRevenues();
    }

    @Watch('selectedRevenueRequestType')
    async getAllRevenues(): Promise<void> {
        this.pending = true;
        try {
            await Promise.all([
                this.getRevenue(),
                this.getRevenueByCategories(),
                this.getRevenueByChannel()
            ]);
        } finally {
            this.pending = false;
        }
    }

    async getRevenue(): Promise<void> {
        this.pendingRevenue = true;
        try {
            await Api.dashboard
                .Revenue(this.selectedRevenueRequestType)
                .then(revenue => {
                    this.revenue = revenue;
                });
        } finally {
            this.pendingRevenue = false;
        }
    }

    async getRevenueByCategories(): Promise<void> {
        this.pendingRevenueByCategories = true;
        try {
            await Api.dashboard
                .RevenueByCategories(this.selectedRevenueRequestType)
                .then(revenueByCategories => {
                    this.revenueByCategories = revenueByCategories;
                });
        } finally {
            this.pendingRevenueByCategories = false;
        }
    }

    async getRevenueByChannel(): Promise<void> {
        this.pendingRevenueByChannel = true;
        try {
            await Api.dashboard
                .RevenueByChannel(this.selectedRevenueRequestType)
                .then(revenueByChannel => {
                    this.revenueByChannel = revenueByChannel;
                });
        } finally {
            this.pendingRevenueByChannel = false;
        }
    }

    onRevenueTypesChange(chosenRevenueRequestType: RevenueRequestType): void {
        this.selectedRevenueRequestType = RevenueRequestType[chosenRevenueRequestType] as unknown as RevenueRequestType;
    }
}
