















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HelpDeskSalesAccountsViewObject } from '@/types/serverContract';
import HelpDeskAdministerProjectFolderEditOffer
    from '@/project/helpDesk/AdministerProjectFolder/HelpDeskAdministerProjectFolderEditOffer.vue';
import HelpDeskAdministerProjectFolderEditProductCatalog
    from '@/project/helpDesk/AdministerProjectFolder/HelpDeskAdministerProjectFolderEditProductCatalog.vue';
import HelpDeskAdministerProjectFolderEditUsefullLinks
    from '@/project/helpDesk/AdministerProjectFolder/HelpDeskAdministerProjectFolderEditUsefullLinks.vue';
import HelpDeskAdministerProjectFolderEditProjectAgreements
    from '@/project/helpDesk/AdministerProjectFolder/HelpDeskAdministerProjectFolderEditProjectAgreements.vue';

type administrationActionTypes = 'editOffer' | 'editProductCatalog' | 'editUsefullLinks' | 'editProjectAgreements';
@Component({
    components: {
        HelpDeskAdministerProjectFolderEditProjectAgreements,
        HelpDeskAdministerProjectFolderEditUsefullLinks,
        HelpDeskAdministerProjectFolderEditProductCatalog,
        HelpDeskAdministerProjectFolderEditOffer
    }
})
export default class HelpDeskAdministerProjectFolder extends Vue {
    @Prop({
        required: true,
        type: Object
    }) selectedAccount!: HelpDeskSalesAccountsViewObject;

    selectedAdministrationAction: administrationActionTypes | null = null;

    setAdministrationAction(administrationAction: administrationActionTypes): void {
        this.selectedAdministrationAction = administrationAction;
    }

    resetAdministrationAction(): void {
        this.selectedAdministrationAction = null;
    }

    goBack(): void {
        this.$emit('goBack');
    }
}
