









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Co2FootprintViewObject } from '@/types/serverContract';
import ProductCo2InfoText from '@/project/products/ProductCo2InfoText.vue';

@Component({
    components: { ProductCo2InfoText }
})
export default class ProductCo2InfoSimple extends Vue {
    @Prop({
        type: Object,
        required: true
    }) co2CalculationItem!: Co2FootprintViewObject;
}
