




































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { GetAccountStatementsRequest } from '@/types/serverContract';
import dayjs, { Dayjs } from 'dayjs';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import BookkeepingUrlHelper from './BookkeepingUrlHelper.service';
import { dateParseFormat, downloadApi } from '@/project/config/utilities';
import { clone } from 'lodash-es';
import DatePickerCtrl from '@/project/form/DatePickerCtrl.vue';

@Component({
    components: {
        DatePickerCtrl,
        SpinnerOverlay
    }
})
export default class AccountStatements extends Vue {
    pending: boolean = false;
    getAccountStatementsRequestPayload: GetAccountStatementsRequest = {
        fromDate: BookkeepingUrlHelper.getSearchQuery('fromDate') === '' ? dayjs().subtract(6, 'month').format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('fromDate'),
        toDate: BookkeepingUrlHelper.getSearchQuery('toDate') === '' ? dayjs().format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('toDate')
    };

    get isDateSpanTooLong(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) > 6;
    }

    get isDateSpanTooShort(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) < 0;
    }

    get dayjsFromDate(): Dayjs {
        return dayjs(this.getAccountStatementsRequestPayload.fromDate);
    }

    get dayjsToDate(): Dayjs {
        return dayjs(this.getAccountStatementsRequestPayload.toDate);
    }

    @Watch('getAccountStatementsRequestPayload.fromDate')
    setFromDate(): void {
        if (this.isDateSpanTooLong) {
            this.getAccountStatementsRequestPayload.toDate = this.dayjsFromDate.clone().add(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.getAccountStatementsRequestPayload.toDate = clone(this.getAccountStatementsRequestPayload.fromDate);
        }
    }

    @Watch('getAccountStatementsRequestPayload.toDate')
    setToDate(): void {
        if (this.isDateSpanTooLong) {
            this.getAccountStatementsRequestPayload.fromDate = this.dayjsToDate.clone().subtract(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.getAccountStatementsRequestPayload.fromDate = clone(this.getAccountStatementsRequestPayload.toDate);
        }
    }

    async submitForm(): Promise<void> {
        this.pending = true;
        try {
            downloadApi('api/AccountStatementsApi/AccountStatements', {
                fromDate: this.getAccountStatementsRequestPayload.fromDate,
                toDate: this.getAccountStatementsRequestPayload.toDate
            });
        } finally {
            this.$emit('downloadSuccess');
            BookkeepingUrlHelper.setSearchQuery({
                fromDate: this.getAccountStatementsRequestPayload.fromDate,
                toDate: this.getAccountStatementsRequestPayload.toDate
            });
            this.pending = false;
        }
    }
}
