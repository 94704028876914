

































import Vue from 'vue';
import Component from 'vue-class-component';
import { AppGetter } from '@/store/app';
import serverContext from '@/core/serverContext.service';
import {
    CheckoutGetter,
    CheckoutAction
} from '@/store/checkout';
import { LoginInformation } from '@/types/serverContract';

@Component
export default class CheckoutLinks extends Vue {
    @AppGetter loginInformation!: LoginInformation;
    @CheckoutGetter isOrderApproved!: boolean;
    @CheckoutGetter isCommentValid!: boolean;
    @CheckoutGetter isOrderSubmitting!: boolean;
    @CheckoutAction setOrderSubmitting!: (payload: boolean) => void;

    get basketUrl(): string {
        return serverContext.sitePages.basketPage.url;
    }

    submitOrder() {
        if (this.isOrderSubmitting || !this.isOrderApproved || !this.isCommentValid || !this.loginInformation.canSubmitOrder) {
            return;
        }
        this.setOrderSubmitting(true);
    }
}
