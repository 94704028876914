






























import Vue from 'vue';
import Component from 'vue-class-component';
import co2ModalState from '@/project/products/productCo2Modal.state';
import { Prop } from 'vue-property-decorator';
import { Co2CalculationItemViewObject } from '@/types/serverContract';
import ProductCo2InfoText from '@/project/products/ProductCo2InfoText.vue';

const { show } = co2ModalState();

@Component({
    components: { ProductCo2InfoText }
})
export default class ProductCo2Info extends Vue {
    @Prop({
        type: Object,
        required: true
    }) co2CalculationItem!: Co2CalculationItemViewObject;

    show = show;
}
