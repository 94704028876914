







































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { debounce } from 'lodash-es';

@Component
export default class LineReference extends Vue {
    @Prop({
        default: '',
        type: String
    }) lineReferenceText!: string;

    @Prop({
        default: '',
        type: Array
    }) referenceHelpList!: string[];

    currentReferenceHelpList: string[] = [];
    suggestDebounceDelay = 1000;
    referenceLineInput: string = '';
    currentSavedReferenceLine: string = '';
    debouncedSetReferenceLineChange = debounce(this.setReferenceLineChange, this.suggestDebounceDelay);
    isFocused = false;
    hasFirstInteraction: boolean = false;

    @Watch('referenceHelpList')
    onReferenceHelpListChange() {
        this.currentSavedReferenceLine = this.referenceLineInput;
        this.filterHelpText();
    }

    created() {
        this.referenceLineInput = this.lineReferenceText;
        this.filterHelpText();
    }

    onFocus() {
        this.isFocused = true;
    }

    onBlur() {
        this.isFocused = false;
    }

    setHelpText(referenceHelpText: string): void {
        this.referenceLineInput = referenceHelpText;
        this.setReferenceLineChange();
    }

    inputChangeActions(): void {
        // Regular expression to match emojis
        const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
        this.referenceLineInput = this.referenceLineInput.replace(emojiRegex, '');

        this.filterHelpText();
        this.debouncedSetReferenceLineChange();

        if (this.hasFirstInteraction) {
            return;
        }

        this.hasFirstInteraction = true;
        this.$emit('first-interaction');
    }

    filterHelpText(): void {
        this.currentReferenceHelpList = this.referenceHelpList.filter((referenceHelpText) => {
            return referenceHelpText !== this.referenceLineInput && referenceHelpText !== this.currentSavedReferenceLine && referenceHelpText.includes(this.referenceLineInput);
        });
    }

    setReferenceLineChange(): void {
        this.$emit('update:reference-line', this.referenceLineInput);
        this.$emit('reference-line-update');
    }
}
