var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-checkbox"},[_c('label',{staticClass:"focus-within:outline-basic relative flex"},[_c('input',{directives:[{name:"validate",rawName:"v-validate.disable",value:(_vm.constraints),expression:"constraints",modifiers:{"disable":true}}],ref:"toggle",staticClass:"absolute inset-0 opacity-0",attrs:{"id":_vm.id,"type":"checkbox","name":_vm.name},domProps:{"checked":_vm.ToggledRight},on:{"click":_vm.validateRequestedUpdate,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.validateRequestedUpdate($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }return _vm.validateRequestedUpdate($event)}]}}),_c('div',{ref:_vm.toggleRef,staticClass:"toggle relative flex cursor-pointer items-center justify-center rounded-full border",class:[_vm.borderColor, _vm.bodyColor]},[_c('div',{ref:_vm.dotRef,staticClass:"dot shadow-lg absolute z-1 flex items-center justify-center rounded-full",class:[
                    {
                        'is-left': !_vm.localToggledRight,
                        'is-right': _vm.localToggledRight,
                        'base-color': !_vm.dotColor,
                        'has-url': _vm.hasUrl && _vm.isHovered
                    },
                    _vm.dotColor
                ]},[_c('KeepAlive',[_vm._t(_vm.contentDot)],2)],1),_c('RouterLinkConditional',{staticClass:"left-content block",attrs:{"to":_vm.leftUrl},nativeOn:{"mouseenter":function($event){_vm.isHoveredLeft = true},"mouseleave":function($event){_vm.isHoveredLeft = false}}},[_c('div',{staticClass:"relative z-1 select-none",style:(_vm.dynamicMargins)},[_c('KeepAlive',[_vm._t(_vm.contentLeft)],2)],1)]),_c('RouterLinkConditional',{staticClass:"right-content block",attrs:{"to":_vm.rightUrl},nativeOn:{"mouseenter":function($event){_vm.isHoveredRight = true},"mouseleave":function($event){_vm.isHoveredRight = false}}},[_c('div',{staticClass:"relative z-1 select-none",style:(_vm.dynamicMargins)},[_c('KeepAlive',[_vm._t(_vm.contentRight)],2)],1)])],1),(_vm.displayLabel)?_c('span',{staticClass:"text-sm font-medium ml-8"},[_vm._v(_vm._s(_vm.displayLabel))]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }