function hashCode(text: string): number {
    var hash = 0;
    if (text.length === 0) {
        return hash;
    }
    for (var i = 0; i < text.length; i++) {
        var char = text.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

function cookieKey(msg: string): string {
    return `sani_infomsg_${hashCode(msg)}`;
}

function cookieKeyValue(msg: string): string {
    return `${cookieKey(msg)}=true`;
}

function addMessage(msg: string, hoursHidden: number): void {
    let expires = new Date();
    expires.setTime(expires.getTime() + hoursHidden * 60 * 60 * 1000);
    document.cookie = `${cookieKeyValue(msg)}; expires=${expires.toUTCString()}; path=/`;
}

function hasBeenMuted(msg: string): boolean {
    const cookies = document.cookie.split('; ');
    return cookies.indexOf(cookieKeyValue(msg)) > -1;
}

export default {
    addMessage,
    hasBeenMuted
};
