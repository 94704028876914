import { render, staticRenderFns } from "./SearchProductPromotions.vue?vue&type=template&id=16a50c7e&scoped=true"
import script from "./SearchProductPromotions.vue?vue&type=script&lang=ts"
export * from "./SearchProductPromotions.vue?vue&type=script&lang=ts"
import style0 from "./SearchProductPromotions.vue?vue&type=style&index=0&id=16a50c7e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a50c7e",
  null
  
)

export default component.exports