


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import urlHelper from './urlHelper.service';
import scrollService from '@/core/scroll/scroll.service';
import tracking from '@/core/tracking/tracking.service';

@Component
export default class SearchDidYouMean extends Vue {
    @Prop({
        type: Array
    })
    terms!: string[];

    @Prop({
        default: 0,
        type: Number
    }) totalSearchResults!: number;

    timeout: number | null = null;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    search(term: string) {
        urlHelper.setTerm(term);
        this.trackSearchNavigation();
        this.timeout = setTimeout(scrollService.scrollToTop, 200);
    }

    private trackSearchNavigation(): void {
        tracking.trackSearchNavigation('Bottom search refinement', this.totalSearchResults);
    }
}
