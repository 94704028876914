import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import bus from '@/core/bus';
import { Position } from 'vue-router/types/router';
import { ensure as pageReadyForScroll, cancel as cancelPendingWaiting } from './ensure';

Vue.use(VueRouter);

function preventAutoScroll() {
    if ('scrollRestoration' in history) {
        try {
            history.scrollRestoration = 'manual';
        } catch (e) {
            // Ignore
        }
    }
}

preventAutoScroll();

export const SpaPageReadyEventKey = 'SpaPageReady';

export const router: VueRouter = new VueRouter({
    scrollBehavior(to: Route, from: Route, savedPosition: Position | void) {
        // Make sure an unfinished waiting to scroll is canceled first.
        cancelPendingWaiting();
        if (to.path === from.path) {
            // Only query or params changed - don't scroll
            return;
        }

        return new Promise((resolve) => {
            bus.once(SpaPageReadyEventKey, async() => {
                if (to.hash) {
                    resolve({
                        selector: to.hash
                    });
                    return;
                } else if (!savedPosition) {
                    // New page with no hash - scroll to top
                    resolve({ x: 0, y: 0 });
                    return;
                }
                // We have a saved position. New SPA page is now loaded but async elements may not be there yet, so page could be too short
                try {
                    const position = await pageReadyForScroll<Position | void>(
                        () => document.body.clientHeight >= (savedPosition as Position).y + window.innerHeight,
                        savedPosition);
                    resolve(position);
                } catch (e) {
                    // Timeout or cancelled - dont scroll
                    resolve();
                }
            });
        });
    },
    mode: 'history'
});

// Potential initial scrolling to hash
/* bus.once(SpaPageRenderedEventKey, async() => {
    const hashElement = router.currentRoute.hash && document.querySelector(router.currentRoute.hash) as HTMLElement;
    if (hashElement) {
        hashElement.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
}); */
