













































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    ConfirmDeleteFavoriteOrderViewObject,
    ConfirmUserDeletionRequest,
    DeleteUserRequest,
    DeleteUserResultViewModel, FavoriteOrderTransferViewObject,
    UserAdministrationListViewObject,
    FavoriteOrdersPerAccountViewObject
} from '@/types/serverContract';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import Api from '@/project/http/api';

interface IDeleteUserState {
    deleteUser: boolean;
    hasSharedFavoritesOnUser: boolean;
}

@Component({
    components: {
        ModalOverlay,
        ButtonSubmit
    }
})
export default class UserListItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) user!: UserAdministrationListViewObject;

    showConfirmDeleteModal: boolean = false;
    userResultViewModel: DeleteUserResultViewModel | null = null;
    favoriteTransfers: FavoriteOrderTransferViewObject[] | null = null;
    deleteUserState: IDeleteUserState = {
        deleteUser: true,
        hasSharedFavoritesOnUser: false
    };

    async deleteUser() {
        if (!this.user.canDeleteUser) {
            return;
        }

        const payload: DeleteUserRequest = {
            userIdentifier: this.user.userIdentifier
        };

        try {
            this.userResultViewModel = await Api.user.deleteUser(payload);
            if (this.userResultViewModel.userWasDeleted) {
                this.closeModal();
                this.$emit('updatedUser');
            } else if (this.userResultViewModel.confirmation) {
                this.favoriteTransfers = [];
                this.selectAllFavoriteTransfers(this.userResultViewModel.confirmation.sharedFavoriteOrders);
                this.updateDeleteUserState();
            }
        } finally {
        }
    }

    async confirmUserDeletion() {
        if (!this.favoriteTransfers) {
            return;
        }

        try {
            const payload: ConfirmUserDeletionRequest = {
                userIdentifier: this.user.userIdentifier,
                favoriteTransfers: this.favoriteTransfers
            };
            await Api.user.confirmUserDeletion(payload);
            this.closeModal();
            this.$emit('updatedUser');
        } finally {
        }
    }

    selectAllFavoriteTransfers(sharedFavoriteOrders: FavoriteOrdersPerAccountViewObject[]): void {
        if (!this.favoriteTransfers) {
            return;
        }
        for (var i = 0; i < sharedFavoriteOrders.length; i++) {
            let sharedFavoriteOrder = sharedFavoriteOrders[i];
            for (var j = 0; j < sharedFavoriteOrder.confirmDeleteFavoriteOrders.length; j++) {
                const favoriteTransfer: FavoriteOrderTransferViewObject = {
                    favoriteOrderId: sharedFavoriteOrder.confirmDeleteFavoriteOrders[j].id
                };
                this.favoriteTransfers.push(favoriteTransfer);
            }
        }
    }

    updateDeleteUserState(): void {
        this.deleteUserState = {
            deleteUser: false,
            hasSharedFavoritesOnUser: true
        };
    }

    updateFavoriteTransfers(favoriteOrder: ConfirmDeleteFavoriteOrderViewObject): void {
        const favoriteTransfer: FavoriteOrderTransferViewObject = {
            favoriteOrderId: favoriteOrder.id
        };
        const itemIndex = this.favoriteTransfers!.findIndex(favoriteOrderTransfer => favoriteOrderTransfer.favoriteOrderId === favoriteTransfer.favoriteOrderId);

        if (this.hasMatch(favoriteTransfer)) {
            this.favoriteTransfers!.splice(itemIndex, 1);
        } else {
            this.favoriteTransfers!.push(favoriteTransfer);
        }
    }

    hasMatch(favoriteTransfer: FavoriteOrderTransferViewObject): boolean {
        return this.favoriteTransfers!.some(favoriteOrderTransfer => favoriteTransfer.favoriteOrderId === favoriteOrderTransfer.favoriteOrderId);
    }

    closeModal(): void {
        this.showConfirmDeleteModal = false;
    }

    isSelected(favoriteOrder: ConfirmDeleteFavoriteOrderViewObject): boolean {
        const favoriteTransfer: FavoriteOrderTransferViewObject = {
            favoriteOrderId: favoriteOrder.id
        };
        return this.hasMatch(favoriteTransfer);
    }
}
