import { render, staticRenderFns } from "./FavoriteOrder.vue?vue&type=template&id=9052e898&scoped=true"
import script from "./FavoriteOrder.vue?vue&type=script&lang=ts"
export * from "./FavoriteOrder.vue?vue&type=script&lang=ts"
import style0 from "./FavoriteOrder.vue?vue&type=style&index=0&id=9052e898&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9052e898",
  null
  
)

export default component.exports