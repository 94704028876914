import httpService from '@/core/http/http.service';
import { ClimateCertificateViewModel } from '@/types/serverContract';

export default class ClimateCertificatesController {
    climateCertificateCache: { [key: string]: ClimateCertificateViewModel } = {};

    public async getClimateCertificationIconDescription(climateCertificateName: string): Promise<ClimateCertificateViewModel> {
        if (!this.climateCertificateCache[climateCertificateName]) {
            this.climateCertificateCache[climateCertificateName] = await httpService.get(`ClimateCertificationApi/GetClimateCertification?climateCertificateName=${climateCertificateName}`);
        }

        return this.climateCertificateCache[climateCertificateName];
    }
}
