



































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { filter, orderBy } from 'lodash-es';
import {
    UserAdministrationListViewObject,
    UserAdministrationViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';
import UserListItem from '@/project/user/UserListItem.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

type sortingDirection = 'asc' | 'desc';

@Component({
    components: {
        SpinnerOverlay,
        UserListItem
    }
})
export default class UsersAdministration extends Vue {
    searchTerm: string = '';
    activeSortingKey: string = '';
    activeSortingDirection: sortingDirection = 'asc';
    userAdministrationViewModel: UserAdministrationViewModel | null = null;
    pending: boolean = false;

    get filteredUsers(): UserAdministrationListViewObject[] {
        if (this.searchTerm !== '') {
            return filter(this.userAdministrationViewModel!.users, user => Object.values([user.username, user.name, user.status, user.userType]).join('').toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0);
        } else {
            return this.userAdministrationViewModel!.users;
        }
    }

    created() {
        this.getUsers();
    }

    async getUsers() {
        this.pending = true;

        try {
            this.userAdministrationViewModel = await Api.account.getUsersForAccount();
        } finally {
            this.pending = false;
        }
    }

    updatedUser(): void {
        this.getUsers();
    }

    sortFavoriteOrdersList(sortingKey: string) {
        if (sortingKey === this.activeSortingKey) {
            this.toggleSortingDirection();
        } else {
            this.activeSortingKey = sortingKey;
            this.activeSortingDirection = 'asc';
        }

        if (this.userAdministrationViewModel) {
            this.userAdministrationViewModel.users = orderBy(this.userAdministrationViewModel.users, item => item[sortingKey], this.activeSortingDirection) as UserAdministrationListViewObject[];
        }
    }

    toggleSortingDirection() {
        this.activeSortingDirection = this.activeSortingDirection === 'asc' ? 'desc' : 'asc';
    }
}
