



































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import urlHelper from '../../favoriteOrders/urlHelperFavoriteOrders.service';
import {
    FavoriteListMergeType,
    FavoriteOrderListItemViewObject,
    FavoriteOrdersViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';

@Component
export default class CopyScannerListToFavoriteOrderForm extends Vue {
    favoriteOrderMergeTypes = FavoriteListMergeType;
    selectedMergeType: string = this.favoriteOrderMergeTypes[this.favoriteOrderMergeTypes.New];
    newFavoriteOrderName: string = '';
    newFavoriteOrderIsShared: boolean = false;
    showFavoriteOrdersList: boolean = false;
    favoriteOrders: FavoriteOrdersViewModel | null = null;
    selectedFavoriteOrder: FavoriteOrderListItemViewObject | null = null;
    pending: boolean = false;

    get favoriteOrderOptions(): FavoriteOrderListItemViewObject[] | null {
        const favoriteOrderOptions = this.favoriteOrders!.favoriteOrderList.filter(favoriteOrder => favoriteOrder.allowEdit).map(favoriteOrder => {
            return favoriteOrder;
        });
        return favoriteOrderOptions.length ? favoriteOrderOptions : null;
    }

    created() {
        this.getFavoriteOrders();
    }

    @Watch('selectedMergeType')
    onChangeSelectedImportMergeType(): void {
        this.showFavoriteOrdersList = this.selectedMergeType !== this.favoriteOrderMergeTypes[this.favoriteOrderMergeTypes.New];

        if (!this.showFavoriteOrdersList) {
            this.selectedFavoriteOrder = null;
        }
    }

    onChangeSelectedFavoriteOrder(event) {
        this.selectedFavoriteOrder = this.favoriteOrderOptions!.find(favoriteOrder => favoriteOrder.id === parseInt(event.target.value, 10))!;
    }

    async getFavoriteOrders() {
        Api.favoriteOrder.getFavoriteOrders().then(favoriteOrders => {
            this.favoriteOrders = favoriteOrders;
        });
    }

    async validateForm() {
        if (this.selectedMergeType !== this.favoriteOrderMergeTypes[this.favoriteOrderMergeTypes.New]) {
            await (this.$refs['selectFavoriteOrder'] as Vue).$validator.validate();
        }
        await this.$validator.validate();
        await this.submitForm();
    }

    async submitForm() {
        if (this.vvErrors.items.length) {
            return;
        }

        this.pending = true;

        try {
            await Api.scannerLists.copyToFavoriteOrder({
                sourceScannerListId: urlHelper.getFavoriteOrderId(),
                targetFavoriteOrderId: this.selectedFavoriteOrder ? this.selectedFavoriteOrder.id : 0,
                favoriteOrderName: this.newFavoriteOrderName,
                mergeType: this.favoriteOrderMergeTypes[this.selectedMergeType],
                shared: this.newFavoriteOrderIsShared
            });
        } finally {
            this.pending = false;
            this.$emit('copySuccess');
        }
    }

    // From Vue-validate
    private vvErrors!: any;
}
