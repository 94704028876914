
















import Vue from 'vue';
import Component from 'vue-class-component';
import urlHelper from '@/project/shared/services/urlHelperSimpleSearch.service';
import { Prop } from 'vue-property-decorator';
import { SortByViewObject } from '@/types/serverContract';

@Component
export default class ProductCatalogDetailsSorting extends Vue {
    @Prop({
        type: Array,
        required: true
    }) options!: SortByViewObject[];

    activeSorting: SortByViewObject | null = null;

    created() {
        this.activeSorting = this.getSelectedSorting();
    }

    getSelectedSorting(): SortByViewObject {
        let selectedOption = this.options.find(option => option.isSelected);
        if (!selectedOption) {
            throw new Error('No sorting option selected - server error');
        }
        return selectedOption;
    }

    onChange(selectedSortingOption: SortByViewObject) {
        urlHelper.setSorting(selectedSortingOption.id);
        this.$emit('sortingChange');
    }
}
