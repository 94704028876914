import {
    ActionTree,
    GetterTree,
    MutationTree
} from 'vuex';
import { namespace } from 'vuex-class';
import { VariantDetailsViewObject, ProductDetailsViewObject, BomDetailsViewObject, StoreStockQuantityViewObject } from '@/types/serverContract';

interface IProductState {
    product: ProductDetailsViewObject | BomDetailsViewObject | null;
    productSelectedVariant: VariantDetailsViewObject | null;
    productStoreStocks: StoreStockQuantityViewObject[] | null;
}

// Define MutationMethods as enum to make it easier to work with later in the code.
enum MutationMethod {
    setProduct = 'setProduct',
    setProductSelectedVariant = 'setProductSelectedVariant',
    setProductStoreStocks = 'setProductStoreStocks',
}

// Set default values for data in the store
const state: IProductState = {
    productSelectedVariant: null,
    product: null,
    productStoreStocks: null
};

// Define the "get able" data from outside the store.
const getters: GetterTree<IProductState, any> = {
    productSelectedVariant: state => state.productSelectedVariant,
    product: state => state.product,
    productStoreStocks: state => state.productStoreStocks
};

const actions: ActionTree<IProductState, any> = {
    setProductSelectedVariant({ commit }, selectedVariant: VariantDetailsViewObject | null) {
        commit(MutationMethod.setProductSelectedVariant, selectedVariant);
    },
    setProduct({ commit }, product: ProductDetailsViewObject | BomDetailsViewObject | null) {
        commit(MutationMethod.setProduct, product);
    },
    setProductStoreStocks({ commit }, storeStocks: StoreStockQuantityViewObject[] | null) {
        commit(MutationMethod.setProductStoreStocks, storeStocks);
    }
};

const mutations: MutationTree<IProductState> = {
    [MutationMethod.setProductSelectedVariant](state, selectedVariant) {
        state.productSelectedVariant = selectedVariant;
    },
    [MutationMethod.setProduct](state, product) {
        state.product = product;
    },
    [MutationMethod.setProductStoreStocks](state, storeStocks) {
        state.productStoreStocks = storeStocks;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export const { Getter: ProductGetter, Action: ProductAction } = namespace('product');
