


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import scrollService from '@/core/scroll/scroll.service';
import { SpaPageReadyEventKey } from '@/router';
import bus from '@/core/bus';

@Component
export default class ReturnToTopButton extends Vue {
    @Prop({ default: false, type: Boolean }) isSticky!: string;
    showButton: boolean = false;
    distanceFromTop: number = document.documentElement.scrollTop;
    showButtonAfterDistance: number = 300;

    get classList() {
        return {
            'flex justify-center mx-55 pt-20': true,
            'relative': !this.isSticky,
            'to-top-button--sticky sticky': this.isSticky
        };
    }

    scrollToTop() {
        scrollService.scrollToElement(document.getElementById('app') as HTMLElement);
    }

    mounted() {
        bus.on(SpaPageReadyEventKey, this.isButtonInView);
        this.isButtonInView();

        this.$nextTick(() => {
            if (!this.isSticky) {
                return;
            }

            window.addEventListener('scroll', this.calculateDistanceFromTop);
        });
    }

    destroyed() {
        if (!this.isSticky) {
            return;
        }

        window.removeEventListener('scroll', this.calculateDistanceFromTop);
    }

    isButtonInView() {
        this.showButton = this.getPageHeight() > window.innerHeight;
    }

    getPageHeight(): number {
        const body = document.body;
        const html = document.documentElement;

        return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    }

    calculateDistanceFromTop() {
        this.distanceFromTop = document.documentElement.scrollTop;
    }
}
