import { EasySupplyWarehouseLineViewObject } from '@/types/serverContract';

export interface IEasySupplyPrintLabelsStep {
    choice?: any;
    chooseLabel: string;
    chosenLabel: string;
    hasChoice: boolean;
    isActive: boolean;
    name: string;
}

export interface IEasySupplyPrintLabelsStepsCollection {
    emailAddress: IEasySupplyPrintLabelsStep;
    priceType: IEasySupplyPrintLabelsStep;
    productNumberType: IEasySupplyPrintLabelsStep;
    products: IEasySupplyPrintLabelsStep;
    reportType: IEasySupplyPrintLabelsStep;
}

export const IEasySupplyPrintLabelsStepNames: { emailAddress; priceType: string; productNumberType: string; products: string; reportType: string } = {
    emailAddress: 'emailAddress',
    products: 'products',
    reportType: 'reportType',
    priceType: 'priceType',
    productNumberType: 'productNumberType'
};

export interface IWarehouseLines {
    isChosen: boolean;
    warehouseLineDetails: EasySupplyWarehouseLineViewObject;
}

export interface IEasySupplyPrintLabelsStepProgress {
    currentStepNumber: string;
    totalSteps: string;
}
