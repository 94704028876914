














































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    SearchUsersRequest,
    SearchUsersViewModel,
    HelpDeskUserViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import HelpDeskFavoriteAccounts from '@/project/helpDesk/HelpDeskFavoriteAccounts.vue';
import HelpDeskUsers from '@/project/helpDesk/HelpDeskUsers.vue';
import CallbackPaging from '@/project/shared/CallbackPaging.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: {
        HelpDeskFavoriteAccounts,
        HelpDeskUsers,
        CallbackPaging,
        SpinnerOverlay
    }
})
export default class HelpDeskUserSearch extends Vue {
    pending: boolean = false;
    accountNumber: string = '';
    freeText: string = '';
    userSearchResult: SearchUsersViewModel | null = null;
    currentPage: number = 1;

    async searchUsers(): Promise<void> {
        this.pending = true;

        const payload: SearchUsersRequest = {
            soldToPartnerId: this.accountNumber,
            term: this.freeText,
            page: this.currentPage
        };

        try {
            await Api.helpDesk.searchUsers(payload).then(res => {
                this.userSearchResult = res;
            });
            if (this.userSearchResult !== null) {
                this.currentPage = this.userSearchResult.currentPage;
            }
        } finally {
            this.pending = false;
        }
    }

    submitForm(): void {
        this.currentPage = 1;
        this.searchUsers();
    }

    selectFavoriteAccount(selectedFavoriteAccount: string): void {
        this.freeText = '';
        this.accountNumber = selectedFavoriteAccount;
        this.currentPage = 1;
        this.searchUsers();
    }

    selectUser(selectedUser: HelpDeskUserViewObject): void {
        this.$emit('userSelected', selectedUser, this.accountNumber);
    }

    gotoPage(pageNo: number): void {
        this.currentPage = pageNo;
        this.searchUsers();
    }
}
