import httpService from '@/core/http/http.service';
import {
    CreateDeliveryAddressRequest,
    DeliveryAddressViewModel,
    DeliveryType,
    SetPreferredDeliveryAddressRequest,
    DeliveryAddressForCheckoutViewModel
} from '@/types/serverContract';

export default class DeliveryAddressController {
    public async getDeliveryAddressesForCheckout(deliveryMethod: DeliveryType): Promise<DeliveryAddressForCheckoutViewModel> {
        return httpService.get('deliveryAddressApi/deliveryAddressesForCheckout', { deliveryMethod });
    }

    public async setPreferredStore(payload: SetPreferredDeliveryAddressRequest): Promise<void> {
        return httpService.post('deliveryAddressApi/setAsPreferred', payload);
    }

    public async setDeliveryAddress(id: string): Promise<void> {
        return httpService.post('deliveryAddressApi/deliveryAddress', { id });
    }

    public async addDeliveryAddress(payload: CreateDeliveryAddressRequest): Promise<void> {
        return httpService.put('deliveryAddressApi/deliveryAddress', payload);
    }

    public async getDeliveryAddresses(AddressDeliveryType: DeliveryType): Promise<DeliveryAddressViewModel> {
        return httpService.get('deliveryAddressApi/DeliveryAddresses', { AddressDeliveryType });
    }
}
