



























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { StorePageViewModel } from '@/types/serverContract';
import Constants from '@/project/config/constants';
import imageService from '@/core/image.service';
import { markerSize } from '@/types/mapMarkerSizes';

@Component
export default class StoreDetail extends Vue {
    @Prop({
        required: true,
        type: Object
    }) storeDetailModel!: StorePageViewModel;

    infoWindow = {
        position: { lat: 0, lng: 0 },
        open: false,
        template: ''
    };

    storeIcon(isCollectStore: boolean, icon: string | null) {
        return {
            url: icon ? imageService.getWebpOptimizedSourceStrictSizeStretch(icon, '', markerSize, markerSize) : (isCollectStore ? Constants.CollectStoreMarker : Constants.StoreMarker),
            size: { width: markerSize, height: markerSize, f: 'px', b: 'px' }
        };
    }

    get latitude(): number {
        return this.storeDetailModel.mapLocation.latitude;
    }

    get longitude(): number {
        return this.storeDetailModel.mapLocation.longitude;
    }

    get zoom(): number {
        return this.storeDetailModel.mapLocation.zoom;
    }

    get getCoordinates(): object {
        return {
            lat: this.storeDetailModel.mapLocation.latitude,
            lng: this.storeDetailModel.mapLocation.longitude
        };
    }

    openInfoWindow() {
        this.setInfoWindowTemplate();
        this.infoWindow.position = {
            lat: this.storeDetailModel.mapLocation.latitude,
            lng: this.storeDetailModel.mapLocation.longitude
        };
        this.infoWindow.open = true;
    }

    setInfoWindowTemplate() {
        this.infoWindow.template = `<div class="p-10">
                                        <span class="block font-semibold mb-10 text-18 text-blue-700">${this.storeDetailModel.city}</span>
                                        <p class="mb-5">${this.storeDetailModel.street}</p>
                                        <p><span>${this.storeDetailModel.postalCode}</span> <span>${this.storeDetailModel.city}</span></p>
                                    </div>`;
    }

    resetAndCloseInfoWindow() {
        this.infoWindow = {
            position: { lat: 0, lng: 0 },
            open: false,
            template: ''
        };
    }
}
