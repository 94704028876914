







































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    EasySupplyOrderLineViewObject,
    EasySupplyOrderLinesRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import EasySupplyStocklistReorderLineItem from '@/project/easySupply/EasySupplyStocklistReorderLineItem.vue';
import DeleteOrderLine from '@/project/easySupply/gateway/DeleteOrderLine.vue';

@Component({
    components: {
        EasySupplyStocklistReorderLineItem,
        DeleteOrderLine
    }
})
export default class EasySupplyGatewayOrderList extends Vue {
    @Prop({
        required: true,
        type: Number
    }) warehouseId!: number;

    pending: boolean = false;
    isDeleteOrderLine: boolean = false;
    easySupplyOrderLineList: EasySupplyOrderLineViewObject[] | null = null;
    orderLineDeleted: EasySupplyOrderLineViewObject | null = null;

    created(): void {
        this.getOrderLines();
    }

    async getOrderLines(): Promise<void> {
        if (this.pending) return;

        this.pending = true;
        const payload: EasySupplyOrderLinesRequest = {
            easySupplyWarehouseId: this.warehouseId,
            manualOrder: false
        };

        try {
            const orderLineViewModel = await Api.easySupply.getEasySupplyOrderLines(payload);
            this.easySupplyOrderLineList = orderLineViewModel.easySupplyOrderLineList;
        } finally {
            this.pending = false;
        }
    }

    showDeleteOrderLine(orderLine: EasySupplyOrderLineViewObject): void {
        this.$emit('changeModalSize', 'small');
        this.isDeleteOrderLine = true;
        this.orderLineDeleted = orderLine;
    }

    hideDeleteOrderLine(): void {
        this.$emit('changeModalSize', 'large');
        this.isDeleteOrderLine = false;
        this.orderLineDeleted = null;
    }

    refreshOrderLineList(): void {
        this.getOrderLines();
    }
}
