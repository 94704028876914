





















































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import Cookies from 'js-cookie';
import ForgotPasswordPanel from '@/project/authentication/ForgotPasswordPanel.vue';
import MustChangePasswordPanel from '@/project/authentication/MustChangePasswordPanel.vue';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';

const rememberUserNameCookieKey = 'sani-username';

@Component({
    components: {
        ForgotPasswordPanel,
        SpinnerOverlay,
        MustChangePasswordPanel,
        OffCanvasOverlay
    }
})
export default class Login extends Vue {
    @Prop({ type: Boolean, default: false }) bannerLogin!: boolean;
    @Prop({ type: Boolean, default: false }) useOffcanvasOverlay!: boolean;

    @AppGetter isLoginOverlayOpen!: boolean;

    showSpinner = false;
    rememberUsername = false;
    username = '';
    password = '';
    result = '';
    showMustChangePass = false;
    forgotPasswordActive = false;
    hasError = false;

    get createUserPage() {
        return window.serverContext.sitePages.createUserPage.url;
    }

    created() {
        const username = Cookies.get(rememberUserNameCookieKey);
        if (username) {
            this.username = username;
            this.rememberUsername = true;
        }
    }

    @Watch('rememberUsername')
    onRememberUsernameChange(value: boolean) {
        if (!value) {
            Cookies.remove(rememberUserNameCookieKey);
        }
    }

    async login() {
        try {
            this.showSpinner = true;
            if (this.rememberUsername) {
                Cookies.set(rememberUserNameCookieKey, this.username);
            }
            await Api.authentication
                .login(this.username, this.password, this.$route.fullPath, 'login')
                .then(result => {
                    this.resultMatchMustChangePassword(result);
                });
        } finally {
            this.showSpinner = false;
        }
        // we have a redirect after login so we don't need to hide the spinner otherwise the spinner will be hidden before the redirect.
    }

    resultMatchMustChangePassword(result) {
        if (result === 'MustChangePassword') {
            this.showMustChangePass = true;
        }
    }

    handleInputError() {
        this.hasError = true;
    }

    handleForgotPasswordDone() {
        this.forgotPasswordActive = false;
        this.$emit('done');
    }
}
