




















































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
    AddFavoriteOrderSpecialLineItemRequest,
    UnitViewObject,
    SpecialLineItemUnitsViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class FavoriteOrderAddSpecialLineItem extends Vue {
    @Prop({
        type: Number,
        required: true
    }) favoriteOrderId!: number;

    @Prop({
        type: Boolean,
        required: true
    }) isActive!: boolean;

    unitOptions: SpecialLineItemUnitsViewModel | null = null;
    selectedUnit: UnitViewObject | null = null;
    unitsPending: boolean = false;
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    displayName: string = '';
    productItemNumber: string = '';
    manufacturer: string = '';
    productText: string = '';
    supplementaryProductText: string = '';
    quantity: number = 1;
    timeout: number | null = null;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    @Watch('isActive')
    async getUnitOptions() {
        if (this.unitOptions !== null) {
            return;
        }

        this.unitsPending = true;
        try {
            this.unitOptions = await Api.unit.specialLineItemUnits();
            this.selectedUnit = this.unitOptions.units[0];
        } finally {
            this.unitsPending = false;
        }
    }

    async addSpecialLineItem() {
        const payload: AddFavoriteOrderSpecialLineItemRequest = {
            favoriteOrderId: this.favoriteOrderId,
            displayName: this.displayName,
            productItemNumber: this.productItemNumber,
            manufacturer: this.manufacturer,
            productText: this.productText,
            supplementaryProductText: this.supplementaryProductText,
            certificateCode: '',
            fixedUnitValue: 0,
            quantity: this.quantity,
            unitCode: this.selectedUnit!.unitCode
        };

        this.pending = true;

        try {
            await Api.favoriteOrder.addSpecialLineItem(payload);
            this.showSuccessIndicator();
            this.$emit('updateFavoriteOrder');
        } finally {
            this.pending = false;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
            this.$emit('done');
            this.resetForm();
        }, this.successTimer);
    }

    resetForm(): void {
        this.displayName = '';
        this.productItemNumber = '';
        this.manufacturer = '';
        this.productText = '';
        this.manufacturer = '';
        this.supplementaryProductText = '';
    }

    cancel() {
        this.resetForm();
        this.$emit('done');
    }
}
