




























import { Component } from 'vue-property-decorator';
import BaseBanner from '@/components/molecules/Banner/BaseBanner.vue';
import Login from '@/components/molecules/Login/Login.vue';
import Heading from '@/components/atoms/Heading/Heading.vue';
import { AppGetter } from '@/store/app';

@Component({
    components: {
        Login,
        Heading,
        BaseBanner
    }
})

export default class LoginBanner extends BaseBanner {
    @AppGetter isLoggedIn!: boolean;
}
