import Vue, { VNodeDirective } from 'vue';
import serverContext from '@/core/serverContext.service';

Vue.directive('on-error', {
    bind: function(el: HTMLElement, binding: VNodeDirective) {
        if (serverContext.initialPageData.isPrerenderRequest) return;

        const noImageSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

        const classNamesArrayTemp = binding.value && binding.value[0] ? binding.value : [];

        const classNamesArray = ['c-on-error', ...classNamesArrayTemp];

        function errorHandler(): void {
            el.setAttribute('src', noImageSrc); // Set attribute to no image src const
            el.removeAttribute('srcset'); // Remove srcset attribute

            classNamesArray.forEach((className: string) => {
                el.classList.add(className); // Add class when error on img
            });
        }

        el.addEventListener('error', errorHandler);
    }
});
