import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { namespace } from 'vuex-class';
import {
    UserViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { store } from '@/store';

interface IUserState {
    isAdmin: boolean | undefined;
    user: UserViewModel;
}

enum MutationMethod {
    setAdmin = 'setAdmin',
    setUser = 'setUser'
}

const userState: IUserState = {
    isAdmin: undefined,
    user: null as unknown as UserViewModel
};

async function calculateAdminStatus() {
    let appStore = store.state.app;
    let userStore = userState.user; // Use the userState directly since it's in scope
    if (userStore && appStore) {
        if (userStore.accountRelations && userStore.accountRelations.length > 0) {
            let activeAccount = userStore.accountRelations.find(ar => ar.soldToPartnerId === appStore.loginInformation.currentAccount.soldToPartnerId);
            if (activeAccount) {
                return activeAccount.accountAccess === 'Administrator';
            }
        }
    }
    return undefined;
}

async function getUser() {
    const user = await store.dispatch('user/getUser');
    return user;
}

async function getAdminStatus() {
    const isAdmin = await store.dispatch('user/getAdminStatus');
    return isAdmin;
}

const getters: GetterTree<IUserState, any> = {
    user: state => {
        if (!state.user) {
            getUser();
        }
        return state.user;
    },
    isAdmin: state => {
        if (state.isAdmin === undefined) {
            getAdminStatus();
        }
        return state.isAdmin;
    }
};

const actions: ActionTree<IUserState, any> = {
    async getUser({ commit, state }) {
        if (state.user) {
            return state.user;
        }
        const initialUserInformation = await Api.user.getCurrentUserForUpdate();
        commit(MutationMethod.setUser, initialUserInformation);
        return state.user;
    },
    async getAdminStatus({ commit, state }) {
        if (state.isAdmin === undefined) {
            const isAdmin = await calculateAdminStatus(); // Fetch admin status
            commit(MutationMethod.setAdmin, isAdmin); // Commit mutation to set isAdmin
        }
        return state.isAdmin;
    }
};

const mutations: MutationTree<IUserState> = {
    [MutationMethod.setUser](state, user: UserViewModel) {
        state.user = Object.freeze(user);
    },
    [MutationMethod.setAdmin](state, isAdmin: boolean) {
        state.isAdmin = isAdmin;
    }
};

export default {
    namespaced: true,
    state: userState,
    getters,
    actions,
    mutations
};

export const { Getter: UserGetter, Action: UserAction } = namespace('user');
