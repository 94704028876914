


















































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import {
    LinkItem,
    NavigationItem
} from '@/types/serverContract';
import overlayOrchestrator from '@/core/offcanvas-overlay/overlayOrchestrator';
import keyboardService from '@/core/keyCodes';
import Accordion from '@/project/shared/Accordion.vue';
import viewportService from '@/core/responsive/viewport/viewportEvents.service';
import { Route } from 'vue-router';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component({
    components: {
        Accordion
    }
})
export default class MobileLeftNavigation extends Vue {
    @Prop({
        type: Object,
        required: true
    }) navigation!: NavigationItem;

    @AppGetter public breadcrumb!: LinkItem[];

    expanded: boolean = false;
    timeout: number | null = null;

    get iconTransitionEnter() {
        const direction = this.expanded ? 'right' : 'left';
        return `u-rotate-in-${direction} u-anim-dur-150`;
    }

    get iconTransitionLeave() {
        const direction = this.expanded ? 'right' : 'left';
        return `u-rotate-out-${direction} u-anim-dur-150`;
    }

    get buttonLabel(): string {
        return isNotNullEmptyOrUndefined(this.breadcrumb) && this.breadcrumb.length >= 1 ? (this.breadcrumb.slice(-1)[0] as unknown as LinkItem).name : this.navigation.link.name;
    }

    public $refs!: {
        leftNavigation: Vue;
    };

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            this.hide();
        }
    }

    toggleExpanded(): void {
        if (this.expanded) {
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        overlayOrchestrator.beginActivation(this).then(() => {
            // Await that a another user of same portal is out
            this.expanded = true;
            this.timeout = setTimeout(() => {
                const leftNavigationHTMLElement = this.$refs.leftNavigation.$el as HTMLElement;
                overlayOrchestrator.scrollTargetReady(leftNavigationHTMLElement);
                leftNavigationHTMLElement.style.maxHeight = viewportService.viewport.height - leftNavigationHTMLElement.getBoundingClientRect().top + 'px';
            }, 100);
            document.addEventListener('keyup', this.keyUp);
        });
    }

    hide(): Promise<void> {
        overlayOrchestrator.deactivate();
        this.expanded = false;
        return Promise.resolve();
    }

    private destroyed() {
        document.removeEventListener('keyup', this.keyUp);
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    keyUp(event) {
        if (!keyboardService.isEscape(event)) {
            return;
        }
        this.hide();
    }
}
