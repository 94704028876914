





















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import ProductCo2Info from '@/project/products/ProductCo2Info.vue';
import CopyTooltip from '@/project/tooltip/copyTooltip.vue';
import {
    LineItemViewObject
} from '@/types/serverContract';

@Component({
    components: {
        ProductCo2Info,
        CopyTooltip
    }
})
export default class LineItemDescription extends Vue {
    @Prop({
        type: Object
    }) lineItem!: LineItemViewObject;

    @Prop({
        type: Boolean
    }) hasVariantProperties!: boolean;

    @Prop({
        type: Boolean
    }) hasPrimaryProperties!: boolean;

    get lineItemUrl(): string {
        return isNotNullEmptyOrUndefined(this.lineItem.url) ? this.lineItem.url : '';
    }
}
