import scrollService from '@/core/scroll/scroll.service';
import { headerHeight } from '@/core/responsive/breakpoints/breakpoints.service';

const scrollToFirstInputError = (wrapperElem: Element, selectElm: HTMLElement) => {
    const firstErrorElement = wrapperElem.querySelector('[aria-invalid=true]')! as HTMLElement;
    firstErrorElement.focus();

    if (firstErrorElement.tagName.toLowerCase() === 'select') {
        scrollService.scrollToElement(selectElm, -(headerHeight(true) * 2), 0);
    }
};

export default scrollToFirstInputError;
