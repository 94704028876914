































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ProjectFolderLinkListViewModel } from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: { SpinnerOverlay }
})
export default class ProjectAgreements extends Vue {
    projectAgreementLinks: ProjectFolderLinkListViewModel | null = null;
    pending: boolean = false;

    created(): void {
        this.getProjectFolderLinks();
    }

    async getProjectFolderLinks(): Promise<void> {
        this.pending = true;
        try {
            await Api.projectAgreement.projectAgreementLinks().then(links => {
                this.projectAgreementLinks = links;
            });
        } finally {
            this.pending = false;
        }
    }
}
