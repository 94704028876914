import { getInteractiveChildElements } from 'focus-elements/src/focus';

const findFirstInteractiveElement = (elementList): HTMLElement => {
    return elementList.find(el => {
        return (
            el.checkVisibility({
                opacityProperty: true,
                visibilityProperty: true
            }) && !el.hasAttribute('disabled')
        );
    });
};

export const focusTrap = (wrapper: HTMLElement) => {
    const focusElements = getInteractiveChildElements(wrapper);

    wrapper.addEventListener('keydown', e => {
        if (e.key !== 'Tab') {
            return;
        }

        const firstFocusableElement = findFirstInteractiveElement(focusElements);
        const lastFocusableElement = findFirstInteractiveElement([...focusElements].reverse());

        // Shift + Tab
        if (e.shiftKey) {
            if (document.activeElement !== firstFocusableElement) {
                return;
            }

            lastFocusableElement.focus();
            e.preventDefault();
            return;
        }

        // Tab only
        if (document.activeElement !== lastFocusableElement) {
            return;
        }

        firstFocusableElement.focus();
        e.preventDefault();
    });

    findFirstInteractiveElement(focusElements).focus();
};
