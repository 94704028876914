import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { namespace } from 'vuex-class';
import flexBoxService from '@/project/flexBox/flexBox.service';
import serverContext from '@/core/serverContext.service';

interface FlexBoxInfo {
    defaultText: string;
    inFlexBoxText: string;
}

interface IFlexBoxState {
    changeToAccount: string;
    flexBoxButtonText: string;
    flexBoxInfoText: string;
    flexBoxPage: string;
    isInFlexBoxContext: boolean;
    showFlexBoxModal: boolean;
}

const storeState: IFlexBoxState = {
    isInFlexBoxContext: flexBoxService.isInFlexBoxContext,
    flexBoxPage: serverContext.sitePages.flexBoxPage ? serverContext.sitePages.flexBoxPage.url : '',
    flexBoxInfoText: '',
    flexBoxButtonText: flexBoxService.flexBoxButtonText,
    showFlexBoxModal: false,
    changeToAccount: ''
};

const getters: GetterTree<IFlexBoxState, any> = {
    isInFlexBoxContext: state => state.isInFlexBoxContext,
    flexBoxPage: state => state.flexBoxPage,
    flexBoxInfoText: state => state.flexBoxInfoText,
    flexBoxButtonText: state => state.flexBoxButtonText,
    showFlexBoxModal: state => state.showFlexBoxModal,
    changeToAccount: state => state.changeToAccount
};

enum MutationMethod {
    setChangeToAccount = 'setChangeToAccount',
    setFlexBoxInfoText = 'setFlexBoxInfoText',
    setShowFlexBoxModal = 'setShowFlexBoxModal'
}

const actions: ActionTree<IFlexBoxState, any> = {
    setFlexBoxInfoText({ commit }, payload: FlexBoxInfo) {
        commit(MutationMethod.setFlexBoxInfoText, payload);
    },
    setShowFlexBoxModal({ commit }, show: boolean) {
        commit(MutationMethod.setShowFlexBoxModal, show);
    },
    setChangeToAccount({ commit }, changeToAccount: string) {
        commit(MutationMethod.setChangeToAccount, changeToAccount);
    }
};

const mutations: MutationTree<IFlexBoxState> = {
    [MutationMethod.setFlexBoxInfoText](state, payload: FlexBoxInfo) {
        state.flexBoxInfoText = flexBoxService.getFlexBoxInfoText(payload.inFlexBoxText, payload.defaultText);
    },
    [MutationMethod.setShowFlexBoxModal](state, show) {
        state.showFlexBoxModal = show;
    },
    [MutationMethod.setChangeToAccount](state, changeToAccount) {
        state.changeToAccount = changeToAccount;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters,
    actions,
    mutations
};

export const { Getter: FlexBoxGetter, Action: FlexBoxAction } = namespace('flexBox');
