





































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FavoriteOrderListItemViewObject } from '@/types/serverContract';
import api from '../http/api';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import keyboardService from '@/core/keyCodes';
import { IconText } from './FavoriteOrder.vue';

@Component({
    components: {
        ModalOverlay
    }
})
export default class FavoriteOrderListItem extends Vue {
    @Prop({ required: true, type: Object }) favoriteOrder!: FavoriteOrderListItemViewObject
    @Prop({ required: true, type: Boolean, default: false }) isUserAdmin!: boolean

    showConfirmationModal: boolean = false;

    get tryGetEditIcon(): IconText {
        if (this.favoriteOrder.readonly && this.favoriteOrder.shared) {
            return new IconText('sanicon-ico-eye', this.$tr(this.$labels.Views.FavoriteOrder.Shared.ReadOnly));
        }
        if (this.favoriteOrder.allowEdit && this.favoriteOrder.shared) {
            return new IconText('sanicon-ico-edit', this.$tr(this.$labels.Views.FavoriteOrder.Shared.AllowEdit));
        }
        return new IconText('sanicon-ico-eye invisible', '');
    }

    get isSharedText(): string {
        return this.favoriteOrder.shared ? this.$tr(this.$labels.Views.FavoriteOrder.IsShared) : this.$tr(this.$labels.Views.FavoriteOrder.IsNotShared);
    }

    openConfirmationModal() {
        if (!this.favoriteOrder.allowDelete) {
            return;
        }

        this.showConfirmationModal = true;
        document.addEventListener('keyup', this.keyHandler);
    }

    closeConfirmationModal() {
        this.showConfirmationModal = false;
        document.removeEventListener('keyup', this.keyHandler);
    }

    keyHandler(event) {
        if (keyboardService.isEnter(event)) {
            this.deleteFavoriteOrder();
        }
    }

    async deleteFavoriteOrder() {
        this.closeConfirmationModal();
        if (this.favoriteOrder.allowDelete) {
            const newFavoriteOrders = await api.favoriteOrder.deleteFavoriteOrder(this.favoriteOrder.id);
            this.$emit('favoriteOrderDeleted', newFavoriteOrders);
        }
    }
}
