










































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { BasketViewModel, LoginInformation, SalesAccountViewObject } from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import { BasketGetter } from '@/store/basket';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import BasketSummarySubTotal from '@/project/basket/BasketSummarySubTotal.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import BasketSummaryTotal from '@/project/basket/BasketSummaryTotal.vue';
import { FlexBoxGetter } from '@/store/flexBox';
import EcoCertificationTotal from '@/project/basket/EcoCertificationTotal.vue';

@Component({
    components: {
        BasketSummaryTotal,
        BasketSummarySubTotal,
        ModalOverlay,
        EcoCertificationTotal
    }
})
export default class BasketSummary extends Vue {
    @AppGetter loginInformation!: LoginInformation;

    @BasketGetter basket!: BasketViewModel;

    @FlexBoxGetter isInFlexBoxContext!: boolean;

    showConfirmEmptyBasket: boolean = false;
    pending: boolean = false;
    showMoreSteelAndMetals: boolean = false;
    showMoreOtherProducts: boolean = false;

    get currentActiveAccount(): SalesAccountViewObject | null {
        return this.loginInformation ? this.loginInformation.currentAccount : null;
    }

    get hasCertificatesOrCharges(): boolean {
        return isNotNullEmptyOrUndefined(this.basket.certificatesTotal) || isNotNullEmptyOrUndefined(this.basket.totalCharge);
    }

    toggleShowMoreSteelAndMetals(): void {
        this.showMoreSteelAndMetals = !this.showMoreSteelAndMetals;
    }

    toggleShowMoreOtherProducts(): void {
        this.showMoreOtherProducts = !this.showMoreOtherProducts;
    }
}
