


















































import { Vue, Component, Prop } from 'vue-property-decorator';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';
import {
    BUTTON_VARIANTS,
    BUTTON_SIZE,
    BUTTON_ICON_SIZE,
    BUTTON_ICON_TYPE
} from '@/core/enums/enums';

@Component({
    components: {
        SpinnerElement
    }
})
export default class ButtonCtrl extends Vue {
    @Prop({ type: String, default: BUTTON_VARIANTS.PRIMARY }) variant!: string;
    @Prop({ type: Boolean, default: false }) fullWidth!: boolean;
    @Prop({ type: String, default: BUTTON_ICON_TYPE.NONE }) iconType!: string;
    @Prop({ type: Boolean, default: false }) loading!: boolean;
    @Prop({ type: String, default: '' }) icon!: string;
    @Prop({ type: String, default: 'button' }) elementType!: string;
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: String, default: BUTTON_SIZE.NORMAL }) buttonSize!: string;
    @Prop({ type: String, default: BUTTON_ICON_SIZE.NORMAL }) iconSize!: string;
    @Prop({ type: Boolean, default: false }) disabled!: boolean;
    @Prop({ type: Boolean, default: false }) isDownload!: boolean;

    get variants() {
        return {
            'c-btn': this.variant !== BUTTON_VARIANTS.CUSTOM,
            'c-btn--primary': this.variant === BUTTON_VARIANTS.PRIMARY,
            'c-btn--secondary': this.variant === BUTTON_VARIANTS.SECONDARY,
            'c-btn--tertiary': this.variant === BUTTON_VARIANTS.TERTIARY,
            'c-btn--quaternary': this.variant === BUTTON_VARIANTS.QUATERNARY,
            'c-btn--black': this.variant === BUTTON_VARIANTS.BLACK,
            'c-btn--link': this.variant === BUTTON_VARIANTS.LINK,
            'c-btn--orange': this.variant === BUTTON_VARIANTS.ORANGE,
            'c-btn--icon-only': this.iconType === BUTTON_ICON_TYPE.ICON_ONLY
        };
    }

    get attributes() {
        return {
            'gap-4 min-w-238 md:min-w-305 self-center rounded-full flex items-center justify-center':
                this.variant !== BUTTON_VARIANTS.LINK && this.variant !== BUTTON_VARIANTS.CUSTOM, // These should be worked into the c-btn class
            'w-full': this.fullWidth,
            'h-48 w-48 p-12': this.iconType === BUTTON_ICON_TYPE.ICON_ONLY,
            'min-h-48': this.buttonSize === BUTTON_SIZE.NORMAL,
            'min-h-28 px-8': this.buttonSize === BUTTON_SIZE.SMALL
        };
    }

    get fontStyles() {
        return {
            'flex items-center justify-center': this.variant !== BUTTON_VARIANTS.CUSTOM,
            'font-w500 text-16':
                this.variant !== BUTTON_VARIANTS.LINK && this.buttonSize === BUTTON_SIZE.NORMAL, // Links should inherit the styles of the inveronment they are in, not have preset values
            'text-14': this.buttonSize === BUTTON_SIZE.SMALL,
            'text-marine font-w500 transition-colors hover:text-ahlsell-blue':
                this.iconType === BUTTON_ICON_TYPE.ICON_WITH_TEXT
        };
    }

    get iconClasses() {
        return {
            'ml-4':
                this.iconType !== BUTTON_ICON_TYPE.ICON_ONLY &&
                this.iconType !== BUTTON_ICON_TYPE.ICON_WITH_TEXT,
            'text-16': this.iconSize === BUTTON_ICON_SIZE.SMALL,
            'text-24': this.iconSize === BUTTON_ICON_SIZE.NORMAL,
            'c-btn c-btn--secondary c-btn--icon-only rounded-full flex items-center justify-center h-40 w-40 mr-8':
                this.iconType === BUTTON_ICON_TYPE.ICON_WITH_TEXT
        };
    }
}
