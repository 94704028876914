




























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import hexToRgba from 'hex-to-rgba';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component
export default class ImageTextButtonModuleInnerContent extends Vue {
    @Prop({
        type: String
    }) preheader!: string;

    @Prop({
        type: String
    }) header!: string;

    @Prop({
        type: String
    }) subheader!: string;

    @Prop({
        default: '',
        type: String
    }) textColor!: string;

    @Prop({
        default: '',
        type: String
    }) textBackgroundColor!: string;

    @Prop({
        type: String,
        default: 'Left',
        validator: (value: string) => ['Left', 'Right'].indexOf(value) > -1
    }) textAlignment!: string;

    @Prop({
        type: String
    }) buttonLink!: string;

    @Prop({
        type: String
    }) buttonLinkTarget!: string;

    @Prop({
        default: '',
        type: String
    }) buttonText!: string;

    @Prop({
        default: '',
        type: String
    }) buttonTextColor!: string;

    @Prop({
        default: '',
        type: String
    }) buttonColor!: string;

    @Prop({
        type: String,
        default: 'Left',
        validator: (value: string) => ['Left', 'Right'].indexOf(value) > -1
    }) buttonAlignment!: string;

    @Prop({
        default: '',
        type: String
    }) image!: string;

    get opaqueTextBackgroundColor(): string {
        return hexToRgba(this.textBackgroundColor, 0.85);
    }

    get useBackgroundColorForText(): boolean {
        return isNotNullEmptyOrUndefined(this.textBackgroundColor) && isNotNullEmptyOrUndefined(this.image);
    }

    get buttonBackgroundColor(): string {
        return '#' + this.buttonColor;
    }

    get styleForWrapper(): Partial<CSSStyleDeclaration> | null {
        if (this.textColor) {
            return {
                color: `#${this.textColor}`
            };
        }
        return null;
    }

    get styleForHeaders(): Partial<CSSStyleDeclaration> | null {
        if (this.opaqueTextBackgroundColor) {
            return {
                backgroundColor: this.opaqueTextBackgroundColor,
                boxShadow: `-1rem 0 0 ${this.opaqueTextBackgroundColor}`
            };
        }
        return null;
    }

    get styleForButton(): Partial<CSSStyleDeclaration> | null {
        if (this.buttonColor) {
            return {
                backgroundColor: this.buttonBackgroundColor,
                color: this.buttonTextColor
            };
        }
        return null;
    }

    trackPromotionClick(): void {
        this.$emit('trackPromotionClick');
    }
}
