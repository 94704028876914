














































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { LinkItem, ProductNewsTeaserViewModel } from '@/types/serverContract';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoints.mixin';

@Component({
    mixins: [BreakpointsMixin]
})
export default class ProductNewsTeaser extends Vue {
    productNewsTeaserResponse: ProductNewsTeaserViewModel | null = null;

    async created() {
        this.getTeasers();
    }

    get articles(): LinkItem[] {
        return this.productNewsTeaserResponse ? this.productNewsTeaserResponse.articles.slice(4) : [];
    }

    async getTeasers() {
        this.productNewsTeaserResponse = await Api.productNewsTeaser.getProductNewsTeaser();
    }
}
