



























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import FlexBoxDeliveryAddress from '@/project/flexBox/FlexBoxDeliveryAddress.vue';
import Api from '@/project/http/api';
import {
    DeliveryAddressViewObject
} from '@/types/serverContract';
import { FlexBoxGetter, FlexBoxAction } from '@/store/flexBox';
import keyboardService from '@/core/keyCodes';
import FlexBoxSelectedDeliveryAddress from '@/project/flexBox/FlexBoxSelectedDeliveryAddress.vue';
import FlexBoxForm from '@/project/flexBox/FlexBoxForm.vue';
import { trackAndStopFlexBoxMode } from '@/project/flexBox/flexBoxModeStop';

@Component({
    components: {
        FlexBoxForm,
        FlexBoxSelectedDeliveryAddress,
        FlexBoxDeliveryAddress
    }
})
export default class FlexBoxModal extends Vue {
    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @FlexBoxGetter changeToAccount!: string;
    @FlexBoxAction setChangeToAccount!: (changeToAccount: string) => void;

    pending: boolean = false;
    mobileNumber: string = '';
    sapAddressId: string = '';
    showCreateDeliveryAddress: boolean = true;
    showChangeDeliveryAddress: boolean = false;
    selectedDeliveryAddress: DeliveryAddressViewObject | null = null;
    timeout: number | null = null;

    mounted() {
        document.addEventListener('keyup', this.keyHandler);
    }

    destroyed() {
        document.removeEventListener('keyup', this.keyHandler);
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async stopFlexBoxMode() {
        if (this.pending) return;
        this.pending = true;

        try {
            await trackAndStopFlexBoxMode();
        } finally {
            this.pending = false;
        }
    }

    async changeAccount() {
        if (this.pending || !this.changeToAccount) return;
        this.pending = true;

        try {
            // This will refresh the page
            await Api.authentication.changeAccount(this.changeToAccount).then(() => {
                this.setChangeToAccount('');
            });
        } finally {
            this.pending = false;
        }
    }

    onSelectDeliveryAddress(address: DeliveryAddressViewObject): void {
        this.setShowChangeDeliveryAddress(false);
        this.setShowCreateDeliveryAddress(false);
        this.selectedDeliveryAddress = address;
        this.sapAddressId = address.id;
    }

    onCreatedNewAddress(newAddress: DeliveryAddressViewObject) {
        this.selectedDeliveryAddress = newAddress;
        this.sapAddressId = newAddress.id;
    }

    onCancel(): void {
        if (this.selectedDeliveryAddress === null) {
            this.closeModal();
        } else {
            this.setShowCreateDeliveryAddress(false);
        }
    }

    setShowCreateDeliveryAddress(show: boolean): void {
        this.showCreateDeliveryAddress = show;
    }

    setShowChangeDeliveryAddress(show: boolean): void {
        this.showChangeDeliveryAddress = show;
    }

    keyHandler(event): void {
        if (keyboardService.isEnter(event) && this.isInFlexBoxContext) {
            this.stopFlexBoxMode();
        }
    }

    closeModal(): void {
        this.$emit('closeModal');
    }

    resetChangeToAccount(): void {
        this.timeout = setTimeout(() => this.setChangeToAccount(''), 100);
        this.closeModal();
    }
}
