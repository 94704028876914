














































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import { ICurtainOverlayBase } from './overlays/ICurtainOverlayBase.vue';
import { focusTrap } from '@/core/accessibility/focusTrap';

@Component({
    components: {
        OffCanvasOverlay
    }
})
export default class CurtainOverlay extends Vue {
    @Prop({ type: Boolean, default: false }) show!: boolean;

    activeCurtainOverlay: ICurtainOverlayBase | null = null;
    title: string = '';
    submitButtonText: string = 'OK';
    submitButtonEnabled: boolean = true;
    payload: any;
    errorMessage: string = '';

    get isSubCurtain(): boolean {
        if (!this.activeCurtainOverlay) {
            return false;
        }
        return this.activeCurtainOverlay.prevCurtainOverlay === undefined;
    }

    toggleOverlayVisibility(open: boolean) {
        if (this.activeCurtainOverlay && !open) {
            this.activeCurtainOverlay.Cancel();
        }
        this.$emit('update:show', open);
    }

    exitOrReturn() {
        if (this.isSubCurtain) {
            this.toggleOverlayVisibility(false);
            return;
        }
        this.returnToPreviousOverlay();
    }

    returnToPreviousOverlay() {
        if (this.activeCurtainOverlay) {
            this.activeCurtainOverlay.Cancel();
            this.overlayChanged(this.activeCurtainOverlay.prevCurtainOverlay);
        }
    }

    overlayChanged(newOverlay: ICurtainOverlayBase) {
        const prevOverlay = this.activeCurtainOverlay;
        if (prevOverlay) {
            prevOverlay.isVisible = false;
            prevOverlay.Inactive();
        }
        this.activeCurtainOverlay = newOverlay;
        this.activeCurtainOverlay.isVisible = true;

        this.activeCurtainOverlay.Active();
        this.title = this.activeCurtainOverlay.titleText;
        this.submitButtonText = this.activeCurtainOverlay.submitButtonText;
        this.activeCurtainOverlay.UpdateSubmitButton();

        focusTrap(this.$refs.overlay as HTMLElement);
    }

    setErrorMessage(message: string) {
        if (!this.activeCurtainOverlay) {
            return;
        }
        this.errorMessage = message;
    }

    async onSubmitted() {
        if (!this.submitButtonEnabled) {
            return;
        }
        if (!this.activeCurtainOverlay) {
            return;
        }
        try {
            await this.activeCurtainOverlay.Submit();
        } catch (error) {
            this.setErrorMessage(error.data.message);
        }
        this.exitOrReturn();
    }
}
