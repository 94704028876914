import httpService from '@/core/http/http.service';
import {
    ProductSearchRequest,
    ProductSearchResponse, SearchFeedbackRequest,
    SearchAsYouTypeRequest,
    SearchAsYouTypeResponse,
    SearchSuggestionsRequest,
    MarketingBannerViewObject
} from '@/types/serverContract';
import Cookies from 'js-cookie';

interface SearchCookie {
    data?: any | null;
    endpoint: string;
}

export default class SearchController {
    private searchUrl(): SearchCookie {
        const searchCookie: string | undefined = Cookies.get('ES_search');

        if (!searchCookie) {
            return {
                endpoint: 'relewiseSearchApi'
            };
        }

        const searchCookieOptions: string[] = searchCookie.split('_');
        const searchCookieData = {};
        for (let i = 0; i < searchCookieOptions.length; i++) {
            if (i !== 0) {
                const option = searchCookieOptions[i].split('.');
                searchCookieData[option[0]] = option[1];
            }
        }

        return {
            endpoint: 'searchApi',
            data: searchCookieData
        };
    }

    public async searchSuggest(payload: SearchAsYouTypeRequest): Promise<SearchAsYouTypeResponse> {
        const { endpoint, data } = this.searchUrl();
        return httpService.get<SearchAsYouTypeResponse>(`${endpoint}/asYouType`, {
            ...payload,
            ...(data && { ...data })
        });
    }

    public async search(payload: ProductSearchRequest): Promise<ProductSearchResponse> {
        const { endpoint, data } = this.searchUrl();
        Object.assign(payload, payload.selectedFacets);
        return httpService.get<ProductSearchResponse>(`${endpoint}/search`, {
            ...payload,
            ...(data && { ...data })
        });
    }

    public async feedback(payload: SearchFeedbackRequest): Promise<void> {
        const { endpoint, data } = this.searchUrl();
        return httpService.post<void>(`${endpoint}/feedback`, {
            ...payload,
            ...(data && { ...data })
        });
    }

    public async fitUmbracoMarketingBanner(request: SearchSuggestionsRequest): Promise<MarketingBannerViewObject> {
        return httpService.get<MarketingBannerViewObject>('searchApi/fitUmbracoMarketingBanner', request);
    }
}
