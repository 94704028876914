

































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import {
    BaseReportRequest,
    DownloadProductDetailsReportRequest
} from '@/types/serverContract';
import DownloadCatalogForm from '@/project/shared/DownloadCatalogForm.vue';
import { downloadApi } from '@/project/config/utilities';
import { AppGetter } from '@/store/app';

@Component({
    components: {
        DownloadCatalogForm,
        ModalOverlay
    }
})
export default class ProductDetailsPrintAsLabel extends Vue {
    @Prop({
        default: '',
        type: String
    }) productId!: string;

    showPrintAsLabelModal: boolean = false;
    @AppGetter isLoggedIn!: boolean;

    async downloadCatalog(baseReportRequest: BaseReportRequest) {
        const downloadProductCatalogRequest: DownloadProductDetailsReportRequest = {
            productId: this.productId,
            reportType: baseReportRequest.reportType,
            priceType: baseReportRequest.priceType,
            productNumberType: baseReportRequest.productNumberType
        };

        try {
            downloadApi('api/ReportsApi/DownloadProductDetailsReport', downloadProductCatalogRequest);
            this.closePrintAsLabelModal();
        } finally {
        }
    }

    openPrintAsLabelModal() {
        this.showPrintAsLabelModal = true;
    }

    closePrintAsLabelModal() {
        this.showPrintAsLabelModal = false;
    }
}
