
















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import {
    EasySupplyWarehouseLineProcuredProductViewObject,
    EasySupplyWarehouseLinesForProcuredProductRequest
} from '@/types/serverContract';
import AddProcuredProductsToWarehouseItem from '@/project/easySupply/procuredProducts/AddProcuredProductsToWarehouseItem.vue';

@Component({
    components: {
        AddProcuredProductsToWarehouseItem
    }
})
export default class AddProcuredProductsToWarehouse extends Vue {
    @Prop({
        type: Number,
        required: true
    }) procuredProductId!: number;

    getListPending: boolean = false;
    easySupplyWarehouseList: EasySupplyWarehouseLineProcuredProductViewObject[] | null = null;

    created() {
        this.getEasySupplyWarehouseList();
    }

    async getEasySupplyWarehouseList() {
        this.getListPending = true;

        try {
            const payload: EasySupplyWarehouseLinesForProcuredProductRequest = {
                procuredProductId: this.procuredProductId,
                returnAllWarehouses: true
            };
            const easySupplyProcuredProductWarehouseLinesViewModel = await Api.easySupply.easySupplyWarehouseLinesForProcuredProduct(payload);
            if (easySupplyProcuredProductWarehouseLinesViewModel) {
                this.easySupplyWarehouseList = easySupplyProcuredProductWarehouseLinesViewModel.warehouseLines;
            }
        } finally {
            this.getListPending = false;
        }
    }
}
