import { router } from '@/router';

const favoriteOrderIdKey = 'id';
const termKey = 'term';
const pageKey = 'page';
const sortByKey = 'sortBy';

function getFavoriteOrderId(): number {
    return parseInt(router.currentRoute.query[favoriteOrderIdKey] as string, 10);
}

function getPageNumber(): number {
    const value = parseInt(router.currentRoute.query[pageKey] as string, 10);
    return !value || Number.isNaN(value) ? 1 : value;
}

function setPageNumber(pageNumber: number): void {
    const query = { ...router.currentRoute.query, [pageKey]: pageNumber.toString() };
    router.push({ query });
}

function hasSearchTerm(): boolean {
    return !!router.currentRoute.query[termKey];
}

function getSearchTerm(): string {
    return router.currentRoute.query[termKey] as string || '';
}

function setSearchTerm(term: string): void {
    const query = { ...router.currentRoute.query };

    query[termKey] = term;

    // Reset page
    delete query[pageKey];

    router.replace({ query });
}

function getSorting(): string {
    return router.currentRoute.query[sortByKey] as string || '';
}

function setSorting(sortBy: string): void {
    const query = { ...router.currentRoute.query };

    query[sortByKey] = sortBy;

    // Reset page
    delete query[pageKey];

    router.replace({ query });
}

export default {
    getFavoriteOrderId,
    getPageNumber: getPageNumber,
    setPageNumber,
    hasSearchTerm,
    getSearchTerm,
    setSearchTerm,
    getSorting,
    setSorting
};
