



















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import MyServicesQuotationsForm from '@/project/myServices/MyServicesQuotationsForm.vue';
import MyServicesQuotationsHeader from '@/project/myServices/MyServicesQuotationsHeader.vue';
import { QuotationListViewModel } from '@/types/serverContract';

@Component({
    components: {
        MyServicesQuotationsForm,
        MyServicesQuotationsHeader
    }
})
export default class MyServicesQuotations extends Vue {
    quotationsResult: QuotationListViewModel | null = null;

    getQuotationsHeaders(quotationsResult: QuotationListViewModel): void {
        if (quotationsResult) {
            this.quotationsResult = quotationsResult;
        }
    }
}
