










































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VariantWithDocumentsViewObject } from '@/types/serverContract';
import ButtonCheckBox from '@/project/form/ButtonCheckBox.vue';
import ProjectFolderOrderHistoryMaintenanceDocument
    from '@/project/myServices/ProjectFolderOrderHistoryMaintenanceDocument.vue';
import { ProjectFolderOrderHistoryMaintenanceMixin } from '@/project/myServices/projectFolderOrderHistoryMaintenanceMixin.mixin';

@Component({
    components: {
        ProjectFolderOrderHistoryMaintenanceDocument,
        ButtonCheckBox
    }
})
export default class ProjectFolderOrderHistoryVariant extends Mixins<ProjectFolderOrderHistoryMaintenanceMixin>(ProjectFolderOrderHistoryMaintenanceMixin) {
    @Prop({
        type: Object,
        required: true
    }) variant!: VariantWithDocumentsViewObject;

    isChecked: boolean = true;

    setParentChecked(isCheckedFromChild: boolean): void {
        this.isChecked = isCheckedFromChild;
        this.$emit('childChanged', this.isChecked);
    }

    setChecked(): void {
        this.isChecked = !this.isChecked;
        this.setChildChecked();
        this.$emit('childChanged', this.isChecked);
        this.sendDocList();
    }

    sendDocList(): void {
        this.variant.documents.forEach((document) => {
            if (this.isChecked) {
                this.addMaintenanceDocument(document);
            } else {
                this.deleteMaintenanceDocument(document);
            }
        });
    }

    setChildChecked(): void {
        this.variant.documents.forEach((variant, ix) => {
            const docComp: any = this.$refs[`docComp--${ix}`];

            docComp[0].forceDocumentCheck(this.isChecked);
        });
    }

    public forceVariantCheck(checked: boolean) {
        this.isChecked = checked;
        this.setChildChecked();
    }
}
