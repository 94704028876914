import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { namespace } from 'vuex-class';
import { ClientMessage } from '@/types/serverContract';
import { store } from '@/store';
import { uuid } from '@/project/config/utilities';

export interface ClientMessageEx extends ClientMessage {
    id: string;
}

interface IMessagesState {
    apiMessages: ClientMessageEx[];
}

enum MutationMethod {
    addApiMessages = 'addApiMessages',
    clearApiMessage = 'clearApiMessage'
}

const state: IMessagesState = {
    apiMessages: []
};

const getters: GetterTree<IMessagesState, any> = {
    apiMessages: state => state.apiMessages
};

const actions: ActionTree<IMessagesState, any> = {
    addApiMessages({ commit }, messages: ClientMessage[]) {
        commit(MutationMethod.addApiMessages, messages);
    },
    clearApiMessage({ commit }, id: string) {
        commit(MutationMethod.clearApiMessage, id);
    }
};

const mutations: MutationTree<IMessagesState> = {
    [MutationMethod.addApiMessages](state, newMessages: ClientMessage[]) {
        const uniqueNewMsgs = newMessages.filter(
            m => !state.apiMessages.find(am => am.message === m.message && am.messageType === m.messageType)
        );
        state.apiMessages = state.apiMessages.concat(
            uniqueNewMsgs.map((m, ix) => {
                return {
                    ...m,
                    id: uuid() + ix.toString()
                };
            })
        );
    },
    [MutationMethod.clearApiMessage](state: IMessagesState, id: string) {
        state.apiMessages = state.apiMessages.filter(m => m.id !== id);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export const { Getter: MessagesGetter, Action: MessagesAction } = namespace('messages');

export function addApiMessages(apiMessages: ClientMessage[]) {
    store.dispatch('messages/addApiMessages', apiMessages);
}
