





























import { Component, Mixins, Prop } from 'vue-property-decorator';
import { MaintenanceDocument } from '@/types/serverContract';
import ButtonCheckBox from '@/project/form/ButtonCheckBox.vue';
import { ProjectFolderOrderHistoryMaintenanceMixin } from '@/project/myServices/projectFolderOrderHistoryMaintenanceMixin.mixin';

@Component({
    components: {
        ButtonCheckBox
    }
})
export default class ProjectFolderOrderHistoryMaintenanceDocument extends Mixins<ProjectFolderOrderHistoryMaintenanceMixin>(ProjectFolderOrderHistoryMaintenanceMixin) {
    @Prop({
        type: Object,
        required: true
    }) maintenanceDocument!: MaintenanceDocument;

    isChecked: boolean = true;

    created() {
        if (this.isChecked) {
            this.addMaintenanceDocument(this.maintenanceDocument);
        }
    }

    setChecked(): void {
        this.isChecked = !this.isChecked;
        this.$emit('childChanged', this.isChecked);

        if (this.isChecked) {
            this.addMaintenanceDocument(this.maintenanceDocument);
        } else {
            this.deleteMaintenanceDocument(this.maintenanceDocument);
        }
    }

    public forceDocumentCheck(checked: boolean): void {
        this.isChecked = checked;
    }
}
