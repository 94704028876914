























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    FavoriteSalesAccountViewObject,
    MoveFavoriteAccountRequest,
    Direction
} from '@/types/serverContract';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import {
    HelpDeskAction,
    HelpDeskGetter
} from '@/store/helpDesk';

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class HelpDeskFavoriteAccounts extends Vue {
    @HelpDeskGetter favoriteAccounts!: FavoriteSalesAccountViewObject[];
    @HelpDeskGetter isFavoriteAccountsBusy!: FavoriteSalesAccountViewObject[];
    @HelpDeskAction getFavoriteSalesAccounts!: () => Promise<FavoriteSalesAccountViewObject[]>;
    @HelpDeskAction deleteFavoriteSalesAccount!: (accountId: number) => Promise<FavoriteSalesAccountViewObject[]>;
    @HelpDeskAction moveFavoriteSalesAccount!: (payload: MoveFavoriteAccountRequest) => Promise<FavoriteSalesAccountViewObject[]>;

    accountsLimit: number = 8;
    showAllAccounts: boolean = false;

    get favoriteAccountsToDisplay(): FavoriteSalesAccountViewObject[] {
        if (this.showAllAccounts) {
            return this.favoriteAccounts;
        }
        return this.favoriteAccounts.slice(0, this.accountsLimit);
    }

    created(): void {
        if (this.favoriteAccounts === null) {
            this.getFavoriteSalesAccounts();
        }
    }

    moveUp(accountId: number): void {
        this.moveFavoriteSalesAccount({
            direction: Direction.Up,
            salesAccountId: accountId
        });
    }

    moveDown(accountId: number): void {
        this.moveFavoriteSalesAccount({
            direction: Direction.Down,
            salesAccountId: accountId
        });
    }

    toggle(): void {
        this.showAllAccounts = !this.showAllAccounts;
    }
}
