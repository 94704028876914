import httpService from '@/core/http/http.service';
import {
    AddressLookupViewModel,
    AddressSuggestionsViewModel
} from '@/types/serverContract';

export default class AddressLookupController {
    public async getAddressSuggestions(query: string): Promise<AddressSuggestionsViewModel> {
        return httpService.get('AddressLookupApi/AddressSuggestions', { query });
    }

    public async getAddressInformation(id: string): Promise<AddressLookupViewModel> {
        return httpService.get('AddressLookupApi/Get', { id });
    }
}
