





























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { StoreIconsListViewModel } from '@/types/serverContract';
import StoreListItem from '@/project/store/StoreListItem.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: {
        SpinnerOverlay,
        StoreListItem
    }
})
export default class StoreIconsList extends Vue {
    pending: boolean = false;
    StoreIconsListModel: StoreIconsListViewModel | null = null;

    created() {
        this.getStoreIcons();
    }

    async getStoreIcons() {
        this.pending = true;
        try {
            this.StoreIconsListModel = await Api.stores.getStoreIcons();
        } finally {
            this.pending = false;
        }
    }
}
