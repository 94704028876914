
















































































































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import EasySupplyStockListEditForm from '@/project/easySupply/EasySupplyStockListEditForm.vue';
import {
    EasySupplyWarehouseDetailViewModel,
    EasySupplyWarehouseFormOptionsViewObject,
    EasySupplyWarehouseLineViewObject,
    SearchEasySupplyWarehouseRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import EasySupplySorting from '@/project/easySupply/EasySupplySorting.vue';
import EasySupplySearch from '@/project/easySupply/EasySupplySearch.vue';
import EasySupplyWarehouseLineItem from '@/project/easySupply/EasySupplyWarehouseLineItem.vue';
import EasySupplyStockListEditActionLinks from '@/project/easySupply/EasySupplyStockListEditActionLinks.vue';
import QueryPaging from '@/project/shared/QueryPaging.vue';
import EasySupplyStockListEditGateway from '@/project/easySupply/gateway/EasySupplyStockListEditGateway.vue';
import urlHelper from '@/project/shared/services/urlHelperSimpleSearch.service';
import { Route } from 'vue-router';
import scrollService from '@/core/scroll/scroll.service';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component({
    components: {
        SpinnerOverlay,
        EasySupplyStockListEditForm,
        EasySupplySorting,
        EasySupplySearch,
        EasySupplyWarehouseLineItem,
        EasySupplyStockListEditActionLinks,
        QueryPaging,
        EasySupplyStockListEditGateway
    }
})
export default class EasySupplyStockListEdit extends Vue {
    warehouseDetail: EasySupplyWarehouseDetailViewModel | null = null;
    formOptions: EasySupplyWarehouseFormOptionsViewObject | null = null;
    warehouseDetailLines!: EasySupplyWarehouseLineViewObject[];
    showEditInfo: boolean = false;
    pending: boolean = false;
    searchTerm: string = '';
    currentPage: number = 1;

    public $refs!: {
        warehouseLines: HTMLElement;
    };

    get hasWarehouseOrFormInfo(): boolean {
        return isNotNullEmptyOrUndefined(this.warehouseDetail) || isNotNullEmptyOrUndefined(this.formOptions);
    }

    get warehouseId(): number {
        return urlHelper.getId();
    }

    get newWarehouseHeaderText(): string {
        return this.$tr(this.$labels.Views.Services.EasySupply.Edit.NewWarehouseHeader);
    }

    get detailHeaderText(): string {
        return this.$tr(this.$labels.Views.Services.EasySupply.Edit.WarehouseHeader);
    }

    get showOrderList(): boolean {
        return isNotNullEmptyOrUndefined(this.warehouseDetail) &&
            this.warehouseDetail!.allowEdit &&
            isNotNullEmptyOrUndefined(this.warehouseDetail!.gatewayDetails);
    }

    created() {
        if (this.warehouseId) {
            this.getWareHouseByQuery();
        } else {
            this.getCreateOptions();
        }
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            return;
        }
        if (this.$refs.warehouseLines !== undefined) {
            this.getWareHouseByQuery().then(() => scrollService.scrollToElement(this.$refs.warehouseLines, -100));
        }
    }

    async getWareHouseByQuery() {
        this.pending = true;

        try {
            const searchPayload: SearchEasySupplyWarehouseRequest = {
                easySupplyWarehouseId: urlHelper.getId(),
                term: urlHelper.getSearchTerm(),
                page: urlHelper.getPageNumber(),
                sortBy: urlHelper.getSorting()
            };

            const updatedWarehouseDetail = await Api.easySupply.getEasySupplyWarehouseDetail(searchPayload);
            this.warehouseDetail = updatedWarehouseDetail;
            this.warehouseDetailLines = updatedWarehouseDetail.lines;
            this.formOptions = this.warehouseDetail.formOptions;

            this.currentPage = urlHelper.getPageNumber();
        } finally {
            this.pending = false;
        }
    }

    async getCreateOptions() {
        this.pending = true;

        try {
            let model = await Api.easySupply.getEasySupplyWarehouseCreateOptions();
            this.formOptions = model.formOptions;
        } finally {
            this.pending = false;
        }
    }

    updateWarehouseInfo(newWarehouseInfo: EasySupplyWarehouseDetailViewModel): void {
        this.warehouseDetail = newWarehouseInfo;
    }

    updatedWarehouse(): void {
        this.getWareHouseByQuery();
    }
}
