









































































import CurtainOverlayBase from '@/components/organisms/CurtainOverlay/overlays/CurtainOverlayBase.vue';
import { ICurtainOverlayBase } from '@/components/organisms/CurtainOverlay/overlays/ICurtainOverlayBase.vue';
import AccountGroupAdministrationOverlay from '@/components/molecules/AccountGroupList/AccountGroupAdministrationOverlay.vue';
import { AccountGroupResponseSlimViewModel } from '@/types/serverContract';
import { Component, Prop, Watch } from 'vue-property-decorator';

export interface AccountGroupResponseSlimWithSelectedState
    extends AccountGroupResponseSlimViewModel {
    selected?: boolean;
}

@Component({
    components: {
        AccountGroupAdministrationOverlay
    }
})
export default class HandleAdministrationGroups
    extends CurtainOverlayBase
    implements ICurtainOverlayBase {
    @Prop() accountGroups!: AccountGroupResponseSlimWithSelectedState[];

    pending: boolean = false;

    get hasAccountGroups(): boolean {
        return !!(this.accountGroups && this.accountGroups.length);
    }

    get isFieldsValid(): boolean {
        if (!this.hasAccountGroups) {
            return false;
        }
        return this.accountGroups.some(group => group.selected);
    }

    @Watch('isFieldsValid')
    UpdateSubmitButton(): void {
        if (this.overlay.activeCurtainOverlay === this) {
            this.overlay.submitButtonEnabled = this.isFieldsValid;
        }
    }

    mounted() {
        this.titleText = this.$tr(
            this.$labels.Views.FavoriteOrder.AdministrationOverlay.AccountGroupsOverlayHeadline
        );
        this.submitButtonText = this.$tr(
            this.$labels.Views.FavoriteOrder.AdministrationOverlay
                .AccountGroupsOverlaySubmitButtonText
        );
        this.overlay.submitButtonEnabled = true;
    }

    refreshGroups() {
        this.$emit('refreshGroups');
    }

    Submit(): Promise<void> {
        this.$emit('update:accountGroups', this.accountGroups);
        return Promise.resolve();
    }
}
