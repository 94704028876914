





















































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import {
    ProductIdType,
    SearchQuotationsRequest
} from '@/types/serverContract';
import dayjs, { Dayjs } from 'dayjs';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import BookkeepingUrlHelper from '@/project/bookkeeping/BookkeepingUrlHelper.service';
import { dateParseFormat } from '@/project/config/utilities';
import { clone } from 'lodash-es';
import DatePickerCtrl from '@/project/form/DatePickerCtrl.vue';
import { KeyValue } from '@/project/config/models';

@Component({
    components: {
        DatePickerCtrl,
        SpinnerOverlay
    }
})
export default class OrderHistoryQuotationsForm extends Vue {
    optionsProductIdTypeChange: KeyValue[] = Object.keys(ProductIdType)
        .filter((key: string): boolean => isNaN(Number(key)))
        .map((val: string): KeyValue => {
            return {
                key: val,
                value: this.$tr(this.$labels.Views.Bookkeeping.Invoices.ProductIdType[val])
            };
        });

    pending: boolean = false;
    selectedProductIdType: KeyValue | null = null;
    searchQuotationsPayload: SearchQuotationsRequest = {
        fromDate: BookkeepingUrlHelper.getSearchQuery('fromDate') === '' ? dayjs().subtract(6, 'month').format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('fromDate'),
        toDate: BookkeepingUrlHelper.getSearchQuery('toDate') === '' ? dayjs().format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('toDate'),
        orderNumber: BookkeepingUrlHelper.getSearchQuery('orderNumber'),
        productId: BookkeepingUrlHelper.getSearchQuery('productId'),
        productIdType: BookkeepingUrlHelper.getSearchQuery('productIdType') === '' ? ProductIdType.Sani : (BookkeepingUrlHelper.getSearchQuery('productIdType') as unknown as ProductIdType),
        reference: BookkeepingUrlHelper.getSearchQuery('reference'),
        caseNumber: BookkeepingUrlHelper.getSearchQuery('caseNumber'),
        page: BookkeepingUrlHelper.getPageNumber(),
        pageSize: 20,
        requisitionNumber: BookkeepingUrlHelper.getSearchQuery('requisitionNumber')
    };

    get isDateSpanTooLong(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) > 6;
    }

    get isDateSpanTooShort(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) < 0;
    }

    get dayjsFromDate(): Dayjs {
        return dayjs(this.searchQuotationsPayload.fromDate);
    }

    get dayjsToDate(): Dayjs {
        return dayjs(this.searchQuotationsPayload.toDate);
    }

    created() {
        this.submitForm();
    }

    @Watch('searchQuotationsPayload.fromDate')
    setFromDate(): void {
        if (this.isDateSpanTooLong) {
            this.searchQuotationsPayload.toDate = this.dayjsFromDate.clone().add(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.searchQuotationsPayload.toDate = clone(this.searchQuotationsPayload.fromDate);
        }
    }

    @Watch('searchQuotationsPayload.toDate')
    setToDate(): void {
        if (this.isDateSpanTooLong) {
            this.searchQuotationsPayload.fromDate = this.dayjsToDate.clone().subtract(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.searchQuotationsPayload.fromDate = clone(this.searchQuotationsPayload.toDate);
        }
    }

    async submitForm(): Promise<void> {
        this.pending = true;
        try {
            await Api.quotations.searchQuotations(this.searchQuotationsPayload).then(searchQuotationsResult => {
                this.$emit('searchSuccess', searchQuotationsResult);
                BookkeepingUrlHelper.setSearchQuery({
                    caseNumber: this.searchQuotationsPayload.caseNumber,
                    fromDate: this.searchQuotationsPayload.fromDate,
                    orderNumber: this.searchQuotationsPayload.orderNumber,
                    productId: this.searchQuotationsPayload.productId,
                    productIdType: this.searchQuotationsPayload.productIdType,
                    reference: this.searchQuotationsPayload.reference,
                    toDate: this.searchQuotationsPayload.toDate,
                    requisitionNumber: this.searchQuotationsPayload.requisitionNumber
                });
            });
        } finally {
            this.pending = false;
        }
    }

    onProductIdTypeChange(chosenProductIdType: any): void {
        this.searchQuotationsPayload.productIdType = (ProductIdType[chosenProductIdType.key] as unknown as ProductIdType);
        this.selectedProductIdType = this.optionsProductIdTypeChange[this.searchQuotationsPayload.productIdType];
    }
}
