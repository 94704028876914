













































































import { DeliveryAddressViewObject } from '@/types/serverContract';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FlexBoxSelectedDeliveryAddress extends Vue {
    @Prop({
        required: true
    })
    selectedDeliveryAddress!: DeliveryAddressViewObject;

    @Prop({
        required: true,
        default: false
    })
    disableSubmit!: boolean;

    close() {
        this.$emit('close');
    }

    toggle() {
        this.$emit('toggle');
    }

    onSubmit() {
        this.$emit('onSubmit');
    }
}
