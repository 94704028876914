































import Component from 'vue-class-component';
import { Mixins, Prop } from 'vue-property-decorator';
import {
    IFeatureToggling,
    LoginInformation,
    NavigationItem,
    TopLevelNavigationItem
} from '@/types/serverContract';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoints.mixin';
import { AppGetter } from '@/store/app';
import tracking from '@/core/tracking/tracking.service';

@Component
export default class PageHeaderDesktopMenu extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @Prop({ type: Array, required: true, default: () => [] }) level1Items!: TopLevelNavigationItem[];
    @Prop({ default: () => {}, type: Object }) activeLevel1Item!: TopLevelNavigationItem;
    @Prop({ required: true, type: Object }) myPage!: NavigationItem;
    @Prop({ required: true, type: Object }) createUserPage!: NavigationItem;
    @Prop({ required: true, type: Boolean }) isScrollUp!: boolean;

    @AppGetter isLoggedIn!: boolean;
    @AppGetter featureToggling!: IFeatureToggling;
    @AppGetter loginInformation!: LoginInformation;
    @AppGetter isPunchoutMode!: boolean;

    get defaultHeight(): string {
        return 'h-36';
    }

    get regularLevel1Items(): TopLevelNavigationItem[] {
        return this.level1Items.filter(navItem => navItem.link.url !== this.myPage.link.url && !navItem.isCatalog);
    }

    navigationTracking(text: string) {
        tracking.trackNavigationClick({ type: 'Top menu', clickText: text });
    }
}
