import Vue from 'vue';
import Vuex from 'vuex';
import appStore from './app';
import messagesStore from './messages';
import basketStore from './basket';
import checkoutStore from './checkout';
import helpDeskStore from './helpDesk';
import reportsStore from './reports';
import flexBoxStore from './flexBox';
import productStore from './product';
import userStore from './user';

Vue.use(Vuex);

export const store = new Vuex.Store({
    strict: true,
    mutations: {
        sync(state, entry) {
            state[entry.namespace][entry.property] = entry.data;
        }
    },
    modules: {
        app: appStore,
        messages: messagesStore,
        basket: basketStore,
        checkout: checkoutStore,
        helpDesk: helpDeskStore,
        reports: reportsStore,
        flexBox: flexBoxStore,
        product: productStore,
        user: userStore
    }
});
