

















import Vue from 'vue';
import Component from 'vue-class-component';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import co2ModalState from '@/project/products/productCo2Modal.state';
import ProductCo2InfoText from '@/project/products/ProductCo2InfoText.vue';
import { Prop } from 'vue-property-decorator';

const { state, hide } = co2ModalState();

@Component({
    components: {
        ModalOverlay,
        ProductCo2InfoText
    }
})
export default class ProductCo2InfoModal extends Vue {
    get active() {
        return state.active;
    }

    @Prop({
        type: String,
        required: true
    }) text!: string;

    replaceCO2WithSubscript(text) {
        var replacedText = text.replace('CO2', 'CO<sub>2</sub>');
        return replacedText;
    }

    get emissionHeaderText(): string {
        return this.replaceCO2WithSubscript(this.text);
    }

    hide = hide;
}
