






































import Component from 'vue-class-component';
import { Vue, Prop, Inject, Watch } from 'vue-property-decorator';
import ViewportEventsService, {
    SidesEnum
} from '@/core/responsive/viewport/viewportEvents.service';
import RouterLinkConditional from '@/core/routerLinkConditional.vue';

@Component({
    inheritAttrs: false,
    components: {
        RouterLinkConditional
    }
})
export default class DropdownCtrlContent extends Vue {
    @Inject() Ctrl!: any;

    @Prop({ type: String }) contentTitle!: string;
    @Prop({ type: String }) redirectText!: string;
    @Prop({ type: String }) redirectUrl!: string;

    @Prop(Boolean) useCustomStyle!: boolean;
    @Prop(String) contentClass!: string;
    @Prop({ default: false }) alignRight!: boolean;

    @Watch('Ctrl.isActive')
    onIsActiveChanged() {
        if (this.Ctrl.isActive) {
            this.$nextTick(() => {
                // wait for DOM update
                const elm = this.$refs['dropdown'] as HTMLElement;
                ViewportEventsService.setPositionInViewport(elm, [SidesEnum.Right]);
            });
        }
    }
}
