












































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
    NavigationItem,
    NavigationViewModel,
    TopLevelNavigationItem
} from '@/types/serverContract';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import serverContext from '@/core/serverContext.service';
import { ActiveInfo } from '@/project/config/models';
import SideMenuSubLevel from './SideMenuSubLevel.vue';
import SideMenuItem from './SideMenuItem.vue';
import tracking from '@/core/tracking/tracking.service';

@Component({
    components: {
        OffCanvasOverlay,
        SideMenuSubLevel,
        SideMenuItem
    }
})
export default class SideMenu extends Vue {
    @Prop({
        type: Boolean,
        required: true
    }) menuOpen!: boolean;

    @Prop({
        type: Object,
        required: true
    }) navigation!: NavigationViewModel;

    isSubLevelActive = false;
    isTransitionDone = false;
    isTab = false;
    isLastItem = true;
    setTabSizeFullHeight = false;

    nextLevelNavigationItem: NavigationItem | null = null;
    nextActiveLevel: number = 0;
    subLevelBackgroundColorStyle: Partial<CSSStyleDeclaration> | null = null;
    mainMenuLinkLabel: string = this.navigation.main[0].link.name;

    navigationTracking(clickText: string) {
        tracking.trackNavigationClick({
            type: ('Left navigation bar - submenu_1'),
            clickText: clickText
        });
    }

    get frontpageUrl(): string {
        return serverContext.sitePages.frontPage.url;
    }

    get tabEnterAnimationClasses() {
        const animCls = this.isTab ? 'fadeInUp' : 'fadeIn';
        return `animated ${animCls} u-anim-dur-500`;
    }

    get tabLeaveAnimationClasses() {
        const animCls = !this.isTab ? 'fadeOutDown' : 'fadeOut';
        return `animated ${animCls} u-anim-dur-500`;
    }

    get sublevelEnterAnimationClasses() {
        return 'animated slideInLeft u-anim-dur-500 cubic-bezier-animation';
    }

    get sublevelLeaveAnimationClasses() {
        return 'animated slideOutLeft u-anim-dur-500 cubic-bezier-animation';
    }

    get regularLevel1Items(): TopLevelNavigationItem[] {
        return this.navigation.main.filter(navItem => navItem.link.url !== this.navigation.myPage.link.url);
    }

    @Watch('isSubLevelActive')
    onIsSubLevelActiveChange(): void {
        if (!this.isSubLevelActive) {
            this.isTab = false;
        }
    }

    overlayShowChanged(open: boolean) {
        this.$emit('update:menuOpen', open);
    }

    tabAnimationAfterEnter() {
        // Add the class after enter transition is complete
        this.$nextTick(() => {
            this.setTabSizeFullHeight = true;
        });
    }

    tabAnimationBeforeLeave() {
        // Add the class after enter transition is complete
        this.$nextTick(() => {
            this.setTabSizeFullHeight = false;
        });
    }

    goToNextLevel(navigationItem: NavigationItem) {
        this.nextActiveLevel = 1;
        this.nextLevelNavigationItem = navigationItem;
        this.isSubLevelActive = true;

        const nextLevelActiveInfo = { level: this.nextActiveLevel };

        this.isLastItem = this.isLastSubLevel(nextLevelActiveInfo);
        this.subLevelBackgroundColorStyle = this.getBackgroundColor(5 * this.nextActiveLevel);
    }

    overlayVisibleChanged(open: boolean) {
        this.isTab = false;
        if (open) {
            this.isLastItem = true;
        } else {
            this.isSubLevelActive = false;
        }

        this.$emit('menuVisible', open);
    }

    closeOverlay(label?: string) {
        (this.$refs.overlay as any).hide();
        if (label) this.navigationTracking(label);
    }

    setTransitionStatus(done: boolean) {
        this.isTransitionDone = done;
    }

    getBackgroundColor(lightning: number | null = null): Partial<CSSStyleDeclaration> {
        const baseColor = 'hsl(208, 75%, 40%)'; // Base color
        const minLightness = 10; // Maximum lightness value

        const colorParts = baseColor.match(/\d+/g); // Extract HSL color values
        if (colorParts !== null && lightning !== null) {
            let lightness = parseInt(colorParts[2]); // Get the lightness value
            lightness = Math.max(lightness - lightning!, minLightness); // Increase lightness based on the attribute value
            return `hsl(${colorParts[0]}, ${colorParts[1]}%, ${lightness}%)`; // Return the background color
        }

        return baseColor;
    }

    updatePreviousItem(activeInfo) {
        this.isTab = this.shrinkToTab(activeInfo);
        this.isLastItem = this.isLastSubLevel(activeInfo);
    }

    isLastSubLevel(activeInfo: ActiveInfo) {
        if (!activeInfo.iterationLevel) {
            return true;
        }
        return false;
    }

    shrinkToTab(activeInfo: ActiveInfo): boolean {
        if (activeInfo.iterationLevel) {
            if (activeInfo.iterationLevel < activeInfo.level) {
                return true;
            }
        }
        return false;
    }

    closeSubLevel() {
        this.isSubLevelActive = false;
        this.nextActiveLevel = 0;
        this.nextLevelNavigationItem = null;
    }

    goToMainMenu(label: string) {
        this.isTransitionDone = false;
        // reset tab height for animation correction
        this.setTabSizeFullHeight = false;

        this.$nextTick().then(() => {
            this.isSubLevelActive = false;
            this.isTab = false;
            const lastChild = this.getLastChild(this.$refs.subLevelComponent) as SideMenuSubLevel;
            if (!lastChild || !lastChild.$parent || !lastChild.$parent.$el.parentElement) {
                return;
            }

            lastChild.$parent.$el.parentElement.style.left = `${-440}px`;
        });
        this.navigationTracking(label);
    }

    getLastChild(component) {
        if (!component || !component.$refs || !component.$refs.subLevelComponent) {
            return component;
        } else {
            const lastChild = component.$refs.subLevelComponent;
            return this.getLastChild(lastChild);
        }
    }
}
