












































import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
    PriceServiceMixin,
    PriceServiceViewObject
} from '@/project/products/PriceServiceMixin.mixin';
import { BasketAllowanceGroupViewObject } from '@/types/serverContract';

@Component
export default class OrderSummarySubTotal extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({
        default: '',
        type: String
    }) netSubTotal!: string;

    @Prop({
        default: '',
        type: String
    }) grossSubTotal!: string;

    @Prop({
        default: () => [],
        type: Array
    }) allowances!: BasketAllowanceGroupViewObject[];

    price: PriceServiceViewObject = {
        netPrice: this.netSubTotal,
        grossPrice: this.grossSubTotal
    };

    get hasAllowances(): boolean {
        return this.shouldShowNetPrice && this.allowances && this.allowances.length >= 1;
    }
}
