




















































import { BRIGHTNESS_MODE } from '@/core/enums/enums';
import { ArticlePromotionTileViewObject } from '@/types/serverContract';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export class TileSizeData {
    constructor(tileId: number, tileMinWidth: number, tilePaddingLeft: number) {
        this.tildId = tileId;
        this.tileMinWidth = tileMinWidth;
        this.tilePaddingLeft = tilePaddingLeft;
    }

    tildId = 0;
    tileMinWidth: number = 440;
    tilePaddingLeft: number = 0;
}
@Component
export default class ArticlePromotionTile extends Vue {
    @Prop({ type: Object, required: true }) tileData!: ArticlePromotionTileViewObject;
    @Prop({ type: String, required: false }) tileColor!: string;
    @Prop({ type: Object, required: false }) tileSizeData!: TileSizeData;

    colorMode: BRIGHTNESS_MODE = BRIGHTNESS_MODE.Light;

    mounted() {
        this.UpdateAvailableLines();
        this.updateTileSize();
        const coloredTile = this.$refs.coloredTile as HTMLElement;
        this.colorMode = this.$helper.lightOrDark(window.getComputedStyle(coloredTile).backgroundColor);
    }

    updateTileSize() {
        if (this.tileData && this.tileSizeData) {
            const tile = this.$refs.tile as HTMLElement;
            tile.style.setProperty('width', `${this.tileSizeData.tileMinWidth}px`);
            if (this.tileSizeData.tildId !== 0) {
                tile.style.setProperty('padding-left', `${this.tileSizeData.tilePaddingLeft}px`);
            }
        }
    }

    UpdateAvailableLines() {
        this.$nextTick(() => {
            const headerText = this.$refs.headerText as HTMLElement;
            const headerComputedStyle = window.getComputedStyle(headerText);

            // Get the line height
            const headerLineHeight = parseInt(headerComputedStyle.lineHeight);

            // Get the height of the text element
            const height = headerText.offsetHeight;

            // Calculate the number of lines
            const headerNumberOfLines = Math.round(height / headerLineHeight);

            const bodyText = this.$refs.bodyText as HTMLElement;
            const availableLines = headerNumberOfLines === 1 ? 3 : 2;
            bodyText.style.setProperty('-webkit-line-clamp', availableLines.toString());
            const lineHeight = parseFloat(window.getComputedStyle(bodyText).lineHeight);
            bodyText.style.setProperty('--max-height', `${availableLines * lineHeight}px`);
            bodyText.innerHTML = this.tileData.body;
        });
    }
}
