




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IEasySupplyPrintLabelsStep } from '@/project/easySupply/printLabels/EasySupplyPrintLabels.service';

@Component
export default class EasySupplyPrintLabelsStepHeader extends Vue {
    @Prop({
        required: true,
        type: Object
    }) step!: IEasySupplyPrintLabelsStep;

    changeChoice(): void {
        this.$emit('changeChoice');
    }
}
