var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isVisible)?_c('div',[(_vm.hasAccountGroups)?_c('h3',{staticClass:"mb-16 text-16"},[_vm._v("\n            "+_vm._s(_vm.$tr(_vm.$labels.Views.FavoriteOrder.AdministrationOverlay.AccountGroupListHeadline))+"\n        ")]):_c('p',[_vm._v("\n            "+_vm._s(_vm.$tr(_vm.$labels.Views.FavoriteOrder.AdministrationOverlay.NoGroups))+"\n        ")]),_vm._l((_vm.accountGroups),function(group){return _c('CheckboxCtrl',{key:group.id,staticClass:"c-checkbox--smaller focus-within:outline-inset mb-8 flex flex-col items-stretch break-all rounded-8 bg-white p-12 text-14 font-w600 text-marine",attrs:{"label":group.name,"name":"selectedAccountGroups[]"},model:{value:(group.selected),callback:function ($$v) {_vm.$set(group, "selected", $$v)},expression:"group.selected"}})}),_c('ButtonCtrl',{staticClass:"mt-16",attrs:{"variant":_vm.$enums.BUTTON_VARIANTS.QUATERNARY,"button-size":_vm.$enums.BUTTON_SIZE.SMALL},nativeOn:{"click":function($event){return _vm.changeOverlay(_vm.$refs.GroupAdministrationOverlay)}}},[_c('i',{staticClass:"sanicon-ico-plus mr-2"}),_vm._v("\n            "+_vm._s(_vm.$tr(_vm.$labels.Views.FavoriteOrder.AdministrationOverlay.CreateGroup))+"\n        ")]),(!_vm.hasAccountGroups)?_c('div',{staticClass:"mt-48"},[_c('h3',{staticClass:"mb-8 text-16"},[_vm._v("\n                "+_vm._s(_vm.$tr(
                        _vm.$labels.Views.FavoriteOrder.AdministrationOverlay
                            .AccountGroupsExplanationHeadline
                    ))+"\n            ")]),_c('div',[_c('p',{staticClass:"mb-16"},[_vm._v("\n                    "+_vm._s(_vm.$tr(
                            _vm.$labels.Views.FavoriteOrder.AdministrationOverlay
                                .AccountGroupsExplanationTop
                        ))+"\n                ")]),_c('p',[_vm._v("\n                    "+_vm._s(_vm.$tr(
                            _vm.$labels.Views.FavoriteOrder.AdministrationOverlay
                                .AccountGroupsExplanationBottom
                        ))+"\n                ")])])]):_vm._e()],2):_vm._e(),_c('AccountGroupAdministrationOverlay',{ref:"GroupAdministrationOverlay",attrs:{"overlay":_vm.overlay,"prev-curtain-overlay":_vm.getThis},on:{"submit":_vm.refreshGroups}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }