










































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import scrollService from '@/core/scroll/scroll.service';
import './round.filter';

@Component
export default class VaWaterbreak extends Vue {
    heightPressureInMeters: number = 1;
    heightPressureInMetersHole: number = 1;
    holeInMeters: number = 0.15;
    outflow: number = 46.94;
    resultOutflow: number = 0;
    resultHole: number = 0;
    showOutflowCalculations: boolean = false;
    showHoleCalculations: boolean = false;

    public $refs!: {
        CalcDiameterElement: HTMLElement;
        CalcWaterFlowElement: HTMLElement;
    };

    created(): void {
        this.resultOutflow = this.calculateOutflow();
        this.resultHole = this.calculateHole();
    }

    calculateOutflow(): number {
        const b3 = this.heightPressureInMeters;
        const b5 = this.holeInMeters;

        const f3 = 1.884;
        const f4 = (b5 * b5 / 4);
        const f5 = 4.42944;

        const result = ((f3 * f4 * f5 * b3) * 1000);
        this.resultOutflow = result;

        return result;
    };

    calculateHole(): number {
        const b3 = this.heightPressureInMetersHole;
        const b5 = this.outflow;

        const f3 = 1.884;
        const f5 = 4.42944;

        const result = Math.sqrt(((b5 / f3 / f5 / b3) / 1000) * 4);
        this.resultHole = result;

        return result;
    };

    toggleOutflow(): void {
        this.showOutflowCalculations = !this.showOutflowCalculations;
    }

    toggleHole(): void {
        this.showHoleCalculations = !this.showHoleCalculations;
    }

    goto(element: HTMLElement): void {
        scrollService.scrollToElement(element);
    };
}
