
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import tracking from '@/core/tracking/tracking.service';

@Component

export default class RichtextWithImage extends Vue {
    @Prop({ type: String, default: '' }) public content!: string;
    @Prop({ type: Number, default: '' }) public imageColumns!: number;
    @Prop({ type: String, default: '' }) public imgUrl!: string;
    @Prop({ type: String, default: '' }) public imgWebpUrl!: string;
    @Prop({ type: String, default: '' }) public linkUrl!: string;
    @Prop({ type: String, default: '' }) public linkTarget!: string;
    @Prop({ type: String, default: '' }) public linkName!: string;
    @Prop({ type: Boolean }) public imageBeforeText!: boolean;
    @Prop({ type: Boolean }) public adaptiveText!: boolean;
    @Prop({ type: String, default: '' }) public trackingEvent!: string;

    links: HTMLAnchorElement[] = [];

    public get imageClasses() {
        return {
            'o-grid-item w-full relative': true,
            [`md:w-${this.imageColumns}/12`]: true,
            [this.imageBeforeText ? 'order-1' : 'order-2']: true
        };
    }

    public get textClasses() {
        return {
            [this.adaptiveText ? 'wysiwyg o-grid-item flex-1' : 'wysiwyg o-grid-item w-full']: true,
            [`md:w-${12 - this.imageColumns}/12`]: !this.adaptiveText,
            [this.imageBeforeText ? 'order-2' : 'order-1']: true
        };
    }

    mounted() {
        const richtext = this.$refs.richtext as HTMLElement;
        this.links = Array.from(richtext.querySelectorAll('a'));

        if (!this.links || !this.links.length || !this.trackingEvent) {
            return;
        }

        this.links.forEach((link) => {
            link.addEventListener('click', this.trackLinkAction);
        });
    }

    destroyed() {
        if (!this.links || !this.links.length || !this.trackingEvent) {
            return;
        }

        this.links.forEach((link) => {
            link.removeEventListener('click', this.trackLinkAction);
        });
    }

    trackLinkAction() {
        tracking[this.trackingEvent]();
    }
}
