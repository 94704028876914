
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { AppGetter } from '@/store/app';
import {
    LoginInformation,
    SalesAccountsGroupViewObject,
    SalesAccountViewObject
} from '@/types/serverContract';
import { FlexBoxGetter, FlexBoxAction } from '@/store/flexBox';
import tracking from '@/core/tracking/tracking.service';

@Component
export default class SelectAccountPanelWrapper extends Vue {
    @AppGetter loginInformation!: LoginInformation;
    @AppGetter isPunchoutMode!: boolean;

    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @FlexBoxAction setShowFlexBoxModal!: (show: boolean) => void;
    @FlexBoxAction setChangeToAccount!: (changeToAccount: string) => void;
    showSpinner = false;
    @Prop({ type: String }) accountSearchTerm!: string;

    get currentAccount(): SalesAccountViewObject | null {
        return this.loginInformation ? this.loginInformation.currentAccount : null;
    }

    get currentAccountDisplayName(): string {
        return this.currentAccount ? `${this.currentAccount.soldToPartnerId} ${this.currentAccount.soldToPartnerName}` : '';
    }

    get currentBillToAccountDisplayName() {
        if (!this.currentAccount) {
            return '';
        }

        const currentBillToPartnerId = this.currentAccount.billToPartnerId;
        const matchingAccount = this.loginInformation.accounts.find(account => account.displayName.includes(currentBillToPartnerId));
        return matchingAccount ? matchingAccount.displayName : '';
    }

    get accountsExcludingCurrent(): SalesAccountsGroupViewObject[] | [] {
        if (this.loginInformation) {
            return this.loginInformation.accounts.map(account => {
                return {
                    ...account,
                    salesAccounts: account.salesAccounts.filter(sa =>
                        (this.accountSearchTerm || !this.isActiveAccount(sa)) &&
                        (account.displayName.toLowerCase().includes(this.accountSearchTerm.toLowerCase()) ||
                        this.accountDisplayName(sa).toLowerCase().includes(this.accountSearchTerm.toLowerCase()))
                    )
                };
            });
        }
        return [];
    }

    async changeAccount(account: SalesAccountViewObject) {
        try {
            this.showSpinner = true;

            // This will refresh the page
            await Api.authentication.changeAccount(account.soldToPartnerId);
        } finally {
            this.$emit('done');
        }
    }

    accountDisplayName(account: SalesAccountViewObject): string {
        return `${account.soldToPartnerId} ${account.soldToPartnerName}`;
    }

    isActiveAccount(account: SalesAccountViewObject): boolean {
        return account.soldToPartnerId === this.loginInformation.currentAccount.soldToPartnerId;
    }

    selectAccount(account: SalesAccountViewObject): void {
        if (!this.isInFlexBoxContext) {
            this.changeAccount(account);
            return;
        }

        this.setChangeToAccount(account.soldToPartnerId);
        tracking.trackFlexBoxButtonPress('Dropdown button, selected account', !this.isInFlexBoxContext);
        this.setShowFlexBoxModal(true);
    }

    showFlexBoxModal() {
        tracking.trackFlexBoxButtonPress('Dropdown button, no account selected', !this.isInFlexBoxContext);
        this.setShowFlexBoxModal(true);
    }

    render() {
        return this.$scopedSlots.default!({
            selectAccount: this.selectAccount,
            showFlexBoxModal: this.showFlexBoxModal,
            accountDisplayName: this.accountDisplayName,
            currentBillToAccountDisplayName: this.currentBillToAccountDisplayName,
            currentAccountDisplayName: this.currentAccountDisplayName,
            accountsExcludingCurrent: this.accountsExcludingCurrent,
            isInFlexBoxContext: this.isInFlexBoxContext,
            showSpinner: this.showSpinner
        });
    }
}
