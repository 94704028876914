















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
    LineItemViewObject,
    UpdateLineItemRequest,
    BasketViewModel
} from '@/types/serverContract';
import api from '@/project/http/api';
import { BasketGetter } from '@/store/basket';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import bus from '@/core/bus';
import { debounce } from 'lodash-es';

@Component
export default class LineItemQuantity extends Vue {
    delayTimer: number | null = null;

    @Prop({
        type: Object
    }) lineItem!: LineItemViewObject;

    @Prop({
        type: Boolean,
        default: false
    }) scalePriceToPromoteVisible!: boolean;

    @BasketGetter basket!: BasketViewModel;

    quantityError: boolean = false;
    pending: boolean = false;
    running: boolean = false;
    debouncedCalculatePrice = debounce(this.updateQuantity, 800);

    lineItemPayLoad: UpdateLineItemRequest = {
        certificateCode: this.lineItem && this.lineItem.certificate ? this.lineItem.certificate.certificateCode : null,
        fixedUnitValue: this.lineItem && this.lineItem.fixedUnit ? this.lineItem.fixedUnit.value : null,
        fixedUnitCode: this.lineItem && this.lineItem.fixedUnit ? this.lineItem.fixedUnit.unitCode : null,
        isFixedUnit: this.lineItem && this.lineItem.unit ? this.lineItem.unit.isFixedUnit : false,
        lineItemId: this.lineItem && this.lineItem.lineIdentifier ? this.lineItem.lineIdentifier : '',
        quantity: this.lineItem && this.lineItem.quantity ? this.lineItem.quantity : 1,
        stamping: this.lineItem && this.lineItem.certificate ? this.lineItem.certificate.stamping : false,
        unit: this.lineItem && this.lineItem.unit ? this.lineItem.unit.unitCode : '',
        customerReference: null
    };

    decreaseQuantity(): void {
        // If the current quantity is 24, and the increment is 10, this gives us 4.
        // If the current quantity is 20, and the increment is 10, this gives us 0.
        const distanceToPreviousValidIncrement = this.lineItemPayLoad.quantity % this.lineItem.unit.increment;

        // If we're on a valid quantity, the distance is 0. Then we need to decrease a whole increment step.
        const quantityToSubtract = distanceToPreviousValidIncrement === 0 ? this.lineItem.unit.increment : distanceToPreviousValidIncrement;

        this.lineItemPayLoad.quantity -= quantityToSubtract;

        bus.emit('calculate-price-start');
        this.debouncedCalculatePrice();
    }

    increaseQuantity(): void {
        const distanceToNextValidIncrement = this.lineItem.unit.increment - (this.lineItemPayLoad.quantity % this.lineItem.unit.increment);
        this.lineItemPayLoad.quantity += distanceToNextValidIncrement;

        bus.emit('calculate-price-start');
        this.debouncedCalculatePrice();
    }

    get exceedsInventoryQuantity(): boolean {
        return this.lineItem.isAdhoc && this.lineItem.inventoryQuantity <= this.lineItemPayLoad.quantity;
    }

    get isDisabled(): boolean {
        return this.lineItemPayLoad.quantity < 2;
    }

    @Watch('lineItem')
    updateLineItemPayload(newVal: LineItemViewObject) {
        if (!newVal) {
            return;
        }

        this.lineItemPayLoad = {
            certificateCode: newVal && newVal.certificate ? newVal.certificate.certificateCode : null,
            fixedUnitValue: newVal && newVal.fixedUnit ? newVal.fixedUnit.value : null,
            fixedUnitCode: newVal && newVal.fixedUnit ? newVal.fixedUnit.unitCode : null,
            isFixedUnit: newVal && newVal.unit ? newVal.unit.isFixedUnit : false,
            lineItemId: newVal && newVal.lineIdentifier ? newVal.lineIdentifier : '',
            quantity: newVal && newVal.quantity ? newVal.quantity : 1,
            stamping: newVal && newVal.certificate ? newVal.certificate.stamping : false,
            unit: newVal && newVal.unit ? newVal.unit.unitCode : '',
            customerReference: newVal && newVal.customerReference ? newVal.customerReference : ''
        };
        this.quantityError = false;
    }

    async updateQuantity() {
        if (this.lineItemPayLoad.quantity < this.lineItem.unit.increment) {
            this.quantityError = true;
        } else {
            this.quantityError = false;
            this.pending = true;
            try {
                await api.basket.updateBasketLineItem(this.lineItemPayLoad).then(() => {
                    if (this.basket && this.basket.lineItems) {
                        const lineWithScalePriceToPromote = this.basket.lineItems.find(line => {
                            // get lineItem with scalePriceToPromote for the payload if present
                            return line.productId === this.lineItem.productId && line.unit.unitCode === this.lineItemPayLoad.unit && isNotNullEmptyOrUndefined(line.scalePriceToPromote);
                        });

                        const showScalePriceToPromote: boolean = isNotNullEmptyOrUndefined(lineWithScalePriceToPromote);
                        if (showScalePriceToPromote) {
                            this.$emit('scalePriceToPromote', lineWithScalePriceToPromote!.scalePriceToPromote);
                        } else if (this.scalePriceToPromoteVisible) {
                            this.$emit('closeScalePriceToPromote');
                        }
                    }
                });
            } finally {
                this.pending = false;
                bus.emit('calculation-price-end');
            }
        }
    }
}
