import Vue from 'vue';
import { router } from '@/router';

// Fallback for matches for IE11
if (!Element.prototype.matches) {
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
}

Vue.directive('delegateHtmlLinks', {
    bind: function(el: HTMLElement) {
        el.addEventListener('click', event => {
            const { target }: any = event;

            // Expects an absolute url "http://example.com/da/foo" and returns the language "da"
            function getHrefLanguage(absolutePath) {
                const relativePath = absolutePath.replace(window.location.origin + '/', '');
                return relativePath.split('/')[0];
            }

            // only links that do not reference external resources
            if (target && target.href && target.href.startsWith(window.location.origin)) {
                // If the target link is not from the same language as the current page, the do not use the vue router.
                // For example if /pl/foo/bar has a link to /da/foo/bar
                if (getHrefLanguage(window.location.href) !== getHrefLanguage(target.href)) {
                    return;
                }

                const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event;

                if (metaKey || altKey || ctrlKey || shiftKey) {
                    return;
                }

                if (defaultPrevented) {
                    return;
                }

                if (button !== undefined && button !== 0) {
                    return;
                }

                if (target && target.getAttribute) {
                    const linkTarget = target.getAttribute('target');
                    if (/\b_blank\b/i.test(linkTarget)) {
                        return;
                    }
                }

                const url = new URL(target.href);
                const to = url.pathname;
                if (window.location.pathname !== to && event.preventDefault) {
                    event.preventDefault();
                    router.push(to);
                }
            }
        });
    }
});
