



































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AccountRelationViewObject } from '@/types/serverContract';

@Component
export default class AccountAccesses extends Vue {
    @Prop({
        required: true,
        type: Object
    }) userAccountAccess!: AccountRelationViewObject;

    @Prop({
        required: true,
        type: String
    }) primarySoldToPartnerId!: string;

    get accessLevelAdmin(): string {
        return this.$tr(this.$labels.Views.EditUser.Tabs.Access.Level.Admin);
    }

    get accessLevelUser(): string {
        return this.$tr(this.$labels.Views.EditUser.Tabs.Access.Level.User);
    }

    onPrimaryAccountChange() {
        this.$emit('update:primarySoldToPartnerId', this.userAccountAccess.soldToPartnerId);
    }
}
