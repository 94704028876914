










































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    AddQuotationToBasketRequest,
    BasketViewModel,
    GetProjectOrderQuotationRequest,
    QuotationDetailsLineItemViewObject,
    QuotationDetailsViewModel,
    QuotationItem
} from '@/types/serverContract';
import Api from '@/project/http/api';
import urlHelper from '@/project/shared/services/urlHelperSimpleSearch.service';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import ProjectOrderQuotationDetailsOrderLine from '@/project/myServices/projectFolder/ProjectOrderQuotationDetailsOrderLine.vue';
import ProjectOrderQuotationDetailsOrderLineWithOptions from '@/project/myServices/projectFolder/ProjectOrderQuotationDetailsOrderLineWithOptions.vue';
import { BasketGetter } from '@/store/basket';

interface EmittedSelectedLine {
    indexId: number;
    selectedLine: QuotationDetailsLineItemViewObject;
}

@Component({
    components: {
        ButtonSubmit,
        ProjectOrderQuotationDetailsOrderLine,
        ProjectOrderQuotationDetailsOrderLineWithOptions,
        SpinnerOverlay
    }
})
export default class ProjectOrderQuotationDetails extends Vue {
    @BasketGetter basket!: BasketViewModel;
    quotationDetails: QuotationDetailsViewModel | null = null;
    pending: boolean = false;
    addToBasketPending: boolean = false;
    success: boolean = false;
    invalidOrderLines: boolean = false;
    orderLinesWithOptions: QuotationDetailsLineItemViewObject[] = [];
    tempOrderLinesWithOptions: EmittedSelectedLine[] = [];
    addedToBasketFromParent: boolean = false;

    get disableAddAllToBasket(): boolean {
        return this.invalidOrderLines || this.addedToBasketFromParent || this.quotationDetails === null || this.quotationDetails.orderLinesWithOptions.length !== this.orderLinesWithOptions.length || !this.quotationDetails.allowOrdering;
    }

    get getProjectOrderQuotationDetailsRequestPayload(): GetProjectOrderQuotationRequest {
        return {
            orderId: urlHelper.getId().toString()
        };
    }

    created(): void {
        this.getQuotationDetails();
    }

    async getQuotationDetails(): Promise<void> {
        this.pending = true;
        try {
            await Api.projectOrderQuotation.projectOrderQuotationDetails(this.getProjectOrderQuotationDetailsRequestPayload).then(quotationDetails => {
                this.quotationDetails = quotationDetails;
            });
        } finally {
            this.pending = false;
        }
    }

    async addAllLinesToBasket() {
        if (!this.quotationDetails) {
            return;
        }

        this.addToBasketPending = true;

        const combinedQuotationItems = [...this.quotationDetails.orderLines, ...this.orderLinesWithOptions];

        let quotationItems: QuotationItem[] = combinedQuotationItems.map(orderLine => ({
            lineId: orderLine.lineId,
            quantity: this.calculateQuantityToAdd(orderLine)
        }));

        let addQuotationToBasketPayload: AddQuotationToBasketRequest = {
            quotationId: this.quotationDetails.orderId,
            lines: quotationItems.filter(orderLine => {
                return orderLine.quantity >= 1;
            })
        };

        try {
            await Api.basket.addQuotationToBasket(addQuotationToBasketPayload);
            this.addedToBasketFromParent = true;
        } finally {
            this.addToBasketPending = false;
        }
    }

    isAddAllToBasketIsInvalid(): void {
        this.invalidOrderLines = true;
    }

    calculateQuantityToAdd(orderLine: QuotationDetailsLineItemViewObject): number {
        let basketlineItem = this.basket.lineItems.find(lineItem =>
            lineItem.referenceDocument !== undefined &&
            lineItem.referenceDocument!.documentItem === orderLine.lineId &&
            this.quotationDetails !== null &&
            lineItem.referenceDocument!.documentId === this.quotationDetails.orderId);

        if (!basketlineItem) {
            return orderLine.maxQuantity;
        }
        return orderLine.maxQuantity - basketlineItem.quantity;
    }

    updateOrderLinesWithOptions(emittedSelectedLine: EmittedSelectedLine): void {
        let hasMatch = this.tempOrderLinesWithOptions.some(orderLineWithOptions => emittedSelectedLine.indexId === orderLineWithOptions.indexId);
        let itemToRemoveIndex = this.tempOrderLinesWithOptions.findIndex(item => item.indexId === emittedSelectedLine.indexId && item.selectedLine.lineId !== emittedSelectedLine.selectedLine.lineId);
        this.tempOrderLinesWithOptions.push(emittedSelectedLine);

        if (hasMatch) {
            this.tempOrderLinesWithOptions.splice(itemToRemoveIndex, 1);
        }

        this.orderLinesWithOptions = this.tempOrderLinesWithOptions.map(orderLine => {
            return orderLine.selectedLine;
        });
    }

    validateOrderLines(showErrorMsg: boolean): void {
        this.invalidOrderLines = showErrorMsg;
    }
}
