import Cookies from 'js-cookie';
import serverContextService from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';
import constants from '@/project/config/constants';

class FlexBoxService {
    public get isInFlexBoxContext(): boolean {
        if (serverContextService.loginInformation !== null) {
            const flexBoxContextCookie = 'flexboxcontext_' + serverContextService.loginInformation.userId;
            const flexBoxContext = Cookies.get(flexBoxContextCookie);
            return !!flexBoxContext;
        } else {
            return false;
        }
    }

    public get flexBoxButtonText(): string {
        if (this.isInFlexBoxContext) {
            return translateFilter('views.FlexBox.Active');
        } else {
            return translateFilter('views.FlexBox.ActivateBasket');
        }
    }

    firstLetterToLowerCase(inputString: string): string {
        if (inputString.length === 0) {
            return inputString; // Return the string as is if it's an empty string
        }

        const firstLetter = inputString[0].toLowerCase();
        const remainingString = inputString.slice(1);
        return firstLetter + remainingString;
    }

    public getFlexBoxInfoText(inFlexBoxText: string, defaultText: string): string {
        let hourLabel = translateFilter('views.FlexBox.TimeSpecific.OneHour');

        if (this.isInFlexBoxContext && serverContextService.loginInformation && serverContextService.loginInformation.flexBoxZone && serverContextService.loginInformation.flexBoxZone !== constants.flexBoxZone[0]) {
            let flexBoxZone = serverContextService.loginInformation.flexBoxZone;

            // get hours by trimming leading zeros from flexBoxZone
            let hours = flexBoxZone.replace(/^0+/, '');
            let hoursLabel = translateFilter('views.FlexBox.TimeSpecific.Hours', hours);

            if (flexBoxZone === constants.flexBoxZone[1]) {
                return translateFilter(this.firstLetterToLowerCase(inFlexBoxText), hourLabel);
            } else {
                return translateFilter(this.firstLetterToLowerCase(inFlexBoxText), hoursLabel);
            }
        } else {
            // return default text when user isn't in flexBox context or zone is unknown or zero
            return translateFilter(defaultText, hourLabel + '*');
        }
    }
}

export default new FlexBoxService();
