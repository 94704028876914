var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProductTileWrapper',{attrs:{"product":_vm.product,"select-default-variant":true},on:{"priceLoaded":function($event){return _vm.priceLoaded($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var selectedVariant = ref.selectedVariant;
var isOrderable = ref.isOrderable;
var productUrl = ref.productUrl;
var trackProductClick = ref.trackProductClick;
return [_c('div',{directives:[{name:"track-product",rawName:"v-track-product",value:(_vm.trackCurrentProduct),expression:"trackCurrentProduct"}],staticClass:"flex items-center border-t border-grey-300 py-7"},[_c('div',{staticClass:"flex w-1/12 justify-center"},[_c('ImageWithFallback',{attrs:{"source":_vm.product.thumbnailImage,"classes":'max-h-37'}})],1),_c('div',{staticClass:"flex w-3/4 flex-col lg:flex-row"},[_c('div',{staticClass:"ml-10 lg:w-2/3 lg:self-center lg:truncate"},[_c('router-link',{staticClass:"font-semibold text-black",attrs:{"to":productUrl},nativeOn:{"click":function($event){return trackProductClick($event)}}},[_vm._v("\n                    "+_vm._s(_vm.product.name)+"\n                ")])],1),(selectedVariant && selectedVariant.ids)?_c('p',{staticClass:"ml-10 lg:w-1/3 lg:self-center lg:truncate"},[(selectedVariant.ids.customerProductId)?_c('span',[_vm._v("\n                    "+_vm._s(_vm.$tr(_vm.$labels.Product.CustomerProductId))+":\n                    "+_vm._s(selectedVariant.ids.customerProductId)+"\n                ")]):_vm._e(),(!selectedVariant.ids.customerProductId && selectedVariant.ids.vvsId)?_c('span',[_vm._v("\n                    "+_vm._s(_vm.$tr(_vm.$labels.Product.VvsId))+": "+_vm._s(selectedVariant.ids.vvsId)+"\n                ")]):_vm._e(),(
                        !selectedVariant.ids.customerProductId &&
                            !selectedVariant.ids.vvsId &&
                            selectedVariant.ids.variantId
                    )?_c('span',[_vm._v("\n                    "+_vm._s(_vm.$tr(_vm.$labels.Product.ProductId))+": "+_vm._s(selectedVariant.ids.variantId)+"\n                ")]):_vm._e()]):_vm._e()]),(_vm.loginInformation.canPurchase && isOrderable)?_c('div',{staticClass:"ml-10 flex justify-end lg:w-1/6"},[_c('div',{staticClass:"flex justify-end overflow-hidden rounded-4 rounded-br-full rounded-tr-full shadow-harder"},[_c('div',{staticClass:"flex items-center bg-grey-100 px-10 text-12"},[_c('span',{staticClass:"inline-block pr-2"},[_vm._v("\n                        "+_vm._s(_vm.product.defaultQuantity)+"\n                    ")]),_c('span',[_vm._v("\n                        "+_vm._s(_vm.product.variants[0].orderableUnits[0].displayName)+"\n                    ")])]),_c('div',[_c('ButtonSubmit',{attrs:{"type":"button","success":_vm.success,"pending":_vm.addToBasketPending,"custom-classes":'c-btn--primary px-15 rounded-none min-h-37',"icon":_vm.isInFlexBoxContext ? 'sanicon-flexbox' : 'sanicon-ico-basket'},on:{"click":function($event){$event.preventDefault();return _vm.addToBasket(_vm.product)}}})],1)])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }