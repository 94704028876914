
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
    ProductTileViewObject,
    KeyAndValue,
    AvailabilityStatus,
    PriceViewObject
} from '@/types/serverContract';
import ProductTile from '@/project/products/ProductTile.vue';
import { AppGetter } from '@/store/app';

@Component({
    components: {
        ProductTile
    }
})
export default class SearchProductPromotions extends Vue {
    @Prop({
        type: Array
    })
    products!: ProductTileViewObject[];

    @Prop({
        default: 0,
        type: Number
    }) numberOfProducts!: number;

    @AppGetter isLoggedIn!: boolean;

    productStockStatus: KeyAndValue<string, AvailabilityStatus>[] = [];

    get showPromotions() {
        return this.isLoggedIn
            ? this.filteredProducts && this.filteredProducts.length
            : true;
    }

    get filteredProducts() {
        if (this.isLoggedIn) {
            let productsInStock = this.products.filter(product => {
                let stockStatus = this.productStockStatus.find(x => x.key === product.id);
                return (
                    stockStatus === undefined || stockStatus.value === AvailabilityStatus.InStock
                );
            });
            return productsInStock.slice(0, this.numberOfProducts);
        } else {
            return this.products.slice(0, this.numberOfProducts);
        }
    }

    async priceLoaded(price: PriceViewObject) {
        this.productStockStatus.push({
            key: price.productId,
            value: price.stockStatus.status
        });
    }
}
