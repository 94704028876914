














import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
    PriceServiceMixin,
    PriceServiceViewObject
} from '@/project/products/PriceServiceMixin.mixin';

@Component
export default class OrderSummaryVat extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({
        default: '',
        type: String
    }) netVat!: string;

    @Prop({
        default: '',
        type: String
    }) grossVat!: string;

    price: PriceServiceViewObject = {
        netPrice: this.netVat,
        grossPrice: this.grossVat
    };
}
