
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Player from '@vimeo/player';

@Component
export default class VimeoPlayer extends Vue {
    @Prop({
        default: '',
        type: String
    }) videoId!: string;

     @Prop({
         default: false,
         type: Boolean
     }) autoplay!: boolean;

     error = false;
     vimeoPlayer: Player;
     isVimeoPlayerReady = false;
     isVimeoScriptLoaded = false;
     userInteracted = false;
     VimeoPlayerWasVisible = false;

     mounted() {
         this.initVimeo();
     }

    @Watch('isVimeoPlayerReady')
     onVimeoPlayerReadyChange() {
         if (this.isVimeoPlayerReady && this.vimeoPlayer && this.VimeoPlayerWasVisible && this.autoplay) {
             this.vimeoPlayer.play();
             this.VimeoPlayerWasVisible = false;
         }
     }

    onVimeoVisible() {
        this.VimeoPlayerWasVisible = true;
        if (this.isVimeoPlayerReady && this.vimeoPlayer && this.autoplay && !this.userInteracted) {
            this.vimeoPlayer.play();
            this.VimeoPlayerWasVisible = false;
        }
    }

    onVimeoHidden() {
        if (this.isVimeoPlayerReady && this.vimeoPlayer) {
            if (!this.userInteracted) {
                this.vimeoPlayer.pause();
            }
        }
    }

    initVimeo() {
        this.vimeoPlayer = new Player(this.$refs.vimeoPlayer, {
            id: this.videoId
        });
        this.vimeoPlayer.ready()
            .then(() => this.onVimeoPlayerReady())
            .catch(() => { this.error = true; });
        this.vimeoPlayer.on('volumechange', (evt) => this.onVimeoPlayerVolumeChange(evt));
    }

    onVimeoPlayerReady() {
        this.vimeoPlayer.setVolume(0); // mute video as default
        this.isVimeoPlayerReady = true;
    }

    onVimeoPlayerVolumeChange(evt) {
        if (!this.userInteracted && evt.volume > 0) {
            this.userInteracted = true;
        }
    }
}
