






import Flickity from 'flickity';
import 'flickity-imagesloaded';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FlickityWrap extends Vue {
    @Prop({
        type: Object,
        default: function() {
            return {
                draggable: true,
                prevNextButtons: true,
                pageDots: false,
                contain: true,
                groupCells: true,
                imagesLoaded: true,
                accessibility: false,
                dragThreshold: 20,
                cellAlign: 'left'
            };
        }
    })
    options;

    @Prop({ default: true }) selectClickedSlide!: boolean;
    @Prop({ default: true }) disableClickOnDrag!: boolean;

    private $flickity: any;
    private lastSelectedCellIndex = -1;

    created() {
        Flickity.prototype.deactivate = function() {
            if (!this.isActive) {
                return;
            }
            this.element.classList.remove('flickity-enabled');
            this.element.classList.remove('flickity-rtl');
            this.unselectSelectedSlide();
            // destroy cells
            this.cells.forEach(function(cell) {
                cell.destroy();
            });
            this.element.removeChild(this.viewport);
            // move child elements back into element
            // moveElements( this.slider.children, this.element ); Causes mem-leak! Carsten
            if (this.options.accessibility) {
                this.element.removeAttribute('tabIndex');
                this.element.removeEventListener('keydown', this);
            }
            // set flags
            this.isActive = false;
            this.emitEvent('deactivate');
        };
    }

    mounted() {
        this.init();
    }

    destroyed() {
        // Hackity-hack. If Flickity is part of something being animated out, 'destroyed' will be called
        // _before_ transition ends and so the flickity component falls apart.
        // https://github.com/vuejs/vue/issues/6983
        setTimeout(() => {
            this.$flickity.destroy();
            this.$flickity = null;
        }, 5000);
    }

    /**
     * Initialize a new flickity and emit init event.
     */
    init() {
        this.$flickity = new Flickity(this.$el, this.options);

        // Emit select event when selection changes (arrows) or when clicked.
        this.$flickity.on('select', cellIndex => {
            // Flickity is sometimes spamming with select calls. Only send on change.
            if (this.lastSelectedCellIndex !== cellIndex) {
                this.lastSelectedCellIndex = cellIndex;
                this.$emit('select', cellIndex);
            }
        });

        if (this.selectClickedSlide) {
            // this.$flickity.on('staticClick', (event, pointer, cellElement, cellIndex) => this.$flickity.select(cellIndex));
        }

        if (this.disableClickOnDrag) {
            this.$flickity.on('dragStart', () => {
                this.$el.classList.add('pointer-events-none');
                // document.ontouchmove = e => { e.preventDefault(); };
            });
            this.$flickity.on('dragEnd', () => {
                this.$el.classList.remove('pointer-events-none');
                // document.ontouchmove = e => { return true; };
            });
        }

        this.$emit('init', this.$flickity);
    }

    /**
     * Reinitialize (e.g. on new collection of children)
     */
    reinit() {
        this.destroy();
        this.init();
    }

    /**
     * Return the current flickity instance to access directly
     *
     * @return {Flickity}
     */
    flickity() {
        return this.$flickity;
    }

    /**
     * Selecting Slides
     */
    /**
     * Select a slide
     *
     * @param {number} index
     * @param {boolean} isWrapped
     * @param {boolean} isInstant
     */
    select(index, isWrapped, isInstant) {
        this.$flickity.select(index, isWrapped, isInstant);
    }

    /**
     * Change to the next slide
     *
     * @param {boolean} isWrapped
     * @param {boolean} isInstant
     */
    next(isWrapped, isInstant) {
        this.$flickity.next(isWrapped, isInstant);
    }

    /**
     * Change to the previous slide
     *
     * @param {boolean} isWrapped
     * @param {boolean} isInstant
     */
    previous(isWrapped, isInstant) {
        this.$flickity.previous(isWrapped, isInstant);
    }

    /**
     * Select a cell
     *
     * @param {number} value
     * @param {boolean} isWrapped
     * @param {boolean} isInstant
     */
    selectCell(value, isWrapped, isInstant) {
        this.$flickity.selectCell(value, isWrapped, isInstant);
    }

    /**
     * Sizing and Positioning
     */
    /**
     * Trigger a resize event
     */
    resize() {
        this.$flickity.resize();
    }

    /**
     * Trigger a reposition event
     */
    reposition() {
        this.$flickity.reposition();
    }

    /**
     * Adding and removing cells
     */
    /**
     * Prepend elements to flickity
     *
     * @param {array|HTMLElement|NodeList} elements
     */
    prepend(elements) {
        this.$flickity.prepend(elements);
    }

    /**
     * Append elements to flickity
     *
     * @param {array|HTMLElement|NodeList} elements
     */
    append(elements) {
        this.$flickity.append(elements);
    }

    /**
     * Insert elements at a given index
     *
     * @param {array|HTMLElement|NodeList} elements
     * @param {number} index
     */
    insert(elements, index) {
        this.$flickity.insert(elements, index);
    }

    /**
     * Remove elements from flickity
     *
     * @param {array|HTMLElement|NodeList} elements
     */
    remove(elements) {
        this.$flickity.remove(elements);
    }

    /**
     * Player
     */
    /**
     * Trigger a playPlayer event
     */
    playPlayer() {
        this.$flickity.playPlayer();
    }

    /**
     * Trigger a stopPlayer event
     */
    stopPlayer() {
        this.$flickity.stopPlayer();
    }

    /**
     * Trigger a pausePlayer event
     */
    pausePlayer() {
        this.$flickity.pausePlayer();
    }

    /**
     * Trigger a unpausePlayer event
     */
    unpausePlayer() {
        this.$flickity.unpausePlayer();
    }

    /**
     * Trigger a rerender event
     */
    rerender() {
        this.$flickity.destroy();
        this.init();
    }

    /**
     * Utilities
     */
    /**
     * Destroy the flickity instance
     */
    destroy() {
        this.$flickity.destroy();
    }

    /**
     * Trigger a rerender event
     */
    reloadCells() {
        this.$flickity.reloadCells();
    }

    /**
     * Get the cell elements
     *
     * @return {array}
     */
    getCellElements() {
        return this.$flickity.getCellElements();
    }

    /**
     * Return flickity data
     *
     * @return {Flickity}
     */
    flickityData() {
        return Flickity.data(this.$el);
    }

    /**
     * Events
     */
    /**
     * Attach an event
     *
     * @param {string} eventName
     * @param {function} listener
     */
    on(eventName, listener) {
        this.$flickity.on(eventName, listener);
    }

    /**
     * Remove an event
     *
     * @param {string} eventName
     * @param {function} listener
     */
    off(eventName, listener) {
        this.$flickity.off(eventName, listener);
    }

    /**
     * Attach an event once
     *
     * @param {string} eventName
     * @param {function} listener
     */
    once(eventName, listener) {
        this.$flickity.once(eventName, listener);
    }

    /**
     * Properties
     */
    /**
     * Return the selected element
     *
     * @return {HTMLElement}
     */
    selectedElement() {
        return this.$flickity.selectedElement;
    }

    /**
     * Return the selected elements
     *
     * @return {array}
     */
    selectedElements() {
        return this.$flickity.selectedElements;
    }

    /**
     * Return the selected index
     *
     * @return {number}
     */
    selectedIndex() {
        return this.$flickity.selectedIndex;
    }

    /**
     * Return the cells
     *
     * @return {array}
     */
    cells() {
        return this.$flickity.cells;
    }

    /**
     * Return the slides
     *
     * @return {array}
     */
    slides() {
        return this.$flickity.slides;
    }

    /**
     * Disable dragging of slider
     */
    disableDrag() {
        this.$flickity.options.draggable = false;
        this.$flickity.updateDraggable();
    }

    /**
     * Enable dragging of slider
     */
    enableDrag() {
        this.$flickity.options.draggable = true;
        this.$flickity.updateDraggable();
    }
}
