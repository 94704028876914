





















































































import Component from 'vue-class-component';
import { Mixins, Prop } from 'vue-property-decorator';
import {
    PriceViewObject,
    VariantDetailsViewObject
} from '@/types/serverContract';
import {
    PriceServiceViewObject,
    PriceServiceMixin
} from '@/project/products/PriceServiceMixin.mixin';

@Component
export default class ProductDetailsPrices extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({
        type: Object,
        required: true
    }) selectedVariant!: VariantDetailsViewObject;

    @Prop({
        type: Array,
        required: true
    }) prices!: PriceViewObject[];

    price: PriceServiceViewObject = {
        netPrice: this.currentPrice.netPrice,
        grossPrice: this.currentPrice.grossPrice
    };

    get currentPrice(): PriceViewObject {
        return this.prices[0];
    }

    get hasAllowances(): boolean {
        return this.shouldShowNetPrice && this.currentPrice.allowances && this.currentPrice.allowances.length >= 1;
    }
}
