











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { DIRECTION } from '@/core/enums/direction';
import { SPACINGS, USER_VISIBILITY } from '@/core/enums/enums';
import { AppGetter } from '@/store/app';
import { isNullOrWhitespace } from '@/project/config/utilities';

@Component
export default class SectionWrapper extends Vue {
    @Prop({ type: String, default: 'FFFFFF' }) backgroundColor!: string;
    @Prop({ type: String, default: SPACINGS.SMALL }) spacing!: string;
    @Prop({ type: String, default: '' }) innerClass!: string;
    @Prop({ type: String, default: USER_VISIBILITY.Both }) userVisbility!: string;
    @Prop({
        type: String,
        default: 'None',
        validator: (value: string) => ['None', 'Horizontal', 'Vertical'].indexOf(value) > -1
    }) direction!: string;

    @AppGetter isLoggedIn!: boolean;

    get showContentToUser() {
        if (this.userVisbility === USER_VISIBILITY.Both) {
            return true;
        } else if (this.isLoggedIn && this.userVisbility === USER_VISIBILITY.LoggedIn) {
            return true;
        } else if (!this.isLoggedIn && this.userVisbility === USER_VISIBILITY.NonLoggedIn) {
            return true;
        }
        return false;
    }

    get sectionClasses() {
        return {
            'section-wrapper': true,
            ['bg-' + this.$helper.hexToColor[this.baseColorIfUndefined(this.backgroundColor)]]: true,
            [`py-${SPACINGS[this.spacing.toUpperCase()]}`]: true
        };
    }

    // Failsafe, since I changed the type from a dropdown to a color picker
    baseColorIfUndefined(value: string) {
        if (isNullOrWhitespace(value)) {
            return '#FFFFFF';
        }
        return '#' + this.backgroundColor.toUpperCase();
    }

    get innerClasses() {
        return {
            'o-container section-wrapper__inner': true,
            [this.direction === DIRECTION.HORIZONTAL ? 'flex-row' : 'flex-col']: true,
            [this.innerClass]: true
        };
    }
}
