




























































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash-es';
import {
    AddressLookupViewModel,
    AddressSuggestionsViewModel,
    CreateDeliveryAddressRequest,
    DeliveryType,
    DeliveryAddressViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import keyboardService from '@/core/keyCodes';

const SuggestDebounceDelay = 300;

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class FlexBoxDeliveryAddressAddAddress extends Vue {
    searchTerm: string = '';
    markedAddressIndex: number | null = null;
    addressSuggestions: AddressSuggestionsViewModel | null = null;
    showSuggestions: boolean = false;
    searchFieldIcon: string = 'sanicon-ico-search search-input-icon';
    selectedAddress: AddressLookupViewModel | null = null;
    pendingSuggestions: boolean = false;
    pendingSelectAddress: boolean = false;
    pendingSubmit: boolean = false;
    deliveryMethodId: number = DeliveryType.FLEXBOX;
    minNoOfCharsToSearch: number = 3;
    companyName: string = '';
    contactPerson: string = '';

    debouncedSearch = debounce(this.searchAddress, SuggestDebounceDelay);

    get selectedAddressDisplayNameAddress(): string {
        if (!this.selectedAddress) return '';

        let displayName = this.selectedAddress.street;
        displayName += this.selectedAddress.number ? ` ${this.selectedAddress.number}` : '';
        displayName += this.selectedAddress.floor ? `, ${this.selectedAddress.floor}.` : '';
        displayName += this.selectedAddress.door ? ` ${this.selectedAddress.door}` : '';

        return displayName;
    }

    get selectedAddressDisplayNameCity(): string {
        if (!this.selectedAddress) return '';

        const displayName = `${this.selectedAddress.postalCode} ${this.selectedAddress.city}`;

        return displayName;
    }

    get selectedAddressDisplayNameCitySubdivision(): string {
        if (!this.selectedAddress) return '';
        return this.selectedAddress.citySubdivision;
    }

    public $refs!: {
        inputCtrl: Vue;
    };

    @Watch('searchTerm')
    handleSearchFieldIcon() {
        this.searchFieldIcon = this.searchTerm.length <= 0 ? 'sanicon-ico-search search-input-icon' : '';
    }

    async submit() {
        if (this.selectedAddress === null) {
            return false;
        }
        this.pendingSubmit = true;
        try {
            let oldDeliveryAddresses = await this.getDeliveryAddresses();
            await this.addDeliveryAddress();
            let newDeliveryAddresses = await this.getDeliveryAddresses();
            if (oldDeliveryAddresses && newDeliveryAddresses) {
                // filter out the newly created address
                let newAddress = newDeliveryAddresses.addresses
                    .filter(adr => oldDeliveryAddresses.addresses
                        .every(oldAdr => oldAdr.id !== adr.id)
                    );
                if (newAddress && newAddress.length === 1) {
                    this.$emit('createdNewAddress', newAddress[0]);
                }
            }
        } catch (e) {
            // Ok for validation-msgs.
        } finally {
            this.pendingSubmit = false;
        }
    }

    async addDeliveryAddress() {
        if (this.selectedAddress === null) return;
        try {
            const newDeliveryAddress: CreateDeliveryAddressRequest = {
                city: this.selectedAddress.city,
                deliveryMethodId: this.deliveryMethodId,
                floor: this.selectedAddress.floor,
                door: this.selectedAddress.door,
                name: this.companyName,
                name2: this.contactPerson,
                postalCode: this.selectedAddress.postalCode,
                streetName: this.selectedAddress.street,
                streetNumber: this.selectedAddress.number,
                citySubdivisionName: this.selectedAddress.citySubdivision
            };
            await Api.deliveryAddress.addDeliveryAddress(newDeliveryAddress);
        } catch (e) {
            // Ok for validation-msgs.
        }
    }

    async getDeliveryAddresses(): Promise<DeliveryAddressViewModel> {
        try {
            return await Api.deliveryAddress.getDeliveryAddresses(DeliveryType.DAG);
        } finally { }
    }

    async searchAddress() {
        if (this.pendingSubmit) return;

        try {
            if (this.searchTerm.length >= this.minNoOfCharsToSearch) {
                this.pendingSuggestions = true;

                this.addressSuggestions = await Api.addressLookup.getAddressSuggestions(this.searchTerm);
                this.markedAddressIndex = null;

                if (window.document.activeElement === this.$refs.inputCtrl.$refs.input) {
                    this.markedAddressIndex = 0;
                    this.setShowSuggestions(true);
                }
            }
        } finally {
            this.pendingSuggestions = false;
        }
    }

    async selectAddress(addressIndex?: number) {
        if (addressIndex === undefined && this.markedAddressIndex !== null) {
            addressIndex = this.markedAddressIndex;
        }

        this.pendingSelectAddress = true;
        try {
            if (this.addressSuggestions !== null && addressIndex !== undefined) {
                this.selectedAddress = await Api.addressLookup.getAddressInformation(this.addressSuggestions.suggestions[addressIndex].id);
                this.markedAddressIndex = addressIndex; // set marked address if a suggestion is clicked
                this.setShowSuggestions(false);
            }
        } finally {
            this.pendingSelectAddress = false;
        }
    }

    cancel(): void {
        this.$emit('cancel');
    }

    clickOutside(): void {
        this.setShowSuggestions(false);
    }

    onSearchFocus(): void {
        if (this.addressSuggestions !== null && this.addressSuggestions.suggestions.length !== 1) {
            this.setShowSuggestions(true);
        }
    }

    enterPressed(event: KeyboardEvent) {
        event.preventDefault();
        if (this.addressSuggestions === null) {
            this.submit();
            return;
        }

        if (this.markedAddressIndex === null && this.addressSuggestions) {
            this.markedAddressIndex = 0;
        } else {
            this.selectAddress();
            this.$validator.validate();
        }
    }

    escPressed(event: KeyboardEvent) {
        event.stopPropagation();
        this.setShowSuggestions(false);
        this.markedAddressIndex = null;
    }

    handleKeyUp(event: KeyboardEvent) {
        // Use keyup to allow term to change
        if (keyboardService.isEscape(event)) {
            this.escPressed(event);
        } else if (keyboardService.isEnter(event)) {
            this.enterPressed(event);
        } else if (keyboardService.isArrowKeyPress(event)) {
            // prevent search from triggering when using arrow keys to navigate has to be return false otherwise lint removes the return
            return false;
        } else if (this.searchTerm.length >= this.minNoOfCharsToSearch) {
            this.debouncedSearch();
        } else {
            this.setShowSuggestions(false);
        }
    }

    handleKeyDown(event: KeyboardEvent) {
        // Use keydown to allow to hold key and move fast
        if (keyboardService.isDownArrow(event)) {
            event.preventDefault(); // handles caret position
            this.updateMarkedAddress('down');
        } else if (keyboardService.isUpArrow(event)) {
            event.preventDefault(); // handles caret position
            this.updateMarkedAddress('up');
        }
    }

    isSelectedAddress(addressIx: number, markedItemIndex: number) {
        return markedItemIndex === addressIx;
    }

    setShowSuggestions(state: boolean): void {
        this.showSuggestions = state;
    }

    updateMarkedAddress(direction: 'up' | 'down'): void {
        if (direction === 'down') {
            if (this.markedAddressIndex === null) {
                if (this.addressSuggestions) {
                    this.markedAddressIndex = 0;
                }
                return;
            }
            this.markedAddressIndex++;
            if (
                this.addressSuggestions &&
                this.markedAddressIndex >= this.addressSuggestions.suggestions.length
            ) {
                this.markedAddressIndex = 0;
            }
        } else {
            if (this.markedAddressIndex === null) {
                if (this.addressSuggestions) {
                    this.markedAddressIndex = this.addressSuggestions.suggestions.length - 1;
                }
                return;
            }
            this.markedAddressIndex--;
            if (this.addressSuggestions && this.markedAddressIndex <= -1) {
                this.markedAddressIndex = this.addressSuggestions.suggestions.length - 1;
            }
        }
        if (this.markedAddressIndex >= 0) {
            this.$refs[`searchSuggestion${this.markedAddressIndex}`][0].scrollIntoView(false);
        }
    }

    // From vee-validate
    vvFields!: any;
}
