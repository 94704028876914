






























































































































import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import ArticlesResult from '@/project/articles/ArticlesResult.vue';
import CallbackPaging from '@/project/shared/CallbackPaging.vue';
import {
    ArticleSearchResponse,
    ArticleViewObject,
    ContentFacetValue,
    ArticleSearchRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import scrollService from '@/core/scroll/scroll.service';
import articlesUrlHelper from './articlesUrlHelper.service';
import tracking from '@/core/tracking/tracking.service';

@Component({
    components: {
        SpinnerOverlay,
        CallbackPaging,
        ArticlesResult
    }
})
export default class ArticlesOverview extends Vue {
    searching = false;
    articleSearchResponse: ArticleSearchResponse | null = null;

    term: string = articlesUrlHelper.getSearchQuery('term');
    selectedManufacturer: ContentFacetValue | null = null;
    selectedProductCategory: ContentFacetValue | null = null;
    selectedTopic: ContentFacetValue | null = null;
    selectedFromYear: ContentFacetValue | null = null;
    selectedToYear: ContentFacetValue | null = null;
    currentPage: number = articlesUrlHelper.getSearchQuery('page') ? parseInt(articlesUrlHelper.getSearchQuery('page'), 10) : 1;
    totalPages: number = 1;

    get productCategories(): ContentFacetValue[] {
        return this.articleSearchResponse ? this.articleSearchResponse.productCategories : [];
    }

    get topics(): ContentFacetValue[] {
        return this.articleSearchResponse ? this.articleSearchResponse.topics : [];
    }

    get publicationYears(): ContentFacetValue[] {
        return this.articleSearchResponse ? this.articleSearchResponse.publicationYear : [];
    }

    get manufacturers(): ContentFacetValue[] {
        return this.articleSearchResponse ? this.articleSearchResponse.manufacturer : [];
    }

    get searchResult(): ArticleViewObject[] {
        return this.articleSearchResponse ? this.articleSearchResponse.results : [];
    }

    get totalResultsCount(): number {
        return this.articleSearchResponse ? this.articleSearchResponse.totalResultCount : 0;
    }

    created() {
        this.search(false);
    }

    @Watch('$route')
    onRouteChange() {
        // track page view when search parameters have changed
        tracking.trackPageView(false);
    }

    async search(resetPageNo = true) {
        if (this.searching) return;

        function prependEmpty(list: ContentFacetValue[]): void {
            list.unshift({
                value: '',
                count: 0,
                displayName: ''
            } as ContentFacetValue);
        }

        try {
            if (resetPageNo) {
                this.currentPage = 1;
            }
            this.searching = true;

            const payload = this.getSearchPayload();
            articlesUrlHelper.setSearchQuery(payload);

            const result = await Api.content.search(payload);

            // prepend empty as first element in dropdowns
            prependEmpty(result.manufacturer);
            prependEmpty(result.productCategories);
            prependEmpty(result.topics);
            prependEmpty(result.publicationYear);
            this.articleSearchResponse = Object.freeze(result);
            this.totalPages = Math.ceil(this.articleSearchResponse.totalResultCount / this.articleSearchResponse.pageSize);

            // select the corresponding values from query in drop downs
            this.selectValues();
            scrollService.scrollToTop();
        } finally {
            this.searching = false;
        }
    }

    getValueDashProductCategories(value: string, displayName: string): string {
        return !value ? displayName : `${value}-${displayName}`;
    }

    selectValues(): void {
        this.selectManufacturer();
        this.selectProductCategory();
        this.selectTopic();
        this.selectFromYear();
        this.selectToYear();
    }

    selectManufacturer(): void {
        const queryManufacturer = articlesUrlHelper.getSearchQuery('manufacturer');
        if (queryManufacturer) {
            this.selectedManufacturer = this.manufacturers.find(o => o.value === queryManufacturer) || null;
        }
    }

    selectProductCategory(): void {
        const queryProductCategory = articlesUrlHelper.getSearchQuery('productCategory');
        if (queryProductCategory) {
            this.selectedProductCategory = this.productCategories.find(o => o.value === queryProductCategory) || null;
        }
    }

    selectTopic(): void {
        const queryTopic = articlesUrlHelper.getSearchQuery('topic');
        if (queryTopic) {
            this.selectedTopic = this.topics.find(o => o.value === queryTopic) || null;
        }
    }

    selectFromYear(): void {
        const queryPublicationYearFrom = articlesUrlHelper.getSearchQuery('publicationYearFrom');
        if (queryPublicationYearFrom) {
            this.selectedFromYear = this.publicationYears.find(o => parseInt(o.value, 10) === parseInt(queryPublicationYearFrom, 10)) || null;
        }
    }

    selectToYear(): void {
        const queryPublicationYearTo = articlesUrlHelper.getSearchQuery('publicationYearTo');
        if (queryPublicationYearTo) {
            this.selectedToYear = this.publicationYears.find(o => parseInt(o.value, 10) === parseInt(queryPublicationYearTo, 10)) || null;
        }
    }

    getSearchPayload(): ArticleSearchRequest {
        let manufacturer = '';
        const queryManufacturer = articlesUrlHelper.getSearchQuery('manufacturer');
        if (this.selectedManufacturer) {
            manufacturer = this.selectedManufacturer.value;
        } else if (queryManufacturer) {
            manufacturer = queryManufacturer;
        }

        let productCategory = '';
        const queryProductCategory = articlesUrlHelper.getSearchQuery('productCategory');
        if (this.selectedProductCategory) {
            productCategory = this.selectedProductCategory.value;
        } else if (queryProductCategory) {
            productCategory = queryProductCategory;
        }

        let topic = '';
        const queryTopic = articlesUrlHelper.getSearchQuery('topic');
        if (this.selectedTopic) {
            topic = this.selectedTopic.value;
        } else if (queryTopic) {
            topic = queryTopic;
        }

        let publicationYearFrom: number | null = null;
        const queryPublicationYearFrom = articlesUrlHelper.getSearchQuery('publicationYearFrom');
        if (this.selectedFromYear) {
            publicationYearFrom = parseInt(this.selectedFromYear.value, 10);
        } else if (queryPublicationYearFrom) {
            publicationYearFrom = parseInt(queryPublicationYearFrom, 10);
        }

        let publicationYearTo: number | null = null;
        const queryPublicationYearTo = articlesUrlHelper.getSearchQuery('publicationYearTo');
        if (this.selectedToYear) {
            publicationYearTo = parseInt(this.selectedToYear.value, 10);
        } else if (queryPublicationYearTo) {
            publicationYearTo = parseInt(queryPublicationYearTo, 10);
        }

        return {
            term: this.term,
            manufacturer: manufacturer,
            productCategory: productCategory,
            topic: topic,
            publicationYearFrom: publicationYearFrom,
            publicationYearTo: publicationYearTo,
            page: this.currentPage
        };
    }

    gotoPage(pageNo: number): void {
        this.currentPage = pageNo;
        this.search(false);
    }
}
