import { render, staticRenderFns } from "./AuthenticationDesktopToggle.vue?vue&type=template&id=18de9b69&scoped=true"
import script from "./AuthenticationDesktopToggle.vue?vue&type=script&lang=ts"
export * from "./AuthenticationDesktopToggle.vue?vue&type=script&lang=ts"
import style0 from "./AuthenticationDesktopToggle.vue?vue&type=style&index=0&id=18de9b69&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18de9b69",
  null
  
)

export default component.exports