





















































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    CreateEasySupplyWarehouseRequest,
    EasySupplyWarehouseDetailViewModel,
    LoginInformation,
    UpdateEasySupplyWarehouseRequest,
    EasySupplyWarehouseFormOptionsViewObject,
    SalesAccountViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { AppGetter } from '@/store/app';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import RedirectException from '@/core/http/redirectException';

interface DeliveryInterval {
    interval: number | null;
}

@Component({
    components: {
        ButtonSubmit
    }
})
export default class EasySupplyStockListEditForm extends Vue {
    @Prop({
        type: Object
    }) warehouseInfo!: EasySupplyWarehouseDetailViewModel;

    @Prop({
        required: true,
        type: Object
    }) formOptions!: EasySupplyWarehouseFormOptionsViewObject;

    @Prop({
        default: false,
        type: Boolean
    }) showCreateAndAdd!: boolean;

    @Prop({
        default: false,
        type: Boolean
    }) showGoBackButton!: boolean;

    @AppGetter loginInformation!: LoginInformation;

    easySupplyWarehouseIdentifier: string = this.warehouseInfo ? this.warehouseInfo.identifier : '';
    identifier: string = '';
    warehouseName: string = this.warehouseInfo ? this.warehouseInfo.name : '';
    deliveryIntervalOptions = this.formOptions.intervals;
    orderReference: string = this.warehouseInfo && this.warehouseInfo.orderReference ? this.warehouseInfo.orderReference : '';
    requisitionNumber: string = this.warehouseInfo && this.warehouseInfo.requisitionNumber ? this.warehouseInfo.requisitionNumber : '';
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    selectedDeliveryInterval: DeliveryInterval = { interval: this.warehouseInfo ? this.warehouseInfo.deliveryInterval : 1 };
    customerLocked: boolean = this.warehouseInfo ? this.warehouseInfo.customerLocked : false;
    active: boolean = this.warehouseInfo ? this.warehouseInfo.active : true;
    createWarehouseUrl: string = '';
    timeout: number | null = null;

    get buttonLabel(): string {
        return this.warehouseInfo ? this.$tr(this.$labels.Views.Services.EasySupply.Edit.Save) : this.$tr(this.$labels.Views.Services.EasySupply.Edit.Add);
    }

    get currentAccount(): SalesAccountViewObject | null {
        return this.loginInformation ? this.loginInformation.currentAccount : null;
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async updateWarehouse() {
        this.pending = true;

        const payload: UpdateEasySupplyWarehouseRequest = {
            easySupplyWarehouseId: this.warehouseInfo.id,
            identifier: this.easySupplyWarehouseIdentifier,
            name: this.warehouseName,
            deliveryInterval: this.selectedDeliveryInterval.interval as number,
            orderReference: this.orderReference,
            requisitionNumber: this.requisitionNumber,
            customerLocked: this.customerLocked,
            active: this.active
        };
        try {
            const easySupplyWarehouseDetailViewModel = await Api.easySupply.updateEasySupplyWarehouse(payload);
            this.showSuccessIndicator();
            this.$emit('updatedWarehouseInfo', easySupplyWarehouseDetailViewModel);
            this.closeForm();
        } finally {
            this.pending = false;
        }
    }

    async createWarehouse() {
        this.pending = true;
        const payload: CreateEasySupplyWarehouseRequest = {
            customerLocked: this.customerLocked,
            active: this.active,
            deliveryInterval: this.selectedDeliveryInterval.interval as number,
            identifier: this.easySupplyWarehouseIdentifier,
            name: this.warehouseName,
            orderReference: this.orderReference,
            requisitionNumber: this.requisitionNumber
        };
        if (this.showCreateAndAdd) {
            try {
                await Api.easySupply.createEasySupplyWarehouse(payload);
                this.showSuccessIndicator();
            } catch (e) {
                // Server here handles redirect
                if (e instanceof RedirectException) {
                    // ignore
                } else {
                    throw e;
                }
            } finally {
                this.pending = false;
            }
        } else {
            try {
                await Api.easySupply.createEasySupplyWarehouse(payload);
                this.showSuccessIndicator();

                this.getEasySupplyWarehouseList().then(() => {
                    this.$router.push(this.createWarehouseUrl);
                    window.location.reload();
                });
            } catch (e) {
                // Server here handles redirect
                if (e instanceof RedirectException) {
                    // ignore
                } else {
                    throw e;
                }
            } finally {
                this.pending = false;
            }
        }
    }

    async getEasySupplyWarehouseList() {
        try {
            let easySupplyWarehouseListViewModel = await Api.easySupply.getEasySupplyWarehouseDetailsList();
            if (easySupplyWarehouseListViewModel && easySupplyWarehouseListViewModel.easySupplyWarehouseList) {
                let easySupplyWarehouseList = easySupplyWarehouseListViewModel.easySupplyWarehouseList;
                if (easySupplyWarehouseList.length > 0) {
                    this.createWarehouseUrl = easySupplyWarehouseList[0].editUrl;
                }
            }
        } finally {
        }
    }

    closeForm(): void {
        this.$emit('closeForm');
    }

    goBack() {
        this.$emit('goBack');
    }

    submitWarehouse() {
        if (this.warehouseInfo) {
            this.updateWarehouse();
        } else {
            this.createWarehouse().then(() => {
                if (this.showCreateAndAdd) {
                    this.$emit('createAndAdd');
                }
            });
        }
    }

    setFieldMask(fieldName, fieldMask) {
        this[fieldName] = fieldMask;
        (this.$refs[fieldName + 'Input'] as HTMLElement).focus();
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors!: any;
}
