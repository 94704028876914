





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ReplaceEasySupplyGatewayRequest } from '@/types/serverContract';
import Api from '@/project/http/api';
import Constants from '@/project/config/constants';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class ReplaceGateway extends Vue {
    @Prop({
        required: true,
        type: String
    }) hardwareIdentifier!: string;

    @Prop({
        required: true,
        type: Number
    }) warehouseDetailId!: number;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number = 0;
    newGatewayId: string = '';

    get macAddressMask(): object {
        return Constants.MacAddressMask;
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async replaceGateway(): Promise<void> {
        if (this.pending) return;

        this.pending = true;

        const payload: ReplaceEasySupplyGatewayRequest = {
            easySupplyWarehouseId: this.warehouseDetailId,
            currentGatewayIdentifier: this.hardwareIdentifier,
            newGatewayIdentifier: this.newGatewayId
        };

        try {
            await Api.easySupply.replaceEasySupplyProcuredProduct(payload);
            this.showSuccessIndicator();
            this.$emit('updatedWarehouse');
            this.closeModal();
        } finally {
            this.pending = false;
        }
    }

    hideReplaceGateway(): void {
        this.$emit('hideReplaceGateway');
    }

    closeModal() {
        this.$emit('closeModal');
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors!: any;
}
