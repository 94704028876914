


































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
    PriceServiceViewObject,
    PriceServiceMixin
} from '@/project/products/PriceServiceMixin.mixin';
import { AllowanceCalculationViewObject } from '@/types/serverContract';

@Component
export default class LineItemPriceTotal extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({
        default: '',
        type: String
    }) lineNetTotal!: string;

    @Prop({
        default: '',
        type: String
    }) lineGrossTotal!: string;

    @Prop({
        default: () => [],
        type: Array
    }) lineAllowances!: AllowanceCalculationViewObject[];

    price: PriceServiceViewObject = {
        netPrice: this.lineNetTotal,
        grossPrice: this.lineGrossTotal
    };

    get hasAllowances(): boolean {
        return this.shouldShowNetPrice && this.lineAllowances && this.lineAllowances.length >= 1;
    }

    @Watch('lineNetTotal')
    onLineNetTotalChange(): void {
        this.price.netPrice = this.lineNetTotal;
    }

    @Watch('lineGrossTotal')
    onLineGrossTotalChange(): void {
        this.price.grossPrice = this.lineGrossTotal;
    }
}
