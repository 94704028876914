




























































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    AddOrUpdateSpecialOrderLineItemRequest,
    AddToBasketRequest,
    DeleteFavoriteOrderLineRequest,
    FavoriteOrderLineItemViewObject,
    PriceRequest,
    PriceViewObject,
    UnitViewObject,
    UpdateFavoriteOrderLineItemRequest,
    UpdateFavoriteOrderSpecialLineItemRequest,
    CaseInformationRequest
} from '@/types/serverContract';
import ProductPrices from '@/project/products/ProductPrices.vue';
import Api from '@/project/http/api';
import SpecialProductTileAddToBasket from '@/project/products/SpecialProductTileAddToBasket.vue';
import priceService from '@/project/products/price.service';
import Constants from '@/project/config/constants';
import ProductAddToBasket from '@/project/products/ProductAddToBasket.vue';
import trackingService from '@/core/tracking/tracking.service';
import { debounce } from 'lodash-es';
import productTrackingService from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';

const ProductAddToBasketChangeDebounceDelay = 200;

@Component({
    components: {
        ProductAddToBasket,
        ProductPrices,
        SpecialProductTileAddToBasket
    }
})
export default class FavoriteOrderProductTile extends Vue {
    @Prop({ required: true, type: Object }) lineItem!: FavoriteOrderLineItemViewObject;
    @Prop({ required: true, type: Object }) caseInformation!: CaseInformationRequest;
    @Prop({ required: true, type: Number }) favoriteOrderId!: number;
    pricesPending: boolean = false;
    price: PriceViewObject | null = null;
    updatePending: boolean = false;
    fixedUnitQty: number | null = this.lineItem.selectedUnit.isFixedUnit ? this.lineItem.fixedUnitValue || 0 : null;
    updatePromise: Promise<void> | null = null;
    debouncedProductAddToBasketChange = debounce(this.onProductAddToBasketChange, ProductAddToBasketChangeDebounceDelay);

    get specialLineItemPayload(): AddOrUpdateSpecialOrderLineItemRequest {
        return {
            lineItemId: null,
            manufacturer: this.lineItem.isSpecialLineItem ? this.lineItem.manufacturer : '',
            productItemNumber: this.lineItem.isSpecialLineItem ? this.lineItem.productItemNumber : '',
            productText: this.lineItem.isSpecialLineItem ? this.lineItem.productText : '',
            quantity: this.lineItem.isSpecialLineItem ? this.lineItem.quantity : 1,
            supplementaryProductText: this.lineItem.isSpecialLineItem ? this.lineItem.supplementaryProductText : '',
            unitCode: this.lineItem.isSpecialLineItem ? this.lineItem.selectedUnit.unitCode : '',
            caseInformation: this.caseInformation
        };
    };

    get hasPrimaryProperties(): boolean {
        return this.lineItem.primaryProperties && this.lineItem.primaryProperties.length >= 1;
    }

    get hasVariantProperties(): boolean {
        return this.lineItem.variantProperties && this.lineItem.variantProperties.length >= 1;
    }

    get orderableUnits(): UnitViewObject[] | {fixedUnitQuantity: object; isFixedUnit: boolean; unitCode: string}[] {
        if (this.lineItem.isSpecialLineItem) {
            const arr: {fixedUnitQuantity: object; isFixedUnit: boolean; unitCode: string}[] = [];
            arr.push({
                unitCode: this.lineItem.selectedUnit.unitCode,
                isFixedUnit: false,
                fixedUnitQuantity: {}
            });
            return arr;
        }
        return this.lineItem.availableUnits;
    }

    get currentOrderUnit(): UnitViewObject {
        const currentOrderUnitFound = this.lineItem.availableUnits ? this.lineItem.availableUnits.find((unit) => {
            return this.lineItem.selectedUnit.unitKey === unit.unitKey;
        }) : this.lineItem.selectedUnit;

        return this.lineItem.availableUnits && currentOrderUnitFound ? currentOrderUnitFound : this.lineItem.selectedUnit;
    }

    created() {
        this.getPricesForProduct(this.lineItem);
    }

    async removeLineFromOrder(productId: number) {
        if (!this.lineItem.allowEdit) {
            return;
        }

        const payload: DeleteFavoriteOrderLineRequest = {
            favoriteOrderId: this.favoriteOrderId,
            lineId: productId,
            isSpecialLineItem: this.lineItem.isSpecialLineItem
        };

        try {
            await Api.favoriteOrder.deleteLineItem(payload).then(() => this.$emit('deleteSuccess'));
        } finally {
        }
    }

    async updateSpecialLineItem() {
        this.updatePending = true;

        const updateFavoriteOrderSpecialLinePayload: UpdateFavoriteOrderSpecialLineItemRequest = {
            lineItemId: this.lineItem.id,
            displayName: '',
            quantity: this.specialLineItemPayload.quantity,
            favoriteOrderId: this.favoriteOrderId,
            fixedUnitValue: null,
            certificateCode: null,
            unitCode: this.lineItem.selectedUnit.unitCode,
            manufacturer: this.lineItem.manufacturer,
            productItemNumber: this.lineItem.productItemNumber,
            productText: this.lineItem.productText,
            supplementaryProductText: this.lineItem.supplementaryProductText
        };

        try {
            await Api.favoriteOrder.updateSpecialLineItem(updateFavoriteOrderSpecialLinePayload);
        } finally {
            this.updatePending = true;
        }
    }

    async getPricesForProduct(lineItem: FavoriteOrderLineItemViewObject | null) {
        if (!lineItem) {
            this.price = null;
            return;
        }
        this.pricesPending = true;

        try {
            const priceRequest: PriceRequest = {
                id: lineItem && lineItem.productId ? lineItem.productId : '',
                availabilityType: lineItem ? lineItem.availabilityType : 0,
                certificateCode: lineItem ? lineItem.availableCertificate : '',
                isSurplus: lineItem ? lineItem.isSurplus : false,
                isTemporarilyPhasedOut: lineItem ? lineItem.isTemporarilyPhasedOut : false,
                isVendorUnableToDeliver: lineItem ? lineItem.isVendorUnableToDeliver : false
            };

            this.price = await priceService.getPrice(priceRequest);
        } finally {
            this.pricesPending = false;
        }
    }

    onProductAddToBasketChange(payload: AddToBasketRequest) {
        if (payload === null) {
            return;
        }
        this.updatePending = true;
        const updateFavoriteOrderLineItemPayload: UpdateFavoriteOrderLineItemRequest = {
            lineItemId: this.lineItem.id,
            favoriteOrderId: this.favoriteOrderId,
            productId: this.lineItem.productId,
            quantity: payload.quantity,
            unitCode: payload.unit,
            certificateCode: payload.certificateCode,
            isFixedUnit: payload.isFixedUnit,
            fixedUnitValue: payload.fixedUnitValue,
            fixedUnitCode: payload.fixedUnitCode,
            stamping: payload.stamping
        };

        try {
            Api.favoriteOrder.updateLineItem(updateFavoriteOrderLineItemPayload);
        } finally {
            this.updatePending = false;
        }
    }

    onSpecialLineItemQtyChanged(specialLineItemQtyChanged: number): void {
        this.specialLineItemPayload.quantity = specialLineItemQtyChanged;
        this.updateSpecialLineItem();
    }

    getThumbnailImage(productImageUrl: string): string {
        return productImageUrl || Constants.ImageNotFound;
    }

    trackProductClick(): void {
        if (this.lineItem) {
            productTrackingService.TrackProduct(
                PRODUCT_TRACKING_EVENT.ProductClick,
                productTrackingService.ToTrackedProduct(
                    this.lineItem,
                    PRODUCT_TRACKING_TYPE.FavoriteOrderLineItemViewObject,
                    this.price
                )
            );
        }
    }

    // This function to be passed to the tracking directive
    public trackCurrentProduct() {
    // Passed a single product in a list to avoid breaking changes in tracking, ultimately this should be changed
        return trackingService.getAndTrackImpressionsFromFavoriteOrderLineItem([this.lineItem]);
    }
}
