

































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { PriceViewObject, ProductTileTrackingContext, ProductTileViewObject } from '@/types/serverContract';
import ProductVariantIds from '@/project/products/ProductVariantIds.vue';
import { AppGetter } from '@/store/app';
import ProductPrices from '@/project/products/ProductPrices.vue';
import ProductTileWrapper from '@/project/products/ProductTileWrapper.vue';
import ProductInventory from '@/project/products/ProductInventory.vue';
import ProductTileSkeletonPrice from '@/project/shared/skeleton/ProductTile/ProductTileSkeletonPrice.vue';
import ProductVariantPicker from '@/project/products/ProductVariantPicker.vue';
import ProductAddToBasket from '@/project/products/ProductAddToBasket.vue';
import ScalePricesCompact from '@/project/products/ScalePricesCompact.vue';
import ProductCo2Info from '@/project/products/ProductCo2Info.vue';
import EcoCertificationsBadges from '@/project/products/EcoCertificationsBadges.vue';
import SearchProductNudgingTile from '@/project/search/SearchProductNudgingTile.vue';
import scrollService from '@/core/scroll/scroll.service';
import serverContext from '@/core/serverContext.service';
import tracking from '@/core/tracking/tracking.service';

@Component({
    components: {
        ProductCo2Info,
        ProductAddToBasket,
        ProductVariantPicker,
        ProductTileSkeletonPrice,
        ProductInventory,
        ProductTileWrapper,
        ProductVariantIds,
        ProductPrices,
        ScalePricesCompact,
        EcoCertificationsBadges,
        SearchProductNudgingTile
    }
})
export default class SearchProductTile extends Vue {
    @Prop({
        required: true,
        type: Object
    }) product!: ProductTileViewObject;

    @Prop({
        required: true,
        type: String
    }) searchTerm!: string;

    @Prop({
        default: false,
        type: Boolean
    }) selectDefaultVariant!: boolean;

    @AppGetter isLoggedIn!: boolean;
    nudgingShow: boolean = false;
    showNudgingProductIfAny: boolean = true;
    renderNudgingProduct: boolean = false;
    showBorderIfNudging: boolean = false;

    onNudgingShowUpdate(
        value: boolean,
        price: PriceViewObject,
        trackingContext: ProductTileTrackingContext
    ) {
        this.nudgingShow = value;
        if (!this.nudgingShow) {
            return;
        }
        if (price) {
            tracking.trackNudgingForSalesExpand(
                price.netPrice,
                this.product.id,
                trackingContext ? tracking.formatListName(trackingContext) : ''
            );
        }
    }

    onNudgingHasRecommendedProduct(value: boolean) {
        this.showNudgingProductIfAny = value;
        this.showBorderIfNudging = value;
        if (this.showNudgingProductIfAny) {
            this.scrollToNudgingProduct();
        }
    }

    scrollToNudgingProduct() {
        const ProductNudgingTileComponent = this.$refs.ProductNudgingTile as SearchProductNudgingTile;
        const ProductNudgingTileElement = ProductNudgingTileComponent.$el as HTMLElement;
        const ContainerToScrollIn = ProductNudgingTileElement.closest('.content-wrapper') as HTMLElement;

        // scroll to nudging product
        if (this.nudgingShow) {
            scrollService.scrollToElement(ProductNudgingTileComponent.$el as HTMLElement, -100, 200, ContainerToScrollIn);
        } else {
            const ProductNudgingTileComponent = this.$refs.ProductNudgingTile as SearchProductNudgingTile;
            this.$nextTick(() => {
                ProductNudgingTileComponent.toggleShow(ContainerToScrollIn);
            });
        }
    }

    onAddedToBasket() {
        // if there was no nudging product then showNudgingProductIfAny will be set to false in onNudgingHaveRecommendedProduct
        if (!this.renderNudgingProduct && this.showNudgingProductIfAny) {
            // load nudging product if any
            this.renderNudgingProduct = true;
        } else if (this.showNudgingProductIfAny) {
            // if adding multiple items to the basket and we know there is a nudging product then scroll to it
            this.scrollToNudgingProduct();
        }
    }

    get nudgeTypeaheadVisibility(): boolean {
        return this.renderNudgingProduct && this.showNudgingProductIfAny && serverContext.nudgeOnTypeahead;
    }
}
