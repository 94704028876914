import { Vue, Component, Prop } from 'vue-property-decorator';
import { uuid } from '@/project/config/utilities';

@Component
export default class InputCtrlData extends Vue {
    @Prop({ required: false, default: '', type: String }) label!: string; // The header label
    @Prop({ required: true, type: String }) name!: string; // Same thing as the label? Not sure the purpose of it
    @Prop({ default: '', type: String }) constraints!: string; // v-validate constraints?
    @Prop({ default: true, type: Boolean }) showError!: boolean; // If active, if a validator is not fulfilled the textbox will indicate so
    @Prop({ default: false, type: Boolean }) autofocus!: boolean; // Autofocuses on component load
    @Prop({ default: false, type: Boolean }) disabled!: boolean; // Should be disabled by default
    @Prop({ default: '', type: String }) icon!: string; // Prepend an icon and push the formfield to make space for it
    @Prop({ default: '', type: String }) description!: string; // Adds a description below the formfield
    @Prop({ required: false, type: String }) placeholder!: string; // Adds a default placeholder text
    @Prop({ default: '', type: String }) fieldsetClass!: string; // Fieldset class used by children component to style it

    emitTextOnChange(ev: Event) {
        this.$emit('input', (ev.target as HTMLInputElement).value); // This is required for v-model to function
    }

    get id() {
        return this.name + uuid();
    }

    get placeholderShown() {
        return false;
    }

    get required() {
        return this.field && this.field.required;
    }

    get listeners() {
        const { input, blur, focus, ...listeners } = this.$listeners; // eslint-disable-line @typescript-eslint/no-unused-vars
        return listeners;
    }

    get field() {
        return this.vvFields[this.name];
    }

    // From Vue-validate
    private vvFields!: any;
}
