import Constants from '@/project/config/constants';

// Default values for width and quality
const DEFAULT_WIDTH = 2000;
const DEFAULT_QUALITY = 75;
const DEFAULT_FEATURE = 'lossless';
const win = window as any;
export const BROWSER_WEBP_SUPPORT = null;

function isGif(source: string): boolean {
    return source.toLowerCase().endsWith('.gif');
}

function isMediaDomain(source: string): boolean {
    return source.toLowerCase().startsWith('https://media');
}

function isLocalFiles(source: string): boolean {
    return source.toLowerCase().indexOf('layout/gfx') > -1;
}

function isRelativeUrl(url: string): boolean {
    const regex = /^\//;
    return regex.test(url);
}

function getAbsoluteUrl(source: string): URL {
    source = isRelativeUrl(source) ? window.location.origin + source : source;
    let url = new URL(source);
    return url;
}

function setMaxSize(url: URL, width: number, height: number = 0): URL {
    if (!url.searchParams.has('upscale')) {
        url.searchParams.append('upscale', 'false');
    }
    if (!url.searchParams.has('width')) {
        url.searchParams.append('width', width.toString());
    }
    if (height > 0 && !url.searchParams.has('height')) {
        url.searchParams.append('height', height.toString());
    }
    return url;
};

function setQuality(url: URL, quality: number): URL {
    if (!url.searchParams.has('quality')) {
        url.searchParams.append('quality', quality.toString());
    }
    return url;
};

function OptimizedWebpSource(webpSource: string, width: number, quality: number): string {
    // For Pixel Density Switching, a component will receive the same URL back if it sets multiple densities, which is not optimized.
    if (webpSource.indexOf(', ') === -1 && !isMediaDomain(webpSource) && !isLocalFiles(webpSource)) {
        let url = getAbsoluteUrl(webpSource);
        url = setMaxSize(url, width);
        url = setQuality(url, quality);
        return url.href;
    } else {
        return webpSource;
    }
}

async function isWebPSupported(feature: string = DEFAULT_FEATURE): Promise<boolean> {
    return new Promise((resolve) => {
        const kTestImages = {
            lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
            lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
            alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
            animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
        };
        const img = new Image();
        img.onload = () => {
            const result = (img.width > 0) && (img.height > 0);
            resolve(result);
        };
        img.onerror = () => {
            resolve(false);
        };
        img.src = 'data:image/webp;base64,' + kTestImages[feature];
    });
}

function setIsWebPSupported() {
    win.webpSupported = isWebPSupported();
}

function setWebpFormat(url: URL): URL {
    url.searchParams.append('format', 'webp');
    return url;
};

function setMode(url: URL, mode: string): URL {
    if (!url.searchParams.has('mode')) {
        url.searchParams.append('mode', mode);
    }
    return url;
};

function getOptimizedSource(source: string, width: number = DEFAULT_WIDTH, quality: number = DEFAULT_QUALITY): string {
    if (source) {
        if (isMediaDomain(source) || isLocalFiles(source)) {
            return source;
        }

        if (source === Constants.ImageNotFound) {
            return Constants.ImageNotFound;
        }
        let url = getAbsoluteUrl(source);
        url = setMaxSize(url, width);
        url = setQuality(url, quality);
        return url.href;
    }
    return Constants.ImageNotFound;
}

function getWebpSource(source: string, webpSource: string = ''): string {
    if (isMediaDomain(source) || isMediaDomain(webpSource) || isLocalFiles(source) || isLocalFiles(webpSource)) {
        return webpSource || source;
    }

    if (source || webpSource) {
        if (webpSource !== '') {
            return webpSource;
        } else if (source === Constants.ImageNotFound) {
            return Constants.ImageNotFound;
        }

        let url = getAbsoluteUrl(source);
        url = setWebpFormat(url);
        return url.href;
    }
    return Constants.ImageNotFound;
}

function getWebpOptimizedSource(source: string, webpSource: string = '', width: number = DEFAULT_WIDTH, quality: number = DEFAULT_QUALITY): string {
    if (isMediaDomain(source) || isMediaDomain(webpSource) || isLocalFiles(source) || isLocalFiles(webpSource) || isGif(source)) {
        return webpSource || source;
    }

    if (source || webpSource) {
        if (webpSource !== '') {
            return OptimizedWebpSource(webpSource, width, quality);
        } else if (source === Constants.ImageNotFound) {
            return Constants.ImageNotFound;
        }

        let url = getAbsoluteUrl(source);
        url = setWebpFormat(url);
        url = setMaxSize(url, width);
        url = setQuality(url, quality);
        return url.href;
    }
    return Constants.ImageNotFound;
}

function getWebpOptimizedSourceStrictSizeStretch(source: string, webpSource: string = '', width: number, height: number, quality: number = DEFAULT_QUALITY): string {
    if (isMediaDomain(source) || isMediaDomain(webpSource) || isLocalFiles(source) || isLocalFiles(webpSource) || isGif(source)) {
        return webpSource || source;
    }

    if (source || webpSource) {
        if (webpSource !== '') {
            return OptimizedWebpSource(webpSource, width, quality);
        } else if (source === Constants.ImageNotFound) {
            return Constants.ImageNotFound;
        }

        let url = getAbsoluteUrl(source);
        url = setWebpFormat(url);
        url = setMaxSize(url, width, height);
        url = setMode(url, 'stretch');
        url = setQuality(url, quality);
        return url.href;
    }
    return Constants.ImageNotFound;
}

function getWebpCompatibleOptimizedSource(source: string, webpSource: string = '', width: number = DEFAULT_WIDTH, quality: number = DEFAULT_QUALITY): string {
    if (isMediaDomain(source) || isMediaDomain(webpSource) || isLocalFiles(source) || isLocalFiles(webpSource) || isGif(source)) {
        return webpSource || source;
    }

    if (source || webpSource) {
        if (webpSource !== '') {
            return OptimizedWebpSource(webpSource, width, quality);
        } else if (source === Constants.ImageNotFound) {
            return Constants.ImageNotFound;
        }

        let url = getAbsoluteUrl(source);
        if (win.webpSupported) {
            url = setWebpFormat(url);
        }
        url = setMaxSize(url, width);
        url = setQuality(url, quality);
        return url.href;
    }
    return Constants.ImageNotFound;
}

export default {
    setIsWebPSupported,
    getWebpSource,
    getOptimizedSource,
    getWebpOptimizedSource,
    getWebpCompatibleOptimizedSource,
    getWebpOptimizedSourceStrictSizeStretch
};
