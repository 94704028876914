























import Vue from 'vue';
import Component from 'vue-class-component';
import { MessagesAction, MessagesGetter } from '@/store/messages';
import { ClientMessageEx } from '../../store/messages';
import { ClientMessageType } from '@/types/serverContract';
import { Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component
export default class ApiMessages extends Vue {
    @MessagesGetter apiMessages!: ClientMessageEx[];
    @MessagesAction clearApiMessage!: (id: string) => void;

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            this.clearApiMessages();
        }
    }

    backgroundClass(msg: ClientMessageEx) {
        return {
            'bg-red-500': msg.messageType === ClientMessageType.Error,
            'bg-yellow-500': msg.messageType === ClientMessageType.Warning,
            'bg-blue-500': msg.messageType === ClientMessageType.Info,
            'bg-green-500': msg.messageType === ClientMessageType.Success
        };
    }

    clearApiMessages(): void {
        this.apiMessages.forEach(msg => {
            this.clearApiMessage(msg.id);
        });
    }
}
