

















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
    PriceServiceMixin,
    PriceServiceViewObject
} from '@/project/products/PriceServiceMixin.mixin';

@Component
export default class BasketSummaryTotal extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({
        default: '',
        type: String
    }) netTotal!: string;

    @Prop({
        default: '',
        type: String
    }) grossTotal!: string;

    price: PriceServiceViewObject = {
        netPrice: this.netTotal,
        grossPrice: this.grossTotal
    };

    @Watch('netTotal')
    onSubTotalChange(): void {
        this.price.netPrice = this.netTotal;
    }

    @Watch('grossTotal')
    onGrossSubTotalChange(): void {
        this.price.grossPrice = this.grossTotal;
    }
}
