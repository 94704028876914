



































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AddAccountToUserRequest } from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class AddAccountForm extends Vue {
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;
    addAccountPayload: AddAccountToUserRequest = {
        phoneNumber: '',
        soldToPartnerId: ''
    };

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async addAccount() {
        this.pending = true;
        try {
            Api.account.addSalesAccount(this.addAccountPayload).then(result => {
                this.showSuccessIndicator().then(() => {
                    this.$emit('onSubmit', result);
                });
            });
        } finally {
            this.pending = false;
        }
    }

    async showSuccessIndicator(): Promise<void> {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            return new Promise(resolve => {
                this.success = false;
                resolve();
            });
        }, this.successTimer);
    }
}
