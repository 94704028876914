

















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BookkeepingUrlHelper from '@/project/bookkeeping/BookkeepingUrlHelper.service';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import api from '@/project/http/api';
import { OrderCertificateDetailsViewModel } from '@/types/serverContract';
import CertificateOrderDetailsLineItem from '@/project/myServices/CertificateOrderDetailsLineItem.vue';

@Component({
    components: {
        CertificateOrderDetailsLineItem,
        SpinnerOverlay
    }
})
export default class CertificateOrderDetails extends Vue {
    pending: boolean = false;
    orderDetails: OrderCertificateDetailsViewModel | null = null;

    created() {
        this.getCertificateDetails();
    }

    async getCertificateDetails() {
        this.pending = true;
        try {
            this.orderDetails = await api.orderHistory.orderCertificateDetails({
                orderId: BookkeepingUrlHelper.getOrderId()
            });
        } finally {
            this.pending = false;
        }
    }
}
