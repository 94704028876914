













































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ProductTileViewObject } from '@/types/serverContract';
import Api from '@/project/http/api';
import { AppGetter } from '@/store/app';
import ProductTileWrapper from '@/project/products/ProductTileWrapper.vue';
import ProductPrices from '@/project/products/ProductPrices.vue';
import ProductTileSkeletonPrice from '@/project/shared/skeleton/ProductTile/ProductTileSkeletonPrice.vue';

@Component({
    components: {
        ProductTileWrapper,
        ProductPrices,
        ProductTileSkeletonPrice
    }
})
export default class ProductDetailsCanBeReplacedByProduct extends Vue {
    @Prop({
        required: true,
        type: String
    }) hybrisCode!: string;

    @AppGetter isLoggedIn!: boolean;

    pending: boolean = false;
    product: ProductTileViewObject | null = null;

    get productId(): string {
        if (this.product) {
            return this.product.id;
        } else {
            return '';
        }
    }

    created() {
        this.getCanBeReplacedByProduct();
    }

    async getCanBeReplacedByProduct() {
        this.pending = true;

        let ids: string[] = [];
        ids.push(this.hybrisCode);
        let products: ProductTileViewObject[] = [];

        if (!ids.length) {
            this.pending = false;
            return;
        }

        try {
            products = await Api.products.getRelatedProducts(ids);
            this.product = products[0];
        } finally {
            this.pending = false;
        }
    }
}
