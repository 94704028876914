














































































































import Vue from 'vue';
import {
    CreateOrUpdateFavoriteOrderRequest,
    LoginInformation,
    SalesAccountViewObject
} from '@/types/serverContract';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import api from '@/project/http/api';
import { cloneDeep } from 'lodash-es';

@Component
export default class FavoriteOrderEditInformation extends Vue {
    @Prop({
        required: true,
        type: Object
    }) favoriteOrderInformation!: CreateOrUpdateFavoriteOrderRequest;

    @AppGetter loginInformation!: LoginInformation;

    updateFavoriteOrderRequestModel: CreateOrUpdateFavoriteOrderRequest = cloneDeep(this.favoriteOrderInformation);
    pending: boolean = false;

    get currentAccount(): SalesAccountViewObject | null {
        return this.loginInformation ? this.loginInformation.currentAccount : null;
    }

    @Watch('updateFavoriteOrderRequestModel.name')
    updateDisplayName() {
        this.$emit('updateOrderName', this.updateFavoriteOrderRequestModel.name);
    }

    async submitUpdateFavoriteOrder() {
        this.pending = true;
        try {
            await api.favoriteOrder.updateFavoriteOrder(this.updateFavoriteOrderRequestModel).then(() => this.$emit('submitSuccess'));
        } finally {
            this.pending = false;
            this.$emit('closeForm');
        }
    }

    cancelUpdateFavoriteOrder() {
        this.updateFavoriteOrderRequestModel = this.favoriteOrderInformation;
        this.updateDisplayName();
        this.$emit('closeForm');
    }

    setFieldMask(fieldName, fieldMask) {
        this.updateFavoriteOrderRequestModel[fieldName] = fieldMask;
        (this.$refs[fieldName + 'Input'] as HTMLElement).focus();
    }

    vvErrors!: any;
}
