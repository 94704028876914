/* DO NOT EDIT THIS MANUALLY.
Update enums in enums.ts, and use npm script update-enums to generate this file. */

/*eslint-disable*/  
 export default class Enums {
    IMAGE_LOADING = {
        Eager: 'eager',
        Lazy: 'lazy',
    }

    BRIGHTNESS_MODE = {
        Dark: 'Dark',
        Light: 'Light',
    }

    AHLSELL_THEME = {
        AhlsellBlue: 'Ahlsell Blue',
        AhlsellUnity: 'Ahlsell Unity',
        Custom: 'Custom',
    }

    BUTTON_VARIANTS = {
        BLACK: 'black',
        CUSTOM: 'custom',
        LINK: 'link',
        ORANGE: 'orange',
        PRIMARY: 'primary',
        QUATERNARY: 'quaternary',
        SECONDARY: 'secondary',
        TERTIARY: 'tertiary',
        WHITE: 'white',
    }

    BUTTON_SIZE = {
        NORMAL: 'normal',
        SMALL: 'small',
    }

    BUTTON_ICON_SIZE = {
        NORMAL: 'normal',
        SMALL: 'small',
    }

    BUTTON_ICON_TYPE = {
        ICON_ONLY: 'iconOnly',
        ICON_WITH_TEXT: 'iconWithText',
        NONE: 'none',
    }

    BANNER_TYPE = {
        ContentBanner: 'contentBanner',
        HeroBanner: 'heroBanner',
        LoginBanner: 'loginBanner',
        VideoBanner: 'videoBanner',
    }

    ADJACENT_DIRECTION = {
        Next: 'Next',
        Previous: 'Previous',
    }

    USER_VISIBILITY = {
        Both: 'Both',
        LoggedIn: 'Logged-in user',
        NonLoggedIn: 'Non-logged-in user',
    }

    SPACINGS = {
        LARGE: '48',
        MEDIUM: '32',
        SMALL: '24',
    }

    PRODUCT_TRACKING_EVENT = {
        AddToCart: 'add_to_cart',
        Checkout: 'begin_checkout',
        Pdp: 'view_item',
        ProductClick: 'select_item',
        ProductImpression: 'view_item_list',
        RemoveFromCart: 'remove_from_cart',
        TransactionComplete: 'purchase',
        checkoutOption: 'add_shipping_info',
    }

    PROMO_TRACKING_EVENT = {
        PromotionClick: 'select_promotion',
        PromotionImpression: 'view_promotion',
    }

    PRODUCT_TRACKING_TYPE = {
        AddToBasketRequest: 'AddToBasketRequest',
        AddLineItemViewObject: 'AddLineItemViewObject',
        TrackProductClickViewModel: 'TrackProductClickViewModel',
        FavoriteOrderLineItemViewObject: 'FavoriteOrderLineItemViewObject',
        ProductTileSlimViewObject: 'ProductTileSlimViewObject',
        LineItemViewObject: 'LineItemViewObject',
        ProductDetailsViewObject: 'ProductDetailsViewObject',
        ProductTileViewObject: 'ProductTileViewObject',
    }

    CREATE_UPDATE = {
        Create: 'Create',
        Update: 'Update',
    }

    PRODUCT_STATUS = {
        All: 'All',
        NaN: 'NaN',
    }

    SHARED_STATUS = {
        Editable: 'Editable',
        Readonly: 'Readonly',
    }

    MEDIA_TYPE = {
        Image: 'Image',
        Video: 'Video',
    }

    USER_MENU_ITEM = {
        OrderSummary: 'OrderSummary',
        FavoriteOrders: 'FavoriteOrders',
        DeliveryNotes: 'DeliveryNotes',
    }

}