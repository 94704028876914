



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
})

export default class StatusLabel extends Vue {
    @Prop({ default: '' }) text;

    bgClass: string = 'bg-grey-200'; // default color is gray
    iconClass: string = '';
    textClass: string = 'text-blue-690';

    get setBgClass() {
        return this.bgClass;
    }

    get setIconClass() {
        return this.iconClass;
    }

    get setTextClass() {
        return this.textClass;
    }
}
