










































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import { DeliveryNoteViewModel } from '@/types/serverContract';
import Api from '@/project/http/api';
import BookkeepingUrlHelper from '@/project/bookkeeping/BookkeepingUrlHelper.service';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import TrackAndTraceDetailsLineItem from '@/project/myServices/TrackAndTraceDetailsLineItem.vue';
import TrackAndTraceDetailsShipmentInfo from '@/project/myServices/TrackAndTraceDetailsShipmentInfo.vue';

@Component({
    components: {
        TrackAndTraceDetailsShipmentInfo,
        ModalOverlay,
        TrackAndTraceDetailsLineItem,
        SpinnerOverlay
    }
})
export default class TrackAndTraceDetails extends Vue {
    orderDetails: DeliveryNoteViewModel | null = null;
    pending: boolean = false;
    showTrackAndTraceInfoModal: boolean = false;

    created() {
        this.getOrderDetails();
    }

    async getOrderDetails() {
        this.pending = true;
        try {
            this.orderDetails = await Api.deliveryNotes.deliveryNote(BookkeepingUrlHelper.getOrderId());
        } finally {
            this.pending = false;
        }
    }

    openTrackAndTraceInfoModal(): void {
        this.showTrackAndTraceInfoModal = true;
    }

    closeTrackAndTraceInfoModal(): void {
        this.showTrackAndTraceInfoModal = false;
    }
}
