var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"u-nullify-double-shadow-top relative pt-px"},[_c('header',{staticClass:"px-15 pb-15 bg-white text-16 text-grey-800 shadow-hard md:px-30 md:pb-20 lg:px-35 lg:pb-20"},[(_vm.totalResultsCount)?_c('span',[_vm._v("\n            "+_vm._s(_vm.$tr(_vm.$labels.Views.Articles.SearchForm.ResultCount.Result, _vm.totalResultsCount.toString()))+"\n        ")]):(!_vm.searching)?_c('span',[_vm._v("\n            "+_vm._s(_vm.$tr(_vm.$labels.Views.Articles.SearchForm.ResultCount.Zero))+"\n        ")]):_vm._e()]),_c('div',{staticClass:"p-10 bg-white shadow-hard"},[_c('form',{directives:[{name:"submit",rawName:"v-submit",value:(_vm.search),expression:"search"}],staticClass:"bg-grey-200 p-20"},[_c('div',{staticClass:"o-grid"},[_c('div',{staticClass:"o-grid-item w-full lg:w-1/3"},[_c('TextInput',{directives:[{name:"prohibit-zoom",rawName:"v-prohibit-zoom"}],attrs:{"type":"search","autocomplete":"off","placeholder":_vm.$tr(_vm.$labels.Views.Articles.SearchForm.Term),"icon":"sanicon-ico-search","name":"search"},model:{value:(_vm.term),callback:function ($$v) {_vm.term=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"term"}})],1),_c('div',{staticClass:"o-grid-item w-1/2 lg:w-1/3"},[_c('SelectCtrl',{attrs:{"label":_vm.$tr(_vm.$labels.Views.Articles.SearchForm.Manufacturer),"name":"manufacturer","options":_vm.manufacturers,"id-key":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v("\n                                "+_vm._s(option.displayName)+"\n                            ")])]}}]),model:{value:(_vm.selectedManufacturer),callback:function ($$v) {_vm.selectedManufacturer=$$v},expression:"selectedManufacturer"}})],1),_c('div',{staticClass:"o-grid-item w-1/2 lg:w-1/3"},[_c('SelectCtrl',{attrs:{"label":_vm.$tr(_vm.$labels.Views.Articles.SearchForm.ProductCategory),"name":"productCategory","options":_vm.productCategories,"id-key":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v("\n                                "+_vm._s(_vm.getValueDashProductCategories(option.value, option.displayName))+"\n                            ")])]}}]),model:{value:(_vm.selectedProductCategory),callback:function ($$v) {_vm.selectedProductCategory=$$v},expression:"selectedProductCategory"}})],1),_c('div',{staticClass:"o-grid-item w-1/2 lg:w-1/3"},[_c('SelectCtrl',{attrs:{"label":_vm.$tr(_vm.$labels.Views.Articles.SearchForm.Topic),"name":"topic","options":_vm.topics,"id-key":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v("\n                                "+_vm._s(option.displayName)+"\n                            ")])]}}]),model:{value:(_vm.selectedTopic),callback:function ($$v) {_vm.selectedTopic=$$v},expression:"selectedTopic"}})],1),_c('div',{staticClass:"o-grid-item w-1/2 lg:w-1/6"},[_c('SelectCtrl',{attrs:{"label":_vm.$tr(_vm.$labels.Views.Articles.SearchForm.FromYear),"name":"fromYear","options":_vm.publicationYears,"id-key":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v("\n                                "+_vm._s(option.displayName)+"\n                            ")])]}}]),model:{value:(_vm.selectedFromYear),callback:function ($$v) {_vm.selectedFromYear=$$v},expression:"selectedFromYear"}})],1),_c('div',{staticClass:"o-grid-item w-1/2 lg:w-1/6"},[_c('SelectCtrl',{attrs:{"label":_vm.$tr(_vm.$labels.Views.Articles.SearchForm.ToYear),"name":"toYear","options":_vm.publicationYears,"id-key":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v("\n                                "+_vm._s(option.displayName)+"\n                            ")])]}}]),model:{value:(_vm.selectedToYear),callback:function ($$v) {_vm.selectedToYear=$$v},expression:"selectedToYear"}})],1),_c('div',{staticClass:"o-grid-item w-1/2 lg:w-1/3"},[_c('button',{staticClass:"c-btn c-btn--primary c-btn--smaller w-full",attrs:{"type":"submit"}},[_vm._v("\n                        "+_vm._s(_vm.$tr(_vm.$labels.Views.Articles.SearchForm.ButtonText))+"\n                    ")])])])])]),_c('div',{staticClass:"mt-10 p-30 pb-25 bg-white shadow-hard"},[_c('ArticlesResult',{attrs:{"articles":_vm.searchResult}}),_c('CallbackPaging',{staticClass:"mt-35",attrs:{"current-page":_vm.currentPage,"total-pages":_vm.totalPages},on:{"changePage":_vm.gotoPage}})],1),_c('SpinnerOverlay',{attrs:{"show":_vm.searching}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }