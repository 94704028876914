import { render, staticRenderFns } from "./ProductAddToBasket.vue?vue&type=template&id=23cb10ad&scoped=true"
import script from "./ProductAddToBasket.vue?vue&type=script&lang=ts"
export * from "./ProductAddToBasket.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23cb10ad",
  null
  
)

export default component.exports