















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import keyboardService from '../keyCodes';
import overlayOrchestrator from './overlayOrchestrator';
import breakpointService from '../../core/responsive/breakpoints/breakpoints.service';

@Component
export default class OffCanvasOverlay extends Vue {
    @Prop({
        type: String,
        default: 'fade',
        validator: (value: string) =>
            ['left', 'right', 'top', 'bottom', 'fade', 'modal'].indexOf(value) > -1
    })
    side!: string;

    @Prop({
        type: String,
        default: 'NaN',
        validator: (value: string) =>
            ['NaN', 'left', 'right', 'top', 'bottom', 'fade', 'modal'].indexOf(value) > -1
    })
    mobileSide!: string;

    @Prop({ type: String, default: 'overlay' }) portal!: string;
    @Prop({ type: Boolean, default: false }) show!: boolean;
    @Prop({ type: Boolean, default: true }) showCloseButton!: boolean;
    @Prop({ type: Boolean, default: true }) contentAutoScroll!: boolean;
    @Prop({ type: Boolean, default: false }) disableBodyScroll!: boolean;
    @Prop({ type: String, default: '' }) outerClass!: string;
    @Prop({ type: String, default: '' }) innerClass!: string;
    @Prop({ type: String, default: '' }) overlayClass!: string;
    @Prop({ type: Boolean, default: true }) isOutsideClickable!: boolean;
    @Prop({ type: Boolean, default: true }) centerModal!: boolean;

    activeEntryMethod: string = '';
    isActive = false;
    isVisible = false;
    donePromise: Promise<void> | null = null;
    donePromiseResolve: (() => void) | null = null;
    overlayTimeout: number | null = null;
    $refs!: {
        contentWrapper: HTMLElement;
    };

    mounted() {
        if (this.mobileSide !== 'NaN') {
            breakpointService.addListener(() => this.updateActiveEntryMethod());
        } else {
            this.updateActiveEntryMethod();
        }
    }

    updateActiveEntryMethod(): void {
        if (breakpointService.isActiveBreakpoint('xs, sm')) {
            this.activeEntryMethod = this.mobileSide;
            return;
        }
        this.activeEntryMethod = this.side;
    }

    get outerClasses(): string {
        return `relative z-overlay ${this.outerClass}`;
    }

    get innerClasses(): string {
        return `${this.centerModal ? 'flex items-center justify-center' : ''} ${this.innerClass}`;
    }

    get overlayClasses() {
        const classes = [
            this.activeEntryMethod,
            this.overlayClass,
            this.activeEntryMethod === 'modal' ? 'overflow-x-hidden' : '',
            this.contentAutoScroll ? 'overflow-y-auto scrolling-touch' : 'overflow-y-visible'
        ];

        return classes.join(' ');
    }

    get animationEnterClass() {
        const animTypes = {
            left: 'slideInLeft',
            right: 'slideInRight',
            top: 'slideInDown',
            bottom: 'slideInUp',
            fade: 'fadeIn',
            modal: 'fadeIn'
        };
        return this.animationClass(animTypes);
    }

    get animationLeaveClass() {
        const animTypes = {
            left: 'slideOutLeft',
            right: 'slideOutRight',
            top: 'slideOutDown',
            bottom: 'slideOutDown',
            fade: 'fadeOut',
            modal: 'fadeOut'
        };
        return this.animationClass(animTypes);
    }

    @Watch('show', { immediate: true })
    onShowChange(show) {
        if (show) {
            overlayOrchestrator.beginActivation(this).then(() => {
                // Await that a another user of same portal is out
                this.setVisible(true);
                this.isActive = true;
                document.addEventListener('keyup', this.keyUp);
            });
        } else {
            this.hide();
        }
    }

    keyUp(event) {
        if (!keyboardService.isEscape(event)) {
            return;
        }

        this.clickOutside();
    }

    clickOutside() {
        // We must await 'hide' before emitting 'click-outside'. It's likely that the this.$emit('visible', visible) won't run if the 'OffCanvasOverlay' component is hidden within the 'click-outside' event.
        this.hide().then(() => {
            this.$emit('click-outside');
        });
    }

    hide(): Promise<void> {
        if (this.donePromise) {
            return this.donePromise;
        }
        if (!this.isVisible) {
            return Promise.resolve();
        }
        this.setVisible(false);

        this.donePromise = new Promise<void>(resolve => {
            this.donePromiseResolve = resolve;
        });
        overlayOrchestrator.deactivate();

        return this.donePromise;
    }

    close() {
        overlayOrchestrator.deactivate();
        this.isActive = false;

        if (this.donePromiseResolve) {
            this.donePromiseResolve();
            this.donePromise = null;
            this.donePromiseResolve = null;
        }
    }

    setVisible(visible: boolean) {
        this.isVisible = visible;
        if (visible && this.disableBodyScroll) {
            this.overlayTimeout = setTimeout(
                () => overlayOrchestrator.scrollTargetReady(this.$refs.contentWrapper),
                100
            );
        }
        this.$emit('visible', visible);
    }

    animationClass(animTypes: { [side: string]: string }) {
        return `animated ${animTypes[this.activeEntryMethod]} u-anim-dur-300`;
    }
}
