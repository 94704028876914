




























































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ScannerListsViewModel, ScannerListViewObject } from '@/types/serverContract';
import Api from '@/project/http/api';
import { filter, orderBy } from 'lodash-es';
import ScannerListLineItem from '@/project/myServices/Scanner/ScannerListLineItem.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

type sortingDirection = 'asc' | 'desc';
@Component({
    components: { SpinnerOverlay, ScannerListLineItem }
})
export default class ScannerLists extends Vue {
    scannerLists: ScannerListsViewModel | null = null;
    pending: boolean = false;
    searchTerm: string = '';
    activeSortingKey: string = '';
    activeSortingDirection: sortingDirection = 'asc';

    get filteredScannerLists(): ScannerListViewObject[] {
        if (this.searchTerm !== '') {
            return filter(this.scannerLists!.productCatalogs, productCatalogInfo => Object.values(
                [
                    productCatalogInfo.name,
                    productCatalogInfo.createdByName,
                    productCatalogInfo.createdDate]
            ).join('').toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0);
        } else {
            return this.scannerLists!.productCatalogs;
        }
    }

    created(): void {
        this.getScannerLists();
    }

    async getScannerLists(): Promise<void> {
        this.pending = true;
        try {
            await Api.scannerLists.scannerLists().then(scannerLists => {
                this.scannerLists = scannerLists;
            });
        } finally {
            this.pending = false;
        }
    }

    sortScannerLists(sortingKey) {
        if (sortingKey === this.activeSortingKey) {
            this.toggleSortingDirection();
        } else {
            this.activeSortingKey = sortingKey;
            this.activeSortingDirection = 'asc';
        }

        if (this.scannerLists) {
            this.scannerLists.productCatalogs = orderBy(this.scannerLists.productCatalogs, item => item[sortingKey], this.activeSortingDirection) as ScannerListViewObject[];
        }
    }

    toggleSortingDirection() {
        this.activeSortingDirection = this.activeSortingDirection === 'asc' ? 'desc' : 'asc';
    }

    updateScannerLists(newScannerLists: ScannerListsViewModel): void {
        this.scannerLists = newScannerLists;
    }
}
