
































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    LoginInformation,
    GetLatestOrdersRequest,
    OrderResumeViewObject
} from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import serverContext from '@/core/serverContext.service';
import { FlexBoxGetter } from '@/store/flexBox';
import tracking from '@/core/tracking/tracking.service';

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class LatestOrders extends Vue {
    @AppGetter isLoggedIn!: boolean;
    @AppGetter loginInformation!: LoginInformation;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    @Prop({
        type: Number,
        default: 6
    }) maxElements!: number;

    orders: OrderResumeViewObject[] | null = null;
    pending: boolean = false;
    skeletonList: number[] = [];
    statusIconMap = new Map<string, string>([
        ['Afsendt', 'sanicon-ico-truck'],
        ['Delvist afsendt', 'sanicon-ico-truck'],
        ['Ekspederes', 'sanicon-ico-package'],
        ['Modtaget', 'sanicon-ico-check-mark-bold'],
        ['Annulleret', 'sanicon-ico-close']
    ]);

    get orderHistoryUrl(): string {
        return serverContext.sitePages.orderHistoryPage.url;
    }

    created() {
        this.getLatestOrders().then(() => this.fillSkeletonList());
    };

    async getLatestOrders() {
        this.pending = true;
        if (this.isLoggedIn) {
            try {
                const payload: GetLatestOrdersRequest = {
                    maxElements: this.maxElements
                };

                await Api.orderHistory.getLatestOrders(payload).then(latestOrderResult => {
                    if (latestOrderResult) {
                        this.orders = latestOrderResult.orders;
                    }
                });
            } finally {
                this.pending = false;
            }
        }
    }

    fillSkeletonList() {
        if (this.orders) {
            let amountToCreate = this.maxElements - this.orders.length;
            for (let index = 0; index < amountToCreate; index++) {
                this.skeletonList.push(index);
            }
        }
    }

    trackOrderClick(orderLabel: string, translateLabel: boolean) {
        if (translateLabel) {
            orderLabel = this.$tr(this.$labels.Views.OrderHistory.LatestOrders.SeeAll);
        }
        tracking.trackFrontpageNavigation('Seneste ordrer', orderLabel);
    }
}
