import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

interface Overlay {
    hide();
}

let activeOverlay: Overlay [];

function beginActivation(offCanvasOverlay: Overlay): Promise<void> {
    activeOverlay = activeOverlay == null ? [] : activeOverlay;
    activeOverlay.push(offCanvasOverlay);
    return Promise.resolve();
}

function scrollTargetReady(scrollTarget: HTMLElement) {
    disableBodyScroll(scrollTarget);
}

function deactivate() {
    if (activeOverlay && activeOverlay.length > 0) {
        activeOverlay.pop();
    } else {
        clearAllBodyScrollLocks();
    }
}

export default {
    beginActivation,
    scrollTargetReady,
    deactivate
};
