export enum ActionStatusType {
}
export enum BusinessEntityType {
}
export enum BusinessFunction {
}
export enum ContactPointOption {
}
export enum DayOfWeek {
}
export enum DeliveryMethod {
}
export enum EventStatusType {
}
export enum ItemAvailability {
}
export enum OfferItemCondition {
}
export enum WarrantyScope {
}
export interface USPComponentTileViewObject {
    bodyText: string;
    header: string;
    icon: string;
}
export interface GetShipmentByIdRequest {
    courier: Courier;
    id: string;
    orderId: string;
}
export interface AddressViewObject {
    city: string;
    country: string;
    name: string;
    postCode: string;
    street1: string;
    street2: string;
}
export interface EventViewObject {
    description: string;
    location: LocationViewObject;
    status: string;
    time: string;
}
export interface ItemViewObject {
    description: string;
    height: MeasurementViewObject;
    length: MeasurementViewObject;
    quantity: number;
    senderReference: string;
    type: string;
    weight: MeasurementViewObject;
    width: MeasurementViewObject;
}
export interface LocationViewObject {
    description: string;
    latitude: number | null;
    longitude: number | null;
}
export interface MeasurementViewObject {
    unit: string;
    value: string;
}
export interface ShipmentSummaryViewObject {
    totalItems: string;
    totalVolume: MeasurementViewObject;
    totalWeight: MeasurementViewObject;
}
export interface ShipmentViewModel {
    courier: string;
    events: EventViewObject[];
    id: string;
    items: ItemViewObject[];
    proofOfDelivery: boolean;
    receiver: AddressViewObject;
    sender: AddressViewObject;
    senderReference: string;
    showGoodsSection: boolean;
    status: string;
    statusText: string;
    summary: ShipmentSummaryViewObject;
}
export interface MapLocationViewObject {
    latitude: number;
    longitude: number;
    zoom: number;
}
export interface StoreIconsListViewModel {
    storeIconsList: StoreIconViewObject[];
}
export interface StoreIconViewObject {
    icon: string;
    name: string;
}
export interface StoresListViewModel {
    storeList: StoreViewObject[];
}
export interface StoreViewObject {
    city: string;
    id: string;
    isCollectStore: boolean;
    isMyStore: boolean;
    isSelectedFlexBoxStore: boolean;
    mapLocation: MapLocation;
    name: string;
    phone: string;
    postalCode: string;
    street: string;
    url: string;
}
export interface MapLocation {
    icon: string;
    iconInStock: string;
    iconNotInStock: string;
    latitude: number;
    longitude: number;
    zoom: number;
}
export interface StoresStockQuantityViewModel {
    storeStockQuantityViewObject: StoreStockQuantityViewObject[];
}
export interface StoreStockQuantityViewObject extends StoreViewObject {
    iconInStock: string;
    iconNotInStock: string;
    quantity: number | null;
    quantityDisplay: string;
    unitCode: string;
}
export interface CloseStoreConfirmationViewModel extends BaseEmailMessage {
    body: string;
}
export interface BaseEmailMessage {
    aboutPage: string;
    cultureCode: string;
    termsPage: string;
    websiteUrl: string;
}
export interface SiteViewObject {
    canonicalBaseUri: string;
    languagecode: string;
    siteName: SiteName;
}
export interface SingleUrlPickerViewObject {
    name: string;
    target: string;
    url: string;
}
export interface ProductSecurityDatasheetsListViewModel {
    productDatasheets: ProductSecurityDatasheetViewObject[];
}
export interface ProductSecurityDatasheetViewObject {
    additionalProperties: PropertyViewObject[];
    name: string;
    primaryProperties: PropertyViewObject[];
    securityDatasheetUrl: string;
    thumbnailImage: string;
    units: UnitViewObject[];
    url: string;
    variantIds: VariantIdsViewObject;
}
export interface BomComponentViewObject {
    productId: string;
    quantity: number;
    unit: UnitViewObject;
}
export interface CategoryHitViewObject {
    displayName: string;
    id: string;
    imageUrl: string;
    subCategories: CategoryHitViewObject[];
    url: string;
}
export interface CategoryScopeViewObject {
    categoryId: string;
    displayName: string;
    imageUrl: string;
    resultCount: number;
    url: string;
}
export interface Co2FootprintViewObject {
    co2Emission: string;
    co2FootprintEpdKgPerUnitDisplay: string;
    unitPrEmission: string;
}
export interface FacetGroupViewObject {
    displayName: string;
    docCount: number;
    key: string;
    range: FacetRangeViewObject;
    rank: number;
    values: FacetValueViewObject[];
}
export interface FacetRangeRequestObject {
    max: number | null;
    min: number | null;
}
export interface FacetRangeViewObject {
    max: number;
    min: number;
    unit: string;
}
export interface FacetValueViewObject {
    count: number;
    displayValue: string;
    selected: boolean;
    sortOrder: number;
    sortValue: string;
    value: string;
}
export interface FixedUnitQuantityViewObject {
    fixedUnitCode: string;
    maximumQuantity: number | null;
    minimumQuantity: number;
    orderableQuantity: number | null;
}
export interface MarketingBannerViewObject {
    coverAndCenter: boolean;
    image: string;
    keyword: string;
    link: string;
    linkTarget: string;
    linkText: string;
}
export interface MasterVariantFeatureViewObject {
    displayName: string;
    name: string;
    options: VariantFeatureOptionViewObject[];
}
export interface ProductDetailsViewObject {
    canonicalUrl: string;
    defaultQuantity: number;
    description: string;
    displayName: string;
    productId: string;
    variantPicker: VariantPickerViewModel;
    variants: VariantDetailsViewObject[];
}
export interface ProductDocumentationViewObject {
    displayName: string;
    type: string;
    url: string;
}
export interface ProductImageViewObject {
    basketThumbnailUrl: string;
    enlargedImageUrl: string;
    largeImageUrl: string;
    mailImageUrl: string;
    productImageUrl: string;
    thumbnailUrl: string;
    url: string;
}
export interface ProductSearchRequest {
    brand: string;
    categoryId: string;
    explain: boolean;
    includeCategoryAggregations: boolean;
    includeFacetAggregations: boolean;
    isFreeText: boolean;
    lastFacet: string;
    onlyShowSurplus: boolean;
    page: number | null;
    selectedFacets: SelectedFacetRequestObject[];
    skipUniqueResultHandling: boolean;
    skipVvsSearch: boolean;
    sortBy: string;
    term: string;
}
export interface ProductSearchResponse {
    allowEasySupplyCsvDownload: boolean;
    categoryHits: CategoryHitViewObject[];
    categoryScopes: CategoryScopeViewObject[];
    facets: FacetGroupViewObject[];
    isVvsResult: boolean;
    maxRecommendedProducts: number;
    products: ProductTileViewObject[];
    productTypeSuggestions: FacetGroupViewObject;
    recommendedProducts: ProductTileViewObject[];
    searchExplanation: string;
    sortingOptions: SortByViewObject[];
    spellCheckedTerm: string;
    suggestions: SuggestionViewObject[];
    totalPages: number;
    totalResults: number;
}
export interface ProductStickerViewObject {
    stickerId: string;
}
export interface ProductTileSlimViewObject {
    defaultQuantity: number;
    defaultUnit: UnitViewObject;
    id: string;
    name: string;
    numberOfVariants: number;
    thumbnailImage: string;
    url: string;
    variant: ProductTileVariantViewObject;
}
export interface ProductTileTrackingContext {
    list: string;
    position: number | null;
    searchTerm: string;
}
export interface ProductTileVariantViewObject {
    availabilityType: ProductAvailabilityType;
    certificateCode: string;
    climateCertificates: string[];
    co2Footprint: Co2FootprintViewObject;
    ids: VariantIdsViewObject;
    isStockedItem: boolean;
    isSurplus: boolean;
    isTemporarilyPhasedOut: boolean;
    isVendorUnableToDeliver: boolean;
    manufacturer: string;
    name: string;
    orderableUnits: UnitViewObject[];
    primaryCategoryName: string;
    primaryProperties: PropertyViewObject[];
    specialTerms: SpecialTermsViewObject;
    sticker: ProductStickerViewObject;
    thumbnailImage: string;
    trackingContext: ProductTileTrackingContext;
    url: string;
    variantFeatures: VariantFeatureViewObject[];
}
export interface ProductTileViewObject {
    additionalProperties: PropertyViewObject[];
    bomComponents: BomComponentViewObject[];
    carlFId: string;
    dbId: string;
    defaultQuantity: number;
    defaultUnit: UnitViewObject;
    defaultVariantId: string;
    eanId: string;
    id: string;
    isBomProduct: boolean;
    lId: string;
    name: string;
    primaryProperties: PropertyViewObject[];
    thumbnailImage: string;
    url: string;
    variantPicker: VariantPickerViewModel;
    variants: ProductTileVariantViewObject[];
    vvsId: string;
}
export interface PromotedResultViewObject {
    imageUrl: string;
    keyword: string;
    linkTarget: string;
    linkTitle: string;
    linkUrl: string;
}
export interface PropertyViewObject {
    displayValue: string;
    name: string;
    unit: string;
}
export interface RecommendationBrandViewObject {
    brandId: string;
    imageUrl: string;
    priority: number;
}
export interface RelatedProductsViewObject {
    header: string;
    productsIds: string[];
}
export interface SearchAsYouTypeRequest {
    isSuggestedTerm: boolean;
    term: string;
}
export interface SearchAsYouTypeResponse {
    brandSuggestions: SuggestionViewObject[];
    categories: CategoryScopeViewObject[];
    products: ProductTileViewObject[];
    promotedResult: PromotedResultViewObject;
    suggestions: SuggestionViewObject[];
    term: string;
    totalResults: number;
}
export interface SearchFeedbackRequest {
    comment: string;
    searchRequest: ProductSearchRequest;
    url: string;
    wasSuccessful: boolean;
}
export interface SearchSuggestionsRequest {
    suggestions: string[];
}
export interface SelectedFacetRequestObject {
    key: string;
    range: FacetRangeRequestObject;
    values: string[];
}
export interface SortByViewObject {
    displayName: string;
    id: string;
    isSelected: boolean;
}
export interface SpecialTermsViewObject {
    termsPageUrl: string;
    title: string;
}
export interface SuggestionViewObject {
    term: string;
    url: string;
}
export interface UnitViewObject {
    baseUnitDisplayName: string;
    displayName: string;
    displayNameSupplementary: string;
    fixedUnitQuantity: FixedUnitQuantityViewObject;
    increment: number;
    isFixedUnit: boolean;
    unitCode: string;
    unitKey: string;
    unitQuantity: number;
}
export interface VariantDetailsViewObject {
    additionalProperties: PropertyViewObject[];
    alternativeProducts: RelatedProductsViewObject;
    availabilityType: ProductAvailabilityType;
    canBeReplacedByProductId: string;
    certificateCode: string;
    climateCertificates: string[];
    co2Footprint: Co2FootprintViewObject;
    documentation: ProductDocumentationViewObject[];
    drawings: ProductImageViewObject[];
    environmentalMarkings: string[];
    ids: VariantIdsViewObject;
    images: ProductImageViewObject[];
    isStockedItem: boolean;
    isSurplus: boolean;
    isTemporarilyPhasedOut: boolean;
    isVendorUnableToDeliver: boolean;
    manufacturer: string;
    name: string;
    primaryCategoryName: string;
    primaryImage: ProductImageViewObject;
    primaryProperties: PropertyViewObject[];
    relatedProducts: RelatedProductsViewObject;
    spareParts: RelatedProductsViewObject;
    specialTerms: SpecialTermsViewObject;
    sticker: ProductStickerViewObject;
    units: UnitViewObject[];
    variantFeatures: VariantFeatureViewObject[];
}
export interface VariantFeatureOptionViewObject {
    displayOption: string;
    option: string;
}
export interface VariantFeatureSetViewObject {
    variantFeatures: VariantFeatureViewObject[];
    variantId: string;
}
export interface VariantFeatureViewObject {
    displayName: string;
    displayOption: string;
    name: string;
    option: string;
    sortOrder: number;
    sortValue: string;
}
export interface VariantIdsViewObject {
    carlFId: string;
    customerProductId: string;
    dbId: string;
    eanId: string;
    lId: string;
    prioritizedIds: VariantIdViewObject[];
    supplierProductId: string;
    variantId: string;
    vvsId: string;
}
export interface VariantIdViewObject {
    id: string;
    name: string;
}
export interface VariantPickerViewModel {
    masterFeatures: MasterVariantFeatureViewObject[];
    masterProductId: string;
    variantFeatures: VariantFeatureSetViewObject[];
}
export interface SynonymDetailedViewModel {
    active: boolean;
    changedBy: string;
    changedDate: string;
    createdBy: string;
    createdDate: string;
    description: string;
    id: number;
    synonym: string;
}
export interface SynonymListViewModel {
    synonyms: SynonymViewObject[];
}
export interface SynonymViewObject {
    description: string;
    id: number;
    synonym: string;
}
export interface CreateSynonymRequest {
    description: string;
    synonym: string;
}
export interface GetSynonymByIdRequest {
    id: number;
}
export interface UpdateSynonymRequest {
    description: string;
    id: number;
    synonym: string;
}
export interface ScannerDataListViewModel {
    listProcessed: boolean;
    notImportedEasySupplyList: ScannerDataViewObject[];
    notImportedFavoriteList: ScannerDataViewObject[];
    scannerDataUnknownList: ScannerDataViewObject[];
    scannerImportedEasySupplyList: ScannerImportListViewObject[];
    scannerImportedFavoriteList: ScannerImportListViewObject[];
    scannerImportUrl: string;
}
export interface ScannerDataViewObject {
    barcodeValue: string;
    productName: string;
    quantity: string;
    scanId: string;
}
export interface ScannerImportListViewObject {
    lines: ScannerDataViewObject[];
    listId: number;
    listName: string;
    listReference: string;
    listUrl: string;
}
export interface ScannerListDetailViewModel {
    allowEdit: boolean;
    caseId: string;
    id: number;
    lineItems: FavoriteOrderLineItemViewObject[];
    name: string;
    referenceId: string;
    requestId: string;
    shared: boolean;
    sortingOptions: SortByViewObject[];
    totalPages: number;
    totalResults: number;
}
export interface ScannerListsViewModel {
    productCatalogs: ScannerListViewObject[];
}
export interface ScannerListViewObject {
    allowDelete: boolean;
    createdByName: string;
    createdDate: string;
    detailsUrl: string;
    id: number;
    name: string;
    numberOfFavoriteOrderLines: number;
    shared: boolean;
    sortableCreatedDate: string;
}
export interface ScannerSetupViewModel {
    requestId: string;
    scannerResultEndpoint: string;
    successUrl: string;
    versionEndpoint: string;
    webClientConfigurationUrl: string;
    webClientReadFromScannerUrl: string;
}
export interface FavoriteOrderDetailViewModel {
    accountGroups: AccountGroupSlim[];
    allowEdit: boolean;
    caseId: string;
    createdDate: string;
    id: number;
    lineItems: FavoriteOrderLineItemViewObject[];
    name: string;
    owner: string;
    readOnly: boolean;
    referenceId: string;
    requestId: string;
    shared: boolean;
    sortingOptions: SortByViewObject[];
    totalPages: number;
    totalResults: number;
}
export interface FavoriteOrderImagesViewObject {
    thumbnailImagesUrls: string[];
}
export interface FavoriteOrderImportLineErrorViewObject {
    errorText: string;
}
export interface FavoriteOrderLineItemViewObject {
    allowEdit: boolean;
    availabilityType: ProductAvailabilityType;
    availableCertificate: string;
    availableUnits: UnitViewObject[];
    canBePurchased: boolean;
    carlFId: string;
    certificate: CertificateViewObject;
    customerProductId: string;
    dbId: string;
    displayName: string;
    eanId: string;
    fixedUnitValue: number | null;
    id: number;
    isSpecialLineItem: boolean;
    isStockedItem: boolean;
    isSurplus: boolean;
    isTemporarilyPhasedOut: boolean;
    isVendorUnableToDeliver: boolean;
    lId: string;
    manufacturer: string;
    primaryCategory: PrimaryCategoryViewObject;
    primaryProperties: PropertyViewObject[];
    productId: string;
    productItemNumber: string;
    productText: string;
    quantity: number;
    selectedUnit: UnitViewObject;
    specialTerms: SpecialTermsViewObject;
    sticker: ProductStickerViewObject;
    supplementaryProductText: string;
    thumbnailImage: string;
    trackingContext: ProductTileTrackingContext;
    url: string;
    variantProperties: PropertyViewObject[];
    vvsId: string;
}
export interface FavoriteOrderListItemViewObject {
    accountGroupNames: string[];
    allowDelete: boolean;
    allowEdit: boolean;
    createdByName: string;
    createdDate: string;
    detailsUrl: string;
    id: number;
    name: string;
    numberOfFavoriteOrderLines: number;
    readonly: boolean;
    shared: boolean;
    sortableCreatedDate: string;
}
export interface FavoriteOrderProductIdsFilteredByCriteriaViewModel {
    fitProducts: string[];
    nonFitProducts: string[];
}
export interface FavoriteOrderSelectorItemViewObject {
    id: number;
    name: string;
}
export interface FavoriteOrderSelectorViewModel {
    favoriteOrderList: FavoriteOrderSelectorItemViewObject[];
}
export interface FavoriteOrdersImportResultViewModel {
    errors: FavoriteOrderImportLineErrorViewObject[];
    favoriteOrders: FavoriteOrdersViewModel;
}
export interface FavoriteOrderSpecialLineItemViewObject {
    allowEdit: boolean;
    displayName: string;
    id: number;
    manufacturer: string;
    productItemNumber: string;
    productText: string;
    quantity: number;
    supplementaryProductText: string;
    thumbnailImage: string;
    unitCode: string;
}
export interface FavoriteOrdersViewModel {
    favoriteOrderList: FavoriteOrderListItemViewObject[];
}
export interface FavoriteOrderViewObject {
    accountGroupNames: string[];
    createdDate: string;
    detailsUrl: string;
    id: number;
    name: string;
    numberOfFavoriteOrderLines: number;
    readOnly: boolean;
}
export interface PagedFavoriteOrdersViewModel {
    currentPage: number;
    favoriteOrders: FavoriteOrderViewObject[];
    totalPages: number;
    totalResults: number;
}
export interface PrimaryCategoryViewObject {
    displayName: string;
    url: string;
}
export interface AddLineItemViewObject {
    manufacturer: string;
    price: number;
    primaryCategoryName: string;
    productId: string;
    productName: string;
    quantity: number;
    trackingContext: ProductTileTrackingContext;
    unitCode: string;
}
export interface AllowanceCalculationViewObject {
    description: string;
    totalAmount: string;
}
export interface AllowanceChargeCalculationViewObject {
    description: string;
    total: string;
}
export interface AllowanceViewObject {
    description: string;
    totalAmount: string;
}
export interface BasketAllowanceGroupViewObject {
    description: string;
    totalAmount: string;
}
export interface BasketSlimViewObject {
    displayName: string;
    identifier: string;
    isActive: boolean;
    numberOfLines: number;
}
export interface BasketViewModel {
    accountNumber: string;
    allowanceCharges: AllowanceChargeCalculationViewObject[];
    allowances: BasketAllowanceGroupViewObject[];
    approvalStatus: ApprovalStatus | null;
    caseNumber: string;
    certificatesTotal: string;
    co2Calculation: Co2CalculationViewObject;
    comment: string;
    deliveryAddress: DeliveryAddressForCheckoutViewObject;
    deliveryDate: string;
    deliveryMethod: DeliveryType;
    displayName: string;
    grossSubTotal: string;
    grossTotal: string;
    grossTotalSummary: string;
    grossTotalSummaryInclVat: string;
    grossVat: string;
    identifier: string;
    isValid: boolean;
    lineItems: LineItemViewObject[];
    priceServiceAvailable: boolean;
    rawDeliveryDate: string;
    reference: string;
    requisitionNumber: string;
    smsServiceNumber: string;
    subTotal: string;
    supportsSmsService: boolean;
    total: string;
    totalCharge: string;
    totalSummary: string;
    totalSummaryInclVat: string;
    vat: string;
}
export interface CertificateViewObject {
    certificateCode: string;
    certificateName: string;
    certificatePrice: string | null;
    stamping: boolean;
    stampingPrice: string | null;
}
export interface ChargeViewObject {
    description: string;
    pricePrUnit: string;
    totalAmount: string;
    unit: string;
}
export interface Co2CalculationItemViewObject {
    co2FootprintEPDKgPerUnit: number;
    co2FootprintEPDKgPerUnitDisplay: string;
    co2TotalForLineItem: string;
    displayUnit: string;
    totalCalculatedAmount: number;
    unit: string;
}
export interface Co2CalculationViewObject {
    co2NumberOfLineItems: string;
    co2TotalForBasket: string;
}
export interface DeliveryAllowanceChargesCalculationViewObject {
    description: string;
    totalAmount: string;
}
export interface DeliveryDateItemsViewObject {
    deliveryDate: string;
    deliveryDateShort: string;
    deliveryDateText: string;
    hasDeliveryDate: boolean;
    lines: LineItemDeliveryViewObject[];
    sortableDateString: string;
}
export interface FixedUnitViewObject {
    unitCode: string;
    value: number;
}
export interface LineItemDeliveryTimeViewObject {
    deliveryAllowanceCharges: DeliveryAllowanceChargesCalculationViewObject[];
    deliveryCharges: DeliveryAllowanceChargesCalculationViewObject[];
    deliveryDate: string;
    deliveryDateShort: string;
    deliveryDateText: string;
    deliveryGrossTotal: string;
    deliveryTotal: string;
    quantity: number;
    showCertificate: boolean;
    sortableDateString: string;
    unit: string;
}
export interface LineItemDeliveryViewObject {
    deliveryAllowanceCharges: DeliveryAllowanceChargesCalculationViewObject[];
    deliveryCharges: DeliveryAllowanceChargesCalculationViewObject[];
    deliveryGrossTotal: string;
    deliveryTotal: string;
    lineItem: LineItemViewObject;
    quantity: number;
    showCertificate: boolean;
    unit: UnitViewObject;
}
export interface LineItemViewObject {
    allowances: AllowanceCalculationViewObject[];
    carlFId: string;
    certificate: CertificateViewObject;
    certificateOptions: CertificateViewObject;
    charges: AllowanceChargeCalculationViewObject[];
    chargeTotal: string;
    climateCertificates: string[];
    co2CalculationItem: Co2CalculationItemViewObject;
    co2Footprint: Co2FootprintViewObject;
    customerReference: string;
    dbId: string;
    deliveries: LineItemDeliveryTimeViewObject[];
    displayName: string;
    eanId: string;
    fixedUnit: FixedUnitViewObject;
    grossUnitPrice: string;
    inventoryQuantity: number;
    inventoryStatus: StockStatusViewObject;
    inventoryUnit: UnitViewObject;
    isAdhoc: boolean;
    isSpecialOrderLineItem: boolean;
    isStockedItem: boolean;
    isSurPlus: boolean;
    lId: string;
    lineGrossTotal: string;
    lineIdentifier: string;
    lineTotal: string;
    manufacturer: string;
    nightDeliveryPossible: NightDeliveryPossibleViewObject;
    orderableUnits: UnitViewObject[];
    primaryCategoryName: string;
    primaryProperties: PropertyViewObject[];
    productId: string;
    quantity: number;
    referenceDocument: ReferenceDocumentViewObject;
    scalePrices: ScalePriceViewObject[];
    scalePriceToPromote: ScalePricePromotionInformationViewObject;
    specialOrderLine: SpecialOrderLineItemViewObject;
    specialTerms: SpecialTermsViewObject;
    sticker: ProductStickerViewObject;
    thumbnailImage: string;
    trackingUnitPrice: number;
    unit: UnitViewObject;
    unitPrice: string;
    url: string;
    validationMessages: ClientMessage[];
    variantProperties: PropertyViewObject[];
    vvsId: string;
}
export interface NightDeliveryPossibleViewObject {
    message: string;
    possible: boolean;
    type: NightDeliveryQualification;
}
export interface OrderViewModel extends BasketViewModel {
    deliveries: DeliveryDateItemsViewObject[];
    emailRecipient: string;
    sapOrderId: string;
    storeId: string;
    trackingRevenue: number;
    trackOrder: boolean;
}
export interface QuickAddToBasketResultViewModel {
    addedLine: AddLineItemViewObject;
}
export interface ReferenceDocumentViewObject {
    documentCategory: DocumentCategory;
    documentCategoryLabel: string;
    documentId: string;
    documentItem: string;
}
export interface ScalePricePromotionInformationViewObject {
    displayUnit: string;
    grossPriceDiscountPercentage: string;
    netPriceDiscountPercentage: string;
    quantity: number;
    unit: string;
}
export interface SpecialLineItemUnitsViewModel {
    units: UnitViewObject[];
}
export interface SpecialOrderLineItemViewObject {
    lineItemId: string;
    manufacturer: string;
    productItemNumber: string;
    productText: string;
    quantity: number;
    supplementaryProductText: string;
    unitCode: string;
}
export interface CopyToFavoriteOrderRequest {
    favoriteOrderName: string;
    mergeType: FavoriteListMergeType;
    shared: boolean;
    sourceScannerListId: number;
    targetFavoriteOrderId: number;
}
export interface DeleteScannerListLineRequest {
    isSpecialLineItem: boolean;
    lineId: number;
    scannerListId: number;
}
export interface GetScannerListRequest {
    lineSearchTerm: string;
    page: number;
    scannerListId: number;
    sortBy: string;
}
export interface UpdateScannerListLineItemRequest {
    certificateCode: string | null;
    fixedUnitCode: string | null;
    fixedUnitValue: number | null;
    isFixedUnit: boolean;
    lineItemId: number;
    productId: string;
    quantity: number;
    scannerListId: number;
    stamping: boolean;
    unitCode: string;
}
export interface FirstLevelCategoryViewObject {
    categories: SecondLevelCategoryViewObject[];
    id: string;
    name: string;
}
export interface MaintenanceReportTreeViewModel {
    categories: FirstLevelCategoryViewObject[];
}
export interface ReportAsEmailViewModel {
    email: string;
}
export interface ReportOptionsViewModel {
    emailAddressForReport: string;
    labelPrinterLabel: string;
    labelPrinterReportTypes: KeyAndValue<ReportType, string>[];
    priceTypes: KeyAndValue<PriceType, string>[];
    productNumberTypes: KeyAndValue<ProductNumberType, string>[];
    reportTypes: KeyAndValue<ReportType, string>[];
}
export interface SecondLevelCategoryViewObject {
    id: string;
    name: string;
    variantsWithDocuments: VariantWithDocumentsViewObject[];
}
export interface VariantWithDocumentsViewObject {
    documents: MaintenanceDocument[];
    name: string;
    variantId: string;
}
export enum MaintenanceDocumentType {
    SafetyDataSheet = 0,
    Document = 1
}
export interface MaintenanceDocument {
    hash: string;
    maintenanceDocumentType: MaintenanceDocumentType;
    name: string;
    variantId: string;
}
export interface KeyAndValue<TKey, TValue> {
    key: TKey;
    value: TValue;
}
export interface BaseReportRequest {
    priceType: PriceType;
    productNumberType: ProductNumberType;
    reportType: ReportType;
}
export interface CategoryReportAsEmailRequest extends BaseReportRequest {
    categoryId: string;
    emailAddress: string;
}
export interface DownloadBasketReportRequest extends BaseReportRequest {
}
export interface DownloadProductDetailsReportRequest extends BaseReportRequest {
    productId: string;
}
export interface EasySupplyWarehouseReportAsEmailRequest extends BaseReportRequest {
    easySupplyWarehouseId: number;
    easySupplyWarehouseLineIds: number[];
    emailAddress: string;
    getAll: boolean;
}
export interface FavoriteReportAsEmailRequest extends BaseReportRequest {
    emailAddress: string;
    favoriteOrderId: number;
    sortBy: string;
    term: string;
}
export interface MaintenanceReportOptionsRequest {
    fromDate: string;
    toDate: string;
}
export interface ProductCatalogReportAsEmailRequest extends BaseReportRequest {
    emailAddress: string;
    productCatalogId: number;
    sortBy: string;
    term: string;
}
export interface ProductListReportAsEmailRequest extends BaseReportRequest {
    emailAddress: string;
    variantIds: string[];
}
export interface RequestMaintenanceReportAsEmailRequest {
    documents: MaintenanceDocument[];
    emailAddress: string;
    fileName: string;
    sendAsZip: boolean;
}
export interface SearchReportAsEmailRequest extends BaseReportRequest {
    emailAddress: string;
}
export interface RecommendedProductsViewModel {
    productId: string;
    recommendedProducts: ProductTileViewObject[];
}
export interface RecommendationParameter {
    name: string;
    value: string;
}
export interface RecommendationViewModel<T> {
    elements: T[];
}
export interface ContentRecommendationForProductRequest {
    maxElements: number | null;
    method: RaptorContentMethod;
    productId: string;
}
export interface ProductRecommendationForBrandRequest {
    brandId: string;
    maxElements: number | null;
    method: RaptorBrandMethod;
}
export interface RecommendationCategoryRequest {
    maxElements: number | null;
    method: string;
    parameters: RecommendationParameter[];
}
export interface RecommendationContentRequest {
    maxElements: number | null;
    method: string;
    parameters: RecommendationParameter[];
}
export interface RecommendationForBrandsRequest {
    maxElements: number | null;
    method: RaptorBrandMethod;
}
export interface RecommendationForCategoriesRequest {
    maxElements: number | null;
    method: RaptorCategoryRecommendationMethod;
}
export interface RecommendationForCategoryByCustomerCategoryRequest {
    categoryId: string;
    customerCategoryId: string;
    maxElements: number | null;
    method: RaptorCategoryByCustomerCategoryMethod;
    onSale: boolean | null;
}
export interface RecommendationForCategoryRequest {
    categoryId: string;
    maxElements: number | null;
    method: RaptorCategoryMethod;
}
export interface RecommendationForContentRequest {
    contentId: string;
    maxElements: number | null;
    method: RaptorProductMethod;
}
export interface RecommendationForCustomerCategoryRequest {
    customerCategoryId: string;
    maxElements: number | null;
    method: RaptorCustomerCategoryMethod;
}
export interface RecommendationForProductRequest {
    isSimilarOrRelated: boolean;
    maxElements: number | null;
    method: RaptorProductMethod;
    productId: string;
}
export interface RecommendationProductRequest {
    maxElements: number | null;
    method: string;
    parameters: RecommendationParameter[];
}
export interface RecommendationRequest {
    maxElements: number | null;
    method: RaptorMethod;
}
export interface TrendingProductsRequest {
    method: RaptorProductMethod;
}
export interface AlternativeLineItemGroupViewObject {
    lines: QuotationDetailsLineItemViewObject[];
    selectedLineId: string;
}
export interface QuotationDetailsLineItemViewObject {
    allowOrdering: boolean;
    atpQuantity: number;
    lineId: string;
    maxQuantity: number;
    orderLineNetPrice: string;
    orderLineText: string;
    productId: string;
    quantity: number;
    unit: UnitViewObject;
}
export interface QuotationDetailsViewModel {
    allowOrdering: boolean;
    caseNumber: string;
    documentCategory: DocumentCategory;
    orderId: string;
    orderLines: QuotationDetailsLineItemViewObject[];
    orderLinesWithOptions: AlternativeLineItemGroupViewObject[];
    orderTypeReady: boolean;
    reference: string;
    requisitionNumber: string;
    validFromDate: string;
}
export interface QuotationHeaderViewObject {
    caseId: string;
    hasReservations: boolean;
    netAmount: string;
    orderId: string;
    orderResponseExists: boolean;
    orderStatus: string;
    orderType: string;
    receiver: string;
    reference: string;
    requestId: string;
    url: string;
    validFromDate: string;
}
export interface QuotationListViewModel {
    quotationHeaders: QuotationHeaderViewObject[];
    totalPages: number;
}
export interface GetQuotationRequest {
    orderId: string;
}
export interface SearchQuotationsRequest {
    caseNumber: string;
    fromDate: string;
    orderNumber: string;
    page: number | null;
    pageSize: number | null;
    productId: string;
    productIdType: ProductIdType;
    reference: string;
    requisitionNumber: string;
    toDate: string;
}
export interface ProjectFolderLinkListViewModel {
    links: ProjectFolderLinkViewObject[];
}
export interface ProjectFolderLinkViewObject {
    id: number;
    originalFileName: string;
    title: string;
    url: string;
}
export interface ProjectOrderQuotationDetailsViewModel extends QuotationDetailsViewModel {
}
export interface ProjectOrderQuotationHeaderViewObject {
    caseId: string;
    downloadOrderConfirmationUrl: string;
    hasReservations: boolean;
    netAmount: string;
    orderId: string;
    orderResponseExists: boolean;
    orderStatus: string;
    orderType: string;
    receiver: string;
    reference: string;
    requestId: string;
    url: string;
    validFromDate: string;
}
export interface ProjectOrderQuotationListViewModel {
    projectOrderHeaders: ProjectOrderQuotationHeaderViewObject[];
    totalPages: number;
}
export interface GetProjectOrderQuotationRequest {
    orderId: string;
}
export interface SearchProjectOrderQuotationsRequest {
    caseNumber: string;
    fromDate: string;
    orderNumber: string;
    page: number | null;
    pageSize: number | null;
    productId: string;
    productIdType: ProductIdType;
    reference: string;
    requisitionNumber: string;
    toDate: string;
}
export interface ProjectFolderProductCatalogDetailViewModel {
    allowEdit: boolean;
    caseId: string;
    id: number;
    lineItems: FavoriteOrderLineItemViewObject[];
    name: string;
    referenceId: string;
    requestId: string;
    shared: boolean;
    sortingOptions: SortByViewObject[];
    totalPages: number;
    totalResults: number;
}
export interface ProjectFolderProductCatalogListViewModel {
    productCatalogs: ProjectFolderProductCatalogListViewObject[];
}
export interface ProjectFolderProductCatalogListViewObject {
    displayName: string;
    url: string;
}
export interface DeleteProductCatalogLineRequest {
    isSpecialLineItem: boolean;
    lineId: number;
    productCatalogId: number;
}
export interface GetProjectFolderProductCatalogRequest {
    lineSearchTerm: string;
    page: number;
    productCatalogId: number;
    sortBy: string;
}
export interface UpdateProductCatalogLineItemRequest {
    certificateCode: string | null;
    fixedUnitCode: string | null;
    fixedUnitValue: number | null;
    isFixedUnit: boolean;
    lineItemId: number;
    productCatalogId: number;
    productId: string;
    quantity: number;
    stamping: boolean;
    unitCode: string;
}
export interface LatestProductsViewModel {
    products: ProductTileViewObject[];
}
export interface Top100ProductsViewModel {
    pageSize: number;
    products: ProductTileViewObject[];
    totalPages: number;
    totalResults: number;
}
export interface GetByVariantEANsRequest {
    variantEANs: string[];
}
export interface GetByVariantIdsRequest {
    variantIds: string[];
}
export interface LatestProductsRequest {
    maxElements: number | null;
}
export interface Top100ProductsRequest {
    monthsBack: number;
}
export interface GetPriceRequest {
    priceRequests: PriceRequest[];
}
export interface PriceRequest {
    availabilityType: ProductAvailabilityType;
    certificateCode: string;
    id: string;
    isSurplus: boolean;
    isTemporarilyPhasedOut: boolean;
    isVendorUnableToDeliver: boolean;
}
export interface PriceViewModel {
    prices: PriceViewObject[];
    priceServiceAvailable: boolean;
}
export interface PriceViewObject {
    allowances: AllowanceViewObject[];
    certificates: CertificateViewObject[];
    charges: ChargeViewObject[];
    grossPrice: string;
    hasScalePrices: boolean;
    isPriceServiceAvailable: boolean;
    netPrice: string;
    orderAllowances: AllowanceViewObject[];
    orderCharges: ChargeViewObject[];
    productId: string;
    scalePrices: ScalePriceViewObject[];
    stockStatus: StockStatusViewObject;
    trackingNetPrice: number;
}
export interface ScalePriceViewObject {
    grossPrice: string;
    grossPriceDiscountPercentage: string;
    netPrice: string;
    netPriceDiscountPercentage: string;
    scaleQuantity: number;
    unit: UnitViewObject;
}
export interface StockQuantityViewObject {
    quantity: string;
    unit: UnitViewObject;
}
export interface StockStatusViewObject {
    expectedDeliveryDateDisplayValue: string;
    inStockIndicator: string;
    inventory: StockQuantityViewObject;
    isOrderable: boolean;
    status: AvailabilityStatus;
}
export interface DocumentLinkViewObject {
    displayName: string;
    url: string;
}
export interface DocumentListViewModel {
    documents: DocumentLinkViewObject[];
    totalPages: number;
    totalResultCount: number;
}
export interface AccountStatementsRequest {
    fromDate: string;
    page: number;
    toDate: string;
}
export interface HistoricDeliveryNotesRequest {
    documentId: string;
    fromDate: string;
    page: number;
    toDate: string;
}
export interface HistoricInvoicesRequest {
    documentId: string;
    fromDate: string;
    page: number;
    toDate: string;
}
export interface HistoricOrdersRequest {
    documentId: string;
    fromDate: string;
    page: number;
    toDate: string;
}
export interface EventTeaserViewModel {
    events: EventTeaserViewObject[];
    url: string;
}
export interface EventTeaserViewObject {
    displayName: string;
    startDate: string;
    url: string;
}
export interface AnchorElementViewObject {
    anchorId: string;
    anchorName: string;
}
export interface SetDeliveryTimeRequest {
    deliveryTime: string;
}
export interface DataPolicyRequest {
    hasAcceptedDataPolicy: boolean;
}
export interface DataPolicyResponse {
    hasAcceptedDataPolicy: boolean;
}
export interface RevenueViewModel {
    revenues: RevenueViewObject[];
    totalRevenue: number;
}
export interface RevenueViewObject {
    label: string;
    revenue: number;
}
export enum RevenueRequestType {
    ThisYear = 0,
    Latest12Months = 1
}
export interface RevenueRequest {
    type: RevenueRequestType;
}
export interface BaseApiResponse<TModel> {
    basket: BasketViewModel;
    clientMessage: MessageResponse;
    model: TModel;
}
export interface ClientMessage {
    message: string;
    messageType: ClientMessageType;
}
export interface MessageResponse {
    messages: ClientMessage[];
}
export interface DeliveryAddressForCheckoutViewModel {
    addresses: DeliveryAddressForCheckoutViewObject[];
    selectedAddressId: string;
}
export interface DeliveryAddressForCheckoutViewObject {
    addressDeliveryType: DeliveryType;
    addressDeliveryTypeLabel: string;
    addressName: string;
    addressName2: string;
    city: string;
    citySubdivision: string;
    country: string;
    digitalCommunication: DigitalCommunicationViewObject;
    floor: string;
    id: string;
    isPreferred: boolean;
    number: string;
    postalCode: string;
    street: string;
}
export interface DeliveryAddressViewModel {
    addresses: DeliveryAddressViewObject[];
}
export interface DeliveryAddressViewObject {
    addressDeliveryType: DeliveryType | null;
    addressDeliveryTypeLabel: string;
    addressName: string;
    addressName2: string;
    city: string;
    citySubdivision: string;
    country: string;
    floor: string;
    id: string;
    number: string;
    postalCode: string;
    street: string;
}
export interface DigitalCommunicationViewObject {
    email: string;
    isEnabled: boolean;
    phone: string;
}
export interface FirmwareDetailsViewObject {
    host: string;
    port: number;
    uri: string;
    version: string;
}
export interface RedirectResponse {
    clientRedirectUrl: string;
    reloadCurrentPage: boolean | null;
    reloadFromServer: boolean;
}
export interface GetHubSpotFeedRequest {
    id: string;
}
export interface EmailOptOutRequest {
    emailAddress: string;
}
export interface StartFlexBoxModeRequest {
    phoneNumber: string;
    sapAddressId: string;
}
export interface UpdateEasySupplyProcuredProductRequest {
    customId: string;
    description: string;
    name: string;
    procuredProductId: number;
    supplierId: string;
    supplierProductId: string;
    unitCode: string;
}
export interface UpdateEasySupplyWarehouseRequest {
    active: boolean;
    customerLocked: boolean;
    deliveryInterval: number;
    easySupplyWarehouseId: number;
    identifier: string;
    name: string;
    orderReference: string;
    requisitionNumber: string;
}
export interface UpdateEasySupplyWarehouseLineRequest {
    easySupplyWarehouseId: number;
    easySupplyWarehouseLineId: number;
    locationDescription: string;
    reorderQuantity: number;
    reorderThreshold: number | null;
}
export interface SearchEasySupplyWarehouseRequest {
    easySupplyWarehouseId: number;
    page: number;
    sortBy: string;
    term: string;
}
export interface EasySupplyWarehouseLinesForProcuredProductRequest {
    procuredProductId: number;
    returnAllWarehouses: boolean;
}
export interface DeleteEasySupplyWarehouseLineRequest {
    easySupplyWarehouseId: number;
    lineId: number;
}
export interface DeleteEasySupplyOrderLineRequest {
    easySupplyOrderLineId: number;
    easySupplyWarehouseId: number;
}
export interface CreateEasySupplyWarehouseRequest {
    active: boolean;
    customerLocked: boolean;
    deliveryInterval: number;
    identifier: string;
    name: string;
    orderReference: string;
    requisitionNumber: string;
}
export interface AddEasySupplyOrderLineRequest {
    easySupplyWarehouseId: number;
    variantId: string;
}
export interface CreateEasySupplyProcuredProductRequest {
    customId: string;
    description: string;
    name: string;
    supplierId: string;
    supplierProductId: string;
    unitCode: string;
}
export interface AddEasySupplyProcuredProductToWarehouseRequest {
    easySupplyWarehouseId: number;
    location: string;
    procuredProductId: number;
    quantity: number;
}
export interface AddEasySupplyWarehouseLineRequest {
    easySupplyWarehouseId: number;
    locationDescription: string;
    reorderQuantity: number;
    reorderQuantityUnit: string;
    variantId: string;
}
export interface DeleteEasySupplySmartButtonRequest {
    easySupplyWarehouseId: number;
    smartButtonHardwareIdentifier: string;
}
export interface AddProductToEasySupplyWarehouseListViewModel {
    easySupplyWarehouseList: EasySupplyWarehouseListItemViewObject[];
    hasUneditableWarehouses: boolean;
}
export interface DeliveryIntervalViewObject {
    displayName: string;
    interval: number;
}
export interface EasySupplyGatewayDetailViewObject {
    deployed: boolean;
    expectedOrderTime: string;
    gatewayFirmwareDetails: FirmwareDetailsViewObject;
    hardwareIdentifier: string;
    selectedDeliveryAddress: DeliveryAddressViewObject;
    smartButtonsFirmwareDetails: FirmwareDetailsViewObject;
    softwareVersion: string;
    weekdaysAllowedToSendOrder: Weekday[];
}
export interface EasySupplyOrderLinesViewModel {
    easySupplyOrderLineList: EasySupplyOrderLineViewObject[];
}
export interface EasySupplyOrderLineViewObject {
    id: number;
    name: string;
    primaryProperties: PropertyViewObject[];
    quantity: number;
    unit: UnitViewObject;
    variantId: string;
    variantProperties: PropertyViewObject[];
}
export interface EasySupplyProcuredProductsListViewModel {
    procuredProductViewObjects: EasySupplyProcuredProductViewObject[];
}
export interface EasySupplyProcuredProductViewObject {
    canDelete: boolean;
    canEdit: boolean;
    customId: string;
    description: string;
    id: number;
    name: string;
    supplierId: string;
    supplierProductId: string;
    unit: UnitViewObject;
}
export interface EasySupplyProcuredProductWarehouseLinesViewModel {
    warehouseLines: EasySupplyWarehouseLineProcuredProductViewObject[];
}
export interface EasySupplySmartButtonViewObject {
    smartButtonHardwareIdentifier: string;
    smartButtonStatus: string;
    smartButtonStatusLabel: string;
}
export interface EasySupplyWarehouseCreateViewModel {
    formOptions: EasySupplyWarehouseFormOptionsViewObject;
}
export interface EasySupplyWarehouseDetailImportLineErrorViewObject {
    errorText: string;
}
export interface EasySupplyWarehouseDetailImportResultViewModel {
    errors: EasySupplyWarehouseDetailImportLineErrorViewObject[];
    generalImportErrorMessage: string;
}
export interface EasySupplyWarehouseDetailsListItemViewObject {
    allowDelete: boolean;
    editUrl: string;
    hasGateway: boolean;
    id: number;
    identifier: string;
    name: string;
    reOrderUrl: string;
    soldToPartnerId: string;
}
export interface EasySupplyWarehouseDetailsListViewModel {
    createWarehouseUrl: string;
    easySupplyWarehouseList: EasySupplyWarehouseDetailsListItemViewObject[];
}
export interface EasySupplyWarehouseDetailViewModel {
    active: boolean;
    allowEdit: boolean;
    allowManageGateway: boolean;
    allowUpdateFirmware: boolean;
    customerLocked: boolean;
    deliveryInterval: number | null;
    formOptions: EasySupplyWarehouseFormOptionsViewObject;
    gatewayDetails: EasySupplyGatewayDetailViewObject;
    id: number;
    identifier: string;
    lines: EasySupplyWarehouseLineViewObject[];
    name: string;
    orderReference: string;
    requisitionNumber: string;
    soldToPartnerId: string;
    sortingOptions: SortByViewObject[];
    totalPages: number;
    totalResults: number;
}
export interface EasySupplyWarehouseFormOptionsViewObject {
    canEditCustomerLocked: boolean;
    intervals: DeliveryIntervalViewObject[];
}
export interface EasySupplyWarehouseLineIdGenerationViewModel {
    easySupplyWarehouseLineId: string;
}
export interface EasySupplyWarehouseLineProcuredProductViewObject {
    canEdit: boolean;
    location: string;
    procuredProductId: number;
    quantity: number;
    smartButtonHardwareIdentifier: string;
    unit: UnitViewObject;
    url: string;
    warehouseId: number;
    warehouseIdentifier: string;
    warehouseName: string;
}
export interface EasySupplyWarehouseLineViewObject {
    allowDelete: boolean;
    allowEdit: boolean;
    allowManageSmartButton: boolean;
    customerProductId: string;
    easySupplyWarehouseId: number;
    id: number;
    isStockProduct: boolean;
    locationDescription: string;
    name: string;
    primaryProperties: PropertyViewObject[];
    productId: string;
    reorderQuantity: number | null;
    reorderQuantityUnit: string;
    reorderThreshold: number | null;
    smartButton: EasySupplySmartButtonViewObject;
    sortOrder: number;
    userId: number;
    variantProperties: PropertyViewObject[];
}
export interface EasySupplyWarehouseListItemViewObject {
    id: number;
    identifier: string;
    name: string;
}
export interface EasySupplyWarehouseListViewModel {
    easySupplyWarehouseList: EasySupplyWarehouseListItemViewObject[];
}
export interface ProcuredProductUnitsViewModel {
    units: UnitViewObject[];
}
export interface UpdateEasySupplySmartButtonFirmwareDetailsRequest {
    gatewayHardwareIdentifier: string;
    host: string;
    port: number;
    uri: string;
    version: string;
}
export interface UpdateEasySupplyGatewayFirmwareDetailsRequest {
    gatewayHardwareIdentifier: string;
    host: string;
    port: number;
    uri: string;
    version: string;
}
export interface SetEmailForManualOrderFlowOnWarehouseRequest {
    emailsForManualOrderFlow: string[];
    warehouseId: number;
}
export interface ReplaceEasySupplyGatewayRequest {
    currentGatewayIdentifier: string;
    easySupplyWarehouseId: number;
    newGatewayIdentifier: string;
}
export interface ReconfigureEasySupplyGatewaysRequest {
    addressDeliveryType: DeliveryType;
    deliveryAddressSapId: string;
    easySupplyWarehouseId: number;
    expectedOrderTime: string;
    weekdaysAllowedToSendOrder: Weekday[];
}
export interface EasySupplyOrderLinesRequest {
    easySupplyWarehouseId: number;
    manualOrder: boolean;
}
export interface DeleteEasySupplyGatewayRequest {
    easySupplyWarehouseId: number;
    gatewayHardwareIdentifier: string;
}
export interface AddEasySupplySmartButtonRequest {
    easySupplyWarehouseLineId: number;
    hardwareIdentifier: string;
    reorderThreshold: number;
}
export interface AddEasySupplyGatewayRequest {
    addressDeliveryType: DeliveryType;
    deliveryAddressSapId: string;
    easySupplyWarehouseId: number;
    expectedOrderTime: string;
    hardwareIdentifier: string;
    weekdaysAllowedToSendOrder: Weekday[];
}
export interface DeliveryDateViewObject {
    date: string;
    prefered: boolean;
}
export interface DeliveryMethodRequest {
    id: DeliveryType;
}
export interface DeliveryMethodsViewModel {
    options: DeliveryMethodViewObject[];
}
export interface DeliveryMethodViewObject {
    displayName: string;
    displayText: string;
    enabled: boolean;
    id: string;
    isDayDelivery: boolean;
}
export interface DeliveryViewModel {
    availableDeliveryDates: DeliveryDateViewObject[];
    order: OrderViewModel;
}
export interface CreateDeliveryAddressRequest {
    city: string;
    citySubdivisionName: string;
    deliveryMethodId: DeliveryType;
    door: string;
    floor: string;
    name: string;
    name2: string;
    postalCode: string;
    streetName: string;
    streetNumber: string;
}
export interface GetDeliveryAddressesRequest {
    deliveryMethod: DeliveryType;
}
export interface SetDeliveryAddressRequest {
    id: string;
}
export interface SetPreferredDeliveryAddressRequest {
    deliveryAddressId: string;
    deliveryMethod: DeliveryType;
}
export interface ArticleSearchRequest {
    manufacturer: string;
    page: number | null;
    productCategory: string;
    publicationYearFrom: number | null;
    publicationYearTo: number | null;
    term: string | null;
    topic: string;
}
export interface ArticleSearchResponse {
    manufacturer: ContentFacetValue[];
    pageSize: number;
    productCategories: ContentFacetValue[];
    publicationYear: ContentFacetValue[];
    results: ArticleViewObject[];
    topics: ContentFacetValue[];
    totalResultCount: number;
}
export interface ArticleViewObject {
    heading: string;
    id: number;
    publicationDate: string;
    subHeading: string;
    thumbnailUrl: string;
    url: string;
}
export interface LatestArticleTeasersResponse {
    articles: LinkItem[];
    url: string;
}
export interface Parameter {
    name: string;
    value: string;
}
export interface ProductNewsTeaserViewModel {
    articles: LinkItem[];
    url: string;
}
export interface RecommendationViewObject {
    method: string;
    parameters: Parameter[];
}
export interface ServiceContentViewObject {
    description: string;
    imageUrl: string;
    name: string;
    url: string;
}
export interface ServiceGroupContentListViewModel {
    serviceGroups: ServiceGroupContentViewModel[];
}
export interface ServiceGroupContentViewModel {
    name: string;
    services: ServiceContentViewObject[];
}
export interface ContentFacetValue {
    count: number;
    displayName: string;
    value: string;
}
export enum SiteName {
    Denmark = 0,
    Poland = 1,
    Germany = 2,
    Lithuania = 3,
    Latvia = 4,
    Netherlands = 5
}
export interface LinkItem {
    name: string;
    url: string;
}
export interface AssignedSalesAccountsToUserViewModel {
    assignedSalesAccounts: AssignedSalesAccountToUserViewObject[];
    name: string;
}
export interface AssignedSalesAccountToUserViewObject {
    isActive: boolean;
    soldToPartnerId: string;
    soldToPartnerName: string;
}
export interface FavoriteSalesAccountsViewModel {
    favoriteSalesAccounts: FavoriteSalesAccountViewObject[];
}
export interface FavoriteSalesAccountViewObject {
    name: string;
    salesAccountId: number;
    soldToPartnerId: string;
    sortOrder: number;
}
export interface HelpDeskAccountAdministrationListViewModel {
    billToAccountsForAdministrationViewObject: HelpDeskAccountAdministrationViewModel[];
    billToName: string;
}
export interface HelpDeskAccountAdministrationViewModel {
    allowNewUsers: boolean;
    displayName: string;
    salesAccountId: number;
    soldToPartnerId: string;
}
export interface HelpDeskProductCatalogListViewModel {
    productCatalogs: HelpDeskProductCatalogViewObject[];
}
export interface HelpDeskProductCatalogViewObject {
    id: number;
    name: string;
}
export interface HelpDeskSalesAccountsViewObject {
    anyActiveUsers: boolean;
    billToPartnerId: string;
    isActive: boolean;
    salesAccountId: number;
    soldToPartnerId: string;
    soldToPartnerName: string;
    soldToPhoneNumber: string;
    users: HelpDeskUserViewObjectSlim[];
}
export interface HelpDeskUserViewObject extends HelpDeskUserViewObjectSlim {
    active: boolean;
    emailAddress: string;
    hasActiveAccounts: boolean;
    id: number;
    name: string;
    rolesForSalesAccounts: RolesForSalesAccountViewObject[];
    username: string;
}
export interface HelpDeskUserViewObjectSlim {
    id: number;
    username: string;
}
export interface ProductCatalogImportResultViewModel {
    catalogs: HelpDeskProductCatalogListViewModel;
    errors: FavoriteOrderImportLineErrorViewObject[];
}
export interface RolesForSalesAccountViewObject {
    active: boolean;
    primaryAccount: boolean;
    roles: string[];
    soldToPartnerId: string;
}
export interface SearchSalesAccountsViewModel {
    currentPage: number;
    salesAccounts: HelpDeskSalesAccountsViewObject[];
    totalPages: number;
    totalResults: number;
}
export interface SearchUsersViewModel {
    currentPage: number;
    totalPages: number;
    totalResults: number;
    users: HelpDeskUserViewObject[];
}
export interface UserAgreementsViewModel {
    hasAcceptedDataPolicy: boolean;
    hasAcceptedTwentyFourSevenAgreement: boolean;
    hasAcceptedWebAgreement: boolean;
    twentyFourSevenAgreementAcceptanceDateTime: string;
    username: string;
    webAgreementAcceptanceDateTime: string;
}
export enum ImpersonationRedirectType {
    FrontPage = 0,
    EditUser = 1
}
export interface AccountImpersonationRequest {
    currentUrl: string;
    soldToPartnerId: string;
}
export interface AddFavoriteAccountRequest {
    salesAccountId: number;
}
export interface AddProjectFolderItemLinkRequest {
    salesAccountId: number;
    title: string;
    url: string;
}
export interface AssignedAccountsToUserRequest {
    userId: number;
}
export interface AssignUserToSalesAccountRequest {
    phoneNumber: string;
    soldToPartnerId: string;
    userId: number;
}
export interface CreateUserFromHelpDeskRequest {
    email: string;
    mobileNumber: string;
    name: string;
    soldToPartnerId: string;
    soldToPhoneNumber: string;
    storeId: string;
    userName: string;
}
export interface GetAccountForUpdateAllowNewUsersRequest {
    soldToPartnerSalesAccountId: number;
}
export interface GetAccountsForUpdateAllowNewUsersRequest {
    billToPartnerSalesAccountId: number;
}
export interface GetProductCatalogsRequest {
    salesAccountId: number;
}
export interface GetProjectFolderItemsRequest {
    salesAccountId: number;
    type: ProjectFolderItemType;
}
export interface ImpersonationRequest {
    currentUrl: string;
    redirectTo: ImpersonationRedirectType;
    soldToPartnerId: string | null;
    userId: number;
}
export interface MoveFavoriteAccountRequest {
    direction: Direction;
    salesAccountId: number;
}
export interface NewMainAccountAndNewUserEmailForAccountRequest {
    accountId: number;
    userId: number;
}
export interface SearchSalesAccountsRequest {
    page: number;
    soldToPartnerId: string;
    term: string;
}
export interface SearchUsersRequest {
    page: number;
    soldToPartnerId: string;
    term: string;
}
export interface UpdateAllowNewUsersOnAccountRequest {
    accounts: UpdateAllowNewUsersOnAccount[];
}
export interface UserAgreementsRequest {
    userId: number;
}
export interface UpdateAllowNewUsersOnAccount {
    allowNewUsers: boolean;
    salesAccountId: number;
}
export interface UpdateAllowNewUsersOnAccountsRequest {
    accounts: UpdateAllowNewUsersOnAccount[];
}
export interface CreateOrUpdateFavoriteOrderRequest {
    accountGroupIds: string[];
    caseId: string;
    favoriteOrderId: number | null;
    name: string;
    readonly: boolean;
    referenceId: string;
    requestId: string;
    shared: boolean;
}
export interface UpdateFavoriteOrderSpecialLineItemRequest {
    certificateCode: string | null;
    displayName: string;
    favoriteOrderId: number;
    fixedUnitValue: number | null;
    lineItemId: number;
    manufacturer: string;
    productItemNumber: string;
    productText: string;
    quantity: number;
    supplementaryProductText: string;
    unitCode: string;
}
export interface UpdateFavoriteOrderLineItemRequest {
    certificateCode: string | null;
    favoriteOrderId: number;
    fixedUnitCode: string | null;
    fixedUnitValue: number | null;
    isFixedUnit: boolean;
    lineItemId: number;
    productId: string;
    quantity: number;
    stamping: boolean;
    unitCode: string;
}
export interface ImportFavoriteOrderRequest {
    favoriteOrderId: number;
    mergeType: FavoriteListMergeType;
    newFavoriteOrderName: string;
}
export interface DownloadFavoriteOrderAsCsvRequest {
    favoriteOrderId: number;
    lineSearchTerm: string;
    sortBy: string;
}
export interface DeleteAllFavoriteOrderLinesRequest {
    favoriteOrderId: number;
}
export interface DeleteFavoriteOrderLineRequest {
    favoriteOrderId: number;
    isSpecialLineItem: boolean;
    lineId: number;
}
export interface CopyTop100ProductsToFavoriteOrderRequest {
    favoriteOrderName: string;
    monthsBack: number;
    shared: boolean;
    targetFavoriteOrderId: number | null;
}
export interface AddFavoriteOrderLineItemRequest {
    certificateCode: string | null;
    favoriteOrderId: number | null;
    fixedUnitCode: string | null;
    fixedUnitValue: number | null;
    isFixedUnit: boolean;
    newFavoriteOrderName: string | null;
    productId: string;
    quantity: number;
    shared: boolean | null;
    stamping: boolean | null;
    unitCode: string;
}
export interface AddFavoriteOrderSpecialLineItemRequest {
    certificateCode: string;
    displayName: string;
    favoriteOrderId: number;
    fixedUnitValue: number | null;
    manufacturer: string;
    productItemNumber: string;
    productText: string;
    quantity: number;
    supplementaryProductText: string;
    unitCode: string;
}
export interface QuickAddFavoriteOrderLineItemRequest {
    favoriteOrderId: number;
    productId: string;
    quantity: number;
}
export interface PagedFavoriteOrdersRequest {
    page: number;
    pageSize: number;
}
export interface CopyFavoriteOrderRequest {
    favoriteOrderName: string;
    shared: boolean;
    sourceFavoriteOrderId: number;
    targetSoldToPartnerId: string;
}
export interface MergeFavoriteOrderRequest {
    sourceFavoriteOrderId: number;
    targetFavoriteOrderId: number;
}
export interface AccountGroupSlim {
    id: string;
    name: string;
}
export interface GetFavoriteOrderRequest {
    favoriteOrderId: number;
    lineSearchTerm: string;
    page: number;
    sortBy: string;
}
export interface GetFilteredFavoriteOrderByProductIdsRequest {
    favoriteOrderId: number;
    page: number;
    productIds: string[];
    sortBy: string;
}
export interface GetIdsInFavoriteOrderBySourceFieldRequest {
    favoriteOrderId: number;
    sourceFilter: SourceFilter;
}
export interface ContactsViewModel {
    contactLists: ContactTypeListViewObject[];
}
export interface ContactTypeListViewObject {
    contacts: ContactViewObject[];
    contactType: string;
    headLine: string;
}
export interface ContactViewObject {
    emailAddress: string;
    employeeImageUrl: string;
    header: string;
    headLine: string;
    initials: string;
    mobileNumber: string;
    name: string;
    phoneNumber: string;
    showContactInformation: boolean;
    title: string;
}
export interface ClimateCertificateViewModel {
    description: string;
    iconUrl: string;
}
export interface CampaignRequest {
    campaignId: string;
    numOfResults: number;
    parameters: RecommendationParameter[];
}
export interface UpdateOrCreateBuyerItemRequest {
    customerProductId: string;
    variantId: string;
}
export interface UpdateOrCreateBuyerItemResponse {
}
export interface ISearchHistoryRequest {
    caseNumber: string;
    fromDate: string;
    orderNumber: string;
    page: number | null;
    pageSize: number | null;
    productId: string;
    productIdType: ProductIdType;
    reference: string;
    toDate: string;
}
export interface ISearchOrderHistoryRequest {
    requisitionNumber: string;
}
export interface LatestOrderResumeViewModel {
    orders: OrderResumeViewObject[];
}
export interface OrderCertificateDetailsLineViewObject {
    certificatePrice: string;
    chargeName: string;
    downloadUrl: string;
    lineId: string;
    orderLineText: string;
    productId: string;
    quantity: number | null;
    unit: UnitViewObject;
}
export interface OrderCertificateDetailsViewModel {
    orderId: string;
    orderLines: OrderCertificateDetailsLineViewObject[];
}
export interface OrderDetailsLineViewObject {
    activeItem: boolean;
    certificateId: string;
    certificateOrdered: boolean;
    certificatePrice: string;
    charges: string[];
    lineId: string;
    orderLineNetPrice: string;
    orderLineText: string;
    productId: string;
    quantity: number | null;
    shippingId: string;
    stampingPrice: string;
    unit: UnitViewObject;
    url: string;
}
export interface OrderDetailsViewModel {
    caseId: string;
    certificateDetailsUrl: string;
    downloadOrderConfirmationUrl: string;
    netAmount: Money;
    orderId: string;
    orderLines: OrderDetailsLineViewObject[];
    orderType: string;
    receiver: string;
    reference: string;
    requestId: string;
    showNetPrices: boolean;
    status: string;
    trackAndTraceUrl: string;
}
export interface OrderHeaderViewObject {
    caseId: string;
    certificateDetailsUrl: string;
    certificateOrdered: boolean;
    certificateStatus: string;
    downloadOrderConfirmationUrl: string;
    netAmount: string;
    orderDate: string;
    orderId: string;
    orderResponseExists: boolean;
    orderStatus: string;
    orderType: string;
    receiver: string;
    reference: string;
    requestId: string;
    url: string;
}
export interface OrderHistoryListViewModel {
    orderHeaders: OrderHeaderViewObject[];
    totalPages: number;
}
export interface OrderResumeViewObject {
    orderDate: string;
    orderId: string;
    orderStatus: string;
    url: string;
}
export enum CurrencyIsoCode {
    AED = 784,
    AFN = 971,
    ALL = 8,
    AMD = 51,
    ANG = 532,
    AOA = 973,
    ARS = 32,
    AUD = 36,
    AWG = 533,
    AZN = 944,
    BAM = 977,
    BBD = 52,
    BDT = 50,
    BGN = 975,
    BHD = 48,
    BIF = 108,
    BMD = 60,
    BND = 96,
    BOB = 68,
    BOV = 984,
    BRL = 986,
    BSD = 44,
    BTN = 64,
    BWP = 72,
    BYN = 933,
    BYR = 974,
    BZD = 84,
    CAD = 124,
    CDF = 976,
    CHE = 947,
    CHF = 756,
    CHW = 948,
    CLF = 990,
    CLP = 152,
    CNY = 156,
    COP = 170,
    COU = 970,
    CRC = 188,
    CUC = 931,
    CUP = 192,
    CVE = 132,
    CZK = 203,
    DJF = 262,
    DKK = 208,
    DOP = 214,
    DZD = 12,
    EEK = 233,
    EGP = 818,
    ERN = 232,
    ETB = 230,
    EUR = 978,
    FJD = 242,
    FKP = 238,
    GBP = 826,
    GEL = 981,
    GHS = 936,
    GIP = 292,
    GMD = 270,
    GNF = 324,
    GTQ = 320,
    GYD = 328,
    HKD = 344,
    HNL = 340,
    HRK = 191,
    HTG = 332,
    HUF = 348,
    IDR = 360,
    ILS = 376,
    INR = 356,
    IQD = 368,
    IRR = 364,
    ISK = 352,
    JMD = 388,
    JOD = 400,
    JPY = 392,
    KES = 404,
    KGS = 417,
    KMF = 174,
    KHR = 116,
    KPW = 408,
    KRW = 410,
    KWD = 414,
    KYD = 136,
    KZT = 398,
    LAK = 418,
    LBP = 422,
    LKR = 144,
    LRD = 430,
    LSL = 426,
    LTL = 440,
    LVL = 428,
    LYD = 434,
    MAD = 504,
    MDL = 498,
    MGA = 969,
    MKD = 807,
    MMK = 104,
    MNT = 496,
    MOP = 446,
    MRO = 478,
    MRU = 929,
    MUR = 480,
    MVR = 462,
    MWK = 454,
    MXN = 484,
    MXV = 979,
    MYR = 458,
    MZN = 943,
    NAD = 516,
    NGN = 566,
    NIO = 558,
    NOK = 578,
    NPR = 524,
    NZD = 554,
    OMR = 512,
    PAB = 590,
    PEN = 604,
    PGK = 598,
    PHP = 608,
    PKR = 586,
    PLN = 985,
    PYG = 600,
    QAR = 634,
    RON = 946,
    RSD = 941,
    RUB = 643,
    RWF = 646,
    SAR = 682,
    SBD = 90,
    SCR = 690,
    SDG = 938,
    SEK = 752,
    SGD = 702,
    SHP = 654,
    SLL = 694,
    SOS = 706,
    SRD = 968,
    SSP = 728,
    STD = 678,
    STN = 930,
    SVC = 222,
    SYP = 760,
    SZL = 748,
    THB = 764,
    TJS = 972,
    TMT = 934,
    TND = 788,
    TOP = 776,
    TRY = 949,
    TTD = 780,
    TWD = 901,
    TZS = 834,
    UAH = 980,
    UGX = 800,
    USD = 840,
    USN = 997,
    USS = 998,
    UYI = 940,
    UYU = 858,
    UYW = 927,
    UZS = 860,
    VEF = 937,
    VES = 928,
    VND = 704,
    VUV = 548,
    WST = 882,
    XAF = 950,
    XAG = 961,
    XAU = 959,
    XBA = 955,
    XBB = 956,
    XBC = 957,
    XBD = 958,
    XCD = 951,
    XDR = 960,
    XOF = 952,
    XPD = 964,
    XPF = 953,
    XPT = 962,
    XSU = 994,
    XTS = 963,
    XUA = 965,
    XXX = 999,
    YER = 886,
    ZAR = 710,
    ZMK = 894,
    ZMW = 967,
    ZWL = 932
}
export interface Money {
    amount: number;
    currencyCode: CurrencyIsoCode;
    hasDecimals: boolean;
    majorAmount: number;
    majorIntegralAmount: number;
    minorAmount: number;
    minorIntegralAmount: number;
    minValue: Money;
}
export interface GetLatestOrdersRequest {
    maxElements: number | null;
}
export interface GetOrderCertificateDetailsRequest {
    orderId: string;
}
export interface GetOrderDetailsRequest {
    orderId: string;
}
export interface SearchOrderHistoryRequest {
    caseNumber: string;
    fromDate: string;
    onlyCertificateOrders: boolean;
    orderNumber: string;
    page: number | null;
    pageSize: number | null;
    productId: string;
    productIdType: ProductIdType;
    reference: string;
    requisitionNumber: string;
    toDate: string;
}
export interface InvoiceHeaderViewObject {
    caseId: string;
    downloadUrl: string;
    netAmount: string;
    orderDate: string;
    receiver: string;
    reference: string;
    requestId: string;
    status: string;
    voucherId: string;
    voucherType: string;
}
export interface InvoiceHistoryListViewModel {
    invoiceHeaders: InvoiceHeaderViewObject[];
    totalPages: number;
}
export enum InvoiceIdType {
    All = 0,
    Invoice = 1,
    CreditNota = 2,
    RateNota = 3,
    RentNota = 4
}
export interface SearchInvoiceHistoryRequest {
    caseNumber: string;
    fromDate: string;
    invoiceIdType: InvoiceIdType;
    invoiceNumber: string;
    orderNumber: string;
    page: number | null;
    pageSize: number | null;
    productId: string;
    productIdType: ProductIdType;
    reference: string;
    requisitionNumber: string;
    toDate: string;
}
export interface DeliveryNoteHeaderViewObject {
    caseNumber: string;
    deliveryNoteDate: string;
    deliveryNoteNo: string;
    deliveryNoteUrl: string;
    orderDate: string;
    orderNumber: string;
    orderReference: string;
    requisitionNumber: string;
    status: string;
    trackAndTraceUrl: string;
}
export interface DeliveryNoteHistoryListViewModel {
    deliveryNoteHeaders: DeliveryNoteHeaderViewObject[];
    totalPages: number;
}
export interface DeliveryNoteLineItemViewObject {
    lineId: string;
    orderLineText: string;
    productId: string;
    quantity: string;
    unit: UnitViewObject;
}
export interface DeliveryNoteViewModel {
    deliveryNoteId: string;
    dispatches: DispatchViewObject[];
    orderNumber: string;
}
export interface DispatchViewObject {
    courier: Courier;
    lineIds: DeliveryNoteLineItemViewObject[];
    trackAndTraceId: string;
}
export interface GetDeliveryNotesRequest {
    deliveryNodeId: string;
}
export interface SearchDeliveryNotesHistoryRequest {
    caseNumber: string;
    fromDate: string;
    orderNumber: string;
    page: number | null;
    pageSize: number | null;
    productId: string;
    productIdType: ProductIdType;
    reference: string;
    requisitionNumber: string;
    toDate: string;
}
export interface DownloadCertificateRequest {
    chargeNumber: string;
    lineId: string;
    orderDate: string;
    supplierOrderId: string;
}
export interface GetAccountStatementsRequest {
    fromDate: string;
    toDate: string;
}
export interface OrderConfirmationMailViewModel extends BaseEmailMessage {
    basket: OrderViewModel;
    deliveryDateText: string;
    deliveryText: string;
    deliveryTimeText: string;
    isFlexBox: boolean;
    manual: boolean;
    showPrices: boolean;
}
export interface AddBomProductToBasketRequest {
    bomProductId: string;
}
export interface AddCommentsRequest {
    caseInformation: CaseInformationRequest;
    comment: string;
}
export interface AddEasySupplyOrderLinesRequest {
    easySupplyWarehouseId: number;
}
export interface AddOrderToBasketRequest {
    orderId: string;
}
export interface AddOrUpdateSpecialOrderLineItemRequest {
    caseInformation: CaseInformationRequest | null;
    lineItemId: string | null;
    manufacturer: string;
    productItemNumber: string;
    productText: string;
    quantity: number;
    supplementaryProductText: string;
    unitCode: string;
}
export interface AddProjectOrderQuotationToBasketRequest {
    lines: ProjectOrderQuotationItem[];
    projectOrderId: string;
}
export interface AddQuotationToBasketRequest {
    lines: QuotationItem[];
    quotationId: string;
}
export interface AddToBasketRequest {
    addAsNewLine: boolean;
    caseInformation: CaseInformationRequest | null;
    certificateCode: string | null;
    fixedUnitCode: string | null;
    fixedUnitValue: number | null;
    isFixedUnit: boolean;
    productId: string;
    quantity: number;
    stamping: boolean;
    unit: string;
}
export interface CaseInformationRequest {
    caseNumber: string;
    reference: string;
    requisitionNumber: string;
}
export interface ConvertToFavoriteOrderRequest {
    favoriteOrderId: number | null;
    newFavoriteOrderName: string | null;
    shared: boolean;
}
export interface CopyToAccountRequest {
    accountNumber: string;
}
export interface CreateBasketRequest {
    displayName: string;
}
export interface QuickAddToBasketRequest {
    productId: string;
    quantity: number;
}
export interface SetPhoneNumberRequest {
    phoneNumber: string;
}
export interface SubmitOrderRequest {
    digitalCommunicationEmail: string | null;
    digitalCommunicationPhone: string | null;
    raptorSessionId: string;
    smsServiceNumber: string;
}
export interface UpdateBasketDisplayNameRequest {
    basketIdentifier: string;
    displayName: string;
}
export interface UpdateLineItemRequest {
    certificateCode: string | null;
    customerReference: string | null;
    fixedUnitCode: string | null;
    fixedUnitValue: number | null;
    isFixedUnit: boolean;
    lineItemId: string;
    quantity: number;
    stamping: boolean;
    unit: string;
}
export interface ProjectOrderQuotationItem {
    lineId: string;
    quantity: number;
}
export interface QuotationItem {
    lineId: string;
    quantity: number;
}
export interface BasketForApprovalViewObject {
    basketApproverIdentifier: string | null;
    basketDisplayName: string;
    basketIdentifier: string;
    basketOwnerName: string;
    numberOfLines: number;
    sentForApprovalDate: string;
    status: ApprovalStatus | null;
}
export interface GetAviouFeedRequest {
    id: string;
}
export interface CheckoutCaseValidation {
    mask: string;
    maskRegex: string;
    required: boolean;
}
export interface ForgotPasswordResponse {
    hasMultipleAccounts: boolean;
    passwordWasResend: boolean;
}
export interface LoginInformation {
    accounts: SalesAccountsGroupViewObject[];
    basketStoreId: string;
    canPurchase: boolean;
    canSeeGrossPrices: boolean;
    canSeeHelpDesk: boolean;
    canSeeNetPrices: boolean;
    canSeeSearchManagement: boolean;
    canSubmitOrder: boolean;
    currentAccount: SalesAccountViewObject;
    fakeContactType: string;
    flexBoxZone: string;
    isImpersonating: boolean;
    primaryContact: ContactViewObject;
    punchOutProvider: PunchOutProvider | null;
    store: StoreViewObject;
    userEmailAddress: string;
    userId: number;
}
export interface MustChangePasswordRequest {
    confirmPassword: string;
    newPassword: string;
    userName: string;
}
export interface ResetPasswordRequest {
    key: string;
    newPassword: string;
}
export interface SalesAccountsGroupViewObject {
    displayName: string;
    salesAccounts: SalesAccountViewObject[];
}
export interface SalesAccountViewObject {
    billToPartnerId: string;
    caseNumber: CheckoutCaseValidation;
    customerGroup: string;
    priceGroup: string;
    referenceContactPerson: CheckoutCaseValidation;
    requisitionNumber: CheckoutCaseValidation;
    soldToPartnerId: string;
    soldToPartnerName: string;
    zaContactTypes: string;
}
export interface ForgotPasswordMailViewModel extends BaseEmailMessage {
    activationKey: string;
    changePasswordUrl: string;
    name: string;
    soldToPartnerId: string;
    username: string;
}
export interface ChangeAccountRequest {
    soldToPartnerId: string;
}
export interface ForgotPasswordRequest {
    email: string;
    username: string;
}
export interface LoginRequest {
    currentUrl: string;
    password: string;
    username: string;
}
export interface ArticlePromotionTileViewObject {
    backgroundColor: string;
    body: string;
    header: string;
    image: string;
    link: SingleUrlPickerViewObject;
}
export interface MarkAgreementAsAcceptedRequest {
    agreementId: number;
    optOutFromEmails: boolean | null;
}
export interface MarkAgreementAsPresentedRequest {
    agreementId: number;
}
export interface AgreementViewModel {
    agreement: AgreementViewObject;
}
export interface AgreementViewObject {
    agreementAcceptanceType: string;
    agreementNavigationId: string;
    allowWait: boolean;
    bodyText: string;
    descriptionText: string;
    headerText: string;
    id: number;
}
export interface AddressLookupViewModel {
    city: string;
    citySubdivision: string;
    door: string;
    floor: string;
    number: string;
    postalCode: string;
    street: string;
}
export interface AddressSuggestionsViewModel {
    suggestions: AddressSuggestionViewObject[];
}
export interface AddressSuggestionViewObject {
    description: string;
    id: string;
}
export interface UpdateMyPasswordRequest {
    confirmPassword: string;
    password: string;
}
export interface UpdateUserPasswordRequest {
    confirmPassword: string;
    password: string;
    targetUserIdentifier: string;
}
export interface AccountRelationViewObject {
    accountAccess: string;
    canPurchase: EditableRoleViewObject;
    canPurchaseWithApproval: EditableRoleViewObject;
    canSeeAccountInformation: EditableRoleViewObject;
    canSeeGrossPrices: EditableRoleViewObject;
    canSeeNetPrices: EditableRoleViewObject;
    hasAccess: EditableRoleViewObject;
    isPrimaryAccount: boolean;
    isReadOnly: boolean;
    purchaseApprover: PurchaseApproverViewObject;
    soldToPartnerId: string;
    soldToPartnerName: string;
}
export interface ConfirmDeleteFavoriteOrderViewObject {
    id: number;
    name: string;
}
export interface ConfirmDeleteUserViewObject {
    isLastAdminOnAnyAccount: boolean;
    sharedFavoriteOrders: FavoriteOrdersPerAccountViewObject[];
    usersByCarbonCopyRecipient: UsersByCarbonCopyRecipientViewObject[];
}
export interface ConfirmUserDeletionRequest {
    favoriteTransfers: FavoriteOrderTransferViewObject[];
    userIdentifier: string;
}
export interface DeleteUserRequest {
    userIdentifier: string;
}
export interface DeleteUserResultViewModel {
    confirmation: ConfirmDeleteUserViewObject;
    userWasDeleted: boolean;
}
export interface EditableRoleViewObject {
    canBeEdited: boolean;
    hasRole: boolean;
}
export interface FavoriteOrdersPerAccountViewObject {
    confirmDeleteFavoriteOrders: ConfirmDeleteFavoriteOrderViewObject[];
    soldToPartnerId: string;
    soldToPartnerName: string;
}
export interface FavoriteOrderTransferViewObject {
    favoriteOrderId: number;
}
export interface MailSubscriptionViewModel {
    subscribedToMarketingMails: boolean;
}
export interface PurchaseApproverViewObject {
    approverId: string;
    approverName: string;
}
export interface SetUserStoreIdRequest {
    storeId: string;
}
export interface UserAdministrationListViewObject {
    canDeleteUser: boolean;
    editUserUrl: string;
    lastLogin: string;
    lastLoginSortable: string;
    name: string;
    status: string;
    userIdentifier: string;
    username: string;
    userType: string;
}
export interface UserAdministrationViewModel {
    currentAccountNumber: string;
    users: UserAdministrationListViewObject[];
}
export interface UserCreationViewModel {
    stores: UserStoreViewObject[];
}
export interface UsersByCarbonCopyRecipientViewObject {
    email: string;
    name: string;
    primarySoldToPartnerId: string;
    username: string;
}
export interface UserStoreViewObject {
    id: string;
    isMyStore: boolean;
    name: string;
}
export interface UserViewModel {
    accountRelations: AccountRelationViewObject[];
    activeStatus: boolean;
    canEditActiveStatus: boolean;
    canEditDeveloperRole: boolean;
    canEditEasySupplyAdminRole: boolean;
    canEditPunchOutRole: boolean;
    canEditTwentyFourSevenRole: boolean;
    canEditUser: boolean;
    canEditUsername: boolean;
    canRemoveTwentyFourSevenRole: boolean;
    comments: string;
    emailAddress: string;
    hasPunchOutCustomXslt: boolean;
    hasTwentyFourSevenAccess: boolean;
    identifier: string;
    isDeveloper: boolean;
    isEasySupplyAdmin: boolean;
    isPunchOut: boolean;
    isSubscribedToMarketingMails: boolean;
    name: string;
    orderReceiptsRecipients: string;
    primarySoldToPartnerId: string;
    receiveOrderReceipts: boolean;
    storeId: string;
    stores: UserStoreViewObject[];
    username: string;
    webMobileNumber: string;
}
export interface AdminUserResponse {
    identifier: string;
    name: string;
}
export interface CreateUserFromAdminRequest {
    active: boolean;
    carbonCopyRecipients: string;
    comments: string;
    email: string;
    isDeveloper: boolean | null;
    isEasySupplyAdmin: boolean | null;
    isPunchOut: boolean | null;
    mobileNumber: string;
    name: string;
    primarySoldToPartnerId: string;
    receiveOrderReceipts: boolean;
    storeId: string;
    updateUserRelations: UpdateUserRelations[];
    username: string;
}
export interface CreateUserRequest {
    email: string;
    mobileNumber: string;
    name: string;
    soldToPartnerId: string;
    soldToPhoneNumber: string;
    storeId: string;
    userName: string;
}
export interface UpdateCurrentUserRequest {
    active: boolean | null;
    carbonCopyRecipients: string;
    comments: string;
    email: string;
    hasTwentyFourSevenAccess: boolean | null;
    isDeveloper: boolean | null;
    isEasySupplyAdmin: boolean | null;
    isPunchOut: boolean | null;
    mobileNumber: string;
    name: string;
    primarySoldToPartnerId: string;
    receiveOrderReceipts: boolean;
    storeId: string;
    updateUserRelations: UpdateUserRelations[];
    username: string;
}
export interface UpdateUserRelations {
    approverIdentifier: string | null;
    canPurchase: boolean | null;
    canPurchaseWithApproval: boolean | null;
    canSeeAccountInformation: boolean | null;
    canSeeGrossPrices: boolean | null;
    canSeeNetPrices: boolean | null;
    hasAccess: boolean | null;
    isAdminForAccount: boolean | null;
    soldToPartnerId: string;
}
export interface UpdateUserRequest {
    active: boolean | null;
    carbonCopyRecipients: string;
    comments: string;
    email: string;
    hasTwentyFourSevenAccess: boolean | null;
    isDeveloper: boolean | null;
    isEasySupplyAdmin: boolean | null;
    isPunchOut: boolean | null;
    mobileNumber: string;
    name: string;
    primarySoldToPartnerId: string;
    receiveOrderReceipts: boolean;
    storeId: string;
    updateUserRelations: UpdateUserRelations[];
    userIdentifier: string;
    username: string;
}
export interface UserActivationMailViewModel extends BaseEmailMessage {
    confirmationUrl: string;
    username: string;
}
export interface UserRegistrationNotificationMailViewModel extends BaseEmailMessage {
    accountName: string;
    fullName: string;
    soldToPartnerId: string;
    username: string;
}
export interface AddAccountToUserRequest {
    phoneNumber: string;
    soldToPartnerId: string;
}
export interface CreateAccountCompanyRequest {
    cvr: string;
    email: string;
    phone: string;
}
export interface CreateAccountRequest {
    company: CreateAccountCompanyRequest;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    userName: string;
}
export interface AccountAndUserCreatedMailViewModel extends BaseEmailMessage {
    accountName: string;
    confirmationUrl: string;
    soldToPartnerId: string;
    storeName: string;
    storePhone: string;
    username: string;
}
export interface AccountCreatedMailViewModel extends BaseEmailMessage {
    accountName: string;
    confirmationUrl: string;
    soldToPartnerId: string;
    username: string;
}
export interface AvailableAccountsForAdministrationViewModel {
    billToAccountsForAdministrationViewModel: BillToAccountsForAdministrationViewModel[];
}
export interface BillToAccountsForAdministrationViewModel {
    billToName: string;
    salesAccountsForAdministration: SalesAccountForAdministrationViewObject[];
}
export interface SalesAccountForAdministrationViewObject {
    allowNewUsers: boolean;
    displayName: string;
    salesAccountId: number;
    soldToPartnerId: string;
}
export interface AccountGroupRequestViewModel {
    name: string;
    salesAccountIds: number[];
}
export interface AccountGroupResponseSlimViewModel {
    id: string;
    name: string;
    numberOfAccounts: number;
}
export interface AccountGroupResponseViewModel {
    id: string;
    name: string;
    salesAccounts: SalesAccountSlim[];
}
export interface SalesAccountSlim {
    id: number;
    isCurrentUserAdmin: boolean | null;
    soldToPartnerId: string;
    soldToPartnerName: string;
}
export interface PageDataViewModel {
    content: string | null;
    featureToggling: IFeatureToggling;
    isNotFound: boolean;
    pageData: PageData;
    sites: SiteViewObject[];
}
export interface ClientServerContext {
    jsonModel: string;
}
export interface Localization {
    language: string;
    siteName: string;
}
export interface PageData {
    breadcrumb: LinkItem[];
    infoMessages: InfoMessagesViewModel[];
    isCheckoutView: boolean;
    isNotFound: boolean;
    isPrerenderRequest: boolean;
    leftNavigation: NavigationItem;
    metadata: MetadataViewModel;
    pageType: string;
    raptorPageData: RaptorPageData;
    useFallbackCookieConsent: boolean;
}
export interface ServerContext {
    applicationCacheBuster: string;
    audiences: string;
    cookieBotContextData: CookieBotContextData;
    currency: string;
    featureToggling: IFeatureToggling;
    localization: Localization;
    loginInformation: LoginInformation | null;
    nudgeOnBasket: boolean;
    nudgeOnPDP: boolean;
    nudgeOnPLP: boolean;
    nudgeOnTypeahead: boolean;
    pageData: PageData;
    raptorContextData: RaptorContextData;
    serverName: string;
    sitePages: SitePages;
    sites: SiteViewObject[];
}
export interface SitePage {
    displayName: string;
    url: string;
}
export interface SitePages {
    basketPage: SitePage;
    checkoutPage: SitePage;
    cookieInfoPage: SitePage;
    createUserPage: SitePage;
    distributionPage: SitePage;
    favoriteOrdersPage: SitePage;
    flexBoxPage: SitePage;
    frontPage: SitePage;
    helpAndContactPage: SitePage;
    myPage: SitePage;
    orderDetailsPage: SitePage;
    orderHistoryPage: SitePage;
    punchOutCheckoutPage: SitePage;
    receiptPage: SitePage;
    searchPage: SitePage;
    selectedPage: SitePage;
    storesPage: SitePage;
    supportGuidesPage: SitePage;
    termsPage: SitePage;
    top100Page: SitePage;
    userEditPage: SitePage;
}
export interface MetadataViewModel {
    breadcrumbList: BreadcrumbList;
    description: string;
    pageTitle: string;
    url: string;
}
export interface BreadcrumbList extends ItemList {
    type: string;
}
export interface IAction {
    actionStatus: ActionStatusType[];
    agent: any[];
    endTime: any[];
    error: IThing[];
    instrument: IThing[];
    location: any[];
    object: IThing[];
    participant: any[];
    result: IThing[];
    startTime: any[];
    target: any[];
}
export interface IAdministrativeArea {
}
export interface IAggregateRating {
    itemReviewed: IThing[];
    ratingCount: number[];
    reviewCount: number[];
}
export interface IAlignmentObject {
    alignmentType: string[];
    educationalFramework: string[];
    targetDescription: string[];
    targetName: string[];
    targetUrl: undefined[];
}
export interface IAudience {
    audienceType: string[];
    geographicArea: IAdministrativeArea[];
}
export interface IBroadcastChannel {
    broadcastChannelId: string[];
    broadcastFrequency: any[];
    broadcastServiceTier: string[];
    genre: any[];
    inBroadcastLineup: ICableOrSatelliteService[];
    providesBroadcastService: IBroadcastService[];
}
export interface IBroadcastService {
    broadcastAffiliateOf: IOrganization[];
    broadcastDisplayName: string[];
    broadcaster: IOrganization[];
    broadcastFrequency: any[];
    broadcastTimezone: string[];
    hasBroadcastChannel: IBroadcastChannel[];
    inLanguage: any[];
    parentService: IBroadcastService[];
    videoFormat: string[];
}
export interface ICableOrSatelliteService {
}
export interface IComment {
    downvoteCount: number[];
    parentItem: IComment[];
    upvoteCount: number[];
}
export interface IContactPoint {
    areaServed: any[];
    availableLanguage: any[];
    contactOption: ContactPointOption[];
    contactType: string[];
    email: string[];
    faxNumber: string[];
    hoursAvailable: IOpeningHoursSpecification[];
    productSupported: any[];
    telephone: string[];
}
export interface ICountry {
}
export interface ICreativeWork {
    about: IThing[];
    accessibilityAPI: string[];
    accessibilityControl: string[];
    accessibilityFeature: string[];
    accessibilityHazard: string[];
    accessibilitySummary: string[];
    accessMode: string[];
    accessModeSufficient: IItemList[];
    accountablePerson: IPerson[];
    aggregateRating: IAggregateRating[];
    alternativeHeadline: string[];
    associatedMedia: IMediaObject[];
    audience: IAudience[];
    audio: any[];
    author: any[];
    award: string[];
    character: IPerson[];
    citation: any[];
    comment: IComment[];
    commentCount: number[];
    contentLocation: IPlace[];
    contentRating: any[];
    contributor: any[];
    copyrightHolder: any[];
    copyrightYear: number[];
    creator: any[];
    dateCreated: any[];
    dateModified: any[];
    datePublished: any[];
    discussionUrl: undefined[];
    editor: IPerson[];
    educationalAlignment: IAlignmentObject[];
    educationalUse: string[];
    encoding: IMediaObject[];
    encodingFormat: any[];
    exampleOfWork: ICreativeWork[];
    expires: any[];
    funder: any[];
    genre: any[];
    hasPart: ICreativeWork[];
    headline: string[];
    inLanguage: any[];
    interactionStatistic: IInteractionCounter[];
    interactivityType: string[];
    isAccessibleForFree: boolean[];
    isBasedOn: any[];
    isFamilyFriendly: boolean[];
    isPartOf: any[];
    keywords: any[];
    learningResourceType: string[];
    license: any[];
    locationCreated: IPlace[];
    mainEntity: IThing[];
    material: any[];
    mentions: IThing[];
    offers: any[];
    position: any[];
    producer: any[];
    provider: any[];
    publication: IPublicationEvent[];
    publisher: any[];
    publisherImprint: IOrganization[];
    publishingPrinciples: any[];
    recordedAt: IEvent[];
    releasedEvent: IPublicationEvent[];
    review: IReview[];
    schemaVersion: any[];
    sourceOrganization: IOrganization[];
    spatial: IPlace[];
    spatialCoverage: IPlace[];
    sponsor: any[];
    temporal: any[];
    temporalCoverage: any[];
    text: string[];
    thumbnailUrl: undefined[];
    timeRequired: string[];
    translationOfWork: ICreativeWork[];
    translator: any[];
    typicalAgeRange: string[];
    version: any[];
    video: any[];
    workExample: ICreativeWork[];
    workTranslation: ICreativeWork[];
}
export interface IDemand {
    acceptedPaymentMethod: any[];
    advanceBookingRequirement: IQuantitativeValue[];
    areaServed: any[];
    availability: ItemAvailability[];
    availabilityEnds: any[];
    availabilityStarts: any[];
    availableAtOrFrom: IPlace[];
    availableDeliveryMethod: DeliveryMethod[];
    businessFunction: BusinessFunction[];
    deliveryLeadTime: IQuantitativeValue[];
    eligibleCustomerType: BusinessEntityType[];
    eligibleDuration: IQuantitativeValue[];
    eligibleQuantity: IQuantitativeValue[];
    eligibleRegion: any[];
    eligibleTransactionVolume: IPriceSpecification[];
    gtin12: string[];
    gtin13: string[];
    gtin14: string[];
    gtin8: string[];
    includesObject: ITypeAndQuantityNode[];
    inventoryLevel: IQuantitativeValue[];
    itemCondition: OfferItemCondition[];
    itemOffered: any[];
    mpn: string[];
    priceSpecification: IPriceSpecification[];
    seller: any[];
    serialNumber: string[];
    sku: string[];
    validFrom: any[];
    validThrough: any[];
    warranty: IWarrantyPromise[];
}
export interface IEvent {
    about: IThing[];
    actor: IPerson[];
    aggregateRating: IAggregateRating[];
    attendee: any[];
    audience: IAudience[];
    composer: any[];
    contributor: any[];
    director: IPerson[];
    doorTime: any[];
    duration: string[];
    endDate: any[];
    eventStatus: EventStatusType[];
    funder: any[];
    inLanguage: any[];
    isAccessibleForFree: boolean[];
    location: any[];
    maximumAttendeeCapacity: number[];
    offers: any[];
    organizer: any[];
    performer: any[];
    previousStartDate: any[];
    recordedIn: ICreativeWork[];
    remainingAttendeeCapacity: number[];
    review: IReview[];
    sponsor: any[];
    startDate: any[];
    subEvent: IEvent[];
    superEvent: IEvent[];
    translator: any[];
    typicalAgeRange: string[];
    workFeatured: ICreativeWork[];
    workPerformed: ICreativeWork[];
}
export interface IInteractionCounter {
    interactionService: any[];
    interactionType: IAction[];
    userInteractionCount: number[];
}
export interface IItemList {
    itemListElement: any[];
    itemListOrder: any[];
    numberOfItems: number[];
}
export interface ILocationFeatureSpecification {
    hoursAvailable: IOpeningHoursSpecification[];
    validFrom: any[];
    validThrough: any[];
}
export interface IMediaObject {
    associatedArticle: INewsArticle[];
    bitrate: string[];
    contentSize: string[];
    contentUrl: undefined[];
    duration: string[];
    embedUrl: undefined[];
    encodesCreativeWork: ICreativeWork[];
    endTime: any[];
    height: any[];
    playerType: string[];
    productionCompany: IOrganization[];
    regionsAllowed: IPlace[];
    requiresSubscription: any[];
    startTime: any[];
    uploadDate: any[];
    width: any[];
}
export interface INewsArticle {
    dateline: string[];
    printColumn: string[];
    printEdition: string[];
    printPage: string[];
    printSection: string[];
}
export interface Intangible extends Thing {
    type: string;
}
export interface IOccupation {
    estimatedSalary: any[];
    experienceRequirements: string[];
    occupationLocation: IAdministrativeArea[];
    responsibilities: string[];
    skills: string[];
}
export interface IOffer {
    acceptedPaymentMethod: any[];
    addOn: IOffer[];
    advanceBookingRequirement: IQuantitativeValue[];
    aggregateRating: IAggregateRating[];
    areaServed: any[];
    availability: ItemAvailability[];
    availabilityEnds: any[];
    availabilityStarts: any[];
    availableAtOrFrom: IPlace[];
    availableDeliveryMethod: DeliveryMethod[];
    businessFunction: BusinessFunction[];
    category: any[];
    deliveryLeadTime: IQuantitativeValue[];
    eligibleCustomerType: BusinessEntityType[];
    eligibleDuration: IQuantitativeValue[];
    eligibleQuantity: IQuantitativeValue[];
    eligibleRegion: any[];
    eligibleTransactionVolume: IPriceSpecification[];
    gtin12: string[];
    gtin13: string[];
    gtin14: string[];
    gtin8: string[];
    includesObject: ITypeAndQuantityNode[];
    inventoryLevel: IQuantitativeValue[];
    itemCondition: OfferItemCondition[];
    itemOffered: any[];
    mpn: string[];
    offeredBy: any[];
    price: any[];
    priceCurrency: string[];
    priceSpecification: IPriceSpecification[];
    priceValidUntil: any[];
    review: IReview[];
    seller: any[];
    serialNumber: string[];
    sku: string[];
    validFrom: any[];
    validThrough: any[];
    warranty: IWarrantyPromise[];
}
export interface IOfferCatalog {
}
export interface IOpeningHoursSpecification {
    closes: string[];
    dayOfWeek: DayOfWeek[];
    opens: string[];
    validFrom: any[];
    validThrough: any[];
}
export interface IOrganization {
    address: any[];
    aggregateRating: IAggregateRating[];
    alumni: IPerson[];
    areaServed: any[];
    award: string[];
    brand: any[];
    contactPoint: IContactPoint[];
    department: IOrganization[];
    dissolutionDate: any[];
    duns: string[];
    email: string[];
    employee: IPerson[];
    events: IEvent[];
    faxNumber: string[];
    founder: IPerson[];
    foundingDate: any[];
    foundingLocation: IPlace[];
    funder: any[];
    globalLocationNumber: string[];
    hasOfferCatalog: IOfferCatalog[];
    hasPOS: IPlace[];
    interactionStatistic: IInteractionCounter[];
    isicV4: string[];
    legalName: string[];
    leiCode: string[];
    location: any[];
    logo: any[];
    makesOffer: IOffer[];
    member: any[];
    memberOf: any[];
    naics: string[];
    numberOfEmployees: IQuantitativeValue[];
    owns: any[];
    parentOrganization: IOrganization[];
    publishingPrinciples: any[];
    review: IReview[];
    seeks: IDemand[];
    slogan: string[];
    sponsor: any[];
    subOrganization: IOrganization[];
    taxID: string[];
    telephone: string[];
    vatID: string[];
}
export interface IPerson {
    additionalName: string[];
    address: any[];
    affiliation: IOrganization[];
    alumniOf: any[];
    award: string[];
    birthDate: any[];
    birthPlace: IPlace[];
    brand: any[];
    children: IPerson[];
    colleague: any[];
    contactPoint: IContactPoint[];
    deathDate: any[];
    deathPlace: IPlace[];
    duns: string[];
    email: string[];
    familyName: string[];
    faxNumber: string[];
    follows: IPerson[];
    funder: any[];
    givenName: string[];
    globalLocationNumber: string[];
    hasOccupation: IOccupation[];
    hasOfferCatalog: IOfferCatalog[];
    hasPOS: IPlace[];
    height: any[];
    homeLocation: any[];
    honorificPrefix: string[];
    honorificSuffix: string[];
    interactionStatistic: IInteractionCounter[];
    isicV4: string[];
    knows: IPerson[];
    makesOffer: IOffer[];
    memberOf: any[];
    naics: string[];
    nationality: ICountry[];
    netWorth: any[];
    owns: any[];
    parent: IPerson[];
    performerIn: IEvent[];
    publishingPrinciples: any[];
    relatedTo: IPerson[];
    seeks: IDemand[];
    sibling: IPerson[];
    sponsor: any[];
    spouse: IPerson[];
    taxID: string[];
    telephone: string[];
    vatID: string[];
    weight: IQuantitativeValue[];
    workLocation: any[];
    worksFor: IOrganization[];
}
export interface IPlace {
    additionalProperty: IPropertyValue[];
    address: any[];
    aggregateRating: IAggregateRating[];
    amenityFeature: ILocationFeatureSpecification[];
    branchCode: string[];
    containedInPlace: IPlace[];
    containsPlace: IPlace[];
    events: IEvent[];
    faxNumber: string[];
    geo: any[];
    geoContains: IPlace[];
    geoCoveredBy: IPlace[];
    geoCovers: IPlace[];
    geoCrosses: IPlace[];
    geoDisjoint: IPlace[];
    geoEquals: IPlace[];
    geoIntersects: IPlace[];
    geoOverlaps: IPlace[];
    geoTouches: IPlace[];
    geoWithin: IPlace[];
    globalLocationNumber: string[];
    hasMap: any[];
    isAccessibleForFree: boolean[];
    isicV4: string[];
    latitude: any[];
    logo: any[];
    longitude: any[];
    maximumAttendeeCapacity: number[];
    openingHoursSpecification: IOpeningHoursSpecification[];
    photo: any[];
    publicAccess: boolean[];
    review: IReview[];
    slogan: string[];
    smokingAllowed: boolean[];
    specialOpeningHoursSpecification: IOpeningHoursSpecification[];
    telephone: string[];
}
export interface IPriceSpecification {
    eligibleQuantity: IQuantitativeValue[];
    eligibleTransactionVolume: IPriceSpecification[];
    maxPrice: number[];
    minPrice: number[];
    price: any[];
    priceCurrency: string[];
    validFrom: any[];
    validThrough: any[];
    valueAddedTaxIncluded: boolean[];
}
export interface IPropertyValue {
    maxValue: number[];
    minValue: number[];
    propertyID: any[];
    unitCode: any[];
    unitText: string[];
    value: any[];
    valueReference: any[];
}
export interface IPublicationEvent {
    publishedBy: any[];
    publishedOn: IBroadcastService[];
}
export interface IQuantitativeValue {
    additionalProperty: IPropertyValue[];
    maxValue: number[];
    minValue: number[];
    unitCode: any[];
    unitText: string[];
    value: any[];
    valueReference: any[];
}
export interface IRating {
    author: any[];
    bestRating: any[];
    ratingValue: any[];
    reviewAspect: string[];
    worstRating: any[];
}
export interface IReview {
    itemReviewed: IThing[];
    reviewAspect: string[];
    reviewBody: string[];
    reviewRating: IRating[];
}
export interface ItemList extends Intangible {
    itemListElement: any[];
    itemListOrder: any[];
    numberOfItems: number[];
    type: string;
}
export interface IThing {
    additionalType: undefined[];
    alternateName: string[];
    description: string[];
    disambiguatingDescription: string[];
    identifier: any[];
    image: any[];
    mainEntityOfPage: any[];
    name: string[];
    potentialAction: IAction[];
    sameAs: undefined[];
    subjectOf: any[];
    url: undefined[];
}
export interface ITypeAndQuantityNode {
    amountOfThisGood: number[];
    businessFunction: BusinessFunction[];
    typeOfGood: any[];
    unitCode: any[];
    unitText: string[];
}
export interface IWarrantyPromise {
    durationOfWarranty: IQuantitativeValue[];
    warrantyScope: WarrantyScope[];
}
export interface JsonLdContext {
    language: string;
    name: string;
}
export interface JsonLdObject {
    context: JsonLdContext;
    id: undefined;
    type: string;
}
export interface LocalBusiness extends OrganizationAndPlace {
    currenciesAccepted: string[];
    openingHours: string[];
    paymentAccepted: string[];
    priceRange: string[];
    type: string;
}
export interface OrganizationAndPlace extends Thing {
    additionalProperty: IPropertyValue[];
    address: any[];
    aggregateRating: IAggregateRating[];
    alumni: IPerson[];
    amenityFeature: ILocationFeatureSpecification[];
    areaServed: any[];
    award: string[];
    branchCode: string[];
    brand: any[];
    contactPoint: IContactPoint[];
    containedInPlace: IPlace[];
    containsPlace: IPlace[];
    department: IOrganization[];
    dissolutionDate: any[];
    duns: string[];
    email: string[];
    employee: IPerson[];
    events: IEvent[];
    faxNumber: string[];
    founder: IPerson[];
    foundingDate: any[];
    foundingLocation: IPlace[];
    funder: any[];
    geo: any[];
    geoContains: IPlace[];
    geoCoveredBy: IPlace[];
    geoCovers: IPlace[];
    geoCrosses: IPlace[];
    geoDisjoint: IPlace[];
    geoEquals: IPlace[];
    geoIntersects: IPlace[];
    geoOverlaps: IPlace[];
    geoTouches: IPlace[];
    geoWithin: IPlace[];
    globalLocationNumber: string[];
    hasMap: any[];
    hasOfferCatalog: IOfferCatalog[];
    hasPOS: IPlace[];
    interactionStatistic: IInteractionCounter[];
    isAccessibleForFree: boolean[];
    isicV4: string[];
    latitude: any[];
    legalName: string[];
    leiCode: string[];
    location: any[];
    logo: any[];
    longitude: any[];
    makesOffer: IOffer[];
    maximumAttendeeCapacity: number[];
    member: any[];
    memberOf: any[];
    naics: string[];
    numberOfEmployees: IQuantitativeValue[];
    openingHoursSpecification: IOpeningHoursSpecification[];
    owns: any[];
    parentOrganization: IOrganization[];
    photo: any[];
    publicAccess: boolean[];
    publishingPrinciples: any[];
    review: IReview[];
    seeks: IDemand[];
    slogan: string[];
    smokingAllowed: boolean[];
    specialOpeningHoursSpecification: IOpeningHoursSpecification[];
    sponsor: any[];
    subOrganization: IOrganization[];
    taxID: string[];
    telephone: string[];
    type: string;
    vatID: string[];
}
export interface Thing extends JsonLdObject {
    additionalType: undefined[];
    alternateName: string[];
    description: string[];
    disambiguatingDescription: string[];
    identifier: any[];
    image: any[];
    mainEntityOfPage: any[];
    name: string[];
    potentialAction: IAction[];
    sameAs: undefined[];
    subjectOf: any[];
    type: string;
    url: undefined[];
}
export interface NavigationItem {
    categoryId: string;
    categoryNavigationLevel: number;
    children: NavigationItem[];
    iconUrl: string;
    imageUrl: string;
    isWebOffer: boolean;
    link: LinkItem;
}
export interface InfoMessagesViewModel {
    link: string;
    maxHoursHidden: number;
    showCloseButton: boolean;
    text: string;
    type: string;
}
export interface RaptorContextData {
    billToPartnerId: string;
    caseKontonr: string;
    caseKundenr: string;
    domain: string;
    fakeContactType: string;
    kundeKategori: string;
    userId: string;
}
export interface RaptorPageData {
    brandId: string;
    categoryPath: string;
    contentType: string;
    pageId: string;
    productId: string;
    productName: string;
}
export interface RaptorScriptViewModel {
    cookieId: string;
    customerId: string;
    exRuid: string;
}
export interface IFeatureToggling {
    basketEnabled: boolean;
    loginEnabled: boolean;
    productCatalogEnabled: boolean;
    twentyFourSevenEnabled: boolean;
}
export interface BannerViewModel {
    bannerType: string;
    bodyText: string;
    buttonLink: LinkViewModel;
    disableOverlay: boolean;
    fullWidth: boolean;
    header: string;
    imageLink: LinkViewModel;
    imageUrl: string;
    preheader: string;
    showCtaButton: boolean;
    textAlignmentRight: boolean;
    textBackgroundColor: string;
    videoAutoPlay: boolean;
    videoUrl: string;
}
export interface LinkViewModel {
    name: string;
    target: string;
    url: string;
}
export interface NavigationViewModel {
    createUserPage: TopLevelNavigationItem;
    main: TopLevelNavigationItem[];
    myPage: TopLevelNavigationItem;
    userMenuItem: UserNavigationItem;
}
export interface TopLevelNavigationItem extends NavigationItem {
    isCatalog: boolean;
}
export interface UserNavigationItem {
    deliveryNotesLink: LinkItem;
    favoriteOrdersLink: LinkItem;
    orderSummaryLink: LinkItem;
    userMenuLinks: LinkItem[];
}
export interface WebOfferEnrichmentViewModel {
    content: undefined[];
}
export interface StorePageViewModel {
    city: string;
    facebookId: string;
    info: string;
    isCollectStore: boolean;
    jsonLD: LocalBusiness;
    mapLocation: MapLocation;
    phone: string;
    postalCode: string;
    sapStoreId: string;
    storeId: string;
    storeName: string;
    street: string;
    workHours: string[];
}
export interface StoresPageViewModel {
    mapLocation: MapLocation;
    name: string;
}
export interface Global404PageViewModel {
    heading: string;
    languageCode: string;
    name: string;
    text: IHtmlString;
}
export interface IHtmlString {
}
export interface CookieBotContextData {
    enabled: boolean;
    key: string;
    languageCode: string;
}
export interface CookieBotViewModel {
    isEnabled: boolean;
    isPrerenderRequest: boolean;
    key: string;
    languageCode: string;
}
export interface EventOverviewViewModel {
    eventStartDateFormatted: string;
    heading: string;
    imageUrl: string;
    teaser: string;
    thumbnailUrl: string;
    url: string;
}
export interface EventPageViewModel extends EventOverviewViewModel {
    archetypeContent: undefined[];
    eventEndDateFormatted: string;
    prioritizeForCustomerGroup: string[];
    storeName: string;
    storeUrl: string;
    subHeading: string;
    tags: string[];
}
export interface PunchOutViewModel {
    content: string;
    returnTarget: string;
    returnUrl: string;
}
export interface CategoryTreePageViewModel {
    categories: NavigationItem[];
    contentTile: ContentTileViewModel;
    downscaleImages: boolean;
    relatedCategoryTile: RelatedCategoryTileViewModel;
}
export interface ContentTileViewModel {
    body: IHtmlString;
    title: string;
}
export interface RelatedCategoryTileViewModel {
    category: NavigationItem;
    title: string;
}
export interface CategoryButtonViewModel {
    imageUrl: string;
    linkObj: Link;
}
export enum LinkType {
    Content = 0,
    Media = 1,
    External = 2
}
export interface Link {
    name: string;
    target: string;
    type: LinkType;
    udi: Udi;
    url: string;
}
export interface Udi {
    entityType: string;
    isRoot: boolean;
}
export interface BomDetailsViewModel {
    archetypeContentAbove: undefined[];
    archetypeContentBelow: undefined[];
    bomProduct: BomDetailsViewObject;
}
export interface BomDetailsViewObject {
    bomComponents: ProductTileViewObject[];
    canonicalUrl: string;
    description: string;
    displayName: string;
    productId: string;
    variant: VariantDetailsViewObject;
}
export interface CatalogPageViewModel {
    categories: NavigationItem[];
}
export interface CategoryPageViewModel {
    archetypeContentAbove: undefined[];
    archetypeContentBelow: undefined[];
    categoryId: string;
    categoryTree: CategoryTreePageViewModel;
    enrichmentContentAbove: undefined[];
    enrichmentContentBelow: undefined[];
    name: string;
    relatedContent: RelatedContentViewObject;
    showPrintCatalogButton: boolean;
}
export interface LeafCategoryPageViewModel {
    archetypeContentAbove: undefined[];
    archetypeContentBelow: undefined[];
    categoryId: string;
    enrichmentContentBelow: undefined[];
    name: string;
}
export interface ProductDetailsViewModel {
    archetypeContentAbove: undefined[];
    archetypeContentBelow: undefined[];
    product: ProductDetailsViewObject;
}
export interface RelatedContentViewObject {
    heading: string;
    name: string;
    teaser: string;
    url: string;
}
export interface ProductRedirectViewObject {
    redirectUrl: string;
}
export interface BrandCategoryPageViewModel {
    brandName: string;
    categoryHeading: string;
    categoryId: string;
    categoryName: string;
}
export interface BrandCategoryViewObject {
    id: string;
    imageUrl: string;
    key: string;
    name: string;
    url: string;
}
export interface BrandPageBaseViewModel {
    brandKey: string;
    brandName: string;
}
export interface BrandPagePremiumViewModel {
    archetypeContentAbove: undefined[];
    archetypeContentBelow: undefined[];
    brandBackgroundColor: string;
    brandKey: string;
    brandLogo: string;
    brandName: string;
    brandNameColor: string;
    brandPromotionViewModel: BrandPromotionViewModel;
    categories: BrandCategoryViewObject[];
    categoryCount: number;
}
export interface BrandPageStandardViewModel {
    brandKey: string;
    brandLogo: string;
    brandName: string;
    categories: BrandCategoryViewObject[];
}
export interface BrandPromotionViewModel {
    imagePromotion: PromotedResultViewObject;
    videoAutoplay: boolean;
    videoUrl: string;
}
export enum Courier {
    None = 0,
    PostDk = 1,
    DF = 2
}
export enum AvailabilityStatus {
    None = 0,
    InStock = 1,
    InStockExtendedDelivery = 2,
    OutOfStock = 3,
    OutOfStockExtendedDelivery = 4,
    SoldOut = 5,
    TemporarilyPhasedOut = 6,
    VendorUnableToDeliver = 7
}
export enum NightDeliveryQualification {
    Yes = 0,
    No = 1,
    Limited = 2
}
export enum ProductAvailabilityType {
    NotAvailable = 0,
    AvailableIfInStock = 1,
    AlwaysAvailable = 2
}
export enum FavoriteListMergeType {
    New = 0,
    Overwrite = 1,
    Merge = 2
}
export enum ReportType {
    ProductList = 0,
    LabelsSmall = 1,
    LabelsLarge = 2,
    Dymo99010 = 4,
    Dymo99012 = 5,
    Dymo99014 = 6,
    Dymo11354 = 7,
    BrotherDK11201 = 8,
    BrotherDK11202 = 9,
    BrotherDK11208 = 10,
    BrotherDK11209 = 11,
    BrotherDK11240 = 12,
    CR80 = 13,
    EasySupplyHyldeforkant = 14,
    CitizenCLS621 = 15,
    BrotherDK11204 = 16,
    BrotherQL700 = 17
}
export enum PriceType {
    None = 0,
    NetPrice = 1,
    GrossPrice = 2,
    Both = 3
}
export enum ProductNumberType {
    SaniNumber = 0,
    VvsNumber = 1,
    CarlF = 2,
    EanNo = 3,
    LNo = 4,
    BuyerItemNumber = 5
}
export enum RaptorBrandMethod {
    GetTopViewedInBrands = 0,
    GetUserFavoriteBrand = 1
}
export enum RaptorCategoryByCustomerCategoryMethod {
    TopSellingInCategoryByCustomerCategory = 0
}
export enum RaptorCategoryMethod {
    GetTopSellingInCategory = 0,
    GetTopViewedInCategory = 1
}
export enum RaptorCategoryRecommendationMethod {
    GetUserFavoriteCategory = 0
}
export enum RaptorContentMethod {
    GetProduct2Contents = 0
}
export enum RaptorCustomerCategoryMethod {
    GetTrendingOnSaleItemsInAGivenCustomerCategoryCustom = 0
}
export enum RaptorMethod {
    GetUserHistory = 0,
    GetUserHistoryRecommendations = 1,
    GetOverAllTopVisits = 2,
    GetOverAllTopSelling = 3,
    GetTrendingItems = 4,
    GetUserRecommendations = 5,
    GetCookieRecommendations = 6
}
export enum RaptorProductMethod {
    GetRelatedItems = 0,
    GetSimilarItems = 1,
    GetBuy2BuyRelations = 2,
    GetContent2Products = 3
}
export enum ApprovalStatus {
    Pending = 0,
    Approved = 1,
    Rejected = 2
}
export enum DeliveryType {
    UNKNOWN = 0,
    DAG = 1,
    NAT = 2,
    BUTIK = 3,
    FLEXBOX = 4,
    CC = 5
}
export enum DocumentCategory {
    Unknown = 0,
    Project = 1,
    QuotationFixed = 2,
    Quotation = 3,
    ReturnOrder = 4,
    FrameOrder = 5
}
export enum ProductIdType {
    Sani = 0,
    VVS = 1,
    DbId = 2,
    CarlF = 3,
    El = 4,
    Buyer = 5
}
export enum ClientMessageType {
    Error = 0,
    Warning = 1,
    Info = 2,
    Success = 3
}
export enum Weekday {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
}
export enum ProjectFolderItemType {
    Agreement = 0,
    Offer = 1,
    Link = 2
}
export enum Direction {
    Up = 0,
    Down = 1
}
export enum SourceFilter {
    Id = 0
}
export enum PunchOutProvider {
    Oci = 0,
    CXml = 1
}
