




















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { OrderViewModel } from '@/types/serverContract';
import OrderSummarySubTotal from '@/project/checkout/OrderSummarySubTotal.vue';
import OrderSummaryVat from '@/project/checkout/OrderSummaryVat.vue';
import OrderSummaryTotal from '@/project/checkout/OrderSummaryTotal.vue';

@Component({
    components: {
        OrderSummaryTotal: OrderSummaryTotal,
        OrderSummaryVat: OrderSummaryVat,
        OrderSummarySubTotal: OrderSummarySubTotal
    }
})
export default class OrderSummary extends Vue {
    @Prop({
        required: true,
        type: Object
    }) order!: OrderViewModel;
}
