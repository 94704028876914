




















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ProjectFolderLinkViewObject } from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: { SpinnerOverlay }
})
export default class ProjectFolderOffers extends Vue {
    offerLinks: ProjectFolderLinkViewObject[] | null = null;
    pending: boolean = false;

    created(): void {
        this.getOfferLinks();
    }

    async getOfferLinks(): Promise<void> {
        this.pending = true;

        try {
            await Api.projectFolderOffers.getOfferLinks().then(projectFolderOffers => {
                this.offerLinks = projectFolderOffers.links;
            });
        } finally {
            this.pending = false;
        }
    }
}
