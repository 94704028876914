









import { Component, Mixins, Watch } from 'vue-property-decorator';
import { PriceServiceMixin } from '@/project/products/PriceServiceMixin.mixin';
import { BasketGetter } from '@/store/basket';
import { FlexBoxGetter } from '@/store/flexBox';

@Component
export default class AuthenticationDesktopBasketTotal extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @BasketGetter subTotal!: string;
    @BasketGetter grossTotal!: string;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    created() {
        this.price = {
            netPrice: this.subTotal,
            grossPrice: this.grossTotal
        };
    }

    @Watch('subTotal')
    onNetTotalChange(): void {
        this.price!.netPrice = this.subTotal;
    }

    @Watch('grossTotal')
    onGrossTotalChange(): void {
        this.price!.grossPrice = this.grossTotal;
    }
}
