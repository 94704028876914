






















































































































































































import Vue from 'vue';
import {
    VariantFeatureOptionViewObject,
    VariantFeatureSetViewObject,
    VariantFeatureViewObject,
    VariantPickerViewModel
} from '@/types/serverContract';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import SelectCtrl from '@/project/form/SelectCtrl.vue';
import { clone, isEmpty, sortBy, uniqBy } from 'lodash-es';
import { stringReplaceLast } from '@/project/config/utilities';
import urlHelper from '@/project/search/urlHelper.service';
import { Route } from 'vue-router';

interface FacetValueAndDisplayValue {
    DisplayValue: string;
    Value: string;
}

@Component({
    components: {
        SelectCtrl
    }
})
export default class ProductVariantPicker extends Vue {
    @Prop({
        required: true,
        type: Object
    })
    variantPickerModel!: VariantPickerViewModel;

    @Prop({
        required: false,
        type: String
    })
    selectedVariantId!: string | null;

    @Prop({
        required: false,
        type: Boolean
    })
    watchSearchQueryChanges!: boolean;

    @Prop({
        type: String,
        default: 'product-tile',
        validator: (value: string) => ['search-tile', 'product-tile'].indexOf(value) > -1
    })
    mode!: string;

    openOptionsList: string = '';
    chosenVariantFeatures: {[name: string]: FacetValueAndDisplayValue} = {};

    created() {
        this.variantPickerModel.masterFeatures.forEach(vmf => {
            this.clearVariant(vmf.name);
        });
        if (this.selectedVariantId) {
            // Setting the chosen variant to the pre selected variant from the parent.
            this.setChosenVariantFromVariantId(this.selectedVariantId);
        } else {
            this.setChosenVariantFromSearchFacets();
        }
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path === oldRoute.path && this.watchSearchQueryChanges) {
            Object.keys(this.chosenVariantFeatures).forEach(variantType => {
                this.clearSelectedVariant(variantType);
            });
            if (this.variantPickerModel.variantFeatures.length === 1) {
                // Setting the chosen variant to the only possible variant for the product.
                this.setChosenVariantFromVariantId(
                    this.variantPickerModel.variantFeatures[0].variantId
                );
            } else {
                this.setChosenVariantFromSearchFacets();
            }
        }
    }

    setChosenVariantFromVariantId(variantId: string): void {
        const variantById = this.variantPickerModel.variantFeatures.find(
            variant => variant.variantId === variantId
        );
        if (variantById !== undefined) {
            variantById.variantFeatures.forEach(vf => {
                this.chosenVariantFeatures[vf.name] = {
                    Value: vf.option,
                    DisplayValue: vf.displayOption
                };
            });
            this.selectProductFromVariants();
        }
    }

    setChosenVariantFromSearchFacets(): void {
        const facets = urlHelper.getFacets();
        if (isEmpty(facets)) {
            return;
        }

        Object.keys(this.chosenVariantFeatures).forEach(variantType => {
            const facetValuesForVariantType = facets[variantType];
            if (facetValuesForVariantType && facetValuesForVariantType.length > 0) {
                const possibleVariantOptions = this.getPossibleOptions(variantType);
                const intersections = possibleVariantOptions.filter(element =>
                    facetValuesForVariantType.includes(element.Value)
                );
                if (intersections.length === 1) {
                    this.selectVariant(variantType, intersections[0]);
                } else {
                    // multiple matches based on facet selections
                    this.clearVariant(variantType);
                }
            }
            this.selectProductFromVariants();
        });
    }

    noChosenVariantOption(variantTypeName: string): boolean {
        return this.chosenVariantFeatures[variantTypeName].Value === '';
    }

    isChosenVariantOption(variantTypeName: string, option: string): boolean {
        return this.chosenVariantFeatures[variantTypeName].Value === option;
    }

    showVariantOptions(variantName: string): void {
        if (this.openOptionsList !== variantName) {
            this.openOptionsList = variantName;
            document.body.addEventListener('click', this.resetOpenOptionList, true);
        } else {
            this.resetOpenOptionList();
        }
    }

    otherVariantTypesForOptionGroupLabel(currentVariantType: string): string {
        const otherVariantTypes = this.variantPickerModel.masterFeatures
            .filter(mvf => mvf.name !== currentVariantType)
            .map(mvf => mvf.displayName.toLowerCase())
            .join()
            .replace(',', ', ');
        return stringReplaceLast(otherVariantTypes, ', ', ' & ');
    }

    clearVariant(currentVariantType: string): void {
        this.chosenVariantFeatures[currentVariantType] = {
            Value: '',
            DisplayValue: ''
        };
    }

    selectVariant(
        currentVariantType: string,
        option: FacetValueAndDisplayValue
    ): void {
        this.chosenVariantFeatures[currentVariantType] = option;

        // Due to weirdness in Vue.js 2 objects with more than 1 level sometimes have to be cloned and overwritten to be updated.
        let updatedChosenVariant = clone(this.chosenVariantFeatures);

        Object.keys(updatedChosenVariant).forEach(variantType => {
            const possibleOptions = this.getPossibleOptions(variantType);
            if (
                variantType !== currentVariantType &&
        !possibleOptions
            .map(x => x.Value)
            .includes(updatedChosenVariant[variantType].Value)
            ) {
                if (possibleOptions.length === 1) {
                    updatedChosenVariant[variantType] = possibleOptions[0];
                } else if (
                    possibleOptions.length === 0 &&
                    this.selectedVariantId &&
                    this.variantPickerModel.masterFeatures[2].options.length === 1
                ) {
                    this.setChosenVariantFromVariantId(this.selectedVariantId);
                } else {
                    updatedChosenVariant[variantType] = { Value: '', DisplayValue: '' };
                }
            }
        });

        this.chosenVariantFeatures = updatedChosenVariant;
        this.resetOpenOptionList();
        this.selectProductFromVariants();
    }

    clearSelectedVariant(currentVariantType: string): void {
        this.clearVariant(currentVariantType);
        this.resetOpenOptionList();
        this.selectProductFromVariants();
    }

    getPossibleOptions(currentVariantType: string): FacetValueAndDisplayValue[] {
        const variantFeaturePairs: VariantFeatureSetViewObject[] =
            this.variantPickerModel.variantFeatures.filter(v =>
                v.variantFeatures.every(vf => {
                    if (vf.name === currentVariantType) {
                        return true;
                    }
                    if (this.noChosenVariantOption(vf.name)) {
                        return true;
                    }
                    return this.isChosenVariantOption(vf.name, vf.option);
                })
            );

        const variantFeaturesList: (VariantFeatureViewObject | any)[] =
      variantFeaturePairs.map(v =>
          v.variantFeatures.find(vf => vf.name === currentVariantType)
      );

        const sortedVariantFeatureList: VariantFeatureViewObject[] = sortBy(
            variantFeaturesList,
            [vf => vf.sortOrder, vf => vf.sortValue, vf => vf.option]
        );

        let result = sortedVariantFeatureList.map(
            (vf: VariantFeatureViewObject): FacetValueAndDisplayValue => {
                return {
                    Value: vf.option,
                    DisplayValue: vf.displayOption
                };
            }
        );

        return uniqBy(result, vf => vf.Value);
    }

    getReplacementOptions(
        currentVariantType: string
    ): FacetValueAndDisplayValue[] {
        const validOptions = this.getPossibleOptions(currentVariantType);

        let variantFeatureOptionViewObjects = this.variantPickerModel.masterFeatures
            .find(mvf => mvf.name === currentVariantType)!
            .options.filter(
                option => !validOptions.map(x => x.Value).includes(option.option)
            );

        let result = variantFeatureOptionViewObjects.map(
            (vf: VariantFeatureOptionViewObject): FacetValueAndDisplayValue => {
                return {
                    Value: vf.option,
                    DisplayValue: vf.displayOption
                };
            }
        );

        return uniqBy(result, vf => vf.Value);
    }

    isAllVariantsChosen(): boolean {
        return Object.values(this.chosenVariantFeatures).every(
            vt => vt.Value !== ''
        );
    }

    selectProductFromVariants(): void {
        if (!this.isAllVariantsChosen()) {
            this.$emit('variantSelected', '');
            return;
        }

        const variant = this.variantPickerModel.variantFeatures.filter(v =>
            v.variantFeatures.every(
                vf => this.chosenVariantFeatures[vf.name].Value === vf.option
            )
        );

        if (!variant || variant.length !== 1) {
            throw new Error(
                'No or more than one product matches chosen variants: ' +
          JSON.stringify(variant)
            );
        }
        this.$emit('variantSelected', variant[0].variantId, true);
    }

    resetOpenOptionList(event?: Event): void {
        if (event !== undefined) {
            event.preventDefault();
        }
        this.openOptionsList = '';
        document.body.removeEventListener('click', this.resetOpenOptionList, true);
    }
}
