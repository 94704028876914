













































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Constants from '@/project/config/constants';
import Api from '@/project/http/api';
import {
    EasySupplyGatewayDetailViewObject,
    DeliveryAddressViewObject,
    Weekday,
    AddEasySupplyGatewayRequest,
    ReconfigureEasySupplyGatewaysRequest,
    DeliveryAddressViewModel,
    DeliveryType
} from '@/types/serverContract';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import AddOrUpdateGatewayDeliveryAddress from '@/project/easySupply/gateway/AddOrUpdateGatewayDeliveryAddress.vue';
import DeliveryAddressAddAddressModal from '@/project/checkout/DeliveryAddressAddAddressModal.vue';
import TimePickerCtrl from '../../form/TimePickerCtrl.vue';

@Component({
    components: {
        ButtonSubmit,
        AddOrUpdateGatewayDeliveryAddress,
        DeliveryAddressAddAddressModal,
        TimePickerCtrl
    }
})
export default class AddOrUpdateGateway extends Vue {
    @Prop({
        type: Object
    })
    gateway: EasySupplyGatewayDetailViewObject;

    @Prop({
        required: true,
        type: Number
    })
    warehouseDetailId: number;

    @Prop({
        required: true,
        type: Boolean
    })
    allowUpdateFirmware: boolean;

    @Prop({
        type: Array
    })
    weekdays: Array<any>;

    deliveryTypeDefaultForCreateAddress: DeliveryType = DeliveryType.DAG;
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number = 0;
    invalidForm: boolean = false;
    invalidWeekdays: boolean = false;
    invalidDeliveryAddress: boolean = false;
    deliveryAddress: DeliveryAddressViewObject | null = null;
    orderTime: string = '';
    gatewayId: string = '';
    oldDeliveryAddresses: DeliveryAddressViewModel | null = null;
    showCreateDeliveryAddress: boolean = false;

    get selectedDeliveryAddress(): DeliveryAddressViewObject | null {
        return this.gateway ? this.gateway.selectedDeliveryAddress : null;
    }

    get payloadWeekdaysAllowedToSendOrder(): Weekday[] {
        return this.weekdays
            .filter(weekday => weekday.selected)
            .map(weekday => {
                return weekday.day;
            });
    }

    get macAddressMask(): object {
        return Constants.MacAddressMask;
    }

    get buttonLabel(): string {
        return this.gateway ? this.$tr(this.$labels.Views.Services.EasySupply.Edit.Save) : this.$tr(this.$labels.Views.Services.EasySupply.Edit.Gateway.Add);
    }

    get deliveryTypeMessage(): string {
        if (this.deliveryAddress) {
            return this.deliveryAddress.addressDeliveryTypeLabel;
        }
        if (this.gateway && this.gateway.selectedDeliveryAddress) {
            return this.gateway.selectedDeliveryAddress
                .addressDeliveryTypeLabel;
        }
        return '';
    }

    created() {
        if (this.gateway) {
            this.orderTime = this.gateway.expectedOrderTime;
        }
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async reconfigureGateway(): Promise<void> {
        if (!this.deliveryAddress || this.pending) return;
        this.pending = true;

        const payload: ReconfigureEasySupplyGatewaysRequest = {
            deliveryAddressSapId: this.deliveryAddress.id,
            easySupplyWarehouseId: this.warehouseDetailId,
            expectedOrderTime: this.orderTime,
            weekdaysAllowedToSendOrder: this.payloadWeekdaysAllowedToSendOrder,
            addressDeliveryType:
                this.deliveryAddress.addressDeliveryType || DeliveryType.DAG
        };

        try {
            await Api.easySupply.reconfigureEasySupplyGateways(payload);
            this.showSuccessIndicator();
            this.$emit('updatedWarehouse');
            this.closeModal();
        } finally {
            this.pending = false;
        }
    }

    async addGateway(): Promise<void> {
        if (!this.deliveryAddress || this.pending) return;
        this.pending = true;

        const payload: AddEasySupplyGatewayRequest = {
            hardwareIdentifier: this.gatewayId,
            easySupplyWarehouseId: this.warehouseDetailId,
            expectedOrderTime: this.orderTime,
            weekdaysAllowedToSendOrder: this.payloadWeekdaysAllowedToSendOrder,
            deliveryAddressSapId: this.deliveryAddress.id,
            addressDeliveryType:
                this.deliveryAddress.addressDeliveryType || DeliveryType.DAG
        };

        try {
            await Api.easySupply.addEasySupplyGateway(payload);
            this.showSuccessIndicator();
            this.$emit('updatedWarehouse');
            this.closeModal();
        } finally {
            this.pending = false;
        }
    }

    submit() {
        this.validateForm();
        if (!this.invalidForm) {
            if (this.gateway) {
                this.reconfigureGateway();
            } else {
                this.addGateway();
            }
        }
    }

    selectDeliveryAddress(address: DeliveryAddressViewObject): void {
        this.deliveryAddress = address;
        this.validateDeliveryAddress();
    }

    onShowCreateDeliveryAddress(
        deliveryAddresses: DeliveryAddressViewModel
    ): void {
        this.oldDeliveryAddresses = deliveryAddresses;
        this.showCreateDeliveryAddress = true;
    }

    showReplaceGateway(): void {
        this.$emit('showReplaceGateway');
    }

    showDeleteGateway(): void {
        this.$emit('showDeleteGateway');
    }

    showUpdateGatewayFirmware(): void {
        this.$emit('showUpdateGatewayFirmware');
    }

    showUpdateSmartButtonsFirmware(): void {
        this.$emit('showUpdateSmartButtonsFirmware');
    }

    hideCreateDeliveryAddress(): void {
        this.showCreateDeliveryAddress = false;
    }

    validateForm(): void {
        this.validateWeekdays();
        this.validateDeliveryAddress();

        this.invalidForm = this.invalidWeekdays || this.invalidDeliveryAddress;
    }

    validateWeekdays(): void {
        this.invalidWeekdays =
            this.payloadWeekdaysAllowedToSendOrder.length < 1;
    }

    validateDeliveryAddress(): void {
        this.invalidDeliveryAddress = !this.deliveryAddress;
    }

    closeModal() {
        this.$emit('closeModal');
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors: any;
}
