




























import Vue from 'vue';
import { AppGetter, AppAction } from '@/store/app';
import { Component, Prop } from 'vue-property-decorator';
import tracking from '@/core/tracking/tracking.service';
@Component
export default class ToggleShowNetPrices extends Vue {
    @Prop({
        type: Boolean,
        default: false
    }) hideLabel!: boolean;

    @AppGetter isLoggedIn!: boolean;
    @AppGetter hideNetPrices!: boolean;
    @AppGetter canSeeNetPrices!: boolean;
    @AppGetter canSeeGrossPrices!: boolean;

    @AppAction toggleHideNetPrices!: () => void;
    toggleHideNetPricesLocal() {
        this.toggleHideNetPrices();
        this.trackNavigation();
    }

    trackNavigation() {
        tracking.trackNavigationClick({
            type: 'footer',
            clickText: this.hideNetPrices ? 'Hide Net Prices' : 'Show Net Prices' });
    }

    get showToggler() {
        return this.isLoggedIn && this.canSeeGrossPrices && this.canSeeNetPrices;
    }
}
