




































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    FirmwareDetailsViewObject,
    UpdateEasySupplyGatewayFirmwareDetailsRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class UpdateGatewayFirmware extends Vue {
    @Prop({
        required: true,
        type: String
    }) hardwareIdentifier!: string;

    @Prop({
        type: Object
    }) gatewayFirmwareDetails!: FirmwareDetailsViewObject;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number = 0;
    gatewayFirmwareHost: string = '';
    gatewayFirmwarePort: number = 0;
    gatewayFirmwareUri: string = '';
    gatewayFirmwareVersion: string = '';

    created() {
        this.setGatewayFirmware(this.gatewayFirmwareDetails);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async updateGatewayFirmware(): Promise<void> {
        if (this.pending) return;
        this.pending = true;

        const payload: UpdateEasySupplyGatewayFirmwareDetailsRequest = {
            gatewayHardwareIdentifier: this.hardwareIdentifier,
            host: this.gatewayFirmwareHost,
            port: this.gatewayFirmwarePort,
            uri: this.gatewayFirmwareUri,
            version: this.gatewayFirmwareVersion
        };

        try {
            await Api.easySupply.updateEasySupplyGatewayFirmwareDetails(payload);
            this.showSuccessIndicator();
            this.$emit('updatedWarehouse');
            this.closeModal();
        } finally {
            this.pending = false;
        }
    }

    setGatewayFirmware(firmware: FirmwareDetailsViewObject): void {
        if (firmware) {
            this.gatewayFirmwareHost = firmware.host;
            this.gatewayFirmwarePort = firmware.port;
            this.gatewayFirmwareUri = firmware.uri;
            this.gatewayFirmwareVersion = firmware.version;
        }
    }

    hideUpdateGatewayFirmware(): void {
        this.$emit('hideUpdateGatewayFirmware');
    }

    closeModal() {
        this.$emit('closeModal');
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors!: any;
}
