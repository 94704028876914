


























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ProductTileViewObject, BomDetailsViewObject } from '@/types/serverContract';
import { ProductGetter } from '@/store/product';
import Accordion from '@/project/shared/Accordion.vue';
import ProductTile from '@/project/products/ProductTile.vue';

@Component({
    components: {
        ProductTile,
        Accordion
    }
})
export default class ProductDetailsBomDescription extends Vue {
    @ProductGetter product!: BomDetailsViewObject | null;

    get productBomComponents(): ProductTileViewObject[] {
        if (!this.product) {
            return [];
        }
        return this.product.bomComponents;
    }

    get productDescriptionHeader(): string {
        return this.$tr(this.$labels.Views.ProductDetails.Description);
    }

    get isBomProduct(): boolean {
        return this.productBomComponents ? this.productBomComponents.length >= 1 : false;
    }
}
