import {
    ActionTree,
    GetterTree,
    MutationTree
} from 'vuex';
import { namespace } from 'vuex-class';
import { OrderViewModel, SubmitOrderRequest } from '@/types/serverContract';
import api from '@/project/http/api';

interface CheckoutState {
    isCommentValid: boolean;
    isOrderApproved: boolean;
    isOrderSubmitting: boolean;
    order: OrderViewModel | {};
}

enum MutationMethod {
    setCommentValid = 'setCommentValid',
    setOrder = 'setOrder',
    setOrderApproved = 'setOrderApproved',
    setOrderSubmitting = 'setOrderSubmitting'
}

const state: CheckoutState = {
    order: ({}) as OrderViewModel,
    isOrderApproved: false,
    isOrderSubmitting: false,
    isCommentValid: true
};

const getters: GetterTree<CheckoutState, any> = {
    order: state => state.order,
    isOrderApproved: state => state.isOrderApproved,
    isOrderSubmitting: state => state.isOrderSubmitting,
    isCommentValid: state => state.isCommentValid
};

const actions: ActionTree<CheckoutState, any> = {
    setOrder({ commit }, order: OrderViewModel) {
        commit(MutationMethod.setOrder, order);
        return order;
    },
    setOrderApproved({ commit }, isApproved: boolean) {
        commit(MutationMethod.setOrderApproved, isApproved);
        return isApproved;
    },
    setOrderSubmitting({ commit }, isSubmitting: boolean) {
        commit(MutationMethod.setOrderSubmitting, isSubmitting);
        return isSubmitting;
    },
    setCommentValid({ commit }, isCommentValid: boolean) {
        commit(MutationMethod.setCommentValid, isCommentValid);
        return isCommentValid;
    },
    async submitOrder({ commit }, payload: SubmitOrderRequest) {
        return api.basket.submitOrder(payload).then(() => {
            commit(MutationMethod.setOrder, null);
        });
    }
};

const mutations: MutationTree<CheckoutState> = {
    [MutationMethod.setOrder](state, order: OrderViewModel | null) {
        if (order === null) {
            state.order = {};
        } else {
            state.order = order;
        }
    },
    [MutationMethod.setOrderApproved](state, isApproved: boolean) {
        state.isOrderApproved = isApproved;
    },
    [MutationMethod.setOrderSubmitting](state, isSubmitting: boolean) {
        state.isOrderSubmitting = isSubmitting;
    },
    [MutationMethod.setCommentValid](state, isCommentValid: boolean) {
        state.isCommentValid = isCommentValid;
    }
};

export default {
    namespaced: true,
    state: state,
    getters,
    actions,
    mutations
};

export const { Getter: CheckoutGetter, Action: CheckoutAction } = namespace('checkout');
