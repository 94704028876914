

















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    CreateUserRequest,
    UserCreationViewModel,
    UserStoreViewObject
} from '@/types/serverContract';
import scrollService from '@/core/scroll/scroll.service';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import tracking, { SignUpTypesEnum } from '@/core/tracking/tracking.service';

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class CreateUser extends Vue {
    @Prop({ type: String, default: '' }) trackingType!: string;
    @Prop({ type: String, default: '' }) trackingEvent!: string;

    pending: boolean = false;
    createUserFormId: string = 'create-user-form';
    accountNumberInputChanged: boolean = false;
    companyPhoneInputChanged: boolean = false;
    nameInputChanged: boolean = false;
    userEmailInputChanged: boolean = false;
    userPhoneInputChanged: boolean = false;
    usernameInputChanged: boolean = false;
    myStoreInputChanged: boolean = false;
    step: number = 1;

    creationOptions: UserCreationViewModel = {
        stores: []
    };

    createUserPayload: CreateUserRequest = {
        soldToPartnerId: '',
        soldToPhoneNumber: '',
        name: '',
        email: '',
        mobileNumber: '',
        userName: '',
        storeId: ''
    };

    created() {
        this.getOptionsForCreate();
    }

    async createUser() {
        this.trackFormSubmit();
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.pending) {
            if (!isValid) {
                // Scoll to error
                var firstErrorField = this.$validator.fields.find({ id: this.$validator.errors.items[0].id });
                if (firstErrorField) {
                    var errorElementParentWithOffset = (firstErrorField.el as HTMLElement).closest('.c-text-input');
                    scrollService.scrollToElement(errorElementParentWithOffset as HTMLElement);
                    this.trackFormSubmitError(this.$validator.errors.items[0].msg);
                }
            }
            return;
        }
        if (this.pending) {
            return;
        }

        this.pending = true;

        try {
            await Api.user.createWebUser(this.createUserPayload, this.createUserFormId);
            tracking.trackSignup(SignUpTypesEnum.ExtraWebUser);
            this.trackFormSubmitSuccess();
        } catch (e) {
            this.step = 1;
            this.trackFormSubmitError(e);
        } finally {
            this.pending = false;
        }
    }

    async getOptionsForCreate() {
        this.pending = true;

        try {
            await Api.user.getUserCreationOptions().then(options => {
                Object.assign(this.creationOptions, options);
            });
        } finally {
            this.pending = false;
        }
    }

    onUserStoreChange(store: UserStoreViewObject) {
        this.createUserPayload.storeId = store.id;
    }

    trackFormSubmit() {
        tracking.trackCreateUserFormSubmit(this.trackingType, this.trackingEvent);
    }

    trackFormSubmitSuccess() {
        tracking.trackCreateUserFormSuccess(this.trackingType, this.trackingEvent);
    }

    trackFormSubmitError(errorMessage) {
        tracking.trackCreateUserFormError(this.trackingType, this.trackingEvent, errorMessage);
    }

    trackFormSubmitStep(stepName, stepNumber) {
        switch (stepNumber) {
            case 1.1:
                if (this.accountNumberInputChanged) return;
                this.accountNumberInputChanged = true;
                break;
            case 1.2:
                if (this.companyPhoneInputChanged) return;
                this.companyPhoneInputChanged = true;
                break;
            case 1.3:
                if (this.nameInputChanged) return;
                this.nameInputChanged = true;
                break;
            case 1.4:
                if (this.userEmailInputChanged) return;
                this.userEmailInputChanged = true;
                break;
            case 1.5:
                if (this.userPhoneInputChanged) return;
                this.userPhoneInputChanged = true;
                break;
            case 1.6:
                if (this.usernameInputChanged) return;
                this.usernameInputChanged = true;
                break;
            case 1.7:
                if (this.myStoreInputChanged) return;
                this.myStoreInputChanged = true;
                break;
        }
        tracking.trackCreateUserFormStep(this.trackingType, this.trackingEvent, stepName, stepNumber);
    }
}
