import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { namespace } from 'vuex-class';
import {
    AddOrUpdateSpecialOrderLineItemRequest,
    AddToBasketRequest,
    BasketViewModel,
    DeliveryAddressViewObject,
    DeliveryType
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { store } from '@/store';

interface BasketState {
    basket: BasketViewModel;
    busy: boolean;
    specialOrderItemForm: AddOrUpdateSpecialOrderLineItemRequest;
}

enum MutationMethod {
    setBasket = 'setBasket',
    setBusy = 'setBusy',
    setOrderForm = 'setOrderForm'
}

const storeState: BasketState = {
    basket: null as unknown as BasketViewModel,
    busy: false,
    specialOrderItemForm: {
        lineItemId: null,
        manufacturer: '',
        productItemNumber: '',
        productText: '',
        quantity: 1,
        supplementaryProductText: '',
        unitCode: '',
        caseInformation: null
    }
};

const getters: GetterTree<BasketState, any> = {
    basket: state => state.basket,
    subTotal: state => state.basket ? state.basket.subTotal : '0',
    grossTotal: state => state.basket ? state.basket.grossTotalSummary : '0',
    totalLines: state => state.basket ? state.basket.lineItems.length : 0,
    isBasketBusy: state => state.busy,
    specialOrderItemForm: state => state.specialOrderItemForm
};

const actions: ActionTree<BasketState, any> = {
    async setBasket({ commit }, basket: BasketViewModel) {
        commit(MutationMethod.setBasket, basket);
        commit(MutationMethod.setBusy, false);
        return basket;
    },
    async addToBasket({ commit }, addToBasketRequest: AddToBasketRequest) {
        commit(MutationMethod.setBusy, true);
        return Api.basket.addToBasket(addToBasketRequest, 'add-to-basket-' + addToBasketRequest.productId).finally(() => {
            commit(MutationMethod.setBusy, false);
        });
    },
    setExistingOrderForm({ commit }, specialOrderItemForm: AddOrUpdateSpecialOrderLineItemRequest) {
        commit(MutationMethod.setOrderForm, specialOrderItemForm);
        return specialOrderItemForm;
    },
    hasDeliveryDateChanged({ state }, deliveryDate: string | null) {
        return !deliveryDate && (!state.basket || !state.basket.deliveryDate) ? false : (state.basket.deliveryDate) !== deliveryDate;
    },
    hasDeliveryMethodChanged({ state }, deliveryMethod: DeliveryType | null) {
        return !deliveryMethod && (!state.basket || !state.basket.deliveryMethod) ? false : state.basket.deliveryMethod !== deliveryMethod;
    },
    hasDeliveryAddressChanged({ state }, deliveryAddress: DeliveryAddressViewObject | null | undefined) {
        return !deliveryAddress && (!state.basket || !state.basket.deliveryAddress) ? false : JSON.stringify(state.basket.deliveryAddress) !== JSON.stringify(deliveryAddress);
    }
};

const mutations: MutationTree<BasketState> = {
    [MutationMethod.setBasket](state, basket: BasketViewModel) {
        state.basket = Object.freeze(basket);
    },
    [MutationMethod.setBusy](state, newValue: boolean) {
        state.busy = newValue;
    },
    [MutationMethod.setOrderForm](state, newValue: AddOrUpdateSpecialOrderLineItemRequest) {
        state.specialOrderItemForm = newValue;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters,
    actions,
    mutations
};

export const { Getter: BasketGetter, Action: BasketAction } = namespace('basket');

export function setBasket(basket: BasketViewModel) {
    store.dispatch('basket/setBasket', basket);
}
