





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { OrderCertificateDetailsLineViewObject } from '@/types/serverContract';

@Component
export default class CertificateOrderDetailsLineItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) lineItem!: OrderCertificateDetailsLineViewObject;
}
