














































import CurtainOverlayBase from '@/components/organisms/CurtainOverlay/overlays/CurtainOverlayBase.vue';
import { ICurtainOverlayBase } from '@/components/organisms/CurtainOverlay/overlays/ICurtainOverlayBase.vue';
import { Component, Watch } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import {
    CopyToAccountRequest,
    LoginInformation,
    SalesAccountViewObject
} from '@/types/serverContract';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import { uuid } from '@/project/config/utilities';
import Api from '@/project/http/api';

@Component({
    components: { ButtonSubmit }
})
export default class CopyBasketToAccount extends CurtainOverlayBase implements ICurtainOverlayBase {
    @AppGetter loginInformation!: LoginInformation;

    name: string = this.$tr(this.$labels.Views.Basket.MoveToAccount.ChooseAccount);
    selectedSalesAccount: SalesAccountViewObject | null = null;
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;

    get id(): string {
        return 'copyBasketToAccountSelect' + uuid();
    }

    get error(): string | null {
        return this.vvErrors.first(this.name);
    }

    get successLabel(): string {
        return this.selectedSalesAccount !== null
            ? this.$tr(
                this.$labels.Views.Basket.MoveToAccountButton.Success,
                this.accountDisplayName(this.selectedSalesAccount)
            )
            : '';
    }

    get isFieldsValid(): boolean {
        return !!this.selectedSalesAccount;
    }

    mounted() {
        this.titleText = this.$tr(this.$labels.Views.Basket.MoveToAccount.CopyBasket);
        this.submitButtonText = this.$tr(this.$labels.Views.Basket.MoveToAccountButton.Copy);
        this.overlay.overlayChanged(this);
        this.toggleSubmitButton(this.isFieldsValid);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    @Watch('isFieldsValid')
    toggleSubmitButton(enable: boolean) {
        this.overlay.submitButtonEnabled = enable;
    }

    async Submit(): Promise<void> {
        if (this.error && this.selectedSalesAccount !== null) {
            return;
        }
        this.pending = true;

        try {
            const payload: CopyToAccountRequest = {
                accountNumber: this.selectedSalesAccount!.soldToPartnerId
            };

            await Api.basket.copyBasketToAccount(payload).then(() => {
                this.showSuccessIndicator();
            });
        } finally {
            this.pending = false;
            this.$emit('submit');
        }
    }

    accountDisplayName(account: SalesAccountViewObject): string {
        return `${account.soldToPartnerId} ${account.soldToPartnerName}`;
    }

    resetForm(): void {
        this.selectedSalesAccount = null;
        this.$validator.reset();
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
            this.resetForm();
        }, this.successTimer);
    }

    vvErrors!: any;
}
