import httpService from '@/core/http/http.service';
import {
    AddAccountToUserRequest,
    AvailableAccountsForAdministrationViewModel,
    UpdateAllowNewUsersOnAccountsRequest,
    UserAdministrationViewModel,
    UserViewModel,
    CreateAccountRequest,
    SalesAccountSlim
} from '@/types/serverContract';

export default class AccountController {
    public async addSalesAccount(payload: AddAccountToUserRequest): Promise<UserViewModel> {
        return httpService.put<UserViewModel>('accountApi/addSalesAccount', payload);
    }

    public async getUsersForAccount(): Promise<UserAdministrationViewModel> {
        return httpService.get<UserAdministrationViewModel>('accountApi/UsersForAccount');
    }

    public async availableAccountsForAdministration(): Promise<AvailableAccountsForAdministrationViewModel> {
        return httpService.get<AvailableAccountsForAdministrationViewModel>('accountApi/availableAccountsForAdministration');
    }

    public async updateAllowNewUsersOnAccounts(payload: UpdateAllowNewUsersOnAccountsRequest): Promise<AvailableAccountsForAdministrationViewModel> {
        return httpService.post<AvailableAccountsForAdministrationViewModel>('accountApi/updateAllowNewUsersOnAccounts', payload);
    }

    public async createAccount(payload: CreateAccountRequest, messagesId: string) {
        return httpService.post('accountApi/createAccount', payload, messagesId).then(response => {
            Promise.resolve(response);
        }).catch(e => {
            if (e.isRedirect) {
                Promise.resolve(e);
                return;
            }

            if (e.data && e.data.clientMessage && e.data.clientMessage.messages[0].message) {
                throw e.data.clientMessage.messages[0].message;
            } else if (e.data && e.data.exceptionMessage) {
                throw e.data.exceptionMessage;
            }
            throw e.data.message;
        });
    }

    public async getAccountsForCurrentUser(): Promise<SalesAccountSlim> {
        return httpService.get<SalesAccountSlim>('accountApi/GetAccountsForCurrentUser');
    }
}
