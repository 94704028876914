













































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import dayjs, { Dayjs } from 'dayjs';
import BookkeepingUrlHelper from '../BookkeepingUrlHelper.service';
import { dateParseFormat } from '@/project/config/utilities';
import { clone } from 'lodash-es';
import DatePickerCtrl from '@/project/form/DatePickerCtrl.vue';
import { Route } from 'vue-router';

@Component({
    components: {
        DatePickerCtrl
    }
})
export default class HistoricalDataSearchForm extends Vue {
    @Prop({
        required: true,
        type: String
    }) heading!: string;

    @Prop({
        default: false,
        type: Boolean
    }) hideTextInput!: boolean;

    @Prop({
        default: '',
        type: String
    }) textInputLabel!: string;

    @Prop({
        default: true,
        type: Boolean
    }) searchOnLoad!: boolean;

    searchHistoricalDataPayload: any = {
        documentId: BookkeepingUrlHelper.getSearchQuery('orderNumber'),
        fromDate: BookkeepingUrlHelper.getSearchQuery('fromDate') === '' ? dayjs().subtract(6, 'month').format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('fromDate'),
        page: BookkeepingUrlHelper.getPageNumber(),
        toDate: BookkeepingUrlHelper.getSearchQuery('toDate') === '' ? dayjs().format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('toDate')
    };

    get isDateSpanTooLong(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) > 6;
    }

    get isDateSpanTooShort(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) < 0;
    }

    get dayjsFromDate(): Dayjs {
        return dayjs(this.searchHistoricalDataPayload.fromDate);
    }

    get dayjsToDate(): Dayjs {
        return dayjs(this.searchHistoricalDataPayload.toDate);
    }

    created() {
        if (this.searchOnLoad) {
            this.searchHistoricalData();
        }
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            return;
        }
        const pageChanged = this.searchHistoricalDataPayload.page !== BookkeepingUrlHelper.getPageNumber();
        if (pageChanged) {
            this.searchHistoricalDataPayload.page = BookkeepingUrlHelper.getPageNumber();
            this.searchHistoricalData();
        }
    }

    @Watch('searchHistoricalDataPayload.fromDate')
    setFromDate(): void {
        if (this.isDateSpanTooLong) {
            this.searchHistoricalDataPayload.toDate = this.dayjsFromDate.clone().add(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.searchHistoricalDataPayload.toDate = clone(this.searchHistoricalDataPayload.fromDate);
        }
    }

    @Watch('searchHistoricalDataPayload.toDate')
    setToDate(): void {
        if (this.isDateSpanTooLong) {
            this.searchHistoricalDataPayload.fromDate = this.dayjsToDate.clone().subtract(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.searchHistoricalDataPayload.fromDate = clone(this.searchHistoricalDataPayload.toDate);
        }
    }

    searchHistoricalData(): void {
        this.$emit('search', this.searchHistoricalDataPayload);
    }

    submitForm(): void {
        this.searchHistoricalDataPayload.page = 1;
        this.searchHistoricalData();
    }
}
