


















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { AppGetter } from '@/store/app';
import { EventTeaserViewModel } from '@/types/serverContract';

@Component
export default class ShopEventsTeaser extends Vue {
    private eventsResponse: EventTeaserViewModel | null = null;

    @AppGetter isLoggedIn!: boolean;

    created() {
        this.getTeaserEvents();
    }

    get hasEvents() {
        return this.topEvents[0] !== undefined;
    }

    get topEvents() {
        return this.eventsResponse ? this.eventsResponse.events.slice(1) : [];
    }

    get heading() {
        return this.eventsResponse && this.isLoggedIn ? this.$tr(this.$labels.Views.Event.Teaser.HeadingAuthenticated) : this.$tr(this.$labels.Views.Event.Teaser.Heading);
    }

    async getTeaserEvents() {
        this.eventsResponse = await Api.shopEvents.getTeaserEvents();
    }
}
