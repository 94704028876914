








































































import Vue from 'vue';
import {
    Component,
    Watch
} from 'vue-property-decorator';
import {
    AppGetter,
    AppAction
} from '@/store/app';
import {
    AgreementViewObject,
    AgreementViewModel,
    MarkAgreementAsAcceptedRequest,
    MarkAgreementAsPresentedRequest
} from '@/types/serverContract';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import Api from '@/project/http/api';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';

@Component({
    components: {
        ModalOverlay
    }
})
export default class AgreementsModal extends Vue {
    @AppGetter nonAcceptedAgreement!: AgreementViewObject;
    @AppAction setNonAcceptedAgreement!: (nonAcceptedAgreement: AgreementViewModel) => void;

    selectOptOutFromEmails: boolean | null = null;
    showNonAcceptedAgreementModal: boolean = false;

    get hasNonAcceptedAgreement(): boolean {
        return this.nonAcceptedAgreement !== null;
    }

    get agreementHeader(): string {
        return this.hasNonAcceptedAgreement ? this.nonAcceptedAgreement.headerText : '';
    }

    get showOptMail(): boolean {
        return this.nonAcceptedAgreement.agreementNavigationId ? this.nonAcceptedAgreement.agreementNavigationId === 'webterms' : false;
    }

    $refs!: {
        modalOverlay: ModalOverlay;
    };

    @Watch('nonAcceptedAgreement', {
        immediate: true
    })
    onNonAcceptedAgreementChange() {
        if (this.hasNonAcceptedAgreement) {
            this.showNonAcceptedAgreementModal = true;
            const payload: MarkAgreementAsPresentedRequest = {
                agreementId: this.nonAcceptedAgreement.id
            };
            Api.agreements.markAgreementAsPresented(payload);
        } else {
            this.closeModal();
        }
    }

    closeModal() {
        this.showNonAcceptedAgreementModal = false;
    }

    cancelAgreement() {
        this.closeModal();
        if (this.hasNonAcceptedAgreement && !this.nonAcceptedAgreement.allowWait) {
            Api.authentication.logout();
        }
    }

    acceptAgreement() {
        const payload: MarkAgreementAsAcceptedRequest = {
            agreementId: this.nonAcceptedAgreement.id,
            optOutFromEmails: this.selectOptOutFromEmails
        };
        Api.agreements.markAgreementAsAccepted(payload).then(() => {
            Api.agreements.nextNonAcceptedAgreement().then(nonAcceptedAgreement => {
                this.setNonAcceptedAgreement(nonAcceptedAgreement);
                if (this.hasNonAcceptedAgreement) {
                    const offCanvasOverlay = this.$refs.modalOverlay.$refs.offCanvasOverlay as OffCanvasOverlay;
                    (offCanvasOverlay.$refs.contentWrapper as HTMLElement).scrollTo(0, 0);
                }
            });
        });
    }
}
