




































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NavigationItem } from '@/types/serverContract';

@Component
export default class NavItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) navigationItem!: NavigationItem;

    expanded: boolean = false;

    get isActive(): boolean {
        return this.$route.path === this.navigationItem.link.url;
    }

    get hasVisibleChildNodes(): boolean {
        return this.navigationItem.children && this.navigationItem.children.length > 0;
    }

    get hasActiveChild(): boolean {
        return this.$route.path.startsWith(this.navigationItem.link.url);
    }

    created(): void {
        this.expanded = this.isActive || this.hasActiveChild;
    }

    showChildrenList(): void {
        this.expanded = true;
    }

    toggleChildrenList(): void {
        this.expanded = !this.expanded;
    }
}
