












































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ContactViewObject } from '@/types/serverContract';

@Component
export default class ContactPersonItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) contactEmployee!: ContactViewObject;
}
