





























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EasySupplyWarehouseLineViewObject } from '@/types/serverContract';

@Component({
    model: {
        prop: 'isChosen',
        event: 'change'
    }
})
export default class EasySupplyPrintLabelsWarehouseLineItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) warehouseLine!: EasySupplyWarehouseLineViewObject;

    @Prop({
        default: false,
        type: Boolean
    }) isChosen!: boolean;

    onChangeIsChosen(isChosen: boolean): void {
        this.$emit('change', isChosen);
    }
}
