
















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { BasketGetter } from '@/store/basket';
import api from '@/project/http/api';
import ProductAddToBasketLifeCycleIcons from '@/project/products/ProductAddToBasketLifeCycleIcons.vue';
import urlHelper from '@/project/shared/services/urlHelperSimpleSearch.service';

@Component({
    components: { ProductAddToBasketLifeCycleIcons }
})
export default class AddProductCatalogToBasket extends Vue {
    @BasketGetter isBasketBusy!: boolean;

    productCatalogId = urlHelper.getId();

    pending: boolean = false;
    success: boolean = false;
    timeout: number | null = null;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async addAllLineItemsToBasket() {
        if (this.isBasketBusy || this.pending) {
            return;
        }

        this.pending = true;
        try {
            await api.basket.addProductCatalogToBasket(this.productCatalogId).then(() => {
                this.showSuccessIndicator();
            });
        } finally {
            this.pending = false;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, 3000);
    }
}
