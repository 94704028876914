





























































































































































import Vue from 'vue';
import { AppGetter } from '@/store/app';
import { Component } from 'vue-property-decorator';
import {
    LoginInformation,
    OrderViewModel,
    SitePage
} from '@/types/serverContract';
import api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import {
    isNotNullEmptyOrUndefined,
    stringReplaceAll
} from '@/project/config/utilities';
import serverContext from '@/core/serverContext.service';
import DeliveryApproveOrderLineItem from '@/project/checkout/DeliveryApproveOrderLineItem.vue';
import OrderSummary from '@/project/checkout/OrderSummary.vue';
import scrollService from '@/core/scroll/scroll.service';
import { headerHeight } from '@/core/responsive/breakpoints/breakpoints.service';
import productTrackingService, { OptionalTrackingData } from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';
import { ITransactionCompleteActionField } from '@/core/tracking/productTracking.g4-structure';

@Component({
    components: {
        OrderSummary,
        DeliveryApproveOrderLineItem,
        SpinnerOverlay
    }
})
export default class OrderReceipt extends Vue {
  @AppGetter canSeeNetPrices!: boolean;
  @AppGetter canSeeGrossPrices!: boolean;
  @AppGetter loginInformation!: LoginInformation;

  order: OrderViewModel | null = null;
  orderPending: boolean = false;

  get hasOrder(): boolean {
      return isNotNullEmptyOrUndefined(this.order);
  }

  get orderHistoryUrl(): SitePage {
      return serverContext.sitePages.orderHistoryPage;
  }

  get unknownDeliveryLabel(): string {
      return this.$tr(this.$labels.Views.Checkout.Confirmation.DeliveryDate.OutOfStock);
  }

  created() {
      this.getOrder();
  }

  async getOrder() {
      this.orderPending = true;
      try {
          this.order = await api.basket.getOrder(
              this.$route.query.order.toString()
          );
          this.doTrackTransactionComplete();
      } finally {
          this.orderPending = false;
      }
  }

  private doTrackTransactionComplete(): void {
      if (!this.order || !this.order.trackOrder) {
          return;
      }

      const actionField: ITransactionCompleteActionField = {
          affiliation: 'Ahlsell.dk',
          // eslint-disable-next-line @typescript-eslint/camelcase
          transaction_id: this.order.sapOrderId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          requisition_number: this.order.requisitionNumber,
          reference: this.order.reference,
          // eslint-disable-next-line @typescript-eslint/camelcase
          case_number: this.order.caseNumber
      };

      productTrackingService.TrackProducts(
          PRODUCT_TRACKING_EVENT.TransactionComplete,
          productTrackingService.ToTrackedProducts(
              this.order.lineItems,
              PRODUCT_TRACKING_TYPE.LineItemViewObject
          ),
          actionField,
          new OptionalTrackingData({
              storeId: this.order.storeId
          })
      );
  }

  getDeliveryRefId(deliveryDate: string): string {
      return stringReplaceAll(deliveryDate, [':', '/', ' ', '.'], '');
  }

  scrollToId(refId: string): void {
      scrollService.scrollToElement(
          (this.$refs[refId] as HTMLElement)[0],
          -headerHeight(true)
      );
  }
}
