import httpService from '@/core/http/http.service';
import {
    DocumentListViewModel,
    AccountStatementsRequest,
    HistoricDeliveryNotesRequest,
    HistoricInvoicesRequest,
    HistoricOrdersRequest
} from '@/types/serverContract';

export default class HistoricalDataController {
    public async getOrderHistory(payload: HistoricOrdersRequest): Promise<DocumentListViewModel> {
        return httpService.get<DocumentListViewModel>('historicalDataApi/getOrderHistory', payload);
    }

    public async getDeliveryNotes(payload: HistoricDeliveryNotesRequest): Promise<DocumentListViewModel> {
        return httpService.get<DocumentListViewModel>('historicalDataApi/getDeliveryNotes', payload);
    }

    public async getInvoices(payload: HistoricInvoicesRequest): Promise<DocumentListViewModel> {
        return httpService.get<DocumentListViewModel>('historicalDataApi/getInvoices', payload);
    }

    public async getAccountStatements(payload: AccountStatementsRequest): Promise<DocumentListViewModel> {
        return httpService.get<DocumentListViewModel>('historicalDataApi/getAccountStatements', payload);
    }
}
