
























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import Api from '@/project/http/api';
import {
    AddProjectFolderItemLinkRequest,
    GetProjectFolderItemsRequest,
    ProjectFolderItemType,
    ProjectFolderLinkListViewModel
} from '@/types/serverContract';

@Component({
    components: { SpinnerOverlay }
})
export default class HelpDeskAdministerProjectFolderEditUsefullLinks extends Vue {
    @Prop({
        required: true,
        type: Number
    }) salesAccountId!: number;

    url: string = '';
    title: string = '';
    pending: boolean = false;
    usefullLinksList: ProjectFolderLinkListViewModel | null = null;

    $refs!: {
        importCSVFile: HTMLInputElement;
    };

    created(): void {
        this.getUsefullLinks();
    }

    async getUsefullLinks(): Promise<void> {
        this.pending = true;

        try {
            const payload: GetProjectFolderItemsRequest = {
                salesAccountId: this.salesAccountId,
                type: ProjectFolderItemType.Link
            };
            await Api.helpDesk.getProjectFolderItems(payload).then(usefullLinks => {
                if (usefullLinks) {
                    this.usefullLinksList = usefullLinks;
                } else {
                    this.usefullLinksList = {
                        links: []
                    };
                }
            });
        } finally {
            this.pending = false;
        }
    }

    async submitForm(): Promise<void> {
        this.pending = true;
        try {
            const payload: AddProjectFolderItemLinkRequest = {
                salesAccountId: this.salesAccountId,
                title: this.title,
                url: this.url
            };

            await Api.helpDesk.addProjectFolderItemLink(payload).then(usefullLinks => {
                this.usefullLinksList = usefullLinks;
                this.resetForm();
            });
        } finally {
            this.pending = false;
        }
    }

    async deleteProjectFolderItem(linkId: number): Promise<void> {
        this.pending = true;

        try {
            await Api.helpDesk.deleteProjectFolderItem(linkId).then(() => {
                this.getUsefullLinks();
            });
        } finally {
            this.pending = false;
        }
    }

    resetForm(): void {
        this.url = '';
        this.title = '';
    }

    goBack(): void {
        this.$emit('goBack');
    }
}
