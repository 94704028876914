



















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ServiceGroupContentViewModel } from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: { SpinnerOverlay }
})
export default class MyServicesContentFrontPage extends Vue {
    pending: boolean = false;
    serviceGroups: ServiceGroupContentViewModel[] = [];

    created(): void {
        this.getContentFrontPage();
    }

    async getContentFrontPage(): Promise<void> {
        this.pending = true;

        try {
            await Api.services.contentFrontPage().then(serviceGroupContentList => {
                this.serviceGroups = serviceGroupContentList.serviceGroups;
            });
        } finally {
            this.pending = false;
        }
    }
}
