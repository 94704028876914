






























































import Vue from 'vue';
import {
    Component,
    Prop
} from 'vue-property-decorator';
import { HelpDeskSalesAccountsViewObject, HelpDeskUserViewObjectSlim } from '@/types/serverContract';
import HelpDeskAccountLine from '@/project/helpDesk/HelpDeskAccountLine.vue';
import { ActiveFields } from './HelpDesk.vue';

@Component({
    components: {
        HelpDeskAccountLine
    }
})

export default class HelpDeskAccounts extends Vue {
    @Prop({ required: true, type: Array }) salesAccounts!: HelpDeskSalesAccountsViewObject[];
    @Prop({ default: false, type: Boolean }) seeAllUsersSelected!: boolean;
    @Prop({ type: Object }) activeFields!: ActiveFields;

    selectAccount(selectedAccount: HelpDeskSalesAccountsViewObject): void {
        this.$emit('accountSelected', selectedAccount);
    }

    selectProfile(selectedProfile: HelpDeskSalesAccountsViewObject): void {
        this.$emit('profileSelected', selectedProfile);
    }

    selectUser(selectedAccount: HelpDeskSalesAccountsViewObject, selectedUser: HelpDeskUserViewObjectSlim): void {
        this.$emit('userSelected', selectedAccount, selectedUser);
    }
}
