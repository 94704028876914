import { AxiosResponse } from 'axios';
import {
    BaseApiResponse,
    ProductSearchResponse,
    SearchAsYouTypeResponse
} from '@/types/serverContract';
import httpService from './http.service';
import tracking from '@/core/tracking/tracking.service';
import { termKey } from '@/project/search/urlHelper.service';
import HttpStatus from 'http-status-codes';

export class ProductSearchEndpoints {
    public static readonly Search = '/searchApi/search';
    public static readonly SearchAsYouType = '/searchApi/asYouType';
}

const responseHandler = (response: AxiosResponse<BaseApiResponse<ProductSearchResponse | SearchAsYouTypeResponse>>) => {
    if (response.status !== HttpStatus.OK) {
        return response;
    }
    const url = response.config.url;
    const isSearchResult = url && url.indexOf(httpService.getUrl(ProductSearchEndpoints.Search)) > -1;
    const isSearchAsYouTypeResult = url && url.indexOf(httpService.getUrl(ProductSearchEndpoints.SearchAsYouType)) > -1 && response.config && response.config.params && response.config.params[termKey];

    if (isSearchResult) {
        // TODO: Remove when tracking work is done, kept for reference
        // tracking.trackSearchResult(response as AxiosResponse<BaseApiResponse<ProductSearchResponse>>);
    } else if (isSearchAsYouTypeResult) {
        tracking.trackSearchAsYouTypeResult(response as AxiosResponse<BaseApiResponse<SearchAsYouTypeResponse>>);
    }
    return response;
};

export {
    responseHandler
};
