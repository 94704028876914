



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ButtonCheckBox extends Vue {
    @Prop({
        type: Boolean,
        default: false
    }) isChecked!: boolean;

    updateChecked(): void {
        this.$emit('updateChecked');
    }
}
