
























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: { SpinnerOverlay }
})
export default class ChangePassword extends Vue {
    showSpinner = false;
    password1 = '';
    password2 = '';

    activationKey = '';

    mounted() {
        this.activationKey = this.$route.query['key'] as string;
    }

    validatePasswordMatch(): boolean {
        return this.password1 === this.password2;
    }

    get mustMatchMessage() {
        return this.$tr(this.$labels.Views.Login.ChangePassword.PasswordMustMatch);
    }

    async changePassword() {
        if (!this.validatePasswordMatch()) return;

        try {
            this.showSpinner = true;
            await Api.authentication.resetPassword(this.activationKey, this.password1, 'change-pw');
        } catch (e) {
            // Ok for validation-msgs.
        } finally {
            this.showSpinner = false;
        }
    }
}
