












































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
    PriceServiceMixin,
    PriceServiceViewObject
} from '@/project/products/PriceServiceMixin.mixin';
import { BasketAllowanceGroupViewObject } from '@/types/serverContract';

@Component
export default class BasketSummarySubTotal extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({
        required: true,
        type: Boolean
    }) hasCertificateOrCharges!: boolean;

    @Prop({
        default: '',
        type: String
    }) netSubTotal!: string;

    @Prop({
        default: '',
        type: String
    }) grossSubTotal!: string;

    @Prop({
        default: () => [],
        type: Array
    }) allowances!: BasketAllowanceGroupViewObject[];

    price: PriceServiceViewObject = {
        netPrice: this.netSubTotal,
        grossPrice: this.grossSubTotal
    };

    get hasAllowances(): boolean {
        return this.shouldShowNetPrice && this.allowances && this.allowances.length >= 1;
    }

    @Watch('netSubTotal')
    onSubTotalChange(): void {
        this.price.netPrice = this.netSubTotal;
    }

    @Watch('grossSubTotal')
    onGrossSubTotalChange(): void {
        this.price.grossPrice = this.grossSubTotal;
    }
}
