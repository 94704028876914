


























































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import {
    BomDetailsViewObject,
    VariantDetailsViewObject,
    ProductImageViewObject,
    StoreStockQuantityViewObject
} from '@/types/serverContract';
import ProductDetailsImagesCarousel from '@/project/products/carousels/ProductDetailsImagesCarousel.vue';
import ProductDetailsImagesThumbs from '@/project/products/ProductDetailsImagesThumbs.vue';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import ProductDetailsTabs from '@/project/products/ProductDetailsTabs.vue';
import ProductTile from '@/project/products/ProductTile.vue';
import Api from '@/project/http/api';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';
import Accordion from '@/project/shared/Accordion.vue';
import ProductDetailsSpecs from '@/project/products/ProductDetailsSpecs.vue';
import ProductAddToBasketLifeCycleIcons from '@/project/products/ProductAddToBasketLifeCycleIcons.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import { FlexBoxGetter } from '@/store/flexBox';
import {
    ProductAction
} from '@/store/product';
import productTrackingService, { TrackedProduct } from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';

@Component({
    components: {
        ProductDetailsImagesCarousel,
        ProductDetailsImagesThumbs,
        OffCanvasOverlay,
        ProductDetailsTabs,
        ProductTile,
        SpinnerElement,
        Accordion,
        ProductDetailsSpecs,
        ProductAddToBasketLifeCycleIcons,
        ButtonSubmit
    }
})
export default class ProductDetailsBom extends Vue {
    @Prop({
        required: true,
        type: Object
    }) product!: BomDetailsViewObject;

    @AppGetter isLoggedIn!: boolean;
    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @ProductAction setProduct!: (product: BomDetailsViewObject | null) => void;
    @ProductAction setProductSelectedVariant!: (selectedVariant: VariantDetailsViewObject | null) => void;
    @ProductAction setProductStoreStocks!: (storeStocks: StoreStockQuantityViewObject[] | null) => void;

    activateFullScreen: boolean = false;
    activeIndex: number = 0;
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;

    created() {
        this.setProduct(this.product);

        if (this.product.variant) {
            this.setProductSelectedVariant(this.product.variant);
        } else {
            this.setProductSelectedVariant(null);
            this.setProductStoreStocks(null);
        }
    }

    mounted() {
        productTrackingService.TrackBatchProducts(
            PRODUCT_TRACKING_EVENT.ProductImpression,
            productTrackingService.ToTrackedProducts(
                this.product.bomComponents,
                PRODUCT_TRACKING_TYPE.ProductTileViewObject
            )
        );
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    get defaultImages(): ProductImageViewObject[] | [] {
        return this.selectedVariant && this.selectedVariant.images ? this.selectedVariant.images : [];
    }

    get selectedVariant(): VariantDetailsViewObject | null {
        return this.product && this.product.variant ? this.product.variant : null;
    }

    async addBomProductsToBasket() {
        this.pending = true;
        this.success = false;

        try {
            await Api.basket.addBomProducts(this.product.productId);

            this.product.bomComponents.forEach(comp => {
                let variant = comp.variants[0];
                const addToBasketPayload = {
                    addAsNewLine: false,
                    productId: variant.ids.variantId,
                    quantity: variant.orderableUnits[0].increment,
                    unit: variant.orderableUnits[0].unitCode,
                    certificateCode: null,
                    stamping: false,
                    isFixedUnit: false,
                    fixedUnitCode: null,
                    fixedUnitValue: null,
                    caseInformation: null
                };

                productTrackingService.TrackProduct(
                    PRODUCT_TRACKING_EVENT.AddToCart,
                    productTrackingService.ToTrackedProduct(
                        addToBasketPayload,
                        PRODUCT_TRACKING_TYPE.AddToBasketRequest,
                        null,
                        new TrackedProduct({
                            name: comp.name,
                            brand: variant.manufacturer,
                            category: variant.primaryCategoryName,
                            trackingContextObject: variant.trackingContext
                        })
                    )
                );
            });

            this.showSuccessIndicator();
        } finally {
            this.pending = false;
        }
    }

    toggleFullScreen(): void {
        this.activateFullScreen = !this.activateFullScreen;
    }

    updateSliderIndex(index: number): void {
        this.activeIndex = index;
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    print(): void {
        window.print();
    }
}
