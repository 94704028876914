













































































































































































































































































































































































































































































import { Component, Watch, Mixins } from 'vue-property-decorator';
import {
    CategoryScopeViewObject,
    SearchAsYouTypeResponse,
    ProductSearchRequest,
    SearchAsYouTypeRequest,
    ProductSearchResponse,
    SuggestionViewObject,
    ProductTileViewObject,
    SearchSuggestionsRequest,
    MarketingBannerViewObject
} from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';
import { router } from '@/router';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import Constants from '@/project/config/constants';
import tracking from '@/core/tracking/tracking.service';
import { termKey, categoryIdKey } from '@/project/search/urlHelper.service';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import SearchPanelSuggestionsSlider from '@/project/search/SearchPanelSuggestionsSlider.vue';
import SearchPanelBrandSuggestionsSlider from '@/project/search/SearchPanelBrandSuggestionsSlider.vue';
import SearchProductTile from '@/project/search/SearchProductTile.vue';
import scrollService from '@/core/scroll/scroll.service';
import { debounce, isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import keyboardService from '@/core/keyCodes';
import Api from '../http/api';
import { getInteractiveChildElements } from 'focus-elements/src/focus';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoints.mixin';
import SearchNoResultsFound from '@/project/search/SearchNoResultsFound.vue';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';
import MarketingBanner from '@/project/marketing/MarketingBanner.vue';
import breakpointService from '../../core/responsive/breakpoints/breakpoints.service';
import Cookies from 'js-cookie';
import productTrackingService, { TrackedProduct } from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';

let typeAheadSearchSeq = 0;
let suggestSearchSeq = 0;

class CachedResult {
    constructor(searchResult: SearchAsYouTypeResponse, bannerResult: MarketingBannerViewObject) {
        this.searchResult = searchResult;
        this.bannerResult = bannerResult;
    }

    searchResult: SearchAsYouTypeResponse;
    bannerResult: MarketingBannerViewObject;
}

@Component({
    mixins: [BreakpointsMixin],
    components: {
        OffCanvasOverlay,
        SpinnerOverlay,
        SearchProductTile,
        SearchPanelSuggestionsSlider,
        SearchPanelBrandSuggestionsSlider,
        SearchNoResultsFound,
        SpinnerElement,
        MarketingBanner
    }
})
export default class SearchPanel extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    asYouTypeTerm: string = ''; // Term that is being typed
    isActive: boolean = false; // Is the search panel active
    isSearching: boolean = false; // Is the search panel searching
    placeholderSearchText: string = ''; // Placeholder text in search panel
    searchResult: SearchAsYouTypeResponse | null = null; // Final search result
    marketingBanner: MarketingBannerViewObject; // Final banner result
    isSearchInFocus: boolean = false; // Is the search input in focus
    minNoOfCharsToSearch: number = 2; // min number of characters to search

    // No idea
    selectedCategory: string = '';
    observer: IntersectionObserver | undefined;
    showStickyCategoryBar: boolean = true;
    focusedSuggestionIndex: number = -1;
    focusedProductIndex: number = -1;
    focusedCategoryIndex: number = -1;
    showModal: boolean = false;
    scrollBarWidth = 0;
    hasCalculatedScroll: boolean = false;
    disableKeyEvents = false;
    timeout: number | null = null;

    // Used when searching by hovering a suggestion
    selectedSuggestionSearchResult: SearchAsYouTypeResponse | null = null;
    selectedSuggestion: string = '';

    // Caches for search results, to avoid repeat api calls
    cachedSearchResults: Record<string, CachedResult> = {};
    cachedSugestionSearchResults: Record<string, ProductSearchResponse> = {};

    get keyForBrandRefresh(): string {
        return this.$refs.input.value + (this.searchResult ? this.searchResult.brandSuggestions.length : '0');
    }

    get keyForSuggestionRefresh(): string {
        return this.$refs.input.value + (this.searchResult ? this.searchResult.suggestions.length : '0');
    }

    get searchpageUrl(): string {
        return serverContext.sitePages.searchPage.url;
    }

    get suggestionsLength(): number {
        return this.searchResult !== null ? this.searchResult.suggestions.length : 0;
    }

    get productsLength(): number {
        if (!this.searchResult) return 0;
        return this.selectedSuggestionSearchResult !== null ? this.selectedSuggestionSearchResult.products.length : this.searchResult.products.length;
    }

    get categoriesLength(): number {
        if (!this.searchResult) return 0;
        return this.selectedSuggestionSearchResult !== null ? this.selectedSuggestionSearchResult.categories.length : this.searchResult.categories.length;
    }

    get showBrandCategories(): boolean {
        return (this.searchResult && this.searchResult.brandSuggestions && this.searchResult.brandSuggestions.length > 0) || false;
    }

    get scrollBarWidthStyle(): string {
        return this.hasCalculatedScroll ? `padding-left: ${this.scrollBarWidth}px;` : '';
    }

    get searchButtonText(): string {
        if (this.isActive) {
            return this.$tr(this.$labels.Views.General.Close);
        } else if (this.searchResult && this.searchResult.suggestions && this.searchResult.suggestions.length + this.searchResult.categories.length === 0) {
            return this.$tr(this.$labels.Views.Header.Search.Field.ButtonText);
        } else {
            return this.$tr(this.$labels.Views.Header.Search.Field.ButtonText);
        }
    }

    get outerClasses(): string {
        return 'md:flex md:justify-center ' + (this.searchResult && this.searchResult.products.length === 0 ? 'search-panel__no-results' : '');
    }

    get showResults(): boolean {
        return this.searchResult !== null && this.isActive && (this.searchResult.categories.length > 0 || this.searchResult.products.length > 0 || this.searchResult.suggestions.length > 0 || isNotNullEmptyOrUndefined(this.searchResult.promotedResult));
    }

    public $refs!: {
        categories: HTMLElement;
        input: HTMLInputElement;
        inputWrapper: HTMLElement;
        OffCanvasOverlay: OffCanvasOverlay;
        overlay: HTMLElement;
        overlayScrollFix: HTMLElement;
        products: HTMLElement;
        stickyCategoryBar: HTMLElement;
        suggestions: HTMLElement;
        SuggestionsSlider: SearchPanelSuggestionsSlider;
    };

    mounted() {
        breakpointService.addListener(() => this.updateSearchText());
        document.addEventListener('keyup', this.handleArrowEvents);

        // If the URL contains the parameter "useBetaSearch" and it has a value we set a cookie to enable the new search endpoint
        if (!this.$route.query.useOldSearch || !this.$route.query.useOldSearch.length) {
            return;
        }

        const rwSearchOptions = this.$route.query.useOldSearch;
        const cookieValue = `ES_${rwSearchOptions}`;
        Cookies.set('ES_search', cookieValue);
    }

    updated() {
        this.$nextTick(function() {
            this.observe();
        });
    }

    updateSearchText(): void {
        if (breakpointService.isActiveBreakpoint('xs, md')) {
            this.placeholderSearchText = this.$tr(this.$labels.Views.Header.Search.Field.PlaceholderTextMobile);
            return;
        }
        this.placeholderSearchText = this.$tr(this.$labels.Views.Header.Search.Field.PlaceholderText);
    }

    getIsSuggestionSeletedClass(suggestion: SuggestionViewObject) {
        if (this.selectedSuggestion === suggestion.term) {
            return 'selected';
        }
    }

    getIsCategorySeletedClass(category: CategoryScopeViewObject) {
        if (this.selectedCategory === this.getCurrentCatCacheId(category.categoryId)) {
            return 'selected';
        }
    }

    calcContainerScrollSize() {
        this.timeout = setTimeout(() => {
            if (!breakpointService.isActiveBreakpoint('xm,sm') && this.$refs.OffCanvasOverlay.$refs.contentWrapper) {
                this.scrollBarWidth = this.$refs.OffCanvasOverlay.$refs.contentWrapper.offsetWidth - this.$refs.OffCanvasOverlay.$refs.contentWrapper.clientWidth;
                this.hasCalculatedScroll = true;
            }
        }, 100);
    }

    getLastTypedTerm(): string {
        return tracking.getLastTypedTerm();
    }

    destroyed() {
        this.setActive(false);
        if (this.observer) {
            this.observer.disconnect();
        }

        document.removeEventListener('keyup', this.handleArrowEvents);

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    @Watch('$route', { immediate: true })
    onRouteChange() {
        this.cachedSearchResults = {};
        this.clickOutside();
    }

    @Watch('closeSearch')
    onCloseSearchChange(state: boolean) {
        if (!state) {
            this.clickOutside();
        }
    }

    getCurrentCatCacheId(category: string): string {
        const searchTerm = this.selectedSuggestion !== '' ? this.selectedSuggestion : this.asYouTypeTerm;
        const cacheId = category + searchTerm;
        return cacheId;
    }

    async doSuggestSearch(category: string = '') {
        const requestSeq = ++suggestSearchSeq;
        // save last search Id to reload it when category sorting is removed
        if (category) {
            this.clearSelectedSuggestion();
        }

        this.isSearching = true;
        const searchTerm = this.selectedSuggestion !== '' ? this.selectedSuggestion : this.asYouTypeTerm;
        const cacheId = this.getCurrentCatCacheId(category);

        try {
            const searchPayload: ProductSearchRequest | any = {
                term: searchTerm,
                page: 1,
                categoryId: category,
                skipUniqueResultHandling: true
            };

            if (!this.cachedSugestionSearchResults[cacheId]) {
                const suggestionResult = { ...await Api.search.search(searchPayload) };
                this.cachedSugestionSearchResults[cacheId] = suggestionResult;
            }
            if (this.cachedSugestionSearchResults[cacheId]) {
                this.selectedCategory = cacheId;
                this.setSuggestSearchResult(requestSeq, this.cachedSugestionSearchResults[cacheId]);
            }
        } finally {
            this.isSearching = false;

            this.$nextTick().then(() => {
                this.calcContainerScrollSize();
            });
        }
    }

    setSuggestSearchResult(requestSeq: number, result: ProductSearchResponse) {
        // only use newest, if crossing request/responses, and if user pressed enter, let page redirect and don't open suggest
        if (requestSeq === suggestSearchSeq) {
            // Handle if esc key has been used and input still has focus
            if (window.document.activeElement === this.$refs.input) {
                this.setActive(true);
            }

            if (this.searchResult != null) {
                // update current showing result values
                this.searchResult.products = result.products;
                this.searchResult.totalResults = result.totalResults;
            }
        }
    }

    async doSearchAsYouType() {
        if (!(this.asYouTypeTerm.length >= this.minNoOfCharsToSearch)) {
            return;
        }

        const requestSeq = ++typeAheadSearchSeq;
        this.isSearching = true;
        this.showModal = true;
        try {
            const searchRequest: SearchAsYouTypeRequest = { term: this.asYouTypeTerm, isSuggestedTerm: false };
            const searchTerm = searchRequest.term;
            const searchResult = { ...await Api.search.searchSuggest(searchRequest) };
            const bannerResult = await this.aquireMarketingBanner(searchTerm, searchResult.suggestions);

            // only use newest, if crossing request/responses, and if user pressed enter, let page redirect and don't open suggest
            if (requestSeq === typeAheadSearchSeq) {
                // Handle if esc key has been used and input still has focus
                if (window.document.activeElement === this.$refs.input) {
                    this.setActive(true);
                }

                this.searchResult = searchResult;
                this.marketingBanner = bannerResult;
            }
        } finally {
            this.isSearching = false;

            this.$nextTick().then(() => {
                this.calcContainerScrollSize();
            });
        }
    }

    async aquireMarketingBanner(searchResult: string, suggestions: SuggestionViewObject[]): Promise<MarketingBannerViewObject> {
        // ask the backend if a banner exists for the search result
        const fitBannersResultPayload: SearchSuggestionsRequest = {
            suggestions: [searchResult, ...suggestions.map(s => s.term)]
        };
        const response = await Api.search.fitUmbracoMarketingBanner(fitBannersResultPayload);
        return response;
    }

    doSuggestionSearchHover(suggestion: string) {
        this.selectedSuggestion = suggestion;
        setTimeout(() => {
            this.doSuggestSearch();
        }, 0);
    }

    clearSelectedSuggestion() {
        this.selectedSuggestion = '';
        this.selectedSuggestionSearchResult = null;
        if (this.$refs.SuggestionsSlider) {
            this.$nextTick().then(() => {
                this.$refs.SuggestionsSlider.reloadFlickity();
            });
        }
    }

    onFocusOutOfInputWrapper(event: any, checkForShiftKey: any) {
        this.timeout = setTimeout(() => {
            if ((checkForShiftKey && keyboardService.isShiftTab(event)) || (!checkForShiftKey && keyboardService.isTab(event))) {
                if (this.showResults) {
                    // move focus to the first focusable element when focus has moved outside of the input wrapper and there are results
                    const focusElements = getInteractiveChildElements(this.$refs.overlay);
                    if (focusElements.length && focusElements.length > 0) {
                        focusElements[0].focus();
                    }
                } else {
                    // set to inactive if there aren't any results
                    this.setActive(false);
                }
            }
        });
    }

    onFocusOutOfOverlay() {
        this.timeout = setTimeout(() => this.moveFocusToInputIfOverlayContainsActiveElement());
    }

    moveFocusToInputIfOverlayContainsActiveElement() {
        if (this.$refs.overlay && !this.$refs.overlay.contains(document.activeElement)) {
            this.$refs.input.focus();
        }
    }

    public handleKeyUp(event: KeyboardEvent) {
        // Use keyup to allow term to change
        if (keyboardService.isEscape(event)) {
            this.escPressed();
        } else if (keyboardService.isEnter(event)) {
            this.enterPressed();
        }
    }

    public inputChanged() {
        this.clearSelectedSuggestion();
        if (this.asYouTypeTerm.length < this.minNoOfCharsToSearch) {
            this.disableSearchModal();
            return;
        }

        tracking.setLastTypedTerm(this.asYouTypeTerm);
        productTrackingService.setLastTypedTerm(this.asYouTypeTerm);
        this.debouncedSearchAsYouType();
    }

    // Throttle method for search as you type
    debouncedSearchAsYouType = debounce(this.doSearchAsYouType, 500);

    handleArrowRightAndLeft(delta: 1 | -1) {
        if ((this.focusedSuggestionIndex < this.suggestionsLength - 1 && delta === 1) || (this.focusedSuggestionIndex > 0 && delta === -1)) {
            this.focusedSuggestionIndex += delta;
            this.focusSuggestionAtIndex();
        }
    }

    handleArrowDownAndUp(delta: 1 | -1) {
        // Her flytter vi focus ned på sogeforslagene
        if ((this.focusedSuggestionIndex < 0 && this.suggestionsLength - 1 !== -1 && delta === 1) || (this.focusedSuggestionIndex < -1 && delta === 1)) {
            this.focusedSuggestionIndex += delta;
            this.focusSuggestionAtIndex();
            // Her flytter vi på produkt index op og ned
        } else if ((this.focusedProductIndex + 1 < this.productsLength && delta === 1) || (this.focusedProductIndex <= this.productsLength && this.focusedProductIndex > 0 && this.focusedCategoryIndex < 0 && delta === -1)) {
            this.focusedProductIndex += delta;
            this.focusProductAtIndex();
            // Her flytter vi focus fra produkter og op på soegeforslagene
        } else if (this.focusedProductIndex === 0 && this.suggestionsLength - 1 > 0 && delta === -1) {
            this.focusedProductIndex += delta;
            this.focusSuggestionAtIndex();
            // Her flytter vi focus ned på kategorierne
        } else if ((this.focusedProductIndex + 1 === this.productsLength && this.focusedCategoryIndex + 1 < this.categoriesLength && delta === 1) || (this.focusedCategoryIndex + 1 <= this.categoriesLength && this.focusedCategoryIndex > 0 && delta === -1)) {
            this.focusedCategoryIndex += delta;
            this.focusCategoryAtIndex();
            // Her flytter vi focus op på produkter fra kategorierne
        } else if (this.focusedCategoryIndex + 1 <= this.categoriesLength && this.focusedCategoryIndex >= 0 && delta === -1) {
            this.focusedCategoryIndex += delta;
            this.focusProductAtIndex();
        }
    }

    handleLockArrowKeyEvents(event: any) {
        this.disableKeyEvents = event;
    }

    handleArrowEvents(event: any) {
        const searchBarElement = this.$refs.input;
        if (this.$refs.overlay && !this.disableKeyEvents) {
            if (document.activeElement !== searchBarElement && (keyboardService.isRightArrow(event) || keyboardService.isLeftArrow(event))) {
                this.handleArrowRightAndLeft(keyboardService.isRightArrow(event) ? 1 : -1);
            } else if (keyboardService.isDownArrow(event) || keyboardService.isUpArrow(event)) {
                this.handleArrowDownAndUp(keyboardService.isDownArrow(event) ? 1 : -1);
            }
        }
    }

    focusSuggestionAtIndex() {
        (this.$refs.suggestions.children[this.focusedSuggestionIndex].children[0] as HTMLElement).focus();
    }

    focusProductAtIndex() {
        (this.$refs.products.children[this.focusedProductIndex].children[0] as HTMLElement).focus();
    }

    focusCategoryAtIndex() {
        (this.$refs.categories.children[this.focusedCategoryIndex] as HTMLElement).focus();
    }

    onClickLink() {
        this.setActive(false);
    }

    clickClear(): void {
        this.clearSearch();
        this.$refs.input.focus();
    }

    setActiveWithoutClear(state: boolean) {
        this.setActive(state, false);
    }

    setActive(state: boolean, clearSearch: boolean = true) {
        this.isActive = state;
        this.$emit('searchState', this.isActive);
        if (!state && clearSearch) {
            this.clearSearch();
        }
    }

    toggleButtonState(): void {
        if (!this.isActive) {
            this.setActive(true);
            this.$refs.input.focus();
        } else {
            this.setActive(false);
            this.$refs.input.blur();
        }
    }

    clickOutside() {
        if (!this.isActive) return;
        this.setActive(false);
    }

    clearSearch(): void {
        this.asYouTypeTerm = '';
        this.disableSearchModal();
        this.resetFocusIndex();
    }

    disableSearchModal(): void {
        this.setActiveWithoutClear(false);
        this.searchResult = null;
        this.showModal = false;
        this.clearSelectedSuggestion();
    }

    resetFocusIndex(): void {
        this.focusedSuggestionIndex = -1;
        this.focusedProductIndex = -1;
        this.focusedCategoryIndex = -1;
    }

    enterPressed() {
        this.toSearchedPage();
    }

    escPressed() {
        this.$refs.input.blur();
        this.setActive(false);
    }

    clickEnter($event: any) {
        if ($event.target.querySelector('a')) {
            $event.target.querySelector('a').click();
        }
    }

    productFocus(index: any) {
        this.focusedProductIndex = index;
        this.$nextTick(() => {
            scrollService.scrollIntoView(this.$refs.products.children[index].children[0] as HTMLElement);
        });
    }

    suggestionFocus(index: any) {
        this.focusedSuggestionIndex = index;
    }

    categoryFocus(index: any) {
        this.focusedCategoryIndex = index;
    }

    toSearchedPage() {
        // Enter pressed and we want to show search result, not search suggestions.
        this.$refs.input.blur();

        // Go to search page with term as query.
        const searchUrl = this.getSearchUrl();
        router.push(searchUrl);

        this.setActive(false);
    }

    getCategoryImage(category: CategoryScopeViewObject): string {
        return category.imageUrl
            ? category.imageUrl
            : Constants.ImageNotFound;
    }

    getCategorySearchUrl(category: CategoryScopeViewObject): string {
        let term = this.selectedSuggestion ? this.selectedSuggestion : this.asYouTypeTerm;
        return category
            ? `${this.searchpageUrl}?${termKey}=${encodeURIComponent(term)}&${categoryIdKey}=${category.categoryId}`
            : this.getSearchUrl();
    }

    getSearchUrl(): string {
        let term = this.selectedSuggestion ? this.selectedSuggestion : this.asYouTypeTerm;
        if (term) {
            return `${this.searchpageUrl}?${termKey}=` + encodeURIComponent(term);
        }

        return this.searchpageUrl;
    }

    onSuggestionClick(url: string, term: string, total: string): void {
        tracking.trackCategorySuggestionClick('', term, total);
        this.onClickLink();
        this.$router.push({ path: url });
    }

    categorySuggestionClick(category: string, url: string, term: string, total: string): void {
        tracking.trackCategorySuggestionClick(category, term, total);
        this.onClickLink();
        this.$router.push({ path: url });
    }

    promotedResultClick(searchResult: SearchAsYouTypeResponse | null): void {
        // This is tracked via mousedown event, because it could be internal or external, and the router link will not let us track correctly otherwise.
        if (searchResult) {
            tracking.trackPromotedLinkClick(searchResult.promotedResult.keyword, this.getResultTerm(), this.getTotalProducts());
            this.onClickLink();
        }
    }

    viewProductsHeaderMessage(): string {
        let results = this.selectedSuggestion && !this.selectedCategory
            ? this.selectedSuggestionSearchResult ? this.selectedSuggestionSearchResult.products.length.toString() : ''
            : this.searchResult ? this.searchResult.products.length.toString() : '';

        let totalResults = this.getTotalProducts();

        return this.$tr(this.$labels.Views.Header.Search.Suggest.ProductsHeader, results, totalResults);
    }

    viewAllProductsMessage(): string {
        let totalResults = this.getTotalProducts();
        return this.$tr(this.$labels.Views.Header.Search.Suggest.ViewAllProducts, totalResults);
    }

    getTotalProducts(): string {
        return this.selectedSuggestion && !this.selectedCategory
            ? this.selectedSuggestionSearchResult ? this.selectedSuggestionSearchResult.totalResults.toString() : ''
            : this.searchResult ? this.searchResult.totalResults.toString() : '';
    }

    scrollToCategories(): void {
        scrollService.scrollIntoView(this.$refs.categories);
    }

    getResultTerm(): string {
        return this.searchResult ? this.searchResult.term : '';
    }

    getSearchSuggestionTerm(): string {
        return this.selectedSuggestion ? this.selectedSuggestion : this.searchResult ? this.searchResult.term : '';
    }

    observe(): void {
        if (breakpointService.isActiveBreakpoint('xm,sm,md')) {
            if (this.observer && this.$refs.categories) {
                this.observer.observe(this.$refs.categories);
            }
        }
    }

    // This function to be passed to the tracking directive
    trackCurrentProduct(product: ProductTileViewObject) {
        // Passed a single product in a list to avoid breaking changes in tracking, ultimately this should be changed
        if (this.asYouTypeTerm) {
            productTrackingService.TrackProduct(
                PRODUCT_TRACKING_EVENT.ProductImpression,
                productTrackingService.ToTrackedProduct(
                    product,
                    PRODUCT_TRACKING_TYPE.ProductTileViewObject,
                    null,
                    new TrackedProduct({
                        searchTerm: this.asYouTypeTerm
                    })
                )
            );
        }
    }
}
