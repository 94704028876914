





































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    PriceViewObject,
    ProductTileSlimViewObject,
    QuickAddToBasketRequest
} from '@/types/serverContract';
import { AppGetter, AppAction } from '@/store/app';
import Api from '@/project/http/api';
import tracking from '@/core/tracking/tracking.service';
import raptor, { TrackProductClickRaptorViewModel } from '@/core/tracking/raptorTracking.service';
import ScalePricesCompact from '@/project/products/ScalePricesCompact.vue';
import ProductCo2InfoSimple from '@/project/products/ProductCo2InfoSimple.vue';
import EcoCertificationsBadges from '@/project/products/EcoCertificationsBadges.vue';
import ProductVariantIds from '@/project/products/ProductVariantIds.vue';
import CopyTooltip from '@/project/tooltip/copyTooltip.vue';
import ProductTileSlimPrice from './subcomponents/ProductTileSlimPrice.vue';
import AddToFavoriteOrder from '@/project/favoriteOrders/AddToFavoriteOrder.vue';
import { IUnitData } from '@/project/products/productHelper.utils';
import { BACKGROUND_COLORS } from '@/core/enums/productTiles';
import productTrackingService, { TrackedProduct } from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';
import breakpointService from '@/core/responsive/breakpoints/breakpoints.service';

@Component({
    components: {
        ProductVariantIds,
        ProductCo2InfoSimple,
        ScalePricesCompact,
        CopyTooltip,
        EcoCertificationsBadges,
        ProductTileSlimPrice,
        AddToFavoriteOrder
    }
})
export default class ProductTileSlim extends Vue {
    @Prop({ required: true, type: Object }) product!: ProductTileSlimViewObject;
    @Prop({ required: false, type: String }) raptorMethod!: string;
    @Prop({ default: false, type: Boolean }) showProductVariantIds!: boolean;
    @Prop({ required: false, type: Object }) priceFromParent!: PriceViewObject;
    @Prop({ default: '', type: String }) productType!: string;
    @Prop({ default: false, type: Boolean }) showDetailsByDefault!: boolean;
    @Prop({ default: false, type: Boolean }) useHoverBoxShadow!: boolean;
    @Prop({ default: BACKGROUND_COLORS.WHITE, type: String }) tileColor!: string;

    @AppGetter isLoggedIn!: boolean;

    @AppAction toggleLoginOverlay!: (forceClose) => void;

    price: PriceViewObject | null = null;
    PropertiesToShow: number = 6;
    pricesPending: boolean = false;
    addToBasketPending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;
    quantity: number = this.product.defaultQuantity;
    productCardIsHovered: boolean = !!this.showDetailsByDefault;

    // todo: Improve HTML structure to avoid multiple anchor tags(RouterLinkConditional and ButtonCtrl), at that time this shouldn't be necessary
    get showLoginBarOnButtonClickMobile() {
        return !this.isLoggedIn && breakpointService.isActiveBreakpoint('xs, sm');
    }

    get productWithSpecificTrackingContext(): ProductTileSlimViewObject {
        const product: ProductTileSlimViewObject = this.productType
            ? {
                ...this.product,
                variant: {
                    ...this.product.variant,
                    trackingContext: {
                        ...this.product.variant.trackingContext,
                        list: this.product.variant.trackingContext.list.replace(
                            /\/[^/]*\/?$/,
                            `/${this.productType}`
                        )
                    }
                }
            }
            : this.product;

        return product;
    }

    get trackProductClickRaptorInfo(): TrackProductClickRaptorViewModel | null {
        const trackingInfo: TrackProductClickRaptorViewModel = {
            methodName: this.raptorMethod,
            eventType: 'itemClick',
            productId: this.product.id,
            productName: this.product.name
        };

        return this.raptorMethod ? trackingInfo : null;
    }

    get showCertificateBadges(): boolean {
        return !!(
            this.product.variant &&
            (this.product.variant.co2Footprint || this.product.variant.climateCertificates.length)
        );
    }

    get showVariants(): string {
        return this.$tr(this.$labels.Views.ProductDetails.TileSlim.ShowVariants).replace(
            '{0}',
            this.product.numberOfVariants.toString()
        );
    }

    get favoriteOrderableUnits(): IUnitData[] {
        const favoriteOrderableUnits: IUnitData[] = [];
        this.product.variant.orderableUnits.forEach(unit => {
            let unitData: IUnitData = {
                unitPayload: {
                    unit: unit.unitKey,
                    quantity: unit.increment,
                    fixedUnitValue: unit.isFixedUnit && unit.fixedUnitQuantity ? 0 : null
                },
                orderableUnit: unit
            };

            favoriteOrderableUnits.push(unitData);
        });

        return favoriteOrderableUnits;
    }

    get tileClasses() {
        return [
            this.tileColor,
            this.tileHasDarkBackground ? 'text-white' : 'text-blue-700',
            {
                'product-tile-slim--show-details': this.showDetailsByDefault,
                'shadow-none hover:shadow-card u-transition-box-shadow u-transition-dur-250 u-transition-cb-ease-out-quart':
                    this.useHoverBoxShadow
            }
        ];
    }

    get tileHasWhiteBackground() {
        return !!(this.tileColor === BACKGROUND_COLORS.WHITE);
    }

    get tileHasDarkBackground() {
        return !!(
            this.tileColor === BACKGROUND_COLORS.BLUE_580 ||
            this.tileColor === BACKGROUND_COLORS.BLUE_690
        );
    }

    get priceSectionColor() {
        if (this.tileHasWhiteBackground) {
            return 'text-cadet-grey';
        } else if (this.tileHasDarkBackground) {
            return 'text-white';
        }

        return 'text-blue-700';
    }

    get productProperties() {
        if (
            this.product.variant.primaryProperties &&
            this.product.variant.primaryProperties.length
        ) {
            return this.product.variant.primaryProperties.map(property => {
                return {
                    ...property,
                    displayName: property.name,
                    displayOption: property.displayValue ? `${property.displayValue}${property.unit ? ' ' + property.unit : ''}` : ''
                };
            });
        }

        if (!this.product.variant.variantFeatures || !this.product.variant.variantFeatures.length) {
            return;
        }

        return this.product.variant.variantFeatures;
    }

    created() {
        if (!this.isLoggedIn || !this.product || !this.priceFromParent) {
            return;
        }
        this.price = this.priceFromParent;
    }

    preventAndToggleOverlay($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.toggleLoginOverlay(false);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async addToBasket() {
        this.addToBasketPending = true;

        const quickAddToBasketRequest: QuickAddToBasketRequest = {
            productId: this.product.variant.ids.variantId,
            quantity: this.quantity
        };

        try {
            if (this.trackProductClickRaptorInfo) {
                // We need to register an itemClick to Raptor before adding to basket, to attribute the action to raptor.
                // And since the route does not change, we cannot use the regular trackProductClick method
                raptor.trackProductClick(this.trackProductClickRaptorInfo);
            }
            await Api.basket.quickAddToBasket(quickAddToBasketRequest, '');
            this.showSuccessIndicator(quickAddToBasketRequest);
        } finally {
            this.addToBasketPending = false;
        }
    }

    showSuccessIndicator(request: QuickAddToBasketRequest) {
        this.addToBasketPending = false;
        this.success = true;
        this.trackAddToBasket(request);

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    updateQuantity(e) {
        this.quantity = e;
    }

    applyHoverEffect() {
        if (this.showDetailsByDefault) {
            return;
        }

        this.productCardIsHovered = true;
    }

    removeHoverEffect() {
        if (this.showDetailsByDefault) {
            return;
        }

        this.productCardIsHovered = false;
    }

    trackProductClick(): void {
        if (this.trackProductClickRaptorInfo) {
            raptor.trackProductClick(this.trackProductClickRaptorInfo);
        }
        if (this.productWithSpecificTrackingContext) {
            productTrackingService.TrackProduct(
                PRODUCT_TRACKING_EVENT.ProductClick,
                productTrackingService.ToTrackedProduct(
                    this.productWithSpecificTrackingContext,
                    PRODUCT_TRACKING_TYPE.ProductTileSlimViewObject,
                    this.price
                )
            );
        }
    }

    private trackAddToBasket(request: QuickAddToBasketRequest): void {
        productTrackingService.TrackProduct(
            PRODUCT_TRACKING_EVENT.AddToCart,
            productTrackingService.ToTrackedProduct(
                this.productWithSpecificTrackingContext,
                PRODUCT_TRACKING_TYPE.ProductTileSlimViewObject,
                this.price, // the price object
                new TrackedProduct({
                    quantity: request.quantity
                })
            )
        );
    }

    // This function to be passed to the tracking directive
    public trackCurrentProduct() {
        // Passed a single product in a list to avoid breaking changes in tracking, ultimately this should be changed
        return tracking.getAndTrackImpressionsFromSlimTileProducts([
            this.productWithSpecificTrackingContext
        ]);
    }
}
