import httpService from '@/core/http/http.service';
import {
    AgreementViewModel,
    MarkAgreementAsAcceptedRequest,
    MarkAgreementAsPresentedRequest
} from '@/types/serverContract';

export default class AgreementController {
    public async nextNonAcceptedAgreement(): Promise<AgreementViewModel> {
        return httpService.get<AgreementViewModel>('agreementApi/nextNonAcceptedAgreement');
    }

    public markAgreementAsPresented(payload: MarkAgreementAsPresentedRequest): Promise<void> {
        return httpService.post<void>('agreementApi/markAgreementAsPresented', payload);
    }

    public markAgreementAsAccepted(payload: MarkAgreementAsAcceptedRequest): Promise<void> {
        return httpService.post<void>('agreementApi/markAgreementAsAccepted', payload);
    }
}
