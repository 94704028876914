























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import urlHelper from './urlHelperFavoriteOrders.service';

@Component
export default class FavoriteOrderSearch extends Vue {
    @Prop({ type: Boolean, default: false }) disabled!: boolean;
    searchTerm: string = '';

    @Watch('$route', {
        immediate: true
    })
    onRouteChange() {
        this.searchTerm = urlHelper.getSearchTerm() !== null ? urlHelper.getSearchTerm()! : '';
    }

    setSearchTerm() {
        urlHelper.setSearchTerm(this.searchTerm);
    }
}
