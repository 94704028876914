










































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import urlHelper from './urlHelper.service';
import { FacetGroupViewObject } from '@/types/serverContract';
import SearchFacetGroup from '@/project/search/SearchFacetGroup.vue';
import scrollService from '@/core/scroll/scroll.service';

@Component({
    components: {
        SearchFacetGroup
    }
})
export default class SearchFacets extends Vue {
    @Prop({
        required: true
    }) facetGroups!: FacetGroupViewObject[];

    @Prop({
        default: 0,
        type: Number
    }) totalResultCount!: number;

    annotatedFacetGroups: FacetGroupViewObject[] = [];
    noOfFacetsSelected = 0;

    @Watch('facetGroups', {
        immediate: true
    })
    onFacetGroupsChange(value: FacetGroupViewObject[]) {
        this.annotatedFacetGroups = value;
        scrollService.scrollToTop();
        this.calcNoOfFacetsSelected();
        this.$emit('facets-change');
    }

    resetFilters() {
        this.annotatedFacetGroups.forEach(grp => {
            (this.$refs[`facetGroup-${grp.key}`] as any)[0].reset();
        });
        urlHelper.resetFacets();
    }

    calcNoOfFacetsSelected(): void {
        this.noOfFacetsSelected = urlHelper.noOfFacetsSelected();
    }
}
