








































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    AddQuotationToBasketRequest,
    QuotationDetailsLineItemViewObject,
    QuotationDetailsViewModel,
    QuotationItem,
    BasketViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { BasketGetter } from '@/store/basket';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import MyServicesQuotationsOrderLine from '@/project/myServices/MyServicesQuotationsOrderLine.vue';
import MyServicesQuotationOrderLineWithOptions from '@/project/myServices/MyServicesQuotationOrderLineWithOptions.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

interface EmittedSelectedLine {
    indexId: number;
    selectedLine: QuotationDetailsLineItemViewObject;
}

@Component({
    components: {
        SpinnerOverlay,
        ButtonSubmit,
        MyServicesQuotationsOrderLine,
        MyServicesQuotationOrderLineWithOptions
    }
})
export default class MyServicesQuotationsDetails extends Vue {
    @BasketGetter basket!: BasketViewModel;
    quotationDetail: QuotationDetailsViewModel | null = null;
    pending: boolean = false;
    addToBasketPending: boolean = false;
    success: boolean = false;
    orderLinesWithOptions: QuotationDetailsLineItemViewObject[] = [];
    tempOrderLinesWithOptions: EmittedSelectedLine[] = [];
    addedToBasketFromParent: boolean = false;

    get disableAddAllToBasket(): boolean {
        return (
            this.addedToBasketFromParent ||
            this.quotationDetail === null ||
            this.quotationDetail.orderLinesWithOptions.length !==
                this.orderLinesWithOptions.length ||
            !this.quotationDetail.allowOrdering
        );
    }

    created() {
        this.getQuotationDetail();
    }

    async getQuotationDetail() {
        const orderId = this.$route.query.id as string;
        this.pending = true;

        try {
            this.quotationDetail = await Api.quotations.getQuotation(orderId);
        } finally {
            this.pending = false;
        }
    }

    async addAllLinesToBasket() {
        if (!this.quotationDetail) {
            return;
        }

        this.addToBasketPending = true;

        const combinedQuotationItems = [
            ...this.quotationDetail.orderLines,
            ...this.orderLinesWithOptions
        ];

        let quotationItems: QuotationItem[] = combinedQuotationItems.map(orderLine => ({
            lineId: orderLine.lineId,
            quantity: this.calculateQuantityToAdd(orderLine)
        }));

        let addQuotationToBasketPayload: AddQuotationToBasketRequest = {
            quotationId: this.quotationDetail.orderId,
            lines: quotationItems.filter(orderLine => {
                return orderLine.quantity >= 1;
            })
        };

        try {
            await Api.basket.addQuotationToBasket(addQuotationToBasketPayload);
            this.addedToBasketFromParent = true;
        } finally {
            this.addToBasketPending = false;
        }
    }

    calculateQuantityToAdd(orderLine: QuotationDetailsLineItemViewObject): number {
        let basketlineItem = this.basket.lineItems.find(
            lineItem =>
                lineItem.referenceDocument !== undefined &&
                lineItem.referenceDocument!.documentItem === orderLine.lineId &&
                this.quotationDetail !== null &&
                lineItem.referenceDocument!.documentId === this.quotationDetail.orderId
        );

        if (!basketlineItem) {
            return orderLine.maxQuantity;
        }
        return orderLine.maxQuantity - basketlineItem.quantity;
    }

    updateOrderLinesWithOptions(emittedSelectedLine: EmittedSelectedLine): void {
        let hasMatch = this.tempOrderLinesWithOptions.some(
            orderLineWithOptions => emittedSelectedLine.indexId === orderLineWithOptions.indexId
        );
        let itemToRemoveIndex = this.tempOrderLinesWithOptions.findIndex(
            item =>
                item.indexId === emittedSelectedLine.indexId &&
                item.selectedLine.lineId !== emittedSelectedLine.selectedLine.lineId
        );
        this.tempOrderLinesWithOptions.push(emittedSelectedLine);

        if (hasMatch) {
            this.tempOrderLinesWithOptions.splice(itemToRemoveIndex, 1);
        }

        this.orderLinesWithOptions = this.tempOrderLinesWithOptions.map(orderLine => {
            return orderLine.selectedLine;
        });
    }
}
