


























import Component from 'vue-class-component';
import { Prop, Mixins } from 'vue-property-decorator';
import { CtrlMixin } from '@/project/form/CtrlMixin.mixin';

@Component
export default class DropdownCtrl extends Mixins<CtrlMixin>(CtrlMixin) {
    @Prop({ default: false }) hover!: boolean;
    @Prop({ default: true }) arrow!: boolean;
    @Prop({ type: String, default: '' }) moniker!: string;

    mouseLeft: boolean = false;
    onClick() {
        this.Ctrl.isActive = !this.Ctrl.isActive;
    }

    onHover() {
        this.Ctrl.isActive = true;
        this.mouseLeft = false;
    }

    async onLeave() {
        this.mouseLeft = true;
        setTimeout(() => {
            if (this.mouseLeft) {
                this.Ctrl.isActive = false;
                this.mouseLeft = false;
            }
        }, 200);
    }
}
