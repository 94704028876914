






































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { MarketingBannerViewObject } from '@/types/serverContract';
import { IPromoActionField } from '@/core/tracking/promotionTracking.g4-structure';
import { PROMO_TRACKING_EVENT } from '@/core/enums/enums';
import promotionTrackingService from '@/core/tracking/promotionTracking.service';

@Component
export default class MarketingBanner extends Vue {
    @Prop({ type: Object, required: true }) banner!: MarketingBannerViewObject;

    get getClasses() {
        return `max-w-full${this.banner.coverAndCenter ? ' object-cover' : ''}`;
    }

    get isExternalLink() {
        return (this.banner.linkTarget === '_blank' || this.banner.link.startsWith('http'));
    }

    get bannerLink() {
        if (!this.isExternalLink) {
            return this.banner.link;
        }

        // If the link is external, but it doesn't start with http, we tell the browser that it's external by prefixing the url with "//"
        return `//${this.banner.link}`;
    }

    trackPromotionClick(): void {
        const id = `${this.banner.keyword}|typeAhead|${this.banner.image}`;
        const name = `${this.banner.link}`;
        const creative = this.banner.linkText;

        const actionField: IPromoActionField = {
        // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_id: id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_name: name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            creative_name: creative
        };

        promotionTrackingService.TrackPromotion(
            PROMO_TRACKING_EVENT.PromotionClick,
            actionField
        );
    }

    trackPromotionView() {
        const id = `${this.banner.keyword}|typeAhead|${this.banner.image}`;
        const name = `${this.banner.link}`;
        const creative = this.banner.linkText;

        const actionField: IPromoActionField = {
        // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_id: id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_name: name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            creative_name: creative
        };

        promotionTrackingService.TrackPromotion(
            PROMO_TRACKING_EVENT.PromotionImpression,
            actionField
        );
    }
}
