



















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AddEasySupplySmartButtonRequest } from '@/types/serverContract';
import Api from '@/project/http/api';
import Constants from '@/project/config/constants';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import serverContext from '@/core/serverContext.service';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class AddSmartButton extends Vue {
    @Prop({
        type: Number
    }) warehouseLineId!: number;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number = 0;
    smartButtonId: string = '';
    reorderThreshold: number | null = 0;

    get macAddressMask(): object {
        return Constants.MacAddressMask;
    }

    get smartButtonIdLengthMessage() {
        return this.$tr(this.$labels.Views.Services.EasySupply.Edit.Smartbutton.IdRequiredLength);
    }

    get qrCodeImageUrl(): string {
        return this.warehouseLineId ? `/api/qrGenerationApi/easySupplyWarehouseLineId?warehouseLineId=${this.warehouseLineId}&siteName=${serverContext.Localization.siteName}` : '';
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async addSmartButton() {
        if (this.pending || this.reorderThreshold === null) return;

        this.pending = true;

        const payload: AddEasySupplySmartButtonRequest = {
            easySupplyWarehouseLineId: this.warehouseLineId,
            hardwareIdentifier: this.smartButtonId,
            reorderThreshold: this.reorderThreshold
        };

        try {
            await Api.easySupply.addEasySupplySmartButton(payload);
            this.showSuccessIndicator();
            this.$emit('updatedWarehouse');
            this.closeModal();
        } finally {
            this.pending = false;
        }
    }

    validateSmartButtonIdLength(): boolean {
        return this.smartButtonId.length === 17;
    }

    closeModal() {
        this.$emit('closeModal');
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors!: any;
}
