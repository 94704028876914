





































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { downloadApi } from '@/project/config/utilities';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import EasySupplyImportFromFile from '@/project/easySupply/EasySupplyImportFromFile.vue';
import EasySupplyGatewayOrderList from '@/project/easySupply/gateway/EasySupplyGatewayOrderList.vue';
import {
    BaseReportRequest,
    EasySupplyWarehouseDetailViewModel,
    EasySupplyWarehouseReportAsEmailRequest,
    ProductListReportAsEmailRequest
} from '@/types/serverContract';
import DownloadCatalogForm from '@/project/shared/DownloadCatalogForm.vue';
import Api from '@/project/http/api';
import EasySupplyPrintLabels from '@/project/easySupply/printLabels/EasySupplyPrintLabels.vue';
import { IEasySupplyPrintLabelsStepProgress } from '@/project/easySupply/printLabels/EasySupplyPrintLabels.service';

@Component({
    components: {
        EasySupplyPrintLabels,
        EasySupplyImportFromFile,
        ModalOverlay,
        DownloadCatalogForm,
        EasySupplyGatewayOrderList
    }
})
export default class EasySupplyStockListEditActionLinks extends Vue {
    @Prop({
        required: true,
        type: Number
    }) warehouseId!: number;

    @Prop({
        required: true,
        type: Object
    }) warehouseDetails!: EasySupplyWarehouseDetailViewModel;

    @Prop({
        default: false,
        type: Boolean
    }) showOrderList!: boolean;

    showImportModal: boolean = false;
    showDownloadCatalogModal: boolean = false;
    showPrintLabelsModal: boolean = false;
    showOrderListModal: boolean = false;
    successTimer: number = 4000;
    pending: boolean = false;
    success: boolean = false;
    successEmail: string | null = null;
    modalSize: string = 'large';
    timeout: number | null = null;
    printLabelsStepsProgress: IEasySupplyPrintLabelsStepProgress = {
        currentStepNumber: '',
        totalSteps: ''
    };

    get warehouseLineIds(): number[] {
        return this.warehouseDetails.lines.map(line => {
            return line.id;
        });
    }

    get printLabelsStepLabel(): string {
        return `
        <p class="flex h-full items-center text-15">
            - ${this.$tr(this.$labels.Views.Services.EasySupply.Edit.PrintLabels.Steps, this.printLabelsStepsProgress.currentStepNumber, this.printLabelsStepsProgress.totalSteps)}
        </p>`;
    };

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async downloadCatalog(baseReportRequest: BaseReportRequest, emailAddressForReport: string) {
        const downloadCatalogRequest: EasySupplyWarehouseReportAsEmailRequest = {
            emailAddress: emailAddressForReport,
            easySupplyWarehouseId: this.warehouseId,
            reportType: baseReportRequest.reportType,
            priceType: baseReportRequest.priceType,
            getAll: true,
            easySupplyWarehouseLineIds: this.warehouseLineIds,
            productNumberType: baseReportRequest.productNumberType
        };

        try {
            const reportAsEmailModel = await Api.reports.requestEasySupplyWarehouseReportAsEmail(downloadCatalogRequest);
            this.successEmail = reportAsEmailModel.email;
            this.showSuccessIndicator();
        } finally {
        }
    }

    async sendLabels(printLabelsPayload: ProductListReportAsEmailRequest) {
        this.pending = true;
        try {
            const reportAsEmailModel = await Api.reports.requestProductListReportAsEmail(printLabelsPayload);
            this.successEmail = reportAsEmailModel.email;
            this.showSuccessIndicator();
        } finally {
            this.pending = false;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.closeDownloadCatalogModal();
            this.closePrintLabelsModal();
            this.success = false;
            this.successEmail = null;
        }, this.successTimer);
    }

    downloadAsCsv() {
        downloadApi('api/EasySupplyApi/DownloadAsCsv', { easySupplyWarehouseId: this.warehouseId });
    }

    closeImportModal(): void {
        this.showImportModal = false;
    }

    closeDownloadCatalogModal() {
        this.showDownloadCatalogModal = false;
    }

    closePrintLabelsModal(): void {
        this.showPrintLabelsModal = false;
    }

    closeOrderListModal(): void {
        this.changeModalSize('large');
        this.showOrderListModal = false;
    }

    changeModalSize(size: string): void {
        this.modalSize = size;
    }

    printLabelsStepChange(printLabelsStepsProgress: IEasySupplyPrintLabelsStepProgress): void {
        this.printLabelsStepsProgress = printLabelsStepsProgress;
    }
}
