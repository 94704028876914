import httpService from '@/core/http/http.service';
import {
    ServiceGroupContentListViewModel,
    ServiceGroupContentViewModel
} from '@/types/serverContract';

export default class ServicesController {
    public async servicesFunctionalFrontPage(): Promise<ServiceGroupContentViewModel> {
        return httpService.get<ServiceGroupContentViewModel>('servicesApi/functionalFrontPage');
    }

    public async contentFrontPage(): Promise<ServiceGroupContentListViewModel> {
        return httpService.get<ServiceGroupContentListViewModel>('servicesApi/contentFrontPage');
    }
}
