













































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    AddQuotationToBasketRequest,
    LineItemViewObject,
    QuotationDetailsLineItemViewObject,
    BasketViewModel
} from '@/types/serverContract';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import Api from '@/project/http/api';
import { BasketGetter } from '@/store/basket';
import { AppGetter } from '@/store/app';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class MyServicesQuotationsOrderLine extends Vue {
    @BasketGetter basket!: BasketViewModel;
    @AppGetter hideNetPrices!: boolean;

    @Prop({
        required: true,
        type: String
    })
    quotationId!: string;

    @Prop({
        required: true,
        type: Object
    })
    orderLine!: QuotationDetailsLineItemViewObject;

    @Prop({
        required: true,
        type: Boolean
    })
    addToAllPending!: boolean;

    @Prop({
        required: true,
        type: Boolean
    })
    addedToBasketFromParent!: boolean;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    showErrorMsg: boolean = false;
    isAlreadyInBasket: boolean = false;
    timeout: number | null = null;
    addToBasketQuantity: number = this.orderLine.quantity;

    get getDisableAddToBasket(): boolean {
        return (
            this.showErrorMsg ||
            this.addToAllPending ||
            this.addedToBasketFromParent ||
            !this.orderLine.allowOrdering ||
            this.addToBasketQuantity === 0
        );
    }

    get findOrderLineInBasket(): LineItemViewObject | undefined {
        return this.basket.lineItems.find(lineItem =>
            lineItem.referenceDocument
                ? lineItem.referenceDocument.documentItem === this.orderLine.lineId &&
                  lineItem.referenceDocument!.documentId === this.quotationId
                : false
        );
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async addOrderLineToBasket() {
        if (!this.isAddToBasketQuantityValid()) {
            return false;
        }

        this.pending = true;

        const payload: AddQuotationToBasketRequest = {
            quotationId: this.quotationId,
            lines: [
                {
                    lineId: this.orderLine.lineId,
                    quantity: this.addToBasketQuantity
                }
            ]
        };

        try {
            await Api.basket.addQuotationToBasket(payload);
            this.showSuccessIndicator();
        } finally {
            this.pending = false;
        }
    }

    setAddToBasketQuantityToMax() {
        let orderLineInBasket = this.findOrderLineInBasket;
        // first subtract maxQuantity, with already added to basket quantity so the addQuantityToBasket never exceeds the max quantity the user can get.
        if (orderLineInBasket && orderLineInBasket.quantity > 0) {
            this.addToBasketQuantity = this.orderLine.maxQuantity - orderLineInBasket.quantity;
        } else {
            this.addToBasketQuantity = this.orderLine.quantity;
        }
    }

    alreadyInBasket(): void {
        let orderLineInBasket = this.findOrderLineInBasket;

        if (orderLineInBasket && orderLineInBasket.quantity === this.orderLine.maxQuantity) {
            this.isAlreadyInBasket = true;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;
        // this.alreadyInBasket();
        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    handleAddToBasketQuantityChange() {
        if (this.addToBasketQuantity < 0) {
            this.addToBasketQuantity = 0;
        }
        // clear messages and events.
        this.showErrorMsg = false;
        // validate amount

        // emit events

        // Set error messages
    }

    isAddToBasketQuantityValid() {
        // clear all emits and shows
        this.showErrorMsg = false;
        let isValid = true;
        if (this.addToBasketQuantity > this.orderLine.maxQuantity) {
            isValid = false;
            this.showErrorMsg = true;
        }

        let orderLineInBasket = this.findOrderLineInBasket;

        if (
            this.addToBasketQuantity !== 0 &&
            orderLineInBasket &&
            orderLineInBasket.quantity === this.orderLine.maxQuantity
        ) {
            isValid = false;
        }

        if (
            orderLineInBasket &&
            orderLineInBasket.quantity + this.addToBasketQuantity > this.orderLine.maxQuantity
        ) {
            isValid = false;
            this.showErrorMsg = true;
        }

        if (this.orderLine.maxQuantity <= 0) {
            isValid = false;
        }
        return isValid;
    }

    validateQuantity() {
        this.showErrorMsg = this.orderLine.quantity > this.orderLine.maxQuantity;
    }
}
