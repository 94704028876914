

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import imageService from '@/core/image.service';
import constants from '../config/constants';
import { IMAGE_LOADING } from '@/core/enums/enums';

@Component
export default class ImageWithFallback extends Vue {
    @Prop({ required: true, type: String }) source!: string;
    @Prop({ default: '', type: String }) webpSource!: string;
    @Prop({ default: '', type: String }) sourceSet!: string;
    @Prop({ default: '', type: String }) classes!: string;
    @Prop({ default: '', type: String }) alt!: string;
    @Prop({ default: '', type: String }) title!: string;
    @Prop({ type: String as () => keyof typeof IMAGE_LOADING, default: IMAGE_LOADING.Eager, validator: (value: string) => Object.values(IMAGE_LOADING).includes(value as IMAGE_LOADING) }) loading!: IMAGE_LOADING;
    @Prop({ default: true, type: Boolean }) showErrorImage!: boolean;
    @Prop({
        default: 'auto',
        type: String,
        validator: (value: string) => ['low', 'high', 'auto'].indexOf(value) > -1
    }) fetchPriority!: string;

    hasError: boolean = false;

    get getWebpOptimizedSource(): string {
        let image = imageService.getWebpOptimizedSource(this.source, this.webpSource);
        if (image === constants.ImageNotFound && !this.showErrorImage) {
            return '';
        }
        return image;
    }

    get getOptimizedSource(): string {
        let image = imageService.getOptimizedSource(this.source);
        if (image === constants.ImageNotFound && !this.showErrorImage) {
            return '';
        }
        return image;
    }

    onError(): void {
        this.hasError = true;
        this.$emit('error');
    }
}
