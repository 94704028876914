import httpService from '@/core/http/http.service';
import {
    StartFlexBoxModeRequest
} from '@/types/serverContract';

export default class FlexBoxController {
    public async startFlexBoxMode(payload: StartFlexBoxModeRequest): Promise<void> {
        return httpService.post('flexBoxApi/startFlexBoxMode', payload);
    }

    public async stopFlexBoxMode(): Promise<void> {
        return httpService.post<void>('flexBoxApi/stopFlexBoxMode');
    }
}
