import Vue from 'vue';

const state = Vue.observable({ active: false });

export default function co2ModalState() {
    const show = () => { state.active = true; };
    const hide = () => { state.active = false; };

    return {
        state,
        show,
        hide
    };
}
