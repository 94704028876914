






















































































import { AppGetter } from '@/store/app';
import { FlexBoxGetter } from '@/store/flexBox';
import {
    LoginInformation,
    PriceViewObject,
    ProductTileSlimViewObject
} from '@/types/serverContract';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ProductTileSlimSkeletonPrice from '@/project/shared/skeleton/ProductTile/ProductTileSlimSkeletonPrice.vue';
import ProductInventory from '@/project/products/ProductInventory.vue';
import RouterLinkConditional from '@/core/routerLinkConditional.vue';
import ProductPrices from '@/project/products/ProductPrices.vue';
import ProductPriceNotAvailable from '@/project/products/ProductPriceNotAvailable.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import QuantitySelector from '@/components/molecules/QuantitySelector/QuantitySelector.vue';
import EcoCertificationsBadges from '@/project/products/EcoCertificationsBadges.vue';

@Component({
    components: {
        ProductInventory,
        ProductTileSlimSkeletonPrice,
        ProductPrices,
        ProductPriceNotAvailable,
        ButtonSubmit,
        QuantitySelector,
        EcoCertificationsBadges,
        RouterLinkConditional
    }
})
export default class ProductTileSlimPrice extends Vue {
    @Prop({ required: true, type: Object }) product!: ProductTileSlimViewObject;
    @Prop({ type: Object }) price!: PriceViewObject;
    @Prop({ default: false, type: Boolean }) pricesPending!: boolean;
    @Prop({ default: false, type: Boolean }) addToBasketPending!: boolean;
    @Prop({ default: false, type: Boolean }) success!: boolean;
    @Prop({ default: false, type: Boolean }) showHoverState!: boolean;
    @Prop({ default: 'text-grey-700', type: String }) textColor!: string;

    @AppGetter isLoggedIn!: boolean;
    @AppGetter loginInformation!: LoginInformation;

    @FlexBoxGetter isInFlexBoxContext!: boolean;

    get isOrderable(): boolean {
        if (this.price === null) {
            return false;
        }
        return this.price.stockStatus.isOrderable;
    }

    get submitButtonVariant(): string {
        if (this.textColor === 'text-white') {
            return 'secondary';
        }

        return 'primary';
    }

    updateQuantity(e) {
        this.$emit('updateQuantity', e);
    }
}
