



















































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    ScannerDataListViewModel,
    ScannerImportListViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';

@Component
export default class ScannerData extends Vue {
    pending: boolean = false;
    scannerData: ScannerDataListViewModel | null = null;
    timerReturnToImportPage: number = 3000;
    timeout: number | null = null;

    created(): void {
        this.getScannerData();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async getScannerData(): Promise <void> {
        this.pending = true;
        try {
            await Api.scanner.processScannerData().then(scannerData => {
                this.scannerData = scannerData;
            });
        } finally {
            this.pending = false;
        }

        if (this.scannerData !== null && !this.scannerData.listProcessed) {
            this.timeout = setTimeout(() => this.$router.push(this.scannerData!.scannerImportUrl), this.timerReturnToImportPage);
        }
    }

    anyListInSectionHasLines(lists: ScannerImportListViewObject[]): boolean {
        return lists.some(list => list.lines.length > 0);
    }
}
