






















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({

})
export default class CopyTooltip extends Vue {
    showTooltip: boolean = false;
    showCopiedMessage: boolean = false;
    hoverTooltip: boolean = true;

    @Prop({ type: String, default: '' }) numberToCopy!: string;
    @Prop({ type: String, default: '' }) initiator!: string;
    @Prop({ type: Boolean, default: false }) disableCopy!: boolean;

    onHoverTooltip() {
        this.hoverTooltip = false;
        setTimeout(() => {
            this.showTooltip = false;
            this.hoverTooltip = true;
        }, 300);
    }

    onCopyTextLeave() {
        this.hoverTooltip = true;

        if (!this.showCopiedMessage) {
            this.showTooltip = false;
        } else {
            this.showTooltip = true;
        }
    }

    OnHoverRemoveTooltip() {
        this.hoverTooltip = false;
    }

    async copyToClipboard(event: Event) {
        event.preventDefault();
        try {
            await (navigator as NavigatorExtended).clipboard.writeText(this.numberToCopy);
            this.showTooltip = true;
            this.showCopiedMessage = true;
            setTimeout(() => {
                this.showTooltip = false;
                this.showCopiedMessage = false;
            }, 1500);
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
    }
}
