import includes from 'lodash-es/includes';
import isArray from 'lodash-es/isArray';
import isString from 'lodash-es/isString';
import trim from 'lodash-es/trim';
import map from 'lodash-es/map';
import find from 'lodash-es/find';
import viewportEvents from '@/core/responsive/viewport/viewportEvents.service';
import throttle from 'lodash-es/throttle';

interface BreakpointDef {
    max: number;
    min: number;
    name: string;
};
type BreakpointsDef = BreakpointDef[];

let breakpoints: BreakpointsDef;

function getBreakpoints(): BreakpointsDef {
    return breakpoints;
}

function getBreakpoint(name: string): BreakpointDef {
    const bp = find(breakpoints, { name });
    if (!bp) {
        throw new Error(`${name} is not a known breakpoint`);
    }
    return bp;
}

export function defineBreakpoints(bps: { [bp: string]: { max: number; min: number } }): void {
    breakpoints = map(bps, (range, bp) => {
        return { name: bp, min: range.min, max: range.max };
    }).sort((a, b) => a.min - b.min);
}

function normalizeBreakpoints(bps: string): string | string[] {
    const normalizedBps = isString(bps) ? bps.split(',') : isArray(bps) ? bps : [];
    return map(normalizedBps, trim);
}

function getActiveBreakpoint(): string {
    const width = Math.max(window.innerWidth, window.document.documentElement!.clientWidth);

    const activeBreakpoint = breakpoints.find(bp => {
        return bp.min <= width && bp.max >= width;
    })!.name;

    return activeBreakpoint;
}

function isActiveBreakpoint(bps: string): boolean {
    const arrayOfBreakpointNames = normalizeBreakpoints(bps);
    const activeBreakpoint = getActiveBreakpoint();
    return includes(arrayOfBreakpointNames, activeBreakpoint);
}

let listeners: Array<() => void> = [];
let continuousResizeListeners: Array<() => void> = [];

// Only runs when the breakpoint changes
function addListener(listener: () => void): void {
    listeners.push(listener);
    listener();
}

// Runs on every resize event, for height-dependent changes, if we only need to run listener when breakpoint changes, use addListener
function addContinuousResizeListener(listener: () => void): void {
    continuousResizeListeners.push(listener);
    listener();
}

function removeListener(listener: Function): void {
    listeners = listeners.filter(l => l !== listener);
}

export function headerHeight(forScroll: boolean = false): number {
    if (getActiveBreakpoint() === 'lg' || getActiveBreakpoint() === 'xl') {
        const pageIsScrolled = document.getElementById('app')!.classList.contains('global-scrolled');
        return pageIsScrolled || forScroll ? 118 : 218;
    } else if (getActiveBreakpoint() === 'lg' || getActiveBreakpoint() === 'xl') {
        return 72;
    }
    return 128;
}

export default {
    isActiveBreakpoint,
    getActiveBreakpoint,
    getBreakpoints,
    getBreakpoint,
    addListener,
    addContinuousResizeListener,
    removeListener
};
class Worker {
    private throttleTimer = 500;
    private lastBreakpoint = '';
    private checkActiveBreakpointThrottled: any;
    private getActiveBreakpoint: () => string;

    public constructor(private callback: any, private continuousResizeListeners: any, getActiveBreakpoint: () => string) { // eslint-disable-line
        this.getActiveBreakpoint = getActiveBreakpoint;
        this.checkActiveBreakpointThrottled = throttle(this.handleViewportEvents.bind(this), this.throttleTimer, {
            trailing: true
        });

        viewportEvents.setCallback(this.checkActiveBreakpointThrottled);
    }

    public destroy(): void {
        viewportEvents.removeCallback(this.checkActiveBreakpointThrottled);
    }

    public handleViewportEvents(): void {
        this.continuousResizeListeners();
        const currentBreakpoint = this.getActiveBreakpoint();
        if (currentBreakpoint === this.lastBreakpoint) {
            return;
        }
        this.lastBreakpoint = currentBreakpoint;
        this.callback();
    }
}

const worker = new Worker(() => { // eslint-disable-line
    for (let l of listeners) {
        l();
    }
}, () => {
    for (let l of continuousResizeListeners) {
        l();
    }
}, getActiveBreakpoint);
