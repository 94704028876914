
















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ErrorMessages from '@/project/shared/ErrorMessages.vue';
import {
    IFixedUnitValidationState,
    validateFixedUnitValue
} from '@/project/products/productHelper.utils';
import {
    ClientMessage,
    ClientMessageType,
    UnitViewObject
} from '@/types/serverContract';

@Component({
    components: {
        ErrorMessages
    }
})
export default class FixedUnitValidation extends Vue {
    @Prop({
        type: String,
        required: true
    }) fixedUnitMaxQuantity!: string;

    @Prop({
        type: String,
        required: true
    }) fixedUnitMinQuantity!: string;

    @Prop({
        type: String,
        required: true
    }) fixedUnitCode!: string;

    @Prop({
        type: Number,
        required: true
    }) fixedUnitQty!: number;

    @Prop({
        type: Object,
        required: true
    }) currentOrderUnit!: UnitViewObject;

    currentFixedUnitErrors: ClientMessage[] = [];

    fixedUnitMaxValueMessage: ClientMessage = {
        message: this.$tr(this.$labels.Validation.FixedUnit.MaxFixedUnitQuantity, this.fixedUnitMaxQuantity, this.fixedUnitCode),
        messageType: ClientMessageType.Error
    };

    fixedUnitMinValueMessage: ClientMessage = {
        message: this.$tr(this.$labels.Validation.FixedUnit.MinFixedUnitQuantity, this.fixedUnitMinQuantity, this.fixedUnitCode),
        messageType: ClientMessageType.Error
    };

    fixedUnitValidationState: IFixedUnitValidationState = {
        isValid: true,
        maxValueNullOrValid: true,
        minValueValid: true
    };

    public forceValidation(): void {
        this.onFixedUnitQtyChange(this.fixedUnitQty);
    }

    @Watch('fixedUnitQty')
    onFixedUnitQtyChange(newFixedUnitVal: number) {
        const validationCheck = validateFixedUnitValue(newFixedUnitVal, this.currentOrderUnit);
        this.setValidationMessage(validationCheck);
        this.fixedUnitValidationState = validationCheck;
        this.$emit('validStateChange', this.fixedUnitValidationState, newFixedUnitVal);
    }

    setValidationMessage(validationState: IFixedUnitValidationState): void {
        this.currentFixedUnitErrors = [];

        if (validationState.isValid) {
            return;
        }

        if (!validationState.maxValueNullOrValid) {
            this.currentFixedUnitErrors.push(this.fixedUnitMaxValueMessage);
            return;
        }

        if (!validationState.minValueValid) {
            this.currentFixedUnitErrors.push(this.fixedUnitMinValueMessage);
        }
    }
}
