import httpService from '@/core/http/http.service';
import { DeliveryMethodsViewModel } from '@/types/serverContract';

export default class DeliveryMethodController {
    public async getDeliveryMethods(): Promise<DeliveryMethodsViewModel> {
        return httpService.get('deliveryMethodApi/deliveryMethods');
    }

    public async updateDeliveryMethod(id: string): Promise<void> {
        return httpService.post('deliveryMethodApi/deliveryMethod', { id });
    }
}
