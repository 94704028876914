














































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import find from 'lodash-es/find';
import { uuid } from '@/project/config/utilities';

@Component({
    inject: ['$validator']
})
export default class SelectCtrl extends Vue {
    @Prop({ type: String, required: false })
    public label!: string;

    @Prop({ required: true })
    public value!: string | object;

    @Prop({ type: String, required: true })
    public name!: string;

    @Prop(String) public constraints!: string;

    // If options and v-bind value is objects, this is the property that selects the id.
    @Prop({
        type: String,
        default: ''
    })
    idKey!: string;

    @Prop({ type: Array, required: true })
    public options!: string[] | object[];

    get id() {
        return (this.label ? this.label : '') + uuid();
    }

    get getValue() {
        return this.stringValue(this.value);
    }

    get hasValue() {
        return this.stringValue(this.value);
    }

    get error(): string | null {
        return this.vvErrors.first(this.name);
    }

    get field() {
        return this.vvFields[this.name];
    }

    get required() {
        return this.field && this.field.required;
    }

    hasPropIsSelected(option: string | object): boolean {
        if (option.hasOwnProperty('isSelected')) {
            return option['isSelected'];
        }
        return false;
    }

    created() {
        if (this.options.length === 1) {
            this.$emit('input', this.options[0]);
        }
    }

    stringValue(option: string | object): string {
        if (this.idKey) {
            return option ? option[this.idKey] : null;
        } else {
            return option as string;
        }
    }

    getOption(option: string | object): string {
        return this.idKey ? option[this.idKey] : option;
    }

    emitChange(event) {
        const newValue = this.idKey ? find(this.options, o => o[this.idKey].toString() === event.target.value) : event.target.value;
        this.$emit('input', newValue);
        this.$emit('change', event);

        this.$validator.validate(this.name);
    }

    // From Vue-validate
    vvErrors!: any;

    // From Vue-validate
    vvFields!: any;
}
