
























































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import {
    ProductIdType,
    SearchDeliveryNotesHistoryRequest
} from '@/types/serverContract';
import dayjs, { Dayjs } from 'dayjs';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import BookkeepingUrlHelper from './BookkeepingUrlHelper.service';
import { dateParseFormat } from '@/project/config/utilities';
import { clone } from 'lodash-es';
import DatePickerCtrl from '@/project/form/DatePickerCtrl.vue';
import { Route } from 'vue-router';
import { KeyValue } from '@/project/config/models';

@Component({
    components: {
        DatePickerCtrl,
        SpinnerOverlay
    }
})
export default class DeliveryNoteHistorySearchForm extends Vue {
    optionsProductIdTypeChange: KeyValue[] = Object.keys(ProductIdType)
        .filter((key: string): boolean => isNaN(Number(key)))
        .map((val: string): KeyValue => {
            return {
                key: val,
                value: this.$tr(this.$labels.Views.Bookkeeping.Invoices.ProductIdType[val])
            };
        });

    pending: boolean = false;
    selectedProductIdType: KeyValue | null = null;
    searchDeliveryNotesRequestPayload: SearchDeliveryNotesHistoryRequest = {
        caseNumber: BookkeepingUrlHelper.getSearchQuery('caseNumber'),
        fromDate: BookkeepingUrlHelper.getSearchQuery('fromDate') === '' ? dayjs().subtract(6, 'month').format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('fromDate'),
        orderNumber: BookkeepingUrlHelper.getSearchQuery('orderNumber'),
        page: BookkeepingUrlHelper.getPageNumber(),
        pageSize: 20,
        productId: BookkeepingUrlHelper.getSearchQuery('productId'),
        productIdType: BookkeepingUrlHelper.getSearchQuery('productIdType') === '' ? ProductIdType.Sani : (BookkeepingUrlHelper.getSearchQuery('productIdType') as unknown as ProductIdType),
        reference: BookkeepingUrlHelper.getSearchQuery('reference'),
        toDate: BookkeepingUrlHelper.getSearchQuery('toDate') === '' ? dayjs().format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('toDate'),
        requisitionNumber: BookkeepingUrlHelper.getSearchQuery('requisitionNumber')
    };

    get isDateSpanTooLong(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) > 6;
    }

    get isDateSpanTooShort(): boolean {
        return this.dayjsToDate.diff(this.dayjsFromDate, 'month', true) < 0;
    }

    get dayjsFromDate(): Dayjs {
        return dayjs(this.searchDeliveryNotesRequestPayload.fromDate);
    }

    get dayjsToDate(): Dayjs {
        return dayjs(this.searchDeliveryNotesRequestPayload.toDate);
    }

    created() {
        this.submitForm();
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            return;
        }
        const pageChanged = this.searchDeliveryNotesRequestPayload.page !== BookkeepingUrlHelper.getPageNumber();
        if (pageChanged) {
            this.searchDeliveryNotesRequestPayload.page = BookkeepingUrlHelper.getPageNumber();
            this.searchDeliveryNotes();
        }
    }

    @Watch('searchDeliveryNotesRequestPayload.fromDate')
    setFromDate(): void {
        if (this.isDateSpanTooLong) {
            this.searchDeliveryNotesRequestPayload.toDate = this.dayjsFromDate.clone().add(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.searchDeliveryNotesRequestPayload.toDate = clone(this.searchDeliveryNotesRequestPayload.fromDate);
        }
    }

    @Watch('searchDeliveryNotesRequestPayload.toDate')
    setToDate(): void {
        if (this.isDateSpanTooLong) {
            this.searchDeliveryNotesRequestPayload.fromDate = this.dayjsToDate.clone().subtract(6, 'month').format(dateParseFormat);
        } else if (this.isDateSpanTooShort) {
            this.searchDeliveryNotesRequestPayload.fromDate = clone(this.searchDeliveryNotesRequestPayload.toDate);
        }
    }

    async searchDeliveryNotes(): Promise<void> {
        this.pending = true;
        try {
            await Api.deliveryNotes.search(this.searchDeliveryNotesRequestPayload).then(searchDeliveryNotesResult => {
                this.$emit('searchSuccess', searchDeliveryNotesResult);
                BookkeepingUrlHelper.setSearchQuery({
                    caseNumber: this.searchDeliveryNotesRequestPayload.caseNumber,
                    fromDate: this.searchDeliveryNotesRequestPayload.fromDate,
                    orderNumber: this.searchDeliveryNotesRequestPayload.orderNumber,
                    productId: this.searchDeliveryNotesRequestPayload.productId,
                    productIdType: this.searchDeliveryNotesRequestPayload.productIdType,
                    reference: this.searchDeliveryNotesRequestPayload.reference,
                    toDate: this.searchDeliveryNotesRequestPayload.toDate,
                    requisitionNumber: this.searchDeliveryNotesRequestPayload.requisitionNumber,
                    page: this.searchDeliveryNotesRequestPayload.page
                });
            });
        } finally {
            this.pending = false;
        }
    }

    onProductIdTypeChange(chosenProductIdType: any): void {
        this.searchDeliveryNotesRequestPayload.productIdType = (ProductIdType[chosenProductIdType.key] as unknown as ProductIdType);
        this.selectedProductIdType = this.optionsProductIdTypeChange[this.searchDeliveryNotesRequestPayload.productIdType];
    }

    submitForm(): void {
        this.searchDeliveryNotesRequestPayload.page = 1;
        this.searchDeliveryNotes();
    }
}
