



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Co2CalculationViewObject } from '@/types/serverContract';

@Component({
    components: {
    }
})
export default class EcoCertificationTotal extends Vue {
    @Prop({
        type: Object,
        required: false
    }) co2Calculation!: Co2CalculationViewObject;

    get cO2InProduktionenTotalText(): string {
        return this.$tr(this.$labels.Views.Checkout.CO2InProduktionenTotal);
    }
}
