

















































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import {
    AddToBasketRequest,
    CertificateViewObject,
    LoginInformation,
    PriceViewObject,
    UnitViewObject,
    ProductTileTrackingContext,
    CaseInformationRequest,
    ScalePricePromotionInformationViewObject
} from '@/types/serverContract';
import ProductOrderableUnit from '@/project/products/ProductOrderableUnit.vue';
import {
    IFixedUnitValidationState,
    IUnitData
} from '@/project/products/productHelper.utils';
import FixedUnitValidation from '@/project/shared/FixedUnitValidation.vue';
import ProductAddToBasketButton from '@/project/products/ProductAddToBasketButton.vue';
import AddToFavoriteOrder from '@/project/favoriteOrders/AddToFavoriteOrder.vue';
import AddToEasySupplyWarehouse from '@/project/easySupply/AddToEasySupplyWarehouse.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import ProductOrderableUnitsInline from '@/project/products/ProductOrderableUnitsInline.vue';
import ScalePriceToPromote from '@/project/products/ScalePriceToPromote.vue';
import { FlexBoxGetter } from '@/store/flexBox';
import productTrackingService, { TrackedProduct } from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';

interface WatchableAddToBasketPayload {
    addAsNewLine: boolean;
    certificateCode: string | null;
    fixedUnitCode: string | null;
    fixedUnitValue: number | null;
    isFixedUnit: boolean;
    productId: string;
    quantity: number;
    stamping: boolean;
    unit: string;
}

@Component({
    components: {
        ProductOrderableUnitsInline,
        AddToEasySupplyWarehouse,
        AddToFavoriteOrder,
        ProductAddToBasketButton,
        FixedUnitValidation,
        ProductOrderableUnit,
        ScalePriceToPromote
    }
})
export default class ProductAddToBasket extends Vue {
    @Prop({ required: true, type: Array }) orderableUnits!: UnitViewObject[];
    @Prop({ required: true, type: String })variantId!: string;
    @Prop({ required: true, type: Object })currentPrice!: PriceViewObject;
    @Prop({ required: true, type: String }) productName!: string;
    @Prop({ required: false, type: String, default: undefined }) manufacturer!: string | undefined;
    @Prop({ required: false, type: String, default: undefined }) primaryCategory!: string| undefined;
    @Prop({ required: false, type: Object }) trackingContext!: ProductTileTrackingContext;
    @Prop({ default: false, type: Boolean }) showAddToFavoriteOrder!: boolean;
    @Prop({ default: false, type: Boolean }) showAddToEasySupplyWarehouse!: boolean;
    @Prop({ default: undefined, type: Object }) currentUnit!: UnitViewObject;
    @Prop({ default: 0, type: Number }) currentFixedUnitQty!: number;
    @Prop({ default: 0, type: Number }) currentQuantity!: number;
    @Prop({ default: () => {}, type: Object }) currentCertificate!: CertificateViewObject;
    @Prop({ default: null, type: Object }) caseInformation!: CaseInformationRequest;
    @Prop({ type: String, default: 'product-tile', validator: (value: string) => ['search-tile', 'product-tile'].indexOf(value) > -1 }) mode!: string;
    @Prop({ default: false, type: Boolean }) compact!: boolean;
    @Prop({ default: false, required: false, type: Boolean }) showOrderableUnits!: boolean;
    @Prop({ default: true, type: Boolean }) allowEdit!: boolean;

    @AppGetter loginInformation!: LoginInformation;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    selectedUnit: UnitViewObject = this.currentUnit || this.orderableUnits[0];
    addCertificate: boolean = isNotNullEmptyOrUndefined(this.currentCertificate);
    certificateCode: string | null = this.currentCertificate
        ? this.currentCertificate.certificateCode
        : null;

    certificateStamping: boolean = this.currentCertificate
        ? this.currentCertificate.stamping
        : false;

    quantity: number = this.currentQuantity || this.selectedUnit.increment;
    hasQuantityError: boolean = false;
    favoriteOrderableUnits: IUnitData[] = [];
    fixedUnitQty: number = this.currentFixedUnitQty || 0;
    fixedUnitValidationState: IFixedUnitValidationState = {
        isValid: true,
        maxValueNullOrValid: true,
        minValueValid: true
    };

    scalePriceToPromote: ScalePricePromotionInformationViewObject | null = null;
    timeout: number | null = null;
    scalePriceToPromoteTimer: number = 10000;

    get hasMultipleOrderableUnits(): boolean {
        return this.orderableUnits.length >= 2 && this.loginInformation.canPurchase;
    }

    get watchableAddToBasketPayload(): WatchableAddToBasketPayload {
        const stamping =
    this.certificateCode && this.certificateStamping
        ? this.certificateStamping
        : false;

        const fixedUnit = this.selectedUnit.isFixedUnit
            ? this.selectedUnit.isFixedUnit
            : false;

        const fixedUnitCode = this.selectedUnit.isFixedUnit
            ? this.selectedUnit.fixedUnitQuantity.fixedUnitCode
            : null;

        const fixedUnitValue = this.selectedUnit.isFixedUnit
            ? this.fixedUnitQty
            : null;

        return {
            addAsNewLine: false,
            certificateCode: this.certificateCode,
            fixedUnitValue: fixedUnitValue,
            fixedUnitCode: fixedUnitCode,
            isFixedUnit: fixedUnit,
            productId: this.variantId,
            quantity: this.quantity,
            stamping: stamping,
            unit: this.selectedUnit.unitCode
        };
    }

    get addToBasketPayload(): AddToBasketRequest {
        return {
            // object spread of properties from watchableAddToBasketPayload to remove duplicate code
            ...this.watchableAddToBasketPayload,
            caseInformation: this.caseInformation
        };
    }

    get fixedUnitMinQuantity(): string {
        return this.selectedUnit.isFixedUnit &&
    this.selectedUnit.fixedUnitQuantity.minimumQuantity
            ? this.selectedUnit.fixedUnitQuantity.minimumQuantity.toString()
            : '';
    }

    get fixedUnitMaxQuantity(): string {
        return this.selectedUnit.isFixedUnit &&
    this.selectedUnit.fixedUnitQuantity.maximumQuantity
            ? this.selectedUnit.fixedUnitQuantity.maximumQuantity.toString()
            : '';
    }

    get fixedUnitCode(): string {
        return this.selectedUnit.isFixedUnit
            ? this.selectedUnit.fixedUnitQuantity.fixedUnitCode
            : '';
    }

    get hasUnitErrors(): boolean {
        return !this.fixedUnitValidationState.isValid || this.hasQuantityError;
    }

    $refs!: {
        fixedUnitValidation: FixedUnitValidation;
    };

    created() {
        this.setFavoriteOrderableUnits();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async forceFixedUnitValidation(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.selectedUnit.isFixedUnit) {
                // Casting reference to type any, to avoid having to implement an interface to tell ts that the reference has the "forceValidation" method
                (this.$refs.fixedUnitValidation as any).forceValidation();
                this.$nextTick().then(() => {
                    if (this.fixedUnitValidationState.isValid) {
                        resolve();
                    } else {
                        reject(new Error('Fixed unit invalid'));
                    }
                });
            } else {
                resolve();
            }
        });
    }

    updateAddToBasketPayload(unitData: IUnitData): void {
        if (unitData && unitData.unitPayload) {
            this.quantity = unitData.unitPayload.quantity;
            this.selectedUnit = unitData.orderableUnit;
            this.fixedUnitQty = unitData.unitPayload.fixedUnitValue || 0;

            this.updateFavoriteOrderableUnit(unitData);
        }
    }

    setFavoriteOrderableUnits(): void {
        this.orderableUnits.forEach(unit => {
            let unitData: IUnitData = {
                unitPayload: {
                    unit: unit.unitKey,
                    quantity: unit.increment,
                    fixedUnitValue: unit.isFixedUnit && unit.fixedUnitQuantity ? 0 : null
                },
                orderableUnit: unit
            };

            this.favoriteOrderableUnits.push(unitData);
        });
    }

    updateFavoriteOrderableUnit(unitData: IUnitData): void {
        let itemIndex = this.favoriteOrderableUnits.findIndex(
            item => item.orderableUnit.unitKey === unitData.orderableUnit.unitKey
        );
        this.favoriteOrderableUnits.splice(itemIndex, 1, unitData);
    }

    validateFixedUnit(validState: IFixedUnitValidationState): void {
        this.fixedUnitValidationState = validState;
    }

    setCertificateCode(): void {
        this.certificateCode =
    this.addCertificate &&
    this.currentPrice &&
    this.currentPrice.certificates.length
        ? this.currentPrice.certificates[0].certificateCode
        : null;
    }

    onQuantityError(hasQuantityError): void {
        this.hasQuantityError = hasQuantityError;
    }

    showScalePriceToPromote(
        scalePriceToPromote: ScalePricePromotionInformationViewObject
    ): void {
        this.scalePriceToPromote = scalePriceToPromote;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.scalePriceToPromote = null;
        }, this.scalePriceToPromoteTimer);
    }

    closeScalePriceToPromote(): void {
        this.scalePriceToPromote = null;
    }

    @Watch('currentQuantity')
    currentQuantityChanged(): void {
        this.quantity = this.currentQuantity;
    }

    @Watch('watchableAddToBasketPayload')
    emitAddToBasketPayloadChanged(): void {
        this.$nextTick().then(() => {
            if (this.fixedUnitValidationState.isValid) {
                this.$emit('change', this.addToBasketPayload);
            }
        });
    }

    private trackAddToBasket(): void {
        productTrackingService.TrackProduct(
            PRODUCT_TRACKING_EVENT.AddToCart,
            productTrackingService.ToTrackedProduct(
                this.addToBasketPayload,
                PRODUCT_TRACKING_TYPE.AddToBasketRequest,
                this.currentPrice,
                new TrackedProduct({
                    name: this.productName,
                    brand: this.manufacturer,
                    category: this.primaryCategory,
                    trackingContextObject: this.trackingContext
                })
            )
        );

        this.$emit('on-added-to-basket');
    }

    @Watch('addCertificate')
    certificateCheckboxWatcher() {
        if (!this.addCertificate) {
            this.certificateStamping = false;
        }
    }
}
