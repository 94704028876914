import serverContextService from '../serverContext.service';
import log from '../logging.service';
import { AxiosResponse } from 'axios';

export default (response: AxiosResponse) => {
    if (response.headers) {
        const currentVersion = response.headers['applicationcachebuster'];
        const initialVersion = serverContextService.applicationCacheBuster;
        if (currentVersion && currentVersion !== initialVersion) {
            log.debug(`Hard-reloading due to version mismatch. Initial: ${initialVersion}, Current: ${currentVersion}`);
            window.location.reload();
        }
    }
    return response;
};
