import Vue from 'vue';
import breakpointService from './breakpoints.service';
import forEach from 'lodash-es/forEach';
import { Mixin } from 'vue-mixin-decorator';

@Mixin
export class BreakpointsMixin extends Vue {
    public breakpointIsActive: { [breakpoint: string]: boolean } = {};

    public created() {
        breakpointService.addListener(this.handler);
    }

    private handler() {
        forEach(breakpointService.getBreakpoints(), bp => {
            this.$set(this.breakpointIsActive, bp.name, breakpointService.isActiveBreakpoint(bp.name));
        });
    }

    protected destroyed() {
        breakpointService.removeListener(this.handler);
    }
}
