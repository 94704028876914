




































































import { Mixins, Component } from 'vue-property-decorator';
import DatePickerCtrl from '@/project/form/DatePickerCtrl.vue';
import BookkeepingUrlHelper from '@/project/bookkeeping/BookkeepingUrlHelper.service';
import dayjs from 'dayjs';
import { dateParseFormat } from '@/project/config/utilities';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import Api from '@/project/http/api';
import {
    FirstLevelCategoryViewObject,
    MaintenanceDocument,
    MaintenanceReportOptionsRequest
} from '@/types/serverContract';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import ProjectFolderOrderHistoryFirstLevel
    from '@/project/myServices/ProjectFolderOrderHistoryFirstLevel.vue';
import { ProjectFolderOrderHistoryMaintenanceMixin } from '@/project/myServices/projectFolderOrderHistoryMaintenanceMixin.mixin';
import { ReportsGetter, ReportsAction } from '@/store/reports';
import RequestMaintenanceReportForm from '@/project/myServices/RequestMaintenanceReportForm.vue';

@Component({
    components: {
        RequestMaintenanceReportForm,
        ProjectFolderOrderHistoryFirstLevel,
        SpinnerOverlay,
        ButtonSubmit,
        DatePickerCtrl
    }
})
export default class ProjectFolderOrderHistoryQa extends Mixins<ProjectFolderOrderHistoryMaintenanceMixin>(ProjectFolderOrderHistoryMaintenanceMixin) {
    @ReportsGetter maintenanceDocuments!: MaintenanceDocument[];
    @ReportsAction addMaintenanceDocument!: (maintenanceDocument: MaintenanceDocument) => void;

    fromDate: string = BookkeepingUrlHelper.getSearchQuery('fromDate') === '' ? dayjs().subtract(1, 'month').format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('fromDate');
    toDate: string = BookkeepingUrlHelper.getSearchQuery('toDate') === '' ? dayjs().format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('toDate');
    pending: boolean = false;
    maintenanceCategories: FirstLevelCategoryViewObject[] | null = null;

    created() {
        this.getMaintenanceReports();
    }

    async getMaintenanceReports() {
        this.pending = true;

        const payload: MaintenanceReportOptionsRequest = {
            toDate: this.toDate,
            fromDate: this.fromDate
        };

        try {
            const maintenanceViewModel = await Api.reports.maintenanceReportOptions(payload);
            if (maintenanceViewModel) {
                this.maintenanceCategories = maintenanceViewModel.categories;
            }
        } finally {
            this.pending = false;
        }
    }
}
