import httpService from '@/core/http/http.service';
import {
    CampaignRequest,
    RecommendationViewModel,
    ProductTileSlimViewObject
} from '@/types/serverContract';

export default class CampaignController {
    public async recommendationCampaign(payload: CampaignRequest): Promise<RecommendationViewModel<ProductTileSlimViewObject>> {
        return httpService.post<RecommendationViewModel<ProductTileSlimViewObject>>('/campaignApi/GetCampaign', payload);
    }
}
