













































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Expandable extends Vue {
    @Prop({
        type: String,
        default: 'sanicon-ico-arrow-nav-forward'
    }) iconName!: string;

    @Prop({
        type: Boolean,
        default: false
    }) fullWidth!: boolean;

    @Prop({
        type: Boolean,
        default: false
    }) defaultOpen!: boolean;

    @Prop({
        type: String,
        default: ''
    }) headerClasses!: string;

    @Prop({
        type: String,
        default: ''
    }) peekSize!: string;

    @Prop({
        type: String
    }) showMoreText!: string;

    @Prop({
        type: String
    }) showLessText!: string;

    show: boolean = false;

    get peekHeight(): string {
        return this.peekSize !== '' ? this.peekSize + 'px' : '0';
    }

    $refs!: {
        expandableBody: HTMLElement;
        expandableSection: HTMLElement;
    };

    created() {
        if (this.defaultOpen) {
            this.show = true;
        }
    }

    async enter(el): Promise<void> {
        el.style.height = el.scrollHeight + 'px';
        return new Promise((resolve) => {
            el.addEventListener('transitionend', () => this.transitionEnd(el, resolve), false);
        });
    }

    toggleShow(): void {
        this.show = !this.show;
        if (this.show) {
            this.enter(this.$refs.expandableBody).then(() => {
                this.afterEnter(this.$refs.expandableBody);
            });
        } else {
            this.leave(this.$refs.expandableBody);
        }
    }

    transitionEnd(el, resolve) {
        el.removeEventListener('transitionend', this.transitionEnd);
        resolve();
    }

    afterEnter(el) {
        el.style.overflow = 'visible';
        el.style.height = 'auto';
    }

    leave(el): void {
        el.style.overflow = 'hidden';
        el.style.height = el.scrollHeight + 'px';
        this.$nextTick(() => {
            el.style.height = this.peekHeight;
        });
    }
}
