



















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: { SpinnerOverlay }
})
export default class Logout extends Vue {
    showSpinner = false;

    async logout() {
        try {
            this.showSpinner = true;
            await Api.authentication.logout();
        } finally {
            this.showSpinner = false;
        }
    }
}
