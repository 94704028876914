














































































































































import Component from 'vue-class-component';
import ServerView from './core/spa/ServerView.vue';
import SpaServer from './core/spa/SpaServer.vue';
import { AppGetter, AppAction } from './store/app';
import Breadcrumb from '@/project/navigation/Breadcrumb.vue';
import InfoMessages from '@/project/messages/InfoMessages.vue';
import { AgreementViewModel, NavigationItem } from '@/types/serverContract';
import { Mixins } from 'vue-mixin-decorator/lib/mixins';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoints.mixin';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import serverContext from '@/core/serverContext.service';
import Api from '@/project/http/api';
import { StorageService } from '@/core/storage.service';
import Constants from '@/project/config/constants';
import CheckoutHeader from '@/project/checkout/CheckoutHeader.vue';
import CheckoutLinks from '@/project/checkout/CheckoutLinks.vue';
import ReturnToTopButton from '@/project/shared/ReturnToTopButton.vue';
import AgreementsModal from '@/project/messages/AgreementsModal.vue';
import HelpDesk from '@/project/helpDesk/HelpDesk.vue';
import MobileLeftNavigation from '@/project/navigation/left-navigation/MobileLeftNavigation.vue';
import tracking from '@/core/tracking/tracking.service';
import raptorTracking from '@/core/tracking/raptorTracking.service';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import FlexBoxModal from '@/project/flexBox/FlexBoxModal.vue';
import { FlexBoxGetter, FlexBoxAction } from '@/store/flexBox';
import ProductCo2InfoModal from '@/project/products/ProductCo2InfoModal.vue';
import imageService from '@/core/image.service';

@Component({
    components: {
        ProductCo2InfoModal,
        MobileLeftNavigation,
        AgreementsModal,
        HelpDesk,
        SpinnerOverlay,
        InfoMessages,
        Breadcrumb,
        SpaServer,
        ServerView,
        CheckoutHeader,
        CheckoutLinks,
        ReturnToTopButton,
        ModalOverlay,
        FlexBoxModal
    }
})
export default class App extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @AppGetter isCheckoutView!: boolean;
    @AppGetter pageHasCampaignView!: boolean;
    @AppGetter isPrerenderRequest!: boolean;
    @AppGetter useFallbackCookieConsent!: boolean;
    @AppGetter leftNavigation!: NavigationItem | null;
    @AppGetter isLoadingSpaPage!: boolean;
    @AppGetter isLoggedIn!: boolean;

    @AppAction setHideNetPrices!: (active: boolean) => void;
    @AppAction setNonAcceptedAgreement!: (nonAcceptedAgreement: AgreementViewModel) => void;

    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @FlexBoxGetter showFlexBoxModal!: boolean;
    @FlexBoxGetter changeToAccount!: string;
    @FlexBoxAction setShowFlexBoxModal!: (show: boolean) => void;
    @FlexBoxAction setFlexBoxInfoText!: (payload: any) => void;

    // @ts-ignore
    showIEWarningModal: boolean = window.isIE;

    showMobileWarningModal: boolean = false;
    mobileWarningShownCookieName: string = 'mobileWarningShown';
    mobileWarningTimeout: number | null = null;

    get cookieMessage(): string {
        return this.$tr(this.$labels.General.CookieAcceptText, this.cookieInfoUrl);
    }

    get cookieInfoUrl(): string {
        return serverContext.sitePages.cookieInfoPage.url;
    }

    get ieBrowserValidationMessage(): string {
        return this.$tr(this.$labels.Validation.Browser.IEMessage);
    }

    get mobileValidationMessage(): string {
        return this.$tr(this.$labels.Validation.Mobile.DownloadApp);
    }

    get flexBoxModalHeading(): string {
        if (this.isInFlexBoxContext) {
            if (this.changeToAccount) {
                return this.$tr(this.$labels.Views.FlexBox.ChangeAccount.Header);
            } else {
                return this.$tr(this.$labels.Views.FlexBox.StopConfirmation);
            }
        } else {
            return this.$tr(this.$labels.Views.FlexBox.SpecifyAddress);
        }
    }

    get flexBoxModalSize(): string {
        return this.isInFlexBoxContext ? 'small' : 'medium';
    }

    get isMobile(): boolean {
        // @ts-ignore
        return window.isMobile;
    }

    get mobileWarningAlreadyShown(): boolean {
        return document.cookie
            .split(';')
            .some(item => item.trim().startsWith(this.mobileWarningShownCookieName + '='));
    }

    created() {
        this.mobileWarningTimeout = setTimeout(() => this.handleMobileWarning(), 7000);
        tracking.trackUserData();
        tracking.initTracking();
        if (serverContext.initialPageData.isNotFound) {
            tracking.trackNotFound();
        }
        raptorTracking.initPageViewTracking();
        if (this.isLoggedIn) {
            this.getBasket();
            Api.agreements.nextNonAcceptedAgreement().then(nonAcceptedAgreement => {
                this.setNonAcceptedAgreement(nonAcceptedAgreement);
            });
        }

        const showNetPricesFromStorage = StorageService.getItem(
            Constants.storageKeys.hideNetPrices
        );
        if (showNetPricesFromStorage !== null) {
            if (showNetPricesFromStorage === 'true') {
                this.setHideNetPrices(true);
            } else {
                this.setHideNetPrices(false);
            }
        }

        const flexBoxInfoPayload = {
            inFlexBoxText: 'views.FlexBox.TimeSpecific.InfoTextShort',
            defaultText: 'views.FlexBox.TimeSpecific.InfoTextLong'
        };
        this.setFlexBoxInfoText(flexBoxInfoPayload);
        imageService.setIsWebPSupported();

        document.addEventListener('keydown', this.setBodyEventClass);
        document.addEventListener('mousedown', this.setBodyEventClass);
    }

    destroyed() {
        if (this.mobileWarningTimeout) {
            clearTimeout(this.mobileWarningTimeout);
        }

        document.removeEventListener('keydown', this.setBodyEventClass);
        document.removeEventListener('mousedown', this.setBodyEventClass);
    }

    async getBasket() {
        try {
            await Api.basket.getBasket();
        } finally {
        }
    }

    handleMobileWarning(): void {
        this.showMobileWarningModal = this.isMobile && !this.mobileWarningAlreadyShown;
        if (this.showMobileWarningModal) {
            this.addMobileWarningShownCookie();
        }
    }

    addMobileWarningShownCookie(): void {
        document.cookie = this.mobileWarningShownCookieName + '=true';
    }

    closeFlexBoxModal(): void {
        if (this.showFlexBoxModal === false) return;

        this.setShowFlexBoxModal(false);
        if (this.isInFlexBoxContext) {
            App.trackStopFlexBox(false);
        } else {
            App.trackStartFlexBox(false);
        }
    }

    setBodyEventClass(event: KeyboardEvent | MouseEvent): void {
        document.body.classList.remove('keyboard', 'mouse');
        document.body.classList.add(event.type === 'keydown' ? 'keyboard' : 'mouse');
    }

    private static trackStartFlexBox(didActivateFlexBoxMode: boolean): void {
        tracking.trackStartFlexBox(didActivateFlexBoxMode);
    }

    private static trackStopFlexBox(didStopFlexBoxMode: boolean): void {
        tracking.trackStopFlexBox(didStopFlexBoxMode);
    }
}
