










































































































import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { StoreStockQuantityViewObject } from '@/types/serverContract';
import translateFilter from '@/core/translation/translate.filter';
import ProductStoreStockItem from '@/project/products/ProductStoreStockItem.vue';
import scrollService from '@/core/scroll/scroll.service';
import { cloneDeep } from 'lodash-es';
import imageService from '@/core/image.service';
import { markerSize } from '@/types/mapMarkerSizes';

const InStockMarker = require('@/assets/images/sanimarker-in-stock.png'); // eslint-disable-line @typescript-eslint/no-var-requires
const OutOfStockMarker = require('@/assets/images/sanimarker-not-in-stock.png'); // eslint-disable-line @typescript-eslint/no-var-requires

@Component({
    components: {
        ProductStoreStockItem
    }
})
export default class ProductStore extends Vue {
    @Prop({
        type: Array
    }) productStoreStocks!: StoreStockQuantityViewObject[];

    pending: boolean = false;
    centerLat: number = 0;
    centerLng: number = 0;
    zoom: number = 14;
    showAllStores: boolean = false;
    showOnlyStock: boolean = false;
    infoWindow = {
        position: { lat: 0, lng: 0 },
        open: false,
        template: ''
    };

    get filteredOrOriginalStores(): StoreStockQuantityViewObject[] {
        return this.showOnlyStock ? this.productStoreStocks.filter(store => store.quantity) : this.productStoreStocks;
    }

    get alphabeticalStores(): StoreStockQuantityViewObject[] {
        const clonedStores = cloneDeep(this.filteredOrOriginalStores);

        return clonedStores.sort((a, b) => a.name.localeCompare(b.name));
    }

    $refs!: {
        fullListSection: HTMLElement;
    };

    created() {
        if (this.productStoreStocks) {
            this.setMyStore(this.productStoreStocks);
        }
    }

    openInfoWindow(store: StoreStockQuantityViewObject) {
        this.setInfoWindowTemplate(store);
        this.infoWindow.position = this.getCoordinates(store);
        this.infoWindow.open = true;
    }

    setInfoWindowTemplate(store: StoreStockQuantityViewObject) {
        const unitLabel = store.quantity ? translateFilter(`unit.${store.unitCode}`) : '';
        const inStockLabel = this.$tr(this.$labels.Views.ProductDetails.Tabs.Stock.InStock);
        const notInStockLabel = this.$tr(this.$labels.Product.NotInStockDetailed);
        const inStockTemplate = store.quantity ? `<span class="inline-block mr-5">
                                                <i class="sanicon-ico-in-stock-small-checkmark inline-block text-green-500 mr-1"></i>
                                                <span class="font-semibold">${store.quantity} ${unitLabel}</span>
                                             </span>` : '';
        const notInStockTemplate = !store.quantity ? '<span class="inline-block mr-5"><i class="h-13 w-13 bg-red-500 rounded-full block"></i></span>' : '';
        const stockLabel = store.quantity ? inStockLabel : notInStockLabel;

        this.infoWindow.template = `<div class="p-10">
                                    <a href="${store.url}" class="inline-block">
                                        <span class="block font-semibold mb-10 text-18 text-blue-700">${store.city}</span>
                                        <p class="mb-5">${store.street}</p>
                                        <p><span>${store.postalCode}</span> <span>${store.city}</span></p>
                                    </a>
                                    <p class="text-blue-700 flex items-center mt-10">
                                        ${notInStockTemplate}
                                        ${inStockTemplate}
                                        ${stockLabel}
                                    </p>
                                </div>`;
    }

    getCoordinates(store: StoreStockQuantityViewObject) {
        return {
            lat: store.mapLocation.latitude,
            lng: store.mapLocation.longitude
        };
    }

    resetAndCloseInfoWindow() {
        this.infoWindow = {
            position: { lat: 0, lng: 0 },
            open: false,
            template: ''
        };
    }

    storeIcon(store: StoreStockQuantityViewObject) {
        return {
            url: store.quantity ? (store.iconInStock ? imageService.getWebpOptimizedSourceStrictSizeStretch(store.iconInStock, '', markerSize, markerSize) : InStockMarker) : (store.iconNotInStock ? imageService.getWebpOptimizedSourceStrictSizeStretch(store.iconNotInStock, '', markerSize, markerSize) : OutOfStockMarker),
            size: { width: markerSize, height: markerSize, f: 'px', b: 'px' }
        };
    }

    setMyStore(storeStocks: StoreStockQuantityViewObject[]): void {
        const myStore = storeStocks.find(store => store.isMyStore);

        this.centerLat = myStore ? myStore.mapLocation.latitude : storeStocks[0].mapLocation.latitude;
        this.centerLng = myStore ? myStore.mapLocation.longitude : storeStocks[0].mapLocation.longitude;
        this.zoom = myStore ? myStore.mapLocation.zoom : 6;
    }

    toggleAllStores(): void {
        this.showAllStores = !this.showAllStores;
        if (this.showAllStores) {
            scrollService.scrollToElement(this.$refs.fullListSection, -150);
        }
    }
}
