import {
    RelatedProductsViewObject,
    UnitViewObject
} from '@/types/serverContract';
import translateFilter from '@/core/translation/translate.filter';

export interface IRelatedProductGroups {
    alternativeProducts: RelatedProductsViewObject;
    relatedProducts: RelatedProductsViewObject;
    spareParts: RelatedProductsViewObject;
}

export interface IFixedUnitValidationState {
    isValid: boolean;
    maxValueNullOrValid: boolean;
    minValueValid: boolean;
}

export interface IUnitPayload {
    fixedUnitValue: number | null;
    quantity: number;
    unit: string;
}

export interface IUnitData {
    orderableUnit: UnitViewObject;
    unitPayload: IUnitPayload;
}

export function orderableUnitText(orderableUnit: UnitViewObject): string {
    if (orderableUnit.isFixedUnit) {
        return orderableUnit.displayNameSupplementary ? orderableUnit.displayName + ' ' + translateFilter('product.PerUnit') : orderableUnit.displayName;
    }
    return orderableUnit.displayNameSupplementary ? `${orderableUnit.displayName} ${orderableUnit.displayNameSupplementary} ${orderableUnit.baseUnitDisplayName}` : orderableUnit.displayName;
}

export function validateFixedUnitValue(parsedValue: number, currentOrderUnit: UnitViewObject): IFixedUnitValidationState {
    const maxValueNullOrValid = currentOrderUnit.fixedUnitQuantity.maximumQuantity === null || currentOrderUnit.fixedUnitQuantity.maximumQuantity >= parsedValue;
    const minValueValid = currentOrderUnit.fixedUnitQuantity.minimumQuantity <= parsedValue;

    return {
        isValid: minValueValid && maxValueNullOrValid,
        maxValueNullOrValid,
        minValueValid
    };
}
