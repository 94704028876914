














































































































import Vue from 'vue';
import {
    Component,
    Prop
} from 'vue-property-decorator';
import {
    HelpDeskSalesAccountsViewObject,
    CreateUserFromHelpDeskRequest,
    UserCreationViewModel,
    UserStoreViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import tracking, { SignUpTypesEnum } from '@/core/tracking/tracking.service';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class HelpDeskCreateUser extends Vue {
    @Prop({
        required: true,
        type: Object
    }) selectedAccount!: HelpDeskSalesAccountsViewObject;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    createUserFormId: string = 'create-user-form';
    timeout: number | null = null;
    creationOptions: UserCreationViewModel = {
        stores: []
    };

    createUserPayload: CreateUserFromHelpDeskRequest = {
        soldToPartnerId: this.selectedAccount.soldToPartnerId,
        soldToPhoneNumber: this.selectedAccount.soldToPhoneNumber,
        name: '',
        email: '',
        mobileNumber: '',
        userName: '',
        storeId: ''
    };

    created() {
        this.getOptionsForCreate();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async createUser() {
        this.pending = true;
        try {
            await Api.helpDesk.createUser(this.createUserPayload, this.createUserFormId).then(() => {
                this.showSuccessIndicator();
                this.resetPayload();
                tracking.trackSignup(SignUpTypesEnum.ExtraWebUser);
            });
        } finally {
            this.pending = false;
        }
    }

    async getOptionsForCreate() {
        this.pending = true;
        try {
            await Api.user.getUserCreationOptions().then(options => {
                Object.assign(this.creationOptions, options);
            });
        } finally {
            this.pending = false;
        }
    }

    onUserStoreChange(store: UserStoreViewObject) {
        this.createUserPayload.storeId = store.id;
    }

    resetPayload() {
        this.createUserPayload.name = '';
        this.createUserPayload.email = '';
        this.createUserPayload.mobileNumber = '';
        this.createUserPayload.userName = '';
        this.createUserPayload.storeId = '';
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    goBack(): void {
        this.$emit('goBack');
    }
}
