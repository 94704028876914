































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    HelpDeskSalesAccountsViewObject,
    GetAccountsForUpdateAllowNewUsersRequest,
    HelpDeskAccountAdministrationListViewModel,
    UpdateAllowNewUsersOnAccountRequest,
    UpdateAllowNewUsersOnAccount
} from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class HelpDeskEditAccountsAccess extends Vue {
    @Prop({
        required: true,
        type: Object
    }) selectedProfile!: HelpDeskSalesAccountsViewObject;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    profile: HelpDeskAccountAdministrationListViewModel | null = null;
    timeout: number | null = null;

    created(): void {
        this.getAccountsForUpdateAllowNewUsers();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async getAccountsForUpdateAllowNewUsers(): Promise<void> {
        try {
            const payload: GetAccountsForUpdateAllowNewUsersRequest = {
                billToPartnerSalesAccountId: this.selectedProfile.salesAccountId
            };

            await Api.helpDesk.accountsForUpdateAllowNewUsers(payload).then(res => {
                this.profile = res;
            });
        } finally {
        }
    }

    async updateAllowNewUsersOnAccount(): Promise<void> {
        this.pending = true;
        if (!this.profile) {
            return;
        }

        const payloadAccounts: UpdateAllowNewUsersOnAccount[] = this.profile.billToAccountsForAdministrationViewObject.map(
            account => {
                return {
                    allowNewUsers: account.allowNewUsers,
                    salesAccountId: account.salesAccountId
                };
            });

        const payload: UpdateAllowNewUsersOnAccountRequest = {
            accounts: payloadAccounts
        };

        try {
            await Api.helpDesk.updateAllowNewUsersOnAccount(payload).then(() => {
                this.success = true;
                this.showSuccessIndicator();
            });
        } finally {
            this.pending = false;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;
        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    goBack(): void {
        this.$emit('goBack');
    }
}
