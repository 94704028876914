





















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CategoryHitViewObject } from '@/types/serverContract';
import ProductTileSlim from '@/project/products/ProductTileSlim.vue';

@Component({
    components: {
        ProductTileSlim
    }
})
export default class RecommendationsCategoriesSlider extends Vue {
    @Prop({
        required: true,
        type: Array
    }) recommendationCategories!: CategoryHitViewObject[];

    flickityOptions = {
        draggable: false,
        prevNextButtons: true,
        pageDots: false,
        contain: true,
        groupCells: true
    }
}
