

































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
    LineItemViewObject,
    NightDeliveryQualification,
    UnitViewObject,
    UpdateLineItemRequest,
    ScalePricePromotionInformationViewObject
} from '@/types/serverContract';
import {
    IUnitData,
    IFixedUnitValidationState
} from '@/project/products/productHelper.utils';
import LineItemQuantity from '@/project/basket/LineItemQuantity.vue';
import ScalePriceToPromote from '@/project/products/ScalePriceToPromote.vue';
import ProductOrderableUnit from '@/project/products/ProductOrderableUnit.vue';
import LineItemPrice from '@/project/basket/LineItemPrice.vue';
import { Prop } from 'vue-property-decorator';
import Constants from '@/project/config/constants';
import { FlexBoxGetter } from '@/store/flexBox';

@Component({
    components: {
        LineItemQuantity,
        ScalePriceToPromote,
        ProductOrderableUnit,
        LineItemPrice
    }
})
export default class LineItemDeliveryQuantityBox extends Vue {
    @Prop({
        type: Object
    }) lineItem!: LineItemViewObject;

    @Prop({
        required: true,
        type: Boolean
    }) addCertificate!: boolean;

    @Prop({
        type: String
    }) certificateCode!: string;

    @Prop({
        type: Number
    }) timeout!: number;

    @Prop({
        type: Boolean
    }) stamping!: boolean;

    @Prop({
        type: Boolean
    }) isSoldOut!: boolean;

    @Prop({
        type: Object
    }) basketLineItemPayload!: UpdateLineItemRequest;

    @Prop({
        type: Object
    }) selectedUnit!: UnitViewObject;

    @Prop({
        type: Number
    }) fixedUnitQty!: number | null;

    @Prop({
        type: Object
    }) fixedUnitValidationState!: IFixedUnitValidationState;

    @FlexBoxGetter isInFlexBoxContext!: boolean;

    currentFixedUnitQty: number | null = this.fixedUnitQty;
    currentTimeout: number | null = this.timeout;
    scalePriceToPromoteTimer: number = 10000;
    scalePriceToPromoteVisible: boolean = false;
    scalePriceToPromote: ScalePricePromotionInformationViewObject | null = null;

    get getThumbnailImage(): string {
        return this.lineItem && this.lineItem.thumbnailImage ? this.lineItem.thumbnailImage : Constants.ImageNotFound;
    }

    get onlyHasFixedOrderableUnit(): boolean {
        return this.selectedUnit.isFixedUnit && this.selectedUnit.fixedUnitQuantity && this.lineItem.orderableUnits.length === 1;
    }

    get hasOrderableUnits(): boolean {
        return !this.lineItem.isSpecialOrderLineItem && this.lineItem.orderableUnits && this.lineItem.orderableUnits.length > 1;
    }

    nightDeliveryQualificationInner(): string {
        if (this.nightDeliveryPossible()) {
            return 'sanicon-ico-moon-natlevering-filled inline-block mr-10';
        }

        return 'sanicon-ico-moon-locked-solid inline-block mr-10';
    }

    nightDeliveryQualificationOuter(): string {
        if (this.nightDeliveryPossible()) {
            return 'text-green-700';
        }

        return 'text-grey-500';
    }

    nightDeliveryPossible(): boolean {
        if (!this.lineItem.nightDeliveryPossible) { return false; }

        let nightDeliveryQualification = this.lineItem.nightDeliveryPossible.type;
        let nightDeliveryPossible = this.lineItem.nightDeliveryPossible.possible;

        if (nightDeliveryQualification === NightDeliveryQualification.Yes) {
            return true;
        } else if (nightDeliveryQualification === NightDeliveryQualification.Limited) {
            return nightDeliveryPossible;
        }

        return false;
    }

    showScalePriceToPromote(scalePriceToPromote: ScalePricePromotionInformationViewObject): void {
        this.scalePriceToPromote = scalePriceToPromote;
        this.scalePriceToPromoteVisible = true;

        if (this.currentTimeout) {
            clearTimeout(this.currentTimeout);
        }
        this.currentTimeout = setTimeout(() => {
            this.scalePriceToPromote = null;
            this.scalePriceToPromoteVisible = false;
        }, this.scalePriceToPromoteTimer);
    }

    closeScalePriceToPromote(): void {
        this.scalePriceToPromote = null;
    }

    updateCurrentUnit(unitData: IUnitData): void {
        this.$emit('updateOrderableUnit', unitData);
    }

    updateFixedUnitProduct(): void {
        if (this.fixedUnitValidationState.isValid) {
            this.$emit('update:quantity', this.lineItem.quantity);
        }
    }
}
