























































import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { StoreStockQuantityViewObject } from '@/types/serverContract';
import translateFilter from '@/core/translation/translate.filter';

@Component
export default class ProductStoreStockItem extends Vue {
    @Prop({
        type: Object
    }) storeStock!: StoreStockQuantityViewObject;

    get storeUnitCode(): string {
        return translateFilter(`unit.${this.storeStock.unitCode}`);
    }
}
