




































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProductSecurityDatasheetViewObject } from '@/types/serverContract';
import AddToFavoriteOrder from '@/project/favoriteOrders/AddToFavoriteOrder.vue';
import Constants from '@/project/config/constants';
import { IUnitData } from '@/project/products/productHelper.utils';

@Component({
    components: { AddToFavoriteOrder }
})
export default class SecurityDatasheetsListItem extends Vue {
    @Prop({
        required: true,
        type: Object
    }) productSecurityDatasheet!: ProductSecurityDatasheetViewObject;

    productUrl: string = this.productSecurityDatasheet.url;
    favoriteOrderOrderableUnits: IUnitData[] = [];

    get thumbnailImage(): string {
        return this.productSecurityDatasheet.thumbnailImage ? this.productSecurityDatasheet.thumbnailImage : Constants.ImageNotFound;
    }

    created() {
        this.setFavoriteOrderOrderableUnits();
    }

    setFavoriteOrderOrderableUnits(): void {
        this.productSecurityDatasheet.units.forEach(unit => {
            const unitData = {
                orderableUnit: unit,
                unitPayload: {
                    unit: unit.unitCode,
                    quantity: 1,
                    fixedUnitValue: unit.isFixedUnit ? unit.fixedUnitQuantity.orderableQuantity : null
                }
            } as IUnitData;
            this.favoriteOrderOrderableUnits.push(unitData);
        });
    }
}
