import { AxiosResponse } from 'axios';
import { BaseApiResponse } from '@/types/serverContract';
import { setBasket } from '@/store/basket';

const responseHandler = (response: AxiosResponse<BaseApiResponse<void>>) => {
    if (response.data && response.data.basket) {
        // Basket received
        setBasket(response.data.basket);
    }
    return response;
};

export {
    responseHandler
};
