







































































































import { Component, Prop } from 'vue-property-decorator';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import ImageTextButtonModuleInnerContent from '@/project/shared/modules/ImageTextButtonModuleInnerContent.vue';
import ImageTextButtonModuleHorizontalSplit from '@/project/shared/modules/ImageTextButtonModuleHorizontalSplit.vue';
import ImageTextButtonModuleVerticalSplit from '@/project/shared/modules/ImageTextButtonModuleVerticalSplit.vue';
import { Mixins } from 'vue-mixin-decorator/lib/mixins';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoints.mixin';
import imageService from '@/core/image.service';
import { IPromoActionField } from '@/core/tracking/promotionTracking.g4-structure';
import promotionTrackingService from '@/core/tracking/promotionTracking.service';
import { PROMO_TRACKING_EVENT } from '@/core/enums/enums';

@Component({
    components: {
        ImageTextButtonModuleInnerContent,
        ImageTextButtonModuleHorizontalSplit,
        ImageTextButtonModuleVerticalSplit
    }
})
export default class ImageTextButtonModule extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @Prop({
        default: '',
        type: String
    }) preheader!: string;

    @Prop({
        default: '',
        type: String
    }) header!: string;

    @Prop({
        default: '',
        type: String
    }) subheader!: string;

    @Prop({
        default: '',
        type: String
    }) textColor!: string;

    @Prop({
        default: '',
        type: String
    }) textBackgroundColor!: string;

    @Prop({
        type: String,
        default: 'Left',
        validator: (value: string) => ['Left', 'Right'].indexOf(value) > -1
    }) textAlignment!: string;

    @Prop({
        default: '',
        type: String
    }) buttonLink!: string;

    @Prop({
        default: '_self',
        type: String
    }) buttonLinkTarget!: string;

    @Prop({
        default: '',
        type: String
    }) buttonText!: string;

    @Prop({
        default: '',
        type: String
    }) buttonColor!: string;

    @Prop({
        type: String,
        default: 'Left',
        validator: (value: string) => ['Left', 'Right'].indexOf(value) > -1
    }) buttonAlignment!: string;

    @Prop({
        default: '',
        type: String
    }) image!: string;

    @Prop({
        type: String
    }) imageLink!: string;

    @Prop({
        default: '_self',
        type: String
    }) imageLinkTarget!: string;

    @Prop({
        type: String,
        default: 'None',
        validator: (value: string) => ['None', 'Horizontal', 'Vertical'].indexOf(value) > -1
    }) splitViewAlignment!: string;

    @Prop({
        type: Boolean,
        default: false
    }) halfHeight!: boolean;

    @Prop({
        default: '',
        type: String
    }) audience!: string;

    whiteColor: string = '#FFFFFF';
    defaultButtonTextColor: string = '#0C365C';

    get useBackgroundColorForContainer(): boolean {
        return isNotNullEmptyOrUndefined(this.textBackgroundColor) && !isNotNullEmptyOrUndefined(this.image);
    }

    get isHorizontalSplit(): boolean {
        return isNotNullEmptyOrUndefined(this.image) && this.splitViewAlignment === 'Horizontal';
    }

    get isVerticalSplit(): boolean {
        return isNotNullEmptyOrUndefined(this.image) && this.splitViewAlignment === 'Vertical';
    }

    get imageClasses(): string {
        return 'bg-center bg-cover bg-no-repeat';
    }

    get heightClass(): string {
        if (this.halfHeight) {
            return 'h-250 lg:h-200';
        } else {
            return 'min-h-200 md:h-500 lg:h-400';
        }
    }

    get buttonTextColor(): string {
        if ('#' + this.buttonColor === this.whiteColor) {
            return this.defaultButtonTextColor;
        } else {
            return this.whiteColor;
        }
    }

    get styleForWrapper(): Partial<CSSStyleDeclaration> | null {
        if (this.useBackgroundColorForContainer) {
            return {
                backgroundColor: `#${this.textBackgroundColor}`
            };
        } else {
            return {
                backgroundImage: `url('${this.getWebpCompatibleOptimizedSource}')`
            };
        }
    }

    get getWebpCompatibleOptimizedSource(): string {
        return imageService.getWebpCompatibleOptimizedSource(this.image);
    }

    trackPromotionView(): void {
        const id = `${this.audience}${this.header}|frontpage|${this.image}`;
        const name = `${this.header}_${this.image}`;
        const creative = this.buttonText === '' ? undefined : this.buttonText;

        const actionField: IPromoActionField = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_id: id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_name: name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            creative_name: creative
        };

        promotionTrackingService.TrackPromotion(
            PROMO_TRACKING_EVENT.PromotionImpression,
            actionField
        );
    }

    trackPromotionClick(): void {
        const id = `${this.audience}${this.header}|frontpage|${this.image}`;
        const name = `${this.header}_${this.image}`;
        const creative = this.buttonText === '' ? undefined : this.buttonText;

        const actionField: IPromoActionField = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_id: id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            promotion_name: name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            creative_name: creative
        };

        promotionTrackingService.TrackPromotion(
            PROMO_TRACKING_EVENT.PromotionClick,
            actionField
        );
    }
}
