import log from '@/core/logging.service';
import { router } from '@/router';
import RedirectException from '@/core/http/redirectException';

export const ServerRedirectActive = '__ServerRedirect__';

function hardReload() {
    log.debug('Hard reload');
    window.location.reload();
}

function softRedirect(url: string) {
    log.debug(`Soft redirect. Url: ${url}`);
    // Set a unique query in order to be able to soft-redirect to same path and get it picked up by SpaServer
    const query = { [ServerRedirectActive]: 'true' };
    router.replace({ path: url, query: query });
}

function hardRedirect(url: string) {
    log.debug(`Hard redirect. Url: ${url}`);
    window.location.href = url;
}

export default response => {
    const shouldRedirect = response.headers.clientredirect === 'True';
    const redirectData = response.data;
    if (shouldRedirect && redirectData) {
        const redirectUrl = redirectData.clientRedirectUrl;
        if (redirectUrl) {
            redirectData.reloadFromServer ? hardRedirect(redirectUrl) : softRedirect(redirectUrl);
        } else if (redirectData.reloadCurrentPage) {
            hardReload();
        }
        // Payload should not be used. This will be treated special
        /*
        * This Promise.reject makes a error line in the browser console in dev.
        * We will however allow this since it also ensures that faulty data does not reach the appStore, which gives errors.
        * The error is that the appStore then will receive content of type: RedirectResponse and not PageDataViewModel on serverContext.PageData.
        * The alternative would be to introduce a large amount of fallback values that might cause freeze/non-updating values in @/store/app/index.ts.
        * */
        return Promise.reject(new RedirectException());
    }
    return response;
};
