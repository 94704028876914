import { AxiosRequestConfig, AxiosResponse } from 'axios';
import bus from '../bus';
import { BaseApiResponse, ClientMessage } from '@/types/serverContract';
import { addApiMessages } from '@/store/messages';

export const ValidationErrorEventKey = 'ValidationError';

const responseHandler = (response: AxiosResponse<BaseApiResponse<void>>) => {
    if (response.data && response.data.clientMessage) {
        // Validation-messages received. If a messagesId is included (from a post) forward messages to local handler
        // If no messagesId or no one wanted to handle show globally.
        const messagesId: string | undefined = response.config ? (response.config as any).messagesId : undefined;
        let isHandled = false;
        const messages = response.data.clientMessage.messages;
        if (messagesId) {
            bus.emit(ValidationErrorEventKey, {
                messagesId,
                messages: messages,
                handled: () => (isHandled = true)
            } as ValidationErrorEvent);
        }

        if (!isHandled) {
            addApiMessages(messages);
        }
    }
    return response;
};

const requestHandler = (config: AxiosRequestConfig): AxiosRequestConfig => {
    // Clear potential previous message (for instance during login attempts)
    const messagesId: string | undefined = (config as any).messagesId;
    if (messagesId) {
        bus.emit(ValidationErrorEventKey, {
            messagesId,
            messages: []
        } as ValidationErrorEvent);
    }
    return config;
};

export interface ValidationErrorEvent {
    handled?: () => void;
    messages: ClientMessage[];
    messagesId: string;
}

export {
    requestHandler,
    responseHandler
};
