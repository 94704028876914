

















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SearchResult from '@/project/search/SearchResult.vue';

@Component({
    components: {
        SearchResult
    }
})
export default class WebOffers extends Vue {
}
