import httpService from '@/core/http/http.service';
import {
    SearchUsersRequest,
    SearchUsersViewModel,
    ImpersonationRequest,
    SearchSalesAccountsViewModel,
    FavoriteSalesAccountsViewModel,
    AddFavoriteAccountRequest,
    UserAgreementsViewModel,
    UserAgreementsRequest,
    MoveFavoriteAccountRequest,
    SearchSalesAccountsRequest,
    AssignedAccountsToUserRequest,
    AssignedSalesAccountsToUserViewModel,
    AssignUserToSalesAccountRequest,
    GetAccountsForUpdateAllowNewUsersRequest,
    HelpDeskAccountAdministrationListViewModel,
    GetAccountForUpdateAllowNewUsersRequest,
    HelpDeskAccountAdministrationViewModel,
    UpdateAllowNewUsersOnAccountRequest,
    AccountImpersonationRequest,
    HelpDeskProductCatalogListViewModel,
    ProductCatalogImportResultViewModel,
    GetProductCatalogsRequest,
    AddProjectFolderItemLinkRequest,
    ProjectFolderLinkListViewModel,
    GetProjectFolderItemsRequest,
    CreateUserRequest,
    NewMainAccountAndNewUserEmailForAccountRequest
} from '@/types/serverContract';

export default class HelpDeskController {
    public async searchUsers(payload: SearchUsersRequest): Promise<SearchUsersViewModel> {
        return httpService.get<SearchUsersViewModel>('helpDeskApi/searchUsers', payload);
    }

    public async NewMainAccountAndNewUserEmailForAccount(payload: NewMainAccountAndNewUserEmailForAccountRequest): Promise<void> {
        return httpService.post<void>('helpDeskApi/NewMainAccountAndNewUserEmailForAccount', payload);
    }

    public async startImpersonation(payload: ImpersonationRequest): Promise<void> {
        return httpService.post<void>('helpDeskApi/startImpersonation', payload);
    }

    public async stopImpersonation(): Promise<void> {
        return httpService.post<void>('helpDeskApi/stopImpersonation');
    }

    public async favoriteSalesAccounts(): Promise<FavoriteSalesAccountsViewModel> {
        return httpService.get<FavoriteSalesAccountsViewModel>('helpDeskApi/FavoriteSalesAccounts');
    }

    public async favoriteSalesAccount(payload: AddFavoriteAccountRequest): Promise<FavoriteSalesAccountsViewModel> {
        return httpService.post<FavoriteSalesAccountsViewModel>('helpDeskApi/FavoriteSalesAccount', payload);
    }

    public async deleteFavoriteSalesAccount(id: number): Promise<FavoriteSalesAccountsViewModel> {
        return httpService.delete<FavoriteSalesAccountsViewModel>('helpDeskApi/FavoriteSalesAccount/' + id);
    }

    public async moveFavoriteSalesAccount(payload: MoveFavoriteAccountRequest): Promise<FavoriteSalesAccountsViewModel> {
        return httpService.put<FavoriteSalesAccountsViewModel>('helpDeskApi/MoveFavoriteSalesAccount', payload);
    }

    public async salesAccountsAssignedToUser(payload: AssignedAccountsToUserRequest): Promise<AssignedSalesAccountsToUserViewModel> {
        return httpService.get<AssignedSalesAccountsToUserViewModel>('helpDeskApi/SalesAccountsAssignedToUser', payload);
    }

    public async assignUserToSalesAccount(payload: AssignUserToSalesAccountRequest): Promise<AssignedSalesAccountsToUserViewModel> {
        return httpService.post<AssignedSalesAccountsToUserViewModel>('helpDeskApi/AssignUserToSalesAccount', payload);
    }

    public async userAgreements(payload: UserAgreementsRequest): Promise<UserAgreementsViewModel> {
        return httpService.get<UserAgreementsViewModel>('helpDeskApi/userAgreements', payload);
    }

    public async searchSalesAccounts(payload: SearchSalesAccountsRequest): Promise<SearchSalesAccountsViewModel> {
        return httpService.get<SearchSalesAccountsViewModel>('helpDeskApi/searchSalesAccounts', payload);
    }

    public async createUser(payLoad: CreateUserRequest, messagesId: string): Promise<void> {
        return httpService.post<void>('helpDeskApi/createUser', payLoad as CreateUserRequest, messagesId);
    }

    public async accountForUpdateAllowNewUsers(payload: GetAccountForUpdateAllowNewUsersRequest): Promise<HelpDeskAccountAdministrationViewModel> {
        return httpService.get<HelpDeskAccountAdministrationViewModel>('helpDeskApi/AccountForUpdateAllowNewUsers', payload);
    }

    public async accountsForUpdateAllowNewUsers(payload: GetAccountsForUpdateAllowNewUsersRequest): Promise<HelpDeskAccountAdministrationListViewModel> {
        return httpService.get<HelpDeskAccountAdministrationListViewModel>('helpDeskApi/AccountsForUpdateAllowNewUsers', payload);
    }

    public async updateAllowNewUsersOnAccount(payload: UpdateAllowNewUsersOnAccountRequest): Promise<void> {
        return httpService.post<void>('helpDeskApi/UpdateAllowNewUsersOnAccount', payload);
    }

    public async startAccountImpersonation(payload: AccountImpersonationRequest): Promise<void> {
        return httpService.post<void>('helpDeskApi/startAccountImpersonation', payload);
    }

    public async getProductCatalogs(payload: GetProductCatalogsRequest): Promise<HelpDeskProductCatalogListViewModel> {
        return httpService.get<HelpDeskProductCatalogListViewModel>('helpDeskApi/productCatalogs', payload);
    }

    public async uploadProductCatalog(payload: any): Promise<ProductCatalogImportResultViewModel> {
        return httpService.post<ProductCatalogImportResultViewModel>('helpDeskApi/uploadProductCatalog', payload);
    }

    public async deleteProductCatalog(id: number): Promise<void> {
        return httpService.delete<void>('helpDeskApi/productCatalog/' + id);
    }

    public async addProjectFolderItemLink(payload: AddProjectFolderItemLinkRequest): Promise<ProjectFolderLinkListViewModel> {
        return httpService.post<ProjectFolderLinkListViewModel>('helpDeskApi/addProjectFolderItemLink', payload);
    }

    public async getProjectFolderItems(payload: GetProjectFolderItemsRequest): Promise<ProjectFolderLinkListViewModel> {
        return httpService.get<ProjectFolderLinkListViewModel>('helpDeskApi/projectFolderItems', payload);
    }

    public async uploadProjectFolderItem(payload: any): Promise<ProjectFolderLinkListViewModel> {
        return httpService.post<ProjectFolderLinkListViewModel>('helpDeskApi/uploadProjectFolderItem', payload);
    }

    public async deleteProjectFolderItem(id: number): Promise<void> {
        return httpService.delete<void>('helpDeskApi/projectFolderItem/' + id);
    }
}
