
































































import Vue from 'vue';
import Component from 'vue-class-component';
import serverContext from '@/core/serverContext.service';
import CheckoutSteps from '@/project/checkout/CheckoutSteps.vue';
import CheckoutAccount from '@/project/checkout/CheckoutAccount.vue';
import SiteSvgLogo from '@/project/navigation/page-header/SiteSvgLogo.vue';
import { LoginInformation, SitePage } from '@/types/serverContract';
import { CheckoutGetter, CheckoutAction } from '@/store/checkout';
import ApiMessages from '@/project/messages/ApiMessages.vue';
import { AppGetter } from '@/store/app';

@Component({
    components: {
        ApiMessages,
        CheckoutSteps,
        CheckoutAccount,
        SiteSvgLogo
    }
})
export default class CheckoutHeader extends Vue {
    @AppGetter loginInformation!: LoginInformation;
    @AppGetter hasOpenModal!: boolean;
    @CheckoutGetter isOrderApproved!: boolean;
    @CheckoutGetter isCommentValid!: boolean;
    @CheckoutGetter isOrderSubmitting!: boolean;
    @CheckoutAction setOrderSubmitting!: (payload: boolean) => void;

    get frontpage(): SitePage {
        return serverContext.sitePages.frontPage;
    }

    submitOrder() {
        if (
            this.isOrderSubmitting ||
            !this.isOrderApproved ||
            !this.isCommentValid ||
            !this.loginInformation.canSubmitOrder
        ) {
            return;
        }
        this.setOrderSubmitting(true);
    }
}
