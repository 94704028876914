























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ProductTileSkeletonPrice from '@/project/shared/skeleton/ProductTile/ProductTileSkeletonPrice.vue';
import ProductTileSkeletonAddToBasket from '@/project/shared/skeleton/ProductTile/ProductTileSkeletonAddToBasket.vue';

@Component({
    components: { ProductTileSkeletonAddToBasket, ProductTileSkeletonPrice }
})
export default class ProductTileSkeleton extends Vue {
}
