





























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
    VariantDetailsViewObject
} from '@/types/serverContract';

@Component
export default class ProductDetailsSpecs extends Vue {
    @Prop({
        required: true,
        type: Object
    }) selectedVariant!: VariantDetailsViewObject;

    flickityOptions = {
        imagesLoaded: true,
        prevNextButtons: this.hasMultipleImages,
        pageDots: this.hasMultipleImages
    };

    get hasMultipleImages(): boolean {
        return this.selectedVariant.drawings && this.selectedVariant.drawings.length > 1;
    }

    onChange(index: number): void {
        this.$emit('sliderChange', index);
    }

    documentIcon(documentType: string): string {
        if (documentType === 'Image') {
            return 'sanicon-ico-camera';
        } else if (documentType === 'Pdf') {
            return 'sanicon-ico-document-pdf';
        }
        return 'sanicon-ico-document-text';
    }
}
