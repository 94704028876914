
import Vue from 'vue';
import DomService from '@/core/dom.service';
import { Component, Watch } from 'vue-property-decorator';
import { AppAction, AppGetter } from '@/store/app';
import { MetadataViewModel, RaptorPageData } from '@/types/serverContract';
import { router } from '@/router';
import { ServerRedirectActive } from '@/core/http/redirect.interceptor';
import { Route } from 'vue-router';
import tracking from '@/core/tracking/tracking.service';
import raptorTracking from '@/core/tracking/raptorTracking.service';

@Component
export default class SpaServer extends Vue {
    @AppGetter private metadata!: MetadataViewModel | undefined;
    @AppGetter private raptorPageData!: RaptorPageData | undefined;
    @AppGetter private isCheckoutView!: boolean;
    @AppGetter private isNotFound!: boolean;
    @AppAction private fetchPage!: (route: string) => void;

    private mounted() {
        this.preventAutoScroll();
    }

    @Watch('$route')
    private onRouteChange(route: Route, oldRoute: Route) {
        if (route.query[ServerRedirectActive]) {
            // Remove redirect query (from soft redirect).
            const cleanedQuery = { ...route.query };
            delete cleanedQuery[ServerRedirectActive];
            router.replace({ query: cleanedQuery });
            this.fetchPage(route.path);
            return;
        }

        if (route.path === oldRoute.path) {
            // Facet change for instance - dont reload
            return;
        }

        this.fetchPage(route.fullPath);
    }

    @Watch('metadata')
    private onMetadataChange(metadata: MetadataViewModel) {
        if (metadata) {
            DomService.updateMetaData(metadata);
            tracking.trackPageView(this.isNotFound);
        }
    }

    @Watch('raptorPageData')
    private onRaptorPageData(raptorPageData: RaptorPageData) {
        if (raptorPageData) {
            raptorTracking.trackPageVisit();
        }
    }

    @Watch('isCheckoutView')
    private onIsCheckoutViewChange(isCheckoutView: boolean) {
        DomService.updateCheckoutView(isCheckoutView);
    }

    private preventAutoScroll() {
        if ('scrollRestoration' in history) {
            try {
                history.scrollRestoration = 'manual';
            } catch (e) {
                // Ok...
            }
        }
    }

    private render() {
        return this.$slots.default![0];
    }
}
