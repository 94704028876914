












































import Vue from 'vue';
import Component from 'vue-class-component';
import { ArticlePromotionTileViewObject } from '@/types/serverContract';
import { Prop } from 'vue-property-decorator';
import ArticlePromotionTile, {
    TileSizeData
} from '@/components/organisms/ArticlePromotionTile/ArticlePromotionTile.vue';
import { BACKGROUND_COLORS_UNITY, COLORS_AHLSELL_BLUE } from '@/core/enums/productTiles';
import breakpointService from '@/core/responsive/breakpoints/breakpoints.service';
import { isNullOrWhitespace } from '@/project/config/utilities';
import { AHLSELL_THEME, BRIGHTNESS_MODE } from '@/core/enums/enums';

@Component({
    components: {
        ArticlePromotionTile
    }
})
export default class ArticlePromotionCarousel extends Vue {
    @Prop({ type: Array }) articlePromotionTiles!: ArticlePromotionTileViewObject[];
    @Prop({ type: String }) colorTheme!: AHLSELL_THEME;
    @Prop({ type: String }) arrowColor!: BRIGHTNESS_MODE;

    isHovered: boolean = false;
    fitTiles: ArticlePromotionTileViewObject[];
    tileMinWidth: number = 390;
    tileGap: number = 24;
    maxWidthExceeded: null | boolean = null;

    get showCarousel() {
        return this.fitTiles.length >= 2;
    }

    created() {
        this.prepareFitTiles();
    }

    mounted() {
        if (this.showCarousel) {
            this.updateMaxWidthExceeded();
        }
    }

    onMouseEnter() {
        this.isHovered = true;
    }

    onMouseExit() {
        this.isHovered = false;
    }

    prepareFitTiles() {
        this.fitTiles = this.articlePromotionTiles.filter(tile => this.hasRequiredData(tile));
    }

    hasRequiredData(tile: ArticlePromotionTileViewObject) {
        return tile && tile.header && tile.link.url !== '' && tile.image;
    }

    updateMaxWidthExceeded() {
        if (breakpointService.isActiveBreakpoint('xs, sm')) {
            this.tileMinWidth = 230;
            this.tileGap = 8;
        } else {
            this.tileMinWidth = 390;
            this.tileGap = 24;
        }
        let expectedCarouselSize = this.fitTiles.length * this.tileMinWidth;
        let carousel = this.$refs.articlePromotionCarousel as HTMLDivElement;
        let maxCarouselSize = carousel.clientWidth;
        this.maxWidthExceeded = expectedCarouselSize > maxCarouselSize;
        if (this.maxWidthExceeded) {
            this.isHovered = false;
        } else {
            carousel.style.setProperty('--gap', this.tileGap.toString() + 'px');
        }
    }

    getTileSizeData(index: number) {
        return new TileSizeData(index, this.tileMinWidth, this.tileGap);
    }

    getTileColor(i: number) {
        switch (this.colorTheme) {
            case AHLSELL_THEME.AhlsellUnity: {
                const colors = Object.values(BACKGROUND_COLORS_UNITY);
                return colors[i % colors.length];
            }
            case AHLSELL_THEME.AhlsellBlue: {
                const colors = Object.values(COLORS_AHLSELL_BLUE).slice(0, 3);
                return 'bg-' + colors[i % colors.length];
            }
            case AHLSELL_THEME.Custom: {
                if (isNullOrWhitespace(this.fitTiles[i].backgroundColor.toUpperCase())) {
                    return 'bg-' + COLORS_AHLSELL_BLUE.AHLSELL_15;
                }
                let key = '#' + this.fitTiles[i].backgroundColor.toUpperCase();
                return 'bg-' + this.$helper.hexToColor[key];
            }
            default: {
                return 'bg-' + COLORS_AHLSELL_BLUE.AHLSELL_15;
            }
        }
    }
}
