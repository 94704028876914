export enum VideoPlatform {
    Vimeo = 'vimeo',
    Youtube = 'youtube'
}

// Youtube examples: https://www.youtube.com/watch?v=fCtpnw7tncs - https://youtu.be/oWzc8gZb634 - https://www.youtube.com/embed/oWzc8gZb634
const youtubePattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
// Vimeo examples: https://vimeo.com/777755005 or https://player.vimeo.com/video/777755005?h=5ae9871801
const vimeoPattern = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i;

function youtubeSrc(videoId: string): string {
    return `//www.youtube.com/embed/${videoId}`;
}

function vimeoSrc(videoId: string): string {
    return `//player.vimeo.com/video/${videoId}?portrait=0`;
}

export const isYoutube = (rawUrl: string): boolean => {
    return youtubePattern.test(rawUrl);
};

export const isVimeo = (rawUrl: string): boolean => {
    return vimeoPattern.test(rawUrl);
};

export const getVideoPlatform = (videoURL: string): 'youtube' | 'vimeo' | 'unknown' => {
    switch (true) {
        case youtubePattern.test(videoURL):
            return VideoPlatform.Youtube;
        case vimeoPattern.test(videoURL):
            return VideoPlatform.Vimeo;
        default:
            return 'unknown';
    }
};

export const getVideoSrc = (rawUrl: string): string => {
    const vimeoMatch = rawUrl.match(vimeoPattern);
    const youtubeMatch = rawUrl.match(youtubePattern);

    if (youtubeMatch && youtubeMatch[2]) {
        return youtubeSrc(youtubeMatch[2]);
    }

    if (vimeoMatch && vimeoMatch[1]) {
        return vimeoSrc(vimeoMatch[1]);
    }

    return rawUrl;
};

export const getVideoId = (rawUrl: string): string => {
    const vimeoMatch = rawUrl.match(vimeoPattern);
    const youtubeMatch = rawUrl.match(youtubePattern);

    if (youtubeMatch && youtubeMatch[2]) {
        return youtubeMatch[2];
    }

    if (vimeoMatch && vimeoMatch[1]) {
        return vimeoMatch[1];
    }

    return rawUrl;
};
