






















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import Api from '@/project/http/api';
import {
    EasySupplyWarehouseDetailImportLineErrorViewObject,
    EasySupplyWarehouseDetailImportResultViewModel
} from '@/types/serverContract';

@Component({
    components: { ButtonSubmit }
})
export default class EasySupplyImportFromFile extends Vue {
    @Prop({
        required: true,
        type: Number
    }) warehouseId!: number;

    fileName: string | null = null;
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    file: File | null = null;
    importResult: EasySupplyWarehouseDetailImportResultViewModel | null = null;
    importResultErrors: EasySupplyWarehouseDetailImportLineErrorViewObject[] = [];
    generalImportErrorMessage: string = '';
    importResultTimeout: number = 0;

    get fileImportDesc(): string {
        return this.$tr(this.$labels.Views.Services.EasySupply.Edit.FileImportDescription);
    }

    get fileImportDesc2(): string {
        return this.$tr(this.$labels.Views.Services.EasySupply.Edit.FileImportDescription2);
    }

    get uploadLabel(): string {
        return this.$tr(this.$labels.Views.Services.EasySupply.Edit.File);
    }

    $refs!: {
        importCSVFile: HTMLInputElement;
    };

    destroyed() {
        if (this.importResultTimeout) {
            clearTimeout(this.importResultTimeout);
        }
    }

    async submitForm() {
        this.pending = true;

        try {
            const formData = new FormData();
            formData.append('file', this.file!);
            formData.append('id', this.warehouseId.toString());
            await Api.easySupply.importEasySupplyFromCsv(formData)
                .then(correlationId => {
                    this.importEasySupplyFromCsvResult(correlationId);
                });
        } finally { }
    }

    async importEasySupplyFromCsvResult(correlationId: string): Promise<void> {
        if (correlationId !== '' && this.importResult === null) {
            try {
                const result = await Api.easySupply.importEasySupplyFromCsvResult(correlationId);
                if (result) {
                    this.importResult = result;
                    if (result.generalImportErrorMessage !== '') {
                        this.generalImportErrorMessage = result.generalImportErrorMessage;
                    }
                    if (result.errors && result.errors.length > 0) {
                        this.importResultErrors = result.errors;
                    } else {
                        this.$emit('closeImportModal');
                    }

                    this.$emit('updatedWarehouse');
                    this.showSuccessIndicator();
                    return;
                }
            } catch (e) {
                this.pending = false;
                return;
            } finally { }

            this.importResultTimeout = setTimeout(() => this.importEasySupplyFromCsvResult(correlationId), 3000);
        }
    }

    onFileChange(): void {
        this.$validator.validate();
        this.file = (this.$refs.importCSVFile as HTMLInputElement).files![0];
        this.fileName = this.file.name;
        this.importResultErrors = [];
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors!: any;
}
