import httpService from '@/core/http/http.service';
import {
    GetSynonymByIdRequest,
    SynonymDetailedViewModel,
    SynonymListViewModel,
    CreateSynonymRequest,
    UpdateSynonymRequest
} from '@/types/serverContract';

export default class SearchManagementController {
    public async getSynonymById(payload: GetSynonymByIdRequest): Promise<SynonymDetailedViewModel> {
        return httpService.get<SynonymDetailedViewModel>('searchManagementApi/GetSynonymById', payload);
    }

    public async getAllSynonyms(): Promise<SynonymListViewModel> {
        return httpService.get<SynonymListViewModel>('searchManagementApi/GetAllSynonyms');
    }

    public async createSynonym(payload: CreateSynonymRequest): Promise<void> {
        return httpService.post<void>('searchManagementApi/CreateSynonym', payload);
    }

    public async updateSynonym(payload: UpdateSynonymRequest): Promise<void> {
        return httpService.put<void>('searchManagementApi/UpdateSynonym', payload);
    }

    public async getPossibleDuplicates(synonym: string): Promise<SynonymListViewModel> {
        return httpService.get<SynonymListViewModel>('searchManagementApi/GetPossibleDuplicates', { synonym });
    }

    public async deleteSynonym(id: number): Promise<void> {
        return httpService.delete<void>('searchManagementApi/DeleteSynonym/' + id);
    }
}
