









































import { Component, Prop } from 'vue-property-decorator';
import InputCtrl from './InputCtrl.vue';
import InputCtrlData from './InputCtrlData';
import { find } from 'lodash';

@Component({
    components: { InputCtrl }
})
export default class DropdownInputCtrl extends InputCtrlData {
    @Prop({ default: true, type: Boolean }) ignoreLastPass!: boolean;
    @Prop({ required: false, type: [String, Object] }) value!: string | object;
    @Prop({ default: '', type: String }) inputClass!: string; // Optional styling classes for the input field
    @Prop({ type: Array, required: true }) public options!: string[] | object[];
    @Prop({ type: String, default: '' }) idKey!: string; // If options and v-bind value is objects, this is the property that selects the id.

    getOption(option: string | object) {
        return this.idKey ? option[this.idKey] : option;
    }

    emitChange(event) {
        const newValue = this.idKey ? find(this.options, o => o[this.idKey].toString() === event.target.value) : event.target.value;
        this.$emit('input', newValue);
        this.$emit('change', event);

        this.$validator.validate(this.name);
    }

    private stringValue(option: string | object): string {
        if (this.idKey) {
            return option ? option[this.idKey] : null;
        }
        return option as string;
    }

    get getValue() {
        return this.stringValue(this.value);
    }
}
