















































































































import { Mixins, Prop } from 'vue-property-decorator';
import { PriceViewObject } from '@/types/serverContract';
import Component from 'vue-class-component';
import ProductInventory from '@/project/products/ProductInventory.vue';
import CopyTooltip from '@/project/tooltip/copyTooltip.vue';
import {
    PriceServiceViewObject,
    PriceServiceMixin
} from '@/project/products/PriceServiceMixin.mixin';

@Component({
    components: {
        ProductInventory,
        CopyTooltip
    }
})
export default class ProductPrices extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({ type: Object, required: true }) currentPrice!: PriceViewObject;
    @Prop({ type: Boolean, default: false }) hideStockStatus!: boolean;
    @Prop({ type: Boolean, default: false }) hideUnit!: boolean;
    @Prop({ type: String, default: 'text-18' }) priceSize!: string;
    @Prop({ type: String, default: 'text-15' }) unitSize!: string;
    @Prop({ type: Boolean, required: true }) isStockedItem!: boolean;
    @Prop({ default: false, type: Boolean }) slimProductTile!: boolean;
    @Prop({ default: false, type: Boolean }) compact!: boolean;
    @Prop({ default: false, type: Boolean }) showScalePrices!: boolean;

    price: PriceServiceViewObject = {
        netPrice: this.currentPrice.netPrice,
        grossPrice: this.currentPrice.grossPrice
    };

    get showUnit(): boolean {
        return this.currentPrice.stockStatus.inventory && !this.hideUnit;
    }

    get hasDiscount(): boolean {
        return this.shouldShowNetPrice && (this.currentPrice.netPrice !== this.currentPrice.grossPrice);
    }
}
