import Vue from 'vue';
import VeeValidate from 'vee-validate';
import da from 'vee-validate/dist/locale/da';

const validateConfig = {
    locale: 'da',
    errorBagName: 'vvErrors',
    fieldsBagName: 'vvFields'
};

VeeValidate.Validator.extend('phoneNumber', {
    validate: value => {
        return value.length <= 16 && ((value.startsWith('+') && !isNaN(value.substring(0))) || !isNaN(value));
    }
});

Vue.use(VeeValidate, validateConfig);

// Todo - dynamic lang

/* eslint-disable no-template-curly-in-string */
const dictionary = {
    messages: {
        ...da.messages
    }
};
/* eslint-enable */

VeeValidate.Validator.localize('da', dictionary);
