

































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { uuid } from '@/project/config/utilities';

@Component({
    inject: ['$validator']
})
export default class TextArea extends Vue {
    @Prop({ type: String, required: true })
    public label!: string;

    @Prop({ type: String, required: true })
    public value!: string;

    @Prop({ type: String, required: true })
    public name!: string;

    @Prop(String) public constraints!: string;

    private get error() {
        return this.vvErrors.first(this.name);
    }

    private hasValue(value: any) {
        return value !== '' && value != null;
    }

    private get field() {
        return this.vvFields[this.name];
    }

    public onInput(ev) {
        this.$emit('input', ev.target.value);

        if (this.field.validated && this.field.invalid) {
            this.validate();
        }
    }

    private onBlur(ev) {
        this.$emit('blur', ev);

        if (this.field.dirty) {
            this.validate();
        }
    }

    private get id() {
        return this.name + uuid();
    }

    private validate() {
        this.$validator.validate(this.name);
    }

    private get listeners() {
        const { input, blur, ...listeners } = this.$listeners; // eslint-disable-line @typescript-eslint/no-unused-vars
        return listeners;
    }

    // From Vue-validate
    private vvErrors!: any;

    // From Vue-validate
    private vvFields!: any;
}
