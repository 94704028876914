import httpService from '@/core/http/http.service';
import {
    DataPolicyRequest,
    DataPolicyResponse
} from '@/types/serverContract';

export default class DataPolicyController {
    public async getDataPolicyStatus(): Promise<DataPolicyResponse> {
        return httpService.get<DataPolicyResponse>('dataPolicyApi/get');
    }

    public async setPolicy(payload: DataPolicyRequest): Promise<void> {
        return httpService.post<void>('dataPolicyApi/setPolicy', payload);
    }
}
