export class Label {
    name: string;
    value: string;

    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }
}
export class EmptyLabel {
    name: string;
    value: string;

    constructor() {
        this.name = '';
        this.value = '';
    }
}
