














































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HelpDeskUserViewObject, UserAgreementsRequest, UserAgreementsViewModel } from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: {
        SpinnerOverlay
    }
})
export default class HelpDeskCheckApprovedAgreements extends Vue {
    @Prop({
        required: true,
        type: Object
    }) selectedUser!: HelpDeskUserViewObject;

    pending: boolean = false;
    userAgreements: UserAgreementsViewModel | null = null;

    created(): void {
        this.getUserAgreements();
    }

    async getUserAgreements(): Promise<void> {
        this.pending = true;

        try {
            const payload: UserAgreementsRequest = {
                userId: this.selectedUser.id
            };

            this.userAgreements = await Api.helpDesk.userAgreements(payload);
        } finally {
            this.pending = false;
        }
    }

    goBack(): void {
        this.$emit('goBack');
    }
}
