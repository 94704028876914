







import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ContentDisplay extends Vue {
    @Prop({ type: String, default: '' }) content!: string;

    get htmlify() {
        if (/<\/?[a-z][\s\S]*>/i.test(this.content)) {
            return this.content;
        }
        return `<p>${this.content.replace(/\n/g, '</p><p>')}</p>`;
    }
}
