























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { AppGetter, AppAction } from '@/store/app';
import {
    AgreementViewModel,
    LoginInformation,
    SalesAccountsGroupViewObject,
    SalesAccountViewObject
} from '@/types/serverContract';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import CurrentAccountInfo from '@/project/authentication/CurrentAccountInfo.vue';
import { FlexBoxGetter, FlexBoxAction } from '@/store/flexBox';
import tracking from '@/core/tracking/tracking.service';

@Component({
    components: {
        CurrentAccountInfo,
        SpinnerOverlay
    }
})
export default class SelectAccountPanel extends Vue {
    @AppGetter loginInformation!: LoginInformation;
    @AppGetter isPunchoutMode!: boolean;
    @AppAction setNonAcceptedAgreement!: (nonAcceptedAgreement: AgreementViewModel) => void;

    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @FlexBoxGetter flexBoxPage!: string;
    @FlexBoxGetter flexBoxInfoText!: string;
    @FlexBoxGetter flexBoxButtonText!: string;
    @FlexBoxAction setShowFlexBoxModal!: (show: boolean) => void;
    @FlexBoxAction setChangeToAccount!: (changeToAccount: string) => void;

    showSpinner = false;

    get accounts(): SalesAccountsGroupViewObject[] {
        return this.loginInformation != null ? this.loginInformation.accounts : [];
    }

    async changeAccount(account: SalesAccountViewObject) {
        try {
            this.showSpinner = true;
            await Api.authentication.changeAccount(account.soldToPartnerId);
        } catch (error) {
            if (error) {
                this.showSpinner = false;
            }
        }
    }

    accountDisplayName(account: SalesAccountViewObject): string {
        return `${account.soldToPartnerId} ${account.soldToPartnerName}`;
    }

    isActiveAccount(account: SalesAccountViewObject): boolean {
        return account.soldToPartnerId === this.loginInformation.currentAccount.soldToPartnerId;
    }

    closeOverlay(): void {
        this.$emit('back');
    }

    selectAccount(account: SalesAccountViewObject): void {
        if (!this.isInFlexBoxContext) {
            this.changeAccount(account);
            return;
        }

        this.setChangeToAccount(account.soldToPartnerId);
        tracking.trackFlexBoxButtonPress('Dropdown button, selected account', !this.isInFlexBoxContext);
        this.setShowFlexBoxModal(true);
    }

    showFlexBoxModal() {
        tracking.trackFlexBoxButtonPress('Menu button, mobile', true);
        this.setShowFlexBoxModal(true);
    }
}
