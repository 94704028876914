


























import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoints.mixin';
import { USPComponentTileViewObject } from '@/types/serverContract';
import { Component, Prop, Mixins } from 'vue-property-decorator';

@Component
export default class USPComponentTile extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @Prop({ type: Object, required: true }) tileData!: USPComponentTileViewObject;

    mounted() {
        this.updateBodyTextHeight();
    }

    umbracoStringToIcon: { [key: string]: string } = {
        'Kontakt': 'sanicon-ico-phone-02',
        'Levering': 'sanicon-ico-truck',
        'Pakke': 'sanicon-ico-package',
        'Kalender': 'sanicon-ico-calander',
        'Flueben': 'sanicon-ico-check-mark-bold'
    };

    updateBodyTextHeight() {
        const bodyText = this.$refs.bodyText as HTMLElement;
        const height = bodyText.offsetHeight;
        const style = window.getComputedStyle(bodyText);
        const lineHeight = parseFloat(style.lineHeight);

        const clampAmount = Math.floor(height / lineHeight);
        document.documentElement.style.setProperty('--usp-tile-bodytext-clamp-amount', `${clampAmount}`);
        bodyText.style.maxHeight = `${clampAmount * lineHeight}px`;
    }
}
