
































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import OrderHistorySearchForm from '@/project/bookkeeping/OrderHistorySearchForm.vue';
import { OrderHistoryListViewModel } from '@/types/serverContract';
import OrderHeader from '@/project/bookkeeping/OrderHeader.vue';
import QueryPaging from '@/project/shared/QueryPaging.vue';
import BookkeepingUrlHelper from './BookkeepingUrlHelper.service';
import scrollService from '@/core/scroll/scroll.service';
import { headerHeight } from '@/core/responsive/breakpoints/breakpoints.service';

@Component({
    components: {
        QueryPaging,
        OrderHeader,
        OrderHistorySearchForm
    }
})
export default class OrderHistoryList extends Vue {
    orders: OrderHistoryListViewModel | null = null;
    currentPage: number = 1;

    public $refs!: {
        OrderHeaderList: HTMLElement;
    };

    updateOrderHistoryOrders(searchOrderHistoryResult: OrderHistoryListViewModel): void {
        const isInitialSearch = this.orders === null;
        this.orders = searchOrderHistoryResult;
        this.currentPage = BookkeepingUrlHelper.getPageNumber();
        if (!isInitialSearch) {
            scrollService.scrollToElement(this.$refs.OrderHeaderList, -headerHeight(true));
        }
    }
}
