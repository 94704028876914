


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import {
    AddEasySupplyOrderLineRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import EasySupplyStocklistReorderLineItem from '@/project/easySupply/EasySupplyStocklistReorderLineItem.vue';
import TextInput from '../form/TextInput.vue';

@Component({
    components: {
        ButtonSubmit,
        EasySupplyStocklistReorderLineItem
    }
})
export default class EasySupplyStocklistReorderQuickAddForm extends Vue {
    @Prop({
        required: true,
        type: Number
    }) warehouseId!: number;

    productId: string = '';
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;

    public $refs!: {
        productId: TextInput;
    };

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async quickAddProduct() {
        if (this.pending) return;

        this.pending = true;
        const payload: AddEasySupplyOrderLineRequest = {
            variantId: this.productId,
            easySupplyWarehouseId: this.warehouseId
        };
        try {
            const orderLineViewModel = await Api.easySupply.addEasySupplyOrderLine(payload);
            this.showSuccessIndicator();
            const easySupplyOrderLineList = orderLineViewModel.easySupplyOrderLineList;
            this.$emit('updateEasySupplyOrderLineList', easySupplyOrderLineList);
        } finally {
            this.pending = false;
            (this.$refs.productId.$refs.input as HTMLInputElement).focus();
            this.$validator.reset();
            this.productId = '';
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }
}
