declare var window: any;

class LoggingService {
    public debug(logObject: any): void {
        if (this.isDebugMode) {
            console.log(logObject);
        } else {
            JL().debug(logObject);
        }
    }

    public info(logObject: any) {
        if (this.isDebugMode) {
            console.info(logObject);
        } else {
            JL().info(logObject);
        }
    }

    public warn(logObject: any) {
        if (this.isDebugMode) {
            console.warn(logObject);
        } else {
            JL().warn(logObject);
        }
    }

    public error(logObject: any) {
        if (this.isDebugMode) {
            console.error(logObject);
        } else {
            JL().error(logObject);
        }
    }

    public exception(logObject: any, e: any) {
        if (this.isDebugMode) {
            console.error(logObject, e);
        } else {
            JL().fatalException(logObject, e);
        }
    }

    get isDebugMode() {
        return true;
    }
}

export default new LoggingService();
