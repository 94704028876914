






















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import CopyScannerListToFavoriteOrderForm from '@/project/myServices/Scanner/CopyScannerListToFavoriteOrderForm.vue';

@Component({
    components: {
        CopyScannerListToFavoriteOrderForm,
        ModalOverlay
    }
})
export default class ScannerListActionLinks extends Vue {
    showCopyScannerListModal: boolean = false;

    closeCopyScannerListModal() {
        this.showCopyScannerListModal = false;
    }

    openCopyScannerListModal() {
        this.showCopyScannerListModal = true;
    }
}
