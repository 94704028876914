

















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { ServiceContentViewObject } from '@/types/serverContract';
import MyServicesFunctionalFrontPageLink from '@/project/myServices/MyServicesFunctionalFrontPageLink.vue';

@Component({
    components: {
        MyServicesFunctionalFrontPageLink
    }
})
export default class MyServicesFunctionalFrontPage extends Vue {
    pending: boolean = false;
    services: ServiceContentViewObject[] | null = null;

    created() {
        this.getServices();
    }

    async getServices() {
        this.pending = true;
        try {
            const serviceGroup = await Api.services.servicesFunctionalFrontPage();
            if (serviceGroup) {
                this.services = serviceGroup.services;
            }
        } finally {
            this.pending = false;
        }
    }
}
