
import Vue, { DirectiveFunction, VNode } from 'vue';

const dispatchEvent = (el: HTMLElement, eventName: string) => {
    el.dispatchEvent(new Event(eventName));
};

const handleIntersection = (entries: IntersectionObserverEntry[], el: HTMLElement) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
        dispatchEvent(el, 'element-visible');
    } else {
        dispatchEvent(el, 'element-hidden');
    }
};

const observeVisibilityInserted: DirectiveFunction = (el: HTMLElement, binding, vnode: VNode) => {
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => handleIntersection(entries, el), options);
    observer.observe(el);

    const entry = observer.takeRecords()[0];
    if (entry && entry.isIntersecting) {
        dispatchEvent(el, 'element-visible');
    }

    (vnode.context as any).$once('hook:beforeDestroy', () => {
        observer.disconnect();
    });

    // Use type assertion to inform TypeScript that __intersectionObserver exists on HTMLElement
    (el as any).__intersectionObserver = observer;
};

const observeVisibilityUnbind: DirectiveFunction = (el: HTMLElement) => {
    // Clean up logic when the directive is unbound from an element.
    const observer = (el as any).__intersectionObserver;
    if (observer) {
        observer.disconnect();
        delete (el as any).__intersectionObserver;
    }
};

Vue.directive('observeVisibility', {
    inserted: observeVisibilityInserted,
    unbind: observeVisibilityUnbind
});
