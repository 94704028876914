import httpService from '@/core/http/http.service';
import {
    GetShipmentByIdRequest,
    ShipmentViewModel
} from '@/types/serverContract';

export default class TrackAndTraceController {
    public async getShipmentInfoById(payload: GetShipmentByIdRequest): Promise<ShipmentViewModel> {
        return httpService.get<ShipmentViewModel>('trackAndTraceApi/getShipmentById', payload);
    }
}
