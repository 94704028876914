

































































































































































































































import Api from '@/project/http/api';
import {
    FavoriteOrderListItemViewObject,
    FavoriteOrdersViewModel,
    FavoriteOrdersImportResultViewModel,
    CreateOrUpdateFavoriteOrderRequest
} from '@/types/serverContract';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import FavoriteOrderProductTile from '@/project/favoriteOrders/FavoriteOrderProductTile.vue';
import { Component, Mixins } from 'vue-property-decorator';
import { filter, orderBy } from 'lodash-es';
import FavoriteOrderImportFromFile from '@/project/favoriteOrders/FavoriteOrderImportFromFile.vue';
import FavoriteOrderListItem from '@/project/favoriteOrders/FavoriteOrderListItem.vue';
import FavoriteOrderAdministrationOverlay from '@/components/molecules/FavoriteOrders/FavoriteOrderAdministrationOverlay.vue';
import CurtainOverlay from '@/components/organisms/CurtainOverlay/CurtainOverlay.vue';
import { UserGetter } from '@/store/user';
import Cookies from 'js-cookie';
import { FavoriteOrderCurtainMixin } from './mixins/FavoriteOrderCurtain.mixin';
import bus from '@/core/bus';

type sortingDirection = 'asc' | 'desc';

@Component({
    components: {
        FavoriteOrderImportFromFile,
        FavoriteOrderAdministrationOverlay,
        FavoriteOrderListItem,
        FavoriteOrderProductTile,
        SpinnerOverlay,
        CurtainOverlay
    }
})
export default class FavoriteOrdersList extends Mixins<FavoriteOrderCurtainMixin>(FavoriteOrderCurtainMixin) {
    searchTerm: string = '';
    showImportFromFile: boolean = false;
    activeSortingKey: string = '';
    activeSortingDirection: sortingDirection = 'asc';
    useAccountGroupsFlag: boolean = false; // TODO SECOM-1890: Remove this check once account groups has been tested thoroughly.
    favoriteOrders: FavoriteOrdersViewModel | null = null;

    @UserGetter isAdmin!: boolean;

    FavoriteOrderRequestPayload: CreateOrUpdateFavoriteOrderRequest =
    new class CreateOrUpdateFavoriteOrderRequest {
        caseId: string = '';
        name: string = '';
        favoriteOrderId: number | null = null;
        referenceId: string = '';
        requestId: string = '';
        readonly: boolean = true;
        accountGroupIds: string[] = [];
        shared: boolean = false;
    }();

    get filteredFavoriteOrders(): FavoriteOrderListItemViewObject[] {
        if (this.searchTerm !== '') {
            return filter(
                this.favoriteOrders!.favoriteOrderList,
                favoriteOrderInfo =>
                    Object.values([
                        favoriteOrderInfo.name,
                        favoriteOrderInfo.createdByName,
                        favoriteOrderInfo.createdDate
                    ])
                        .join('')
                        .toLowerCase()
                        .indexOf(this.searchTerm.toLowerCase()) >= 0
            );
        } else {
            return this.favoriteOrders!.favoriteOrderList;
        }
    }

    created() {
        this.getFavoriteOrders();

        // TODO SECOM-1890: Remove this cookie section, when account groups have been tested thoroughly.
        // If the URL contains the parameter enableAccountGroups=true/false we set or remove a cookie accordingly.
        if (
            !this.$route.query.enableAccountGroups ||
            !this.$route.query.enableAccountGroups.length
        ) {
            return;
        }

        const enableAccountGroups = this.$route.query.enableAccountGroups;
        switch (enableAccountGroups) {
            case 'true':
                Cookies.set('showAccountGroups', enableAccountGroups);
                break;
            case 'false':
                Cookies.remove('showAccountGroups');
                break;
        }

        // TODO SECOM-1890: Remove this section once account groups has been tested thoroughly.
        if (enableAccountGroups !== 'true') {
            return;
        }

        this.useAccountGroupsFlag = true;
    }

    resetForm(open: boolean) {
        if (open) {
            this.FavoriteOrderRequestPayload = new class CreateOrUpdateFavoriteOrderRequest {
                caseId: string = '';
                name: string = '';
                favoriteOrderId: number | null = null;
                referenceId: string = '';
                requestId: string = '';
                readonly: boolean = true;
                accountGroupIds: string[] = [];
                shared: boolean = false;
            }();
        }
    }

    async saveFavoriteList() {
        this.pending = true;
        try {
            await Api.favoriteOrder
                .createFavoriteOrder(this.FavoriteOrderRequestPayload)
                .then(newFavoriteOrders => this.updateFavoriteOrders(newFavoriteOrders));
        } finally {
            this.pending = false;
            this.showAdministrationFavoriteOrderForm = false;
            bus.emit('PageHeaderDesktopUserMenu.getFavoriteOrders');
        }
    }

    async getFavoriteOrders() {
        this.pending = true;
        try {
            this.favoriteOrders = await Api.favoriteOrder.getFavoriteOrders();
        } finally {
            this.pending = false;
        }
    }

    sortFavoriteOrdersList(sortingKey) {
        if (sortingKey === this.activeSortingKey) {
            this.toggleSortingDirection();
        } else {
            this.activeSortingKey = sortingKey;
            this.activeSortingDirection = 'asc';
        }

        if (this.favoriteOrders) {
            this.favoriteOrders.favoriteOrderList = orderBy(
                this.favoriteOrders.favoriteOrderList,
                item => item[sortingKey],
                this.activeSortingDirection
            ) as FavoriteOrderListItemViewObject[];
        }
    }

    toggleSortingDirection() {
        this.activeSortingDirection = this.activeSortingDirection === 'asc' ? 'desc' : 'asc';
    }

    importSuccess(importResult: FavoriteOrdersImportResultViewModel): void {
        this.updateFavoriteOrders(importResult.favoriteOrders);
    }

    openShowImportFromFile(): void {
        this.showImportFromFile = true;
    }

    closeShowImportFromFile(): void {
        this.showImportFromFile = false;
    }
}
