




































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ContactPersonItem from '@/project/contact/ContactPersonItem.vue';
import Api from '@/project/http/api';
import { ContactsViewModel, ContactTypeListViewObject } from '@/types/serverContract';
import { downloadApi } from '@/project/config/utilities';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: { ButtonSubmit, ContactPersonItem }
})
export default class ContactPersons extends Vue {
    pending: boolean = false;
    contactsViewModel: ContactsViewModel | null = null;

    created() {
        this.getContactTypes();
    }

    get contactLists(): ContactTypeListViewObject[] | null {
        return this.contactsViewModel ? this.contactsViewModel.contactLists : null;
    }

    async getContactTypes() {
        this.pending = true;

        try {
            this.contactsViewModel = await Api.contacts.getContacts();
        } finally {
            this.pending = false;
        }
    }

    downloadContacts() {
        downloadApi('api/contactApi/generateContactReport', null);
    }
}
