






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Api from '@/project/http/api';
import {
    RaptorBrandMethod,
    RecommendationBrandViewObject,
    RecommendationForBrandsRequest,
    RecommendationViewModel
} from '@/types/serverContract';
import BrandDisplay from '@/project/recommendations/BrandDisplay.vue';
import { FlexBoxGetter } from '@/store/flexBox';

@Component({
    components: { BrandDisplay }
})
export default class RaptorBrands extends Vue {
    @Prop({
        type: String,
        required: true
    }) title!: string;

    @Prop({
        type: String,
        required: true
    }) method!: RaptorBrandMethod;

    @Prop({
        type: Number,
        default: 4
    }) maxElements!: number;

    @FlexBoxGetter isInFlexBoxContext!: boolean;
    pending: boolean = false;
    recommendationViewModel?: RecommendationViewModel<RecommendationBrandViewObject>;
    placeholderItems: number[] = [];

    get recommendationElements(): RecommendationBrandViewObject[] | null {
        return this.recommendationViewModel ? this.recommendationViewModel.elements : null;
    }

    created() {
        this.getRaptorFavoriteBrandsForUser().then(() => this.createPlaceholderItems());
    }

    createPlaceholderItems() {
        if (this.recommendationViewModel && this.recommendationViewModel.elements) {
            const amountToCreate = this.maxElements - this.recommendationViewModel.elements.length;

            for (let index = 0; index < amountToCreate; index++) {
                this.placeholderItems.push(index);
            }
        }
    }

    async getRaptorFavoriteBrandsForUser() {
        this.pending = true;

        const payload: RecommendationForBrandsRequest = {
            method: this.method,
            maxElements: this.maxElements
        };

        try {
            this.recommendationViewModel = await Api.recommendation.recommendationForBrands(payload);
        } finally {
            this.pending = false;
        }
    }
}
