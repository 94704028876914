





























































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import './round.filter';

@Component
export default class VaLift extends Vue {
    diameter: number = 2;
    length: number = 1;
    weight: number = 240;
    coverage: number = 0.95;
    backFillWeight: number = 1000;
    result: number = 0;
    negativeResult: boolean = false;
    showLiftCalculations: boolean = false;

    created(): void {
        this.result = this.calculate();
    }

    calculate(): number {
        const b3 = this.diameter;
        const c3 = this.length;
        const b5 = this.weight;
        const b8 = this.coverage;
        const b10 = this.backFillWeight;

        const e3 = (b3 * c3 * 1000);
        const e5 = ((e3 - b5) * 1.05);
        const e10 = (b3 * c3 * b8 * b10);

        const result = (e10 - e5);

        if (result < 0) {
            this.negativeResult = true;
            this.result = -(result);
        } else {
            this.negativeResult = false;
            this.result = result;
        }
        return result;
    }

    toggle(): void {
        this.showLiftCalculations = !this.showLiftCalculations;
    }
}
