





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import tracking from '@/core/tracking/tracking.service';

@Component

export default class CreateUserEntryBox extends Vue {
    @Prop({ type: String, default: '' }) public header!: string;
    @Prop({ type: String, default: '' }) public text!: string;
    @Prop({ type: String, default: '' }) public linkUrl!: string;
    @Prop({ type: String, default: '' }) public imageUrl!: string;
    @Prop({ type: String, default: '' }) public imageWebpUrl!: string;
    @Prop({ type: String, default: '' }) public buttonText!: string;
    @Prop({ type: String, default: '' }) public trackingEvent!: string;

    trackFormStart() {
        tracking.trackCreateUserFormStart(this.trackingEvent);
    }
}
