import 'core-js/fn/object/entries';
import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/array/find-index';

import './styling/main.less';
import Vue from 'vue';
import './core/errorHandling';
import { sync } from 'vuex-router-sync';
import { router } from './router';
import { store } from './store';
import './core/config';
import './project/config';
import { TranslateFromUmbraco } from './core/translation/translate';

// To respect ITCSS order
import './styling/utils.less';
import { Labels } from './types/Labels.generated';
import { Helper } from './project/config/helper';
import Enums from './core/enums/enumsGlobal.generated';

sync(store, router);

Vue.prototype.$labels = new Labels();
Vue.prototype.$tr = TranslateFromUmbraco;
Vue.prototype.$helper = new Helper();
Vue.prototype.$enums = new Enums();

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store
});
