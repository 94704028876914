

































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import { LoginInformation } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import tracking from '@/core/tracking/tracking.service';

@Component
export default class FooterContactSection extends Vue {
    @AppGetter isLoggedIn!: boolean;
    @AppGetter loginInformation!: LoginInformation;

    get helpAndContactPageUrl(): string {
        return serverContext.sitePages.helpAndContactPage.url;
    }

    get hasContactImage(): boolean {
        return isNotNullEmptyOrUndefined(this.loginInformation.primaryContact.employeeImageUrl);
    }

    navigationTracking(clickText: string) {
        tracking.trackNavigationClick({ type: 'footer', clickText: clickText });
    }
}
