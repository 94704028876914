









































import Vue from 'vue';
import Component from 'vue-class-component';
import SpecialOrderItemForm from '@/project/basket/SpecialOrderItemForm.vue';
import QuickOrderForm from '@/project/basket/QuickOrderForm.vue';
import { AppGetter } from '@/store/app';
import { LoginInformation } from '@/types/serverContract';
import { Prop } from 'vue-property-decorator';
import { FlexBoxGetter } from '@/store/flexBox';

type tabs = 'SpecialOrderItem' | 'quickOrder';

@Component({
    components: {
        QuickOrderForm,
        SpecialOrderItemForm
    }
})
export default class TabbedHeading extends Vue {
    @Prop({
        default: 0,
        type: Number
    })
    lineItemCount!: number;

    @AppGetter loginInformation!: LoginInformation;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    active: tabs = 'quickOrder';

    get hasMultipleAccounts(): boolean {
        return this.loginInformation.accounts.length > 1
            ? true
            : this.loginInformation.accounts[0].salesAccounts.length > 1;
    }

    setActiveTab(active: tabs): void {
        this.active = active;
    }
}
