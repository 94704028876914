


































import { Component } from 'vue-property-decorator';
import BaseBanner from '@/components/molecules/Banner/BaseBanner.vue';

@Component({
    components: {
        BaseBanner
    }
})
export default class ContentBanner extends BaseBanner {
}
