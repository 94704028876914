




































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import TrackAndTraceSearchForm from '@/project/myServices/TrackAndTraceSearchForm.vue';
import TrackAndTraceHeader from '@/project/myServices/TrackAndTraceHeader.vue';
import { DeliveryNoteHistoryListViewModel } from '@/types/serverContract';

@Component({
    components: {
        TrackAndTraceHeader,
        TrackAndTraceSearchForm
    }
})
export default class TrackAndTraceList extends Vue {
    deliveryNotes: DeliveryNoteHistoryListViewModel | null = null;

    updateDeliveryNoteHistoryHeaders(searchDeliveryNoteResult: DeliveryNoteHistoryListViewModel): void {
        this.deliveryNotes = searchDeliveryNoteResult;
    }
}
