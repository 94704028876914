export default {
    storageKeys: {
        hideNetPrices: 'hideNetPrices'
    },
    ImageNotFound: require('@/assets/images/on-error.svg'),
    GoogleApiKey: 'AIzaSyDq4hBetZ6vdtIgIT34pIPtR2HZkkq-bSA',
    StoreMarker: require('@/assets/images/sanimarkerblue.png'),
    CollectStoreMarker: require('@/assets/images/sanimarkercyan.png'),
    manualDeliveryAddressId: 'ManualAddress_',
    subscribeToEmailsUrl: 'https://info.sanistaal.com/genabonner',
    rangeFacetExtension: {
        min: '_rangemin',
        max: '_rangemax'
    },
    MacAddressMask: {
        mask: 'HH:HH:HH:HH:HH:HH',
        tokens: {
            'H': {
                pattern: /[0-9a-fA-F]/
            }
        }
    },
    flexBoxZone: {
        0: '000',
        1: '001',
        2: '002'
    }
};
