







































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { ProductTileViewObject, Top100ProductsRequest, Top100ProductsViewModel } from '@/types/serverContract';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import Api from '@/project/http/api';
import AddTop100ProductsToFavoriteOrderForm from '@/project/top100Products/AddTop100ProductsToFavoriteOrderForm.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import ProductTile from '@/project/products/ProductTile.vue';
import QueryPaging from '@/project/shared/QueryPaging.vue';
import urlHelper from '@/project/search/urlHelper.service';
import { Route } from 'vue-router';
import scrollService from '@/core/scroll/scroll.service';

interface IMonthsBackOption {
    label: string;
    numberOfMonths: number;
}

@Component({
    components: {
        QueryPaging,
        ProductTile,
        ButtonSubmit,
        ModalOverlay,
        AddTop100ProductsToFavoriteOrderForm,
        SpinnerOverlay
    }
})
export default class Top100Products extends Vue {
    pending: boolean = false;
    showModal: boolean = false;
    monthsBackOptions: IMonthsBackOption[] = [
        {
            label: this.$tr(this.$labels.Views.Top100Products.SortByLastSixMonths),
            numberOfMonths: 6
        },
        {
            label: this.$tr(this.$labels.Views.Top100Products.SortByLastThreeMonths),
            numberOfMonths: 3
        }
    ];

    top100ProductsRequestPayload: Top100ProductsRequest = {
        monthsBack: this.monthsBackOptions[0].numberOfMonths
    };

    top100Products: Top100ProductsViewModel = {
        pageSize: 0,
        products: [],
        totalPages: 0,
        totalResults: 0
    };

    currentPage: number = 1;

    get hasProducts(): boolean {
        return this.top100Products.products.length >= 1;
    }

    get productsForPage(): ProductTileViewObject[] {
        const pageStartIndex = (this.currentPage - 1) * this.top100Products.pageSize;
        return this.hasProducts ? this.top100Products.products.slice(pageStartIndex, this.top100Products.pageSize * this.currentPage) : [];
    }

    created() {
        this.getTop100Products();
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path === oldRoute.path) {
            scrollService.scrollToTop();
            this.currentPage = urlHelper.getPage();
        }
    }

    @Watch('top100ProductsRequestPayload.monthsBack')
    async getTop100Products(): Promise<void> {
        this.pending = true;
        try {
            await Api.products.top100Products(this.top100ProductsRequestPayload).then(top100ProductsResult => {
                this.top100Products = top100ProductsResult;
                this.currentPage = urlHelper.getPage();
            });
        } finally {
            this.pending = false;
        }
    }

    toggleModal(): void {
        this.showModal = !this.showModal;
    }

    onNumberOfMonthsBackChange(option: IMonthsBackOption): void {
        this.top100ProductsRequestPayload.monthsBack = option.numberOfMonths;
    }
}
