import { router } from '@/router';

type queryKey = 'term' | 'manufacturer' | 'productCategory' | 'topic' | 'publicationYearFrom' | 'publicationYearTo' | 'page';

const queryKeys = {
    term: 'term',
    manufacturer: 'manufacturer',
    productCategory: 'productCategory',
    topic: 'topic',
    publicationYearFrom: 'publicationYearFrom',
    publicationYearTo: 'publicationYearTo',
    page: 'page'
};

function getSearchQuery(queryKey: queryKey): string {
    return router.currentRoute.query[queryKeys[queryKey]] as string || '';
}

function setSearchQuery(payload): void {
    const cleanQuery = Object.keys(payload).reduce((obj, key) => {
        if (payload[key]) {
            obj[key] = payload[key];
        }
        return obj;
    }, {});
    let query = { ...router.currentRoute.query };

    query = cleanQuery;

    router.replace({ query });
}

export default {
    setSearchQuery,
    getSearchQuery
};
