































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
    UnitViewObject
} from '@/types/serverContract';
import {
    orderableUnitText,
    IUnitData,
    IUnitPayload
} from '@/project/products/productHelper.utils';
import { uuid, isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import keyboardService from '@/core/keyCodes';

@Component
export default class ProductOrderableUnitsInline extends Vue {
    @Prop({
        type: Array,
        required: true
    }) orderableUnits!: UnitViewObject[];

    @Prop({
        type: Number,
        required: true
    }) quantity!: number;

    @Prop({
        type: Number,
        required: true
    }) fixedUnitQuantity!: number;

    showOrderableUnitsList: boolean = false;
    selectedUnit: UnitViewObject = this.orderableUnits[0];
    quantityError: boolean = false;
    incrementQuantityError: boolean = false;
    unitPayload: IUnitPayload = {
        unit: '',
        quantity: this.selectedUnit.increment,
        fixedUnitValue: this.fixedUnitQuantity ? this.fixedUnitQuantity
            : this.selectedUnit.isFixedUnit && this.selectedUnit.fixedUnitQuantity ? 0
                : null
    };

    selectedIndex: number = -1;

    public $refs!: {
        dropdown: HTMLElement;
    }

    get id(): string {
        return uuid();
    }

    private mounted() {
        document.addEventListener('keydown', this.handleKeyboardEvent);
    }

    created() {
        this.unitPayload.unit = this.selectedUnit.unitKey;
        this.unitPayload.quantity = isNotNullEmptyOrUndefined(this.quantity) ? this.quantity : this.selectedUnit.increment;

        if (this.quantity) {
            this.validateQuantityIncrement();
            this.validateQuantity();
        }
    }

    destroyed() {
        document.removeEventListener('keydown', this.handleKeyboardEvent);
    }

    orderableUnitText(orderableUnit: UnitViewObject): string {
        return orderableUnitText(orderableUnit);
    }

    toggleOrderableUnitsList(): void {
        this.showOrderableUnitsList = !this.showOrderableUnitsList;
    }

    closeOrderableUnitsList(): void {
        this.showOrderableUnitsList = false;
    }

    updateSelectedUnit(selectedUnit: UnitViewObject): void {
        this.selectedUnit = selectedUnit;
        this.unitPayload.unit = this.selectedUnit.unitKey;
        this.unitPayload.quantity = this.selectedUnit.increment;
        this.unitPayload.fixedUnitValue = this.selectedUnit.isFixedUnit ? 0 : null;

        this.updateAddToBasketPayload();
        this.closeOrderableUnitsList();
        this.$emit('lockArrowKeyEvents', false);
    }

    updateAddToBasketPayload(): void {
        this.validateQuantityIncrement();
        this.validateQuantity();
        this.$emit('updateAddToBasketPayload', this.setUnitData());
    }

    validateQuantityIncrement(): void {
        // Uses modulus to check if the current Quantity is fully divisible by the specified increment.
        const isInvalidIncrement = this.unitPayload.quantity % this.selectedUnit.increment !== 0;

        this.incrementQuantityError = isInvalidIncrement;
        this.$emit('quantityError', isInvalidIncrement);
    }

    validateQuantity(): void {
        const isInvalidQuantity = this.unitPayload.quantity < this.selectedUnit.increment;
        this.quantityError = isInvalidQuantity;
        this.$emit('quantityError', isInvalidQuantity);
    }

    setUnitData(): IUnitData {
        return {
            unitPayload: this.unitPayload,
            orderableUnit: this.selectedUnit
        };
    }

    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.showOrderableUnitsList) {
            this.$emit('lockArrowKeyEvents', true);
            if (keyboardService.isUpArrow(event) || keyboardService.isDownArrow(event)) {
                event.preventDefault();
                this.selectedIndex += keyboardService.isDownArrow(event) ? 1 : -1;
                // Try to move to child and if no child exists with the index, revert index
                try {
                    (this.$refs.dropdown.children[this.selectedIndex].children[0] as HTMLElement).focus();
                } catch {
                    this.selectedIndex -= keyboardService.isDownArrow(event) ? 1 : -1;
                }
            }
        }
    }
}
