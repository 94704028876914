



























































































import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import ProductOrderableUnit from '@/project/products/ProductOrderableUnit.vue';
import {
    AddFavoriteOrderLineItemRequest,
    CertificateViewObject
} from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import {
    IFixedUnitValidationState,
    IUnitData
} from '@/project/products/productHelper.utils';
import AddToFavoriteOrderCreateOrChooseOrder from '@/project/favoriteOrders/AddToFavoriteOrderCreateOrChooseOrder.vue';
import { IChooseFavoriteOrderListModel } from '@/project/favoriteOrders/addToFavoriteOrder.service';
import FixedUnitValidation from '@/project/shared/FixedUnitValidation.vue';

@Component({
    components: {
        FixedUnitValidation,
        AddToFavoriteOrderCreateOrChooseOrder,
        ProductOrderableUnit,
        ButtonSubmit
    }
})
export default class AddToFavoriteOrderForm extends Vue {
    @Prop({
        type: Array
    }) orderableUnits!: IUnitData[];

    @Prop({
        type: String
    }) variantId!: string;

    @Prop({
        type: String
    }) selectedUnit!: string;

    @Prop({
        type: Object
    }) certificate!: CertificateViewObject;

    @Prop({
        type: Boolean,
        default: false
    }) setCertificate!: boolean;

    @Prop({
        type: Boolean,
        default: false
    }) setStamping!: boolean;

    selectedOrderableUnit: IUnitData = this.orderableUnits.find(favoriteOrderableUnit => favoriteOrderableUnit.orderableUnit.unitKey === this.selectedUnit)!;
    hasQuantityError: boolean = false;
    isExistingListChosen: boolean = false;
    addCertificate: boolean = this.setCertificate;
    addStamping: boolean = this.setStamping;
    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number | null = null;
    fixedUnitValidationState: IFixedUnitValidationState = {
        isValid: true,
        maxValueNullOrValid: true,
        minValueValid: true
    };

    addToFavoriteOrderPayload: AddFavoriteOrderLineItemRequest = {
        newFavoriteOrderName: null,
        favoriteOrderId: null,
        productId: this.variantId,
        quantity: this.selectedOrderableUnit.unitPayload ? this.selectedOrderableUnit.unitPayload.quantity : 1,
        unitCode: this.selectedOrderableUnit.orderableUnit ? this.selectedOrderableUnit.orderableUnit.unitCode : '',
        certificateCode: this.addCertificate && this.certificate ? this.certificate.certificateCode : null,
        stamping: this.addCertificate && this.certificate && this.addStamping ? this.certificate.stamping : null,
        fixedUnitCode: this.selectedOrderableUnit.orderableUnit && this.selectedOrderableUnit.orderableUnit.fixedUnitQuantity ? this.selectedOrderableUnit.orderableUnit.fixedUnitQuantity.fixedUnitCode : null,
        fixedUnitValue: this.selectedOrderableUnit.unitPayload && this.selectedOrderableUnit.unitPayload.fixedUnitValue ? this.selectedOrderableUnit.unitPayload.fixedUnitValue : null,
        isFixedUnit: this.selectedOrderableUnit.orderableUnit ? this.selectedOrderableUnit.orderableUnit.isFixedUnit : false,
        shared: false
    };

    get buttonLabel(): string {
        return this.isExistingListChosen ? this.$tr(this.$labels.Views.ProductDetails.AddToFavoriteOrder) : this.$tr(this.$labels.Views.FavoriteOrder.CreateAndAdd);
    }

    get buttonSuccessLabel(): string {
        return this.isExistingListChosen ? this.$tr(this.$labels.Views.FavoriteOrder.ItemsAdded) : this.$tr(this.$labels.Views.FavoriteOrder.Created);
    }

    get fixedUnitMinQuantity(): string {
        return this.selectedUnit && this.selectedOrderableUnit.orderableUnit.isFixedUnit && this.selectedOrderableUnit.orderableUnit.fixedUnitQuantity.minimumQuantity ? this.selectedOrderableUnit.orderableUnit.fixedUnitQuantity.minimumQuantity.toString() : '';
    }

    get fixedUnitMaxQuantity(): string {
        return this.selectedUnit && this.selectedOrderableUnit.orderableUnit.isFixedUnit && this.selectedOrderableUnit.orderableUnit.fixedUnitQuantity.maximumQuantity ? this.selectedOrderableUnit.orderableUnit.fixedUnitQuantity.maximumQuantity.toString() : '';
    }

    get fixedUnitCode(): string {
        return this.selectedOrderableUnit.orderableUnit.isFixedUnit ? this.selectedOrderableUnit.orderableUnit.fixedUnitQuantity.fixedUnitCode : '';
    }

    get hasUnitErrors(): boolean {
        return !this.fixedUnitValidationState.isValid || this.hasQuantityError;
    }

    $refs!: {
        AddToFavoriteOrderCreateOrChooseOrder: AddToFavoriteOrderCreateOrChooseOrder;
        fixedUnitValidation: FixedUnitValidation;
    };

    created() {
        this.setInitialPayload();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async submit() {
        if (this.addToFavoriteOrderPayload === null) {
            return;
        }

        this.forceFixedUnitValidation().then(() => {
            (this.$refs.AddToFavoriteOrderCreateOrChooseOrder as any).validateForm().then(isFormValid => {
                if (!isFormValid) {
                    return;
                }

                this.pending = true;

                try {
                    Api.favoriteOrder.addToFavoriteOrder(this.addToFavoriteOrderPayload!).then(() => {
                        this.showSuccessIndicator();
                    });
                } finally {
                    this.pending = false;
                }
            });
        });
    }

    async forceFixedUnitValidation(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.selectedOrderableUnit.orderableUnit.isFixedUnit) {
                // Casting reference to type any, to avoid having to implement an interface to tell ts that the reference has the "forceValidation" method
                (this.$refs.fixedUnitValidation as any).forceValidation();
                this.$nextTick().then(() => {
                    if (this.fixedUnitValidationState.isValid) {
                        resolve();
                    } else {
                        reject(new Error('Fixed unit invalid'));
                    }
                });
            } else {
                resolve();
            }
        });
    }

    updateFavoriteOrderList(payload: IChooseFavoriteOrderListModel): void {
        if (this.addToFavoriteOrderPayload === null) {
            return;
        }
        this.addToFavoriteOrderPayload.newFavoriteOrderName = payload.newFavoriteOrderName;
        this.addToFavoriteOrderPayload.shared = payload.shared;
        this.addToFavoriteOrderPayload.favoriteOrderId = payload.targetFavoriteOrderId;

        this.isExistingListChosen = payload.activeTab === 'existingList';
    }

    setInitialPayload(): void {
        this.addToFavoriteOrderPayload = {
            newFavoriteOrderName: null,
            favoriteOrderId: null,
            productId: this.variantId,
            quantity: this.selectedOrderableUnit.unitPayload ? this.selectedOrderableUnit.unitPayload.quantity : 1,
            unitCode: this.selectedOrderableUnit.orderableUnit ? this.selectedOrderableUnit.orderableUnit.unitCode : '',
            certificateCode: this.addCertificate && this.certificate ? this.certificate.certificateCode : null,
            stamping: this.addCertificate && this.certificate && this.addStamping ? this.certificate.stamping : null,
            fixedUnitCode: this.selectedOrderableUnit.orderableUnit && this.selectedOrderableUnit.orderableUnit.fixedUnitQuantity ? this.selectedOrderableUnit.orderableUnit.fixedUnitQuantity.fixedUnitCode : null,
            fixedUnitValue: this.selectedOrderableUnit.unitPayload && this.selectedOrderableUnit.unitPayload.fixedUnitValue ? this.selectedOrderableUnit.unitPayload.fixedUnitValue : null,
            isFixedUnit: this.selectedOrderableUnit.orderableUnit ? this.selectedOrderableUnit.orderableUnit.isFixedUnit : false,
            shared: false
        };
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
            this.$emit('closeModal');
        }, this.successTimer);
    }

    updateCurrentUnit(unitData: IUnitData): void {
        this.selectedOrderableUnit = unitData;
        if (this.addToFavoriteOrderPayload && unitData) {
            this.addToFavoriteOrderPayload.unitCode = unitData.orderableUnit.unitCode;
            this.addToFavoriteOrderPayload.quantity = unitData.unitPayload.quantity;
            this.addToFavoriteOrderPayload.isFixedUnit = unitData.orderableUnit.isFixedUnit;
            this.addToFavoriteOrderPayload.fixedUnitValue = unitData.unitPayload.fixedUnitValue ? unitData.unitPayload.fixedUnitValue : null;
            this.addToFavoriteOrderPayload.fixedUnitCode = unitData.orderableUnit.fixedUnitQuantity ? unitData.orderableUnit.fixedUnitQuantity.fixedUnitCode : null;
        }
    }

    setCertificateCode(): void {
        if (this.addToFavoriteOrderPayload === null) {
            return;
        }
        this.addToFavoriteOrderPayload.certificateCode = this.addCertificate && this.certificate ? this.certificate.certificateCode : null;
    }

    setStampingValue(): void {
        if (this.addToFavoriteOrderPayload === null) {
            return;
        }
        this.addToFavoriteOrderPayload.stamping = this.addCertificate && this.addStamping && this.certificate ? this.certificate.stamping : null;
    }

    validateFixedUnit(validState: IFixedUnitValidationState): void {
        this.fixedUnitValidationState = validState;
    }

    onQuantityError(hasQuantityError): void {
        this.hasQuantityError = hasQuantityError;
    }
}
