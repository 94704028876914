























































import Vue from 'vue';
import Component from 'vue-class-component';
import { AppGetter } from '@/store/app';
import { InfoMessagesViewModel } from '@/types/serverContract';
import infoMessageCookieService from './infoMessageCookie.service';
import { Watch } from 'vue-property-decorator';
import scrollService from '@/core/scroll/scroll.service';

@Component
export default class InfoMessages extends Vue {
    visibleInfoMessages: InfoMessagesViewModel[] = [];
    @AppGetter infoMessages!: InfoMessagesViewModel[];

    created() {
        this.calcVisibleInfoMessages();
    }

    @Watch('infoMessages')
    onInfoMessagesChange() {
        // When changed by SPA page change
        this.calcVisibleInfoMessages();
    }

    msgClasses(msg: InfoMessagesViewModel) {
        return {
            [msg.type.toLowerCase()]: true,
            'has-link': msg.link!!
        };
    }

    iconClass(msg: InfoMessagesViewModel) {
        return {
            'sanicon-ico-lifebuoy': msg.type === 'Alert',
            'sanicon-ico-warning': msg.type === 'Warning',
            'sanicon-ico-notification': msg.type === 'Info'
        };
    }

    calcVisibleInfoMessages(): void {
        this.visibleInfoMessages =
            this.infoMessages != null
                ? this.infoMessages.filter(msg => !infoMessageCookieService.hasBeenMuted(msg.text))
                : [];
    }

    clearMessage(msg: InfoMessagesViewModel) {
        infoMessageCookieService.addMessage(msg.text, msg.maxHoursHidden);
        this.calcVisibleInfoMessages();
    }

    showAsInternalLink(msg: InfoMessagesViewModel): boolean {
        return msg.link !== null && !this.showAsExternalLink(msg);
    }

    showAsExternalLink(msg: InfoMessagesViewModel): boolean {
        return msg.link !== null && msg.link.startsWith('mailto');
    }

    @Watch('apiMessages')
    onVisibleInfoMessagesChange(value: InfoMessagesViewModel[], old: InfoMessagesViewModel[]) {
        if (value.length > old.length) {
            scrollService.scrollToTop();
        }
    }
}
