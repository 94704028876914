import { render, staticRenderFns } from "./HelpDeskAdministerProjectFolderEditUsefullLinks.vue?vue&type=template&id=5d458d2a"
import script from "./HelpDeskAdministerProjectFolderEditUsefullLinks.vue?vue&type=script&lang=ts"
export * from "./HelpDeskAdministerProjectFolderEditUsefullLinks.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports