





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { UnitViewObject, StockStatusViewObject } from '@/types/serverContract';

@Component
export default class QuantitySelector extends Vue {
    @Prop({ type: Number, default: 1 }) quantity!: number;
    @Prop({ type: Object, default: '' }) unit!: UnitViewObject;
    @Prop({ type: Object, default: '' }) stock!: StockStatusViewObject;

    updatedQuantity: number = this.quantity;
    maxQuantity: number = Math.floor(Number(this.stock.inventory.quantity) / this.unit.unitQuantity);

    get decrementDisabled(): boolean {
        return this.updatedQuantity <= this.unit.increment;
    }

    get incrementDisabled(): boolean {
        return this.updatedQuantity >= this.maxQuantity;
    }

    get inputStyle(): string {
        return `--input-width: ${Math.min(this.updatedQuantity.toString().length + 1, 6)}ch;`;
    }

    decrementQuantity() {
        if (this.decrementDisabled) {
            return;
        }

        this.updatedQuantity -= this.unit.increment;
        this.$emit('update', this.updatedQuantity);
    }

    incrementQuantity() {
        if (this.incrementDisabled) {
            return;
        }

        this.updatedQuantity += this.unit.increment;
        this.$emit('update', this.updatedQuantity);
    }

    updateQuantity(e) {
        this.updatedQuantity = Number(e.target.value);
        this.$emit('update', this.updatedQuantity);
    }
}
