


























































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import {
    LoginInformation,
    HelpDeskUserViewObject,
    ImpersonationRequest,
    ImpersonationRedirectType,
    HelpDeskSalesAccountsViewObject,
    AccountImpersonationRequest,
    NewMainAccountAndNewUserEmailForAccountRequest,
    HelpDeskUserViewObjectSlim
} from '@/types/serverContract';
import Api from '@/project/http/api';
import serverContext from '@/core/serverContext.service';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import HelpDeskUserSearch from '@/project/helpDesk/HelpDeskUserSearch.vue';
import HelpDeskAccountSearch from '@/project/helpDesk/HelpDeskAccountSearch.vue';
import HelpDeskAssignUserToAccount from '@/project/helpDesk/HelpDeskAssignUserToAccount.vue';
import HelpDeskCheckApprovedAgreements from '@/project/helpDesk/HelpDeskCheckApprovedAgreements.vue';
import HelpDeskCreateUser from '@/project/helpDesk/HelpDeskCreateUser.vue';
import HelpDeskEditAccountAccess from '@/project/helpDesk/HelpDeskEditAccountAccess.vue';
import HelpDeskEditAccountsAccess from '@/project/helpDesk/HelpDeskEditAccountsAccess.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import HelpDeskAdministerProjectFolder from '@/project/helpDesk/AdministerProjectFolder/HelpDeskAdministerProjectFolder.vue';
import HelpDeskSynonymList from '@/project/helpDesk/HelpDeskSynonymList.vue';
import HelpDeskAddSynonym from '@/project/helpDesk/HelpDeskAddSynonym.vue';

export class ActiveFields {
    Profile: boolean;
    Account: boolean;
    User: boolean;
}

@Component({
    components: {
        HelpDeskAdministerProjectFolder,
        SpinnerOverlay,
        ModalOverlay,
        HelpDeskUserSearch,
        HelpDeskAccountSearch,
        HelpDeskAssignUserToAccount,
        HelpDeskCheckApprovedAgreements,
        HelpDeskCreateUser,
        HelpDeskEditAccountAccess,
        HelpDeskEditAccountsAccess,
        HelpDeskSynonymList,
        HelpDeskAddSynonym
    }
})
export default class HelpDesk extends Vue {
    @AppGetter isLoggedIn!: boolean;
    @AppGetter loginInformation!: LoginInformation;

    activeFields: ActiveFields = {
        Profile: false,
        Account: false,
        User: false
    };

    pending: boolean = false;
    showHelpDeskModal: boolean = false;
    userAction: string = '';
    accountAction: string = '';
    searchAction: string = '';
    selectedUser: HelpDeskUserViewObject | null = null;
    selectedAccount: HelpDeskSalesAccountsViewObject | null = null;
    selectedProfile: HelpDeskSalesAccountsViewObject | null = null;
    seeAllUsersSelected: boolean = false;

    get supportGuidesPageUrl(): string {
        return serverContext.sitePages.supportGuidesPage.url;
    }

    async newMainAccountAndNewUserForAccount(user: HelpDeskUserViewObjectSlim): Promise<void> {
        if (user === null || this.selectedAccount === null) {
            return;
        }
        this.pending = true;

        const payload: NewMainAccountAndNewUserEmailForAccountRequest = {
            accountId: this.selectedAccount.salesAccountId,
            userId: user.id
        };
        try {
            await Api.helpDesk.NewMainAccountAndNewUserEmailForAccount(payload);
        } finally {
            this.pending = false;
        }
    }

    async startImpersonation(redirectTo: ImpersonationRedirectType, accountNumber: string): Promise<void> {
        this.pending = true;

        const payload: ImpersonationRequest = {
            currentUrl: window.location.href,
            redirectTo: redirectTo,
            soldToPartnerId: accountNumber,
            userId: this.selectedUser!.id
        };

        try {
            await Api.helpDesk.startImpersonation(payload);
        } finally {
            this.pending = false;
        }
    }

    async startAccountImpersonation(): Promise<void> {
        if (this.selectedAccount === null) {
            return;
        }
        this.pending = true;

        const payload: AccountImpersonationRequest = {
            currentUrl: window.location.href,
            soldToPartnerId: this.selectedAccount.soldToPartnerId
        };

        try {
            await Api.helpDesk.startAccountImpersonation(payload);
        } finally {
            this.pending = false;
        }
    }

    async stopImpersonation(): Promise<void> {
        try {
            await Api.helpDesk.stopImpersonation();
        } finally { }
    }

    openHelpDeskModal() {
        this.showHelpDeskModal = true;
    }

    closeHelpDeskModal() {
        this.showHelpDeskModal = false;
        this.resetUserAction();
        this.resetAccountAction();
        this.resetSearchAction();
    }

    resetUserAction(): void {
        this.userAction = '';
        this.resetSelection();

        if (this.accountAction === 'updateAllowNewUsersFromFrontpage') {
            this.activeFields.Account = true;
            this.activeFields.Profile = true;
        } else if (this.accountAction === 'newMainAccountAndNewUserForAccount') {
            this.activeFields.User = true;
        } else {
            this.activeFields.Account = true;
        }

        if (this.accountAction === 'seeAllUsersOnAccount') {
            this.seeAllUsersSelected = true;
        } else {
            this.seeAllUsersSelected = false;
        }
    }

    resetAccountFields() {
        this.activeFields.Profile = false;
        this.activeFields.Account = false;
        this.activeFields.User = false;
    }

    resetAccountAction(): void {
        this.accountAction = '';
        this.resetSelection();
    }

    resetSearchAction(): void {
        this.searchAction = '';
    }

    resetOtherActions(action: string): void {
        if (action === 'userAction') {
            this.resetAccountAction();
            this.resetSearchAction();
        }
        if (action === 'accountAction') {
            this.resetUserAction();
            this.resetSearchAction();
        }
        if (action === 'searchAction') {
            this.resetUserAction();
            this.resetAccountAction();
        }
    }

    resetSelectedUser(): void {
        this.selectedUser = null;
    }

    resetSelectedAccount(): void {
        this.selectedAccount = null;
    }

    resetSelectedProfile(): void {
        this.selectedProfile = null;
    }

    resetSelection(): void {
        this.resetSelectedUser();
        this.resetSelectedAccount();
        this.resetSelectedProfile();
        this.resetAccountFields();
    }

    selectUser(selectedUser: HelpDeskUserViewObject, accountNumber: string): void {
        this.selectedUser = selectedUser;
        if (this.userAction === 'impersonate') {
            this.startImpersonation(ImpersonationRedirectType.FrontPage, accountNumber);
        } else if (this.userAction === 'impersonateToChangePassword' || this.userAction === 'impersonateToEditUser' || this.userAction === 'impersonateToChangeAccess') {
            this.startImpersonation(ImpersonationRedirectType.EditUser, accountNumber);
        }
    }

    selectAccount(selectedAccount: HelpDeskSalesAccountsViewObject): void {
        this.selectedAccount = selectedAccount;
        if (this.accountAction === 'seeAllUsersOnAccount') {
            this.startAccountImpersonation();
        }
    }

    selectProfile(selectedProfile: HelpDeskSalesAccountsViewObject): void {
        this.selectedProfile = selectedProfile;
    }

    selectAccountAndUser(selectedAccount: HelpDeskSalesAccountsViewObject, selectedUser: HelpDeskUserViewObjectSlim): void {
        this.selectedAccount = selectedAccount;
        if (this.accountAction === 'newMainAccountAndNewUserForAccount') {
            this.newMainAccountAndNewUserForAccount(selectedUser);
        }
    }
}
