




































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    HelpDeskSalesAccountsViewObject,
    HelpDeskUserViewObjectSlim,
    SearchSalesAccountsRequest,
    SearchSalesAccountsViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';
import HelpDeskFavoriteAccounts from '@/project/helpDesk/HelpDeskFavoriteAccounts.vue';
import CallbackPaging from '@/project/shared/CallbackPaging.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import HelpDeskAccounts from '@/project/helpDesk/HelpDeskAccounts.vue';
import { ActiveFields } from './HelpDesk.vue';

@Component({
    components: {
        HelpDeskAccounts,
        HelpDeskFavoriteAccounts,
        CallbackPaging,
        SpinnerOverlay
    }
})
export default class HelpDeskAccountSearch extends Vue {
    @Prop({ default: false, type: Boolean }) selectSalesAccountEnabled!: boolean;
    @Prop({ default: false, type: Boolean }) selectUserEnabled!: boolean;
    @Prop({ default: false, type: Boolean }) seeAllUsersSelected!: boolean;
    @Prop({ type: Object }) activeFields!: ActiveFields;

    pending: boolean = false;
    accountNumber: string = '';
    freeText: string = '';
    accountSearchResult: SearchSalesAccountsViewModel | null = null;
    currentPage: number = 1;

    async searchAccounts(): Promise<void> {
        this.pending = true;

        const payload: SearchSalesAccountsRequest = {
            soldToPartnerId: this.accountNumber,
            term: this.freeText,
            page: this.currentPage
        };

        try {
            await Api.helpDesk.searchSalesAccounts(payload).then(res => {
                this.accountSearchResult = res;
            });
            if (this.accountSearchResult !== null) {
                this.currentPage = this.accountSearchResult.currentPage;
            }
        } finally {
            this.pending = false;
        }
    }

    submitForm(): void {
        this.currentPage = 1;
        this.searchAccounts();
    }

    selectFavoriteAccount(selectedFavoriteAccount: string): void {
        this.freeText = '';
        this.accountNumber = selectedFavoriteAccount;
        this.currentPage = 1;
        this.searchAccounts();
    }

    selectAccount(selectedAccount: HelpDeskSalesAccountsViewObject): void {
        this.$emit('accountSelected', selectedAccount);
    }

    selectProfile(selectedProfile: HelpDeskSalesAccountsViewObject): void {
        this.$emit('profileSelected', selectedProfile);
    }

    selectUser(selectedAccount: HelpDeskSalesAccountsViewObject, selectedUser: HelpDeskUserViewObjectSlim): void {
        this.$emit('userSelected', selectedAccount, selectedUser);
    }

    gotoPage(pageNo: number): void {
        this.currentPage = pageNo;
        this.searchAccounts();
    }
}
