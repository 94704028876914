







































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AddToBasketRequest, BasketViewModel } from '@/types/serverContract';
import { BasketAction, BasketGetter } from '@/store/basket';
import ProductAddToBasketLifeCycleIcons from '@/project/products/ProductAddToBasketLifeCycleIcons.vue';
import { FlexBoxGetter } from '@/store/flexBox';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component({
    components: {
        ProductAddToBasketLifeCycleIcons
    }
})
export default class ProductAddToBasketButton extends Vue {
    @BasketGetter isBasketBusy!: boolean;
    @BasketGetter basket!: BasketViewModel;
    @BasketAction addToBasket!: (payload: AddToBasketRequest) => Promise<BasketViewModel>;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    @Prop({
        default: false,
        type: Boolean
    })
    compact!: boolean;

    @Prop({
        required: true,
        type: Object
    })
    payload!: AddToBasketRequest;

    @Prop({
        default: false,
        type: Boolean
    })
    disabled!: boolean;

    @Prop({
        default: () => {
            return new Promise(resolve => {
                resolve();
            });
        },
        type: Function
    })
    beforeAddToBasketFunction!: () => Promise<void>;

    @Prop({
        type: String,
        default: 'product-tile',
        validator: (value: string) => ['search-tile', 'product-tile'].indexOf(value) > -1
    })
    mode!: string;

    pending = false;
    success = false;
    successTimer = 3000;
    timeout: number | null = null;

    get buttonClasses() {
        return {
            'c-btn--success': this.success,
            'px-20 inline-flex min-h-50 w-60': this.compact,
            'c-btn--smaller w-full': !this.compact
        };
    }

    get buttonIsDisabled() {
        return this.disabled || (this.isBasketBusy && !this.pending);
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    initAddToBasketRequest() {
        if (this.isBasketBusy || this.pending) {
            return;
        }

        this.beforeAddToBasketFunction().then(() => {
            this.pending = true;
            this.addToBasket(this.payload)
                .then(() => {
                    if (this.basket && this.basket.lineItems) {
                        const lineWithScalePriceToPromote = this.basket.lineItems.find(line => {
                            // get lineItem with scalePriceToPromote for the payload if present
                            return (
                                line.productId === this.payload.productId &&
                                line.unit.unitCode === this.payload.unit &&
                                isNotNullEmptyOrUndefined(line.scalePriceToPromote)
                            );
                        });

                        const showScalePriceToPromote: boolean = isNotNullEmptyOrUndefined(
                            lineWithScalePriceToPromote
                        );
                        if (showScalePriceToPromote) {
                            this.$emit(
                                'scalePriceToPromote',
                                lineWithScalePriceToPromote!.scalePriceToPromote
                            );
                        }
                    }
                    this.showSuccessIndicator();
                })
                .finally(() => {
                    this.pending = false;
                });
        });
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;
        this.$emit('on-add-to-basket', this.payload);

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }
}
