





import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component
export default class ProductCo2InfoText extends Vue {
    @Prop({
        type: String,
        required: true
    }) text!: string;

    replaceCO2WithSubscript(text) {
        var replacedText = text.replace(/CO2/gi, 'CO<sub>2</sub>');
        return replacedText;
    }

    get emissionText(): string {
        return this.replaceCO2WithSubscript(this.text);
    }
}
