









































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import keyboardService from '@/core/keyCodes';
import Api from '@/project/http/api';
import BookkeepingUrlHelper from './BookkeepingUrlHelper.service';
import { AddOrderToBasketRequest } from '@/types/serverContract';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

@Component({
    components: {
        SpinnerOverlay,
        ModalOverlay
    }
})
export default class OrderHistoryDetailsActionLinks extends Vue {
    @Prop({
        type: String
    }) trackAndTraceUrl!: string;

    @Prop({
        type: String
    }) certificateDetailsUrl!: string;

    @Prop({
        type: String
    }) downloadUrl!: string;

    showConfirmationModal: boolean = false;
    pending: boolean = false;

    async reOrder() {
        this.closeConfirmationModal();
        this.pending = true;
        try {
            const payload: AddOrderToBasketRequest = {
                orderId: BookkeepingUrlHelper.getOrderId()
            };
            await Api.basket.addOrderToBasket(payload);
        } finally {
            this.pending = false;
        }
    }

    openConfirmationModal() {
        this.showConfirmationModal = true;
        document.addEventListener('keyup', this.keyHandler);
    }

    closeConfirmationModal() {
        this.showConfirmationModal = false;
        document.removeEventListener('keyup', this.keyHandler);
    }

    keyHandler(event) {
        if (keyboardService.isEnter(event)) {
            this.reOrder();
        }
    }
}
