
































































































































































































































































































































































import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import {
    IFeatureToggling,
    LinkItem,
    UserNavigationItem,
    OrderResumeViewObject,
    GetLatestOrdersRequest,
    SearchDeliveryNotesHistoryRequest,
    ProductIdType,
    DeliveryNoteHeaderViewObject,
    FavoriteOrderViewObject,
    PagedFavoriteOrdersRequest
} from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import tracking from '@/core/tracking/tracking.service';

import OrderStatusLabel, { StatusId } from '@/project/statusLabels/OrderStatusLabel.vue';
import StatusLabel from '@/project/statusLabels/StatusLabel.vue';
import Api from '@/project/http/api';
import BookkeepingUrlHelper from '@/project/bookkeeping/BookkeepingUrlHelper.service';
import dayjs from 'dayjs';
import { dateParseFormat } from '@/project/config/utilities';
import bus from '@/core/bus';
import DropdownCtrl from '@/project/form/Dropdown/DropdownCtrl.vue';
import DropdownCtrlContent from '@/project/form/Dropdown/DropdownCtrlContent.vue';
import DropdownCtrlItem from '@/project/form/Dropdown/DropdownCtrlItem.vue';
import { FormOrchestrator } from '@/project/form/FormOrchestrator';
import SelectAccountPanel from '@/project/authentication/SelectAccountPanel.vue';
import SelectAccountPanelWrapper from '@/project/authentication/SelectAccountPanelWrapper.vue';
import AccountAvatarCircle from '@/project/navigation/page-header/AccountAvatarCircle.vue';
import AccountAvatarMenu from '@/project/navigation/page-header/AccountAvatarMenu.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import Cookies from 'js-cookie';
import { USER_MENU_ITEM } from '@/core/enums/enums';

@Component({
    components: {
        DropdownCtrl,
        DropdownCtrlContent,
        DropdownCtrlItem,
        OrderStatusLabel,
        StatusLabel,
        SelectAccountPanel,
        SelectAccountPanelWrapper,
        AccountAvatarCircle,
        AccountAvatarMenu,
        SpinnerOverlay
    }
})
export default class PageHeaderDesktopUserMenu extends Vue {
    @Prop({ type: Object, required: true, default: () => {} }) userMenuItem!: UserNavigationItem;
    @Prop({ default: () => {}, type: Object }) activeLevel1Item!: LinkItem;

    @AppGetter isLoggedIn!: boolean;
    @AppGetter featureToggling!: IFeatureToggling;
    @AppGetter isPunchoutMode!: boolean;

    statusToStatusIDMap = {
        'Afsendt': StatusId.Sent,
        'Delvist afsendt': StatusId.PartiallySent,
        'Ekspederes': StatusId.Expedited,
        'Modtaget': StatusId.Recieved,
        'Annulleret': StatusId.Cancelled
    };

    ShowSoldToIdAccountNames = false;
    latestOrders: OrderResumeViewObject[] | null = null;
    latestDeliveryNotes: DeliveryNoteHeaderViewObject[] | null = null;
    latestFavoriteOrders: FavoriteOrderViewObject[] | null = null;
    donePromise: (Promise<void>) | null = null;
    accountSearchTerm: string = '';

    maxElements: number = 6;

    dropdownOrchestrator: FormOrchestrator = new FormOrchestrator();

    setShowSoldToIdAccountNamesChange(checked: boolean) {
        if (!checked) {
            Cookies.remove('ShowSoldToIdAccountNamesCookieKey');
            this.ShowSoldToIdAccountNames = false;
            return;
        }

        Cookies.set('ShowSoldToIdAccountNamesCookieKey', 'true');
        this.ShowSoldToIdAccountNames = true;
    }

    handleOverlayPosition(vueElement: any) {
        let dropdownCtrl = vueElement as DropdownCtrl;
        if (!dropdownCtrl.Ctrl.isActive) {
            this.latestOrders = null;
            this.latestFavoriteOrders = null;
            this.latestDeliveryNotes = null;
            return;
        }
        switch (dropdownCtrl.moniker) {
            case USER_MENU_ITEM.OrderSummary.toString():
                this.getLatestOrders();
                break;
            case USER_MENU_ITEM.FavoriteOrders.toString():
                this.getFavoriteOrders();
                break;
            case USER_MENU_ITEM.DeliveryNotes.toString():
                this.searchDeliveryNotes();
                break;
        }
    }

    clickOutside() {
        this.dropdownOrchestrator.exit();
    }

    created() {
        const cookieValue = Cookies.get('ShowSoldToIdAccountNamesCookieKey');
        if (cookieValue !== undefined) {
            this.ShowSoldToIdAccountNames = cookieValue === 'true';
        }

        bus.on('PageHeaderDesktopUserMenu.getFavoriteOrders', this.getFavoriteOrders);
    };

    mounted() {
        this.dropdownOrchestrator.setOtherFormsInactive = true;
        this.dropdownOrchestrator.allCtrls =
            [
                this.$refs.orderSummaryLink as DropdownCtrl,
                this.$refs.favoriteOrdersLink as DropdownCtrl,
                this.$refs.deliveryNotesLink as DropdownCtrl,
                this.$refs.accountDropdown as DropdownCtrl,
                this.$refs.accountAvatarDropdown as DropdownCtrl
            ].filter(item => !!item);
        this.dropdownOrchestrator.init();
    }

    beforeDestroy() {
        bus.off('PageHeaderDesktopUserMenu.getFavoriteOrders', this.getFavoriteOrders);
    }

    async searchDeliveryNotes(): Promise<void> {
        if (this.isLoggedIn && this.userMenuItem.deliveryNotesLink !== undefined) {
            const payload: SearchDeliveryNotesHistoryRequest = {
                caseNumber: BookkeepingUrlHelper.getSearchQuery('caseNumber'),
                fromDate: BookkeepingUrlHelper.getSearchQuery('fromDate') === '' ? dayjs().subtract(6, 'month').format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('fromDate'),
                orderNumber: BookkeepingUrlHelper.getSearchQuery('orderNumber'),
                page: BookkeepingUrlHelper.getPageNumber(),
                pageSize: this.maxElements,
                productId: BookkeepingUrlHelper.getSearchQuery('productId'),
                productIdType: BookkeepingUrlHelper.getSearchQuery('productIdType') === '' ? ProductIdType.Sani : (BookkeepingUrlHelper.getSearchQuery('productIdType') as unknown as ProductIdType),
                reference: BookkeepingUrlHelper.getSearchQuery('reference'),
                toDate: BookkeepingUrlHelper.getSearchQuery('toDate') === '' ? dayjs().format(dateParseFormat) : BookkeepingUrlHelper.getSearchQuery('toDate'),
                requisitionNumber: BookkeepingUrlHelper.getSearchQuery('requisitionNumber')
            };
            await Api.deliveryNotes.search(payload).then(searchDeliveryNotesResult => {
                if (searchDeliveryNotesResult) {
                    this.latestDeliveryNotes = searchDeliveryNotesResult.deliveryNoteHeaders;
                }
            });
        };
    }

    async getLatestOrders() {
        if (this.isLoggedIn && this.userMenuItem.orderSummaryLink !== undefined) {
            const payload: GetLatestOrdersRequest = {
                maxElements: this.maxElements
            };
            await Api.orderHistory.getLatestOrders(payload).then(latestOrderResult => {
                if (latestOrderResult) {
                    this.latestOrders = latestOrderResult.orders;
                }
            });
        }
    }

    async getFavoriteOrders() {
        if (this.isLoggedIn && this.userMenuItem.favoriteOrdersLink !== undefined) {
            const payload: PagedFavoriteOrdersRequest = {
                page: 1,
                pageSize: 6
            };

            await Api.favoriteOrder.getPagedFavoriteOrders(payload).then(PagedFavoriteOrders => {
                if (PagedFavoriteOrders) {
                    this.latestFavoriteOrders = PagedFavoriteOrders.favoriteOrders;
                }
            });
        }
    }

    navigationTracking(text: string) {
        tracking.trackNavigationClick({ type: 'User menu', clickText: text });
    }
}
