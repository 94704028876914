





























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import Login from '@/components/molecules/Login/Login.vue';

@Component({
    components: {
        SpinnerOverlay,
        Login
    }
})
export default class LoginPanel extends Vue {
    showSpinner = false;
    created() {
    }

    close(): void {
        this.$emit('close');
    }
}
