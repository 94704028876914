
































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SynonymViewObject } from '@/types/serverContract';

@Component
export default class HelpDeskDuplicateSynonymList extends Vue {
    @Prop({
        required: true,
        type: Array
    }) duplicateSynonyms!: SynonymViewObject[];

    @Prop({
        required: true,
        type: String
    }) synonymToAdd!: string;

    get proceedText(): string {
        return this.$tr(this.$labels.Views.Helpdesk.EditSearch.AddSynonym.ProceedText, this.synonymToAdd);
    }

    goBack(createSynonym: boolean): void {
        this.$emit('goBack', createSynonym);
    }
}
