import httpService from '@/core/http/http.service';
import {
    ArticleSearchRequest,
    ArticleSearchResponse
} from '@/types/serverContract';

export default class ContentController {
    public async search(payload: ArticleSearchRequest): Promise<ArticleSearchResponse> {
        return httpService.get('contentApi/search', payload as ArticleSearchRequest);
    }
}
