


































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
    NavigationItem,
    NavigationViewModel
} from '@/types/serverContract';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import { ActiveInfo } from '@/project/config/models';
import tracking, { NavigationClicksTypes } from '@/core/tracking/tracking.service';

const MAX_LEVELS = 10;

@Component({
    components: {
        OffCanvasOverlay
    }
})
export default class SideMenuItem extends Vue {
    @Prop({
        type: Object,
        required: true
    }) navigationItem!: NavigationViewModel;

    @Prop({
        type: Object
    }) activeInfo!: ActiveInfo;

    goToNextLevel(navigationItem: NavigationItem, navigationItemLabel: string) {
        // not already open
        this.$emit('goToNextLevel', navigationItem);
        this.navigationTracking(navigationItemLabel);
    }

    navigationTracking(clickText: string) {
        const currentLevelNumber = this.activeInfo && this.activeInfo.level ? this.activeInfo.level + 1 : 1;
        tracking.trackNavigationClick({
            type: ('Left navigation bar - submenu_' + currentLevelNumber) as NavigationClicksTypes,
            clickText: clickText
        });
    }

    shouldShowNextLevel(navigationItem: NavigationItem): boolean {
        if (!this.activeInfo && navigationItem.children && navigationItem.children.length > 0) {
            return true;
        }
        return navigationItem.children && navigationItem.children.length > 0 && this.activeInfo.level + 1 < MAX_LEVELS;
    }

    closeOverlay(linkLabel: string) {
        this.$emit('closeOverlay');
        this.navigationTracking(linkLabel);
    }
}
