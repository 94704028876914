
import { Vue, Prop } from 'vue-property-decorator';
import CurtainOverlay from '../CurtainOverlay.vue';
import { ICurtainOverlayBase } from './ICurtainOverlayBase.vue';

export default class CurtainOverlayBase extends Vue implements ICurtainOverlayBase {
    Submit(): Promise<void> { return Promise.resolve(); }// Virtual method
    Cancel(): void {} // Virtual method
    Active(): void {} // Virtual method
    Inactive(): void {} // Virtual method
    UpdateSubmitButton(): void {} // Virtual method

    @Prop({ type: Object }) prevCurtainOverlay!: ICurtainOverlayBase;
    @Prop({ type: Object }) overlay!: CurtainOverlay;

    submitButtonText: string;
    titleText: string;

    isVisible: boolean = false;
    submitted: boolean = false;

    get getThis() {
        return this;
    }

    changeOverlay(newOverlay: Vue | Element | Vue[] | Element[]) {
        var correct = newOverlay as unknown as ICurtainOverlayBase;
        this.overlay.overlayChanged(correct);
    }
}
