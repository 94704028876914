
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component
export default class ButtonSubmit extends Vue {
    @Prop({ type: Boolean }) pending!: boolean;
    @Prop({ type: String, default: 'primary' }) variant!: string;
    @Prop({ type: String, default: '' }) customClasses!: string;
    @Prop({ type: Boolean, default: true }) showIcon!: boolean;
    @Prop({ type: Boolean }) success!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop({ type: String, default: '' }) iconColor!: string;
    @Prop({ type: String, default: '' }) label!: boolean;
    @Prop({ type: String }) successLabel!: string;
    @Prop({ type: String, default: 'sanicon-ico-basket' }) icon!: string;
    @Prop({ type: String, default: '' }) title!: string;

    get successClass(): string {
        return this.success ? 'c-btn--success' : '';
    }

    get isSuccessFull(): boolean {
        return this.success && isNotNullEmptyOrUndefined(this.successLabel);
    }

    get iconToshow(): string {
        if (this.success) {
            return 'sanicon-ico-check-mark-bold';
        }

        return this.icon;
    }

    get buttonIconType(): string {
        return this.showIcon && !this.label
            ? this.$enums.BUTTON_ICON_TYPE.ICON_ONLY
            : this.$enums.BUTTON_ICON_TYPE.NONE;
    }
}
