



































import { Component } from 'vue-property-decorator';
import BaseBanner from '@/components/molecules/Banner/BaseBanner.vue';
import breakpointService from '@/core/responsive/breakpoints/breakpoints.service';

@Component({
    components: {
        BaseBanner
    }
})
export default class HeroBanner extends BaseBanner {
    isMobile: boolean = false;

    get slotName() {
        return this.fullWidth && !this.isMobile ? 'imageText' : 'text';
    }

    get fullWidthIfDesktop(): boolean {
        return this.fullWidth && !this.isMobile;
    }

    get headingClasses(): string {
        let classes = 'font-w500 text-40 mb-0';
        classes += this.preheader ? ' pt-8 md:pt-16' : '';
        classes += !this.fullWidth ? ' lg:text-50 xl:text-68' : '';
        return classes;
    }

    mounted() {
        breakpointService.addListener(() => this.isMobileActive());
    }

    isMobileActive() {
        this.isMobile = breakpointService.isActiveBreakpoint('xs, sm');
    }
}
