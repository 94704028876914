











import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import { BasketGetter } from '@/store/basket';
import { LoginInformation, SalesAccountViewObject } from '@/types/serverContract';

@Component
export default class CurrentAccountInfo extends Vue {
    @AppGetter loginInformation!: LoginInformation;
    @BasketGetter subTotal!: string;

    get currentAccount(): SalesAccountViewObject | null {
        return this.loginInformation ? this.loginInformation.currentAccount : null;
    }

    get accountDisplayName(): string {
        return this.currentAccount ? `${this.currentAccount.soldToPartnerId} ${this.currentAccount.soldToPartnerName}` : '';
    }
}
