

























import { Vue, Component, Prop } from 'vue-property-decorator';
import { SuggestionViewObject } from '@/types/serverContract';

@Component
export default class SearchPanelBrandSuggestionsSlider extends Vue {
    @Prop({
        type: Array,
        required: true
    }) suggestions!: SuggestionViewObject[];

    @Prop({
        type: String,
        default: ''
    }) btnClasses!: string;

    flickityOptions = {
        wrapAround: false,
        freeScroll: true,
        setGallerySize: true,
        contain: true,
        prevNextButtons: true,
        pageDots: false,
        percentPosition: false,
        cellAlign: 'left'
    }

    onSuggestionClick(term): void {
        this.$emit('onSuggestionClick', term);
    }

    clearSelectedSuggestion(): void {
        this.$emit('clearSelectedSuggestion');
    }
}
