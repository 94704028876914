





























































import Vue from 'vue';
import Component from 'vue-class-component';
import { QuickAddToBasketRequest, QuickAddToBasketResultViewModel } from '@/types/serverContract';
import Api from '@/project/http/api';
import ProductAddToBasketLifeCycleIcons from '@/project/products/ProductAddToBasketLifeCycleIcons.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import TextInput from '@/project/form/TextInput.vue';
import { FlexBoxGetter } from '@/store/flexBox';
import keyboardService from '@/core/keyCodes';
import productTrackingService from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';

@Component({
    components: {
        ProductAddToBasketLifeCycleIcons,
        ButtonSubmit
    }
})
export default class QuickOrderForm extends Vue {
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    pending = false;
    productIdError = '';
    quantityError = '';
    quantity: string = '1';
    productId: string = '';
    success = false;
    successTimer = 3000;
    timeout: number | null = null;

    $refs!: {
        productId: TextInput;
        productQuantity: TextInput;
    };

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async saveQuickOrder() {
        this.pending = true;

        const quickAddToBasketPayload: QuickAddToBasketRequest = {
            productId: this.productId.trim(),
            quantity: parseInt(this.quantity, 10)
        };

        this.resetForm();

        try {
            let added: QuickAddToBasketResultViewModel = await Api.basket.quickAddToBasket(
                quickAddToBasketPayload,
                'quick-order'
            );
            if (added.addedLine) {
                productTrackingService.TrackProduct(
                    PRODUCT_TRACKING_EVENT.AddToCart,
                    productTrackingService.ToTrackedProduct(
                        added.addedLine,
                        PRODUCT_TRACKING_TYPE.AddLineItemViewObject
                    )
                );
            }
            this.showSuccessIndicator();
        } finally {
            this.pending = false;
        }
    }

    // We have to override pressing the enter key on key down because IE changes focus to the forms submit button when pressing the "Enter" key in an input field
    // This means that in IE both "document.activeElement" and "document.querySelector(':focus')" are both the button when the form is submitted
    handleProductIdKeyDown(event: KeyboardEvent) {
        if (keyboardService.isEnter(event)) {
            event.preventDefault();
            (this.$refs.productQuantity.$refs.input as HTMLInputElement).select();
        }
    }

    resetForm(): void {
        this.productId = '';
        this.quantity = '1';
        (this.$refs.productId.$refs.input as HTMLInputElement).focus();
        this.$validator.reset();
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }
}
