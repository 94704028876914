
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DeliveryDigitalCommunication extends Vue {
    @Prop({
        required: true,
        type: String
    }) digitalCommunicationEmail!: string;

    email: string = this.digitalCommunicationEmail;

    setDigitalCommunicationEmail(): void {
        this.$emit('update:digitalCommunicationEmail', this.email);
    }
}
