















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getVideoSrc, getVideoId, getVideoPlatform, VideoPlatform } from '@/components/atoms/video/getVideoSrc';
import YTPlayer from './YTPlayer.vue';
import VimeoPlayer from './VimeoPlayer.vue';

@Component({
    components: {
        YTPlayer,
        VimeoPlayer
    }
})
export default class VideoPlayer extends Vue {
    @Prop({
        default: '',
        type: String
    }) source!: string;

    @Prop({
        default: false,
        type: Boolean
    }) autoplay!: boolean;

    win = window as any;
    player: any = null;
    isPlayerReady = false;
    userInteracted = false;
    ytPlayerWasVisible = false;

    get src() {
        return getVideoSrc(this.source);
    }

    get isYoutube() {
        return getVideoPlatform(this.source) === VideoPlatform.Youtube;
    }

    get isVimeo() {
        return getVideoPlatform(this.source) === VideoPlatform.Vimeo;
    }

    get videoId() {
        return getVideoId(this.source);
    }
}
