





























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ProjectOrderQuotationSearchForm from '@/project/myServices/projectFolder/ProjectOrderQuotationSearchForm.vue';
import { ProjectOrderQuotationListViewModel } from '@/types/serverContract';
import QueryPaging from '@/project/shared/QueryPaging.vue';
import ProjectOrderQuotationHeader from '@/project/myServices/projectFolder/ProjectOrderQuotationHeader.vue';
import BookkeepingUrlHelper from '@/project/bookkeeping/BookkeepingUrlHelper.service';
import scrollService from '@/core/scroll/scroll.service';
import { headerHeight } from '@/core/responsive/breakpoints/breakpoints.service';

@Component({
    components: { ProjectOrderQuotationHeader, QueryPaging, ProjectOrderQuotationSearchForm }
})
export default class ProjectOrderQuotationList extends Vue {
    projectOrderQuotationList: ProjectOrderQuotationListViewModel | null = null;
    currentPage: number = 1;

    public $refs!: {
        QuotationList: HTMLElement;
    };

    updateProjectOrderQuotationList(projectOrderQuotationList: ProjectOrderQuotationListViewModel): void {
        this.projectOrderQuotationList = projectOrderQuotationList;
        this.currentPage = BookkeepingUrlHelper.getPageNumber();
        scrollService.scrollToElement(this.$refs.QuotationList, -headerHeight(true));
    }
}
