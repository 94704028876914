import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    AddFavoriteAccountRequest,
    FavoriteSalesAccountViewObject,
    MoveFavoriteAccountRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { namespace } from 'vuex-class';

// Set dataTypes for the data that is in the store.
interface IHelpDeskState {
    favoriteAccounts: FavoriteSalesAccountViewObject[] | null;
    favoriteAccountsPending: boolean;
}

// Define MutationMethods as enum to make it easier to work with later in the code.
enum MutationMethod {
    setFavoriteAccounts = 'setFavoriteAccounts',
    setFavoriteAccountsBusy = 'setFavoriteAccountsBusy'
}

// Set default values for data in the store
const storeState: IHelpDeskState = {
    favoriteAccountsPending: false,
    favoriteAccounts: null
};

// Define the "get able" data from outside the store.
const getters: GetterTree<IHelpDeskState, any> = {
    isFavoriteAccountsBusy: state => state.favoriteAccountsPending,
    favoriteAccounts: state => state.favoriteAccounts
};

const actions: ActionTree<IHelpDeskState, any> = {
    async getFavoriteSalesAccounts({ commit }) {
        commit(MutationMethod.setFavoriteAccountsBusy, true);
        return Api.helpDesk.favoriteSalesAccounts().then(res => {
            commit(MutationMethod.setFavoriteAccounts, res.favoriteSalesAccounts);
        }).finally(() => {
            commit(MutationMethod.setFavoriteAccountsBusy, false);
        });
    },

    deleteFavoriteSalesAccount({ commit }, accountId: number) {
        commit(MutationMethod.setFavoriteAccountsBusy, true);
        return Api.helpDesk.deleteFavoriteSalesAccount(accountId).then(res => {
            commit(MutationMethod.setFavoriteAccounts, res.favoriteSalesAccounts);
        }).finally(() => {
            commit(MutationMethod.setFavoriteAccountsBusy, false);
        });
    },
    moveFavoriteSalesAccount({ commit }, moveFavoriteAccountRequestPayload: MoveFavoriteAccountRequest) {
        commit(MutationMethod.setFavoriteAccountsBusy, true);
        return Api.helpDesk.moveFavoriteSalesAccount(moveFavoriteAccountRequestPayload).then(res => {
            commit(MutationMethod.setFavoriteAccounts, res.favoriteSalesAccounts);
        }).finally(() => {
            commit(MutationMethod.setFavoriteAccountsBusy, false);
        });
    },
    addAccountToFavoriteAccounts({ commit }, AddFavoriteAccountRequestPayload: AddFavoriteAccountRequest) {
        commit(MutationMethod.setFavoriteAccountsBusy, true);
        return Api.helpDesk.favoriteSalesAccount(AddFavoriteAccountRequestPayload).then(res => {
            commit(MutationMethod.setFavoriteAccounts, res.favoriteSalesAccounts);
        }).finally(() => {
            commit(MutationMethod.setFavoriteAccountsBusy, false);
        });
    }
};

const mutations: MutationTree<IHelpDeskState> = {
    [MutationMethod.setFavoriteAccountsBusy](state, pending: boolean) {
        state.favoriteAccountsPending = pending;
    },
    [MutationMethod.setFavoriteAccounts](state, favoriteAccounts: FavoriteSalesAccountViewObject[]) {
        state.favoriteAccounts = favoriteAccounts;
    }
};

export default {
    namespaced: true,
    state: storeState,
    getters,
    actions,
    mutations
};

export const { Getter: HelpDeskGetter, Action: HelpDeskAction } = namespace('helpDesk');
