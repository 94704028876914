









































































































































































































































import {
    LoginInformation,
    SalesAccountViewObject,
    AccountGroupResponseSlimViewModel,
    CreateOrUpdateFavoriteOrderRequest
} from '@/types/serverContract';
import CurtainOverlayBase from '@/components/organisms/CurtainOverlay/overlays/CurtainOverlayBase.vue';
import { ICurtainOverlayBase } from '@/components/organisms/CurtainOverlay/overlays/ICurtainOverlayBase.vue';
import RadioButtonGroup from '@/components/organisms/RadioButtonGroup/RadioButtonGroup.vue';
import HandleAdministrationGroups from './HandleAdministrationGroups.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import Api from '@/project/http/api';
import Cookies from 'js-cookie';
import { CREATE_UPDATE, SHARED_STATUS } from '@/core/enums/enums';
import { UserGetter } from '@/store/user';

interface RadioButtonGroupOption {
    description: string;
    icon: string;
    label: string;
    selected?: boolean;
    value: string | boolean;
}

export interface AccountGroupResponseSlimWithSelectedState
    extends AccountGroupResponseSlimViewModel {
    selected?: boolean;
}

@Component({
    components: {
        RadioButtonGroup,
        HandleAdministrationGroups,
        SpinnerOverlay
    }
})
export default class FavoriteOrderAdministrationOverlay
    extends CurtainOverlayBase
    implements ICurtainOverlayBase {
    @Prop() FavoriteOrderRequestPayload: CreateOrUpdateFavoriteOrderRequest;
    @Prop({ type: String, default: CREATE_UPDATE.Create }) action!: CREATE_UPDATE;
    @AppGetter loginInformation!: LoginInformation;
    @UserGetter isAdmin!: boolean;

    localPayload: CreateOrUpdateFavoriteOrderRequest = new (class {
        caseId: string = '';
        name: string = '';
        favoriteOrderId: number | null = null;
        referenceId: string = '';
        requestId: string = '';
        readonly: boolean = true;
        accountGroupIds: string[] = [];
        shared: boolean = false;
    })();

    readonlyOptionsDict: Record<SHARED_STATUS, RadioButtonGroupOption> = {
        [SHARED_STATUS.Readonly]: {
            description: this.$tr(
                this.$labels.Views.FavoriteOrder.AdministrationOverlay.ReadonlyDescription
            ),
            icon: 'sanicon-ico-view',
            label: this.$tr(this.$labels.Views.FavoriteOrder.AdministrationOverlay.ReadonlyLabel),
            selected: true,
            value: true
        },
        [SHARED_STATUS.Editable]: {
            description: this.$tr(
                this.$labels.Views.FavoriteOrder.AdministrationOverlay.EditableDescription
            ),
            icon: 'sanicon-ico-edit',
            label: this.$tr(this.$labels.Views.FavoriteOrder.AdministrationOverlay.EditableLabel),
            selected: false,
            value: false
        }
    };

    pending: boolean = false;
    showReferenceInputs: boolean = true;
    referenceSectionHeight: number = 0;
    showReadonlySection: boolean = true;
    readonlySectionHeight: number = 0;
    accountGroupList: AccountGroupResponseSlimWithSelectedState[] | null = null;
    useAccountGroupsFlag: boolean = false; // TODO SECOM-1890: Remove this check once account groups has been tested thoroughly.

    private vvFields!: any;

    get readonlyOptions(): RadioButtonGroupOption[] {
        return Object.values(this.readonlyOptionsDict);
    }

    get anyReferenceRequired(): boolean {
        return !!(
            this.currentAccount &&
            (this.currentAccount.requisitionNumber.required ||
                this.currentAccount.referenceContactPerson.required ||
                this.currentAccount.caseNumber.required)
        );
    }

    get currentAccount(): SalesAccountViewObject | null {
        return this.loginInformation ? this.loginInformation.currentAccount : null;
    }

    get isShared(): boolean {
        return !!this.FavoriteOrderRequestPayload.shared;
    }

    get isEditable(): boolean {
        return !!(this.isShared && !this.FavoriteOrderRequestPayload.readonly);
    }

    get hasAccountGroups(): boolean {
        return !!(this.localPayload.accountGroupIds && this.localPayload.accountGroupIds.length);
    }

    get selectedAccountGroups(): AccountGroupResponseSlimWithSelectedState[] {
        if (
            !this.accountGroupList ||
            !this.accountGroupList.length ||
            !this.localPayload.accountGroupIds ||
            !this.localPayload.accountGroupIds.length
        ) {
            return [];
        }
        return this.accountGroupList.filter(accountGroup =>
            this.localPayload.accountGroupIds.some(id => id === accountGroup.id)
        );
    }

    get computedReferenceSectionHeight(): string {
        if (this.referenceSectionHeight === 0) {
            return '';
        }
        return `--transition-height: ${this.referenceSectionHeight}px; --height-hidden: 0px;`;
    }

    get computedReadonlySectionHeight(): string {
        if (this.readonlySectionHeight === 0) {
            return '';
        }
        return `--transition-height: ${this.readonlySectionHeight}px; --height-hidden: 0px;`;
    }

    get isFieldsValid(): boolean {
        return !Object.values(this.vvFields).some((field: any) => field.invalid);
    }

    @Watch('isFieldsValid')
    UpdateSubmitButton(): void {
        if (this.overlay.activeCurtainOverlay === this) {
            this.overlay.submitButtonEnabled = this.isFieldsValid;
        }
    }

    get accountGroupDisclaimer(): string {
        if (!this.currentAccount) {
            return '';
        }

        if (!this.isShared && this.hasAccountGroups) {
            return `${this.$tr(
                this.$labels.Views.FavoriteOrder.AdministrationOverlay.DisclaimerNotSharedHasGroups
            )}`;
        } else if (!this.isShared && this.currentAccount) {
            return `${this.$tr(
                this.$labels.Views.FavoriteOrder.AdministrationOverlay.DisclaimerNotShared
            )} <strong>${this.currentAccount.soldToPartnerName}</strong>.`;
        } else if (!this.isEditable && this.hasAccountGroups) {
            return `${this.$tr(
                this.$labels.Views.FavoriteOrder.AdministrationOverlay.DisclaimerReadonlyHasGroups
            )}`;
        } else if (this.currentAccount && !this.isEditable) {
            return `${this.$tr(
                this.$labels.Views.FavoriteOrder.AdministrationOverlay.DisclaimerReadonly
            )} <strong>${this.currentAccount.soldToPartnerName}</strong>.`;
        } else if (this.isEditable && this.hasAccountGroups) {
            return `${this.$tr(
                this.$labels.Views.FavoriteOrder.AdministrationOverlay.DisclaimerEditableHasGroups
            )}`;
        } else {
            return `${this.$tr(
                this.$labels.Views.FavoriteOrder.AdministrationOverlay.DisclaimerEditable
            )} <strong>${this.currentAccount.soldToPartnerName}</strong>.`;
        }
    }

    created() {
        this.updateOverlayPayload(this.FavoriteOrderRequestPayload);
        this.getAccountGroups();
    }

    mounted() {
        switch (this.action) {
            case CREATE_UPDATE.Create:
                this.titleText = this.$tr(
                    this.$labels.Views.FavoriteOrder.AdministrationOverlay.OverlayHeadline
                );
                this.submitButtonText = this.$tr(
                    this.$labels.Views.FavoriteOrder.AdministrationOverlay.SubmitButtonText
                );
                break;
            case CREATE_UPDATE.Update:
                this.titleText = this.$tr(
                    this.$labels.Views.FavoriteOrder.AdministrationOverlay.Edit.Headline
                );
                this.submitButtonText = this.$tr(
                    this.$labels.Views.FavoriteOrder.AdministrationOverlay.Edit.SubmitText
                );
                break;
        }
        this.overlay.overlayChanged(this);

        this.$nextTick(() => {
            const referenceSection = this.$refs.referenceSection as HTMLElement;
            const readonlySection = this.$refs.readonlySection as HTMLElement;
            this.referenceSectionHeight = referenceSection.getBoundingClientRect().height;
            this.readonlySectionHeight = readonlySection.getBoundingClientRect().height;
            this.showReferenceInputs = false;
            this.showReadonlySection = false;

            if (this.localPayload.shared) {
                if (!this.localPayload.readonly) {
                    this.readonlyOptionsDict[SHARED_STATUS.Readonly].selected = false;
                    this.readonlyOptionsDict[SHARED_STATUS.Editable].selected = true;
                }
            }
            this.toggleShared(this.localPayload.shared);
            // TODO SECOM-1890: Remove this section once account groups has been tested thoroughly.
            const enableAccountGroups = Cookies.get('showAccountGroups');
            if (!enableAccountGroups) {
                return;
            }

            this.useAccountGroupsFlag = true;
        });
    }

    Active(): void {
        if (!this.accountGroupList) {
            return;
        }
        this.accountGroupList = this.accountGroupList.map(v => ({
            ...v,
            selected: false
        }));
        this.accountGroupList.forEach(accountGroup => {
            accountGroup.selected = this.localPayload.accountGroupIds.includes(accountGroup.id);
        });
    }

    prependAsterix(text: string, required: boolean) {
        return `${text} ${required ? '*' : ''}`;
    }

    updateOverlayPayload(payload: CreateOrUpdateFavoriteOrderRequest) {
        this.localPayload = payload;
        this.overlay.payload = this.localPayload;
    }

    async getAccountGroups() {
        this.pending = true;

        try {
            const accountGroupList = await Api.accountGroup.GetAll();

            if (!(accountGroupList instanceof Array)) {
                return;
            }

            this.accountGroupList = accountGroupList.map(v => ({
                ...v,
                selected: false
            }));
        } finally {
            this.pending = false;
            this.UpdateSubmitButton();
            if (this.accountGroupList) {
                this.accountGroupList.forEach(accountGroup => {
                    accountGroup.selected = this.localPayload.accountGroupIds.includes(
                        accountGroup.id
                    );
                });
            }
        }
    }

    toggleReference() {
        this.showReferenceInputs = !this.showReferenceInputs;
    }

    toggleShared(isShared) {
        this.localPayload.shared = isShared;
        this.toggleReadonlySection(isShared);
    }

    toggleReadonlySection(showSection) {
        this.showReadonlySection = showSection;
    }

    updateAccountGroups() {
        if (!this.accountGroupList) {
            return;
        }
        this.localPayload.accountGroupIds = this.accountGroupList
            .filter(x => x.selected)
            .map(x => x.id);
    }

    toggleAddGroupOverlay() {
        // Add logic for add group overlay here
    }

    updateSelectedReadonlyOption(value) {
        this.readonlyOptions.forEach(option => {
            option.selected = false;
        });
        const changedOption = this.readonlyOptions.find(option => option.value === value);
        if (!changedOption) {
            return;
        }
        changedOption.selected = true;
        this.localPayload.readonly = value;
    }

    removeAccountGroup(accountGroupId) {
        this.localPayload.accountGroupIds = this.localPayload.accountGroupIds.filter(
            id => id !== accountGroupId
        );
        const currentAccountGroup =
            this.accountGroupList &&
            this.accountGroupList.find(accountGroup => accountGroup.id === accountGroupId);

        if (!currentAccountGroup) {
            return;
        }

        currentAccountGroup.selected = false;
    }

    Submit(): Promise<void> {
        this.$emit('submit');
        return Promise.resolve();
    }
}
