import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import clientServerVersionInterceptor from './clientServerVersion.interceptor';
import redirectInterceptor from './redirect.interceptor';
import { requestHandler as validationErrorsRequestInterceptor, responseHandler as validationErrorsResponseInterceptor } from './validationErrors.interceptor';
import { responseHandler as basketInterceptor } from './basket.interceptor';
import { responseHandler as trackSearchResultInterceptor } from './track-search-result.interceptor';
import qs from 'querystring';
import isArray from 'lodash-es/isArray';
import forEach from 'lodash-es/forEach';
import HttpStatus from 'http-status-codes';
import { ClientMessageType, PageDataViewModel } from '@/types/serverContract';
import { addApiMessages } from '@/store/messages';
import serverContext from '@/core/serverContext.service';
import bus from '@/core/bus';
import { debounce } from 'lodash-es';

declare global {
    interface Window {
        prerenderReady: boolean;
    }
}

window.prerenderReady = false;

function handleOutstandingXhrCalls(hasOutstandingXhrCalls: boolean) {
    if (window.prerenderReady) {
        bus.off(HasOutstandingXhrCallsEventKey, handleOutstandingXhrCalls); // eslint-disable-line @typescript-eslint/no-use-before-define
    }

    if (!hasOutstandingXhrCalls) {
        window.prerenderReady = true;
    }
}

const HasOutstandingXhrCallsEventKey = 'NoOfOutstandingXhrCallsEventKey';
const debouncedHandleOutstandingXhrCalls = debounce(handleOutstandingXhrCalls, 10);

export class HttpService {
    _outstandingRequests = 0;

    private responseInterceptors: Array<(value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>>
    = [clientServerVersionInterceptor, redirectInterceptor, validationErrorsResponseInterceptor, basketInterceptor, trackSearchResultInterceptor];

    private requestInterceptors: Array<(value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>> = [
        validationErrorsRequestInterceptor
    ];

    private pageCancelTokenSrc: CancelTokenSource | null = null;

    constructor() {
        this.registerInterceptors();

        // workaround for pages without any XHR calls
        // start with sending a ready signal.
        // if there are any XHR requests it will be debounced.
        this.sendHasOutstandingXhrCallsEvent(false);

        // Those status'es that should cause "then" to be executed (so we can have interceptors)
        axios.defaults.validateStatus = status => {
            return (
                (status >= 200 && status < 300) || this.isErrorHandledAsNonError(status)
            );
        };

        axios.defaults.paramsSerializer = params => {
            let sortedParams = this.getSortedParams(params);
            return qs.stringify(sortedParams);
        };

        axios.defaults.headers = {
            contentType: 'application/json',
            sitename: serverContext.Localization.siteName,
            xhr: 'true'
        };
    }

    public async get<T>(relativeUrl: string, params?: any): Promise<T> {
        this.outstandingRequests++;
        try {
            return axios
                .get(this.getUrl(relativeUrl), {
                    data: {},
                    params
                })
                .then(res => {
                    if (res.status === 500 && res.data.content === undefined) {
                        this.handleErrorResponse(res);
                        return Promise.reject(res.statusText);
                    }
                    return res.data.model;
                })
                .catch(err => {
                    this.handleErrorResponse(err);
                });
        } finally {
            this.outstandingRequests--;
        }
    }

    public async delete<T>(relativeUrl: string, params?: any): Promise<T> {
        this.outstandingRequests++;
        try {
            return axios
                .delete(this.getUrl(relativeUrl), {
                    data: {},
                    params
                })
                .then(res => res.data.model)
                .catch(err => this.handleErrorResponse(err));
        } finally {
            this.outstandingRequests--;
        }
    }

    public async post<T>(relativeUrl: string, payload?: any, messagesId?: string, params?: any): Promise<T> {
        this.outstandingRequests++;
        try {
            return axios
                .post(this.getUrl(relativeUrl), payload, {
                    messagesId,
                    params
                } as any)
                .then(res => {
                    return this.handlePutAndPostResponse(res);
                })
                .catch(err => this.handleErrorResponse(err));
        } finally {
            this.outstandingRequests--;
        }
    }

    public async put<T>(relativeUrl: string, payload?: any, messagesId?: string): Promise<T> {
        this.outstandingRequests++;
        try {
            return axios
                .put(this.getUrl(relativeUrl), payload, {
                    messagesId
                } as any)
                .then(res => {
                    return this.handlePutAndPostResponse(res);
                })
                .catch(err => this.handleErrorResponse(err));
        } finally {
            this.outstandingRequests--;
        }
    }

    async getPage(relativeUrl: string): Promise<PageDataViewModel | null> {
        this.outstandingRequests++;
        try {
            if (this.pageCancelTokenSrc) {
                this.pageCancelTokenSrc.cancel();
            }
            this.pageCancelTokenSrc = axios.CancelToken.source();

            try {
                const res = await axios.get<PageDataViewModel>(relativeUrl, {
                    headers: { Accept: 'application/json' },
                    params: { xhr: true },
                    cancelToken: this.pageCancelTokenSrc.token
                });

                // If this is an error response, and it does not look like a PageDataViewModel, then reject the promise.
                // If it _does_ look like a PageDataViewModel and has 'content', then the server should ensure that this content is compatible with Vue (No standard YSOD pages )
                if (res.status === 500 && res.data.content === undefined) {
                    this.handleErrorResponse(res);
                    return Promise.reject(res.statusText);
                }
                return res.data;
            } catch (err) {
                if (axios.isCancel(err)) {
                // Request was canceled - no response
                    this.pageCancelTokenSrc = null;
                    return null;
                }
                return Promise.reject(err);
            } finally {
                this.pageCancelTokenSrc = null;
            }
        } finally {
            this.outstandingRequests--;
        }
    }

    private handleErrorResponse(error: any): void {
        if (error.status === 500) {
            let message;
            if (error.data && error.data.exceptionMessage) {
                message = error.data.exceptionMessage;
            } else if (error.data && error.data.clientMessage && error.data.clientMessage.messages[0] && error.data.clientMessage.messages[0].message) {
                message = error.data.clientMessage.messages[0].message;
            } else {
                message = null;
            }

            if (message === null) {
                if (error.statusText) {
                    message = error.statusText;
                } else {
                    message = window.dictionary['errors.Unknown'];
                }
            }
            addApiMessages([{ message, messageType: ClientMessageType.Error }]);
        }
        throw error;
    }

    public getUrl(relativeUrl: string): string {
        function removeLeadingSlash(url: string) {
            return url.charAt(0) === '/' ? url.substr(1) : url;
        }

        relativeUrl = removeLeadingSlash(relativeUrl);
        return `/api/${relativeUrl}`;
    }

    private registerInterceptors() {
        this.responseInterceptors.forEach(i => axios.interceptors.response.use(i));
        this.requestInterceptors.forEach(i => axios.interceptors.request.use(i));
    }

    public getSortedParams(params: { [key: string]: string | string[] }): { [key: string]: string | string[] } {
        let keys: string[] = [];

        forEach(params, (value, key: string) => {
            keys.push(key);
        });

        let sortedParams = {};
        let sortedKeys = keys.sort();

        sortedKeys.forEach(value => {
            let searchValue = params[value];
            let sortedValues = isArray(searchValue) ? searchValue.sort() : searchValue;
            sortedParams[value] = sortedValues;
        });

        return sortedParams;
    }

    private isErrorHandledAsNonError(status: number): boolean {
        // List of statuscodes which we allow as "not" errors (to make interceptors run). We receive them in "then" and treat them like errors there. Dough.
        return status === HttpStatus.BAD_REQUEST || status === HttpStatus.INTERNAL_SERVER_ERROR || status === HttpStatus.UNAUTHORIZED;
    }

    private handlePutAndPostResponse(res: AxiosResponse) {
        if (this.isErrorHandledAsNonError(res.status)) {
            // Validatestatus above is set to include these ones so it will trigger 'then'.
            // Reason is that interceptors will then be run automatically on this as well.
            return Promise.reject(res);
        }
        return res.data.model;
    }

    private set outstandingRequests(value: number) {
        this._outstandingRequests = value;
        this.sendHasOutstandingXhrCallsEvent(value > 0);
    }

    private get outstandingRequests() {
        return this._outstandingRequests;
    }

    private sendHasOutstandingXhrCallsEvent(value: boolean) {
        bus.emit(HasOutstandingXhrCallsEventKey, value);
    }
}

bus.on(HasOutstandingXhrCallsEventKey, debouncedHandleOutstandingXhrCalls);

export default new HttpService();
