






































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import imageService from '@/core/image.service';
import { LinkViewModel } from '@/types/serverContract';
import { BRIGHTNESS_MODE, PROMO_TRACKING_EVENT } from '@/core/enums/enums';
import ImageWithOverlay from '@/components/molecules/ImageWithOverlay/ImageWithOverlay.vue';
import breakpointsService from '@/core/responsive/breakpoints/breakpoints.service';
import { IPromoActionField } from '@/core/tracking/promotionTracking.g4-structure';
import promotionTrackingService from '@/core/tracking/promotionTracking.service';

@Component({
    components: {
        ImageWithOverlay
    }
})
export default class BaseBanner extends Vue {
    @Prop({ type: String }) preheader!: string;
    @Prop({ type: String }) header!: string;
    @Prop({ type: String }) subheader!: string;
    @Prop({ default: 'E0EAF2', type: String }) textBackgroundColor!: string;
    @Prop({ default: false, type: Boolean }) textAlignmentRight!: boolean;
    @Prop({ type: Object }) ctaButton!: LinkViewModel;
    @Prop({ default: '', type: String }) image!: string;
    @Prop({ type: Object }) imageLink!: LinkViewModel;

    @Prop({ type: Object }) trackingActionField!: IPromoActionField;

    @Prop({ default: true, type: Boolean }) showCtaButton!: boolean;
    @Prop({ default: false, type: Boolean }) fullWidth!: boolean;
    @Prop({ default: false, type: Boolean }) disableOverlay!: boolean;

    colorMode: BRIGHTNESS_MODE = BRIGHTNESS_MODE.Light;
    imageError: boolean = false;

    onMobileAndNoImage: boolean = false;

    mounted() {
        this.colorMode = this.$helper.lightOrDark(this.textBackgroundColor);
        if (this.fullWidth) {
            breakpointsService.addContinuousResizeListener(this.checkWindowSize);
        }
    }

    checkWindowSize() {
        const onMobile = breakpointsService.isActiveBreakpoint('xs, sm');
        this.onMobileAndNoImage = onMobile && !this.image;
        this.$nextTick(() => {
            if (this.fullWidth) {
                this.adjustAspectRatioForFullWidth();
            } else {
                this.resetAspectRatio();
            }
        });
    }

    adjustAspectRatioForFullWidth() {
        const baseBannerImageContainer = this.$refs.baseBannerImageContainer as HTMLElement;
        if (baseBannerImageContainer) {
            const overlay = this.getOverlay(baseBannerImageContainer);
            if (overlay) {
                this.adjustAspectRatio(baseBannerImageContainer, overlay);
            }
        }
    }

    getOverlay(baseBannerImageContainer: HTMLElement) {
        if (this.image) {
            return baseBannerImageContainer.querySelector('.a-image-container__overlay') as HTMLElement;
        }
        return baseBannerImageContainer.querySelector('.__no-image-container') as HTMLElement;
    }

    adjustAspectRatio(baseBannerImageContainer: HTMLElement, overlay: HTMLElement) {
        (baseBannerImageContainer.style as CSSStyleDeclaration)['aspectRatio'] = '';
        const overlayHeight = overlay.offsetHeight;
        const containerHeight = baseBannerImageContainer.offsetHeight;
        if (overlayHeight > containerHeight) {
            (baseBannerImageContainer.style as CSSStyleDeclaration)['aspectRatio'] = 'inherit';
        } else {
            (baseBannerImageContainer.style as CSSStyleDeclaration)['aspectRatio'] = '';
        }
    }

    resetAspectRatio() {
        const baseBannerImageContainer = this.$refs.baseBannerImageContainer as HTMLElement;
        if (baseBannerImageContainer) {
            (baseBannerImageContainer.style as CSSStyleDeclaration)['aspectRatio'] = '';
        }
    }

    get getBackgroundColorIfNoImage(): string {
        return this.image ? '' : 'bg-' + this.$helper.hexToColor['#' + this.textBackgroundColor];
    }

    get getImageContainerComponentType(): string {
        return this.image ? 'ImageWithOverlay' : 'div';
    }

    get imageProps() {
        return {
            ref: 'baseBannerImageContainer',
            source: this.imageUrl,
            alt: this.header,
            loading: this.$enums.IMAGE_LOADING.Lazy,
            classes: 'w-full h-full object-cover',
            'v-error': this.onError,
            addCurtain: this.fullWidth && !this.disableOverlay,
            curtainClasses: ''
        };
    }

    get imageContainerStyle(): Partial<CSSStyleDeclaration> {
        const styles: Partial<CSSStyleDeclaration> = {};

        if (this.image) {
            styles.backgroundImage = `url('${imageService.getWebpCompatibleOptimizedSource(this.image)}')`;
        }

        return styles;
    }

    get imageUrl(): string {
        return imageService.getWebpCompatibleOptimizedSource(this.image);
    }

    get textContainerStyle(): Partial<CSSStyleDeclaration> {
        const styles: Partial<CSSStyleDeclaration> = {};

        if (this.textBackgroundColor) {
            styles.backgroundColor = `#${this.textBackgroundColor}`;
        }

        return styles;
    }

    trackPromotionClick(): void {
        promotionTrackingService.TrackPromotion(
            PROMO_TRACKING_EVENT.PromotionClick,
            this.trackingActionField
        );
    }

    onError(): void {
        this.imageError = true;
    }
}
