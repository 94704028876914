




















































import Component from 'vue-class-component';
import { Vue, Prop } from 'vue-property-decorator';
import tracking, { NavigationClicksTypes } from '@/core/tracking/tracking.service';

@Component
export default class DropdownCtrlItem extends Vue {
    @Prop({ type: String }) to!: string;
    @Prop({ type: String }) subTitle!: string;
    @Prop({ type: String }) date!: string;
    @Prop({ type: String }) title!: string;
    @Prop({ type: String }) orderID!: string;
    @Prop({ type: Boolean }) loading!: string;
    @Prop({ default: false, type: Boolean }) isDownload!: boolean;
    @Prop({ default: false, type: Boolean }) minimal!: boolean;
    @Prop({ default: 'font-semi-bold', type: String }) titleClass!: string;

    @Prop({ type: String }) trackingType!: NavigationClicksTypes;
    @Prop({ type: String }) trackingParentExplainer!: string;
    @Prop({ default: false, type: Boolean }) noTruncate!: boolean;

    navigationTracking(text: string) {
        if (this.trackingType) {
            tracking.trackNavigationClick({ type: this.trackingType, clickText: this.trackingParentExplainer + text });
        }
    }
}
