




























































import Vue from 'vue';
import Component from 'vue-class-component';
import PageHeaderTop from './PageHeaderTop.vue';
import PageHeaderDesktopMenu from './PageHeaderDesktopMenu.vue';
import PageHeaderDesktopUserMenu from './PageHeaderDesktopUserMenu.vue';
import { Prop } from 'vue-property-decorator';
import {
    LinkItem,
    NavigationViewModel,
    TopLevelNavigationItem,
    LinkViewModel
} from '@/types/serverContract';
import ApiMessages from '@/project/messages/ApiMessages.vue';
import { AppGetter } from '@/store/app';
import scrollService from '@/core/scroll/scroll.service';
import breakpointService from '@/core/responsive/breakpoints/breakpoints.service';

@Component({
    components: {
        ApiMessages,
        PageHeaderDesktopUserMenu,
        PageHeaderTop,
        PageHeaderDesktopMenu
    }
})
export default class PageHeader extends Vue {
    @Prop({ required: true, type: Object }) navigation!: NavigationViewModel;
    @Prop({ default: false, type: Boolean }) isSubsidiarySite!: boolean;
    @Prop({ default: false, type: Boolean }) hasShopLink!: boolean;
    @Prop({ default: () => {}, type: Object }) shopLink!: LinkViewModel;
    @Prop({ default: false, type: Boolean }) hasCustomLogo!: boolean;
    @Prop({ default: '', type: String }) customLogoUrl!: string;

    @AppGetter isLoggedIn!: boolean;
    @AppGetter hasOpenModal!: boolean;

    searchState: boolean = false;
    isScrollUp: boolean = true;
    showUserMenu: boolean = true;

    mounted() {
        scrollService.onScroll(this.onScroll);
        breakpointService.addListener(() => this.UpdatePadding());
    }

    UpdatePadding(): void {
        const pageHeader = this.$refs.pageHeader as HTMLElement;
        const pageHeaderOverlay = this.$refs.pageHeaderOverlay as HTMLElement;
        if (!pageHeader || !pageHeaderOverlay) {
            return;
        }
        pageHeaderOverlay.style.setProperty('--pb', `${pageHeader.offsetHeight}px`);
    }

    onScroll(event: { event: Event; isTop: boolean; isUp: boolean}) {
        this.isScrollUp = event.isUp;
    }

    get level2Items() {
        // Show level2 when no level1 selected or catalog
        if (this.activeLevel1Item && !this.activeLevel1Item.isCatalog) return [];

        const catalogTopLevel = this.level1Items.find(n => n.isCatalog);
        return catalogTopLevel ? catalogTopLevel.children : [];
    }

    get level1Items() {
        return this.navigation.main;
    }

    get activeLevel1Item(): TopLevelNavigationItem | undefined {
        return this.level1Items.find(i => this.isActiveLevel1Item(i.link));
    }

    get activeLevel2Item(): LinkItem | undefined {
        return this.navigation.userMenuItem.userMenuLinks.find(i => this.isActiveUserMenu(i));
    }

    isActiveLevel1Item(item: LinkItem) {
        const pathExp = '/.*/.*';
        const levelPathMatch = item.url.match(pathExp);
        if (!levelPathMatch || !levelPathMatch.length) {
            return false;
        }
        return this.$route.path.startsWith(levelPathMatch[0]);
    }

    isActiveUserMenu(item: LinkItem) {
        const pathExp = '/.*/.*/.*';
        const levelPathMatch = item.url.match(pathExp);
        if (!levelPathMatch || !levelPathMatch.length) {
            return false;
        }
        // trim main category code because undercategories' urls don't start with it
        const trimmedLevelPathMatch = levelPathMatch[0].substr(0, levelPathMatch[0].lastIndexOf('/') + 1);
        return this.$route.path.startsWith(trimmedLevelPathMatch);
    }

    handleSearchState(state: boolean): void {
        this.searchState = state;
    }
}
