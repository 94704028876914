
































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import DeliveryNoteHistorySearchForm from '@/project/bookkeeping/DeliveryNoteHistorySearchForm.vue';
import { DeliveryNoteHistoryListViewModel } from '@/types/serverContract';
import DeliveryNoteHeader from '@/project/bookkeeping/DeliveryNoteHeader.vue';
import QueryPaging from '@/project/shared/QueryPaging.vue';
import BookkeepingUrlHelper from './BookkeepingUrlHelper.service';
import scrollService from '@/core/scroll/scroll.service';
import { headerHeight } from '@/core/responsive/breakpoints/breakpoints.service';

@Component({
    components: {
        QueryPaging,
        DeliveryNoteHeader,
        DeliveryNoteHistorySearchForm
    }
})
export default class DeliveryNoteHistoryList extends Vue {
    deliveryNotes: DeliveryNoteHistoryListViewModel | null = null;
    currentPage: number = 1;

    public $refs!: {
        DeliveryHeaderList: HTMLElement;
    };

    updateDeliveryNoteHistoryHeaders(searchDeliveryNoteResult: DeliveryNoteHistoryListViewModel): void {
        const isInitialSearch = this.deliveryNotes === null;
        this.deliveryNotes = searchDeliveryNoteResult;
        this.currentPage = BookkeepingUrlHelper.getPageNumber();
        if (!isInitialSearch) {
            scrollService.scrollToElement(this.$refs.DeliveryHeaderList, -headerHeight(true));
        }
    }
}
