

















































import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
    PriceServiceMixin,
    PriceServiceViewObject
} from '@/project/products/PriceServiceMixin.mixin';
import { DeliveryAllowanceChargesCalculationViewObject } from '@/types/serverContract';

@Component
export default class DeliveryApproveOrderLineItemPrice extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({
        default: '',
        type: String
    }) deliveryTotal!: string;

    @Prop({
        default: '',
        type: String
    }) deliveryGrossTotal!: string;

    @Prop({
        default: () => [],
        type: Array
    }) lineAllowances!: DeliveryAllowanceChargesCalculationViewObject[];

    @Prop({
        default: () => [],
        type: Array
    }) lineCharges!: DeliveryAllowanceChargesCalculationViewObject[];

    price: PriceServiceViewObject = {
        netPrice: this.deliveryTotal,
        grossPrice: this.deliveryGrossTotal
    };

    get hasAllowances(): boolean {
        return this.shouldShowNetPrice && this.lineAllowances && this.lineAllowances.length >= 1;
    }

    get hasCharges(): boolean {
        return this.shouldShowNetPrice && this.lineCharges && this.lineCharges.length >= 1;
    }
}
