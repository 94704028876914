























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AppGetter } from '@/store/app';
import {
    MaintenanceDocument,
    RequestMaintenanceReportAsEmailRequest,
    LoginInformation
} from '@/types/serverContract';
import Api from '@/project/http/api';
import { ReportsGetter } from '@/store/reports';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: { ButtonSubmit }
})
export default class RequestMaintenanceReportForm extends Vue {
    @ReportsGetter maintenanceDocuments!: MaintenanceDocument[];
    @AppGetter loginInformation!: LoginInformation;

    fileName: string = '';
    emailAddress: string = '';
    reportsPending: boolean = false;
    sendAsZip: boolean = false;
    emailSent: boolean = false;
    emailSentTimer: number = 3000;
    sentToEmail: string = '';
    timeout: number | null = null;

    created() {
        this.emailAddress = this.loginInformation.userEmailAddress;
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async requestMaintenanceReport() {
        this.reportsPending = true;

        const payload: RequestMaintenanceReportAsEmailRequest = {
            fileName: this.fileName,
            sendAsZip: this.sendAsZip,
            documents: this.maintenanceDocuments,
            emailAddress: this.emailAddress
        };

        try {
            await Api.reports.requestMaintenanceReportAsEmail(payload).then(reportAsEmailModel => {
                this.sentToEmail = reportAsEmailModel.email;
                this.showSuccessIndicator();
            });
        } finally {
            this.reportsPending = false;
        }
    }

    resetForm(): void {
        this.fileName = '';
        this.sendAsZip = false;
    }

    showSuccessIndicator() {
        this.emailSent = true;
        this.resetForm();

        this.timeout = setTimeout(() => {
            this.emailSent = false;
        }, this.emailSentTimer);
    }
}
