



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ServiceContentViewObject } from '@/types/serverContract';

@Component
export default class MyServicesService extends Vue {
    @Prop({
        type: Object,
        required: true
    }) service!: ServiceContentViewObject
}
