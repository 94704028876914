










































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { SecondLevelCategoryViewObject } from '@/types/serverContract';
import ButtonCheckBox from '@/project/form/ButtonCheckBox.vue';
import ProjectFolderOrderHistoryVariant
    from '@/project/myServices/ProjectFolderOrderHistoryVariant.vue';
import { ProjectFolderOrderHistoryMaintenanceMixin } from '@/project/myServices/projectFolderOrderHistoryMaintenanceMixin.mixin';

@Component({
    components: { ButtonCheckBox, ProjectFolderOrderHistoryVariant }
})
export default class ProjectFolderOrderHistoryCategory extends Mixins<ProjectFolderOrderHistoryMaintenanceMixin>(ProjectFolderOrderHistoryMaintenanceMixin) {
    @Prop({
        type: Object,
        required: true
    }) category!: SecondLevelCategoryViewObject;

    isChecked: boolean = true;

    setParentChecked(isCheckedFromChild: boolean): void {
        this.isChecked = isCheckedFromChild;
        this.$emit('childChanged', this.isChecked);
    }

    setChecked(): void {
        this.isChecked = !this.isChecked;
        this.setChildChecked();
        this.$emit('childChanged', this.isChecked);
        this.sendDocList();
    }

    sendDocList(): void {
        this.category.variantsWithDocuments.forEach((variant) => {
            variant.documents.forEach((document) => {
                if (this.isChecked) {
                    this.addMaintenanceDocument(document);
                } else {
                    this.deleteMaintenanceDocument(document);
                }
            });
        });
    }

    setChildChecked(): void {
        this.category.variantsWithDocuments.forEach((variant, ix) => {
            const variantComp: any = this.$refs[`variantComp--${ix}`];

            variantComp[0].forceVariantCheck(this.isChecked);
        });
    }

    public forceCategoryCheck(checked: boolean): void {
        this.isChecked = checked;
        this.setChildChecked();
    }
}
