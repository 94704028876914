



































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import { throttle } from 'lodash-es';
import {
    Co2FootprintViewObject,
    ClimateCertificateViewModel,
    Co2CalculationItemViewObject
} from '@/types/serverContract';
import ProductCo2InfoText from '@/project/products/ProductCo2InfoText.vue';
import co2ModalState from '@/project/products/productCo2Modal.state';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';

const { show } = co2ModalState();

@Component({
    components: {
        ModalOverlay,
        ProductCo2InfoText,
        SpinnerOverlay
    }
})
export default class EcoCertificationsBadges extends Vue {
    @Prop({ type: Array, required: false }) climateCertificates!: string[];
    @Prop({ type: Object }) co2FootPrint!: Co2FootprintViewObject;
    @Prop({ type: Object }) co2CalculationItem!: Co2CalculationItemViewObject;
    @Prop({ type: Boolean, default: false }) isPdp!: boolean;
    @Prop({ type: Boolean, default: false }) minimal!: boolean;

    show = show;

    showClimateModal: boolean = false;
    amountOfHiddenBadges: number = 0;
    showMoreButton: boolean = false;
    isElementRendered: boolean = false;
    hiddenBadges: HTMLElement[] = [];
    hideHiddenBadgesContainer = true;
    badgesContainerWidth = 0;
    pending: boolean = false;

    climateCertificateViewModel: ClimateCertificateViewModel | null = null;
    overlayHeading: string | null = null;

    get isThereHiddenBadges(): boolean {
        return this.amountOfHiddenBadges > 0;
    }

    get amountOfHiddenBadgesText(): string {
        return this.amountOfHiddenBadges > 0
            ? `+${this.amountOfHiddenBadges} ${this.$tr(
                this.$labels.Views.ClimateCertificate.Buttons.More
            )}`
            : `${this.$tr(this.$labels.Views.ClimateCertificate.Buttons.ShowLess)}`;
    }

    get debouncedAdjustBadgeVisibility() {
        return throttle(this.adjustBadgeVisibility, 200);
    }

    get sectionClasses() {
        return {
            'certifications-badges-wrapper': true,
            'certifications-badges-wrapper--pdp': this.isPdp,
            'certifications-badges-wrapper--paddings': !this.isPdp,
            'certifications-badges-wrapper--minimal': this.minimal,
            'certifications-badges-wrapper--minimal-co2':
                this.minimal && (this.co2FootPrint || this.co2CalculationItem),
            'certifications-badges-wrapper--border': !this.minimal
        };
    }

    get badgesContainerClasses() {
        return {
            'certifications-badges': true,
            'certifications-badges--order': this.minimal
        };
    }

    get badgesDesignClasses() {
        return {
            'certifications-badge certifications-badge--text-grey certifications-badge--bg-blue':
                true,
            'certifications-badge--minimal': this.minimal,
            'certifications-badge--minimal-co2':
                this.minimal && (this.co2FootPrint || this.co2CalculationItem)
        };
    }

    public $refs!: {
        badges: HTMLElement[];
        badgesContainer: HTMLElement;
        co2Wrapper: HTMLElement;
        headlineText: HTMLElement;
        hiddenBadgesContainer: HTMLElement;
        showMoreElement: HTMLElement;
        wrapper: HTMLElement;
    };

    mounted() {
        this.$nextTick(() => {
            this.adjustBadgeVisibility();
            window.addEventListener('resize', this.debouncedAdjustBadgeVisibility);
        });
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.debouncedAdjustBadgeVisibility);
    }

    @Watch('showMoreButton')
    doesShowMoreButtonFitInContainer(value: boolean): void {
        this.$nextTick(() => {
            if (!value) {
                return;
            }

            const wrapper = this.$refs.wrapper;
            const wrapperWidth = this.getTotalWidth(wrapper, 0);
            const co2Wrapper = this.$refs.co2Wrapper;
            const co2WrapperWidth = this.getTotalWidth(co2Wrapper, 0);
            const showMoreButtonElement = this.$refs.showMoreElement;

            const hideLastBadge = () => {
                const allBadges = Object.values(this.$refs.badges);
                const lastVisibleBadge = allBadges[allBadges.length - this.amountOfHiddenBadges];
                lastVisibleBadge.classList.add('hidden');
                this.amountOfHiddenBadges++;
                this.hiddenBadges.push(lastVisibleBadge);
            };

            if (this.badgesContainerWidth) {
                while (this.badgesContainerWidth < this.$refs.badgesContainer.offsetWidth) {
                    hideLastBadge();
                }
            }

            if (!wrapper || !co2Wrapper || !showMoreButtonElement) return;

            if (
                wrapperWidth === co2WrapperWidth
                    ? wrapperWidth - this.getTotalWidth(showMoreButtonElement, 0)
                    : wrapperWidth -
                          co2WrapperWidth -
                          this.getTotalWidth(showMoreButtonElement, 0) >=
                      0
            ) {
                return;
            }

            this.showMoreButton = false;
        });
    }

    async showClimateCertificateOverlay(climateName: string) {
        this.pending = true;
        this.overlayHeading = climateName;
        this.climateCertificateViewModel =
            await Api.climateCertificatesController.getClimateCertificationIconDescription(
                climateName
            );
        this.pending = false;
        this.showClimateModal = true;
    }

    closeClimateCertificateOverlay(): void {
        this.showClimateModal = false;
    }

    showAllHiddenBadges() {
        const badges = this.$refs.badges;

        if (!badges || !this.amountOfHiddenBadges) {
            this.adjustBadgeVisibility();
            return;
        }

        this.amountOfHiddenBadges = 0;
        this.hideHiddenBadgesContainer = false;
    }

    adjustBadgeVisibility() {
        if (!this.$refs.badges) {
            return;
        }

        const badges = Object.values(this.$refs.badges);
        const container = this.$refs.badgesContainer;
        const headlineText = this.$refs.headlineText;

        if (!container || !badges) return;

        this.setContainerMinHeight(badges[0], container);
        container.classList.add('w-full'); // Set container width to full width
        this.badgesContainerWidth = container.offsetWidth;

        const threshold = 0; // Width threshold (0px of container width)
        let totalWidth = 0;
        this.amountOfHiddenBadges = 0; // Reset amount of HiddenBadges
        this.hiddenBadges = []; // Reset HiddenBadges list

        if (!this.minimal) {
            totalWidth = this.getTotalWidth(headlineText, 0); // Add the headlineText width and remember ro remove gap
        }

        badges.forEach(badge => {
            // Start by removing hidden to get badge width
            badge.classList.remove('hidden');

            // Add badge width
            totalWidth += this.getTotalWidth(badge);
            // Hide badge if the totalWidth exceed the threshold
            if (this.badgesContainerWidth - totalWidth <= threshold) {
                badge.classList.add('hidden');
                this.amountOfHiddenBadges++;
                this.hiddenBadges.push(badge);
            }
        });

        container.classList.remove('w-full'); // Reset container width

        if (this.amountOfHiddenBadges) {
            this.hideHiddenBadgesContainer = true;
        }

        // We include this logic to ensure that the button is visible even when all badges are visible, but not when all badges were visible from start;
        if (this.isThereHiddenBadges && this.showMoreButton) {
            this.doesShowMoreButtonFitInContainer(true);
            return;
        }

        this.showMoreButton = this.isThereHiddenBadges;
    }

    getTotalWidth(element: HTMLElement, gap: number = 4): number {
        if (!element) {
            return 0;
        }

        const rect = element.getBoundingClientRect();
        const style = getComputedStyle(element) as CSSStyleDeclaration;
        const marginLeft = parseFloat(style.marginLeft ? style.marginLeft : '0');
        const marginRight = parseFloat(style.marginRight ? style.marginRight : '0');
        const totalWidth = rect.width + marginLeft + marginRight + gap;
        return totalWidth;
    }

    setContainerMinHeight(referenceElement: HTMLElement, container: HTMLElement) {
        referenceElement.classList.remove('hidden');
        const rect = referenceElement.getBoundingClientRect();
        referenceElement.classList.add('hidden');
        const elementHeight = rect.height;
        container.style.setProperty('--container-min-height', `${elementHeight}px`);
    }
}
