














































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import serverContext from '@/core/serverContext.service';
import Datepicker from 'vuejs-datepicker';
import * as datePickerLanguageCollection from 'vuejs-datepicker/dist/locale';
import dayjs, { Dayjs } from 'dayjs';
import { dateParseFormat } from '@/project/config/utilities';

dayjs.extend(customParseFormat);

@Component({
    components: {
        Datepicker
    }
})
export default class DatePickerCtrl extends Vue {
    @Prop({
        required: true,
        type: String
    }) date!: string;

    @Prop({
        default: true,
        type: Boolean
    }) showInput!: boolean;

    @Prop({
        default: '',
        type: String
    }) inputLabel!: string;

    @Prop({
        default: true,
        type: Boolean
    }) showButton!: boolean;

    @Prop({
        default: '',
        type: String
    }) datePickerClass!: string;

     @Prop({
         default: () => {},
         type: Object
     }) disabledDates!: object;

     languageCollection = datePickerLanguageCollection;
     currentLanguage: string = serverContext.Localization.language;
     localeFormat: string = this.currentLanguage === 'da' ? 'DD/MM/YYYY' : 'YYYY/MM/DD';
     dayjsDate: Dayjs = dayjs();
     inputDate: string = '';
     showDatePicker: boolean = false;

    @Watch('date', {
        immediate: true
    })
     setDate(): void {
         this.dayjsDate = dayjs(this.date);
         this.inputDate = this.dayjsDate.format(this.localeFormat);
     }

    @Watch('inputDate')
    validateInputDate(): void {
        this.$nextTick().then(() => {
            this.$validator.validate();
        });
    }

    selectDate(): void {
        this.inputDate = this.dayjsDate.format(this.localeFormat);
        this.$emit('update:date', this.dayjsDate.format(dateParseFormat));
        this.hideDatePicker();
    }

    toggleDatePicker(): void {
        this.showDatePicker = !this.showDatePicker;
    }

    hideDatePicker(): void {
        this.showDatePicker = false;
    }

    blurInputDate(): void {
        const newDateFromInput = dayjs(this.inputDate, this.localeFormat);
        if (newDateFromInput.isValid()) {
            this.dayjsDate = newDateFromInput;
        }
        this.selectDate();
    }

    datePickerSelectDate(selectedDate): void {
        const newDateFromSelectedDate = dayjs(selectedDate);
        if (newDateFromSelectedDate.isValid()) {
            this.dayjsDate = newDateFromSelectedDate;
        }
        this.selectDate();
    }
}
