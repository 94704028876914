import httpService from '@/core/http/http.service';
import {
    DeliveryNoteHistoryListViewModel,
    DeliveryNoteViewModel,
    SearchDeliveryNotesHistoryRequest
} from '@/types/serverContract';

export default class DeliveryNotesController {
    public async search(payload: SearchDeliveryNotesHistoryRequest): Promise<DeliveryNoteHistoryListViewModel> {
        return httpService.get('deliveryNoteApi/search', payload);
    }

    public async deliveryNote(deliveryNodeId: string): Promise<DeliveryNoteViewModel> {
        return httpService.get<DeliveryNoteViewModel>('deliveryNoteApi/deliveryNote', { deliveryNodeId });
    }
}
