





































































































import Vue from 'vue';
import { router } from '@/router';
import { Component } from 'vue-property-decorator';

import Api from '@/project/http/api';
import { downloadApi } from '@/project/config/utilities';
import serverContext from '@/core/serverContext.service';

import { BasketGetter } from '@/store/basket';
import { AppGetter } from '@/store/app';
import { FlexBoxGetter } from '@/store/flexBox';

import TabbedHeading from '@/project/basket/TabbedHeading.vue';
import DownloadCatalogForm from '@/project/shared/DownloadCatalogForm.vue';
import AddBasketToFavoriteOrderForm from '@/project/favoriteOrders/AddBasketToFavoriteOrderForm.vue';
import CopyBasketToAccount from '@/project/basket/CopyBasketToAccount.vue';
import ContextMenu from '@/components/molecules/ContextMenu/ContextMenu.vue';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import CurtainOverlay from '@/components/organisms/CurtainOverlay/CurtainOverlay.vue';

import {
    BaseReportRequest,
    DownloadBasketReportRequest,
    BasketViewModel
} from '@/types/serverContract';

interface BasketHeaderButton {
    action: string | null;
    disabled: boolean;
    icon: string;
    name: string;
}

@Component({
    components: {
        TabbedHeading,
        DownloadCatalogForm,
        AddBasketToFavoriteOrderForm,
        CopyBasketToAccount,
        ContextMenu,
        ModalOverlay,
        CurtainOverlay
    }
})
export default class BasketHeader extends Vue {
    @AppGetter isPunchoutMode!: boolean;
    @BasketGetter basket!: BasketViewModel;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    showPrintCatalogModal: boolean = false;
    showConfirmEmptyBasket: boolean = false;
    showBasketToFavoriteListOverlay: boolean = false;
    showCopyBasketOverlay: boolean = false;
    buttonTrigger: number = -1;
    pending: boolean = false;

    get context(): BasketHeaderButton[] {
        return [
            // TODO SECOM-1792: Uncomment the code below to show the "Change basket" button. Remember to add action to open Curtain overlay for multiple baskets.
            /* {
                icon: 'sanicon-ico-change',
                name: 'Skift kurv',
                disabled: false,
                action: null
            }, */
            {
                icon: 'sanicon-ico-favorite-create',
                name: this.$tr(this.$labels.Views.ProductDetails.AddToFavoriteOrder),
                disabled: !this.basket.lineItems.length,
                action: 'toggleBasketToFavoriteListOverlay'
            },
            {
                icon: 'sanicon-ico-print',
                name: this.$tr(this.$labels.Views.Basket.Print),
                disabled: !this.basket.lineItems.length,
                action: 'togglePrintCatalogOverlay'
            },
            {
                icon: 'sanicon-ico-copy',
                name: this.$tr(this.$labels.Views.Basket.MoveToAccount.CopyBasket),
                disabled: !this.basket.lineItems.length,
                action: 'toggleCopyBasketOverlay'
            },
            {
                icon: 'sanicon-ico-bin',
                name: this.$tr(this.$labels.Views.Basket.RemoveAllLines),
                disabled: !this.basket.lineItems.length,
                action: 'openConfirmEmptyBasket'
            }
        ];
    }

    get checkoutPageUrl(): string {
        return serverContext.sitePages.checkoutPage.url;
    }

    get basketIsValid(): boolean {
        return this.basket && this.basket.isValid;
    }

    get punchOutCheckoutPageUrl(): string {
        return serverContext.sitePages.punchOutCheckoutPage.url;
    }

    get hasPunchOutProvider(): boolean | null {
        return (
            serverContext.loginInformation != null &&
            serverContext.loginInformation.punchOutProvider != null
        );
    }

    toCheckout(): void {
        if (this.basket.lineItems.length) {
            router.push(this.checkoutPageUrl);
        }
    }

    closePrintCatalogModal() {
        this.showPrintCatalogModal = false;

        this.focusOnButtonTrigger();
    }

    async printCatalog(baseReportRequest: BaseReportRequest) {
        const printCatalogRequest: DownloadBasketReportRequest = {
            reportType: baseReportRequest.reportType,
            priceType: baseReportRequest.priceType,
            productNumberType: baseReportRequest.productNumberType
        };

        try {
            downloadApi('api/reportsApi/downloadBasketReport', printCatalogRequest);
            this.closePrintCatalogModal();
        } finally {
        }
    }

    closeBasketToFavoriteListOverlay() {
        this.showBasketToFavoriteListOverlay = false;

        this.focusOnButtonTrigger();
    }

    async deleteBasket() {
        if (this.pending) return;
        this.pending = true;
        try {
            await Api.basket.deleteAllLineItems();
        } finally {
            this.pending = false;
        }
    }

    async deleteAllLineItems() {
        if (this.pending) return;
        this.pending = true;
        try {
            await Api.basket.deleteAllLineItems();
        } finally {
            this.pending = false;
        }
    }

    triggerAction(action) {
        this[action]();
    }

    togglePrintCatalogOverlay() {
        this.showPrintCatalogModal = !this.showPrintCatalogModal;
    }

    toggleBasketToFavoriteListOverlay() {
        this.showBasketToFavoriteListOverlay = !this.showBasketToFavoriteListOverlay;

        if (!this.showBasketToFavoriteListOverlay) {
            this.focusOnButtonTrigger();
        }
    }

    toggleCopyBasketOverlay() {
        this.showCopyBasketOverlay = !this.showCopyBasketOverlay;

        if (!this.showCopyBasketOverlay) {
            this.focusOnButtonTrigger();
        }
    }

    emptyBasket() {
        this.isInFlexBoxContext ? this.deleteAllLineItems() : this.deleteBasket();
        this.closeConfirmEmptyBasket();
    }

    openConfirmEmptyBasket() {
        this.showConfirmEmptyBasket = true;
    }

    closeConfirmEmptyBasket() {
        this.showConfirmEmptyBasket = false;
    }

    setButtonTrigger(index) {
        this.buttonTrigger = index;
    }

    focusOnButtonTrigger() {
        this.$nextTick(() => {
            this.$refs.headerButtons[this.buttonTrigger].$el.focus();
        });
    }
}
