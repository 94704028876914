import BusComp from './Bus.vue';

export interface Bus {
    emit(event: string, ...args: any[]);

    off(event: string, callback: (...args: any[]) => void);

    on(event: string, callback: (...args: any[]) => void);

    once(event: string, callback: (...args: any[]) => void);
}

export default new BusComp() as Bus;
