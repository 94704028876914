










import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ImageWithLink extends Vue {
    @Prop({ type: String, required: true }) imageSource!: string;

    @Prop({ type: String, required: true }) linkUrl!: string;
    @Prop({ type: String, default: '' }) linkTarget!: string;
    @Prop({ type: String, required: true }) linkTitle!: string;
}
