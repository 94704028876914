








































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
    ProductTileSlimViewObject,
    PriceViewObject,
    RelatedProductsViewObject,
    KeyAndValue,
    AvailabilityStatus,
    PriceRequest,
    GetByVariantIdsRequest
} from '@/types/serverContract';
import ProductTileSlim from '@/project/products/ProductTileSlim.vue';
import Api from '@/project/http/api';
import { AppGetter } from '@/store/app';
import priceService from '../price.service';
import { BulkFetchItemListener, IBulkFetchItem } from '@/core/bulk-fetch/AbstractBulkFetch';
import Deferred from '@/core/async/Deferred';
import { PRODUCT_LIST_VIEW_TYPES } from '@/core/enums/productListViewTypes';
import FlickityWrap from '@/core/FlickityWrap.vue';
import { BACKGROUND_COLORS_BLUE } from '@/core/enums/productTiles';

class ProductWithPrice {
    product: ProductTileSlimViewObject;
    price: PriceViewObject | undefined;

    constructor(product: ProductTileSlimViewObject, price?: PriceViewObject) {
        this.product = product;
        this.price = price;
    }
}

enum IdTypeEnum {
    SaniNr,
    HybrisCode
}

@Component({
    components: {
        ProductTileSlim
    }
})
export default class ProductCarousel extends Vue {
    // Product carousel  is used in 3 different ways. Due to backend implementation,
    // we cannot change this easily.

    // For related products, alternative products and spare parts
    // we get the product group, which tells us product ID's and header.
    // We use that to get the needed products, as well as the header text.
    @Prop({ required: false, type: Object }) productGroup!: RelatedProductsViewObject;
    @Prop({ default: false, type: Boolean }) showHeader!: boolean;
    @Prop({ required: false, type: String }) raptorMethod!: string;
    @Prop({ default: PRODUCT_LIST_VIEW_TYPES.RELATED_ITEM, type: String }) productType!: string;

    // For product recommendations and basket recommended, we get the products directly, and a header text seperately.
    @Prop({ required: false, type: Array }) recommendedProducts!: ProductTileSlimViewObject[];
    @Prop({ required: false, type: String }) header!: string;

    // For editor picks, we get just the products ID and a header text seperately.
    @Prop({ required: false, type: Array }) productIds!: string[];

    // NEW/SLEEK DESIGN settings
    @Prop({ type: String, default: 'bg-grey-200' }) backgroundColor!: string;

    @Prop({ default: false, type: Boolean }) coloredTiles!: boolean;
    @Prop({ default: false, type: Boolean }) showProductAttributes!: boolean;
    @Prop({ required: false, type: String }) body!: string;
    @Prop({ required: false, type: String }) linkText!: string;
    @Prop({ required: false, type: String }) LinkUrl!: string;
    @Prop({ required: false, type: String }) linkTarget!: string;

    @AppGetter isLoggedIn!: boolean;

    productStockStatus: KeyAndValue<string, AvailabilityStatus>[] = [];

    pending: boolean = false;
    products: ProductTileSlimViewObject[] = [];
    prices: ProductTileSlimViewObject[] = [];
    productsWithPrice: ProductWithPrice[] = [];

    created() {
        this.getProducts();
    }

    @Watch('productGroup')
    onProductGroupChange(): void {
        this.getProducts();
    }

    getTileColor(i: number) {
        if (this.coloredTiles) {
            const colors = Object.values(BACKGROUND_COLORS_BLUE);
            return colors[i % colors.length];
        }
    }

    async relatedProductsFromId(productIds: string[], idType: IdTypeEnum) {
        this.pending = true;

        let products: ProductTileSlimViewObject[] = [];
        try {
            switch (idType) {
                case IdTypeEnum.SaniNr:
                    const payload: GetByVariantIdsRequest = {
                        variantIds: productIds
                    };
                    products = await Api.products.getProductsByVariantIdsSlim(payload);
                    break;
                case IdTypeEnum.HybrisCode:
                    products = await Api.products.getRelatedProductsSlim(productIds);
                    break;
            }
        } finally {
            this.pending = false;
        }
        return products;
    }

    get carouselHeader(): string {
        if (this.productGroup) {
            return this.productGroup.header;
        } else {
            return this.header;
        }
    }

    get hasProducts(): boolean {
        return (this.products && this.products.length >= 1) || false;
    }

    get carouselBackground(): string {
        /*
            Rules for background colors:
            1 If product cards have colored backgrounds the background can't be blue (bg-blue-100).
            2 If product cards have white backgrounds the background can't be white (bg-white).
            3 If this component is not inside of a <SectionWrapper /> component the background can't be grey (bg-grey-200) (This is handled in the styles section).
        */
        if (this.coloredTiles) {
            return this.backgroundColor === 'bg-blue-100' ? 'bg-white' : this.backgroundColor;
        }

        return this.backgroundColor === 'bg-white' ? 'bg-grey-200' : this.backgroundColor;
    }

    getProducts(): void {
        if (this.productIds && this.productIds.length >= 1) {
            this.relatedProductsFromId(this.productIds, IdTypeEnum.SaniNr).then(products => {
                this.products = products;
                this.getPricesForProduct();
            });
            return;
        }

        if (this.productGroup && this.productGroup.productsIds.length >= 1) {
            this.relatedProductsFromId(this.productGroup.productsIds, IdTypeEnum.HybrisCode).then(
                products => {
                    this.products = products;
                    this.getPricesForProduct();
                }
            );
            return;
        }

        if (this.recommendedProducts && this.recommendedProducts.length >= 1) {
            this.products = this.recommendedProducts;
            this.getPricesForProduct();
        }
    }

    async getPricesForProduct() {
        this.productsWithPrice = [];
        if (!this.isLoggedIn) {
            for (let i = 0; i < this.products.length; i++) {
                this.productsWithPrice.push(new ProductWithPrice(this.products[i]));
            }
            return;
        }
        const itemListener: BulkFetchItemListener<PriceRequest, PriceViewObject>[] = [];
        try {
            if (this.products) {
                this.products.forEach(product => {
                    const priceRequest: PriceRequest = {
                        id: product.variant.ids.variantId,
                        availabilityType: product.variant.availabilityType,
                        certificateCode: product.variant.certificateCode,
                        isSurplus: product.variant.isSurplus,
                        isTemporarilyPhasedOut: product.variant.isTemporarilyPhasedOut,
                        isVendorUnableToDeliver: product.variant.isVendorUnableToDeliver
                    };

                    const productFetchRequest: IBulkFetchItem<PriceRequest> = {
                        requestId: priceRequest.id,
                        requestData: priceRequest
                    };
                    const result: Deferred<PriceViewObject> = new Deferred<PriceViewObject>();

                    itemListener.push(
                        new BulkFetchItemListener<PriceRequest, PriceViewObject>(
                            productFetchRequest,
                            result
                        )
                    );
                });
            }
        } catch (error) {
            console.error(error);
        }
        try {
            const prices = await priceService.bulkGetPrices(itemListener);

            for (let i = 0; i < this.products.length; i++) {
                const productId = this.products[i].id;

                if (prices && prices.hasOwnProperty(productId)) {
                    this.productsWithPrice.push(
                        new ProductWithPrice(this.products[i], prices[productId])
                    );
                    this.priceLoaded(prices[productId]);
                } else {
                    this.productsWithPrice.push(new ProductWithPrice(this.products[i]));
                }
            }
            this.$nextTick(() => {
                if (!this.$refs['flickity-slim']) {
                    return;
                }

                (this.$refs['flickity-slim'] as FlickityWrap).resize();
            });
        } catch (error) {
            console.error(error);
        }
    }

    priceLoaded(price: PriceViewObject) {
        this.productStockStatus.push({
            key: price.productId,
            value: price.stockStatus.status
        });
    }
}
