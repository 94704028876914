






















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
    PriceServiceViewObject,
    PriceServiceMixin
} from '@/project/products/PriceServiceMixin.mixin';
import {
    StockStatusViewObject,
    ScalePriceViewObject
} from '@/types/serverContract';
import ProductInventory from '@/project/products/ProductInventory.vue';

@Component({
    components: { ProductInventory }
})
export default class LineItemPrice extends Mixins<PriceServiceMixin>(PriceServiceMixin) {
    @Prop({
        default: '',
        type: String
    }) unitNetPrice!: string;

    @Prop({
        default: '',
        type: String
    }) unitGrossPrice!: string;

    @Prop({
        type: Object
    }) inventoryStatus!: StockStatusViewObject;

    @Prop({
        required: true,
        type: Boolean
    }) isStockedItem!: boolean;

    @Prop({
        required: true,
        type: Boolean
    }) isSurplus!: boolean;

    @Prop({
        required: true,
        type: Boolean
    }) isSoldOut!: boolean;

    @Prop({
        default: () => [],
        type: Array
    }) scalePrices!: ScalePriceViewObject[];

    lineItemInventoryStatusOverride: StockStatusViewObject = this.inventoryStatus;
    price: PriceServiceViewObject = {
        netPrice: this.unitNetPrice,
        grossPrice: this.unitGrossPrice
    };

    @Watch('unitNetPrice')
    onUnitNetPriceChange() {
        this.price.netPrice = this.unitNetPrice;
    }

    @Watch('unitGrossPrice')
    onUnitGrossPriceChange() {
        this.price.grossPrice = this.unitGrossPrice;
    }
}
