






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class SvgLogo extends Vue {
    @Prop({
        required: false,
        type: String,
        default: 'text-ahlsell-blue'
    }) textColorClass!: string;

    @Prop({
        required: false,
        type: Number,
        default: 94
    }) width!: number;
}
