var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{staticClass:"bg-ahlsell-blue"},[_c('div',{staticClass:"o-container text-white"},[_c('div',{staticClass:"py-15 text-center md:flex md:flex-wrap md:items-center md:justify-between"},[_c('div',{staticClass:"mb-10 md:mb-0"},[_c('RouterLinkConditional',{attrs:{"to":_vm.frontpage.url}},[_c('SiteSvgLogo',{attrs:{"text-color-class":"text-white"}})],1)],1),_c('CheckoutSteps',{staticClass:"md:flex-1 md:px-15"}),_c('CheckoutAccount',{staticClass:"hidden text-right md:block"})],1)])]),_c('div',{staticClass:"bg-white shadow-hard md:mb-20"},[_c('div',{staticClass:"o-container"},[_c('div',{staticClass:"flex items-center justify-between py-20 md:py-25"},[_c('div',[_c('h1',{staticClass:"mb-0 text-24 md:text-34"},[_vm._v("\n                        "+_vm._s(_vm.$tr(_vm.$labels.Views.Checkout.Delivery.Header))+"\n                    ")])]),_c('div',[_c('button',{staticClass:"c-btn ml-10 text-15 md:text-20",class:[
                            !_vm.isOrderSubmitting &&
                                _vm.isOrderApproved &&
                                _vm.isCommentValid &&
                                _vm.loginInformation.canSubmitOrder
                                ? 'bg-blue-500 text-white hover:bg-blue-500-hover'
                                : 'cursor-default bg-grey-400 text-grey-600 opacity-100'
                        ],on:{"click":function($event){$event.preventDefault();return _vm.submitOrder($event)}}},[_c('span',{staticClass:"mr-10 inline-block"},[_vm._v("\n                            "+_vm._s(_vm.$tr(_vm.$labels.Views.Checkout.Delivery.CompleteOrder))+"\n                        ")]),_c('i',{staticClass:"sanicon-ico-arrow-breadcrumb"})])])])])]),_c('div',{staticClass:"md:hidden"},[_c('div',{staticClass:"o-container"},[_c('CheckoutAccount',{staticClass:"py-10"})],1)]),_c('transition',{attrs:{"mode":"out-in","enter-active-class":"animated fadeIn u-anim-dur-300","leave-active-class":"animated fadeOut u-anim-dur-300"}},[(!_vm.hasOpenModal)?_c('div',[_c('ApiMessages')],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }