















































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
    FavoriteListMergeType,
    FavoriteOrderListItemViewObject,
    FavoriteOrdersViewModel,
    FavoriteOrderImportLineErrorViewObject
} from '@/types/serverContract';
import api from '@/project/http/api';

@Component
export default class FavoriteOrderImportFromFile extends Vue {
        @Prop({
            required: true,
            type: Object
        }) favoriteOrders!: FavoriteOrdersViewModel;

        file: File | null = null;
        importFavoriteListMergeTypes = FavoriteListMergeType;
        selectedImportMergeType: string = this.importFavoriteListMergeTypes[this.importFavoriteListMergeTypes.New];
        showFavoriteOrdersList: boolean = false;
        newFavoriteOrderName: string = '';
        selectedFavoriteOrder: FavoriteOrderListItemViewObject | null = null;
        pending: boolean = false;
        importResultErrors: FavoriteOrderImportLineErrorViewObject[] = [];

        get favoriteOrderOptions(): FavoriteOrderListItemViewObject[] | null {
            const favoriteOrderOptions = this.favoriteOrders.favoriteOrderList.filter(favoriteOrder => favoriteOrder.allowEdit).map(favoriteOrder => {
                return favoriteOrder;
            });
            return favoriteOrderOptions.length ? favoriteOrderOptions : null;
        }

        @Watch('selectedImportMergeType')
        onChangeSelectedImportMergeType(): void {
            this.showFavoriteOrdersList = this.selectedImportMergeType !== this.importFavoriteListMergeTypes[this.importFavoriteListMergeTypes.New];

            if (!this.showFavoriteOrdersList) {
                this.selectedFavoriteOrder = null;
            }
        }

        async submitForm() {
            if (this.vvErrors.items.length) {
                return;
            }
            this.pending = true;
            try {
                this.importResultErrors = [];
                const formData = new FormData();
                formData.append('file', this.file!);
                formData.append('favoriteOrderId', this.selectedFavoriteOrder ? this.selectedFavoriteOrder.id.toString() : '');
                formData.append('newFavoriteOrderName', this.newFavoriteOrderName);
                formData.append('mergeType', this.importFavoriteListMergeTypes[this.selectedImportMergeType]);

                await api.favoriteOrder.importFavoriteOrder(formData)
                    .then(importResult => {
                        this.$emit('importSuccess', importResult);
                        if (importResult.errors && importResult.errors.length > 0) {
                            this.importResultErrors = importResult.errors;
                        } else {
                            this.returnToList();
                        }
                    });
            } finally {
                this.pending = false;
            }
        }

        async validateForm() {
            if (this.selectedImportMergeType !== this.importFavoriteListMergeTypes[this.importFavoriteListMergeTypes.New]) {
                await (this.$refs['selectFavoriteOrder'] as Vue).$validator.validate();
            }
            await this.$validator.validate();
            await this.submitForm();
        }

        onFileChange() {
            this.file = (this.$refs.importCSVFile as HTMLInputElement).files![0];
        }

        onChangeSelectedFavoriteOrder(event) {
            this.selectedFavoriteOrder = this.favoriteOrderOptions!.find(favoriteOrder => favoriteOrder.id === parseInt(event.target.value, 10))!;
        }

        returnToList() {
            this.$emit('closeForm');
        }

        // From Vue-validate
        private vvErrors!: any;
}
