






































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    Courier,
    ShipmentViewModel
} from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import TrackAndTraceDetailsShipmentInfoEvents from '@/project/myServices/TrackAndTraceDetailsShipmentInfoEvents.vue';
import TrackAndTraceDetailsShipmentInfoFreightInfo from '@/project/myServices/TrackAndTraceDetailsShipmentInfoFreightInfo.vue';
import TrackAndTraceDetailsShipmentInfoGoods from '@/project/myServices/TrackAndTraceDetailsShipmentInfoGoods.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component({
    components: {
        TrackAndTraceDetailsShipmentInfoGoods,
        TrackAndTraceDetailsShipmentInfoFreightInfo,
        TrackAndTraceDetailsShipmentInfoEvents,
        SpinnerOverlay
    }
})
export default class TrackAndTraceDetailsShipmentInfo extends Vue {
    @Prop({
        required: true,
        type: String
    }) trackAndTraceId!: string;

    @Prop({
        required: true,
        type: String
    }) orderId!: string;

    @Prop({
        required: true,
        type: Number
    }) courier!: Courier;

    shipmentInfo: ShipmentViewModel | null = null;
    pending: boolean = true;

    get hasShipmentInfo(): boolean {
        return isNotNullEmptyOrUndefined(this.shipmentInfo);
    }

    get freightInfo(): object {
        if (this.shipmentInfo === null) {
            return {};
        }
        return {
            id: this.shipmentInfo.id,
            courier: this.shipmentInfo.courier,
            senderReference: this.shipmentInfo.senderReference
        };
    }

    created() {
        this.getShipmentInfo();
    }

    async getShipmentInfo() {
        this.pending = true;
        try {
            this.shipmentInfo = await Api.trackAndTrace.getShipmentInfoById({
                id: this.trackAndTraceId,
                courier: this.courier,
                orderId: this.orderId
            });
        } finally {
            this.pending = false;
        }
    }
}
