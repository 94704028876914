







































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    CreateAccountRequest,
    CreateAccountCompanyRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import CreateAccountTimeline from './CreateAccountTimeline.vue';
import scrollService from '@/core/scroll/scroll.service';
import tracking from '@/core/tracking/tracking.service';

@Component({
    components: {
        SpinnerOverlay,
        CreateAccountTimeline
    }
})
export default class CreateAccount extends Vue {
    @Prop({ type: String, default: '' }) trackingType!: string;
    @Prop({ type: String, default: '' }) trackingEvent!: string;

    pending: boolean = false;
    createAccountFormId: string = 'create-account-form';
    useCompanyPhoneAsPersonal: boolean = false;
    useCompanyEmailAsPersonal: boolean = false;
    useEmailAsUsername: boolean = true;
    newUsername: string = '';
    yourPhoneNumber: string = '';
    companyPhone: string = '';
    invoiceEmail: string = '';
    step: number = 1;
    checkboxUseSamePhoneLabel: string = this.$tr(this.$labels.Views.CreateAccount.Placeholder.UseSamePhoneNr);
    checkboxUseSameEmailLabel: string = this.$tr(this.$labels.Views.CreateAccount.Placeholder.UseSameEmail);
    useSameEmailAsUsernameLabel: string = this.$tr(this.$labels.Views.CreateAccount.Placeholder.UseEmailAsUsername);

    cvrInputChanged: boolean = false;
    companyEmailInputChanged: boolean = false;
    companyPhoneInputChanged: boolean = false;
    firstNameInputChanged: boolean = false;
    lastNameInputChanged: boolean = false;
    phoneInputChanged: boolean = false;
    emailInputChanged: boolean = false;
    usernameInputChanged: boolean = false;

    initialCreateAccountCompanyPayload: CreateAccountCompanyRequest = {
        cvr: '',
        email: '',
        phone: ''
    };

    initialCreateAccountPayload: CreateAccountRequest = {
        company: { ...this.initialCreateAccountCompanyPayload },
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        userName: ''
    };

    createAccountPayload = { ...this.initialCreateAccountPayload };

    setPersonalField(field, data, useFirmField = true) {
        if (useFirmField) {
            this.createAccountPayload[field] = data;
        } else {
            this.resetPersonalField(field);
        }
    }

    resetPersonalField(field) {
        this.createAccountPayload[field] = '';
    }

    public async createAccount() {
        this.trackFormSubmit();
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.pending) {
            if (!isValid) {
                // Scoll to error
                var firstErrorField = this.$validator.fields.find({ id: this.$validator.errors.items[0].id });
                if (firstErrorField) {
                    var errorElementParentWithOffset = (firstErrorField.el as HTMLElement).closest('.c-text-input');
                    scrollService.scrollToElement(errorElementParentWithOffset as HTMLElement);
                    this.trackFormSubmitError(this.$validator.errors.items[0].msg);
                }
            }
            return;
        }

        this.pending = true;

        // if set to use company data as personal then set them again to secure the values
        if (this.useCompanyPhoneAsPersonal) {
            this.setPersonalField('phone', this.createAccountPayload.company.phone);
        }
        if (this.useCompanyEmailAsPersonal) {
            this.setPersonalField('email', this.createAccountPayload.company.email);
        }
        if (this.useEmailAsUsername) {
            this.setPersonalField('userName', this.createAccountPayload.company.email);
        }

        try {
            await Api.account.createAccount(this.createAccountPayload, this.createAccountFormId);
            this.resetForm();
            this.trackFormSubmitSuccess();
        } catch (e) {
            this.step = 1;
            this.trackFormSubmitError(e);
        } finally {
            this.pending = false;
        }
    }

    updateUseSameEmailLabels() {
        this.checkboxUseSameEmailLabel = this.createAccountPayload.company.email
            ? `${this.$tr(this.$labels.Views.CreateAccount.Placeholder.UseSameEmail)} (${this.createAccountPayload.company.email})`
            : this.$tr(this.$labels.Views.CreateAccount.Placeholder.UseSameEmail);

        this.useSameEmailAsUsernameLabel = this.createAccountPayload.company.email
            ? `${this.$tr(this.$labels.Views.CreateAccount.Placeholder.UseEmailAsUsername)} (${this.createAccountPayload.company.email})`
            : this.$tr(this.$labels.Views.CreateAccount.Placeholder.UseEmailAsUsername);
    }

    updateUseSamePhoneLabel() {
        this.checkboxUseSamePhoneLabel = this.createAccountPayload.company.phone
            ? `${this.$tr(this.$labels.Views.CreateAccount.Placeholder.UseSamePhoneNr)} (${this.createAccountPayload.company.phone})`
            : this.$tr(this.$labels.Views.CreateAccount.Placeholder.UseSamePhoneNr);
    }

    resetForm() {
        this.createAccountPayload = { ...this.initialCreateAccountPayload };
    }

    noSpecialCharacters(value): boolean {
        const regex = /^[^()&<>[\]/\\=*']*$/;
        let regxText = regex.test(value);
        if (value.indexOf('^') > -1) {
            regxText = false;
        }
        return regxText;
    }

    get noSpecialCharactersMessage() {
        return this.$tr(this.$labels.Views.CreateAccount.Validation.UsernameNoSpecial);
    }

    noNumbers(value) {
        return !/\d/.test(value);
    }

    get noNumbersFirstNameMessage() {
        return this.$tr(this.$labels.Views.CreateAccount.Validation.FirstNameNoNumber);
    }

    get noNumbersLastNameMessage() {
        return this.$tr(this.$labels.Views.CreateAccount.Validation.LastNameNoNumber);
    }

    trackFormSubmit() {
        tracking.trackCreateUserFormSubmit(this.trackingType, this.trackingEvent);
    }

    trackFormSubmitSuccess() {
        tracking.trackCreateUserFormSuccess(this.trackingType, this.trackingEvent);
    }

    trackFormSubmitError(errorMessage) {
        tracking.trackCreateUserFormError(this.trackingType, this.trackingEvent, errorMessage);
    }

    trackFormSubmitStep(stepName, stepNumber) {
        switch (stepNumber) {
            case 1.1:
                if (this.cvrInputChanged) return;
                this.cvrInputChanged = true;
                break;
            case 1.2:
                if (this.companyEmailInputChanged) return;
                this.companyEmailInputChanged = true;
                break;
            case 1.3:
                if (this.companyPhoneInputChanged) return;
                this.companyPhoneInputChanged = true;
                break;
            case 1.4:
                if (this.firstNameInputChanged) return;
                this.firstNameInputChanged = true;
                break;
            case 1.5:
                if (this.lastNameInputChanged) return;
                this.lastNameInputChanged = true;
                break;
            case 1.6:
                if (this.phoneInputChanged) return;
                this.phoneInputChanged = true;
                break;
            case 1.7:
                if (this.emailInputChanged) return;
                this.emailInputChanged = true;
                break;
            case 1.8:
                if (this.usernameInputChanged) return;
                this.usernameInputChanged = true;
                break;
        }

        tracking.trackCreateUserFormStep(this.trackingType, this.trackingEvent, stepName, stepNumber);
    }
}
