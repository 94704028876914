import { render, staticRenderFns } from "./EcoCertificationsBadges.vue?vue&type=template&id=09fc8ac6"
import script from "./EcoCertificationsBadges.vue?vue&type=script&lang=ts"
export * from "./EcoCertificationsBadges.vue?vue&type=script&lang=ts"
import style0 from "./EcoCertificationsBadges.vue?vue&type=style&index=0&id=09fc8ac6&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports