

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface RadioButtonGroupOption {
    description: string;
    icon: string;
    label: string;
    selected?: boolean;
    value: string | boolean;
}

@Component
export default class RadioButtonGroup extends Vue {
    @Prop({
        type: Array,
        default: []
    })
    options!: RadioButtonGroupOption[];

    @Prop({
        type: String,
        default: ''
    })
    name!: string;

    updateValue(value) {
        this.$emit('change', value);
    }
}
