



























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
    LineItemViewObject
} from '@/types/serverContract';

@Component
export default class LineItemActionButtons extends Vue {
    @Prop({
        type: Object
    }) lineItem!: LineItemViewObject;

    @Prop({
        type: Boolean,
        default: false
    }) buttonPending!: boolean;

    @Prop({
        default: false,
        type: Boolean
    }) calculatingPrice!: boolean;

    isButtonPending: boolean = this.buttonPending;

    editSpecialOrderLineItem(): void {
        this.isButtonPending = true;
        this.$emit('editSpecialOrderLineItem');
    }

    deleteLineItem(): void {
        this.isButtonPending = true;
        this.$emit('deleteLineItem');
    }
}
