




























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
    ProductTileSlimViewObject,
    PriceViewObject,
    KeyAndValue,
    AvailabilityStatus,
    RecommendationViewModel,
    CampaignRequest,
    PriceRequest
} from '@/types/serverContract';
import ProductTileSlim from '@/project/products/ProductTileSlim.vue';
import { BulkFetchItemListener, IBulkFetchItem } from '@/core/bulk-fetch/AbstractBulkFetch';
import priceService from '../products/price.service';
import Deferred from '@/core/async/Deferred';
import Api from '@/project/http/api';
import { AppAction, AppGetter } from '@/store/app';

class ProductWithPrice {
    product: ProductTileSlimViewObject;
    price: PriceViewObject | undefined;

    constructor(product: ProductTileSlimViewObject, price?: PriceViewObject) {
        this.product = product;
        this.price = price;
    }
}

@Component({
    components: {
        ProductTileSlim
    }
})
export default class ProductListGrid extends Vue {
    @Prop({ type: String }) anchorId!: string;
    @Prop({ required: true, type: String }) title!: string;
    @Prop({ required: true, type: Number }) maxElements!: number;
    @Prop({ required: true, type: Array }) parameters;

    @AppAction setPageHasCampaignView!: () => void;
    @AppGetter isLoggedIn!: boolean;

    pending: boolean = false;
    products: ProductTileSlimViewObject[] = [];
    productsWithPrice: ProductWithPrice[] = [];
    productStockStatus: KeyAndValue<string, AvailabilityStatus>[] = [];
    staggeringTransitionDelay: number = 100;
    /*
        We could have up to 400 products at a time, so we need to limit the amount of products to fade in.
        If we don't do this it could take up to 40 seconds to show all products, for now we will limit it
        to 3 seconds (30 products).
    */
    maxProductsToFade: number = 30;

    get campaignId(): string {
        return this.parameters.find((parameter) => {
            return parameter.Name === 'CategoryId';
        }).Value;
    }

    created() {
        this.getProductsFromCategoryId();
        this.setPageHasCampaignView();
    }

    @Watch('categoryId')
    onCategoryIdChange(): void {
        this.getProductsFromCategoryId();
    }

    async getProductsFromCategoryId() {
        this.pending = true;
        let products: RecommendationViewModel<ProductTileSlimViewObject>;

        const payload: CampaignRequest = {
            numOfResults: this.maxElements,
            campaignId: this.campaignId,
            parameters: this.parameters
        };

        try {
            products = await Api.campaign.recommendationCampaign(payload);
        } finally {
            this.pending = false;
        }

        this.products = products.elements;
        this.getPricesForProduct();
    }

    get hasProducts(): boolean {
        return (this.products && this.products.length >= 1) || false;
    }

    async priceLoaded(price: PriceViewObject) {
        this.productStockStatus.push({
            key: price.productId,
            value: price.stockStatus.status
        });
    }

    async getPricesForProduct() {
        this.productsWithPrice = [];
        if (!this.isLoggedIn) {
            for (let i = 0; i < this.products.length; i++) {
                this.productsWithPrice.push(new ProductWithPrice(this.products[i]));
            }
            return;
        }
        const itemListener: BulkFetchItemListener<PriceRequest, PriceViewObject>[] = [];
        if (this.products) {
            this.products.forEach(product => {
                const priceRequest: PriceRequest = {
                    id: product.variant.ids.variantId,
                    availabilityType: product.variant.availabilityType,
                    certificateCode: product.variant.certificateCode,
                    isSurplus: product.variant.isSurplus,
                    isTemporarilyPhasedOut: product.variant.isTemporarilyPhasedOut,
                    isVendorUnableToDeliver: product.variant.isVendorUnableToDeliver
                };

                const productFetchRequest: IBulkFetchItem<PriceRequest> = {
                    requestId: priceRequest.id,
                    requestData: priceRequest
                };
                const result: Deferred<PriceViewObject> = new Deferred<PriceViewObject>();

                itemListener.push(new BulkFetchItemListener<PriceRequest, PriceViewObject>(productFetchRequest, result));
            });
        }

        try {
            const prices = await priceService.bulkGetPrices(itemListener);
            for (let i = 0; i < this.products.length; i++) {
                const productId = this.products[i].id;
                if (!prices) {
                    return;
                }
                if (prices.hasOwnProperty(productId)) {
                    this.productsWithPrice.push(new ProductWithPrice(this.products[i], prices[productId]));
                }
                this.priceLoaded(prices[productId]);
            }
        } catch (error) {
            console.error(error);
        }
    }

    calculateDelay(index: number): number {
        const limitedIndex = index > this.maxProductsToFade ? this.maxProductsToFade : index;
        return this.staggeringTransitionDelay * limitedIndex;
    }
}
