

























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IMAGE_LOADING } from '@/core/enums/enums';

@Component
export default class ImageWithOverlay extends Vue {
    @Prop({ required: true, type: String }) source!: string;
    @Prop({ default: '', type: String }) webpSource!: string;
    @Prop({ default: '', type: String }) sourceSet!: string;
    @Prop({ default: '', type: String }) classes!: string;
    @Prop({ default: '', type: String }) alt!: string;
    @Prop({ default: '', type: String }) title!: string;
    @Prop({ default: IMAGE_LOADING.Eager, type: String }) loading!: string;
    @Prop({ default: false, type: Boolean }) addCurtain!: boolean;
    @Prop({ default: 'bg-transparent-black', type: String }) curtainClasses!: string;
    @Prop({ default: 'relative', type: String }) containerClasses!: string;

    onError(): void {
        this.$emit('error');
    }
}
