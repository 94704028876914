import { Label } from './label';
/* eslint-disable */

export class Labels {       
        
		AllowanceChargeCode = new class
		{

Label = new Label("AllowanceChargeCode","AllowanceChargeCode");

			SAW = new Label("AllowanceChargeCode.SAW","SAW");

			Unknown = new Label("AllowanceChargeCode.Unknown","Unknown");

			ZDCH = new Label("AllowanceChargeCode.ZDCH","ZDCH");

			ZDGR = new Label("AllowanceChargeCode.ZDGR","ZDGR");

			ZFBX = new Label("AllowanceChargeCode.ZFBX","ZFBX");

			ZKMO = new Label("AllowanceChargeCode.ZKMO","ZKMO");

			ZPNT = new Label("AllowanceChargeCode.ZPNT","ZPNT");

			ZREA = new Label("AllowanceChargeCode.ZREA","ZREA");

			ZREF = new Label("AllowanceChargeCode.ZREF","ZREF");

			ZRFB = new Label("AllowanceChargeCode.ZRFB","ZRFB");

			ZSD0 = new Label("AllowanceChargeCode.ZSD0","ZSD0");

			ZSD1 = new Label("AllowanceChargeCode.ZSD1","ZSD1");

			ZSD2 = new Label("AllowanceChargeCode.ZSD2","ZSD2");

			ZSD3 = new Label("AllowanceChargeCode.ZSD3","ZSD3");

			ZSD4 = new Label("AllowanceChargeCode.ZSD4","ZSD4");

			ZSD5 = new Label("AllowanceChargeCode.ZSD5","ZSD5");

			ZSE0 = new Label("AllowanceChargeCode.ZSE0","ZSE0");

			ZSE1 = new Label("AllowanceChargeCode.ZSE1","ZSE1");

			ZSE2 = new Label("AllowanceChargeCode.ZSE2","ZSE2");

			ZSE3 = new Label("AllowanceChargeCode.ZSE3","ZSE3");

			ZSE4 = new Label("AllowanceChargeCode.ZSE4","ZSE4");

			ZSOS = new Label("AllowanceChargeCode.ZSOS","ZSOS");

			ZSSD = new Label("AllowanceChargeCode.ZSSD","ZSSD");

			ZSSE = new Label("AllowanceChargeCode.ZSSE","ZSSE");

			ZSSM = new Label("AllowanceChargeCode.ZSSM","ZSSM");

			ZSTD = new Label("AllowanceChargeCode.ZSTD","ZSTD");

			ZT04 = new Label("AllowanceChargeCode.ZT04","ZT04");

			ZU01 = new Label("AllowanceChargeCode.ZU01","ZU01");

			ZU02 = new Label("AllowanceChargeCode.ZU02","ZU02");

			ZU03 = new Label("AllowanceChargeCode.ZU03","ZU03");

			ZU04 = new Label("AllowanceChargeCode.ZU04","ZU04");

			ZU05 = new Label("AllowanceChargeCode.ZU05","ZU05");

			ZU06 = new Label("AllowanceChargeCode.ZU06","ZU06");

			ZU07 = new Label("AllowanceChargeCode.ZU07","ZU07");

			ZU08 = new Label("AllowanceChargeCode.ZU08","ZU08");

			ZU09 = new Label("AllowanceChargeCode.ZU09","ZU09");

			ZU10 = new Label("AllowanceChargeCode.ZU10","ZU10");

			ZU11 = new Label("AllowanceChargeCode.ZU11","ZU11");

			ZU12 = new Label("AllowanceChargeCode.ZU12","ZU12");

			ZU13 = new Label("AllowanceChargeCode.ZU13","ZU13");

			ZU14 = new Label("AllowanceChargeCode.ZU14","ZU14");

			ZU15 = new Label("AllowanceChargeCode.ZU15","ZU15");

			ZU16 = new Label("AllowanceChargeCode.ZU16","ZU16");

			ZU17 = new Label("AllowanceChargeCode.ZU17","ZU17");

			ZU18 = new Label("AllowanceChargeCode.ZU18","ZU18");

			ZU19 = new Label("AllowanceChargeCode.ZU19","ZU19");

			ZU20 = new Label("AllowanceChargeCode.ZU20","ZU20");

			ZU21 = new Label("AllowanceChargeCode.ZU21","ZU21");

			ZU22 = new Label("AllowanceChargeCode.ZU22","ZU22");

			ZU23 = new Label("AllowanceChargeCode.ZU23","ZU23");

			ZU24 = new Label("AllowanceChargeCode.ZU24","ZU24");

			ZU25 = new Label("AllowanceChargeCode.ZU25","ZU25");

			ZU26 = new Label("AllowanceChargeCode.ZU26","ZU26");

			ZU27 = new Label("AllowanceChargeCode.ZU27","ZU27");

			ZU28 = new Label("AllowanceChargeCode.ZU28","ZU28");

			ZU29 = new Label("AllowanceChargeCode.ZU29","ZU29");

			ZU30 = new Label("AllowanceChargeCode.ZU30","ZU30");

			ZU31 = new Label("AllowanceChargeCode.ZU31","ZU31");

			ZU32 = new Label("AllowanceChargeCode.ZU32","ZU32");

			ZU33 = new Label("AllowanceChargeCode.ZU33","ZU33");

			ZU34 = new Label("AllowanceChargeCode.ZU34","ZU34");

			ZU35 = new Label("AllowanceChargeCode.ZU35","ZU35");

			ZU36 = new Label("AllowanceChargeCode.ZU36","ZU36");

			ZU37 = new Label("AllowanceChargeCode.ZU37","ZU37");

			ZU38 = new Label("AllowanceChargeCode.ZU38","ZU38");

			ZU39 = new Label("AllowanceChargeCode.ZU39","ZU39");

			ZU40 = new Label("AllowanceChargeCode.ZU40","ZU40");

			ZU41 = new Label("AllowanceChargeCode.ZU41","ZU41");

			ZU50 = new Label("AllowanceChargeCode.ZU50","ZU50");

			ZU51 = new Label("AllowanceChargeCode.ZU51","ZU51");

			ZU52 = new Label("AllowanceChargeCode.ZU52","ZU52");

			ZU53 = new Label("AllowanceChargeCode.ZU53","ZU53");

			ZU54 = new Label("AllowanceChargeCode.ZU54","ZU54");

			ZU55 = new Label("AllowanceChargeCode.ZU55","ZU55");

			ZU56 = new Label("AllowanceChargeCode.ZU56","ZU56");

			ZU57 = new Label("AllowanceChargeCode.ZU57","ZU57");

			ZU58 = new Label("AllowanceChargeCode.ZU58","ZU58");

			ZU59 = new Label("AllowanceChargeCode.ZU59","ZU59");

			ZU60 = new Label("AllowanceChargeCode.ZU60","ZU60");

			ZU61 = new Label("AllowanceChargeCode.ZU61","ZU61");

			ZU62 = new Label("AllowanceChargeCode.ZU62","ZU62");

			ZU63 = new Label("AllowanceChargeCode.ZU63","ZU63");

			ZU64 = new Label("AllowanceChargeCode.ZU64","ZU64");

			ZU65 = new Label("AllowanceChargeCode.ZU65","ZU65");

			ZU66 = new Label("AllowanceChargeCode.ZU66","ZU66");

			ZU67 = new Label("AllowanceChargeCode.ZU67","ZU67");

			ZU68 = new Label("AllowanceChargeCode.ZU68","ZU68");

			ZU69 = new Label("AllowanceChargeCode.ZU69","ZU69");

			ZU70 = new Label("AllowanceChargeCode.ZU70","ZU70");

			ZU71 = new Label("AllowanceChargeCode.ZU71","ZU71");

			ZU72 = new Label("AllowanceChargeCode.ZU72","ZU72");

			ZU73 = new Label("AllowanceChargeCode.ZU73","ZU73");

			ZU74 = new Label("AllowanceChargeCode.ZU74","ZU74");

			ZU75 = new Label("AllowanceChargeCode.ZU75","ZU75");

			ZV62 = new Label("AllowanceChargeCode.ZV62","ZV62");
		}

		Certifikater = new class
		{

Label = new Label("Certifikater","Certifikater");

			Certifikat_1_ = new Label("Certifikater.Certifikat (1)","Certifikat_1_");

			Certifikat_2_ = new Label("Certifikater.Certifikat (2)","Certifikat_2_");

			Certifikat_22_ = new Label("Certifikater.Certifikat (22)","Certifikat_22_");

			Certifikat_3_ = new Label("Certifikater.Certifikat (3)","Certifikat_3_");

			Certifikat_31_ = new Label("Certifikater.Certifikat (31)","Certifikat_31_");

			Certifikat_32_ = new Label("Certifikater.Certifikat (32)","Certifikat_32_");

			Certifikat_4_ = new Label("Certifikater.Certifikat (4)","Certifikat_4_");

			Certifikat_5_ = new Label("Certifikater.Certifikat (5)","Certifikat_5_");

			Certifikat_6_ = new Label("Certifikater.Certifikat (6)","Certifikat_6_");
		}

		EasySupply = new class
		{
			Label = new Label("EasySupply","EasySupply");

			SmartButton = new class
			{

Label = new Label("EasySupply.SmartButton","SmartButton");

				HardwareIdentifier = new Label("EasySupply.SmartButton.HardwareIdentifier","HardwareIdentifier");

				Status = new class
				{

Label = new Label("EasySupply.SmartButton.Status","Status");

					CannotBeConfigured = new Label("EasySupply.SmartButton.Status.CannotBeConfigured","CannotBeConfigured");

					Deployed = new Label("EasySupply.SmartButton.Status.Deployed","Deployed");

					LowBattery = new Label("EasySupply.SmartButton.Status.LowBattery","LowBattery");

					Missing = new Label("EasySupply.SmartButton.Status.Missing","Missing");

					None = new Label("EasySupply.SmartButton.Status.None","None");
				}
			}
		}

		Emails = new class
		{
			Label = new Label("Emails","Emails");

			AccountAndUserCreated = new class
			{

Label = new Label("Emails.AccountAndUserCreated","AccountAndUserCreated");

				AccountName = new Label("Emails.AccountAndUserCreated.AccountName","AccountName");

				AccountNumber = new Label("Emails.AccountAndUserCreated.AccountNumber","AccountNumber");

				Activation = new Label("Emails.AccountAndUserCreated.Activation","Activation");

				ActivationText = new Label("Emails.AccountAndUserCreated.ActivationText","ActivationText");

				FooterAddress = new Label("Emails.AccountAndUserCreated.FooterAddress","FooterAddress");

				FooterCVR = new Label("Emails.AccountAndUserCreated.FooterCVR","FooterCVR");

				FooterEmail = new Label("Emails.AccountAndUserCreated.FooterEmail","FooterEmail");

				FooterPhone = new Label("Emails.AccountAndUserCreated.FooterPhone","FooterPhone");

				Header = new Label("Emails.AccountAndUserCreated.Header","Header");

				NeedForHelp = new Label("Emails.AccountAndUserCreated.NeedForHelp","NeedForHelp");

				NeedForHelpTextWithoutStore = new Label("Emails.AccountAndUserCreated.NeedForHelpTextWithoutStore","NeedForHelpTextWithoutStore");

				NeedForHelpTextWithStore = new Label("Emails.AccountAndUserCreated.NeedForHelpTextWithStore","NeedForHelpTextWithStore");

				SeeYourPrices = new Label("Emails.AccountAndUserCreated.SeeYourPrices","SeeYourPrices");

				SeeYourPricesText = new Label("Emails.AccountAndUserCreated.SeeYourPricesText","SeeYourPricesText");

				Text = new Label("Emails.AccountAndUserCreated.Text","Text");

				Username = new Label("Emails.AccountAndUserCreated.Username","Username");
			}

			AccountCreated = new class
			{

Label = new Label("Emails.AccountCreated","AccountCreated");

				AccountName = new Label("Emails.AccountCreated.AccountName","AccountName");

				AccountNumber = new Label("Emails.AccountCreated.AccountNumber","AccountNumber");

				Activation = new Label("Emails.AccountCreated.Activation","Activation");

				ActivationWithNoConfirmation = new Label("Emails.AccountCreated.ActivationWithNoConfirmation","ActivationWithNoConfirmation");

				BottomText = new Label("Emails.AccountCreated.BottomText","BottomText");

				Header = new Label("Emails.AccountCreated.Header","Header");

				HeaderWithNoConfirmation = new Label("Emails.AccountCreated.HeaderWithNoConfirmation","HeaderWithNoConfirmation");

				HeaderWithNoUserNotification = new Label("Emails.AccountCreated.HeaderWithNoUserNotification","HeaderWithNoUserNotification");

				Text = new Label("Emails.AccountCreated.Text","Text");

				TextWithNoConfirmation = new Label("Emails.AccountCreated.TextWithNoConfirmation","TextWithNoConfirmation");

				Username = new Label("Emails.AccountCreated.Username","Username");

				WarningText = new Label("Emails.AccountCreated.WarningText","WarningText");
			}

			BlockedAccountCreated = new class
			{

Label = new Label("Emails.BlockedAccountCreated","BlockedAccountCreated");

				Header = new Label("Emails.BlockedAccountCreated.Header","Header");

				Text = new Label("Emails.BlockedAccountCreated.Text","Text");
			}

			ChangePassword = new class
			{

Label = new Label("Emails.ChangePassword","ChangePassword");

				Account = new Label("Emails.ChangePassword.Account","Account");

				ChooseNewPassword = new Label("Emails.ChangePassword.ChooseNewPassword","ChooseNewPassword");

				Header = new Label("Emails.ChangePassword.Header","Header");

				Name = new Label("Emails.ChangePassword.Name","Name");

				Password = new Label("Emails.ChangePassword.Password","Password");

				Subject = new Label("Emails.ChangePassword.Subject","Subject");

				Text = new Label("Emails.ChangePassword.Text","Text");

				Username = new Label("Emails.ChangePassword.Username","Username");
			}

			DownloadCatalog = new class
			{

Label = new Label("Emails.DownloadCatalog","DownloadCatalog");

				CatalogSendTo = new Label("Emails.DownloadCatalog.CatalogSendTo","CatalogSendTo");
			}

			ElectronicDocumentsSignUp = new class
			{

Label = new Label("Emails.ElectronicDocumentsSignUp","ElectronicDocumentsSignUp");

				CannotRemoveLastAdmin = new Label("Emails.ElectronicDocumentsSignUp.CannotRemoveLastAdmin","CannotRemoveLastAdmin");

				ContactForOtherDocTypes = new Label("Emails.ElectronicDocumentsSignUp.ContactForOtherDocTypes","ContactForOtherDocTypes");

				DeliveryInformationHeader = new Label("Emails.ElectronicDocumentsSignUp.DeliveryInformationHeader","DeliveryInformationHeader");

				DeliveryInformationText = new Label("Emails.ElectronicDocumentsSignUp.DeliveryInformationText","DeliveryInformationText");

				EmailAccountNumber = new Label("Emails.ElectronicDocumentsSignUp.EmailAccountNumber","EmailAccountNumber");

				EmailChangeEmailAddress = new Label("Emails.ElectronicDocumentsSignUp.EmailChangeEmailAddress","EmailChangeEmailAddress");

				EmailNewEmailAddress = new Label("Emails.ElectronicDocumentsSignUp.EmailNewEmailAddress","EmailNewEmailAddress");

				EmailOldEmailAddress = new Label("Emails.ElectronicDocumentsSignUp.EmailOldEmailAddress","EmailOldEmailAddress");

				Emails_ElectronicDocumentsSignUp_Emails_ElectronicDocumentsSignUpSaveText = new Label("Emails.ElectronicDocumentsSignUp.Emails.ElectronicDocumentsSignUpSaveText","Emails_ElectronicDocumentsSignUp_Emails_ElectronicDocumentsSignUpSaveText");

				Emails_ElectronicDocumentsSignUp_Emails_ElectronicDocumentsSignUpTitle = new Label("Emails.ElectronicDocumentsSignUp.Emails.ElectronicDocumentsSignUpTitle","Emails_ElectronicDocumentsSignUp_Emails_ElectronicDocumentsSignUpTitle");

				EmailSignUpForDeliveryInformation = new Label("Emails.ElectronicDocumentsSignUp.EmailSignUpForDeliveryInformation","EmailSignUpForDeliveryInformation");

				EmailSignUpForInvoice = new Label("Emails.ElectronicDocumentsSignUp.EmailSignUpForInvoice","EmailSignUpForInvoice");

				EmailSignUpForOrderConfirmation = new Label("Emails.ElectronicDocumentsSignUp.EmailSignUpForOrderConfirmation","EmailSignUpForOrderConfirmation");

				EmailSignUpToNewEmailAddress = new Label("Emails.ElectronicDocumentsSignUp.EmailSignUpToNewEmailAddress","EmailSignUpToNewEmailAddress");

				InvoiceHeader = new Label("Emails.ElectronicDocumentsSignUp.InvoiceHeader","InvoiceHeader");

				InvoiceText = new Label("Emails.ElectronicDocumentsSignUp.InvoiceText","InvoiceText");

				NewUserSaveText = new Label("Emails.ElectronicDocumentsSignUp.NewUserSaveText","NewUserSaveText");

				NewUserTitle = new Label("Emails.ElectronicDocumentsSignUp.NewUserTitle","NewUserTitle");

				OrderConfirmationHeader = new Label("Emails.ElectronicDocumentsSignUp.OrderConfirmationHeader","OrderConfirmationHeader");

				OrderConfirmationText = new Label("Emails.ElectronicDocumentsSignUp.OrderConfirmationText","OrderConfirmationText");

				OrderInvoice = new Label("Emails.ElectronicDocumentsSignUp.OrderInvoice","OrderInvoice");

				ProductCatalogHeader = new Label("Emails.ElectronicDocumentsSignUp.ProductCatalogHeader","ProductCatalogHeader");

				ProductCatalogText = new Label("Emails.ElectronicDocumentsSignUp.ProductCatalogText","ProductCatalogText");

				ProductNumberTypeBuyerId = new Label("Emails.ElectronicDocumentsSignUp.ProductNumberTypeBuyerId","ProductNumberTypeBuyerId");

				ProductNumberTypeCarlFId = new Label("Emails.ElectronicDocumentsSignUp.ProductNumberTypeCarlFId","ProductNumberTypeCarlFId");

				ProductNumberTypeHeadline = new Label("Emails.ElectronicDocumentsSignUp.ProductNumberTypeHeadline","ProductNumberTypeHeadline");

				ProductNumberTypeId = new Label("Emails.ElectronicDocumentsSignUp.ProductNumberTypeId","ProductNumberTypeId");

				ProductNumberTypeLId = new Label("Emails.ElectronicDocumentsSignUp.ProductNumberTypeLId","ProductNumberTypeLId");

				ProductNumberTypeVvsId = new Label("Emails.ElectronicDocumentsSignUp.ProductNumberTypeVvsId","ProductNumberTypeVvsId");

				PunchOutAccount = new Label("Emails.ElectronicDocumentsSignUp.PunchOutAccount","PunchOutAccount");

				PunchOutDeleteCustomXslt = new Label("Emails.ElectronicDocumentsSignUp.PunchOutDeleteCustomXslt","PunchOutDeleteCustomXslt");

				PunchOutFileWasNotOfTypeXslt = new Label("Emails.ElectronicDocumentsSignUp.PunchOutFileWasNotOfTypeXslt","PunchOutFileWasNotOfTypeXslt");

				PunchOutHeadline = new Label("Emails.ElectronicDocumentsSignUp.PunchOutHeadline","PunchOutHeadline");

				PunchOutNoCustomXsltUploaded = new Label("Emails.ElectronicDocumentsSignUp.PunchOutNoCustomXsltUploaded","PunchOutNoCustomXsltUploaded");

				PunchOutNoFileChoosen = new Label("Emails.ElectronicDocumentsSignUp.PunchOutNoFileChoosen","PunchOutNoFileChoosen");

				PunchOutUploadButtonText = new Label("Emails.ElectronicDocumentsSignUp.PunchOutUploadButtonText","PunchOutUploadButtonText");

				PunchOutUploadCustomXsltFile = new Label("Emails.ElectronicDocumentsSignUp.PunchOutUploadCustomXsltFile","PunchOutUploadCustomXsltFile");

				SubscribeForProductcatalog = new Label("Emails.ElectronicDocumentsSignUp.SubscribeForProductcatalog","SubscribeForProductcatalog");

				WantToReceiveOrderInvoice1 = new Label("Emails.ElectronicDocumentsSignUp.WantToReceiveOrderInvoice1","WantToReceiveOrderInvoice1");

				WantToReceiveOrderInvoice2 = new Label("Emails.ElectronicDocumentsSignUp.WantToReceiveOrderInvoice2","WantToReceiveOrderInvoice2");
			}

			General = new class
			{
				Label = new Label("Emails.General","General");

				Footer = new class
				{

Label = new Label("Emails.General.Footer","Footer");

					About = new Label("Emails.General.Footer.About","About");

					BestRegards = new Label("Emails.General.Footer.BestRegards","BestRegards");

					Phone = new Label("Emails.General.Footer.Phone","Phone");

					Terms = new Label("Emails.General.Footer.Terms","Terms");

					Text = new Label("Emails.General.Footer.Text","Text");
				}
			}

			OrderConfirmation = new class
			{

Label = new Label("Emails.OrderConfirmation","OrderConfirmation");

				AccountNumber = new Label("Emails.OrderConfirmation.AccountNumber","AccountNumber");

				CaseNumber = new Label("Emails.OrderConfirmation.CaseNumber","CaseNumber");

				Comment = new Label("Emails.OrderConfirmation.Comment","Comment");

				Footer = new class
				{

Label = new Label("Emails.OrderConfirmation.Footer","Footer");

					About = new Label("Emails.OrderConfirmation.Footer.About","About");

					BestRegards = new Label("Emails.OrderConfirmation.Footer.BestRegards","BestRegards");

					Phone = new Label("Emails.OrderConfirmation.Footer.Phone","Phone");

					Terms = new Label("Emails.OrderConfirmation.Footer.Terms","Terms");

					Text = new Label("Emails.OrderConfirmation.Footer.Text","Text");
				}

				Header = new class
				{

Label = new Label("Emails.OrderConfirmation.Header","Header");

					OwnCaseControl = new Label("Emails.OrderConfirmation.Header.OwnCaseControl","OwnCaseControl");

					PickupInStore = new Label("Emails.OrderConfirmation.Header.PickupInStore","PickupInStore");

					PickupTime = new Label("Emails.OrderConfirmation.Header.PickupTime","PickupTime");
				}

				Lines = new class
				{

Label = new Label("Emails.OrderConfirmation.Lines","Lines");

					ExpectedDelivery = new Label("Emails.OrderConfirmation.Lines.ExpectedDelivery","ExpectedDelivery");

					Emails_OrderConfirmation_Lines_ExpectedDelivery_test = new Label("Emails.OrderConfirmation.Lines.ExpectedDelivery.test","Emails_OrderConfirmation_Lines_ExpectedDelivery_test");

					Header = new class
					{

Label = new Label("Emails.OrderConfirmation.Lines.Header","Header");

						Delivery = new Label("Emails.OrderConfirmation.Lines.Header.Delivery","Delivery");

						Pickup = new Label("Emails.OrderConfirmation.Lines.Header.Pickup","Pickup");

						ProductName = new Label("Emails.OrderConfirmation.Lines.Header.ProductName","ProductName");

						ProductNumber = new Label("Emails.OrderConfirmation.Lines.Header.ProductNumber","ProductNumber");

						Quantity = new Label("Emails.OrderConfirmation.Lines.Header.Quantity","Quantity");

						TotalNetPrice = new Label("Emails.OrderConfirmation.Lines.Header.TotalNetPrice","TotalNetPrice");

						VvsId = new Label("Emails.OrderConfirmation.Lines.Header.VvsId","VvsId");
					}

					Total = new Label("Emails.OrderConfirmation.Lines.Total","Total");
				}

				Manual = new Label("Emails.OrderConfirmation.Manual","Manual");

				NotAllProductsReady = new Label("Emails.OrderConfirmation.NotAllProductsReady","NotAllProductsReady");

				OrderNumber = new Label("Emails.OrderConfirmation.OrderNumber","OrderNumber");

				Reference = new Label("Emails.OrderConfirmation.Reference","Reference");

				RequisitionNumber = new Label("Emails.OrderConfirmation.RequisitionNumber","RequisitionNumber");

				ShippingMethods = new class
				{

Label = new Label("Emails.OrderConfirmation.ShippingMethods","ShippingMethods");

					CC = new Label("Emails.OrderConfirmation.ShippingMethods.CC","CC");

					Day = new Label("Emails.OrderConfirmation.ShippingMethods.Day","Day");

					FlexBox = new Label("Emails.OrderConfirmation.ShippingMethods.FlexBox","FlexBox");

					Night = new Label("Emails.OrderConfirmation.ShippingMethods.Night","Night");

					Shop = new Label("Emails.OrderConfirmation.ShippingMethods.Shop","Shop");
				}

				ShippingTime = new class
				{

Label = new Label("Emails.OrderConfirmation.ShippingTime","ShippingTime");

					CC = new Label("Emails.OrderConfirmation.ShippingTime.CC","CC");

					DayOrNight = new Label("Emails.OrderConfirmation.ShippingTime.DayOrNight","DayOrNight");

					Shop = new Label("Emails.OrderConfirmation.ShippingTime.Shop","Shop");
				}

				Subject = new Label("Emails.OrderConfirmation.Subject","Subject");

				Teaser = new Label("Emails.OrderConfirmation.Teaser","Teaser");

				Text = new Label("Emails.OrderConfirmation.Text","Text");
			}

			OrderReciept = new class
			{

Label = new Label("Emails.OrderReciept","OrderReciept");

				Header = new Label("Emails.OrderReciept.Header","Header");

				OrderReference = new Label("Emails.OrderReciept.OrderReference","OrderReference");

				Subject = new Label("Emails.OrderReciept.Subject","Subject");

				Teaser = new class
				{

Label = new Label("Emails.OrderReciept.Teaser","Teaser");

					Extended = new Label("Emails.OrderReciept.Teaser.Extended","Extended");
				}
			}

			ProjectOrderEmail = new class
			{

Label = new Label("Emails.ProjectOrderEmail","ProjectOrderEmail");

				ContactPerson = new Label("Emails.ProjectOrderEmail.ContactPerson","ContactPerson");

				DeliveryText = new Label("Emails.ProjectOrderEmail.DeliveryText","DeliveryText");

				ENOEmail = new Label("Emails.ProjectOrderEmail.ENOEmail","ENOEmail");

				NextDayDeliveryLimit = new Label("Emails.ProjectOrderEmail.NextDayDeliveryLimit","NextDayDeliveryLimit");

				OrderId = new Label("Emails.ProjectOrderEmail.OrderId","OrderId");

				Subject = new Label("Emails.ProjectOrderEmail.Subject","Subject");

				UnloadingEmail = new Label("Emails.ProjectOrderEmail.UnloadingEmail","UnloadingEmail");

				UnloadingOption = new Label("Emails.ProjectOrderEmail.UnloadingOption","UnloadingOption");
			}

			Reports = new class
			{
				Label = new Label("Emails.Reports","Reports");

				Generic = new class
				{

Label = new Label("Emails.Reports.Generic","Generic");

					CatalogReadyBody = new Label("Emails.Reports.Generic.CatalogReadyBody","CatalogReadyBody");

					CatalogReadySubject = new Label("Emails.Reports.Generic.CatalogReadySubject","CatalogReadySubject");
				}

				KlarPrisDailyRequestLogs = new class
				{

Label = new Label("Emails.Reports.KlarPrisDailyRequestLogs","KlarPrisDailyRequestLogs");

					Body = new Label("Emails.Reports.KlarPrisDailyRequestLogs.Body","Body");

					Subject = new Label("Emails.Reports.KlarPrisDailyRequestLogs.Subject","Subject");
				}

				Options = new class
				{
					Label = new Label("Emails.Reports.Options","Options");

					PriceTypes = new class
					{

Label = new Label("Emails.Reports.Options.PriceTypes","PriceTypes");

						Both = new Label("Emails.Reports.Options.PriceTypes.Both","Both");

						GrossPrice = new Label("Emails.Reports.Options.PriceTypes.GrossPrice","GrossPrice");

						NetPrice = new Label("Emails.Reports.Options.PriceTypes.NetPrice","NetPrice");

						None = new Label("Emails.Reports.Options.PriceTypes.None","None");
					}

					ProductNumberTypes = new class
					{

Label = new Label("Emails.Reports.Options.ProductNumberTypes","ProductNumberTypes");

						BuyerItemNumber = new Label("Emails.Reports.Options.ProductNumberTypes.BuyerItemNumber","BuyerItemNumber");

						CarlF = new Label("Emails.Reports.Options.ProductNumberTypes.CarlF","CarlF");

						EanNo = new Label("Emails.Reports.Options.ProductNumberTypes.EanNo","EanNo");

						LNo = new Label("Emails.Reports.Options.ProductNumberTypes.LNo","LNo");

						SaniNumber = new Label("Emails.Reports.Options.ProductNumberTypes.SaniNumber","SaniNumber");

						VvsNumber = new Label("Emails.Reports.Options.ProductNumberTypes.VvsNumber","VvsNumber");
					}

					ReportTypes = new class
					{

Label = new Label("Emails.Reports.Options.ReportTypes","ReportTypes");

						BrotherDK11201 = new Label("Emails.Reports.Options.ReportTypes.BrotherDK11201","BrotherDK11201");

						BrotherDK11202 = new Label("Emails.Reports.Options.ReportTypes.BrotherDK11202","BrotherDK11202");

						BrotherDK11204 = new Label("Emails.Reports.Options.ReportTypes.BrotherDK11204","BrotherDK11204");

						BrotherDK11208 = new Label("Emails.Reports.Options.ReportTypes.BrotherDK11208","BrotherDK11208");

						BrotherDK11209 = new Label("Emails.Reports.Options.ReportTypes.BrotherDK11209","BrotherDK11209");

						CR80 = new Label("Emails.Reports.Options.ReportTypes.CR80","CR80");

						Dymo11354 = new Label("Emails.Reports.Options.ReportTypes.Dymo11354","Dymo11354");

						Dymo99010 = new Label("Emails.Reports.Options.ReportTypes.Dymo99010","Dymo99010");

						Dymo99012 = new Label("Emails.Reports.Options.ReportTypes.Dymo99012","Dymo99012");

						Dymo99014 = new Label("Emails.Reports.Options.ReportTypes.Dymo99014","Dymo99014");

						EasySupplyHyldeForkant = new Label("Emails.Reports.Options.ReportTypes.EasySupplyHyldeForkant","EasySupplyHyldeForkant");
					}
				}

				ProductNumberTypes = new class
				{

Label = new Label("Emails.Reports.ProductNumberTypes","ProductNumberTypes");

					BuyerItemNumber = new Label("Emails.Reports.ProductNumberTypes.BuyerItemNumber","BuyerItemNumber");

					CarlF = new Label("Emails.Reports.ProductNumberTypes.CarlF","CarlF");

					EanNo = new Label("Emails.Reports.ProductNumberTypes.EanNo","EanNo");

					LNo = new Label("Emails.Reports.ProductNumberTypes.LNo","LNo");

					None = new Label("Emails.Reports.ProductNumberTypes.None","None");

					SaniNumber = new Label("Emails.Reports.ProductNumberTypes.SaniNumber","SaniNumber");

					VvsNumber = new Label("Emails.Reports.ProductNumberTypes.VvsNumber","VvsNumber");
				}
			}

			ReturnItemMail = new class
			{

Label = new Label("Emails.ReturnItemMail","ReturnItemMail");

				ConditionLink = new Label("Emails.ReturnItemMail.ConditionLink","ConditionLink");

				ContactPhone = new Label("Emails.ReturnItemMail.ContactPhone","ContactPhone");

				CustomerCC = new Label("Emails.ReturnItemMail.CustomerCC","CustomerCC");

				Header = new Label("Emails.ReturnItemMail.Header","Header");

				ProductText = new Label("Emails.ReturnItemMail.ProductText","ProductText");

				Quantity = new Label("Emails.ReturnItemMail.Quantity","Quantity");

				Reason = new Label("Emails.ReturnItemMail.Reason","Reason");

				RequestedBy = new Label("Emails.ReturnItemMail.RequestedBy","RequestedBy");

				ReturnAddress = new Label("Emails.ReturnItemMail.ReturnAddress","ReturnAddress");

				ReturnConditions = new Label("Emails.ReturnItemMail.ReturnConditions","ReturnConditions");

				ReturnQuantity = new Label("Emails.ReturnItemMail.ReturnQuantity","ReturnQuantity");

				Saninr = new Label("Emails.ReturnItemMail.Saninr","Saninr");
			}

			SmartButton = new class
			{

Label = new Label("Emails.SmartButton","SmartButton");

				Body = new Label("Emails.SmartButton.Body","Body");

				Subject = new Label("Emails.SmartButton.Subject","Subject");
			}

			StoreAccess = new class
			{
				Label = new Label("Emails.StoreAccess","StoreAccess");

				CloseStoreConfirmation = new class
				{

Label = new Label("Emails.StoreAccess.CloseStoreConfirmation","CloseStoreConfirmation");

					Body = new Label("Emails.StoreAccess.CloseStoreConfirmation.Body","Body");

					Subject = new Label("Emails.StoreAccess.CloseStoreConfirmation.Subject","Subject");
				}
			}

			SubscribeForProductcatalog = new class
			{

Label = new Label("Emails.SubscribeForProductcatalog","SubscribeForProductcatalog");

				Address = new Label("Emails.SubscribeForProductcatalog.Address","Address");

				AllProducts = new Label("Emails.SubscribeForProductcatalog.AllProducts","AllProducts");

				AllProductsNumber = new Label("Emails.SubscribeForProductcatalog.AllProductsNumber","AllProductsNumber");

				Approximately = new Label("Emails.SubscribeForProductcatalog.Approximately","Approximately");

				CatalogSubscriptionsChangedTo = new Label("Emails.SubscribeForProductcatalog.CatalogSubscriptionsChangedTo","CatalogSubscriptionsChangedTo");

				CompanyName = new Label("Emails.SubscribeForProductcatalog.CompanyName","CompanyName");

				ContactPerson = new Label("Emails.SubscribeForProductcatalog.ContactPerson","ContactPerson");

				Daily = new Label("Emails.SubscribeForProductcatalog.Daily","Daily");

				DoNotWantProductCatalogs = new Label("Emails.SubscribeForProductcatalog.DoNotWantProductCatalogs","DoNotWantProductCatalogs");

				Electricity = new Label("Emails.SubscribeForProductcatalog.Electricity","Electricity");

				ElectricityNumber = new Label("Emails.SubscribeForProductcatalog.ElectricityNumber","ElectricityNumber");

				Email = new Label("Emails.SubscribeForProductcatalog.Email","Email");

				Gas = new Label("Emails.SubscribeForProductcatalog.Gas","Gas");

				GasNumber = new Label("Emails.SubscribeForProductcatalog.GasNumber","GasNumber");

				Headline = new Label("Emails.SubscribeForProductcatalog.Headline","Headline");

				HeadlineChange = new Label("Emails.SubscribeForProductcatalog.HeadlineChange","HeadlineChange");

				HeadlineNew = new Label("Emails.SubscribeForProductcatalog.HeadlineNew","HeadlineNew");

				MainPhoneNumber = new Label("Emails.SubscribeForProductcatalog.MainPhoneNumber","MainPhoneNumber");

				Metals = new Label("Emails.SubscribeForProductcatalog.Metals","Metals");

				MetalsNumber = new Label("Emails.SubscribeForProductcatalog.MetalsNumber","MetalsNumber");

				Monthly = new Label("Emails.SubscribeForProductcatalog.Monthly","Monthly");

				NameOfSystem = new Label("Emails.SubscribeForProductcatalog.NameOfSystem","NameOfSystem");

				No = new Label("Emails.SubscribeForProductcatalog.No","No");

				NumberOfProduct = new Label("Emails.SubscribeForProductcatalog.NumberOfProduct","NumberOfProduct");

				PhoneNumber = new Label("Emails.SubscribeForProductcatalog.PhoneNumber","PhoneNumber");

				ProductGroups = new Label("Emails.SubscribeForProductcatalog.ProductGroups","ProductGroups");

				Remarks = new Label("Emails.SubscribeForProductcatalog.Remarks","Remarks");

				SendUpdatesEvery = new Label("Emails.SubscribeForProductcatalog.SendUpdatesEvery","SendUpdatesEvery");

				Steel = new Label("Emails.SubscribeForProductcatalog.Steel","Steel");

				SteelNumber = new Label("Emails.SubscribeForProductcatalog.SteelNumber","SteelNumber");

				SubscribeButtonText = new Label("Emails.SubscribeForProductcatalog.SubscribeButtonText","SubscribeButtonText");

				TechnicalArticles = new Label("Emails.SubscribeForProductcatalog.TechnicalArticles","TechnicalArticles");

				TechnicalArticlesNumber = new Label("Emails.SubscribeForProductcatalog.TechnicalArticlesNumber","TechnicalArticlesNumber");

				ToolMachines = new Label("Emails.SubscribeForProductcatalog.ToolMachines","ToolMachines");

				ToolMachinesNumber = new Label("Emails.SubscribeForProductcatalog.ToolMachinesNumber","ToolMachinesNumber");

				Tools = new Label("Emails.SubscribeForProductcatalog.Tools","Tools");

				ToolsNumber = new Label("Emails.SubscribeForProductcatalog.ToolsNumber","ToolsNumber");

				Various = new Label("Emails.SubscribeForProductcatalog.Various","Various");

				VariousNumber = new Label("Emails.SubscribeForProductcatalog.VariousNumber","VariousNumber");

				VssNumberInProductText = new Label("Emails.SubscribeForProductcatalog.VssNumberInProductText","VssNumberInProductText");

				Vvs = new Label("Emails.SubscribeForProductcatalog.Vvs","Vvs");

				VvsNumber = new Label("Emails.SubscribeForProductcatalog.VvsNumber","VvsNumber");

				WantProductGroupsSend = new Label("Emails.SubscribeForProductcatalog.WantProductGroupsSend","WantProductGroupsSend");

				Weekly = new Label("Emails.SubscribeForProductcatalog.Weekly","Weekly");

				Yes = new Label("Emails.SubscribeForProductcatalog.Yes","Yes");
			}

			UserActivation = new class
			{

Label = new Label("Emails.UserActivation","UserActivation");

				Account = new Label("Emails.UserActivation.Account","Account");

				Activation = new Label("Emails.UserActivation.Activation","Activation");

				BottomText = new Label("Emails.UserActivation.BottomText","BottomText");

				Header = new Label("Emails.UserActivation.Header","Header");

				Name = new Label("Emails.UserActivation.Name","Name");

				Subject = new Label("Emails.UserActivation.Subject","Subject");

				Text = new Label("Emails.UserActivation.Text","Text");

				Username = new Label("Emails.UserActivation.Username","Username");

				WarningText = new Label("Emails.UserActivation.WarningText","WarningText");
			}

			UserRegistration = new class
			{

Label = new Label("Emails.UserRegistration","UserRegistration");

				Account = new Label("Emails.UserRegistration.Account","Account");

				Header = new Label("Emails.UserRegistration.Header","Header");

				Name = new Label("Emails.UserRegistration.Name","Name");

				NewUserCreated = new Label("Emails.UserRegistration.NewUserCreated","NewUserCreated");

				Subject = new Label("Emails.UserRegistration.Subject","Subject");

				Text = new Label("Emails.UserRegistration.Text","Text");

				Username = new Label("Emails.UserRegistration.Username","Username");
			}
		}

		Errors = new class
		{

Label = new Label("Errors","Errors");

			Api = new class
			{
				Label = new Label("Errors.Api","Api");

				DigitalCommunication = new class
				{

Label = new Label("Errors.Api.DigitalCommunication","DigitalCommunication");

					MissingAddress = new Label("Errors.Api.DigitalCommunication.MissingAddress","MissingAddress");

					NotEnabled = new Label("Errors.Api.DigitalCommunication.NotEnabled","NotEnabled");
				}
			}

			ApiParameterIsNull = new Label("Errors.ApiParameterIsNull","ApiParameterIsNull");

			Basket = new class
			{

Label = new Label("Errors.Basket","Basket");

				NotFound = new Label("Errors.Basket.NotFound","NotFound");
			}

			CannotRemoveLastAdmin = new Label("Errors.CannotRemoveLastAdmin","CannotRemoveLastAdmin");

			CreateUser = new class
			{

Label = new Label("Errors.CreateUser","CreateUser");

				NemIdLoginRequired = new Label("Errors.CreateUser.NemIdLoginRequired","NemIdLoginRequired");
			}

			LoginRequired = new class
			{

Label = new Label("Errors.LoginRequired","LoginRequired");

				button = new Label("Errors.LoginRequired.button","button");

				Postfix = new Label("Errors.LoginRequired.Postfix","Postfix");
			}

			Sap = new class
			{

Label = new Label("Errors.Sap","Sap");

				ContactSanistaal = new Label("Errors.Sap.ContactSanistaal","ContactSanistaal");
			}

			Test = new Label("Errors.Test","Test");

			Unauthorized = new Label("Errors.Unauthorized","Unauthorized");

			Unknown = new Label("Errors.Unknown","Unknown");
		}

		General = new class
		{

Label = new Label("General","General");

			Cancel = new Label("General.Cancel","Cancel");

			CookieAcceptLabel = new Label("General.CookieAcceptLabel","CookieAcceptLabel");

			CookieAcceptText = new Label("General.CookieAcceptText","CookieAcceptText");

			DownloadingFile = new Label("General.DownloadingFile","DownloadingFile");

			Show = new Label("General.Show","Show");
		}

		MobileNotifications = new class
		{
			Label = new Label("MobileNotifications","MobileNotifications");

			StoreAccess = new class
			{
				Label = new Label("MobileNotifications.StoreAccess","StoreAccess");

				LastStoreUser = new class
				{

Label = new Label("MobileNotifications.StoreAccess.LastStoreUser","LastStoreUser");

					Body = new Label("MobileNotifications.StoreAccess.LastStoreUser.Body","Body");

					Title = new Label("MobileNotifications.StoreAccess.LastStoreUser.Title","Title");
				}

				StoreOpening = new class
				{

Label = new Label("MobileNotifications.StoreAccess.StoreOpening","StoreOpening");

					Body = new Label("MobileNotifications.StoreAccess.StoreOpening.Body","Body");

					Title = new Label("MobileNotifications.StoreAccess.StoreOpening.Title","Title");
				}

				WarningThatStoreWillClose = new class
				{

Label = new Label("MobileNotifications.StoreAccess.WarningThatStoreWillClose","WarningThatStoreWillClose");

					Body = new Label("MobileNotifications.StoreAccess.WarningThatStoreWillClose.Body","Body");

					Title = new Label("MobileNotifications.StoreAccess.WarningThatStoreWillClose.Title","Title");
				}
			}
		}

		Newsletter = new class
		{

Label = new Label("Newsletter","Newsletter");

			MailChimpCategoryNameConstructionNews = new Label("Newsletter.MailChimpCategoryNameConstructionNews","MailChimpCategoryNameConstructionNews");

			MailChimpCategoryNameFinancialNews = new Label("Newsletter.MailChimpCategoryNameFinancialNews","MailChimpCategoryNameFinancialNews");

			MailChimpCategoryNameIndustryNews = new Label("Newsletter.MailChimpCategoryNameIndustryNews","MailChimpCategoryNameIndustryNews");

			MailChimpCategoryNameSanistaalNews = new Label("Newsletter.MailChimpCategoryNameSanistaalNews","MailChimpCategoryNameSanistaalNews");
		}

		OrderType = new class
		{

Label = new Label("OrderType","OrderType");

			ZFO = new Label("OrderType.ZFO","ZFO");

			ZQ1 = new Label("OrderType.ZQ1","ZQ1");
		}

		Product = new class
		{

Label = new Label("Product","Product");

			AddCertificate = new Label("Product.AddCertificate","AddCertificate");

			AddedToBasket = new Label("Product.AddedToBasket","AddedToBasket");

			AddToBasket = new Label("Product.AddToBasket","AddToBasket");

			Api = new class
			{

Label = new Label("Product.Api","Api");

				ExpectedDeliveryTime = new Label("Product.Api.ExpectedDeliveryTime","ExpectedDeliveryTime");

				FixedUnit = new class
				{

Label = new Label("Product.Api.FixedUnit","FixedUnit");

					SalesUnitWithQuantityAndBaseUnit = new Label("Product.Api.FixedUnit.SalesUnitWithQuantityAndBaseUnit","SalesUnitWithQuantityAndBaseUnit");
				}

				InStock = new class
				{

Label = new Label("Product.Api.InStock","InStock");

					WithDeliveryTime = new Label("Product.Api.InStock.WithDeliveryTime","WithDeliveryTime");

					WithQuantity = new Label("Product.Api.InStock.WithQuantity","WithQuantity");

					WithQuantityAndDeliveryTime = new Label("Product.Api.InStock.WithQuantityAndDeliveryTime","WithQuantityAndDeliveryTime");
				}

				NotInStock = new class
				{

Label = new Label("Product.Api.NotInStock","NotInStock");

					ContactForDeliveryTime = new Label("Product.Api.NotInStock.ContactForDeliveryTime","ContactForDeliveryTime");

					WithExpectedDeliveryDate = new Label("Product.Api.NotInStock.WithExpectedDeliveryDate","WithExpectedDeliveryDate");
				}

				SecurityDatasheet = new class
				{

Label = new Label("Product.Api.SecurityDatasheet","SecurityDatasheet");

					NameFromValue = new Label("Product.Api.SecurityDatasheet.NameFromValue","NameFromValue");
				}

				SoldOut = new Label("Product.Api.SoldOut","SoldOut");

				SpecialOrderProduct = new class
				{

Label = new Label("Product.Api.SpecialOrderProduct","SpecialOrderProduct");

					Name = new Label("Product.Api.SpecialOrderProduct.Name","Name");

					NameAndExpectedDeliveryTime = new Label("Product.Api.SpecialOrderProduct.NameAndExpectedDeliveryTime","NameAndExpectedDeliveryTime");
				}

				Unit = new class
				{

Label = new Label("Product.Api.Unit","Unit");

					SalesUnitWithQuantityAndBaseUnit = new Label("Product.Api.Unit.SalesUnitWithQuantityAndBaseUnit","SalesUnitWithQuantityAndBaseUnit");
				}
			}

			Bom = new class
			{

Label = new Label("Product.Bom","Bom");

				AddToBasket = new Label("Product.Bom.AddToBasket","AddToBasket");

				BomContains = new Label("Product.Bom.BomContains","BomContains");

				InfoHeader = new Label("Product.Bom.InfoHeader","InfoHeader");

				InfoText = new Label("Product.Bom.InfoText","InfoText");

				QuantityChanged = new Label("Product.Bom.QuantityChanged","QuantityChanged");

				ViewDetails = new Label("Product.Bom.ViewDetails","ViewDetails");
			}

			BomItemsSeeMore = new Label("Product.BomItemsSeeMore","BomItemsSeeMore");

			BomProducts = new Label("Product.BomProducts","BomProducts");

			CarlfId = new Label("Product.CarlfId","CarlfId");

			Certificate = new Label("Product.Certificate","Certificate");

			Charges = new Label("Product.Charges","Charges");

			ChooseVariantToSeePrice = new Label("Product.ChooseVariantToSeePrice","ChooseVariantToSeePrice");

			Co2 = new class
			{

Label = new Label("Product.Co2","Co2");

				Explainer = new Label("Product.Co2.Explainer","Explainer");

				ExplainerHeading = new Label("Product.Co2.ExplainerHeading","ExplainerHeading");

				Heading = new Label("Product.Co2.Heading","Heading");

				IconAltText = new Label("Product.Co2.IconAltText","IconAltText");

				ReadMore = new Label("Product.Co2.ReadMore","ReadMore");

				Unit = new class
				{

Label = new Label("Product.Co2.Unit","Unit");

					EA = new Label("Product.Co2.Unit.EA","EA");

					KG = new Label("Product.Co2.Unit.KG","KG");

					M = new Label("product.Co2.Unit.M","M");

					M2 = new Label("Product.Co2.Unit.M2","M2");

					M3 = new Label("Product.Co2.Unit.M3","M3");

					PAR = new Label("product.Co2.Unit.PAR","PAR");

					STK = new Label("Product.Co2.Unit.STK","STK");
				}
			}

			CommonId = new Label("Product.CommonId","CommonId");

			ContactForDeliveryTime = new Label("Product.ContactForDeliveryTime","ContactForDeliveryTime");

			CustomerProductId = new Label("Product.CustomerProductId","CustomerProductId");

			DbId = new Label("Product.DbId","DbId");

			DecreaseQuantity = new Label("Product.DecreaseQuantity","DecreaseQuantity");

			DeliveryTime = new Label("Product.DeliveryTime","DeliveryTime");

			DiscountDeducted = new Label("Product.DiscountDeducted","DiscountDeducted");

			EanId = new Label("Product.EanId","EanId");

			EcommerceAllowance = new Label("Product.EcommerceAllowance","EcommerceAllowance");

			Excluding = new Label("Product.Excluding","Excluding");

			ExcludingTax = new Label("Product.ExcludingTax","ExcludingTax");

			ExpectedDeliveryTime = new Label("Product.ExpectedDeliveryTime","ExpectedDeliveryTime");

			ExpectedInStock = new Label("Product.ExpectedInStock","ExpectedInStock");

			FixedUnit = new class
			{

Label = new Label("Product.FixedUnit","FixedUnit");

				Description = new Label("Product.FixedUnit.Description","Description");

				DisplayName = new Label("Product.FixedUnit.DisplayName","DisplayName");

				Max = new Label("Product.FixedUnit.Max","Max");

				Quantity = new Label("Product.FixedUnit.Quantity","Quantity");
			}

			GrossPrice = new Label("Product.GrossPrice","GrossPrice");

			IncreaseQuantity = new Label("Product.IncreaseQuantity","IncreaseQuantity");

			InStock = new Label("Product.InStock","InStock");

			IsEligibleForSubsidy = new Label("Product.IsEligibleForSubsidy","IsEligibleForSubsidy");

			LabelCampaign = new Label("Product.LabelCampaign","LabelCampaign");

			LabelIsFavorite = new Label("Product.LabelIsFavorite","LabelIsFavorite");

			LabelRestSalg = new Label("Product.LabelRestSalg","LabelRestSalg");

			LabelWebOffer = new Label("Product.LabelWebOffer","LabelWebOffer");

			Lnumber = new Label("Product.Lnumber","Lnumber");

			LoadingPrices = new Label("Product.LoadingPrices","LoadingPrices");

			LogInPrompt = new Label("Product.LogInPrompt","LogInPrompt");

			MinOrderQuantity = new Label("Product.MinOrderQuantity","MinOrderQuantity");

			NightDeliveryMessage = new class
			{

Label = new Label("Product.NightDeliveryMessage","NightDeliveryMessage");

				Limited = new Label("Product.NightDeliveryMessage.Limited","Limited");

				MaxAmountLimitedAtNight = new Label("Product.NightDeliveryMessage.MaxAmountLimitedAtNight","MaxAmountLimitedAtNight");

				No = new Label("Product.NightDeliveryMessage.No","No");

				Yes = new Label("Product.NightDeliveryMessage.Yes","Yes");
			}

			NoCertificate = new Label("Product.NoCertificate","NoCertificate");

			NoCertificateSelected = new Label("Product.NoCertificateSelected","NoCertificateSelected");

			NoCertifikat = new Label("Product.NoCertifikat","NoCertifikat");

			NotInStock = new Label("Product.NotInStock","NotInStock");

			NotInStockDetailed = new Label("Product.NotInStockDetailed","NotInStockDetailed");

			NoUnitsAvailable = new Label("Product.NoUnitsAvailable","NoUnitsAvailable");

			PerUnit = new Label("Product.PerUnit","PerUnit");

			PriceAfterDiscount = new Label("Product.PriceAfterDiscount","PriceAfterDiscount");

			PriceFrom = new Label("Product.PriceFrom","PriceFrom");

			PriceNotAvailable = new Label("Product.PriceNotAvailable","PriceNotAvailable");

			PricePerUnit = new Label("Product.PricePerUnit","PricePerUnit");

			PricePerUnitFormat = new Label("Product.PricePerUnitFormat","PricePerUnitFormat");

			ProductCategory = new Label("Product.ProductCategory","ProductCategory");

			ProductId = new Label("Product.ProductId","ProductId");

			ProductNotAvailable = new Label("Product.ProductNotAvailable","ProductNotAvailable");

			Relation = new class
			{

Label = new Label("Product.Relation","Relation");

				Alternative = new Label("Product.Relation.Alternative","Alternative");

				Can_Be_Replaced_By = new Label("Product.Relation.Can_Be_Replaced_By","Can_Be_Replaced_By");

				Functional = new Label("Product.Relation.Functional","Functional");

				Logical = new Label("Product.Relation.Logical","Logical");

				Other = new Label("Product.Relation.Other","Other");

				RememberToBuy = new Label("Product.Relation.RememberToBuy","RememberToBuy");

				SparePart = new Label("Product.Relation.SparePart","SparePart");

				UpSelling = new Label("Product.Relation.UpSelling","UpSelling");
			}

			Remove = new Label("Product.Remove","Remove");

			RemoveQuotation = new Label("Product.RemoveQuotation","RemoveQuotation");

			ScalePrice = new class
			{

Label = new Label("Product.ScalePrice","ScalePrice");

				Add = new Label("Product.ScalePrice.Add","Add");

				BuyMoreAndSave = new Label("Product.ScalePrice.BuyMoreAndSave","BuyMoreAndSave");

				NoThanks = new Label("Product.ScalePrice.NoThanks","NoThanks");

				PriceAt = new Label("Product.ScalePrice.PriceAt","PriceAt");

				PromotionText = new Label("Product.ScalePrice.PromotionText","PromotionText");
			}

			ScalePriceQuantity = new Label("Product.ScalePriceQuantity","ScalePriceQuantity");

			ScalePriceStep = new Label("Product.ScalePriceStep","ScalePriceStep");

			SecurityDatasheet = new Label("Product.SecurityDatasheet","SecurityDatasheet");

			SelectOtherUnit = new Label("Product.SelectOtherUnit","SelectOtherUnit");

			SelectVariant = new Label("Product.SelectVariant","SelectVariant");

			SelectVariantName = new Label("Product.SelectVariantName","SelectVariantName");

			SeparateCurrency = new Label("Product.SeparateCurrency","SeparateCurrency");

			SoldOut = new Label("Product.SoldOut","SoldOut");

			SpecialOrderProduct = new class
			{

Label = new Label("Product.SpecialOrderProduct","SpecialOrderProduct");

				Edit = new Label("Product.SpecialOrderProduct.Edit","Edit");

				Name = new Label("Product.SpecialOrderProduct.Name","Name");

				ProductItemNumber = new Label("Product.SpecialOrderProduct.ProductItemNumber","ProductItemNumber");
			}

			SpecialReturnConditions = new Label("Product.SpecialReturnConditions","SpecialReturnConditions");

			Stamping = new Label("Product.Stamping","Stamping");

			StockStatusModal = new class
			{

Label = new Label("Product.StockStatusModal","StockStatusModal");

				NoShopsMatchYourCriteria = new Label("Product.StockStatusModal.NoShopsMatchYourCriteria","NoShopsMatchYourCriteria");

				SearchInList = new Label("Product.StockStatusModal.SearchInList","SearchInList");

				ShowOnlyStoresWithTheItemInStock = new Label("Product.StockStatusModal.ShowOnlyStoresWithTheItemInStock","ShowOnlyStoresWithTheItemInStock");

				ShowStockStatusInStores = new Label("Product.StockStatusModal.ShowStockStatusInStores","ShowStockStatusInStores");

				StockStatusInStore = new Label("Product.StockStatusModal.StockStatusInStore","StockStatusInStore");

				StoresHaveTheItemInStock = new Label("Product.StockStatusModal.StoresHaveTheItemInStock","StoresHaveTheItemInStock");
			}

			SupplierProductId = new Label("Product.SupplierProductId","SupplierProductId");

			T3Message = new Label("Product.T3Message","T3Message");

			TemporarilyPhasedOut = new Label("Product.TemporarilyPhasedOut","TemporarilyPhasedOut");

			VariantOptionGroup = new class
			{

Label = new Label("Product.VariantOptionGroup","VariantOptionGroup");

				Matching = new Label("Product.VariantOptionGroup.Matching","Matching");

				Replacement = new Label("Product.VariantOptionGroup.Replacement","Replacement");
			}

			VariantsNotAvailable = new Label("Product.VariantsNotAvailable","VariantsNotAvailable");

			VendorUnableToDeliver = new Label("Product.VendorUnableToDeliver","VendorUnableToDeliver");

			VvsId = new Label("Product.VvsId","VvsId");
		}

		TrackAndTrace = new class
		{
			Label = new Label("TrackAndTrace","TrackAndTrace");

			Status = new class
			{

Label = new Label("TrackAndTrace.Status","Status");

				Available_for_delivery = new Label("TrackAndTrace.Status.Available_for_delivery","Available_for_delivery");

				Available_for_delivery_par_loc = new Label("TrackAndTrace.Status.Available_for_delivery_par_loc","Available_for_delivery_par_loc");

				Created = new Label("TrackAndTrace.Status.Created","Created");

				Delayed = new Label("TrackAndTrace.Status.Delayed","Delayed");

				Delivered = new Label("TrackAndTrace.Status.Delivered","Delivered");

				Delivery_impossible = new Label("TrackAndTrace.Status.Delivery_impossible","Delivery_impossible");

				Delivery_refused = new Label("TrackAndTrace.Status.Delivery_refused","Delivery_refused");

				En_route = new Label("TrackAndTrace.Status.En_route","En_route");

				Error = new Label("TrackAndTrace.Status.Error","Error");

				Expected_delay = new Label("TrackAndTrace.Status.Expected_delay","Expected_delay");

				Information = new Label("TrackAndTrace.Status.Information","Information");

				Informed = new Label("TrackAndTrace.Status.Informed","Informed");

				InProgress = new Label("TrackAndTrace.Status.InProgress","InProgress");

				Other = new Label("TrackAndTrace.Status.Other","Other");

				Ready = new Label("TrackAndTrace.Status.Ready","Ready");

				Returned = new Label("TrackAndTrace.Status.Returned","Returned");

				Split = new Label("TrackAndTrace.Status.Split","Split");

				Stopped = new Label("TrackAndTrace.Status.Stopped","Stopped");

				Unknown = new Label("TrackAndTrace.Status.Unknown","Unknown");
			}
		}

		Unit = new class
		{

Label = new Label("Unit","Unit");

			BAG = new Label("Unit.BAG","BAG");

			BDT = new Label("Unit.BDT","BDT");

			BE = new Label("Unit.BE","BE");

			BG = new Label("Unit.BG","BG");

			BO = new Label("Unit.BO","BO");

			BOT = new Label("Unit.BOT","BOT");

			BT = new Label("Unit.BT","BT");

			CA = new Label("Unit.CA","CA");

			CAN = new Label("Unit.CAN","CAN");

			CAR = new Label("Unit.CAR","CAR");

			CM = new Label("Unit.CM","CM");

			CT = new Label("Unit.CT","CT");

			DisplayName = new Label("Unit.DisplayName","DisplayName");

			EA = new Label("Unit.EA","EA");

			JG = new Label("Unit.JG","JG");

			KAN = new Label("Unit.KAN","KAN");

			KANDE = new Label("Unit.KANDE","KANDE");

			KAR = new Label("Unit.KAR","KAR");

			KG = new Label("Unit.KG","KG");

			KGM = new Label("Unit.KGM","KGM");

			L = new Label("Unit.L","L");

			LGD = new Label("Unit.LGD","LGD");

			LN = new Label("Unit.LN","LN");

			LTR = new Label("Unit.LTR","LTR");

			M = new Label("Unit.M","M");

			M2 = new Label("Unit.M2","M2");

			MM = new Label("Unit.MM","MM");

			MMT = new Label("Unit.MMT","MMT");

			MTK = new Label("Unit.MTK","MTK");

			MTR = new Label("Unit.MTR","MTR");

			PA = new Label("Unit.PA","PA");

			PAC = new Label("Unit.PAC","PAC");

			PAK = new Label("Unit.PAK","PAK");

			PAKKE = new Label("Unit.PAKKE","PAKKE");

			PAL = new Label("Unit.PAL","PAL");

			PAR = new Label("Unit.PAR","PAR");

			PCE = new Label("Unit.PCE","PCE");

			PK = new Label("Unit.PK","PK");

			PL = new Label("Unit.PL","PL");

			PR = new Label("Unit.PR","PR");

			PAA = new Label("Unit.PAA","PAA");

			RL = new Label("Unit.RL","RL");

			RO = new Label("Unit.RO","RO");

			ROL = new Label("Unit.ROL","ROL");

			SET = new Label("Unit.SET","SET");

			ST = new Label("Unit.ST","ST");

			STK = new Label("Unit.STK","STK");

			TU = new Label("Unit.TU","TU");

			UnitOf = new Label("Unit.UnitOf","UnitOf");
		}

		User = new class
		{

Label = new Label("User","User");

			Active = new Label("User.Active","Active");

			Roles = new class
			{

Label = new Label("User.Roles","Roles");

				Admin = new Label("User.Roles.Admin","Admin");

				AppNewsOffer = new Label("User.Roles.AppNewsOffer","AppNewsOffer");

				Caddie = new Label("User.Roles.Caddie","Caddie");

				Developer = new Label("User.Roles.Developer","Developer");

				GrossPrices = new Label("User.Roles.GrossPrices","GrossPrices");

				HelpDesk = new Label("User.Roles.HelpDesk","HelpDesk");

				KInfo = new Label("User.Roles.KInfo","KInfo");

				NetPrices = new Label("user.Roles.NetPrices","NetPrices");

				NewsLetter = new Label("User.Roles.NewsLetter","NewsLetter");

				PunchOut = new Label("User.Roles.PunchOut","PunchOut");

				Purchase = new Label("User.Roles.Purchase","Purchase");

				User = new Label("User.Roles.User","User");
			}

			Status = new class
			{

Label = new Label("User.Status","Status");

				Active = new Label("User.Status.Active","Active");

				Deactive = new Label("User.Status.Deactive","Deactive");
			}

			UserType = new class
			{

Label = new Label("User.UserType","UserType");

				Administrator = new Label("User.UserType.Administrator","Administrator");

				Newsletter = new Label("User.UserType.Newsletter","Newsletter");

				User = new Label("User.UserType.User","User");
			}
		}

		Validation = new class
		{

Label = new Label("Validation","Validation");

			AccountAllreadyAssociatedWithUser = new Label("Validation.AccountAllreadyAssociatedWithUser","AccountAllreadyAssociatedWithUser");

			AccountAllreadyExists = new Label("Validation.AccountAllreadyExists","AccountAllreadyExists");

			AccountNotFound = new Label("Validation.AccountNotFound","AccountNotFound");

			AccountNotFoundCannotUpdate = new Label("Validation.AccountNotFoundCannotUpdate","AccountNotFoundCannotUpdate");

			AddToBasketFailed = new Label("Validation.AddToBasketFailed","AddToBasketFailed");

			Basket = new class
			{

Label = new Label("Validation.Basket","Basket");

				BasketIsAlreadyBeingProcessed = new Label("Validation.Basket.BasketIsAlreadyBeingProcessed","BasketIsAlreadyBeingProcessed");

				CannotChangeFlexBoxDeliveryAddress = new Label("Validation.Basket.CannotChangeFlexBoxDeliveryAddress","CannotChangeFlexBoxDeliveryAddress");

				CertificateNotFound = new Label("Validation.Basket.CertificateNotFound","CertificateNotFound");

				CustomerReferenceTooLong = new Label("Validation.Basket.CustomerReferenceTooLong","CustomerReferenceTooLong");

				DeliveryDateNotValid = new Label("Validation.Basket.DeliveryDateNotValid","DeliveryDateNotValid");

				DeliveryMethodNotValid = new Label("Validation.Basket.DeliveryMethodNotValid","DeliveryMethodNotValid");

				ExportToSapFailed = new Label("Validation.Basket.ExportToSapFailed","ExportToSapFailed");

				FixedQuotationLineQuantityInvalidMessage = new Label("Validation.Basket.FixedQuotationLineQuantityInvalidMessage","FixedQuotationLineQuantityInvalidMessage");

				FixedQuotationSingleLines = new Label("Validation.Basket.FixedQuotationSingleLines","FixedQuotationSingleLines");

				FlexboxMaxQuantityExceeded = new Label("Validation.Basket.FlexboxMaxQuantityExceeded","FlexboxMaxQuantityExceeded");

				InvalidShippingAddressId = new Label("Validation.Basket.InvalidShippingAddressId","InvalidShippingAddressId");

				InvalidShippingMethodForAddress = new Label("Validation.Basket.InvalidShippingMethodForAddress","InvalidShippingMethodForAddress");

				MaxQuantityError = new Label("Validation.Basket.MaxQuantityError","MaxQuantityError");

				MaxQuantityErrorFormatString = new Label("Validation.Basket.MaxQuantityErrorFormatString","MaxQuantityErrorFormatString");

				MinQuantityError = new Label("Validation.Basket.MinQuantityError","MinQuantityError");

				MissingShippingAddress = new Label("Validation.Basket.MissingShippingAddress","MissingShippingAddress");

				MissingShippingAddressForPhone = new Label("Validation.Basket.MissingShippingAddressForPhone","MissingShippingAddressForPhone");

				MissingShippingMethod = new Label("Validation.Basket.MissingShippingMethod","MissingShippingMethod");

				MissingShippingMethodForAddress = new Label("Validation.Basket.MissingShippingMethodForAddress","MissingShippingMethodForAddress");

				NoPricesAvailable = new Label("Validation.Basket.NoPricesAvailable","NoPricesAvailable");

				NoProductFound = new Label("Validation.Basket.NoProductFound","NoProductFound");

				NotFoundForCurrentSalesAccount = new Label("Validation.Basket.NotFoundForCurrentSalesAccount","NotFoundForCurrentSalesAccount");

				PriceNotFound = new Label("Validation.Basket.PriceNotFound","PriceNotFound");

				QuantityIncrementError = new Label("Validation.Basket.QuantityIncrementError","QuantityIncrementError");

				QuotationAlternativeLineDuplicateMessage = new Label("Validation.Basket.QuotationAlternativeLineDuplicateMessage","QuotationAlternativeLineDuplicateMessage");

				QuotationItemMissingMessage = new Label("Validation.Basket.QuotationItemMissingMessage","QuotationItemMissingMessage");

				QuotationLineCertificateMismatchMessage = new Label("Validation.Basket.QuotationLineCertificateMismatchMessage","QuotationLineCertificateMismatchMessage");

				QuotationLineInvalidPriceMessage = new Label("Validation.Basket.QuotationLineInvalidPriceMessage","QuotationLineInvalidPriceMessage");

				QuotationLineInvalidStampingMessage = new Label("Validation.Basket.QuotationLineInvalidStampingMessage","QuotationLineInvalidStampingMessage");

				QuotationLineMissingMessage = new Label("Validation.Basket.QuotationLineMissingMessage","QuotationLineMissingMessage");

				QuotationLineMissingStampingMessage = new Label("Validation.Basket.QuotationLineMissingStampingMessage","QuotationLineMissingStampingMessage");

				QuotationLineNotAllowedMessage = new Label("Validation.Basket.QuotationLineNotAllowedMessage","QuotationLineNotAllowedMessage");

				QuotationLineQuantityExceededMessage = new Label("Validation.Basket.QuotationLineQuantityExceededMessage","QuotationLineQuantityExceededMessage");

				QuotationLineSoldOut = new Label("Validation.Basket.QuotationLineSoldOut","QuotationLineSoldOut");

				QuotationLineUnitMismatchMessage = new Label("Validation.Basket.QuotationLineUnitMismatchMessage","QuotationLineUnitMismatchMessage");

				QuotationMissingMessage = new Label("Validation.Basket.QuotationMissingMessage","QuotationMissingMessage");

				QuotationNotFound = new Label("Validation.Basket.QuotationNotFound","QuotationNotFound");

				RemoveSoldOutProductFromBasket = new Label("Validation.Basket.RemoveSoldOutProductFromBasket","RemoveSoldOutProductFromBasket");

				SalesUnitNotFound = new Label("Validation.Basket.SalesUnitNotFound","SalesUnitNotFound");

				SoldOutProductInBasketMessage = new Label("Validation.Basket.SoldOutProductInBasketMessage","SoldOutProductInBasketMessage");

				SpecialProductInvalidUnit = new Label("Validation.Basket.SpecialProductInvalidUnit","SpecialProductInvalidUnit");

				SurplusProductQuantityExceeded = new Label("Validation.Basket.SurplusProductQuantityExceeded","SurplusProductQuantityExceeded");

				ZeroQuantityAvailable = new Label("Validation.Basket.ZeroQuantityAvailable","ZeroQuantityAvailable");
			}

			Browser = new class
			{

Label = new Label("Validation.Browser","Browser");

				IEMessage = new Label("Validation.Browser.IEMessage","IEMessage");

				ModalHeader = new Label("Validation.Browser.ModalHeader","ModalHeader");
			}

			ChangePassword = new class
			{

Label = new Label("Validation.ChangePassword","ChangePassword");

				ConfirmPassword = new Label("Validation.ChangePassword.ConfirmPassword","ConfirmPassword");

				PasswordPolicy = new class
				{

Label = new Label("Validation.ChangePassword.PasswordPolicy","PasswordPolicy");

					Complexity = new Label("Validation.ChangePassword.PasswordPolicy.Complexity","Complexity");

					UsernameNotAllowed = new Label("Validation.ChangePassword.PasswordPolicy.UsernameNotAllowed","UsernameNotAllowed");
				}

				Required = new Label("Validation.ChangePassword.Required","Required");

				StringLength = new Label("Validation.ChangePassword.StringLength","StringLength");

				UserNotFound = new Label("Validation.ChangePassword.UserNotFound","UserNotFound");
			}

			Checkout = new class
			{

Label = new Label("Validation.Checkout","Checkout");

				Comment = new class
				{

Label = new Label("Validation.Checkout.Comment","Comment");

					CaseNumberTooLong = new Label("Validation.Checkout.Comment.CaseNumberTooLong","CaseNumberTooLong");

					ReferenceTooLong = new Label("Validation.Checkout.Comment.ReferenceTooLong","ReferenceTooLong");

					RequisitionNumberTooLong = new Label("Validation.Checkout.Comment.RequisitionNumberTooLong","RequisitionNumberTooLong");
				}

				CustomerBlocked = new Label("Validation.Checkout.CustomerBlocked","CustomerBlocked");

				MissingAddress = new Label("Validation.Checkout.MissingAddress","MissingAddress");

				ProductQuantityValidation = new Label("Validation.Checkout.ProductQuantityValidation","ProductQuantityValidation");
			}

			CommaNotAllowed = new Label("Validation.CommaNotAllowed","CommaNotAllowed");

			CreateAccount = new class
			{

Label = new Label("Validation.CreateAccount","CreateAccount");

				AccountAlreadyCreating = new Label("Validation.CreateAccount.AccountAlreadyCreating","AccountAlreadyCreating");

				CVRInvalid = new Label("Validation.CreateAccount.CVRInvalid","CVRInvalid");

				EmailFirmInvalid = new Label("Validation.CreateAccount.EmailFirmInvalid","EmailFirmInvalid");

				EmailPersonalInvalid = new Label("Validation.CreateAccount.EmailPersonalInvalid","EmailPersonalInvalid");

				Error001 = new Label("Validation.CreateAccount.Error001","Error001");

				Error002 = new Label("Validation.CreateAccount.Error002","Error002");

				Error003 = new Label("Validation.CreateAccount.Error003","Error003");

				Error005 = new Label("Validation.CreateAccount.Error005","Error005");

				Error006 = new Label("Validation.CreateAccount.Error006","Error006");

				Error007 = new Label("Validation.CreateAccount.Error007","Error007");

				Error008 = new Label("Validation.CreateAccount.Error008","Error008");

				Error009 = new Label("Validation.CreateAccount.Error009","Error009");

				Error010 = new Label("Validation.CreateAccount.Error010","Error010");

				Error011 = new Label("Validation.CreateAccount.Error011","Error011");

				Error012 = new Label("Validation.CreateAccount.Error012","Error012");

				Error013 = new Label("Validation.CreateAccount.Error013","Error013");

				Error014 = new Label("Validation.CreateAccount.Error014","Error014");

				Error015 = new Label("Validation.CreateAccount.Error015","Error015");

				Error016 = new Label("Validation.CreateAccount.Error016","Error016");

				ExceptionMessage = new Label("Validation.CreateAccount.ExceptionMessage","ExceptionMessage");

				FirstNameSpecialCharacters = new Label("Validation.CreateAccount.FirstNameSpecialCharacters","FirstNameSpecialCharacters");

				FirstNameTooShort = new Label("Validation.CreateAccount.FirstNameTooShort","FirstNameTooShort");

				LastNameSpecialCharacters = new Label("Validation.CreateAccount.LastNameSpecialCharacters","LastNameSpecialCharacters");

				LastNameTooShort = new Label("Validation.CreateAccount.LastNameTooShort","LastNameTooShort");

				PhoneFirmInvalid = new Label("Validation.CreateAccount.PhoneFirmInvalid","PhoneFirmInvalid");

				PhonePersonalInvalid = new Label("Validation.CreateAccount.PhonePersonalInvalid","PhonePersonalInvalid");

				UserNameNotUnique = new Label("Validation.CreateAccount.UserNameNotUnique","UserNameNotUnique");

				UserNameTooShort = new Label("Validation.CreateAccount.UserNameTooShort","UserNameTooShort");
			}

			CustomerProductIdAlreadyExists = new Label("Validation.CustomerProductIdAlreadyExists","CustomerProductIdAlreadyExists");

			CustomerProductIdTooLong = new Label("Validation.CustomerProductIdTooLong","CustomerProductIdTooLong");

			EasySupply = new class
			{

Label = new Label("Validation.EasySupply","EasySupply");

				GatewayAllreadyExists = new Label("Validation.EasySupply.GatewayAllreadyExists","GatewayAllreadyExists");

				GatewayAlreadyExists = new Label("Validation.EasySupply.GatewayAlreadyExists","GatewayAlreadyExists");

				GatewayAlreadyExistsOnAnotherWarehouse = new Label("Validation.EasySupply.GatewayAlreadyExistsOnAnotherWarehouse","GatewayAlreadyExistsOnAnotherWarehouse");

				GatewayByWarehouseNotFound = new Label("Validation.EasySupply.GatewayByWarehouseNotFound","GatewayByWarehouseNotFound");

				GatewayIdentifiersMustBeDifferent = new Label("Validation.EasySupply.GatewayIdentifiersMustBeDifferent","GatewayIdentifiersMustBeDifferent");

				GatewayNotFound = new Label("Validation.EasySupply.GatewayNotFound","GatewayNotFound");

				IncorrectCMacValue = new Label("Validation.EasySupply.IncorrectCMacValue","IncorrectCMacValue");

				IncorrectHardwareIdentifierPattern = new Label("Validation.EasySupply.IncorrectHardwareIdentifierPattern","IncorrectHardwareIdentifierPattern");

				IncorrectIdentifierLength = new Label("Validation.EasySupply.IncorrectIdentifierLength","IncorrectIdentifierLength");

				InvalidWeekday = new Label("Validation.EasySupply.InvalidWeekday","InvalidWeekday");

				MustBeAdmin = new Label("Validation.EasySupply.MustBeAdmin","MustBeAdmin");

				NumberOfSmartButtonsExceeded = new Label("Validation.EasySupply.NumberOfSmartButtonsExceeded","NumberOfSmartButtonsExceeded");

				NumberOfSmartButtonsExceededOnImport = new Label("Validation.EasySupply.NumberOfSmartButtonsExceededOnImport","NumberOfSmartButtonsExceededOnImport");

				ReorderThresholdWithoutAdmin = new Label("Validation.EasySupply.ReorderThresholdWithoutAdmin","ReorderThresholdWithoutAdmin");

				SelectOneOrMoreDays = new Label("Validation.EasySupply.SelectOneOrMoreDays","SelectOneOrMoreDays");

				SmartButtonAllreadyExists = new Label("Validation.EasySupply.SmartButtonAllreadyExists","SmartButtonAllreadyExists");

				SmartButtonAlreadyExists = new Label("Validation.EasySupply.SmartButtonAlreadyExists","SmartButtonAlreadyExists");

				SmartButtonAlreadyOnWarehouseLine = new Label("Validation.EasySupply.SmartButtonAlreadyOnWarehouseLine","SmartButtonAlreadyOnWarehouseLine");

				SmartButtonNotFound = new Label("Validation.EasySupply.SmartButtonNotFound","SmartButtonNotFound");

				SmartButtonNotFoundOnWarehouse = new Label("Validation.EasySupply.SmartButtonNotFoundOnWarehouse","SmartButtonNotFoundOnWarehouse");

				SmartButtonSessionIdMatch = new Label("Validation.EasySupply.SmartButtonSessionIdMatch","SmartButtonSessionIdMatch");

				WrongHardwareIdentifier = new Label("Validation.EasySupply.WrongHardwareIdentifier","WrongHardwareIdentifier");
			}

			Email = new Label("Validation.Email","Email");

			FavoriteAccountNotFound = new Label("Validation.FavoriteAccountNotFound","FavoriteAccountNotFound");

			FavoriteOrder = new class
			{

Label = new Label("Validation.FavoriteOrder","FavoriteOrder");

				BadData = new Label("Validation.FavoriteOrder.BadData","BadData");

				ErrorReadingCsv = new Label("Validation.FavoriteOrder.ErrorReadingCsv","ErrorReadingCsv");

				ErrorReadingCsvLine = new Label("Validation.FavoriteOrder.ErrorReadingCsvLine","ErrorReadingCsvLine");

				FixedUnitNotAllowed = new Label("Validation.FavoriteOrder.FixedUnitNotAllowed","FixedUnitNotAllowed");

				ImportProductNotFound = new Label("Validation.FavoriteOrder.ImportProductNotFound","ImportProductNotFound");

				InvalidColumnsReadingCsvLine = new Label("Validation.FavoriteOrder.InvalidColumnsReadingCsvLine","InvalidColumnsReadingCsvLine");

				InvalidDecimalConversionPoint = new Label("Validation.FavoriteOrder.InvalidDecimalConversionPoint","InvalidDecimalConversionPoint");

				NameRequired = new Label("Validation.FavoriteOrder.NameRequired","NameRequired");

				NotAllowed = new Label("Validation.FavoriteOrder.NotAllowed","NotAllowed");

				NotFound = new Label("Validation.FavoriteOrder.NotFound","NotFound");

				SalesAccountNotValid = new Label("Validation.FavoriteOrder.SalesAccountNotValid","SalesAccountNotValid");
			}

			FixedUnit = new class
			{

Label = new Label("Validation.FixedUnit","FixedUnit");

				IncrementFixedUnitQuantityFormatString = new Label("Validation.FixedUnit.IncrementFixedUnitQuantityFormatString","IncrementFixedUnitQuantityFormatString");

				MaxFixedUnitQuantity = new Label("Validation.FixedUnit.MaxFixedUnitQuantity","MaxFixedUnitQuantity");

				MinFixedUnitQuantity = new Label("Validation.FixedUnit.MinFixedUnitQuantity","MinFixedUnitQuantity");
			}

			Flexbox = new class
			{

Label = new Label("Validation.Flexbox","Flexbox");

				NotPossibleToOrder = new Label("Validation.Flexbox.NotPossibleToOrder","NotPossibleToOrder");

				StoreClosedForToday = new Label("Validation.Flexbox.StoreClosedForToday","StoreClosedForToday");

				StoreNotOpenedForToday = new Label("Validation.Flexbox.StoreNotOpenedForToday","StoreNotOpenedForToday");

				StoreNotOpenToday = new Label("Validation.Flexbox.StoreNotOpenToday","StoreNotOpenToday");
			}

			HelpDesk = new class
			{

Label = new Label("Validation.HelpDesk","HelpDesk");

				AccountNotFound = new Label("Validation.HelpDesk.AccountNotFound","AccountNotFound");

				AssignSameUser = new Label("Validation.HelpDesk.AssignSameUser","AssignSameUser");

				CallGroupNotFound = new Label("Validation.HelpDesk.CallGroupNotFound","CallGroupNotFound");

				CsvHeaderError = new Label("Validation.HelpDesk.CsvHeaderError","CsvHeaderError");

				InitialsNotFound = new Label("Validation.HelpDesk.InitialsNotFound","InitialsNotFound");

				OnlyOneColumn = new Label("Validation.HelpDesk.OnlyOneColumn","OnlyOneColumn");
			}

			Mobile = new class
			{

Label = new Label("Validation.Mobile","Mobile");

				DownloadApp = new Label("Validation.Mobile.DownloadApp","DownloadApp");

				ModalHeader = new Label("Validation.Mobile.ModalHeader","ModalHeader");
			}

			MoreThanOneProductFound = new Label("Validation.MoreThanOneProductFound","MoreThanOneProductFound");

			PasswordTooShort = new Label("Validation.PasswordTooShort","PasswordTooShort");

			PriceNotFound = new Label("Validation.PriceNotFound","PriceNotFound");

			ProductIsBlocked = new Label("Validation.ProductIsBlocked","ProductIsBlocked");

			ProductNotFound = new Label("Validation.ProductNotFound","ProductNotFound");

			QuantityIncrement = new Label("Validation.QuantityIncrement","QuantityIncrement");

			QuantityTooBig1 = new Label("Validation.QuantityTooBig1","QuantityTooBig1");

			QuantityTooBig2 = new Label("Validation.QuantityTooBig2","QuantityTooBig2");

			QuantityTooLarge = new Label("Validation.QuantityTooLarge","QuantityTooLarge");

			QuantityTooLow = new Label("Validation.QuantityTooLow","QuantityTooLow");

			QuantityTooLow1 = new Label("Validation.QuantityTooLow1","QuantityTooLow1");

			QuantityTooLow2 = new Label("Validation.QuantityTooLow2","QuantityTooLow2");

			Regex = new class
			{

Label = new Label("Validation.Regex","Regex");

				CaseNumber = new Label("Validation.Regex.CaseNumber","CaseNumber");

				Cvr = new Label("Validation.Regex.Cvr","Cvr");

				Decimal = new Label("Validation.Regex.Decimal","Decimal");

				Email = new Label("Validation.Regex.Email","Email");

				Emails = new Label("Validation.Regex.Emails","Emails");

				OfferNumber = new Label("Validation.Regex.OfferNumber","OfferNumber");

				Password = new Label("Validation.Regex.Password","Password");

				Reference = new Label("Validation.Regex.Reference","Reference");

				RequisitionNumber = new Label("Validation.Regex.RequisitionNumber","RequisitionNumber");

				SpecialOrderNumber = new Label("Validation.Regex.SpecialOrderNumber","SpecialOrderNumber");

				WarehouseIdentification = new Label("Validation.Regex.WarehouseIdentification","WarehouseIdentification");
			}

			Required = new Label("Validation.Required","Required");

			RequiredFormatted = new Label("Validation.RequiredFormatted","RequiredFormatted");

			ReturnQuantity = new Label("Validation.ReturnQuantity","ReturnQuantity");

			Services = new class
			{
				Label = new Label("Validation.Services","Services");

				EasySupply = new class
				{

Label = new Label("Validation.Services.EasySupply","EasySupply");

					CantParseUnitCodeProcuredProduct = new Label("Validation.Services.EasySupply.CantParseUnitCodeProcuredProduct","CantParseUnitCodeProcuredProduct");

					NameMaximum = new Label("Validation.Services.EasySupply.NameMaximum","NameMaximum");

					ProcuredNameRequired = new Label("Validation.Services.EasySupply.ProcuredNameRequired","ProcuredNameRequired");

					ProcuredProductNotFound = new Label("Validation.Services.EasySupply.ProcuredProductNotFound","ProcuredProductNotFound");

					ProcuredProductNumberRequired = new Label("Validation.Services.EasySupply.ProcuredProductNumberRequired","ProcuredProductNumberRequired");

					ProcuredQuantityRequired = new Label("Validation.Services.EasySupply.ProcuredQuantityRequired","ProcuredQuantityRequired");

					ProcuredQuantityShouldBeWholeNumber = new Label("Validation.Services.EasySupply.ProcuredQuantityShouldBeWholeNumber","ProcuredQuantityShouldBeWholeNumber");

					ProcuredSupplierNumberRequired = new Label("Validation.Services.EasySupply.ProcuredSupplierNumberRequired","ProcuredSupplierNumberRequired");

					ProcuredUnitCodeRequired = new Label("Validation.Services.EasySupply.ProcuredUnitCodeRequired","ProcuredUnitCodeRequired");

					ProductNumberRequired = new Label("Validation.Services.EasySupply.ProductNumberRequired","ProductNumberRequired");

					QuantityRequired = new Label("Validation.Services.EasySupply.QuantityRequired","QuantityRequired");

					QuantityShouldBeWholeNumber = new Label("Validation.Services.EasySupply.QuantityShouldBeWholeNumber","QuantityShouldBeWholeNumber");

					ReorderThresholdShouldBeWholeNumber = new Label("Validation.Services.EasySupply.ReorderThresholdShouldBeWholeNumber","ReorderThresholdShouldBeWholeNumber");

					UnitCodeCantConvert = new Label("Validation.Services.EasySupply.UnitCodeCantConvert","UnitCodeCantConvert");

					WarehouseNotFound = new Label("Validation.Services.EasySupply.WarehouseNotFound","WarehouseNotFound");
				}

				Integration = new class
				{
					Label = new Label("Validation.Services.Integration","Integration");

					DataPolicy = new class
					{

Label = new Label("Validation.Services.Integration.DataPolicy","DataPolicy");

						AcceptRequired = new Label("Validation.Services.Integration.DataPolicy.AcceptRequired","AcceptRequired");
					}
				}
			}

			SpecialLineItemCharLimitExceeded = new Label("Validation.SpecialLineItemCharLimitExceeded","SpecialLineItemCharLimitExceeded");

			StoreAccess = new class
			{

Label = new Label("Validation.StoreAccess","StoreAccess");

				FailedClosingStore = new Label("FailedClosingStore","FailedClosingStore");

				FailedOpeningStore = new Label("FailedOpeningStore","FailedOpeningStore");
			}

			Support = new class
			{

Label = new Label("Validation.Support","Support");

				NoUsersForAccount = new Label("Validation.Support.NoUsersForAccount","NoUsersForAccount");

				UsersAlreadyImpersonated = new Label("Validation.Support.UsersAlreadyImpersonated","UsersAlreadyImpersonated");
			}

			TooBig = new Label("Validation.TooBig","TooBig");

			TooLong = new Label("Validation.TooLong","TooLong");

			TooLongFormatted = new Label("Validation.TooLongFormatted","TooLongFormatted");

			TooShort = new Label("Validation.TooShort","TooShort");

			TooSmall = new Label("Validation.TooSmall","TooSmall");

			TryingToDeleteYourSelf = new Label("Validation.TryingToDeleteYourSelf","TryingToDeleteYourSelf");

			UndefinedLabel = new Label("Validation.UndefinedLabel","UndefinedLabel");

			Unit = new class
			{

Label = new Label("Validation.Unit","Unit");

				NotFound = new Label("Validation.Unit.NotFound","NotFound");
			}

			User = new class
			{

Label = new Label("Validation.User","User");

				EmailNotFormedCorrect = new Label("EmailNotFormedCorrect","EmailNotFormedCorrect");

				AlreadyConfirmed = new class
				{

Label = new Label("Validation.User.AlreadyConfirmed","AlreadyConfirmed");

					Validation_User_EmailNotFormedCorrect = new Label("Validation.User.EmailNotFormedCorrect","Validation_User_EmailNotFormedCorrect");
				}

				CannotRemoveFromLastAccount = new Label("Validation.User.CannotRemoveFromLastAccount","CannotRemoveFromLastAccount");

				ContradictingPurchaseRoles = new Label("Validation.User.ContradictingPurchaseRoles","ContradictingPurchaseRoles");

				InvalidConfirmationKey = new Label("Validation.User.InvalidConfirmationKey","InvalidConfirmationKey");

				InvalidIdentifier = new Label("Validation.User.InvalidIdentifier","InvalidIdentifier");

				InvalidPrimaryAccount = new Label("Validation.User.InvalidPrimaryAccount","InvalidPrimaryAccount");

				NameTooShort = new Label("Validation.User.NameTooShort","NameTooShort");

				NotAllowedToUpdateUser = new Label("Validation.User.NotAllowedToUpdateUser","NotAllowedToUpdateUser");

				NotFound = new Label("Validation.User.NotFound","NotFound");

				NotInRoleForThisOperation = new Label("Validation.User.NotInRoleForThisOperation","NotInRoleForThisOperation");

				PhoneNumberRequired = new Label("Validation.User.PhoneNumberRequired","PhoneNumberRequired");
			}

			UserName = new class
			{

Label = new Label("Validation.UserName","UserName");

				NotPossibleToChangeUsername = new Label("Validation.UserName.NotPossibleToChangeUsername","NotPossibleToChangeUsername");

				SpecialCharacters = new Label("Validation.UserName.SpecialCharacters","SpecialCharacters");

				TooLong = new Label("Validation.UserName.TooLong","TooLong");

				TooShort = new Label("Validation.UserName.TooShort","TooShort");
			}

			VariantRequired = new Label("Validation.VariantRequired","VariantRequired");
		}

		Views = new class
		{

Label = new Label("Views","Views");

			Accordion = new class
			{

Label = new Label("Views.Accordion","Accordion");

				ToggleClose = new Label("Views.Accordion.ToggleClose","ToggleClose");

				ToggleOpen = new Label("Views.Accordion.ToggleOpen","ToggleOpen");
			}

			AccountAdministration = new class
			{

Label = new Label("Views.AccountAdministration","AccountAdministration");

				Account = new Label("Views.AccountAdministration.Account","Account");

				AccountText = new Label("Views.AccountAdministration.AccountText","AccountText");

				AllowNewUsers = new Label("Views.AccountAdministration.AllowNewUsers","AllowNewUsers");

				Save = new Label("Views.AccountAdministration.Save","Save");

				StockMaster = new Label("Views.AccountAdministration.StockMaster","StockMaster");

				SuccessMessage = new Label("Views.AccountAdministration.SuccessMessage","SuccessMessage");
			}

			AccountGroupAdministration = new class
			{

Label = new Label("Views.AccountGroupAdministration","AccountGroupAdministration");

				Amount = new Label("Views.AccountGroupAdministration.Amount","Amount");

				CreateGroup = new Label("Views.AccountGroupAdministration.CreateGroup","CreateGroup");

				CreateOverlay = new class
				{

Label = new Label("Views.AccountGroupAdministration.CreateOverlay","CreateOverlay");

					Account = new Label("Views.AccountGroupAdministration.CreateOverlay.Account","Account");

					Accounts = new Label("Views.AccountGroupAdministration.CreateOverlay.Accounts","Accounts");

					GroupNameLabel = new Label("Views.AccountGroupAdministration.CreateOverlay.GroupNameLabel","GroupNameLabel");

					GroupNamePlaceholder = new Label("Views.AccountGroupAdministration.CreateOverlay.GroupNamePlaceholder","GroupNamePlaceholder");

					NoAccounts = new Label("Views.AccountGroupAdministration.CreateOverlay.NoAccounts","NoAccounts");

					SearchLabel = new Label("Views.AccountGroupAdministration.CreateOverlay.SearchLabel","SearchLabel");

					SearchPlaceholder = new Label("Views.AccountGroupAdministration.CreateOverlay.SearchPlaceholder","SearchPlaceholder");

					SubmitButtonText = new Label("Views.AccountGroupAdministration.CreateOverlay.SubmitButtonText","SubmitButtonText");

					Title = new Label("Views.AccountGroupAdministration.CreateOverlay.Title","Title");
				}

				DeleteOverlay = new class
				{

Label = new Label("Views.AccountGroupAdministration.DeleteOverlay","DeleteOverlay");

					Cancel = new Label("Views.AccountGroupAdministration.DeleteOverlay.Cancel","Cancel");

					Delete = new Label("Views.AccountGroupAdministration.DeleteOverlay.Delete","Delete");

					Title = new Label("Views.AccountGroupAdministration.DeleteOverlay.Title","Title");
				}

				Edit = new Label("Views.AccountGroupAdministration.Edit","Edit");

				EditOverlay = new class
				{

Label = new Label("Views.AccountGroupAdministration.EditOverlay","EditOverlay");

					SubmitButtonText = new Label("Views.AccountGroupAdministration.EditOverlay.SubmitButtonText","SubmitButtonText");

					Title = new Label("Views.AccountGroupAdministration.EditOverlay.Title","Title");
				}

				Group = new Label("Views.AccountGroupAdministration.Group","Group");
			}

			AddToBasket = new class
			{

Label = new Label("Views.AddToBasket","AddToBasket");

				Ok = new Label("Views.AddToBasket.Ok","Ok");
			}

			Agreement = new class
			{

Label = new Label("Views.Agreement","Agreement");

				Accept = new Label("Views.Agreement.Accept","Accept");

				Cancel = new Label("Views.Agreement.Cancel","Cancel");

				Postpone = new Label("Views.Agreement.Postpone","Postpone");

				Subscription = new class
				{

Label = new Label("Views.Agreement.Subscription","Subscription");

					OptIn = new Label("Views.Agreement.Subscription.OptIn","OptIn");

					OptOut = new Label("Views.Agreement.Subscription.OptOut","OptOut");
				}
			}

			Article = new class
			{

Label = new Label("Views.Article","Article");

				RelatedHeader = new Label("Views.Article.RelatedHeader","RelatedHeader");
			}

			Articles = new class
			{
				Label = new Label("Views.Articles","Articles");

				FrontpageTeaser = new class
				{

Label = new Label("Views.Articles.FrontpageTeaser","FrontpageTeaser");

					Heading = new Label("Views.Articles.FrontpageTeaser.Heading","Heading");

					SeeAllLink = new Label("Views.Articles.FrontpageTeaser.SeeAllLink","SeeAllLink");
				}

				SearchForm = new class
				{

Label = new Label("Views.Articles.SearchForm","SearchForm");

					ButtonText = new Label("Views.Articles.SearchForm.ButtonText","ButtonText");

					FromYear = new Label("Views.Articles.SearchForm.FromYear","FromYear");

					Manufacturer = new Label("Views.Articles.SearchForm.Manufacturer","Manufacturer");

					ProductCategory = new Label("Views.Articles.SearchForm.ProductCategory","ProductCategory");

					PublicationYear = new Label("Views.Articles.SearchForm.PublicationYear","PublicationYear");

					ResultCount = new class
					{

Label = new Label("Views.Articles.SearchForm.ResultCount","ResultCount");

						Result = new Label("Views.Articles.SearchForm.ResultCount.Result","Result");

						Zero = new Label("Views.Articles.SearchForm.ResultCount.Zero","Zero");
					}

					Term = new Label("Views.Articles.SearchForm.Term","Term");

					Topic = new Label("Views.Articles.SearchForm.Topic","Topic");

					ToYear = new Label("Views.Articles.SearchForm.ToYear","ToYear");

					Wait = new Label("Views.Articles.SearchForm.Wait","Wait");
				}

				Tile = new class
				{

Label = new Label("Views.Articles.Tile","Tile");

					Manufacturer = new Label("Views.Articles.Tile.Manufacturer","Manufacturer");

					ProductCategory = new Label("Views.Articles.Tile.ProductCategory","ProductCategory");

					PublicationDate = new Label("Views.Articles.Tile.PublicationDate","PublicationDate");

					Topic = new Label("Views.Articles.Tile.Topic","Topic");
				}
			}

			Basket = new class
			{

Label = new Label("Views.Basket","Basket");

				Account = new Label("Views.Basket.Account","Account");

				AccountNumber = new Label("Views.Basket.AccountNumber","AccountNumber");

				AddToShortcuts = new Label("Views.Basket.AddToShortcuts","AddToShortcuts");

				AllProducts = new Label("Views.Basket.AllProducts","AllProducts");

				CertificatesTotal = new Label("Views.Basket.CertificatesTotal","CertificatesTotal");

				ChangeBasketOverlay = new class
				{

Label = new Label("Views.Basket.ChangeBasketOverlay","ChangeBasketOverlay");

					DeleteBasketButton = new Label("Views.Basket.ChangeBasketOverlay.DeleteBasketButton","DeleteBasketButton");

					DeleteBasketOverlayCancel = new Label("Views.Basket.ChangeBasketOverlay.DeleteBasketOverlayCancel","DeleteBasketOverlayCancel");

					DeleteBasketOverlaySubmit = new Label("Views.Basket.ChangeBasketOverlay.DeleteBasketOverlaySubmit","DeleteBasketOverlaySubmit");

					DeleteBasketOverlayTitle = new Label("Views.Basket.ChangeBasketOverlay.DeleteBasketOverlayTitle","DeleteBasketOverlayTitle");

					EditBasketButton = new Label("Views.Basket.ChangeBasketOverlay.EditBasketButton","EditBasketButton");

					EditBasketSubmitButtonText = new Label("Views.Basket.ChangeBasketOverlay.EditBasketSubmitButtonText","EditBasketSubmitButtonText");

					EditBasketTitle = new Label("Views.Basket.ChangeBasketOverlay.EditBasketTitle","EditBasketTitle");

					NewBasketButton = new Label("Views.Basket.ChangeBasketOverlay.NewBasketButton","NewBasketButton");

					NewBasketDescription = new Label("Views.Basket.ChangeBasketOverlay.NewBasketDescription","NewBasketDescription");

					NewBasketNameLabel = new Label("Views.Basket.ChangeBasketOverlay.NewBasketNameLabel","NewBasketNameLabel");

					NewBasketSubmitButtonText = new Label("Views.Basket.ChangeBasketOverlay.NewBasketSubmitButtonText","NewBasketSubmitButtonText");

					NewBasketTitle = new Label("Views.Basket.ChangeBasketOverlay.NewBasketTitle","NewBasketTitle");

					OverlayTriggerText = new Label("Views.Basket.ChangeBasketOverlay.OverlayTriggerText","OverlayTriggerText");

					Products = new Label("Views.Basket.ChangeBasketOverlay.Products","Products");

					SubmitButtonText = new Label("Views.Basket.ChangeBasketOverlay.SubmitButtonText","SubmitButtonText");
				}

				ChargeTotal = new Label("Views.Basket.ChargeTotal","ChargeTotal");

				Co2FootprintEPDKgPerUnit = new Label("Views.Basket.Co2FootprintEPDKgPerUnit","Co2FootprintEPDKgPerUnit");

				Co2NumberOfLineItems = new Label("Views.Basket.Co2NumberOfLineItems","Co2NumberOfLineItems");

				Co2TotalForBasket = new Label("Views.Basket.Co2TotalForBasket","Co2TotalForBasket");

				Co2TotalForLineItem = new Label("Views.Basket.Co2TotalForLineItem","Co2TotalForLineItem");

				ConvertToFavoriteOrder = new Label("Views.Basket.ConvertToFavoriteOrder","ConvertToFavoriteOrder");

				DistributionSurcharge = new class
				{

Label = new Label("Views.Basket.DistributionSurcharge","DistributionSurcharge");

					Heading = new Label("Views.Basket.DistributionSurcharge.Heading","Heading");

					OtherProducts = new Label("Views.Basket.DistributionSurcharge.OtherProducts","OtherProducts");

					OtherProductsInfo = new Label("Views.Basket.DistributionSurcharge.OtherProductsInfo","OtherProductsInfo");

					SteelAndMetals = new Label("Views.Basket.DistributionSurcharge.SteelAndMetals","SteelAndMetals");

					SteelAndMetalsInfo = new Label("Views.Basket.DistributionSurcharge.SteelAndMetalsInfo","SteelAndMetalsInfo");
				}

				DocumentCategory = new class
				{

Label = new Label("Views.Basket.DocumentCategory","DocumentCategory");

					FrameOrder = new Label("Views.Basket.DocumentCategory.FrameOrder","FrameOrder");

					Project = new Label("Views.Basket.DocumentCategory.Project","Project");

					Quotation = new Label("Views.Basket.DocumentCategory.Quotation","Quotation");

					QuotationFixed = new Label("Views.Basket.DocumentCategory.QuotationFixed","QuotationFixed");

					ReturnOrder = new Label("Views.Basket.DocumentCategory.ReturnOrder","ReturnOrder");

					Unknown = new Label("Views.Basket.DocumentCategory.Unknown","Unknown");
				}

				GrossListTotal = new Label("Views.Basket.GrossListTotal","GrossListTotal");

				GrossSubtotal = new Label("Views.Basket.GrossSubtotal","GrossSubtotal");

				GrossTotal = new Label("Views.Basket.GrossTotal","GrossTotal");

				Header = new Label("Views.Basket.Header","Header");

				IncludingCharge = new Label("Views.Basket.IncludingCharge","IncludingCharge");

				KeepShopping = new Label("Views.Basket.KeepShopping","KeepShopping");

				LineChargesInfo = new Label("Views.Basket.LineChargesInfo","LineChargesInfo");

				MoveToAccount = new class
				{

Label = new Label("Views.Basket.MoveToAccount","MoveToAccount");

					ChooseAccount = new Label("Views.Basket.MoveToAccount.ChooseAccount","ChooseAccount");

					CopyBasket = new Label("Views.Basket.MoveToAccount.CopyBasket","CopyBasket");
				}

				MoveToAccountButton = new class
				{

Label = new Label("Views.Basket.MoveToAccountButton","MoveToAccountButton");

					Copy = new Label("Views.Basket.MoveToAccountButton.Copy","Copy");

					Success = new Label("Views.Basket.MoveToAccountButton.Success","Success");
				}

				NetSubTotal = new Label("Views.Basket.NetSubTotal","NetSubTotal");

				NetTotal = new Label("Views.Basket.NetTotal","NetTotal");

				Print = new Label("Views.Basket.Print","Print");

				QuickOrder = new class
				{

Label = new Label("Views.Basket.QuickOrder","QuickOrder");

					AddToBasket = new Label("Views.Basket.QuickOrder.AddToBasket","AddToBasket");

					ProductNumber = new Label("Views.Basket.QuickOrder.ProductNumber","ProductNumber");

					Quantity = new Label("Views.Basket.QuickOrder.Quantity","Quantity");

					Text = new Label("Views.Basket.QuickOrder.Text","Text");

					ValidationHelp = new Label("Views.Basket.QuickOrder.ValidationHelp","ValidationHelp");
				}

				RecommendedProductsTitle = new Label("Views.Basket.RecommendedProductsTitle","RecommendedProductsTitle");

				RemoveAllLines = new Label("Views.Basket.RemoveAllLines","RemoveAllLines");

				RemoveAllLinesConfirm = new Label("Views.Basket.RemoveAllLinesConfirm","RemoveAllLinesConfirm");

				SpecialOrder = new class
				{

Label = new Label("Views.Basket.SpecialOrder","SpecialOrder");

					Description = new Label("Views.Basket.SpecialOrder.Description","Description");

					Request = new Label("Views.Basket.SpecialOrder.Request","Request");

					Text = new Label("Views.Basket.SpecialOrder.Text","Text");
				}

				SubTotal = new Label("Views.Basket.SubTotal","SubTotal");

				Title = new Label("Views.Basket.Title","Title");

				ToCheckout = new Label("Views.Basket.ToCheckout","ToCheckout");

				ToPunchOut = new Label("Views.Basket.ToPunchOut","ToPunchOut");

				TotalNotAvailable = new Label("Views.Basket.TotalNotAvailable","TotalNotAvailable");

				Validation = new class
				{

Label = new Label("Views.Basket.Validation","Validation");

					NotFound = new Label("Views.Basket.Validation.NotFound","NotFound");
				}
			}

			BasketApproval = new class
			{

Label = new Label("Views.BasketApproval","BasketApproval");

				Baskets = new Label("Views.BasketApproval.Baskets","Baskets");

				CreationDate = new Label("Views.BasketApproval.CreationDate","CreationDate");

				DescriptionApprover = new Label("Views.BasketApproval.DescriptionApprover","DescriptionApprover");

				DescriptionUser = new Label("Views.BasketApproval.DescriptionUser","DescriptionUser");

				FromDate = new Label("Views.BasketApproval.FromDate","FromDate");

				GotoBasket = new Label("Views.BasketApproval.GotoBasket","GotoBasket");

				Header = new Label("Views.BasketApproval.Header","Header");

				NoBasketToApprove = new Label("Views.BasketApproval.NoBasketToApprove","NoBasketToApprove");

				Search = new Label("Views.BasketApproval.Search","Search");

				Sender = new Label("Views.BasketApproval.Sender","Sender");

				Status = new Label("Views.BasketApproval.Status","Status");

				StatusApproved = new Label("Views.BasketApproval.StatusApproved","StatusApproved");

				StatusPending = new Label("Views.BasketApproval.StatusPending","StatusPending");

				StatusRejected = new Label("Views.BasketApproval.StatusRejected","StatusRejected");

				ToDate = new Label("Views.BasketApproval.ToDate","ToDate");
			}

			Bookkeeping = new class
			{

Label = new Label("Views.Bookkeeping","Bookkeeping");

				AccountStatements = new class
				{

Label = new Label("Views.Bookkeeping.AccountStatements","AccountStatements");

					EmptyMonth = new Label("Views.Bookkeeping.AccountStatements.EmptyMonth","EmptyMonth");

					EmptyPeriod = new Label("Views.Bookkeeping.AccountStatements.EmptyPeriod","EmptyPeriod");

					FindAccountStatements = new Label("Views.Bookkeeping.AccountStatements.FindAccountStatements","FindAccountStatements");

					Month = new Label("Views.Bookkeeping.AccountStatements.Month","Month");

					Open = new Label("Views.Bookkeeping.AccountStatements.Open","Open");

					Statement = new Label("Views.Bookkeeping.AccountStatements.Statement","Statement");

					Year = new Label("Views.Bookkeeping.AccountStatements.Year","Year");
				}

				DeliveryNotes = new class
				{

Label = new Label("Views.Bookkeeping.DeliveryNotes","DeliveryNotes");

					DeliveryNote = new Label("Views.Bookkeeping.DeliveryNotes.DeliveryNote","DeliveryNote");

					DeliveryNoteDate = new Label("Views.Bookkeeping.DeliveryNotes.DeliveryNoteDate","DeliveryNoteDate");

					FindDeliveryNotes = new Label("Views.Bookkeeping.DeliveryNotes.FindDeliveryNotes","FindDeliveryNotes");

					Open = new Label("Views.Bookkeeping.DeliveryNotes.Open","Open");

					Text = new Label("Views.Bookkeeping.DeliveryNotes.Text","Text");
				}

				Invoices = new class
				{

Label = new Label("Views.Bookkeeping.Invoices","Invoices");

					CreditNota = new Label("Views.Bookkeeping.Invoices.CreditNota","CreditNota");

					ElectronicReceiver = new Label("Views.Bookkeeping.Invoices.ElectronicReceiver","ElectronicReceiver");

					FindInvoices = new Label("Views.Bookkeeping.Invoices.FindInvoices","FindInvoices");

					InvoiceDate = new Label("Views.Bookkeeping.Invoices.InvoiceDate","InvoiceDate");

					InvoiceId = new Label("Views.Bookkeeping.Invoices.InvoiceId","InvoiceId");

					InvoiceIdType = new class
					{

Label = new Label("Views.Bookkeeping.Invoices.InvoiceIdType","InvoiceIdType");

						All = new Label("Views.Bookkeeping.Invoices.InvoiceIdType.All","All");

						BonusCreditNota = new Label("Views.Bookkeeping.Invoices.InvoiceIdType.BonusCreditNota","BonusCreditNota");

						BonusInvoice = new Label("Views.Bookkeeping.Invoices.InvoiceIdType.BonusInvoice","BonusInvoice");

						CreditNota = new Label("Views.Bookkeeping.Invoices.InvoiceIdType.CreditNota","CreditNota");

						Invoice = new Label("Views.Bookkeeping.Invoices.InvoiceIdType.Invoice","Invoice");

						RateNota = new Label("Views.Bookkeeping.Invoices.InvoiceIdType.RateNota","RateNota");

						RentNota = new Label("Views.Bookkeeping.Invoices.InvoiceIdType.RentNota","RentNota");

						Text = new Label("Views.Bookkeeping.Invoices.InvoiceIdType.Text","Text");
					}

					InvoiceStatus = new class
					{

Label = new Label("Views.Bookkeeping.Invoices.InvoiceStatus","InvoiceStatus");

						NotPaid = new Label("Views.Bookkeeping.Invoices.InvoiceStatus.NotPaid","NotPaid");

						Paid = new Label("Views.Bookkeeping.Invoices.InvoiceStatus.Paid","Paid");
					}

					InvoiceTypeCode = new class
					{

Label = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode","InvoiceTypeCode");

						F = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.F","F");

						F2 = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.F2","F2");

						F5 = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.F5","F5");

						F8 = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.F8","F8");

						FR = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.FR","FR");

						G2 = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.G2","G2");

						K = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.K","K");

						L2 = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.L2","L2");

						RE = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.RE","RE");

						S1 = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.S1","S1");

						S2 = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.S2","S2");

						S3 = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.S3","S3");

						ZBHR = new Label("Views.Bookkeeping.Invoices.InvoiceTypeCode.ZBHR","ZBHR");
					}

					ProductIdType = new class
					{

Label = new Label("Views.Bookkeeping.Invoices.ProductIdType","ProductIdType");

						Buyer = new Label("Views.Bookkeeping.Invoices.ProductIdType.Buyer","Buyer");

						CarlF = new Label("Views.Bookkeeping.Invoices.ProductIdType.CarlF","CarlF");

						DbId = new Label("Views.Bookkeeping.Invoices.ProductIdType.DbId","DbId");

						El = new Label("Views.Bookkeeping.Invoices.ProductIdType.El","El");

						Sani = new Label("Views.Bookkeeping.Invoices.ProductIdType.Sani","Sani");

						VVS = new Label("Views.Bookkeeping.Invoices.ProductIdType.VVS","VVS");
					}

					Status = new Label("Views.Bookkeeping.Invoices.Status","Status");

					Text = new Label("Views.Bookkeeping.Invoices.Text","Text");
				}

				Orders = new Label("Views.Bookkeeping.Orders","Orders");
			}

			Brands = new class
			{

Label = new Label("Views.Brands","Brands");

				BaseBrandHeading = new Label("Views.Brands.BaseBrandHeading","BaseBrandHeading");

				CategoryHeading = new Label("Views.Brands.CategoryHeading","CategoryHeading");

				PremiumBrandHeading = new Label("Views.Brands.PremiumBrandHeading","PremiumBrandHeading");

				StandardBrandHeading = new Label("Views.Brands.StandardBrandHeading","StandardBrandHeading");
			}

			Category = new class
			{

Label = new Label("Views.Category","Category");

				DefaultMetaDescriptionForCategoryList = new Label("Views.Category.DefaultMetaDescriptionForCategoryList","DefaultMetaDescriptionForCategoryList");

				DefaultMetaDescriptionForProductList = new Label("Views.Category.DefaultMetaDescriptionForProductList","DefaultMetaDescriptionForProductList");

				NoCategoriesFallbackText = new Label("Views.Category.NoCategoriesFallbackText","NoCategoriesFallbackText");

				Products = new class
				{

Label = new Label("Views.Category.Products","Products");

					CountText = new Label("Views.Category.Products.CountText","CountText");

					CountTextSingle = new Label("Views.Category.Products.CountTextSingle","CountTextSingle");

					Facets = new class
					{

Label = new Label("Views.Category.Products.Facets","Facets");

						Clear = new Label("Views.Category.Products.Facets.Clear","Clear");

						ClearShort = new Label("Views.Category.Products.Facets.ClearShort","ClearShort");

						MobileClose = new Label("Views.Category.Products.Facets.MobileClose","MobileClose");

						MobileHeader = new Label("Views.Category.Products.Facets.MobileHeader","MobileHeader");

						MobileTrigger = new Label("Views.Category.Products.Facets.MobileTrigger","MobileTrigger");

						MobileTriggerShort = new Label("Views.Category.Products.Facets.MobileTriggerShort","MobileTriggerShort");

						NoFacets = new Label("Views.Category.Products.Facets.NoFacets","NoFacets");

						Text = new Label("Views.Category.Products.Facets.Text","Text");
					}

					NoResultText = new Label("Views.Category.Products.NoResultText","NoResultText");

					PromotedProductsHeader = new Label("Views.Category.Products.PromotedProductsHeader","PromotedProductsHeader");

					Sorting = new class
					{

Label = new Label("Views.Category.Products.Sorting","Sorting");

						SetDefaultText = new Label("Views.Category.Products.Sorting.SetDefaultText","SetDefaultText");

						Text = new Label("Views.Category.Products.Sorting.Text","Text");
					}

					SuggestedFacetsHeader = new Label("Views.Category.Products.SuggestedFacetsHeader","SuggestedFacetsHeader");
				}

				Related = new Label("Views.Category.Related","Related");

				RelatedProducts = new Label("Views.Category.RelatedProducts","RelatedProducts");

				ShowMore = new Label("Views.Category.ShowMore","ShowMore");
			}

			ChangeAccount = new class
			{

Label = new Label("Views.ChangeAccount","ChangeAccount");

				ActiveAccount = new Label("Views.ChangeAccount.ActiveAccount","ActiveAccount");

				ChooseOtherAccount = new Label("Views.ChangeAccount.ChooseOtherAccount","ChooseOtherAccount");
			}

			ChangePassword = new class
			{

Label = new Label("Views.ChangePassword","ChangePassword");

				ConfirmButton = new Label("Views.ChangePassword.ConfirmButton","ConfirmButton");

				ConfirmPassword = new Label("Views.ChangePassword.ConfirmPassword","ConfirmPassword");

				NewPassword = new Label("Views.ChangePassword.NewPassword","NewPassword");

				PasswordsNotEqual = new Label("Views.ChangePassword.PasswordsNotEqual","PasswordsNotEqual");
			}

			ChatInfo = new class
			{

Label = new Label("Views.ChatInfo","ChatInfo");

				EhandelButton = new Label("Views.ChatInfo.EhandelButton","EhandelButton");

				ProductsButton = new Label("Views.ChatInfo.ProductsButton","ProductsButton");

				ServiceButton = new Label("Views.ChatInfo.ServiceButton","ServiceButton");
			}

			Checkout = new class
			{

Label = new Label("Views.Checkout","Checkout");

				ActiveAccount = new Label("Views.Checkout.ActiveAccount","ActiveAccount");

				Approval = new class
				{

Label = new Label("Views.Checkout.Approval","Approval");

					ApproveBasket = new Label("Views.Checkout.Approval.ApproveBasket","ApproveBasket");

					DeclineAlertConfirm = new Label("Views.Checkout.Approval.DeclineAlertConfirm","DeclineAlertConfirm");

					DeclineAlertDescription = new Label("Views.Checkout.Approval.DeclineAlertDescription","DeclineAlertDescription");

					DeclineAlertHeader = new Label("Views.Checkout.Approval.DeclineAlertHeader","DeclineAlertHeader");

					DeclineBasket = new Label("Views.Checkout.Approval.DeclineBasket","DeclineBasket");

					Header = new Label("Views.Checkout.Approval.Header","Header");

					PageHeader = new Label("Views.Checkout.Approval.PageHeader","PageHeader");
				}

				BackToBasket = new Label("Views.Checkout.BackToBasket","BackToBasket");

				BackToDelivery = new Label("Views.Checkout.BackToDelivery","BackToDelivery");

				CO2InProduktionenTotal = new Label("Views.Checkout.CO2InProduktionenTotal","CO2InProduktionenTotal");

				Confirmation = new class
				{

Label = new Label("Views.Checkout.Confirmation","Confirmation");

					ConfirmOrder = new Label("Views.Checkout.Confirmation.ConfirmOrder","ConfirmOrder");

					ConfirmOrderHeader = new Label("Views.Checkout.Confirmation.ConfirmOrderHeader","ConfirmOrderHeader");

					ConfirmOrderHeaderSmall = new Label("Views.Checkout.Confirmation.ConfirmOrderHeaderSmall","ConfirmOrderHeaderSmall");

					DeleteReferenceDescription = new Label("Views.Checkout.Confirmation.DeleteReferenceDescription","DeleteReferenceDescription");

					DeleteReferenceLineHeading = new Label("Views.Checkout.Confirmation.DeleteReferenceLineHeading","DeleteReferenceLineHeading");

					DeliveryDate = new class
					{

Label = new Label("Views.Checkout.Confirmation.DeliveryDate","DeliveryDate");

						Format = new Label("Views.Checkout.Confirmation.DeliveryDate.Format","Format");

						OutOfStock = new Label("Views.Checkout.Confirmation.DeliveryDate.OutOfStock","OutOfStock");

						Text = new Label("Views.Checkout.Confirmation.DeliveryDate.Text","Text");

						Unknown = new Label("Views.Checkout.Confirmation.DeliveryDate.Unknown","Unknown");
					}

					DeliveryProducts = new Label("Views.Checkout.Confirmation.DeliveryProducts","DeliveryProducts");

					LineItem = new class
					{

Label = new Label("Views.Checkout.Confirmation.LineItem","LineItem");

						Charge = new Label("Views.Checkout.Confirmation.LineItem.Charge","Charge");

						DeliveryTime = new Label("Views.Checkout.Confirmation.LineItem.DeliveryTime","DeliveryTime");

						ExpectedDelivery = new Label("Views.Checkout.Confirmation.LineItem.ExpectedDelivery","ExpectedDelivery");

						PickupTime = new Label("Views.Checkout.Confirmation.LineItem.PickupTime","PickupTime");

						ProductId = new Label("Views.Checkout.Confirmation.LineItem.ProductId","ProductId");

						ProductName = new Label("Views.Checkout.Confirmation.LineItem.ProductName","ProductName");

						Quantity = new Label("Views.Checkout.Confirmation.LineItem.Quantity","Quantity");

						Total = new Label("Views.Checkout.Confirmation.LineItem.Total","Total");

						VvsId = new Label("Views.Checkout.Confirmation.LineItem.VvsId","VvsId");
					}

					Manual = new Label("Views.Checkout.Confirmation.Manual","Manual");

					OrderOverviewLink = new Label("Views.Checkout.Confirmation.OrderOverviewLink","OrderOverviewLink");

					Pickup = new Label("Views.Checkout.Confirmation.Pickup","Pickup");

					PickupDate = new Label("Views.Checkout.Confirmation.PickupDate","PickupDate");

					Shipping = new Label("Views.Checkout.Confirmation.Shipping","Shipping");

					ShippingOptions = new class
					{

Label = new Label("Views.Checkout.Confirmation.ShippingOptions","ShippingOptions");

						AccountNumber = new Label("Views.Checkout.Confirmation.ShippingOptions.AccountNumber","AccountNumber");

						CaseNumber = new Label("Views.Checkout.Confirmation.ShippingOptions.CaseNumber","CaseNumber");

						Reference = new Label("Views.Checkout.Confirmation.ShippingOptions.Reference","Reference");

						RequisitionNumber = new Label("Views.Checkout.Confirmation.ShippingOptions.RequisitionNumber","RequisitionNumber");

						Text = new Label("Views.Checkout.Confirmation.ShippingOptions.Text","Text");
					}

					ShowDeliveryInformation = new Label("Views.Checkout.Confirmation.ShowDeliveryInformation","ShowDeliveryInformation");

					SplitBasket = new Label("Views.Checkout.Confirmation.SplitBasket","SplitBasket");

					Thanks = new class
					{

Label = new Label("Views.Checkout.Confirmation.Thanks","Thanks");

						CopyTo = new Label("Views.Checkout.Confirmation.Thanks.CopyTo","CopyTo");

						EmailSendt = new Label("Views.Checkout.Confirmation.Thanks.EmailSendt","EmailSendt");

						Text = new Label("Views.Checkout.Confirmation.Thanks.Text","Text");
					}

					Total = new class
					{

Label = new Label("Views.Checkout.Confirmation.Total","Total");

						And = new Label("Views.Checkout.Confirmation.Total.And","And");

						CertificatesIncl = new Label("Views.Checkout.Confirmation.Total.CertificatesIncl","CertificatesIncl");

						CertificatesTotal = new Label("Views.Checkout.Confirmation.Total.CertificatesTotal","CertificatesTotal");

						ChargeIncl = new Label("Views.Checkout.Confirmation.Total.ChargeIncl","ChargeIncl");

						ChargeTotal = new Label("Views.Checkout.Confirmation.Total.ChargeTotal","ChargeTotal");

						GrossSubTotal = new Label("Views.Checkout.Confirmation.Total.GrossSubTotal","GrossSubTotal");

						GrossTotal = new Label("Views.Checkout.Confirmation.Total.GrossTotal","GrossTotal");

						NetSubTotal = new Label("Views.Checkout.Confirmation.Total.NetSubTotal","NetSubTotal");

						NetTotal = new Label("Views.Checkout.Confirmation.Total.NetTotal","NetTotal");

						Vat = new Label("Views.Checkout.Confirmation.Total.Vat","Vat");
					}
				}

				Delivery = new class
				{

Label = new Label("Views.Checkout.Delivery","Delivery");

					ChangeDeliveryAddress = new Label("Views.Checkout.Delivery.ChangeDeliveryAddress","ChangeDeliveryAddress");

					ChangeDeliveryDate = new Label("views.Checkout.Delivery.ChangeDeliveryDate","ChangeDeliveryDate");

					ChangeDeliveryMethod = new Label("Views.Checkout.Delivery.ChangeDeliveryMethod","ChangeDeliveryMethod");

					ChangeStore = new Label("Views.Checkout.Delivery.ChangeStore","ChangeStore");

					ChooseDeliveryAddress = new Label("Views.Checkout.Delivery.ChooseDeliveryAddress","ChooseDeliveryAddress");

					ChooseNearestShop = new Label("Views.Checkout.Delivery.ChooseNearestShop","ChooseNearestShop");

					ChooseStore = new Label("Views.Checkout.Delivery.ChooseStore","ChooseStore");

					ChosenDeliveryAddress = new Label("Views.Checkout.Delivery.ChosenDeliveryAddress","ChosenDeliveryAddress");

					ChosenDeliveryDate = new Label("Views.Checkout.Delivery.ChosenDeliveryDate","ChosenDeliveryDate");

					ChosenDeliveryMethod = new Label("Views.Checkout.Delivery.ChosenDeliveryMethod","ChosenDeliveryMethod");

					ChosenStore = new Label("Views.Checkout.Delivery.ChosenStore","ChosenStore");

					CompleteOrder = new Label("views.Checkout.Delivery.CompleteOrder","CompleteOrder");

					ConfirmOrder = new Label("Views.Checkout.Delivery.ConfirmOrder","ConfirmOrder");

					CreateAddress = new class
					{

Label = new Label("Views.Checkout.Delivery.CreateAddress","CreateAddress");

						AddNew = new Label("Views.Checkout.Delivery.CreateAddress.AddNew","AddNew");

						AddNewButton = new Label("Views.Checkout.Delivery.CreateAddress.AddNewButton","AddNewButton");

						AddNewCancel = new Label("Views.Checkout.Delivery.CreateAddress.AddNewCancel","AddNewCancel");

						AddNewDeliveryAddress = new Label("Views.Checkout.Delivery.CreateAddress.AddNewDeliveryAddress","AddNewDeliveryAddress");

						AddNewWithoutValidation = new Label("Views.Checkout.Delivery.CreateAddress.AddNewWithoutValidation","AddNewWithoutValidation");

						AddNewWithoutValidationComment = new Label("Views.Checkout.Delivery.CreateAddress.AddNewWithoutValidationComment","AddNewWithoutValidationComment");

						ChosenAddress = new Label("Views.Checkout.Delivery.CreateAddress.ChosenAddress","ChosenAddress");

						City = new Label("Views.Checkout.Delivery.CreateAddress.City","City");

						Floor = new Label("Views.Checkout.Delivery.CreateAddress.Floor","Floor");

						Invalid = new Label("Views.Checkout.Delivery.CreateAddress.Invalid","Invalid");

						Manual = new Label("Views.Checkout.Delivery.CreateAddress.Manual","Manual");

						Name1 = new Label("Views.Checkout.Delivery.CreateAddress.Name1","Name1");

						Name2 = new Label("Views.Checkout.Delivery.CreateAddress.Name2","Name2");

						PostalCode = new Label("Views.Checkout.Delivery.CreateAddress.PostalCode","PostalCode");

						SearchAddress = new Label("views.Checkout.Delivery.CreateAddress.SearchAddress","SearchAddress");

						Street = new Label("Views.Checkout.Delivery.CreateAddress.Street","Street");

						StreetNumber = new Label("Views.Checkout.Delivery.CreateAddress.StreetNumber","StreetNumber");

						Text = new Label("Views.Checkout.Delivery.CreateAddress.Text","Text");

						Unsuccessfull = new Label("Views.Checkout.Delivery.CreateAddress.Unsuccessfull","Unsuccessfull");
					}

					DeliveryAddressNotFound = new Label("Views.Checkout.Delivery.DeliveryAddressNotFound","DeliveryAddressNotFound");

					Header = new Label("Views.Checkout.Delivery.Header","Header");

					ReadyToday = new Label("Views.Checkout.Delivery.ReadyToday","ReadyToday");

					SetAsAccountPickupStore = new Label("Views.Checkout.Delivery.SetAsAccountPickupStore","SetAsAccountPickupStore");

					SetAsDefaultDeliveryAddress = new Label("Views.Checkout.Delivery.SetAsDefaultDeliveryAddress","SetAsDefaultDeliveryAddress");

					SetAsDefaultPickupStore = new Label("Views.Checkout.Delivery.SetAsDefaultPickupStore","SetAsDefaultPickupStore");

					Shipping = new class
					{

Label = new Label("Views.Checkout.Delivery.Shipping","Shipping");

						_03001 = new class
						{

Label = new Label("Views.Checkout.Delivery.Shipping.03001","_03001");

							NotEnabledDisplayText = new Label("Views.Checkout.Delivery.Shipping.03001.NotEnabledDisplayText","NotEnabledDisplayText");
						}

						BUTIK = new class
						{

Label = new Label("Views.Checkout.Delivery.Shipping.BUTIK","BUTIK");

							ChooseAddress = new Label("Views.Checkout.Delivery.Shipping.BUTIK.ChooseAddress","ChooseAddress");

							ChoosePickUpTime = new Label("Views.Checkout.Delivery.Shipping.BUTIK.ChoosePickUpTime","ChoosePickUpTime");

							DisplayName = new Label("Views.Checkout.Delivery.Shipping.BUTIK.DisplayName","DisplayName");

							DisplayText = new Label("Views.Checkout.Delivery.Shipping.BUTIK.DisplayText","DisplayText");

							Note = new Label("Views.Checkout.Delivery.Shipping.BUTIK.Note","Note");
						}

						CC = new class
						{

Label = new Label("Views.Checkout.Delivery.Shipping.CC","CC");

							DisplayName = new Label("Views.Checkout.Delivery.Shipping.CC.DisplayName","DisplayName");

							DisplayText = new Label("Views.Checkout.Delivery.Shipping.CC.DisplayText","DisplayText");
						}

						ChooseMethod = new Label("Views.Checkout.Delivery.Shipping.ChooseMethod","ChooseMethod");

						ChooseOtherAddress = new Label("Views.Checkout.Delivery.Shipping.ChooseOtherAddress","ChooseOtherAddress");

						DAG = new class
						{

Label = new Label("Views.Checkout.Delivery.Shipping.DAG","DAG");

							DisplayName = new Label("Views.Checkout.Delivery.Shipping.DAG.DisplayName","DisplayName");

							DisplayText = new Label("Views.Checkout.Delivery.Shipping.DAG.DisplayText","DisplayText");
						}

						DeliveryFeeNote = new Label("Views.Checkout.Delivery.Shipping.DeliveryFeeNote","DeliveryFeeNote");

						DeliveryOptions = new Label("Views.Checkout.Delivery.Shipping.DeliveryOptions","DeliveryOptions");

						DigitalCommunitaction = new class
						{

Label = new Label("Views.Checkout.Delivery.Shipping.DigitalCommunitaction","DigitalCommunitaction");

							Email = new Label("Views.Checkout.Delivery.Shipping.DigitalCommunitaction.Email","Email");

							Header = new Label("Views.Checkout.Delivery.Shipping.DigitalCommunitaction.Header","Header");

							Info = new Label("Views.Checkout.Delivery.Shipping.DigitalCommunitaction.Info","Info");

							Phone = new Label("Views.Checkout.Delivery.Shipping.DigitalCommunitaction.Phone","Phone");
						}

						NAT = new class
						{

Label = new Label("Views.Checkout.Delivery.Shipping.NAT","NAT");

							DisplayName = new Label("Views.Checkout.Delivery.Shipping.NAT.DisplayName","DisplayName");

							DisplayText = new Label("Views.Checkout.Delivery.Shipping.NAT.DisplayText","DisplayText");
						}

						PICKUP = new class
						{

Label = new Label("Views.Checkout.Delivery.Shipping.PICKUP","PICKUP");

							DisplayName = new Label("Views.Checkout.Delivery.Shipping.PICKUP.DisplayName","DisplayName");

							DisplayText = new Label("Views.Checkout.Delivery.Shipping.PICKUP.DisplayText","DisplayText");
						}

						SearchNoResult = new Label("views.Checkout.Delivery.Shipping.SearchNoResult","SearchNoResult");

						SearchPlaceholder = new Label("Views.Checkout.Delivery.Shipping.SearchPlaceholder","SearchPlaceholder");

						SearchShippingAddress = new Label("Views.Checkout.Delivery.Shipping.SearchShippingAddress","SearchShippingAddress");

						SearchStore = new Label("Views.Checkout.Delivery.Shipping.SearchStore","SearchStore");
					}

					ShippingOptions = new class
					{

Label = new Label("Views.Checkout.Delivery.ShippingOptions","ShippingOptions");

						CaseMaskLinkTooltip = new Label("Views.Checkout.Delivery.ShippingOptions.CaseMaskLinkTooltip","CaseMaskLinkTooltip");

						CaseNumber = new Label("Views.Checkout.Delivery.ShippingOptions.CaseNumber","CaseNumber");

						Comment = new Label("Views.Checkout.Delivery.ShippingOptions.Comment","Comment");

						Commentnb = new Label("Views.Checkout.Delivery.ShippingOptions.Commentnb","Commentnb");

						Hide = new Label("Views.Checkout.Delivery.ShippingOptions.Hide","Hide");

						MaskExplanation = new Label("Views.Checkout.Delivery.ShippingOptions.MaskExplanation","MaskExplanation");

						MaskExplanationHeader = new Label("Views.Checkout.Delivery.ShippingOptions.MaskExplanationHeader","MaskExplanationHeader");

						MaskHelp = new Label("Views.Checkout.Delivery.ShippingOptions.MaskHelp","MaskHelp");

						Reference = new Label("Views.Checkout.Delivery.ShippingOptions.Reference","Reference");

						ReferenceMaskLinkTooltip = new Label("Views.Checkout.Delivery.ShippingOptions.ReferenceMaskLinkTooltip","ReferenceMaskLinkTooltip");

						RekvMaskLinkTooltip = new Label("Views.Checkout.Delivery.ShippingOptions.RekvMaskLinkTooltip","RekvMaskLinkTooltip");

						RequisitionNumber = new Label("Views.Checkout.Delivery.ShippingOptions.RequisitionNumber","RequisitionNumber");

						RequisitionNumberHelp = new Label("Views.Checkout.Delivery.ShippingOptions.RequisitionNumberHelp","RequisitionNumberHelp");

						Show = new Label("Views.Checkout.Delivery.ShippingOptions.Show","Show");

						Text = new Label("Views.Checkout.Delivery.ShippingOptions.Text","Text");
					}

					ShippingTime = new class
					{

Label = new Label("Views.Checkout.Delivery.ShippingTime","ShippingTime");

						CanBeDeliveredSimultaneously = new Label("Views.Checkout.Delivery.ShippingTime.CanBeDeliveredSimultaneously","CanBeDeliveredSimultaneously");

						CanBePickedUpSimultaneously = new Label("Views.Checkout.Delivery.ShippingTime.CanBePickedUpSimultaneously","CanBePickedUpSimultaneously");

						CanNotBeDeliveredSimultaneously = new Label("Views.Checkout.Delivery.ShippingTime.CanNotBeDeliveredSimultaneously","CanNotBeDeliveredSimultaneously");

						CanNotBePickedUpSimultaneously = new Label("Views.Checkout.Delivery.ShippingTime.CanNotBePickedUpSimultaneously","CanNotBePickedUpSimultaneously");

						CCCanNotBePickedUpSimultaneously = new Label("Views.Checkout.Delivery.ShippingTime.CCCanNotBePickedUpSimultaneously","CCCanNotBePickedUpSimultaneously");

						ChooseDeliveryDate = new Label("Views.Checkout.Delivery.ShippingTime.ChooseDeliveryDate","ChooseDeliveryDate");

						ChoosenDeliveryDate = new Label("Views.Checkout.Delivery.ShippingTime.ChoosenDeliveryDate","ChoosenDeliveryDate");

						Deliveries = new Label("Views.Checkout.Delivery.ShippingTime.Deliveries","Deliveries");

						DeliveryDate = new class
						{

Label = new Label("Views.Checkout.Delivery.ShippingTime.DeliveryDate","DeliveryDate");

							ChooseDate = new Label("Views.Checkout.Delivery.ShippingTime.DeliveryDate.ChooseDate","ChooseDate");
						}

						DeliveryOverview = new Label("Views.Checkout.Delivery.ShippingTime.DeliveryOverview","DeliveryOverview");

						Formats = new class
						{

Label = new Label("Views.Checkout.Delivery.ShippingTime.Formats","Formats");

							Date = new Label("Views.Checkout.Delivery.ShippingTime.Formats.Date","Date");

							DateWithFromTimeOnly = new Label("Views.Checkout.Delivery.ShippingTime.Formats.DateWithFromTimeOnly","DateWithFromTimeOnly");

							ExpectedDateOnly = new Label("Views.Checkout.Delivery.ShippingTime.Formats.ExpectedDateOnly","ExpectedDateOnly");
						}

						PickupDate = new Label("Views.Checkout.Delivery.ShippingTime.PickupDate","PickupDate");

						PickupOpeningHours = new Label("Views.Checkout.Delivery.ShippingTime.PickupOpeningHours","PickupOpeningHours");

						PickupOverview = new Label("Views.Checkout.Delivery.ShippingTime.PickupOverview","PickupOverview");

						SmsService = new class
						{

Label = new Label("Views.Checkout.Delivery.ShippingTime.SmsService","SmsService");

							SmsServiceText = new Label("Views.Checkout.Delivery.ShippingTime.SmsService.SmsServiceText","SmsServiceText");

							Text = new Label("Views.Checkout.Delivery.ShippingTime.SmsService.Text","Text");
						}

						UnknownDate = new Label("Views.Checkout.Delivery.ShippingTime.UnknownDate","UnknownDate");
					}

					ShowAllAddresses = new Label("Views.Checkout.Delivery.ShowAllAddresses","ShowAllAddresses");

					ShowAllStores = new Label("Views.Checkout.Delivery.ShowAllStores","ShowAllStores");

					ShowLessAddresses = new Label("views.Checkout.Delivery.ShowLessAddresses","ShowLessAddresses");

					ShowLessStores = new Label("Views.Checkout.Delivery.ShowLessStores","ShowLessStores");

					SplitBasket = new Label("Views.Checkout.Delivery.SplitBasket","SplitBasket");

					StoreSelectedNotValid = new Label("Views.Checkout.Delivery.StoreSelectedNotValid","StoreSelectedNotValid");
				}

				LineItem = new class
				{

Label = new Label("Views.Checkout.LineItem","LineItem");

					CO2EstimatedValue = new Label("Views.Checkout.LineItem.CO2EstimatedValue","CO2EstimatedValue");

					CreateReferenceLine = new Label("Views.Checkout.LineItem.CreateReferenceLine","CreateReferenceLine");

					DeleteReferenceLine = new Label("Views.Checkout.LineItem.DeleteReferenceLine","DeleteReferenceLine");

					ReferenceLine = new Label("Views.Checkout.LineItem.ReferenceLine","ReferenceLine");
				}

				Receipt = new class
				{

Label = new Label("Views.Checkout.Receipt","Receipt");

					DeliveryAddress = new Label("Views.Checkout.Receipt.DeliveryAddress","DeliveryAddress");

					Header = new Label("Views.Checkout.Receipt.Header","Header");

					OrderNumber = new Label("Views.Checkout.Receipt.OrderNumber","OrderNumber");

					Print = new Label("Views.Checkout.Receipt.Print","Print");

					ReturnToFrontpage = new Label("views.Checkout.Receipt.ReturnToFrontpage","ReturnToFrontpage");

					SplitBasket = new Label("Views.Checkout.Receipt.SplitBasket","SplitBasket");
				}
			}

			ClimateCertificate = new class
			{
				Label = new Label("Views.ClimateCertificate","ClimateCertificate");

				Buttons = new class
				{

Label = new Label("Views.ClimateCertificate.Buttons","Buttons");

					More = new Label("Views.ClimateCertificate.Buttons.More","More");

					ShowLess = new Label("Views.ClimateCertificate.Buttons.ShowLess","ShowLess");
				}
			}

			Configurator = new class
			{

Label = new Label("Views.Configurator","Configurator");

				Products = new Label("Views.Configurator.Products","Products");
			}

			Contact = new class
			{

Label = new Label("Views.Contact","Contact");

				ContactType = new class
				{

Label = new Label("Views.Contact.ContactType","ContactType");

					CP = new Label("Views.Contact.ContactType.CP","CP");

					ER = new Label("Views.Contact.ContactType.ER","ER");

					SE = new Label("Views.Contact.ContactType.SE","SE");

					TM = new Label("Views.Contact.ContactType.TM","TM");

					VE = new Label("Views.Contact.ContactType.VE","VE");

					YA = new Label("Views.Contact.ContactType.YA","YA");

					YB = new Label("Views.Contact.ContactType.YB","YB");

					YC = new Label("Views.Contact.ContactType.YC","YC");

					YD = new Label("Views.Contact.ContactType.YD","YD");

					YE = new Label("Views.Contact.ContactType.YE","YE");

					YF = new Label("Views.Contact.ContactType.YF","YF");

					YG = new Label("Views.Contact.ContactType.YG","YG");

					YH = new Label("Views.Contact.ContactType.YH","YH");

					YI = new Label("Views.Contact.ContactType.YI","YI");

					YJ = new Label("Views.Contact.ContactType.YJ","YJ");

					YK = new Label("Views.Contact.ContactType.YK","YK");

					YL = new Label("Views.Contact.ContactType.YL","YL");

					YM = new Label("Views.Contact.ContactType.YM","YM");

					YN = new Label("Views.Contact.ContactType.YN","YN");

					YO = new Label("Views.Contact.ContactType.YO","YO");

					YP = new Label("Views.Contact.ContactType.YP","YP");

					YQ = new Label("Views.Contact.ContactType.YQ","YQ");

					YR = new Label("Views.Contact.ContactType.YR","YR");

					YS = new Label("Views.Contact.ContactType.YS","YS");

					YT = new Label("Views.Contact.ContactType.YT","YT");

					YU = new Label("Views.Contact.ContactType.YU","YU");

					YV = new Label("Views.Contact.ContactType.YV","YV");

					YW = new Label("Views.Contact.ContactType.YW","YW");

					YX = new Label("Views.Contact.ContactType.YX","YX");

					YY = new Label("Views.Contact.ContactType.YY","YY");

					Z1 = new Label("Views.Contact.ContactType.Z1","Z1");

					Z2 = new Label("Views.Contact.ContactType.Z2","Z2");

					Z3 = new Label("Views.Contact.ContactType.Z3","Z3");

					Z4 = new Label("Views.Contact.ContactType.Z4","Z4");

					Z5 = new Label("Views.Contact.ContactType.Z5","Z5");

					Z6 = new Label("Views.Contact.ContactType.Z6","Z6");

					Z7 = new Label("Views.Contact.ContactType.Z7","Z7");

					Z8 = new Label("Views.Contact.ContactType.Z8","Z8");

					Z9 = new Label("Views.Contact.ContactType.Z9","Z9");

					ZA = new Label("Views.Contact.ContactType.ZA","ZA");

					ZE = new Label("Views.Contact.ContactType.ZE","ZE");

					ZF = new Label("Views.Contact.ContactType.ZF","ZF");

					ZM = new Label("Views.Contact.ContactType.ZM","ZM");

					ZP = new Label("Views.Contact.ContactType.ZP","ZP");

					ZS = new Label("Views.Contact.ContactType.ZS","ZS");

					ZT = new Label("Views.Contact.ContactType.ZT","ZT");

					ZV = new Label("Views.Contact.ContactType.ZV","ZV");
				}

				DownloadPdf = new Label("Views.Contact.DownloadPdf","DownloadPdf");

				Header = new Label("Views.Contact.Header","Header");

				Mobile = new Label("Views.Contact.Mobile","Mobile");

				PdfLabelAddress = new Label("Views.Contact.PdfLabelAddress","PdfLabelAddress");

				PdfLabelName = new Label("Views.Contact.PdfLabelName","PdfLabelName");

				PdfLabelPhone = new Label("Views.Contact.PdfLabelPhone","PdfLabelPhone");

				PdfLabelStoreHeader = new Label("Views.Contact.PdfLabelStoreHeader","PdfLabelStoreHeader");

				PdfLabelWorkHours = new Label("Views.Contact.PdfLabelWorkHours","PdfLabelWorkHours");

				Phone = new Label("Views.Contact.Phone","Phone");
			}

			Copytooltip = new class
			{

Label = new Label("Views.Copytooltip","Copytooltip");

				ClickToCopy = new Label("Views.Copytooltip.ClickToCopy","ClickToCopy");

				Copied = new Label("Views.Copytooltip.Copied","Copied");
			}

			CreateAccount = new class
			{

Label = new Label("Views.CreateAccount","CreateAccount");

				Company = new class
				{
					Label = new Label("Views.CreateAccount.Company","Company");

					Placeholder = new class
					{

Label = new Label("Views.CreateAccount.Company.Placeholder","Placeholder");

						CvrNumber = new Label("Views.CreateAccount.Company.Placeholder.CvrNumber","CvrNumber");

						Email = new Label("Views.CreateAccount.Company.Placeholder.Email","Email");

						Phone = new Label("Views.CreateAccount.Company.Placeholder.Phone","Phone");
					}
				}

				Create = new Label("Views.CreateAccount.Create","Create");

				Cvr = new Label("Views.CreateAccount.Cvr","Cvr");

				Email = new Label("Views.CreateAccount.Email","Email");

				ErpServiceSchedule = new Label("Views.CreateAccount.ErpServiceSchedule","ErpServiceSchedule");

				HelpText = new class
				{

Label = new Label("Views.CreateAccount.HelpText","HelpText");

					EasyAndFast = new Label("Views.CreateAccount.HelpText.EasyAndFast","EasyAndFast");

					EasyAndFastText = new Label("Views.CreateAccount.HelpText.EasyAndFastText","EasyAndFastText");

					FirmData = new Label("Views.CreateAccount.HelpText.FirmData","FirmData");

					FirmDataText = new Label("Views.CreateAccount.HelpText.FirmDataText","FirmDataText");

					UserData = new Label("Views.CreateAccount.HelpText.UserData","UserData");

					UserDataText = new Label("Views.CreateAccount.HelpText.UserDataText","UserDataText");
				}

				Phone = new Label("Views.CreateAccount.Phone","Phone");

				Placeholder = new class
				{

Label = new Label("Views.CreateAccount.Placeholder","Placeholder");

					CreateNewUsername = new Label("Views.CreateAccount.Placeholder.CreateNewUsername","CreateNewUsername");

					Email = new Label("Views.CreateAccount.Placeholder.Email","Email");

					FirstName = new Label("Views.CreateAccount.Placeholder.FirstName","FirstName");

					LastName = new Label("Views.CreateAccount.Placeholder.LastName","LastName");

					Phone = new Label("Views.CreateAccount.Placeholder.Phone","Phone");

					UseEmailAsUsername = new Label("Views.CreateAccount.Placeholder.UseEmailAsUsername","UseEmailAsUsername");

					Username = new Label("Views.CreateAccount.Placeholder.Username","Username");

					UseSameEmail = new Label("Views.CreateAccount.Placeholder.UseSameEmail","UseSameEmail");

					UseSamePhoneNr = new Label("Views.CreateAccount.Placeholder.UseSamePhoneNr","UseSamePhoneNr");
				}

				Shop = new Label("Views.CreateAccount.Shop","Shop");

				StoreRequired = new Label("Views.CreateAccount.StoreRequired","StoreRequired");

				Timeline = new class
				{

Label = new Label("Views.CreateAccount.Timeline","Timeline");

					ApproveTerms = new Label("Views.CreateAccount.Timeline.ApproveTerms","ApproveTerms");

					ConfirmEmail = new Label("Views.CreateAccount.Timeline.ConfirmEmail","ConfirmEmail");

					FirmUserData = new Label("Views.CreateAccount.Timeline.FirmUserData","FirmUserData");
				}

				Username = new Label("Views.CreateAccount.Username","Username");

				Validation = new class
				{

Label = new Label("Views.CreateAccount.Validation","Validation");

					Cvr = new Label("Views.CreateAccount.Validation.Cvr","Cvr");

					FirstNameNoNumber = new Label("Views.CreateAccount.Validation.FirstNameNoNumber","FirstNameNoNumber");

					LastNameNoNumber = new Label("Views.CreateAccount.Validation.LastNameNoNumber","LastNameNoNumber");

					UsernameNoSpecial = new Label("Views.CreateAccount.Validation.UsernameNoSpecial","UsernameNoSpecial");
				}

				YourFirm = new Label("Views.CreateAccount.YourFirm","YourFirm");

				Yourinfo = new Label("Views.CreateAccount.Yourinfo","Yourinfo");
			}

			CreateEasySupplyWarehouse = new class
			{
				Label = new Label("Views.CreateEasySupplyWarehouse","CreateEasySupplyWarehouse");

				Validation = new class
				{

Label = new Label("Views.CreateEasySupplyWarehouse.Validation","Validation");

					EasySupplyWarehouseIsNotUnique = new Label("Views.CreateEasySupplyWarehouse.Validation.EasySupplyWarehouseIsNotUnique","EasySupplyWarehouseIsNotUnique");
				}
			}

			CreateUser = new class
			{

Label = new Label("Views.CreateUser","CreateUser");

				AccountNumber = new Label("Views.CreateUser.AccountNumber","AccountNumber");

				CreateButton = new Label("Views.CreateUser.CreateButton","CreateButton");

				Email = new Label("Views.CreateUser.Email","Email");

				GroupHeading1 = new Label("Views.CreateUser.GroupHeading1","GroupHeading1");

				GroupHeading2 = new Label("Views.CreateUser.GroupHeading2","GroupHeading2");

				Help = new Label("Views.CreateUser.Help","Help");

				HelpHeading = new Label("Views.CreateUser.HelpHeading","HelpHeading");

				HelpText = new Label("Views.CreateUser.HelpText","HelpText");

				Name = new Label("Views.CreateUser.Name","Name");

				NotAllowed = new Label("Views.CreateUser.NotAllowed","NotAllowed");

				PhoneMain = new Label("Views.CreateUser.PhoneMain","PhoneMain");

				PhoneMobile = new Label("Views.CreateUser.PhoneMobile","PhoneMobile");

				Success = new Label("Views.CreateUser.Success","Success");

				Timeline = new class
				{

Label = new Label("Views.CreateUser.Timeline","Timeline");

					ApproveTerms = new Label("Views.CreateUser.Timeline.ApproveTerms","ApproveTerms");

					ConfirmEmail = new Label("Views.CreateUser.Timeline.ConfirmEmail","ConfirmEmail");

					FirmUserData = new Label("Views.CreateUser.Timeline.FirmUserData","FirmUserData");
				}

				UserName = new Label("Views.CreateUser.UserName","UserName");

				Validation = new class
				{

Label = new Label("Views.CreateUser.Validation","Validation");

					AccountAndPhoneMisMatch = new Label("Views.CreateUser.Validation.AccountAndPhoneMisMatch","AccountAndPhoneMisMatch");

					AccountLocked = new Label("Views.CreateUser.Validation.AccountLocked","AccountLocked");

					UsernameIsNotUnique = new Label("Views.CreateUser.Validation.UsernameIsNotUnique","UsernameIsNotUnique");

					UsernameTooShort = new Label("Views.CreateUser.Validation.UsernameTooShort","UsernameTooShort");
				}

				ValidationPhoneNumberInvalid = new Label("Views.CreateUser.ValidationPhoneNumberInvalid","ValidationPhoneNumberInvalid");

				ValidationPhoneNumberLength = new Label("Views.CreateUser.ValidationPhoneNumberLength","ValidationPhoneNumberLength");
			}

			CreateWarehouse = new class
			{
				Label = new Label("Views.CreateWarehouse","CreateWarehouse");

				Validation = new class
				{

Label = new Label("Views.CreateWarehouse.Validation","Validation");

					WarehouseIsNotUnique = new Label("Views.CreateWarehouse.Validation.WarehouseIsNotUnique","WarehouseIsNotUnique");
				}
			}

			CustomerAnalysis = new class
			{

Label = new Label("Views.CustomerAnalysis","CustomerAnalysis");

				Views_CustomerAnalysis_Anonymous_ButtonText = new Label("Views.CustomerAnalysis.Anonymous.ButtonText","Views_CustomerAnalysis_Anonymous_ButtonText");

				Views_CustomerAnalysis_Anonymous_EmailLabel = new Label("Views.CustomerAnalysis.Anonymous.EmailLabel","Views_CustomerAnalysis_Anonymous_EmailLabel");

				Views_CustomerAnalysis_Anonymous_EmailValidationRegex = new Label("Views.CustomerAnalysis.Anonymous.EmailValidationRegex","Views_CustomerAnalysis_Anonymous_EmailValidationRegex");

				Views_CustomerAnalysis_Anonymous_EmailValidationRequired = new Label("Views.CustomerAnalysis.Anonymous.EmailValidationRequired","Views_CustomerAnalysis_Anonymous_EmailValidationRequired");
			}

			Dashboard = new class
			{

Label = new Label("Views.Dashboard","Dashboard");

				Graphs = new class
				{

Label = new Label("Views.Dashboard.Graphs","Graphs");

					Header = new class
					{

Label = new Label("Views.Dashboard.Graphs.Header","Header");

						Revenue = new Label("Views.Dashboard.Graphs.Header.Revenue","Revenue");

						RevenueByCategories = new Label("Views.Dashboard.Graphs.Header.RevenueByCategories","RevenueByCategories");

						RevenueByChannel = new Label("Views.Dashboard.Graphs.Header.RevenueByChannel","RevenueByChannel");
					}

					Labels = new class
					{

Label = new Label("Views.Dashboard.Graphs.Labels","Labels");

						All = new Label("Views.Dashboard.Graphs.Labels.All","All");

						Total = new Label("Views.Dashboard.Graphs.Labels.Total","Total");
					}

					MobileScreenInfo = new Label("Views.Dashboard.Graphs.MobileScreenInfo","MobileScreenInfo");
				}

				SplitInfo = new Label("Views.Dashboard.SplitInfo","SplitInfo");

				TimeInterval = new class
				{

Label = new Label("Views.Dashboard.TimeInterval","TimeInterval");

					Latest12Months = new Label("Views.Dashboard.TimeInterval.Latest12Months","Latest12Months");

					Latest6Months = new Label("Views.Dashboard.TimeInterval.Latest6Months","Latest6Months");

					ThisYear = new Label("Views.Dashboard.TimeInterval.ThisYear","ThisYear");
				}

				TimeIntervalHeader = new Label("Views.Dashboard.TimeIntervalHeader","TimeIntervalHeader");

				Title = new Label("Views.Dashboard.Title","Title");
			}

			DatePicker = new class
			{

Label = new Label("Views.DatePicker","DatePicker");

				ClearText = new Label("Views.DatePicker.ClearText","ClearText");

				CloseText = new Label("Views.DatePicker.CloseText","CloseText");

				TodayText = new Label("Views.DatePicker.TodayText","TodayText");
			}

			Download = new Label("Views.Download","Download");

			DownloadEsCsv = new Label("Views.DownloadEsCsv","DownloadEsCsv");

			DynamicMails = new class
			{
				Label = new Label("Views.DynamicMails","DynamicMails");

				Unsubscribe = new class
				{

Label = new Label("Views.DynamicMails.Unsubscribe","Unsubscribe");

					ButtonText = new Label("Views.DynamicMails.Unsubscribe.ButtonText","ButtonText");

					Error = new Label("Views.DynamicMails.Unsubscribe.Error","Error");

					MissingUserId = new Label("Views.DynamicMails.Unsubscribe.MissingUserId","MissingUserId");

					Success = new Label("Views.DynamicMails.Unsubscribe.Success","Success");
				}
			}

			EasySupply = new class
			{

Label = new Label("Views.EasySupply","EasySupply");

				QuantityAndUnitDisplayName = new Label("Views.EasySupply.QuantityAndUnitDisplayName","QuantityAndUnitDisplayName");

				Validation = new class
				{

Label = new Label("Views.EasySupply.Validation","Validation");

					AddLineNotAllowedBySalesAccount = new Label("Views.EasySupply.Validation.AddLineNotAllowedBySalesAccount","AddLineNotAllowedBySalesAccount");

					AddOrderLineNotAllowedBySalesAccount = new Label("Views.EasySupply.Validation.AddOrderLineNotAllowedBySalesAccount","AddOrderLineNotAllowedBySalesAccount");

					AlreadyExists = new Label("Views.EasySupply.Validation.AlreadyExists","AlreadyExists");

					AmbiguousProduct = new Label("Views.EasySupply.Validation.AmbiguousProduct","AmbiguousProduct");

					DeleteLineIdOutOfRange = new Label("Views.EasySupply.Validation.DeleteLineIdOutOfRange","DeleteLineIdOutOfRange");

					DeleteLineNotAllowedBySalesAccount = new Label("Views.EasySupply.Validation.DeleteLineNotAllowedBySalesAccount","DeleteLineNotAllowedBySalesAccount");

					DeleteNotAllowed = new Label("Views.EasySupply.Validation.DeleteNotAllowed","DeleteNotAllowed");

					DeliveryIntervalOutOfRange = new Label("Views.EasySupply.Validation.DeliveryIntervalOutOfRange","DeliveryIntervalOutOfRange");

					EditNotAllowed = new Label("Views.EasySupply.Validation.EditNotAllowed","EditNotAllowed");

					IdentifierInvalid = new Label("Views.EasySupply.Validation.IdentifierInvalid","IdentifierInvalid");

					ImportCsvErrorInLine = new Label("Views.EasySupply.Validation.ImportCsvErrorInLine","ImportCsvErrorInLine");

					ImportCsvReadingError = new Label("Views.EasySupply.Validation.ImportCsvReadingError","ImportCsvReadingError");

					LineNotFound = new Label("Views.EasySupply.Validation.LineNotFound","LineNotFound");

					LocationTooLong = new Label("Views.EasySupply.Validation.LocationTooLong","LocationTooLong");

					NameInValid = new Label("Views.EasySupply.Validation.NameInValid","NameInValid");

					NoGatewayOnWarehouse = new Label("Views.EasySupply.Validation.NoGatewayOnWarehouse","NoGatewayOnWarehouse");

					NotFound = new Label("Views.EasySupply.Validation.NotFound","NotFound");

					OrderLineNotFound = new Label("Views.EasySupply.Validation.OrderLineNotFound","OrderLineNotFound");

					ProductAlreadyExists = new Label("Views.EasySupply.Validation.ProductAlreadyExists","ProductAlreadyExists");

					ProductDeleteNotAllowed = new Label("Views.EasySupply.Validation.ProductDeleteNotAllowed","ProductDeleteNotAllowed");

					ProductNotFound = new Label("Views.EasySupply.Validation.ProductNotFound","ProductNotFound");

					ReOrderQuantityInvalid = new Label("Views.EasySupply.Validation.ReOrderQuantityInvalid","ReOrderQuantityInvalid");

					ReorderQuantityOutOfRange = new Label("Views.EasySupply.Validation.ReorderQuantityOutOfRange","ReorderQuantityOutOfRange");

					ReorderThresholdOutOfRange = new Label("Views.EasySupply.Validation.ReorderThresholdOutOfRange","ReorderThresholdOutOfRange");

					UpdateNotAllowedBySalesAccount = new Label("Views.EasySupply.Validation.UpdateNotAllowedBySalesAccount","UpdateNotAllowedBySalesAccount");

					ViewNotAllowed = new Label("Views.EasySupply.Validation.ViewNotAllowed","ViewNotAllowed");
				}
			}

			EditUser = new class
			{

Label = new Label("Views.EditUser","EditUser");

				AccountAdded = new Label("Views.EditUser.AccountAdded","AccountAdded");

				AccountNumber = new Label("Views.EditUser.AccountNumber","AccountNumber");

				Add = new Label("Views.EditUser.Add","Add");

				AddAccountButton = new Label("Views.EditUser.AddAccountButton","AddAccountButton");

				AddAccountHeading = new Label("Views.EditUser.AddAccountHeading","AddAccountHeading");

				AddAccountInfo = new Label("Views.EditUser.AddAccountInfo","AddAccountInfo");

				Added = new Label("Views.EditUser.Added","Added");

				AutoGeneratedPassword = new Label("Views.EditUser.AutoGeneratedPassword","AutoGeneratedPassword");

				AutoGeneratePassword = new Label("Views.EditUser.AutoGeneratePassword","AutoGeneratePassword");

				Comments = new Label("Views.EditUser.Comments","Comments");

				ConfirmDelete = new Label("Views.EditUser.ConfirmDelete","ConfirmDelete");

				CopyTo = new class
				{

Label = new Label("Views.EditUser.CopyTo","CopyTo");

					HelpText = new Label("Views.EditUser.CopyTo.HelpText","HelpText");

					Placeholder = new Label("Views.EditUser.CopyTo.Placeholder","Placeholder");

					SendCopyTo = new Label("Views.EditUser.CopyTo.SendCopyTo","SendCopyTo");
				}

				Create = new Label("Views.EditUser.Create","Create");

				Created = new Label("views.EditUser.Created","Created");

				Delete = new Label("Views.EditUser.Delete","Delete");

				Developer = new class
				{

Label = new Label("Views.EditUser.Developer","Developer");

					Access = new Label("Views.EditUser.Developer.Access","Access");

					Text = new Label("Views.EditUser.Developer.Text","Text");
				}

				DoesNotAllowPublicRegistration = new Label("Views.EditUser.DoesNotAllowPublicRegistration","DoesNotAllowPublicRegistration");

				EasySupplyAdmin = new class
				{

Label = new Label("Views.EditUser.EasySupplyAdmin","EasySupplyAdmin");

					Checkbox = new Label("Views.EditUser.EasySupplyAdmin.Checkbox","Checkbox");

					Text = new Label("Views.EditUser.EasySupplyAdmin.Text","Text");
				}

				Email = new Label("Views.EditUser.Email","Email");

				Help = new Label("Views.EditUser.Help","Help");

				InvalidPhoneNumber = new Label("Views.EditUser.InvalidPhoneNumber","InvalidPhoneNumber");

				LockedOut = new Label("Views.EditUser.LockedOut","LockedOut");

				MailUserMessage = new Label("Views.EditUser.MailUserMessage","MailUserMessage");

				ManualGeneratePassword = new Label("Views.EditUser.ManualGeneratePassword","ManualGeneratePassword");

				MobilePhone = new Label("Views.EditUser.MobilePhone","MobilePhone");

				Name = new Label("Views.EditUser.Name","Name");

				NewPassword = new Label("Views.EditUser.NewPassword","NewPassword");

				NewPasswordRepeat = new Label("Views.EditUser.NewPasswordRepeat","NewPasswordRepeat");

				Newsletter = new Label("Views.EditUser.Newsletter","Newsletter");

				NewsMail = new class
				{

Label = new Label("Views.EditUser.NewsMail","NewsMail");

					Heading = new Label("Views.EditUser.NewsMail.Heading","Heading");

					Views_EditUser_NewsMail_Subscribe_Button = new Label("Views.EditUser.NewsMail.Subscribe.Button","Views_EditUser_NewsMail_Subscribe_Button");

					Views_EditUser_NewsMail_Subscribe_ConfirmText = new Label("Views.EditUser.NewsMail.Subscribe.ConfirmText","Views_EditUser_NewsMail_Subscribe_ConfirmText");

					Views_EditUser_NewsMail_Subscribe_Heading = new Label("Views.EditUser.NewsMail.Subscribe.Heading","Views_EditUser_NewsMail_Subscribe_Heading");

					Views_EditUser_NewsMail_Subscribe_Text = new Label("Views.EditUser.NewsMail.Subscribe.Text","Views_EditUser_NewsMail_Subscribe_Text");

					Views_EditUser_NewsMail_Unsubscribe_Button = new Label("Views.EditUser.NewsMail.Unsubscribe.Button","Views_EditUser_NewsMail_Unsubscribe_Button");

					Views_EditUser_NewsMail_Unsubscribe_ConfirmText = new Label("Views.EditUser.NewsMail.Unsubscribe.ConfirmText","Views_EditUser_NewsMail_Unsubscribe_ConfirmText");

					Views_EditUser_NewsMail_Unsubscribe_Heading = new Label("Views.EditUser.NewsMail.Unsubscribe.Heading","Views_EditUser_NewsMail_Unsubscribe_Heading");

					Views_EditUser_NewsMail_Unsubscribe_Text = new Label("Views.EditUser.NewsMail.Unsubscribe.Text","Views_EditUser_NewsMail_Unsubscribe_Text");
				}

				OrderReceipts = new Label("Views.EditUser.OrderReceipts","OrderReceipts");

				OrderReceiptsHeading = new Label("Views.EditUser.OrderReceiptsHeading","OrderReceiptsHeading");

				Password = new Label("Views.EditUser.Password","Password");

				PasswordChanged = new Label("Views.EditUser.PasswordChanged","PasswordChanged");

				Phone = new Label("Views.EditUser.Phone","Phone");

				PhoneNumberMismatch = new Label("Views.EditUser.PhoneNumberMismatch","PhoneNumberMismatch");

				Punchout = new class
				{

Label = new Label("Views.EditUser.Punchout","Punchout");

					Account = new Label("Views.EditUser.Punchout.Account","Account");

					Delete = new Label("Views.EditUser.Punchout.Delete","Delete");

					Developer = new Label("Views.EditUser.Punchout.Developer","Developer");

					DeveloperAccess = new Label("Views.EditUser.Punchout.DeveloperAccess","DeveloperAccess");

					Heading = new Label("Views.EditUser.Punchout.Heading","Heading");

					HelpText = new Label("Views.EditUser.Punchout.HelpText","HelpText");

					NoFile = new Label("Views.EditUser.Punchout.NoFile","NoFile");

					Save = new Label("Views.EditUser.Punchout.Save","Save");

					Text = new Label("Views.EditUser.Punchout.Text","Text");

					Upload = new Label("Views.EditUser.Punchout.Upload","Upload");

					UploadXslt = new Label("Views.EditUser.Punchout.UploadXslt","UploadXslt");

					Validation = new class
					{

Label = new Label("Views.EditUser.Punchout.Validation","Validation");

						AddCustomXsltFailed = new Label("Views.EditUser.Punchout.Validation.AddCustomXsltFailed","AddCustomXsltFailed");

						MissingPunchOutSettings = new Label("Views.EditUser.Punchout.Validation.MissingPunchOutSettings","MissingPunchOutSettings");
					}
				}

				RequestNewPassword = new Label("Views.EditUser.RequestNewPassword","RequestNewPassword");

				Save = new Label("Views.EditUser.Save","Save");

				Saved = new Label("views.EditUser.Saved","Saved");

				SaveNote = new Label("Views.EditUser.SaveNote","SaveNote");

				Tabs = new class
				{

Label = new Label("Views.EditUser.Tabs","Tabs");

					Access = new class
					{

Label = new Label("Views.EditUser.Tabs.Access","Access");

						Account = new Label("Views.EditUser.Tabs.Access.Account","Account");

						AccountAccess = new Label("Views.EditUser.Tabs.Access.AccountAccess","AccountAccess");

						AddAccount = new Label("Views.EditUser.Tabs.Access.AddAccount","AddAccount");

						Level = new class
						{

Label = new Label("Views.EditUser.Tabs.Access.Level","Level");

							Account = new Label("Views.EditUser.Tabs.Access.Level.Account","Account");

							Admin = new Label("Views.EditUser.Tabs.Access.Level.Admin","Admin");

							ChooseAdministratorPlaceholder = new Label("Views.EditUser.Tabs.Access.Level.ChooseAdministratorPlaceholder","ChooseAdministratorPlaceholder");

							HasAccess = new Label("Views.EditUser.Tabs.Access.Level.HasAccess","HasAccess");

							HasBookkeeping = new Label("Views.EditUser.Tabs.Access.Level.HasBookkeeping","HasBookkeeping");

							HasPurchase = new Label("Views.EditUser.Tabs.Access.Level.HasPurchase","HasPurchase");

							HasPurchaseWithApproval = new Label("Views.EditUser.Tabs.Access.Level.HasPurchaseWithApproval","HasPurchaseWithApproval");

							Heading = new Label("Views.EditUser.Tabs.Access.Level.Heading","Heading");

							IsPrimaryAccount = new Label("Views.EditUser.Tabs.Access.Level.IsPrimaryAccount","IsPrimaryAccount");

							Newsletter = new Label("Views.EditUser.Tabs.Access.Level.Newsletter","Newsletter");

							NoAdministratorsAvailable = new Label("Views.EditUser.Tabs.Access.Level.NoAdministratorsAvailable","NoAdministratorsAvailable");

							SelectHeading = new Label("Views.EditUser.Tabs.Access.Level.SelectHeading","SelectHeading");

							User = new Label("Views.EditUser.Tabs.Access.Level.User","User");
						}
					}

					Heading = new Label("Views.EditUser.Tabs.Heading","Heading");

					View = new class
					{

Label = new Label("Views.EditUser.Tabs.View","View");

						Add = new Label("Views.EditUser.Tabs.View.Add","Add");

						AddAccount = new Label("Views.EditUser.Tabs.View.AddAccount","AddAccount");

						ShowBruttoPrices = new Label("Views.EditUser.Tabs.View.ShowBruttoPrices","ShowBruttoPrices");

						ShowNettoPrices = new Label("Views.EditUser.Tabs.View.ShowNettoPrices","ShowNettoPrices");

						ShowPrices = new Label("Views.EditUser.Tabs.View.ShowPrices","ShowPrices");
					}
				}

				TwentyFourSeven = new class
				{

Label = new Label("Views.EditUser.TwentyFourSeven","TwentyFourSeven");

					AccessText = new Label("Views.EditUser.TwentyFourSeven.AccessText","AccessText");

					Checkbox = new Label("Views.EditUser.TwentyFourSeven.Checkbox","Checkbox");

					CheckboxContactSanistaal = new Label("Views.EditUser.TwentyFourSeven.CheckboxContactSanistaal","CheckboxContactSanistaal");

					CheckboxOnlyRemove = new Label("Views.EditUser.TwentyFourSeven.CheckboxOnlyRemove","CheckboxOnlyRemove");

					HasAccess = new Label("Views.EditUser.TwentyFourSeven.HasAccess","HasAccess");

					NoAccess = new Label("Views.EditUser.TwentyFourSeven.NoAccess","NoAccess");
				}

				UnlockUser = new Label("Views.EditUser.UnlockUser","UnlockUser");

				UserCreatedMessage = new Label("Views.EditUser.UserCreatedMessage","UserCreatedMessage");

				UserInfoHeading = new Label("Views.EditUser.UserInfoHeading","UserInfoHeading");

				UserName = new Label("Views.EditUser.UserName","UserName");

				ValidationPasswordLength = new Label("Views.EditUser.ValidationPasswordLength","ValidationPasswordLength");

				ValidationPhoneLength = new Label("Views.EditUser.ValidationPhoneLength","ValidationPhoneLength");

				ValidationPhoneNumberInvalid = new Label("Views.EditUser.ValidationPhoneNumberInvalid","ValidationPhoneNumberInvalid");

				ValidationPhoneNumberLength = new Label("Views.EditUser.ValidationPhoneNumberLength","ValidationPhoneNumberLength");
			}

			Event = new class
			{

Label = new Label("Views.Event","Event");

				Date = new Label("Views.Event.Date","Date");

				SidebarHeading = new Label("Views.Event.SidebarHeading","SidebarHeading");

				Teaser = new class
				{

Label = new Label("Views.Event.Teaser","Teaser");

					Heading = new Label("Views.Event.Teaser.Heading","Heading");

					HeadingAuthenticated = new Label("Views.Event.Teaser.HeadingAuthenticated","HeadingAuthenticated");

					SeeAllLink = new Label("Views.Event.Teaser.SeeAllLink","SeeAllLink");
				}

				Time = new Label("Views.Event.Time","Time");
			}

			FavoriteOrder = new class
			{

Label = new Label("Views.FavoriteOrder","FavoriteOrder");

				Add = new Label("Views.FavoriteOrder.Add","Add");

				AddHeader = new Label("Views.FavoriteOrder.AddHeader","AddHeader");

				AddToExisting = new Label("Views.FavoriteOrder.AddToExisting","AddToExisting");

				AddToNew = new Label("Views.FavoriteOrder.AddToNew","AddToNew");

				AdministrationOverlay = new class
				{

Label = new Label("Views.FavoriteOrder.AdministrationOverlay","AdministrationOverlay");

					AccountGroupListHeadline = new Label("Views.FavoriteOrder.AdministrationOverlay.AccountGroupListHeadline","AccountGroupListHeadline");

					AccountGroupsExplanationBottom = new Label("Views.FavoriteOrder.AdministrationOverlay.AccountGroupsExplanationBottom","AccountGroupsExplanationBottom");

					AccountGroupsExplanationHeadline = new Label("Views.FavoriteOrder.AdministrationOverlay.AccountGroupsExplanationHeadline","AccountGroupsExplanationHeadline");

					AccountGroupsExplanationTop = new Label("Views.FavoriteOrder.AdministrationOverlay.AccountGroupsExplanationTop","AccountGroupsExplanationTop");

					AccountGroupsHeadline = new Label("Views.FavoriteOrder.AdministrationOverlay.AccountGroupsHeadline","AccountGroupsHeadline");

					AccountGroupsOverlayHeadline = new Label("Views.FavoriteOrder.AdministrationOverlay.AccountGroupsOverlayHeadline","AccountGroupsOverlayHeadline");

					AccountGroupsOverlaySubmitButtonText = new Label("Views.FavoriteOrder.AdministrationOverlay.AccountGroupsOverlaySubmitButtonText","AccountGroupsOverlaySubmitButtonText");

					AddAccountGroup = new Label("Views.FavoriteOrder.AdministrationOverlay.AddAccountGroup","AddAccountGroup");

					AddReference = new Label("Views.FavoriteOrder.AdministrationOverlay.AddReference","AddReference");

					CreateGroup = new Label("Views.FavoriteOrder.AdministrationOverlay.CreateGroup","CreateGroup");

					DisclaimerEditable = new Label("Views.FavoriteOrder.AdministrationOverlay.DisclaimerEditable","DisclaimerEditable");

					DisclaimerEditableHasGroups = new Label("Views.FavoriteOrder.AdministrationOverlay.DisclaimerEditableHasGroups","DisclaimerEditableHasGroups");

					DisclaimerNotShared = new Label("Views.FavoriteOrder.AdministrationOverlay.DisclaimerNotShared","DisclaimerNotShared");

					DisclaimerNotSharedHasGroups = new Label("Views.FavoriteOrder.AdministrationOverlay.DisclaimerNotSharedHasGroups","DisclaimerNotSharedHasGroups");

					DisclaimerReadonly = new Label("Views.FavoriteOrder.AdministrationOverlay.DisclaimerReadonly","DisclaimerReadonly");

					DisclaimerReadonlyHasGroups = new Label("Views.FavoriteOrder.AdministrationOverlay.DisclaimerReadonlyHasGroups","DisclaimerReadonlyHasGroups");

					DoNotShare = new Label("Views.FavoriteOrder.AdministrationOverlay.DoNotShare","DoNotShare");

					Edit = new class
					{

Label = new Label("Views.FavoriteOrder.AdministrationOverlay.Edit","Edit");

						Headline = new Label("Views.FavoriteOrder.AdministrationOverlay.Edit.Headline","Headline");

						SubmitText = new Label("Views.FavoriteOrder.AdministrationOverlay.Edit.SubmitText","SubmitText");
					}

					EditableDescription = new Label("Views.FavoriteOrder.AdministrationOverlay.EditableDescription","EditableDescription");

					EditableLabel = new Label("Views.FavoriteOrder.AdministrationOverlay.EditableLabel","EditableLabel");

					FormNameLabel = new Label("Views.FavoriteOrder.AdministrationOverlay.FormNameLabel","FormNameLabel");

					FormNamePlaceholder = new Label("Views.FavoriteOrder.AdministrationOverlay.FormNamePlaceholder","FormNamePlaceholder");

					HideReference = new Label("Views.FavoriteOrder.AdministrationOverlay.HideReference","HideReference");

					NoGroups = new Label("Views.FavoriteOrder.AdministrationOverlay.NoGroups","NoGroups");

					OverlayHeadline = new Label("Views.FavoriteOrder.AdministrationOverlay.OverlayHeadline","OverlayHeadline");

					ReadonlyDescription = new Label("Views.FavoriteOrder.AdministrationOverlay.ReadonlyDescription","ReadonlyDescription");

					ReadonlyLabel = new Label("Views.FavoriteOrder.AdministrationOverlay.ReadonlyLabel","ReadonlyLabel");

					ReferenceHeadline = new Label("Views.FavoriteOrder.AdministrationOverlay.ReferenceHeadline","ReferenceHeadline");

					Share = new Label("Views.FavoriteOrder.AdministrationOverlay.Share","Share");

					SharedHeadline = new Label("Views.FavoriteOrder.AdministrationOverlay.SharedHeadline","SharedHeadline");

					SubmitButtonText = new Label("Views.FavoriteOrder.AdministrationOverlay.SubmitButtonText","SubmitButtonText");
				}

				ChooseList = new class
				{

Label = new Label("Views.FavoriteOrder.ChooseList","ChooseList");

					Postfix = new Label("Views.FavoriteOrder.ChooseList.Postfix","Postfix");
				}

				CopyTo = new Label("Views.FavoriteOrder.CopyTo","CopyTo");

				CreateAndAdd = new Label("Views.FavoriteOrder.CreateAndAdd","CreateAndAdd");

				Created = new Label("Views.FavoriteOrder.Created","Created");

				CreatedBy = new Label("Views.FavoriteOrder.CreatedBy","CreatedBy");

				CreateFirstList = new Label("Views.FavoriteOrder.CreateFirstList","CreateFirstList");

				CreateNew = new Label("Views.FavoriteOrder.CreateNew","CreateNew");

				CreateNewCancel = new Label("Views.FavoriteOrder.CreateNewCancel","CreateNewCancel");

				DeleteConfirm = new Label("Views.FavoriteOrder.DeleteConfirm","DeleteConfirm");

				EditButton = new Label("Views.FavoriteOrder.EditButton","EditButton");

				EditFavoriteOrderView = new class
				{

Label = new Label("Views.FavoriteOrder.EditFavoriteOrderView","EditFavoriteOrderView");

					AddProductButton = new Label("Views.FavoriteOrder.EditFavoriteOrderView.AddProductButton","AddProductButton");

					AddProductText = new Label("Views.FavoriteOrder.EditFavoriteOrderView.AddProductText","AddProductText");

					AddToBasket = new Label("Views.FavoriteOrder.EditFavoriteOrderView.AddToBasket","AddToBasket");

					ConfirmDeleteAll = new Label("Views.FavoriteOrder.EditFavoriteOrderView.ConfirmDeleteAll","ConfirmDeleteAll");

					Copy = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Copy","Copy");

					CopyFavoriteOrder = new Label("Views.FavoriteOrder.EditFavoriteOrderView.CopyFavoriteOrder","CopyFavoriteOrder");

					CopyToFavoriteOrder = new Label("Views.FavoriteOrder.EditFavoriteOrderView.CopyToFavoriteOrder","CopyToFavoriteOrder");

					Create = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Create","Create");

					DeleteAll = new Label("Views.FavoriteOrder.EditFavoriteOrderView.DeleteAll","DeleteAll");

					DeleteList = new Label("Views.FavoriteOrder.EditFavoriteOrderView.DeleteList","DeleteList");

					Download = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Download","Download");

					Export = new class
					{

Label = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export","Export");

						Afregningsenhed = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.Afregningsenhed","Afregningsenhed");

						AfregningsenhedAntal = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.AfregningsenhedAntal","AfregningsenhedAntal");

						Antal = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.Antal","Antal");

						BruttoPerAfregningsenhed = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.BruttoPerAfregningsenhed","BruttoPerAfregningsenhed");

						BruttoprisIalt = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.BruttoprisIalt","BruttoprisIalt");

						BruttoprisPerStk = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.BruttoprisPerStk","BruttoprisPerStk");

						Enhed = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.Enhed","Enhed");

						Features = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.Features","Features");

						NettoPerAfregningsenhed = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.NettoPerAfregningsenhed","NettoPerAfregningsenhed");

						NettoprisIalt = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.NettoprisIalt","NettoprisIalt");

						NettoprisPerStk = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.NettoprisPerStk","NettoprisPerStk");

						SaniNr = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.SaniNr","SaniNr");

						VareNavn = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.VareNavn","VareNavn");

						VvsNr = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Export.VvsNr","VvsNr");
					}

					FormLabelCaseIdShared = new Label("Views.FavoriteOrder.EditFavoriteOrderView.FormLabelCaseIdShared","FormLabelCaseIdShared");

					FormLabelName = new Label("Views.FavoriteOrder.EditFavoriteOrderView.FormLabelName","FormLabelName");

					FormLabelReferenceId = new Label("Views.FavoriteOrder.EditFavoriteOrderView.FormLabelReferenceId","FormLabelReferenceId");

					FormLabelRequestId = new Label("Views.FavoriteOrder.EditFavoriteOrderView.FormLabelRequestId","FormLabelRequestId");

					FormLabelShared = new Label("Views.FavoriteOrder.EditFavoriteOrderView.FormLabelShared","FormLabelShared");

					NoMatch = new Label("Views.FavoriteOrder.EditFavoriteOrderView.NoMatch","NoMatch");

					PrintList = new Label("Views.FavoriteOrder.EditFavoriteOrderView.PrintList","PrintList");

					QuickOrderTitle = new Label("Views.FavoriteOrder.EditFavoriteOrderView.QuickOrderTitle","QuickOrderTitle");

					Save = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Save","Save");

					Search = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Search","Search");

					SearchPlaceholder = new Label("Views.FavoriteOrder.EditFavoriteOrderView.SearchPlaceholder","SearchPlaceholder");

					TableHeaderDescription = new Label("Views.FavoriteOrder.EditFavoriteOrderView.TableHeaderDescription","TableHeaderDescription");

					Update = new Label("Views.FavoriteOrder.EditFavoriteOrderView.Update","Update");

					UpdateLine = new Label("Views.FavoriteOrder.EditFavoriteOrderView.UpdateLine","UpdateLine");
				}

				ExistingList = new Label("Views.FavoriteOrder.ExistingList","ExistingList");

				Filter = new class
				{

Label = new Label("Views.FavoriteOrder.Filter","Filter");

					FilterBy = new Label("Views.FavoriteOrder.Filter.FilterBy","FilterBy");

					Options = new class
					{

Label = new Label("Views.FavoriteOrder.Filter.Options","Options");

						All = new Label("Views.FavoriteOrder.Filter.Options.All","All");

						Expired = new Label("Views.FavoriteOrder.Filter.Options.Expired","Expired");
					}
				}

				Groups = new Label("Views.FavoriteOrder.Groups","Groups");

				Header = new class
				{

Label = new Label("Views.FavoriteOrder.Header","Header");

					Created = new Label("Views.FavoriteOrder.Header.Created","Created");

					Edit = new Label("Views.FavoriteOrder.Header.Edit","Edit");

					Groups = new Label("Views.FavoriteOrder.Header.Groups","Groups");

					ProductsTextMultiple = new Label("Views.FavoriteOrder.Header.ProductsTextMultiple","ProductsTextMultiple");

					ProductsTextSingle = new Label("Views.FavoriteOrder.Header.ProductsTextSingle","ProductsTextSingle");

					Shared = new class
					{

Label = new Label("Views.FavoriteOrder.Header.Shared","Shared");

						AllowEdit = new Label("Views.FavoriteOrder.Header.Shared.AllowEdit","AllowEdit");

						ReadOnly = new Label("Views.FavoriteOrder.Header.Shared.ReadOnly","ReadOnly");
					}
				}

				Import = new Label("Views.FavoriteOrder.Import","Import");

				ImportFavoriteOrderView = new class
				{

Label = new Label("Views.FavoriteOrder.ImportFavoriteOrderView","ImportFavoriteOrderView");

					BackButton = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.BackButton","BackButton");

					FileSelect = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.FileSelect","FileSelect");

					HelpHeading = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.HelpHeading","HelpHeading");

					ImportButton = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.ImportButton","ImportButton");

					ImportComplete = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.ImportComplete","ImportComplete");

					MergeDropDownTip = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.MergeDropDownTip","MergeDropDownTip");

					MergeRadioButtonText = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.MergeRadioButtonText","MergeRadioButtonText");

					New = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.New","New");

					NewRadioButtonLabel = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.NewRadioButtonLabel","NewRadioButtonLabel");

					NewRadioButtonText = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.NewRadioButtonText","NewRadioButtonText");

					NewRadioButtonTip = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.NewRadioButtonTip","NewRadioButtonTip");

					OverwriteDropDownTip = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.OverwriteDropDownTip","OverwriteDropDownTip");

					OverwriteRadioButtonText = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.OverwriteRadioButtonText","OverwriteRadioButtonText");

					PartialImport = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.PartialImport","PartialImport");

					RequirementsExplanation = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.RequirementsExplanation","RequirementsExplanation");

					SelectListDefaultValue = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.SelectListDefaultValue","SelectListDefaultValue");

					Title = new Label("Views.FavoriteOrder.ImportFavoriteOrderView.Title","Title");
				}

				IsNotShared = new Label("Views.FavoriteOrder.IsNotShared","IsNotShared");

				IsShared = new Label("Views.FavoriteOrder.IsShared","IsShared");

				ItemsAdded = new Label("Views.FavoriteOrder.ItemsAdded","ItemsAdded");

				Lines = new Label("Views.FavoriteOrder.Lines","Lines");

				Lists = new Label("Views.FavoriteOrder.Lists","Lists");

				Mismatch = new class
				{

Label = new Label("Views.FavoriteOrder.Mismatch","Mismatch");

					Convert = new Label("Views.FavoriteOrder.Mismatch.Convert","Convert");

					Header = new Label("Views.FavoriteOrder.Mismatch.Header","Header");

					SavedUnit = new Label("Views.FavoriteOrder.Mismatch.SavedUnit","SavedUnit");

					ValidUnits = new Label("Views.FavoriteOrder.Mismatch.ValidUnits","ValidUnits");
				}

				Name = new Label("Views.FavoriteOrder.Name","Name");

				New = new Label("Views.FavoriteOrder.New","New");

				NewList = new Label("Views.FavoriteOrder.NewList","NewList");

				NewName = new Label("Views.FavoriteOrder.NewName","NewName");

				NotSharedSummary = new Label("Views.FavoriteOrder.NotSharedSummary","NotSharedSummary");

				NumberOfLines = new Label("Views.FavoriteOrder.NumberOfLines","NumberOfLines");

				NumberOfLinesLong = new Label("Views.FavoriteOrder.NumberOfLinesLong","NumberOfLinesLong");

				ProductLines = new Label("Views.FavoriteOrder.ProductLines","ProductLines");

				ProductVersion = new Label("Views.FavoriteOrder.ProductVersion","ProductVersion");

				QuickOrderHeader = new Label("Views.FavoriteOrder.QuickOrderHeader","QuickOrderHeader");

				Remove = new class
				{

Label = new Label("Views.FavoriteOrder.Remove","Remove");

					Confirm = new Label("Views.FavoriteOrder.Remove.Confirm","Confirm");

					Text = new Label("Views.FavoriteOrder.Remove.Text","Text");
				}

				RemoveExistingProducts = new Label("Views.FavoriteOrder.RemoveExistingProducts","RemoveExistingProducts");

				Search = new Label("Views.FavoriteOrder.Search","Search");

				Shared = new class
				{

Label = new Label("Views.FavoriteOrder.Shared","Shared");

					AllowEdit = new Label("Views.FavoriteOrder.Shared.AllowEdit","AllowEdit");

					ReadOnly = new Label("Views.FavoriteOrder.Shared.ReadOnly","ReadOnly");

					SharedText = new Label("Views.FavoriteOrder.Shared.SharedText","SharedText");
				}

				SharedLong = new Label("Views.FavoriteOrder.SharedLong","SharedLong");

				SharedSummary = new Label("Views.FavoriteOrder.SharedSummary","SharedSummary");

				ShowAll = new Label("Views.FavoriteOrder.ShowAll","ShowAll");

				Sorting = new class
				{

Label = new Label("Views.FavoriteOrder.Sorting","Sorting");

					CommonId = new Label("Views.FavoriteOrder.Sorting.CommonId","CommonId");

					CommonIdZA = new Label("Views.FavoriteOrder.Sorting.CommonIdZA","CommonIdZA");

					DefaultSort = new Label("Views.FavoriteOrder.Sorting.DefaultSort","DefaultSort");

					DefaultSortZA = new Label("Views.FavoriteOrder.Sorting.DefaultSortZA","DefaultSortZA");

					DisplayName = new Label("Views.FavoriteOrder.Sorting.DisplayName","DisplayName");

					DisplayNameZA = new Label("Views.FavoriteOrder.Sorting.DisplayNameZA","DisplayNameZA");

					Id = new Label("Views.FavoriteOrder.Sorting.Id","Id");

					IdZA = new Label("Views.FavoriteOrder.Sorting.IdZA","IdZA");

					ProductNumber = new Label("Views.FavoriteOrder.Sorting.ProductNumber","ProductNumber");

					ProductNumberZA = new Label("Views.FavoriteOrder.Sorting.ProductNumberZA","ProductNumberZA");

					VvsIdSort = new Label("Views.FavoriteOrder.Sorting.VvsIdSort","VvsIdSort");
				}

				Validation = new class
				{

Label = new Label("Views.FavoriteOrder.Validation","Validation");

					AddLineNotAllowedBySalesAccount = new Label("Views.FavoriteOrder.Validation.AddLineNotAllowedBySalesAccount","AddLineNotAllowedBySalesAccount");

					AddLineNotAllowedByUser = new Label("Views.FavoriteOrder.Validation.AddLineNotAllowedByUser","AddLineNotAllowedByUser");

					AddNotAllowed = new Label("Views.FavoriteOrder.Validation.AddNotAllowed","AddNotAllowed");

					CopyFromNotAllowedForSalesAccount = new Label("Views.FavoriteOrder.Validation.CopyFromNotAllowedForSalesAccount","CopyFromNotAllowedForSalesAccount");

					CopyFromNotAllowedForUser = new Label("Views.FavoriteOrder.Validation.CopyFromNotAllowedForUser","CopyFromNotAllowedForUser");

					CopyToNotAllowedForSalesAccount = new Label("Views.FavoriteOrder.Validation.CopyToNotAllowedForSalesAccount","CopyToNotAllowedForSalesAccount");

					CopyToNotAllowedForUser = new Label("Views.FavoriteOrder.Validation.CopyToNotAllowedForUser","CopyToNotAllowedForUser");

					DeleteAllItemsNotAllowed = new Label("Views.FavoriteOrder.Validation.DeleteAllItemsNotAllowed","DeleteAllItemsNotAllowed");

					DeleteLineNotAllowed = new Label("Views.FavoriteOrder.Validation.DeleteLineNotAllowed","DeleteLineNotAllowed");

					DeleteNotAllowed = new Label("Views.FavoriteOrder.Validation.DeleteNotAllowed","DeleteNotAllowed");

					NotFound = new Label("Views.FavoriteOrder.Validation.NotFound","NotFound");

					Required = new Label("Views.FavoriteOrder.Validation.Required","Required");

					UpdateLineNotAllowedBySalesAccount = new Label("Views.FavoriteOrder.Validation.UpdateLineNotAllowedBySalesAccount","UpdateLineNotAllowedBySalesAccount");

					UpdateLineNotAllowedByUser = new Label("Views.FavoriteOrder.Validation.UpdateLineNotAllowedByUser","UpdateLineNotAllowedByUser");

					UpdateNotAllowed = new Label("Views.FavoriteOrder.Validation.UpdateNotAllowed","UpdateNotAllowed");

					ViewNotAllowed = new Label("Views.FavoriteOrder.Validation.ViewNotAllowed","ViewNotAllowed");
				}
			}

			FavoriteOrderSelector = new class
			{

Label = new Label("Views.FavoriteOrderSelector","FavoriteOrderSelector");

				FormLabelAccountNumber = new Label("Views.FavoriteOrderSelector.FormLabelAccountNumber","FormLabelAccountNumber");

				FormLabelAddToList = new Label("Views.FavoriteOrderSelector.FormLabelAddToList","FormLabelAddToList");

				FormLabelCreate = new Label("Views.FavoriteOrderSelector.FormLabelCreate","FormLabelCreate");

				FormLabelFavoriteListName = new Label("Views.FavoriteOrderSelector.FormLabelFavoriteListName","FormLabelFavoriteListName");

				FormLabelOverrideList = new Label("Views.FavoriteOrderSelector.FormLabelOverrideList","FormLabelOverrideList");

				FormTextBoxDefaultListName = new Label("Views.FavoriteOrderSelector.FormTextBoxDefaultListName","FormTextBoxDefaultListName");

				MergeFavoriteOrder = new Label("Views.FavoriteOrderSelector.MergeFavoriteOrder","MergeFavoriteOrder");

				NameOfNewFavoriteListIsNotUnique = new Label("Views.FavoriteOrderSelector.NameOfNewFavoriteListIsNotUnique","NameOfNewFavoriteListIsNotUnique");

				NameOfNewFavoriteListIsRequired = new Label("Views.FavoriteOrderSelector.NameOfNewFavoriteListIsRequired","NameOfNewFavoriteListIsRequired");

				OverrideFavoriteOrder = new Label("Views.FavoriteOrderSelector.OverrideFavoriteOrder","OverrideFavoriteOrder");

				PageHeader = new Label("Views.FavoriteOrderSelector.PageHeader","PageHeader");
			}

			FlexBox = new class
			{

Label = new Label("Views.FlexBox","FlexBox");

				ActivateBasket = new Label("Views.FlexBox.ActivateBasket","ActivateBasket");

				Active = new Label("Views.FlexBox.Active","Active");

				Basket = new Label("Views.FlexBox.Basket","Basket");

				ChangeAccount = new class
				{

Label = new Label("Views.FlexBox.ChangeAccount","ChangeAccount");

					Button = new Label("Views.FlexBox.ChangeAccount.Button","Button");

					Header = new Label("Views.FlexBox.ChangeAccount.Header","Header");
				}

				Contact = new Label("Views.FlexBox.Contact","Contact");

				ContactPerson = new Label("Views.FlexBox.ContactPerson","ContactPerson");

				ContinueWithFlexBox = new Label("Views.FlexBox.ContinueWithFlexBox","ContinueWithFlexBox");

				Delivery = new Label("Views.FlexBox.Delivery","Delivery");

				DeliveryInOneHour = new Label("Views.FlexBox.DeliveryInOneHour","DeliveryInOneHour");

				DeliveryInOneHourTo = new Label("Views.FlexBox.DeliveryInOneHourTo","DeliveryInOneHourTo");

				DeliveryTo = new Label("Views.FlexBox.DeliveryTo","DeliveryTo");

				ForBiggerSelection = new Label("Views.FlexBox.ForBiggerSelection","ForBiggerSelection");

				ForFlexBox = new Label("Views.FlexBox.ForFlexBox","ForFlexBox");

				ForFlexBoxDeliveryIn = new Label("Views.FlexBox.ForFlexBoxDeliveryIn","ForFlexBoxDeliveryIn");

				ForFlexBoxIn = new Label("Views.FlexBox.ForFlexBoxIn","ForFlexBoxIn");

				InFlexBox = new Label("Views.FlexBox.InFlexBox","InFlexBox");

				InfoTextLong = new Label("Views.FlexBox.InfoTextLong","InfoTextLong");

				InfoTextShort = new Label("Views.FlexBox.InfoTextShort","InfoTextShort");

				MobileNumber = new Label("Views.FlexBox.MobileNumber","MobileNumber");

				NoHitsBody = new Label("Views.FlexBox.NoHitsBody","NoHitsBody");

				OrderDeliveryInOneHour = new Label("Views.FlexBox.OrderDeliveryInOneHour","OrderDeliveryInOneHour");

				ReadMore = new Label("Views.FlexBox.ReadMore","ReadMore");

				SelectSapAddress = new Label("Views.FlexBox.SelectSapAddress","SelectSapAddress");

				SpecifyAddress = new Label("Views.FlexBox.SpecifyAddress","SpecifyAddress");

				Start = new Label("Views.FlexBox.Start","Start");

				StartWarning1 = new Label("Views.FlexBox.StartWarning1","StartWarning1");

				StartWarning2 = new Label("Views.FlexBox.StartWarning2","StartWarning2");

				StockStatusFor = new Label("Views.FlexBox.StockStatusFor","StockStatusFor");

				StopConfirmation = new Label("Views.FlexBox.StopConfirmation","StopConfirmation");

				StopWarning = new Label("Views.FlexBox.StopWarning","StopWarning");

				Text = new Label("Views.FlexBox.Text","Text");

				TimeSpecific = new class
				{

Label = new Label("Views.FlexBox.TimeSpecific","TimeSpecific");

					DeliveryInTo = new Label("Views.FlexBox.TimeSpecific.DeliveryInTo","DeliveryInTo");

					ForDeliveryIn = new Label("Views.FlexBox.TimeSpecific.ForDeliveryIn","ForDeliveryIn");

					Hours = new Label("Views.FlexBox.TimeSpecific.Hours","Hours");

					InfoTextLong = new Label("Views.FlexBox.TimeSpecific.InfoTextLong","InfoTextLong");

					InfoTextShort = new Label("Views.FlexBox.TimeSpecific.InfoTextShort","InfoTextShort");

					OneHour = new Label("Views.FlexBox.TimeSpecific.OneHour","OneHour");

					OrderDeliveryIn = new Label("Views.FlexBox.TimeSpecific.OrderDeliveryIn","OrderDeliveryIn");
				}

				TryTo = new Label("Views.FlexBox.TryTo","TryTo");

				UseAddress = new Label("Views.FlexBox.UseAddress","UseAddress");

				UseNormalBasket = new Label("Views.FlexBox.UseNormalBasket","UseNormalBasket");
			}

			Footer = new class
			{

Label = new Label("Views.Footer","Footer");

				Anonymous = new class
				{

Label = new Label("Views.Footer.Anonymous","Anonymous");

					ContactHqHtml = new Label("Views.Footer.Anonymous.ContactHqHtml","ContactHqHtml");

					Header = new Label("Views.Footer.Anonymous.Header","Header");
				}

				ChatCampaign = new Label("Views.Footer.ChatCampaign","ChatCampaign");

				ChatCampaignToolTip = new Label("Views.Footer.ChatCampaignToolTip","ChatCampaignToolTip");

				ConcernText = new Label("Views.Footer.ConcernText","ConcernText");

				MySanistaal = new class
				{

Label = new Label("Views.Footer.MySanistaal","MySanistaal");

					AllStoresLink = new Label("Views.Footer.MySanistaal.AllStoresLink","AllStoresLink");

					Consultant = new class
					{

Label = new Label("Views.Footer.MySanistaal.Consultant","Consultant");

						Contact = new Label("Views.Footer.MySanistaal.Consultant.Contact","Contact");

						Email = new Label("Views.Footer.MySanistaal.Consultant.Email","Email");

						MobilePhone = new Label("Views.Footer.MySanistaal.Consultant.MobilePhone","MobilePhone");

						Phone = new Label("Views.Footer.MySanistaal.Consultant.Phone","Phone");
					}

					Heading = new Label("Views.Footer.MySanistaal.Heading","Heading");

					HideNetPrices = new Label("Views.Footer.MySanistaal.HideNetPrices","HideNetPrices");

					MyContact = new class
					{

Label = new Label("Views.Footer.MySanistaal.MyContact","MyContact");

						Email = new Label("views.Footer.MySanistaal.MyContact.Email","Email");

						MobilePhone = new Label("Views.Footer.MySanistaal.MyContact.MobilePhone","MobilePhone");

						Phone = new Label("Views.Footer.MySanistaal.MyContact.Phone","Phone");
					}

					MyStore = new class
					{

Label = new Label("Views.Footer.MySanistaal.MyStore","MyStore");

						Store = new Label("Views.Footer.MySanistaal.MyStore.Store","Store");

						Text = new Label("Views.Footer.MySanistaal.MyStore.Text","Text");
					}

					OtherContactsLink = new Label("Views.Footer.MySanistaal.OtherContactsLink","OtherContactsLink");

					Phone = new Label("Views.Footer.MySanistaal.Phone","Phone");

					ShowNetPrices = new Label("Views.Footer.MySanistaal.ShowNetPrices","ShowNetPrices");

					Store = new Label("Views.Footer.MySanistaal.Store","Store");
				}

				NewsletterLink = new Label("Views.Footer.NewsletterLink","NewsletterLink");

				Social = new class
				{

Label = new Label("Views.Footer.Social","Social");

					Facebook = new Label("Views.Footer.Social.Facebook","Facebook");

					GooglePlus = new Label("Views.Footer.Social.GooglePlus","GooglePlus");

					LinkedIn = new Label("Views.Footer.Social.LinkedIn","LinkedIn");

					Youtube = new Label("Views.Footer.Social.Youtube","Youtube");
				}
			}

			ForgotPassword = new class
			{

Label = new Label("Views.ForgotPassword","ForgotPassword");

				Email = new Label("Views.ForgotPassword.Email","Email");

				MultipleUsersWithEmailAddress = new Label("Views.ForgotPassword.MultipleUsersWithEmailAddress","MultipleUsersWithEmailAddress");

				SendButton = new Label("Views.ForgotPassword.SendButton","SendButton");

				UserName = new Label("Views.ForgotPassword.UserName","UserName");

				Validation = new class
				{

Label = new Label("Views.ForgotPassword.Validation","Validation");

					MultipleUsersWithThisEmailAddress = new Label("Views.ForgotPassword.Validation.MultipleUsersWithThisEmailAddress","MultipleUsersWithThisEmailAddress");

					NoUserWithEmail = new Label("Views.ForgotPassword.Validation.NoUserWithEmail","NoUserWithEmail");

					UserEmailDoesntMatchWithUsername = new Label("Views.ForgotPassword.Validation.UserEmailDoesntMatchWithUsername","UserEmailDoesntMatchWithUsername");

					UsernameDoesNotExist = new Label("Views.ForgotPassword.Validation.UsernameDoesNotExist","UsernameDoesNotExist");

					UserWithThisEmailAddressDoesNotExist = new Label("Views.ForgotPassword.Validation.UserWithThisEmailAddressDoesNotExist","UserWithThisEmailAddressDoesNotExist");
				}
			}

			Frontpage = new class
			{

Label = new Label("Views.Frontpage","Frontpage");

				Title = new Label("Views.Frontpage.Title","Title");
			}

			General = new class
			{

Label = new Label("Views.General","General");

				Cancel = new Label("Views.General.Cancel","Cancel");

				ClearField = new Label("Views.General.ClearField","ClearField");

				Close = new Label("Views.General.Close","Close");

				ContextMenu = new Label("Views.General.ContextMenu","ContextMenu");

				Ok = new Label("Views.General.Ok","Ok");

				ReadLess = new Label("Views.General.ReadLess","ReadLess");

				ReadMore = new Label("Views.General.ReadMore","ReadMore");
			}

			GroupAdministration = new class
			{

Label = new Label("Views.GroupAdministration","GroupAdministration");

				Amount = new Label("Views.GroupAdministration.Amount","Amount");

				CreateGroup = new Label("Views.GroupAdministration.CreateGroup","CreateGroup");

				Delete = new class
				{

Label = new Label("Views.GroupAdministration.Delete","Delete");

					DeleteConfirm = new Label("Views.GroupAdministration.Delete.DeleteConfirm","DeleteConfirm");
				}

				Edit = new Label("Views.GroupAdministration.Edit","Edit");

				Group = new Label("Views.GroupAdministration.Group","Group");
			}

			Header = new class
			{

Label = new Label("Views.Header","Header");

				AllProducts = new Label("views.Header.AllProducts","AllProducts");

				AuthenticationToggle = new class
				{

Label = new Label("Views.Header.AuthenticationToggle","AuthenticationToggle");

					Flexbox = new Label("Views.Header.AuthenticationToggle.Flexbox","Flexbox");

					MyBasket = new Label("Views.Header.AuthenticationToggle.MyBasket","MyBasket");
				}

				CreateUser = new Label("Views.Header.CreateUser","CreateUser");

				GoToShopButton = new class
				{

Label = new Label("Views.Header.GoToShopButton","GoToShopButton");

					Default = new Label("Views.Header.GoToShopButton.Default","Default");

					Small = new Label("Views.Header.GoToShopButton.Small","Small");
				}

				LoginButton = new class
				{

Label = new Label("Views.Header.LoginButton","LoginButton");

					Small = new Label("Views.Header.LoginButton.Small","Small");

					ToShop = new Label("Views.Header.LoginButton.ToShop","ToShop");
				}

				MiniBasket = new class
				{

Label = new Label("Views.Header.MiniBasket","MiniBasket");

					GrossTotal = new Label("Views.Header.MiniBasket.GrossTotal","GrossTotal");

					NetTotal = new Label("Views.Header.MiniBasket.NetTotal","NetTotal");
				}

				ScanBarcode = new Label("Views.Header.ScanBarcode","ScanBarcode");

				Search = new class
				{
					Label = new Label("Views.Header.Search","Search");

					Field = new class
					{

Label = new Label("Views.Header.Search.Field","Field");

						ButtonText = new Label("Views.Header.Search.Field.ButtonText","ButtonText");

						PlaceholderText = new Label("Views.Header.Search.Field.PlaceholderText","PlaceholderText");

						PlaceholderTextLeft = new Label("Views.Header.Search.Field.PlaceholderTextLeft","PlaceholderTextLeft");

						PlaceholderTextMobile = new Label("Views.Header.Search.Field.PlaceholderTextMobile","PlaceholderTextMobile");

						PlaceholderTextRight = new Label("Views.Header.Search.Field.PlaceholderTextRight","PlaceholderTextRight");
					}

					Suggest = new class
					{

Label = new Label("Views.Header.Search.Suggest","Suggest");

						BrandsWidth = new Label("Views.Header.Search.Suggest.BrandsWidth","BrandsWidth");

						CategoriesWith = new Label("Views.Header.Search.Suggest.CategoriesWith","CategoriesWith");

						CategoryHeader = new class
						{

Label = new Label("Views.Header.Search.Suggest.CategoryHeader","CategoryHeader");

							NoCategoryFound = new Label("Views.Header.Search.Suggest.CategoryHeader.NoCategoryFound","NoCategoryFound");

							Postfix = new Label("Views.Header.Search.Suggest.CategoryHeader.Postfix","Postfix");

							Prefix = new Label("Views.Header.Search.Suggest.CategoryHeader.Prefix","Prefix");
						}

						CategoryWith = new Label("Views.Header.Search.Suggest.CategoryWith","CategoryWith");

						ProductsHeader = new Label("Views.Header.Search.Suggest.ProductsHeader","ProductsHeader");

						TermsHeader = new Label("Views.Header.Search.Suggest.TermsHeader","TermsHeader");

						ViewAll = new Label("Views.Header.Search.Suggest.ViewAll","ViewAll");

						ViewAllProducts = new Label("Views.Header.Search.Suggest.ViewAllProducts","ViewAllProducts");
					}
				}

				UserMenu = new class
				{
					Label = new Label("Views.Header.UserMenu","UserMenu");

					Accounts = new class
					{

Label = new Label("Views.Header.UserMenu.Accounts","Accounts");

						Search = new Label("Views.Header.UserMenu.Accounts.Search","Search");

						ShowSoldToIdAccountNames = new Label("Views.Header.UserMenu.Accounts.ShowSoldToIdAccountNames","ShowSoldToIdAccountNames");
					}

					DeliveryNotes = new class
					{

Label = new Label("Views.Header.UserMenu.DeliveryNotes","DeliveryNotes");

						Latest = new Label("Views.Header.UserMenu.DeliveryNotes.Latest","Latest");

						NoResults = new Label("Views.Header.UserMenu.DeliveryNotes.NoResults","NoResults");

						SeeAll = new Label("Views.Header.UserMenu.DeliveryNotes.SeeAll","SeeAll");
					}

					FavoriteOrders = new class
					{

Label = new Label("Views.Header.UserMenu.FavoriteOrders","FavoriteOrders");

						Latest = new Label("Views.Header.UserMenu.FavoriteOrders.Latest","Latest");

						NoResults = new Label("Views.Header.UserMenu.FavoriteOrders.NoResults","NoResults");

						SeeAll = new Label("Views.Header.UserMenu.FavoriteOrders.SeeAll","SeeAll");
					}

					LatestDeliveryNotes = new class
					{

Label = new Label("Views.Header.UserMenu.LatestDeliveryNotes","LatestDeliveryNotes");

						DeliveryDate = new Label("Views.Header.UserMenu.LatestDeliveryNotes.DeliveryDate","DeliveryDate");

						Text = new Label("Views.Header.UserMenu.LatestDeliveryNotes.Text","Text");
					}

					Orders = new class
					{

Label = new Label("Views.Header.UserMenu.Orders","Orders");

						Latest = new Label("Views.Header.UserMenu.Orders.Latest","Latest");

						NoResults = new Label("Views.Header.UserMenu.Orders.NoResults","NoResults");

						SeeAll = new Label("Views.Header.UserMenu.Orders.SeeAll","SeeAll");
					}
				}
			}

			Helpdesk = new class
			{

Label = new Label("Views.Helpdesk","Helpdesk");

				AlreadyImpersonating = new Label("Views.Helpdesk.AlreadyImpersonating","AlreadyImpersonating");

				Back = new Label("Views.Helpdesk.Back","Back");

				ChangeContactPerson = new class
				{

Label = new Label("Views.Helpdesk.ChangeContactPerson","ChangeContactPerson");

					AddToList = new Label("Views.Helpdesk.ChangeContactPerson.AddToList","AddToList");

					ChooseCsvFile = new Label("Views.Helpdesk.ChangeContactPerson.ChooseCsvFile","ChooseCsvFile");

					CopyContactsHeader = new Label("Views.Helpdesk.ChangeContactPerson.CopyContactsHeader","CopyContactsHeader");

					CopyContactsToAccounts = new Label("Views.Helpdesk.ChangeContactPerson.CopyContactsToAccounts","CopyContactsToAccounts");

					ImportContactsHeader = new Label("Views.Helpdesk.ChangeContactPerson.ImportContactsHeader","ImportContactsHeader");

					ImportHelp = new Label("Views.Helpdesk.ChangeContactPerson.ImportHelp","ImportHelp");

					ImportViaCsv = new Label("Views.Helpdesk.ChangeContactPerson.ImportViaCsv","ImportViaCsv");

					ListOfAccounts = new Label("Views.Helpdesk.ChangeContactPerson.ListOfAccounts","ListOfAccounts");
				}

				EditAccount = new class
				{

Label = new Label("Views.Helpdesk.EditAccount","EditAccount");

					AdministerProjectFolder = new class
					{

Label = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder","AdministerProjectFolder");

						ChooseFile = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.ChooseFile","ChooseFile");

						EditOffer = new class
						{

Label = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditOffer","EditOffer");

							NoOffersFound = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditOffer.NoOffersFound","NoOffersFound");

							Text = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditOffer.Text","Text");

							UploadOffer = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditOffer.UploadOffer","UploadOffer");
						}

						EditProductCatalog = new class
						{

Label = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditProductCatalog","EditProductCatalog");

							NoProductCatalogsFound = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditProductCatalog.NoProductCatalogsFound","NoProductCatalogsFound");

							Text = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditProductCatalog.Text","Text");

							UploadProductCatalog = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditProductCatalog.UploadProductCatalog","UploadProductCatalog");
						}

						EditProjectAgreements = new class
						{

Label = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditProjectAgreements","EditProjectAgreements");

							NoAgreementFound = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditProjectAgreements.NoAgreementFound","NoAgreementFound");

							Text = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditProjectAgreements.Text","Text");

							UploadProjectAgreement = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditProjectAgreements.UploadProjectAgreement","UploadProjectAgreement");
						}

						EditUsefullLinks = new class
						{

Label = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditUsefullLinks","EditUsefullLinks");

							CreateNewLink = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditUsefullLinks.CreateNewLink","CreateNewLink");

							NoLinkFound = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditUsefullLinks.NoLinkFound","NoLinkFound");

							Text = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.EditUsefullLinks.Text","Text");
						}

						FileTitle = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.FileTitle","FileTitle");

						Link = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.Link","Link");

						LinkTitle = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.LinkTitle","LinkTitle");

						Text = new Label("Views.Helpdesk.EditAccount.AdministerProjectFolder.Text","Text");
					}

					CreateUser = new class
					{

Label = new Label("Views.Helpdesk.EditAccount.CreateUser","CreateUser");

						Created = new Label("Views.Helpdesk.EditAccount.CreateUser.Created","Created");

						Heading = new Label("Views.Helpdesk.EditAccount.CreateUser.Heading","Heading");

						Text = new Label("Views.Helpdesk.EditAccount.CreateUser.Text","Text");
					}

					SearchAccounts = new class
					{

Label = new Label("Views.Helpdesk.EditAccount.SearchAccounts","SearchAccounts");

						AccountList = new class
						{

Label = new Label("Views.Helpdesk.EditAccount.SearchAccounts.AccountList","AccountList");

							AccountDescription = new Label("Views.Helpdesk.EditAccount.SearchAccounts.AccountList.AccountDescription","AccountDescription");

							AccountNumber = new Label("Views.Helpdesk.EditAccount.SearchAccounts.AccountList.AccountNumber","AccountNumber");

							AddToFavoriteAccounts = new Label("Views.Helpdesk.EditAccount.SearchAccounts.AccountList.AddToFavoriteAccounts","AddToFavoriteAccounts");

							Admins = new Label("Views.Helpdesk.EditAccount.SearchAccounts.AccountList.Admins","Admins");

							MainAccount = new Label("Views.Helpdesk.EditAccount.SearchAccounts.AccountList.MainAccount","MainAccount");

							Name = new Label("Views.Helpdesk.EditAccount.SearchAccounts.AccountList.Name","Name");

							PhoneNumber = new Label("Views.Helpdesk.EditAccount.SearchAccounts.AccountList.PhoneNumber","PhoneNumber");
						}

						AccountNumber = new Label("Views.Helpdesk.EditAccount.SearchAccounts.AccountNumber","AccountNumber");

						FreeText = new Label("Views.Helpdesk.EditAccount.SearchAccounts.FreeText","FreeText");

						Header = new Label("Views.Helpdesk.EditAccount.SearchAccounts.Header","Header");

						InsertAccountNumber = new Label("Views.Helpdesk.EditAccount.SearchAccounts.InsertAccountNumber","InsertAccountNumber");

						InsertUsername = new Label("Views.Helpdesk.EditAccount.SearchAccounts.InsertUsername","InsertUsername");

						Search = new Label("Views.Helpdesk.EditAccount.SearchAccounts.Search","Search");
					}

					SeeAllUsersOnAccount = new Label("Views.Helpdesk.EditAccount.SeeAllUsersOnAccount","SeeAllUsersOnAccount");

					Text = new Label("Views.Helpdesk.EditAccount.Text","Text");

					UpdateAllowNewUsersFromFrontpage = new class
					{

Label = new Label("Views.Helpdesk.EditAccount.UpdateAllowNewUsersFromFrontpage","UpdateAllowNewUsersFromFrontpage");

						Account = new Label("Views.Helpdesk.EditAccount.UpdateAllowNewUsersFromFrontpage.Account","Account");

						AccountText = new Label("Views.Helpdesk.EditAccount.UpdateAllowNewUsersFromFrontpage.AccountText","AccountText");

						AllowCreateFromFrontpage = new Label("Views.Helpdesk.EditAccount.UpdateAllowNewUsersFromFrontpage.AllowCreateFromFrontpage","AllowCreateFromFrontpage");

						CreateUserFromFrontPage = new Label("Views.Helpdesk.EditAccount.UpdateAllowNewUsersFromFrontpage.CreateUserFromFrontPage","CreateUserFromFrontPage");

						EditAccount = new Label("Views.Helpdesk.EditAccount.UpdateAllowNewUsersFromFrontpage.EditAccount","EditAccount");

						EditProfile = new Label("Views.Helpdesk.EditAccount.UpdateAllowNewUsersFromFrontpage.EditProfile","EditProfile");

						SaveChanges = new Label("Views.Helpdesk.EditAccount.UpdateAllowNewUsersFromFrontpage.SaveChanges","SaveChanges");

						Text = new Label("Views.Helpdesk.EditAccount.UpdateAllowNewUsersFromFrontpage.Text","Text");
					}

					Validation = new class
					{

Label = new Label("Views.Helpdesk.EditAccount.Validation","Validation");

						NewMainAccountAndNewUserEmail = new Label("Views.Helpdesk.EditAccount.Validation.NewMainAccountAndNewUserEmail","NewMainAccountAndNewUserEmail");
					}
				}

				EditSearch = new class
				{

Label = new Label("Views.Helpdesk.EditSearch","EditSearch");

					AddSynonym = new class
					{

Label = new Label("Views.Helpdesk.EditSearch.AddSynonym","AddSynonym");

						Create = new Label("Views.Helpdesk.EditSearch.AddSynonym.Create","Create");

						Created = new Label("Views.Helpdesk.EditSearch.AddSynonym.Created","Created");

						Description = new Label("Views.Helpdesk.EditSearch.AddSynonym.Description","Description");

						PossibleDuplicates = new Label("Views.Helpdesk.EditSearch.AddSynonym.PossibleDuplicates","PossibleDuplicates");

						ProceedText = new Label("Views.Helpdesk.EditSearch.AddSynonym.ProceedText","ProceedText");

						Synonym = new Label("Views.Helpdesk.EditSearch.AddSynonym.Synonym","Synonym");

						Text = new Label("Views.Helpdesk.EditSearch.AddSynonym.Text","Text");
					}

					ChangeIndexes = new Label("Views.Helpdesk.EditSearch.ChangeIndexes","ChangeIndexes");

					EditSynonyms = new class
					{

Label = new Label("Views.Helpdesk.EditSearch.EditSynonyms","EditSynonyms");

						Active = new Label("Views.Helpdesk.EditSearch.EditSynonyms.Active","Active");

						Changed = new Label("Views.Helpdesk.EditSearch.EditSynonyms.Changed","Changed");

						ConfirmDelete = new Label("Views.Helpdesk.EditSearch.EditSynonyms.ConfirmDelete","ConfirmDelete");

						Created = new Label("Views.Helpdesk.EditSearch.EditSynonyms.Created","Created");

						Delete = new Label("Views.Helpdesk.EditSearch.EditSynonyms.Delete","Delete");

						Description = new Label("Views.Helpdesk.EditSearch.EditSynonyms.Description","Description");

						Edit = new Label("Views.Helpdesk.EditSearch.EditSynonyms.Edit","Edit");

						EditSynonym = new Label("Views.Helpdesk.EditSearch.EditSynonyms.EditSynonym","EditSynonym");

						ExamplesText = new Label("Views.Helpdesk.EditSearch.EditSynonyms.ExamplesText","ExamplesText");

						IsActive = new Label("Views.Helpdesk.EditSearch.EditSynonyms.IsActive","IsActive");

						IsNotActive = new Label("Views.Helpdesk.EditSearch.EditSynonyms.IsNotActive","IsNotActive");

						Synonym = new Label("Views.Helpdesk.EditSearch.EditSynonyms.Synonym","Synonym");

						Text = new Label("Views.Helpdesk.EditSearch.EditSynonyms.Text","Text");
					}
				}

				EditUser = new class
				{

Label = new Label("Views.Helpdesk.EditUser","EditUser");

					AssignUserToAccount = new class
					{

Label = new Label("Views.Helpdesk.EditUser.AssignUserToAccount","AssignUserToAccount");

						AccountIsActive = new Label("Views.Helpdesk.EditUser.AssignUserToAccount.AccountIsActive","AccountIsActive");

						AccountIsNotActive = new Label("Views.Helpdesk.EditUser.AssignUserToAccount.AccountIsNotActive","AccountIsNotActive");

						AccountName = new Label("Views.Helpdesk.EditUser.AssignUserToAccount.AccountName","AccountName");

						AccountNumber = new Label("Views.Helpdesk.EditUser.AssignUserToAccount.AccountNumber","AccountNumber");

						Active = new Label("Views.Helpdesk.EditUser.AssignUserToAccount.Active","Active");

						CurrentAccounts = new Label("Views.Helpdesk.EditUser.AssignUserToAccount.CurrentAccounts","CurrentAccounts");

						InsertAccountInfo = new Label("Views.Helpdesk.EditUser.AssignUserToAccount.InsertAccountInfo","InsertAccountInfo");

						PhoneNumber = new Label("Views.Helpdesk.EditUser.AssignUserToAccount.PhoneNumber","PhoneNumber");

						Text = new Label("Views.Helpdesk.EditUser.AssignUserToAccount.Text","Text");
					}

					ChangeUserAccess = new Label("Views.Helpdesk.EditUser.ChangeUserAccess","ChangeUserAccess");

					ChangeUserEmail = new class
					{

Label = new Label("Views.Helpdesk.EditUser.ChangeUserEmail","ChangeUserEmail");

						Email = new Label("Views.Helpdesk.EditUser.ChangeUserEmail.Email","Email");

						RadioButtonText = new Label("Views.Helpdesk.EditUser.ChangeUserEmail.RadioButtonText","RadioButtonText");

						Validation = new Label("Views.Helpdesk.EditUser.ChangeUserEmail.Validation","Validation");
					}

					ChangeUserPassword = new Label("Views.Helpdesk.EditUser.ChangeUserPassword","ChangeUserPassword");

					CheckApprovedAgreements = new class
					{

Label = new Label("Views.Helpdesk.EditUser.CheckApprovedAgreements","CheckApprovedAgreements");

						AgreementStatusHeader = new Label("Views.Helpdesk.EditUser.CheckApprovedAgreements.AgreementStatusHeader","AgreementStatusHeader");

						DataPolicyAccepted = new Label("Views.Helpdesk.EditUser.CheckApprovedAgreements.DataPolicyAccepted","DataPolicyAccepted");

						DataPolicyNotAccepted = new Label("Views.Helpdesk.EditUser.CheckApprovedAgreements.DataPolicyNotAccepted","DataPolicyNotAccepted");

						Text = new Label("Views.Helpdesk.EditUser.CheckApprovedAgreements.Text","Text");

						WebAgreementAccepted = new Label("Views.Helpdesk.EditUser.CheckApprovedAgreements.WebAgreementAccepted","WebAgreementAccepted");

						WebAgreementNotAccepted = new Label("Views.Helpdesk.EditUser.CheckApprovedAgreements.WebAgreementNotAccepted","WebAgreementNotAccepted");
					}

					EditUserInfo = new Label("Views.Helpdesk.EditUser.EditUserInfo","EditUserInfo");

					SearchUsers = new class
					{

Label = new Label("Views.Helpdesk.EditUser.SearchUsers","SearchUsers");

						AccountNumber = new Label("Views.Helpdesk.EditUser.SearchUsers.AccountNumber","AccountNumber");

						FreeText = new Label("Views.Helpdesk.EditUser.SearchUsers.FreeText","FreeText");

						Header = new Label("Views.Helpdesk.EditUser.SearchUsers.Header","Header");

						InsertAccountNumber = new Label("Views.Helpdesk.EditUser.SearchUsers.InsertAccountNumber","InsertAccountNumber");

						InsertUsername = new Label("Views.Helpdesk.EditUser.SearchUsers.InsertUsername","InsertUsername");

						NoUsers = new Label("Views.Helpdesk.EditUser.SearchUsers.NoUsers","NoUsers");

						NoUsersAddNew = new Label("Views.Helpdesk.EditUser.SearchUsers.NoUsersAddNew","NoUsersAddNew");

						Search = new Label("Views.Helpdesk.EditUser.SearchUsers.Search","Search");

						UserList = new class
						{

Label = new Label("Views.Helpdesk.EditUser.SearchUsers.UserList","UserList");

							Account = new Label("Views.Helpdesk.EditUser.SearchUsers.UserList.Account","Account");

							Active = new Label("Views.Helpdesk.EditUser.SearchUsers.UserList.Active","Active");

							Email = new Label("Views.Helpdesk.EditUser.SearchUsers.UserList.Email","Email");

							HelpText = new Label("Views.Helpdesk.EditUser.SearchUsers.UserList.HelpText","HelpText");

							Level = new Label("Views.Helpdesk.EditUser.SearchUsers.UserList.Level","Level");

							Name = new Label("Views.Helpdesk.EditUser.SearchUsers.UserList.Name","Name");

							UserIsActive = new Label("Views.Helpdesk.EditUser.SearchUsers.UserList.UserIsActive","UserIsActive");

							UserIsNotActive = new Label("Views.Helpdesk.EditUser.SearchUsers.UserList.UserIsNotActive","UserIsNotActive");

							Username = new Label("Views.Helpdesk.EditUser.SearchUsers.UserList.Username","Username");
						}
					}

					SeeWebsiteAsUser = new Label("Views.Helpdesk.EditUser.SeeWebsiteAsUser","SeeWebsiteAsUser");

					Text = new Label("Views.Helpdesk.EditUser.Text","Text");
				}

				FavoriteAccounts = new class
				{

Label = new Label("Views.Helpdesk.FavoriteAccounts","FavoriteAccounts");

					Header = new Label("Views.Helpdesk.FavoriteAccounts.Header","Header");

					ShowAllAccounts = new Label("Views.Helpdesk.FavoriteAccounts.ShowAllAccounts","ShowAllAccounts");

					ShowLessAccounts = new Label("Views.Helpdesk.FavoriteAccounts.ShowLessAccounts","ShowLessAccounts");
				}

				Header = new Label("Views.Helpdesk.Header","Header");

				Header2 = new Label("Views.Helpdesk.Header2","Header2");

				Ribbons = new class
				{

Label = new Label("Views.Helpdesk.Ribbons","Ribbons");

					Help = new Label("Views.Helpdesk.Ribbons.Help","Help");

					StopSupport = new Label("Views.Helpdesk.Ribbons.StopSupport","StopSupport");

					Support = new Label("Views.Helpdesk.Ribbons.Support","Support");
				}

				Save = new Label("Views.Helpdesk.Save","Save");
			}

			Integrations = new class
			{
				Label = new Label("Views.Integrations","Integrations");

				DataPolicy = new class
				{

Label = new Label("Views.Integrations.DataPolicy","DataPolicy");

					AcceptButtonText = new Label("Views.Integrations.DataPolicy.AcceptButtonText","AcceptButtonText");

					AlreadyAccepted = new Label("Views.Integrations.DataPolicy.AlreadyAccepted","AlreadyAccepted");

					CheckboxText = new Label("Views.Integrations.DataPolicy.CheckboxText","CheckboxText");

					SuccessText = new Label("Views.Integrations.DataPolicy.SuccessText","SuccessText");
				}

				DefinitionHistory = new class
				{

Label = new Label("Views.Integrations.DefinitionHistory","DefinitionHistory");

					EditDefinitionLink = new Label("Views.Integrations.DefinitionHistory.EditDefinitionLink","EditDefinitionLink");

					Resend = new Label("Views.Integrations.DefinitionHistory.Resend","Resend");

					RowCount = new Label("Views.Integrations.DefinitionHistory.RowCount","RowCount");

					TableHeader = new class
					{

Label = new Label("Views.Integrations.DefinitionHistory.TableHeader","TableHeader");

						Content = new Label("Views.Integrations.DefinitionHistory.TableHeader.Content","Content");

						Date = new Label("Views.Integrations.DefinitionHistory.TableHeader.Date","Date");

						Format = new Label("Views.Integrations.DefinitionHistory.TableHeader.Format","Format");

						Shipment = new Label("Views.Integrations.DefinitionHistory.TableHeader.Shipment","Shipment");

						Status = new Label("Views.Integrations.DefinitionHistory.TableHeader.Status","Status");

						Version = new Label("Views.Integrations.DefinitionHistory.TableHeader.Version","Version");
					}
				}

				DefinitionsList = new class
				{

Label = new Label("Views.Integrations.DefinitionsList","DefinitionsList");

					Active = new class
					{

Label = new Label("Views.Integrations.DefinitionsList.Active","Active");

						False = new Label("Views.Integrations.DefinitionsList.Active.False","False");

						True = new Label("Views.Integrations.DefinitionsList.Active.True","True");
					}

					AdministerAccountsLink = new Label("Views.Integrations.DefinitionsList.AdministerAccountsLink","AdministerAccountsLink");

					ConfirmDelete = new Label("Views.Integrations.DefinitionsList.ConfirmDelete","ConfirmDelete");

					CreateNewDefinitionLink = new Label("Views.Integrations.DefinitionsList.CreateNewDefinitionLink","CreateNewDefinitionLink");

					Delete = new Label("Views.Integrations.DefinitionsList.Delete","Delete");

					HeaderActive = new Label("Views.Integrations.DefinitionsList.HeaderActive","HeaderActive");

					HeaderDescription = new Label("Views.Integrations.DefinitionsList.HeaderDescription","HeaderDescription");

					HeaderDraft = new Label("Views.Integrations.DefinitionsList.HeaderDraft","HeaderDraft");

					HeaderEditableDefinitions = new Label("Views.Integrations.DefinitionsList.HeaderEditableDefinitions","HeaderEditableDefinitions");

					HeaderLastSent = new Label("Views.Integrations.DefinitionsList.HeaderLastSent","HeaderLastSent");

					HeaderLayout = new Label("Views.Integrations.DefinitionsList.HeaderLayout","HeaderLayout");

					HeaderNonEditableDefinitions = new Label("Views.Integrations.DefinitionsList.HeaderNonEditableDefinitions","HeaderNonEditableDefinitions");

					HeaderShipment = new Label("Views.Integrations.DefinitionsList.HeaderShipment","HeaderShipment");

					HeaderType = new Label("Views.Integrations.DefinitionsList.HeaderType","HeaderType");

					Kladde = new class
					{

Label = new Label("Views.Integrations.DefinitionsList.Kladde","Kladde");

						False = new Label("Views.Integrations.DefinitionsList.Kladde.False","False");

						True = new Label("Views.Integrations.DefinitionsList.Kladde.True","True");
					}

					NeverSent = new Label("Views.Integrations.DefinitionsList.NeverSent","NeverSent");

					NoDispatchType = new Label("Views.Integrations.DefinitionsList.NoDispatchType","NoDispatchType");

					NoEditableDefinitions = new Label("Views.Integrations.DefinitionsList.NoEditableDefinitions","NoEditableDefinitions");

					NoLayout = new Label("Views.Integrations.DefinitionsList.NoLayout","NoLayout");

					NoNonEditableDefinitions = new Label("Views.Integrations.DefinitionsList.NoNonEditableDefinitions","NoNonEditableDefinitions");

					ShowHistory = new Label("Views.Integrations.DefinitionsList.ShowHistory","ShowHistory");

					UploadXsltLink = new Label("Views.Integrations.DefinitionsList.UploadXsltLink","UploadXsltLink");
				}

				DispatchTypes = new class
				{

Label = new Label("Views.Integrations.DispatchTypes","DispatchTypes");

					Email = new Label("Views.Integrations.DispatchTypes.Email","Email");

					FTP = new Label("Views.Integrations.DispatchTypes.FTP","FTP");

					HTTP = new Label("Views.Integrations.DispatchTypes.HTTP","HTTP");

					None = new Label("Views.Integrations.DispatchTypes.None","None");
				}

				DocumentNames = new class
				{

Label = new Label("Views.Integrations.DocumentNames","DocumentNames");

					BEKRAEFT = new Label("Views.Integrations.DocumentNames.BEKRAEFT","BEKRAEFT");

					FAKTURA = new Label("Views.Integrations.DocumentNames.FAKTURA","FAKTURA");

					LEVERING = new Label("Views.Integrations.DocumentNames.LEVERING","LEVERING");

					ORDRE = new Label("Views.Integrations.DocumentNames.ORDRE","ORDRE");

					PRISFIL = new Label("Views.Integrations.DocumentNames.PRISFIL","PRISFIL");

					XBEKRAEFT = new Label("Views.Integrations.DocumentNames.XBEKRAEFT","XBEKRAEFT");

					XFAKTURA = new Label("Views.Integrations.DocumentNames.XFAKTURA","XFAKTURA");

					XLEVERING = new Label("Views.Integrations.DocumentNames.XLEVERING","XLEVERING");

					XORDRE = new Label("Views.Integrations.DocumentNames.XORDRE","XORDRE");

					XPRISFIL = new Label("Views.Integrations.DocumentNames.XPRISFIL","XPRISFIL");
				}

				ExtractionStatus = new class
				{

Label = new Label("Views.Integrations.ExtractionStatus","ExtractionStatus");

					Deleted = new Label("Views.Integrations.ExtractionStatus.Deleted","Deleted");

					Error = new Label("Views.Integrations.ExtractionStatus.Error","Error");

					InProgress = new Label("Views.Integrations.ExtractionStatus.InProgress","InProgress");

					None = new Label("Views.Integrations.ExtractionStatus.None","None");

					Sent = new Label("Views.Integrations.ExtractionStatus.Sent","Sent");

					Unknown = new Label("Views.Integrations.ExtractionStatus.Unknown","Unknown");
				}
			}

			KlarPris = new class
			{
				Label = new Label("Views.KlarPris","KlarPris");

				KlarPrisProductRequestLogsCsv = new class
				{

Label = new Label("Views.KlarPris.KlarPrisProductRequestLogsCsv","KlarPrisProductRequestLogsCsv");

					ContractDiscount = new Label("Views.KlarPris.KlarPrisProductRequestLogsCsv.ContractDiscount","ContractDiscount");

					CreationDate = new Label("Views.KlarPris.KlarPrisProductRequestLogsCsv.CreationDate","CreationDate");

					GrossPrice = new Label("Views.KlarPris.KlarPrisProductRequestLogsCsv.GrossPrice","GrossPrice");

					NetPrice = new Label("Views.KlarPris.KlarPrisProductRequestLogsCsv.NetPrice","NetPrice");

					RowId = new Label("Views.KlarPris.KlarPrisProductRequestLogsCsv.RowId","RowId");

					SalesAccount = new Label("Views.KlarPris.KlarPrisProductRequestLogsCsv.SalesAccount","SalesAccount");

					Saninr = new Label("Views.KlarPris.KlarPrisProductRequestLogsCsv.Saninr","Saninr");

					WebDiscount = new Label("Views.KlarPris.KlarPrisProductRequestLogsCsv.WebDiscount","WebDiscount");
				}
			}

			Login = new class
			{

Label = new Label("Views.Login","Login");

				Account = new Label("Views.Login.Account","Account");

				BecomeCustomer = new Label("Views.Login.BecomeCustomer","BecomeCustomer");

				ChangePassword = new class
				{

Label = new Label("Views.Login.ChangePassword","ChangePassword");

					Header = new Label("Views.Login.ChangePassword.Header","Header");

					PasswordMustMatch = new Label("Views.Login.ChangePassword.PasswordMustMatch","PasswordMustMatch");
				}

				CreateProfileOrAccount = new Label("Views.Login.CreateProfileOrAccount","CreateProfileOrAccount");

				ForgotPassword = new class
				{

Label = new Label("Views.Login.ForgotPassword","ForgotPassword");

					EmailSent = new Label("Views.Login.ForgotPassword.EmailSent","EmailSent");

					Header = new Label("Views.Login.ForgotPassword.Header","Header");

					ShorterSubHeader = new Label("Views.Login.ForgotPassword.ShorterSubHeader","ShorterSubHeader");

					SubHeader = new Label("Views.Login.ForgotPassword.SubHeader","SubHeader");
				}

				ForgotPasswordButton = new Label("Views.Login.ForgotPasswordButton","ForgotPasswordButton");

				LoginButton = new Label("Views.Login.LoginButton","LoginButton");

				LoginModalHeadline = new Label("Views.Login.LoginModalHeadline","LoginModalHeadline");

				LogoutButton = new Label("Views.Login.LogoutButton","LogoutButton");

				NewUserQuestion = new Label("Views.Login.NewUserQuestion","NewUserQuestion");

				NewUserQuestionExtended = new Label("Views.Login.NewUserQuestionExtended","NewUserQuestionExtended");

				Page = new class
				{

Label = new Label("Views.Login.Page","Page");

					CreateUser = new Label("Views.Login.Page.CreateUser","CreateUser");

					CreateUserHeading = new Label("Views.Login.Page.CreateUserHeading","CreateUserHeading");

					UsernameAndPasswordRequired = new Label("Views.Login.Page.UsernameAndPasswordRequired","UsernameAndPasswordRequired");
				}

				Password = new Label("Views.Login.Password","Password");

				ProfileSettings = new Label("Views.Login.ProfileSettings","ProfileSettings");

				RememberUsername = new Label("Views.Login.RememberUsername","RememberUsername");

				RememberUsernameAndPassword = new Label("Views.Login.RememberUsernameAndPassword","RememberUsernameAndPassword");

				User = new Label("Views.Login.User","User");

				UserName = new Label("Views.Login.UserName","UserName");

				Validation = new class
				{

Label = new Label("Views.Login.Validation","Validation");

					AccountTermsNotAccepted = new Label("Views.Login.Validation.AccountTermsNotAccepted","AccountTermsNotAccepted");

					AwaitsConfirmation = new Label("Views.Login.Validation.AwaitsConfirmation","AwaitsConfirmation");

					InActiveUserAccount = new Label("Views.Login.Validation.InActiveUserAccount","InActiveUserAccount");

					MustChangePassword = new Label("Views.Login.Validation.MustChangePassword","MustChangePassword");

					NoActiveAccounts = new Label("Views.Login.Validation.NoActiveAccounts","NoActiveAccounts");

					NoAvailableAccounts = new Label("Views.Login.Validation.NoAvailableAccounts","NoAvailableAccounts");

					PunchOutError = new Label("Views.Login.Validation.PunchOutError","PunchOutError");

					UserLocked = new Label("Views.Login.Validation.UserLocked","UserLocked");

					WrongUserNameOrPassword = new class
					{

Label = new Label("Views.Login.Validation.WrongUserNameOrPassword","WrongUserNameOrPassword");

						Text = new Label("Views.Login.Validation.WrongUserNameOrPassword.Text","Text");

						WithAttemptsLeft = new Label("Views.Login.Validation.WrongUserNameOrPassword.WithAttemptsLeft","WithAttemptsLeft");
					}
				}
			}

			MustChangePassword = new class
			{

Label = new Label("Views.MustChangePassword","MustChangePassword");

				Complexity = new Label("Views.MustChangePassword.Complexity","Complexity");

				ConfirmButton = new Label("Views.MustChangePassword.ConfirmButton","ConfirmButton");

				ConfirmPassword = new Label("Views.MustChangePassword.ConfirmPassword","ConfirmPassword");

				Header = new Label("Views.MustChangePassword.Header","Header");

				NewPassWord = new Label("Views.MustChangePassword.NewPassWord","NewPassWord");

				Views_MustChangePassword_Validation_PasswordPolicy_Complexity = new Label("Views.MustChangePassword.Validation.PasswordPolicy.Complexity","Views_MustChangePassword_Validation_PasswordPolicy_Complexity");
			}

			Navigation = new class
			{

Label = new Label("Views.Navigation","Navigation");

				Mainmenu = new Label("Views.Navigation.Mainmenu","Mainmenu");

				Mobile = new class
				{

Label = new Label("Views.Navigation.Mobile","Mobile");

					CloseAccountSelector = new Label("Views.Navigation.Mobile.CloseAccountSelector","CloseAccountSelector");

					Usergreeting = new Label("Views.Navigation.Mobile.Usergreeting","Usergreeting");
				}
			}

			NemId = new class
			{

Label = new Label("Views.NemId","NemId");

				DigitalSignaturLogin = new Label("Views.NemId.DigitalSignaturLogin","DigitalSignaturLogin");

				ForgotPassword = new Label("Views.NemId.ForgotPassword","ForgotPassword");

				Help = new Label("Views.NemId.Help","Help");

				KeyCardLogin = new Label("Views.NemId.KeyCardLogin","KeyCardLogin");

				KeyFileLogin = new Label("Views.NemId.KeyFileLogin","KeyFileLogin");

				OneTimeKey = new Label("Views.NemId.OneTimeKey","OneTimeKey");

				Order = new Label("Views.NemId.Order","Order");

				ShortCuts = new Label("Views.NemId.ShortCuts","ShortCuts");
			}

			OrderHistory = new class
			{

Label = new Label("Views.OrderHistory","OrderHistory");

				CaseNumber = new Label("Views.OrderHistory.CaseNumber","CaseNumber");

				ExpectedDelivery = new Label("Views.OrderHistory.ExpectedDelivery","ExpectedDelivery");

				HasReservations = new Label("Views.OrderHistory.HasReservations","HasReservations");

				Invoice = new Label("Views.OrderHistory.Invoice","Invoice");

				LatestOrders = new class
				{

Label = new Label("Views.OrderHistory.LatestOrders","LatestOrders");

					NoOrdersText = new Label("Views.OrderHistory.LatestOrders.NoOrdersText","NoOrdersText");

					SeeAll = new Label("Views.OrderHistory.LatestOrders.SeeAll","SeeAll");

					Text = new Label("Views.OrderHistory.LatestOrders.Text","Text");
				}

				OrderConfirmation = new Label("Views.OrderHistory.OrderConfirmation","OrderConfirmation");

				OrderDate = new Label("Views.OrderHistory.OrderDate","OrderDate");

				OrderNumber = new Label("Views.OrderHistory.OrderNumber","OrderNumber");

				OrderStatus = new class
				{

Label = new Label("Views.OrderHistory.OrderStatus","OrderStatus");

					Cancelled = new Label("Views.OrderHistory.OrderStatus.Cancelled","Cancelled");

					InProgress = new Label("Views.OrderHistory.OrderStatus.InProgress","InProgress");

					Invoiced = new Label("Views.OrderHistory.OrderStatus.Invoiced","Invoiced");

					PartiallyShipped = new Label("Views.OrderHistory.OrderStatus.PartiallyShipped","PartiallyShipped");

					Recieved = new Label("Views.OrderHistory.OrderStatus.Recieved","Recieved");

					Shipped = new Label("Views.OrderHistory.OrderStatus.Shipped","Shipped");

					Unknown = new Label("Views.OrderHistory.OrderStatus.Unknown","Unknown");
				}

				OrderType = new class
				{

Label = new Label("Views.OrderHistory.OrderType","OrderType");

					TA = new Label("Views.OrderHistory.OrderType.TA","TA");
				}

				QuotationValidTo = new Label("Views.OrderHistory.QuotationValidTo","QuotationValidTo");

				Receiver = new Label("Views.OrderHistory.Receiver","Receiver");

				ReceiverReplaceElectronic = new Label("Views.OrderHistory.ReceiverReplaceElectronic","ReceiverReplaceElectronic");

				Reference = new Label("Views.OrderHistory.Reference","Reference");

				RequisitionNumber = new Label("Views.OrderHistory.RequisitionNumber","RequisitionNumber");

				Search = new class
				{

Label = new Label("Views.OrderHistory.Search","Search");

					ButtonText = new Label("Views.OrderHistory.Search.ButtonText","ButtonText");

					CaseNumberLabel = new Label("Views.OrderHistory.Search.CaseNumberLabel","CaseNumberLabel");

					DateFromLabel = new Label("Views.OrderHistory.Search.DateFromLabel","DateFromLabel");

					DateToLabel = new Label("Views.OrderHistory.Search.DateToLabel","DateToLabel");

					DeliveryDateFromLabel = new Label("Views.OrderHistory.Search.DeliveryDateFromLabel","DeliveryDateFromLabel");

					DeliveryNoteNumberLabel = new Label("Views.OrderHistory.Search.DeliveryNoteNumberLabel","DeliveryNoteNumberLabel");

					FindOrder = new Label("Views.OrderHistory.Search.FindOrder","FindOrder");

					FromDate = new class
					{
						Label = new Label("Views.OrderHistory.Search.FromDate","FromDate");

						Validation = new class
						{

Label = new Label("Views.OrderHistory.Search.FromDate.Validation","Validation");

							Date = new Label("Views.OrderHistory.Search.FromDate.Validation.Date","Date");

							Min = new Label("Views.OrderHistory.Search.FromDate.Validation.Min","Min");
						}
					}

					InvoiceNumber = new Label("Views.OrderHistory.Search.InvoiceNumber","InvoiceNumber");

					InvoiceType = new class
					{

Label = new Label("Views.OrderHistory.Search.InvoiceType","InvoiceType");

						All = new Label("Views.OrderHistory.Search.InvoiceType.All","All");

						Type = new Label("Views.OrderHistory.Search.InvoiceType.Type","Type");
					}

					OrderNumberLabel = new Label("Views.OrderHistory.Search.OrderNumberLabel","OrderNumberLabel");

					ProductIdLabel = new Label("Views.OrderHistory.Search.ProductIdLabel","ProductIdLabel");

					ProductIdTypeLabel = new Label("Views.OrderHistory.Search.ProductIdTypeLabel","ProductIdTypeLabel");

					ReferenceLabel = new Label("Views.OrderHistory.Search.ReferenceLabel","ReferenceLabel");

					RequisitionNumberLabel = new Label("Views.OrderHistory.Search.RequisitionNumberLabel","RequisitionNumberLabel");

					toDate = new class
					{
						Label = new Label("Views.OrderHistory.Search.toDate","toDate");

						Validation = new class
						{

Label = new Label("Views.OrderHistory.Search.toDate.Validation","Validation");

							Min = new Label("Views.OrderHistory.Search.toDate.Validation.Min","Min");
						}
					}
				}

				Status = new Label("Views.OrderHistory.Status","Status");

				Total = new Label("Views.OrderHistory.Total","Total");
			}

			OrderHistoryCertificates = new class
			{

Label = new Label("Views.OrderHistoryCertificates","OrderHistoryCertificates");

				Buy = new Label("Views.OrderHistoryCertificates.Buy","Buy");

				Certifikate = new class
				{

Label = new Label("Views.OrderHistoryCertificates.Certifikate","Certifikate");

					Buy = new Label("Views.OrderHistoryCertificates.Certifikate.Buy","Buy");

					ConfirmOrder = new Label("Views.OrderHistoryCertificates.Certifikate.ConfirmOrder","ConfirmOrder");

					Price = new Label("Views.OrderHistoryCertificates.Certifikate.Price","Price");

					Reorder = new Label("Views.OrderHistoryCertificates.Certifikate.Reorder","Reorder");

					Text = new Label("Views.OrderHistoryCertificates.Certifikate.Text","Text");
				}

				Certifikates = new Label("Views.OrderHistoryCertificates.Certifikates","Certifikates");

				CertifikatesCancelled = new Label("Views.OrderHistoryCertificates.CertifikatesCancelled","CertifikatesCancelled");

				CertifikatesLink = new Label("Views.OrderHistoryCertificates.CertifikatesLink","CertifikatesLink");

				CertifikatesNoLink = new Label("Views.OrderHistoryCertificates.CertifikatesNoLink","CertifikatesNoLink");

				CertifikatesReady = new Label("Views.OrderHistoryCertificates.CertifikatesReady","CertifikatesReady");

				CertifikatesWaiting = new Label("Views.OrderHistoryCertificates.CertifikatesWaiting","CertifikatesWaiting");

				ContactSanistaal = new Label("Views.OrderHistoryCertificates.ContactSanistaal","ContactSanistaal");

				NoCertificates = new Label("Views.OrderHistoryCertificates.NoCertificates","NoCertificates");

				Reorder = new Label("Views.OrderHistoryCertificates.Reorder","Reorder");

				Success = new Label("Views.OrderHistoryCertificates.Success","Success");
			}

			OrderHistoryDetails = new class
			{

Label = new Label("Views.OrderHistoryDetails","OrderHistoryDetails");

				AtpQuantity = new Label("Views.OrderHistoryDetails.AtpQuantity","AtpQuantity");

				Certificates = new Label("Views.OrderHistoryDetails.Certificates","Certificates");

				ConfirmReorder = new Label("Views.OrderHistoryDetails.ConfirmReorder","ConfirmReorder");

				ConfirmReturn = new Label("Views.OrderHistoryDetails.ConfirmReturn","ConfirmReturn");

				CustomerCC = new Label("Views.OrderHistoryDetails.CustomerCC","CustomerCC");

				GrossPrice = new Label("Views.OrderHistoryDetails.GrossPrice","GrossPrice");

				line = new Label("Views.OrderHistoryDetails.line","line");

				MaxQuantity = new Label("Views.OrderHistoryDetails.MaxQuantity","MaxQuantity");

				NettoPrice = new Label("Views.OrderHistoryDetails.NettoPrice","NettoPrice");

				OrderedQuantity = new Label("Views.OrderHistoryDetails.OrderedQuantity","OrderedQuantity");

				Price = new Label("Views.OrderHistoryDetails.Price","Price");

				productId = new Label("Views.OrderHistoryDetails.productId","productId");

				productText = new Label("Views.OrderHistoryDetails.productText","productText");

				Quantity = new Label("Views.OrderHistoryDetails.Quantity","Quantity");

				QuotationAddToBasketButton = new Label("Views.OrderHistoryDetails.QuotationAddToBasketButton","QuotationAddToBasketButton");

				QuotationAddToBasketInfo = new Label("Views.OrderHistoryDetails.QuotationAddToBasketInfo","QuotationAddToBasketInfo");

				QuotationChooseVariant = new Label("Views.OrderHistoryDetails.QuotationChooseVariant","QuotationChooseVariant");

				QuotationHeader = new Label("Views.OrderHistoryDetails.QuotationHeader","QuotationHeader");

				QuotationWithOptionsHeader = new Label("Views.OrderHistoryDetails.QuotationWithOptionsHeader","QuotationWithOptionsHeader");

				Reason = new Label("Views.OrderHistoryDetails.Reason","Reason");

				RemQuantity = new Label("Views.OrderHistoryDetails.RemQuantity","RemQuantity");

				Reorder = new Label("Views.OrderHistoryDetails.Reorder","Reorder");

				ReturnAddress = new Label("Views.OrderHistoryDetails.ReturnAddress","ReturnAddress");

				ReturnItem = new Label("Views.OrderHistoryDetails.ReturnItem","ReturnItem");

				ReturnPhone = new Label("Views.OrderHistoryDetails.ReturnPhone","ReturnPhone");

				SendToService = new Label("Views.OrderHistoryDetails.SendToService","SendToService");

				Track = new Label("Views.OrderHistoryDetails.Track","Track");

				Unit = new Label("Views.OrderHistoryDetails.Unit","Unit");

				ValidFrom = new Label("Views.OrderHistoryDetails.ValidFrom","ValidFrom");

				ValidTo = new Label("Views.OrderHistoryDetails.ValidTo","ValidTo");
			}

			OrderHistoryTracking = new class
			{

Label = new Label("Views.OrderHistoryTracking","OrderHistoryTracking");

				Despatch = new Label("Views.OrderHistoryTracking.Despatch","Despatch");

				GrossPrice = new Label("Views.OrderHistoryTracking.GrossPrice","GrossPrice");

				Line = new Label("Views.OrderHistoryTracking.Line","Line");

				NetPrice = new Label("Views.OrderHistoryTracking.NetPrice","NetPrice");

				NoTrack = new Label("Views.OrderHistoryTracking.NoTrack","NoTrack");

				ProductId = new Label("Views.OrderHistoryTracking.ProductId","ProductId");

				ProductText = new Label("Views.OrderHistoryTracking.ProductText","ProductText");

				Quantity = new Label("Views.OrderHistoryTracking.Quantity","Quantity");

				Track = new Label("Views.OrderHistoryTracking.Track","Track");

				TrackLink = new Label("Views.OrderHistoryTracking.TrackLink","TrackLink");

				TrackNoLink = new Label("Views.OrderHistoryTracking.TrackNoLink","TrackNoLink");

				Unit = new Label("Views.OrderHistoryTracking.Unit","Unit");
			}

			Paging = new class
			{

Label = new Label("Views.Paging","Paging");

				Next = new Label("Views.Paging.Next","Next");

				Previous = new Label("Views.Paging.Previous","Previous");
			}

			PrintCatalog = new class
			{

Label = new Label("Views.PrintCatalog","PrintCatalog");

				Create = new Label("Views.PrintCatalog.Create","Create");

				Email = new Label("Views.PrintCatalog.Email","Email");

				EmailForReport = new Label("Views.PrintCatalog.EmailForReport","EmailForReport");

				NextStep = new Label("Views.PrintCatalog.NextStep","NextStep");

				NotifyText = new Label("Views.PrintCatalog.NotifyText","NotifyText");

				PrevStep = new Label("Views.PrintCatalog.PrevStep","PrevStep");

				SelectLabelPrinter = new Label("Views.PrintCatalog.SelectLabelPrinter","SelectLabelPrinter");

				Step1 = new class
				{

Label = new Label("Views.PrintCatalog.Step1","Step1");

					Title = new Label("Views.PrintCatalog.Step1.Title","Title");

					TypeBarcode = new Label("Views.PrintCatalog.Step1.TypeBarcode","TypeBarcode");

					TypeSheet = new Label("Views.PrintCatalog.Step1.TypeSheet","TypeSheet");

					TypeSheetWithImage = new Label("Views.PrintCatalog.Step1.TypeSheetWithImage","TypeSheetWithImage");

					Warning = new Label("Views.PrintCatalog.Step1.Warning","Warning");
				}

				Step2 = new class
				{

Label = new Label("Views.PrintCatalog.Step2","Step2");

					Title = new Label("Views.PrintCatalog.Step2.Title","Title");
				}

				Step3 = new class
				{

Label = new Label("Views.PrintCatalog.Step3","Step3");

					CreateCatalog = new Label("Views.PrintCatalog.Step3.CreateCatalog","CreateCatalog");

					MakeTableOfContent = new Label("Views.PrintCatalog.Step3.MakeTableOfContent","MakeTableOfContent");

					Title = new Label("Views.PrintCatalog.Step3.Title","Title");
				}

				TooManyProducts = new Label("Views.PrintCatalog.TooManyProducts","TooManyProducts");
			}

			ProductDetails = new class
			{

Label = new Label("Views.ProductDetails","ProductDetails");

				AddCustomerProductId = new Label("Views.ProductDetails.AddCustomerProductId","AddCustomerProductId");

				AddToBasket = new Label("Views.ProductDetails.AddToBasket","AddToBasket");

				AddToEasySupply = new Label("Views.ProductDetails.AddToEasySupply","AddToEasySupply");

				AddToFavoriteOrder = new Label("Views.ProductDetails.AddToFavoriteOrder","AddToFavoriteOrder");

				AddToShortcuts = new Label("Views.ProductDetails.AddToShortcuts","AddToShortcuts");

				AlternativeProduct = new Label("Views.ProductDetails.AlternativeProduct","AlternativeProduct");

				BomProductsIncluded = new Label("Views.ProductDetails.BomProductsIncluded","BomProductsIncluded");

				Buy = new Label("Views.ProductDetails.Buy","Buy");

				BuyerItemEdit = new class
				{

Label = new Label("Views.ProductDetails.BuyerItemEdit","BuyerItemEdit");

					Remove = new Label("Views.ProductDetails.BuyerItemEdit.Remove","Remove");

					Save = new Label("Views.ProductDetails.BuyerItemEdit.Save","Save");
				}

				Certifications = new class
				{

Label = new Label("Views.ProductDetails.Certifications","Certifications");

					Badge = new class
					{

Label = new Label("Views.ProductDetails.Certifications.Badge","Badge");

						Headline = new Label("Views.ProductDetails.Certifications.Badge.Headline","Headline");
					}

					CO2eText = new Label("Views.ProductDetails.Certifications.CO2eText","CO2eText");

					Headline = new Label("Views.ProductDetails.Certifications.Headline","Headline");
				}

				ChooseVariant = new Label("Views.ProductDetails.ChooseVariant","ChooseVariant");

				DefaultMetaDescription = new Label("Views.ProductDetails.DefaultMetaDescription","DefaultMetaDescription");

				Description = new Label("Views.ProductDetails.Description","Description");

				Drawing = new Label("Views.ProductDetails.Drawing","Drawing");

				EditCustomerProductId = new Label("Views.ProductDetails.EditCustomerProductId","EditCustomerProductId");

				IncludedInBomProducts = new Label("Views.ProductDetails.IncludedInBomProducts","IncludedInBomProducts");

				NoDescription = new Label("Views.ProductDetails.NoDescription","NoDescription");

				NoDrawing = new Label("Views.ProductDetails.NoDrawing","NoDrawing");

				NoVariantChosen = new Label("Views.ProductDetails.NoVariantChosen","NoVariantChosen");

				Print = new Label("Views.ProductDetails.Print","Print");

				PrintLabel = new Label("Views.ProductDetails.PrintLabel","PrintLabel");

				ProductData = new Label("Views.ProductDetails.ProductData","ProductData");

				ProductDescription = new Label("Views.ProductDetails.ProductDescription","ProductDescription");

				ProductIdentification = new Label("Views.ProductDetails.ProductIdentification","ProductIdentification");

				RaptorBoughtByOthers = new Label("Views.ProductDetails.RaptorBoughtByOthers","RaptorBoughtByOthers");

				RaptorRelatedContent = new Label("Views.ProductDetails.RaptorRelatedContent","RaptorRelatedContent");

				RaptorRelatedProducts = new Label("Views.ProductDetails.RaptorRelatedProducts","RaptorRelatedProducts");

				SeeAllSpecifications = new Label("Views.ProductDetails.SeeAllSpecifications","SeeAllSpecifications");

				Specifications = new class
				{

Label = new Label("Views.ProductDetails.Specifications","Specifications");

					ViewAll = new Label("Views.ProductDetails.Specifications.ViewAll","ViewAll");
				}

				Stock = new class
				{

Label = new Label("Views.ProductDetails.Stock","Stock");

					ChooseVariantToDisplayStock = new Label("ChooseVariantToDisplayStock","ChooseVariantToDisplayStock");

					Detailed = new Label("Views.ProductDetails.Stock.Detailed","Detailed");

					DetailedView = new Label("Views.ProductDetails.Stock.DetailedView","DetailedView");

					InYourStore = new Label("Views.ProductDetails.Stock.InYourStore","InYourStore");

					Status = new Label("Views.ProductDetails.Stock.Status","Status");
				}

				Tabs = new class
				{

Label = new Label("Views.ProductDetails.Tabs","Tabs");

					AlternativeProducts = new Label("Views.ProductDetails.Tabs.AlternativeProducts","AlternativeProducts");

					Ask = new Label("Views.ProductDetails.Tabs.Ask","Ask");

					Freight = new Label("Views.ProductDetails.Tabs.Freight","Freight");

					Info = new Label("Views.ProductDetails.Tabs.Info","Info");

					RelatedProducts = new Label("Views.ProductDetails.Tabs.RelatedProducts","RelatedProducts");

					Specifications = new Label("Views.ProductDetails.Tabs.Specifications","Specifications");

					Stock = new class
					{

Label = new Label("Views.ProductDetails.Tabs.Stock","Stock");

						InStock = new Label("Views.ProductDetails.Tabs.Stock.InStock","InStock");

						SelectedStores = new Label("Views.ProductDetails.Tabs.Stock.SelectedStores","SelectedStores");

						ShowAll = new Label("Views.ProductDetails.Tabs.Stock.ShowAll","ShowAll");

						ShowAllStores = new Label("Views.ProductDetails.Tabs.Stock.ShowAllStores","ShowAllStores");

						ShowLessStores = new Label("Views.ProductDetails.Tabs.Stock.ShowLessStores","ShowLessStores");

						ShowOnlyInStock = new Label("Views.ProductDetails.Tabs.Stock.ShowOnlyInStock","ShowOnlyInStock");

						Status = new Label("Views.ProductDetails.Tabs.Stock.Status","Status");

						YourStore = new Label("Views.ProductDetails.Tabs.Stock.YourStore","YourStore");
					}
				}

				TileSlim = new class
				{

Label = new Label("Views.ProductDetails.TileSlim","TileSlim");

					ShowVariants = new Label("Views.ProductDetails.TileSlim.ShowVariants","ShowVariants");
				}
			}

			ProjectFolderOrders = new class
			{

Label = new Label("Views.ProjectFolderOrders","ProjectFolderOrders");

				CaseNumber = new Label("Views.ProjectFolderOrders.CaseNumber","CaseNumber");

				ExpectedDelivery = new Label("Views.ProjectFolderOrders.ExpectedDelivery","ExpectedDelivery");

				OrderDate = new Label("Views.ProjectFolderOrders.OrderDate","OrderDate");

				OrderNumber = new Label("Views.ProjectFolderOrders.OrderNumber","OrderNumber");

				RequisitionNumber = new Label("Views.ProjectFolderOrders.RequisitionNumber","RequisitionNumber");

				Status = new Label("Views.ProjectFolderOrders.Status","Status");
			}

			ProjectFolders = new class
			{
				Label = new Label("Views.ProjectFolders","ProjectFolders");

				Agreements = new class
				{

Label = new Label("Views.ProjectFolders.Agreements","Agreements");

					NoOffers = new Label("Views.ProjectFolders.Agreements.NoOffers","NoOffers");
				}

				Catalogs = new class
				{

Label = new Label("Views.ProjectFolders.Catalogs","Catalogs");

					NoFavoriteOrders = new Label("Views.ProjectFolders.Catalogs.NoFavoriteOrders","NoFavoriteOrders");
				}

				Offers = new class
				{

Label = new Label("Views.ProjectFolders.Offers","Offers");

					NoOffers = new Label("Views.ProjectFolders.Offers.NoOffers","NoOffers");
				}

				Orders = new class
				{

Label = new Label("Views.ProjectFolders.Orders","Orders");

					Activate = new Label("Views.ProjectFolders.Orders.Activate","Activate");

					ActivateOrder = new Label("Views.ProjectFolders.Orders.ActivateOrder","ActivateOrder");

					ActivationNotPossible = new Label("Views.ProjectFolders.Orders.ActivationNotPossible","ActivationNotPossible");

					ActivationSent = new Label("Views.ProjectFolders.Orders.ActivationSent","ActivationSent");

					Close = new Label("Views.ProjectFolders.Orders.Close","Close");

					Comments = new Label("Views.ProjectFolders.Orders.Comments","Comments");

					Contact = new Label("Views.ProjectFolders.Orders.Contact","Contact");

					Deadline = new Label("Views.ProjectFolders.Orders.Deadline","Deadline");

					NoAccess = new Label("Views.ProjectFolders.Orders.NoAccess","NoAccess");
				}

				OrdersQA = new class
				{

Label = new Label("Views.ProjectFolders.OrdersQA","OrdersQA");

					ChooseForQA = new Label("Views.ProjectFolders.OrdersQA.ChooseForQA","ChooseForQA");

					ChosenForQA = new Label("Views.ProjectFolders.OrdersQA.ChosenForQA","ChosenForQA");

					CreateQA = new Label("Views.ProjectFolders.OrdersQA.CreateQA","CreateQA");

					ManyResults = new Label("Views.ProjectFolders.OrdersQA.ManyResults","ManyResults");

					Report = new class
					{

Label = new Label("Views.ProjectFolders.OrdersQA.Report","Report");

						Close = new Label("Views.ProjectFolders.OrdersQA.Report.Close","Close");

						Create = new Label("Views.ProjectFolders.OrdersQA.Report.Create","Create");

						Email = new Label("Views.ProjectFolders.OrdersQA.Report.Email","Email");

						Error = new Label("Views.ProjectFolders.OrdersQA.Report.Error","Error");

						Name = new Label("Views.ProjectFolders.OrdersQA.Report.Name","Name");

						SplitPdfPerProduct = new Label("Views.ProjectFolders.OrdersQA.Report.SplitPdfPerProduct","SplitPdfPerProduct");

						Subheading = new Label("Views.ProjectFolders.OrdersQA.Report.Subheading","Subheading");

						Warning = new Label("Views.ProjectFolders.OrdersQA.Report.Warning","Warning");
					}

					SelectAll = new Label("Views.ProjectFolders.OrdersQA.SelectAll","SelectAll");
				}
			}

			Recommendation = new class
			{

Label = new Label("Views.Recommendation","Recommendation");

				LastViewedFallbackText = new Label("Views.Recommendation.LastViewedFallbackText","LastViewedFallbackText");
			}

			ReturnOrder = new class
			{

Label = new Label("Views.ReturnOrder","ReturnOrder");

				BackToBasket = new Label("Views.ReturnOrder.BackToBasket","BackToBasket");

				Basket = new class
				{

Label = new Label("Views.ReturnOrder.Basket","Basket");

					Header = new Label("Views.ReturnOrder.Basket.Header","Header");

					ToCheckout = new Label("Views.ReturnOrder.Basket.ToCheckout","ToCheckout");
				}

				Confirmation = new class
				{

Label = new Label("Views.ReturnOrder.Confirmation","Confirmation");

					ConfirmOrder = new Label("Views.ReturnOrder.Confirmation.ConfirmOrder","ConfirmOrder");

					Message = new Label("Views.ReturnOrder.Confirmation.Message","Message");

					Thanks = new Label("Views.ReturnOrder.Confirmation.Thanks","Thanks");
				}

				Delivery = new class
				{

Label = new Label("Views.ReturnOrder.Delivery","Delivery");

					ConfirmOrder = new Label("Views.ReturnOrder.Delivery.ConfirmOrder","ConfirmOrder");
				}

				NoItems = new Label("Views.ReturnOrder.NoItems","NoItems");
			}

			Scanner = new class
			{
				Label = new Label("Views.Scanner","Scanner");

				ScannerData = new class
				{

Label = new Label("Views.Scanner.ScannerData","ScannerData");

					Amount = new Label("Views.Scanner.ScannerData.Amount","Amount");

					Barcode = new Label("Views.Scanner.ScannerData.Barcode","Barcode");

					HeaderText = new Label("Views.Scanner.ScannerData.HeaderText","HeaderText");

					ImportedEasySupplyListItemsHeader = new Label("Views.Scanner.ScannerData.ImportedEasySupplyListItemsHeader","ImportedEasySupplyListItemsHeader");

					ImportedEasySupplyListsHeader = new Label("Views.Scanner.ScannerData.ImportedEasySupplyListsHeader","ImportedEasySupplyListsHeader");

					ImportedFavoriteListItemsHeader = new Label("Views.Scanner.ScannerData.ImportedFavoriteListItemsHeader","ImportedFavoriteListItemsHeader");

					ImportedFavoriteListsHeader = new Label("Views.Scanner.ScannerData.ImportedFavoriteListsHeader","ImportedFavoriteListsHeader");

					NotIdentifiedListHeader = new Label("Views.Scanner.ScannerData.NotIdentifiedListHeader","NotIdentifiedListHeader");

					NotImportedEasySupplyListsHeader = new Label("Views.Scanner.ScannerData.NotImportedEasySupplyListsHeader","NotImportedEasySupplyListsHeader");

					NotImportedFavoriteListsHeader = new Label("Views.Scanner.ScannerData.NotImportedFavoriteListsHeader","NotImportedFavoriteListsHeader");

					ProductId = new Label("Views.Scanner.ScannerData.ProductId","ProductId");

					ProductName = new Label("Views.Scanner.ScannerData.ProductName","ProductName");

					RedirectMessage = new Label("Views.Scanner.ScannerData.RedirectMessage","RedirectMessage");

					ReorderQuantity = new Label("Views.Scanner.ScannerData.ReorderQuantity","ReorderQuantity");

					RequestId = new Label("Views.Scanner.ScannerData.RequestId","RequestId");

					ScannedId = new Label("Views.Scanner.ScannerData.ScannedId","ScannedId");

					StorageId = new Label("Views.Scanner.ScannerData.StorageId","StorageId");
				}

				ScannerImport = new class
				{

Label = new Label("Views.Scanner.ScannerImport","ScannerImport");

					CurrentVersion = new Label("Views.Scanner.ScannerImport.CurrentVersion","CurrentVersion");

					DownloadInstallButton = new Label("Views.Scanner.ScannerImport.DownloadInstallButton","DownloadInstallButton");

					DownloadInstallInfoText = new Label("Views.Scanner.ScannerImport.DownloadInstallInfoText","DownloadInstallInfoText");

					EmptyScanner = new Label("Views.Scanner.ScannerImport.EmptyScanner","EmptyScanner");

					Error = new class
					{

Label = new Label("Views.Scanner.ScannerImport.Error","Error");

						ErrorHeader = new Label("Views.Scanner.ScannerImport.Error.ErrorHeader","ErrorHeader");

						Fix1 = new Label("Views.Scanner.ScannerImport.Error.Fix1","Fix1");

						Fix2 = new Label("Views.Scanner.ScannerImport.Error.Fix2","Fix2");

						Fix3 = new Label("Views.Scanner.ScannerImport.Error.Fix3","Fix3");

						Fix4 = new Label("Views.Scanner.ScannerImport.Error.Fix4","Fix4");

						Fix5 = new Label("Views.Scanner.ScannerImport.Error.Fix5","Fix5");
					}

					SetupScanner = new Label("Views.Scanner.ScannerImport.SetupScanner","SetupScanner");

					UpdateNeeded = new Label("Views.Scanner.ScannerImport.UpdateNeeded","UpdateNeeded");

					YourCurrentVersion = new Label("Views.Scanner.ScannerImport.YourCurrentVersion","YourCurrentVersion");
				}

				ScannerLists = new class
				{

Label = new Label("Views.Scanner.ScannerLists","ScannerLists");

					ConfirmDelete = new Label("Views.Scanner.ScannerLists.ConfirmDelete","ConfirmDelete");

					CopyScannerListToFavoriteOrderHeader = new Label("Views.Scanner.ScannerLists.CopyScannerListToFavoriteOrderHeader","CopyScannerListToFavoriteOrderHeader");
				}
			}

			Search = new class
			{
				Label = new Label("Views.Search","Search");

				Category = new class
				{

Label = new Label("Views.Search.Category","Category");

					Categories = new Label("Views.Search.Category.Categories","Categories");

					Header = new Label("Views.Search.Category.Header","Header");

					NoHitsCategoryResults = new Label("Views.Search.Category.NoHitsCategoryResults","NoHitsCategoryResults");

					ViewAll = new Label("Views.Search.Category.ViewAll","ViewAll");
				}

				Facets = new class
				{

Label = new Label("Views.Search.Facets","Facets");

					Range = new class
					{

Label = new Label("Views.Search.Facets.Range","Range");

						ButtonLabel = new Label("Views.Search.Facets.Range.ButtonLabel","ButtonLabel");

						Reset = new Label("Views.Search.Facets.Range.Reset","Reset");

						Showing = new Label("Views.Search.Facets.Range.Showing","Showing");

						To = new Label("Views.Search.Facets.Range.To","To");
					}

					Search = new Label("Views.Search.Facets.Search","Search");

					ShowAll = new Label("Views.Search.Facets.ShowAll","ShowAll");

					ShowAllGroups = new Label("Views.Search.Facets.ShowAllGroups","ShowAllGroups");

					ShowLess = new Label("Views.Search.Facets.ShowLess","ShowLess");
				}

				Feedback = new class
				{

Label = new Label("Views.Search.Feedback","Feedback");

					ButtonClose = new Label("Views.Search.Feedback.ButtonClose","ButtonClose");

					ButtonNo = new Label("Views.Search.Feedback.ButtonNo","ButtonNo");

					ButtonSend = new Label("Views.Search.Feedback.ButtonSend","ButtonSend");

					ButtonYes = new Label("Views.Search.Feedback.ButtonYes","ButtonYes");

					CommentPlaceholder = new Label("Views.Search.Feedback.CommentPlaceholder","CommentPlaceholder");

					DidYouFindWhatYouWereLookingFor = new Label("Views.Search.Feedback.DidYouFindWhatYouWereLookingFor","DidYouFindWhatYouWereLookingFor");

					ResponseLine1 = new Label("Views.Search.Feedback.ResponseLine1","ResponseLine1");

					ResponseLine2 = new Label("Views.Search.Feedback.ResponseLine2","ResponseLine2");

					YourExperience = new Label("Views.Search.Feedback.YourExperience","YourExperience");
				}

				QuickAdd = new class
				{

Label = new Label("Views.Search.QuickAdd","QuickAdd");

					MoreThanOneProductFound = new Label("Views.Search.QuickAdd.MoreThanOneProductFound","MoreThanOneProductFound");
				}

				Sorting = new class
				{

Label = new Label("Views.Search.Sorting","Sorting");

					CommonId = new Label("Views.Search.Sorting.CommonId","CommonId");

					CommonIdDesc = new Label("Views.Search.Sorting.CommonIdDesc","CommonIdDesc");

					DefaultSort = new Label("Views.Search.Sorting.DefaultSort","DefaultSort");

					DefaultSortDesc = new Label("Views.Search.Sorting.DefaultSortDesc","DefaultSortDesc");

					PopularityDesc = new Label("Views.Search.Sorting.PopularityDesc","PopularityDesc");

					ProductName = new Label("Views.Search.Sorting.ProductName","ProductName");

					ProductNameDesc = new Label("Views.Search.Sorting.ProductNameDesc","ProductNameDesc");

					Score = new Label("Views.Search.Sorting.Score","Score");

					SelectedStandard = new Label("Views.Search.Sorting.SelectedStandard","SelectedStandard");

					VvsIdSort = new Label("Views.Search.Sorting.VvsIdSort","VvsIdSort");
				}

				Term = new class
				{

Label = new Label("Views.Search.Term","Term");

					Header = new class
					{

Label = new Label("Views.Search.Term.Header","Header");

						InCategory = new Label("Views.Search.Term.Header.InCategory","InCategory");

						SearchResult = new Label("Views.Search.Term.Header.SearchResult","SearchResult");

						SpellChecked = new Label("Views.Search.Term.Header.SpellChecked","SpellChecked");

						Vvs = new Label("Views.Search.Term.Header.Vvs","Vvs");
					}

					NoHitsBody = new Label("Views.Search.Term.NoHitsBody","NoHitsBody");

					NoHitsHeader = new class
					{

Label = new Label("Views.Search.Term.NoHitsHeader","NoHitsHeader");

						Category = new Label("Views.Search.Term.NoHitsHeader.Category","Category");

						Postfix = new Label("Views.Search.Term.NoHitsHeader.Postfix","Postfix");

						WiderSearchLinkText = new Label("Views.Search.Term.NoHitsHeader.WiderSearchLinkText","WiderSearchLinkText");
					}

					SkipVvs = new Label("Views.Search.Term.SkipVvs","SkipVvs");

					SpellChecked = new Label("Views.Search.Term.SpellChecked","SpellChecked");

					Suggestions = new Label("Views.Search.Term.Suggestions","Suggestions");
				}

				WebOffers = new class
				{

Label = new Label("Views.Search.WebOffers","WebOffers");

					CategoryHeader = new Label("Views.Search.WebOffers.CategoryHeader","CategoryHeader");

					Header = new Label("Views.Search.WebOffers.Header","Header");
				}
			}

			SearchManagement = new class
			{

Label = new Label("Views.SearchManagement","SearchManagement");

				DescriptionRequired = new Label("Views.SearchManagement.DescriptionRequired","DescriptionRequired");

				SynonymInvalid = new Label("Views.SearchManagement.SynonymInvalid","SynonymInvalid");

				SynonymNotFound = new Label("Views.SearchManagement.SynonymNotFound","SynonymNotFound");
			}

			ServiceCenterChat = new class
			{

Label = new Label("Views.ServiceCenterChat","ServiceCenterChat");

				ClickOnChat = new Label("Views.ServiceCenterChat.ClickOnChat","ClickOnChat");
			}

			Services = new class
			{

Label = new Label("Views.Services","Services");

				EasySupply = new class
				{

Label = new Label("Views.Services.EasySupply","EasySupply");

					AddProductEasySupplyWarehouseModal = new class
					{

Label = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal","AddProductEasySupplyWarehouseModal");

						AddButtonText = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.AddButtonText","AddButtonText");

						Back = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.Back","Back");

						CreateNewESBtn = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.CreateNewESBtn","CreateNewESBtn");

						CreateNewWarehouse = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.CreateNewWarehouse","CreateNewWarehouse");

						EasySupplyWarehouse = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.EasySupplyWarehouse","EasySupplyWarehouse");

						ErrorIfEmpty = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.ErrorIfEmpty","ErrorIfEmpty");

						HasUneditableWarehouses = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.HasUneditableWarehouses","HasUneditableWarehouses");

						HeadingPostfix = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.HeadingPostfix","HeadingPostfix");

						HeadingPrefix = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.HeadingPrefix","HeadingPrefix");

						Location = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.Location","Location");

						NewESFields = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.NewESFields","NewESFields");

						NoWarehouseWarning = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.NoWarehouseWarning","NoWarehouseWarning");

						Quantity = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.Quantity","Quantity");

						RegretBtn = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.RegretBtn","RegretBtn");

						Unit = new Label("Views.Services.EasySupply.AddProductEasySupplyWarehouseModal.Unit","Unit");
					}

					AddProductWarehouseModal = new class
					{

Label = new Label("Views.Services.EasySupply.AddProductWarehouseModal","AddProductWarehouseModal");

						AddButtonText = new Label("Views.Services.EasySupply.AddProductWarehouseModal.AddButtonText","AddButtonText");

						AddToWarehouse = new Label("Views.Services.EasySupply.AddProductWarehouseModal.AddToWarehouse","AddToWarehouse");

						CreateAndAddToWarehouse = new Label("Views.Services.EasySupply.AddProductWarehouseModal.CreateAndAddToWarehouse","CreateAndAddToWarehouse");

						HeadingPostfix = new Label("Views.Services.EasySupply.AddProductWarehouseModal.HeadingPostfix","HeadingPostfix");

						HeadingPrefix = new Label("Views.Services.EasySupply.AddProductWarehouseModal.HeadingPrefix","HeadingPrefix");

						Location = new Label("Views.Services.EasySupply.AddProductWarehouseModal.Location","Location");

						Quantity = new Label("Views.Services.EasySupply.AddProductWarehouseModal.Quantity","Quantity");

						Unit = new Label("Views.Services.EasySupply.AddProductWarehouseModal.Unit","Unit");

						Warehouse = new Label("Views.Services.EasySupply.AddProductWarehouseModal.Warehouse","Warehouse");
					}

					Edit = new class
					{

Label = new Label("Views.Services.EasySupply.Edit","Edit");

						AccountNumber = new Label("Views.Services.EasySupply.Edit.AccountNumber","AccountNumber");

						Active = new Label("Views.Services.EasySupply.Edit.Active","Active");

						Add = new Label("Views.Services.EasySupply.Edit.Add","Add");

						AdministeredBySanistaal = new Label("Views.Services.EasySupply.Edit.AdministeredBySanistaal","AdministeredBySanistaal");

						CancelEditButton = new Label("Views.Services.EasySupply.Edit.CancelEditButton","CancelEditButton");

						DeleteConfirm = new Label("Views.Services.EasySupply.Edit.DeleteConfirm","DeleteConfirm");

						DeliveryInterval = new Label("Views.Services.EasySupply.Edit.DeliveryInterval","DeliveryInterval");

						EasySupplyWarehouse = new Label("Views.Services.EasySupply.Edit.EasySupplyWarehouse","EasySupplyWarehouse");

						EasySupplyWarehouseHeader = new Label("Views.Services.EasySupply.Edit.EasySupplyWarehouseHeader","EasySupplyWarehouseHeader");

						EasySupplyWarehouseId = new Label("Views.Services.EasySupply.Edit.EasySupplyWarehouseId","EasySupplyWarehouseId");

						EasySupplyWarehouseStatistics = new Label("Views.Services.EasySupply.Edit.EasySupplyWarehouseStatistics","EasySupplyWarehouseStatistics");

						EditButton = new Label("Views.Services.EasySupply.Edit.EditButton","EditButton");

						EditLine = new Label("Views.Services.EasySupply.Edit.EditLine","EditLine");

						Export = new Label("Views.Services.EasySupply.Edit.Export","Export");

						File = new Label("Views.Services.EasySupply.Edit.File","File");

						FileImportDescription = new Label("Views.Services.EasySupply.Edit.FileImportDescription","FileImportDescription");

						FileImportDescription2 = new Label("Views.Services.EasySupply.Edit.FileImportDescription2","FileImportDescription2");

						FileLayout = new Label("Views.Services.EasySupply.Edit.FileLayout","FileLayout");

						Format = new Label("Views.Services.EasySupply.Edit.Format","Format");

						From = new Label("Views.Services.EasySupply.Edit.From","From");

						Gateway = new class
						{

Label = new Label("Views.Services.EasySupply.Edit.Gateway","Gateway");

							Add = new Label("Views.Services.EasySupply.Edit.Gateway.Add","Add");

							AddGateway = new Label("Views.Services.EasySupply.Edit.Gateway.AddGateway","AddGateway");

							Address = new Label("Views.Services.EasySupply.Edit.Gateway.Address","Address");

							Back = new Label("Views.Services.EasySupply.Edit.Gateway.Back","Back");

							ChooseWeekdays = new Label("Views.Services.EasySupply.Edit.Gateway.ChooseWeekdays","ChooseWeekdays");

							Delete = new Label("Views.Services.EasySupply.Edit.Gateway.Delete","Delete");

							DeleteWarning = new Label("Views.Services.EasySupply.Edit.Gateway.DeleteWarning","DeleteWarning");

							DeliveryType = new class
							{

Label = new Label("Views.Services.EasySupply.Edit.Gateway.DeliveryType","DeliveryType");

								BUTIK = new Label("Views.Services.EasySupply.Edit.Gateway.DeliveryType.BUTIK","BUTIK");

								DAG = new Label("Views.Services.EasySupply.Edit.Gateway.DeliveryType.DAG","DAG");

								FLEXBOX = new Label("Views.Services.EasySupply.Edit.Gateway.DeliveryType.FLEXBOX","FLEXBOX");

								NAT = new Label("Views.Services.EasySupply.Edit.Gateway.DeliveryType.NAT","NAT");

								Text = new Label("Views.Services.EasySupply.Edit.Gateway.DeliveryType.Text","Text");

								UNKNOWN = new Label("Views.Services.EasySupply.Edit.Gateway.DeliveryType.UNKNOWN","UNKNOWN");
							}

							DeployedFalse = new Label("Views.Services.EasySupply.Edit.Gateway.DeployedFalse","DeployedFalse");

							DeployedTrue = new Label("Views.Services.EasySupply.Edit.Gateway.DeployedTrue","DeployedTrue");

							Header = new Label("Views.Services.EasySupply.Edit.Gateway.Header","Header");

							Host = new Label("views.Services.EasySupply.Edit.Gateway.Host","Host");

							Id = new Label("Views.Services.EasySupply.Edit.Gateway.Id","Id");

							IsDeployed = new Label("Views.Services.EasySupply.Edit.Gateway.IsDeployed","IsDeployed");

							NightDelivery = new Label("Views.Services.EasySupply.Edit.Gateway.NightDelivery","NightDelivery");

							OrderTime = new Label("Views.Services.EasySupply.Edit.Gateway.OrderTime","OrderTime");

							Port = new Label("Views.Services.EasySupply.Edit.Gateway.Port","Port");

							Reconfigure = new Label("Views.Services.EasySupply.Edit.Gateway.Reconfigure","Reconfigure");

							ReconfigureGateway = new Label("Views.Services.EasySupply.Edit.Gateway.ReconfigureGateway","ReconfigureGateway");

							ReplaceGateway = new Label("Views.Services.EasySupply.Edit.Gateway.ReplaceGateway","ReplaceGateway");

							ReplaceWarning = new Label("Views.Services.EasySupply.Edit.Gateway.ReplaceWarning","ReplaceWarning");

							SoftwareVersion = new Label("Views.Services.EasySupply.Edit.Gateway.SoftwareVersion","SoftwareVersion");

							UpdateGatewayFirmware = new Label("views.Services.EasySupply.Edit.Gateway.UpdateGatewayFirmware","UpdateGatewayFirmware");

							UpdateSmartButtonsFirmware = new Label("views.Services.EasySupply.Edit.Gateway.UpdateSmartButtonsFirmware","UpdateSmartButtonsFirmware");

							Uri = new Label("Views.Services.EasySupply.Edit.Gateway.Uri","Uri");

							Validation = new class
							{

Label = new Label("Views.Services.EasySupply.Edit.Gateway.Validation","Validation");

								ChooseDeliveryAddress = new Label("Views.Services.EasySupply.Edit.Gateway.Validation.ChooseDeliveryAddress","ChooseDeliveryAddress");

								ChooseWeekdays = new Label("Views.Services.EasySupply.Edit.Gateway.Validation.ChooseWeekdays","ChooseWeekdays");
							}

							Version = new Label("Views.Services.EasySupply.Edit.Gateway.Version","Version");

							Weekdays = new class
							{

Label = new Label("Views.Services.EasySupply.Edit.Gateway.Weekdays","Weekdays");

								Friday = new Label("Views.Services.EasySupply.Edit.Gateway.Weekdays.Friday","Friday");

								Monday = new Label("Views.Services.EasySupply.Edit.Gateway.Weekdays.Monday","Monday");

								Text = new Label("Views.Services.EasySupply.Edit.Gateway.Weekdays.Text","Text");

								Thursday = new Label("Views.Services.EasySupply.Edit.Gateway.Weekdays.Thursday","Thursday");

								Tuesday = new Label("Views.Services.EasySupply.Edit.Gateway.Weekdays.Tuesday","Tuesday");

								Wednesday = new Label("Views.Services.EasySupply.Edit.Gateway.Weekdays.Wednesday","Wednesday");
							}
						}

						Import = new Label("Views.Services.EasySupply.Edit.Import","Import");

						ImportLines = new Label("Views.Services.EasySupply.Edit.ImportLines","ImportLines");

						ImportTitle = new Label("Views.Services.EasySupply.Edit.ImportTitle","ImportTitle");

						InActive = new Label("Views.Services.EasySupply.Edit.InActive","InActive");

						Location = new Label("Views.Services.EasySupply.Edit.Location","Location");

						Name = new Label("Views.Services.EasySupply.Edit.Name","Name");

						NewEasySupplyWarehouseHeader = new Label("Views.Services.EasySupply.Edit.NewEasySupplyWarehouseHeader","NewEasySupplyWarehouseHeader");

						NewWarehouseHeader = new Label("Views.Services.EasySupply.Edit.NewWarehouseHeader","NewWarehouseHeader");

						OrderList = new class
						{

Label = new Label("Views.Services.EasySupply.Edit.OrderList","OrderList");

							Back = new Label("Views.Services.EasySupply.Edit.OrderList.Back","Back");

							Delete = new Label("Views.Services.EasySupply.Edit.OrderList.Delete","Delete");

							Empty = new Label("Views.Services.EasySupply.Edit.OrderList.Empty","Empty");

							SeeOrderList = new Label("Views.Services.EasySupply.Edit.OrderList.SeeOrderList","SeeOrderList");

							Text = new Label("Views.Services.EasySupply.Edit.OrderList.Text","Text");

							Warning = new Label("Views.Services.EasySupply.Edit.OrderList.Warning","Warning");
						}

						OrderReference = new Label("Views.Services.EasySupply.Edit.OrderReference","OrderReference");

						OwnVnr = new Label("Views.Services.EasySupply.Edit.OwnVnr","OwnVnr");

						PartialImportHeading = new Label("Views.Services.EasySupply.Edit.PartialImportHeading","PartialImportHeading");

						PrintLabels = new class
						{

Label = new Label("Views.Services.EasySupply.Edit.PrintLabels","PrintLabels");

							ChangeButton = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChangeButton","ChangeButton");

							ChooseEmailAddress = new Label("views.Services.EasySupply.Edit.PrintLabels.ChooseEmailAddress","ChooseEmailAddress");

							ChoosePriceType = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChoosePriceType","ChoosePriceType");

							ChooseProductNumberType = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChooseProductNumberType","ChooseProductNumberType");

							ChooseProducts = new class
							{

Label = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChooseProducts","ChooseProducts");

								ChooseAll = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChooseProducts.ChooseAll","ChooseAll");

								ChosenCount = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChooseProducts.ChosenCount","ChosenCount");

								Text = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChooseProducts.Text","Text");
							}

							ChooseTemplate = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChooseTemplate","ChooseTemplate");

							ChosenEmailAddress = new Label("views.Services.EasySupply.Edit.PrintLabels.ChosenEmailAddress","ChosenEmailAddress");

							ChosenPriceType = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChosenPriceType","ChosenPriceType");

							ChosenProductNumberType = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChosenProductNumberType","ChosenProductNumberType");

							ChosenProducts = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChosenProducts","ChosenProducts");

							ChosenTemplate = new Label("Views.Services.EasySupply.Edit.PrintLabels.ChosenTemplate","ChosenTemplate");

							Steps = new Label("Views.Services.EasySupply.Edit.PrintLabels.Steps","Steps");

							Text = new Label("Views.Services.EasySupply.Edit.PrintLabels.Text","Text");
						}

						PrintLabelsComfirm = new Label("Views.Services.EasySupply.Edit.PrintLabelsComfirm","PrintLabelsComfirm");

						PrintLabelsHelpText = new Label("Views.Services.EasySupply.Edit.PrintLabelsHelpText","PrintLabelsHelpText");

						ProductId = new Label("Views.Services.EasySupply.Edit.ProductId","ProductId");

						Quantity = new Label("Views.Services.EasySupply.Edit.Quantity","Quantity");

						Requisition = new Label("Views.Services.EasySupply.Edit.Requisition","Requisition");

						Save = new Label("Views.Services.EasySupply.Edit.Save","Save");

						Search = new Label("Views.Services.EasySupply.Edit.Search","Search");

						SearchWord = new Label("Views.Services.EasySupply.Edit.SearchWord","SearchWord");

						ShowStatistics = new Label("Views.Services.EasySupply.Edit.ShowStatistics","ShowStatistics");

						Smartbutton = new class
						{

Label = new Label("Views.Services.EasySupply.Edit.Smartbutton","Smartbutton");

							Add = new Label("Views.Services.EasySupply.Edit.Smartbutton.Add","Add");

							AddSmartbutton = new Label("Views.Services.EasySupply.Edit.Smartbutton.AddSmartbutton","AddSmartbutton");

							Back = new Label("Views.Services.EasySupply.Edit.Smartbutton.Back","Back");

							Delete = new Label("Views.Services.EasySupply.Edit.Smartbutton.Delete","Delete");

							Deployed = new Label("Views.Services.EasySupply.Edit.Smartbutton.Deployed","Deployed");

							Error = new Label("Views.Services.EasySupply.Edit.Smartbutton.Error","Error");

							Id = new Label("Views.Services.EasySupply.Edit.Smartbutton.Id","Id");

							IdRequiredLength = new Label("Views.Services.EasySupply.Edit.Smartbutton.IdRequiredLength","IdRequiredLength");

							LowBattery = new Label("Views.Services.EasySupply.Edit.Smartbutton.LowBattery","LowBattery");

							Missing = new Label("Views.Services.EasySupply.Edit.Smartbutton.Missing","Missing");

							ReorderThreshold = new Label("Views.Services.EasySupply.Edit.Smartbutton.ReorderThreshold","ReorderThreshold");

							Status = new Label("Views.Services.EasySupply.Edit.Smartbutton.Status","Status");

							Text = new Label("Views.Services.EasySupply.Edit.Smartbutton.Text","Text");

							Warning = new Label("Views.Services.EasySupply.Edit.Smartbutton.Warning","Warning");
						}

						Sorting = new class
						{

Label = new Label("Views.Services.EasySupply.Edit.Sorting","Sorting");

							DisplayName = new Label("Views.Services.EasySupply.Edit.Sorting.DisplayName","DisplayName");

							DisplayNameZA = new Label("Views.Services.EasySupply.Edit.Sorting.DisplayNameZA","DisplayNameZA");

							Id = new Label("Views.Services.EasySupply.Edit.Sorting.Id","Id");

							IdZA = new Label("Views.Services.EasySupply.Edit.Sorting.IdZA","IdZA");

							ProductNumber = new Label("Views.Services.EasySupply.Edit.Sorting.ProductNumber","ProductNumber");

							ProductNumberZA = new Label("Views.Services.EasySupply.Edit.Sorting.ProductNumberZA","ProductNumberZA");
						}

						StatisticsDates = new Label("Views.Services.EasySupply.Edit.StatisticsDates","StatisticsDates");

						To = new Label("Views.Services.EasySupply.Edit.To","To");

						Type = new Label("Views.Services.EasySupply.Edit.Type","Type");

						Unit = new Label("Views.Services.EasySupply.Edit.Unit","Unit");

						Warehouse = new Label("Views.Services.EasySupply.Edit.Warehouse","Warehouse");

						WarehouseHeader = new Label("Views.Services.EasySupply.Edit.WarehouseHeader","WarehouseHeader");

						WarehouseId = new Label("Views.Services.EasySupply.Edit.WarehouseId","WarehouseId");

						WarehouseStatistics = new Label("Views.Services.EasySupply.Edit.WarehouseStatistics","WarehouseStatistics");

						Week = new Label("Views.Services.EasySupply.Edit.Week","Week");

						Weeks = new Label("Views.Services.EasySupply.Edit.Weeks","Weeks");
					}

					EmptyListText = new Label("Views.Services.EasySupply.EmptyListText","EmptyListText");

					ExportTitle = new class
					{

Label = new Label("Views.Services.EasySupply.ExportTitle","ExportTitle");

						Description = new Label("Views.Services.EasySupply.ExportTitle.Description","Description");

						Location = new Label("Views.Services.EasySupply.ExportTitle.Location","Location");

						Name = new Label("Views.Services.EasySupply.ExportTitle.Name","Name");

						ProductNo = new Label("Views.Services.EasySupply.ExportTitle.ProductNo","ProductNo");

						Quantity = new Label("Views.Services.EasySupply.ExportTitle.Quantity","Quantity");

						ReorderThreshold = new Label("Views.Services.EasySupply.ExportTitle.ReorderThreshold","ReorderThreshold");

						SupplierItemNo = new Label("Views.Services.EasySupply.ExportTitle.SupplierItemNo","SupplierItemNo");

						SupplierNo = new Label("Views.Services.EasySupply.ExportTitle.SupplierNo","SupplierNo");

						Unit = new Label("Views.Services.EasySupply.ExportTitle.Unit","Unit");
					}

					ProcuredProducts = new class
					{

Label = new Label("Views.Services.EasySupply.ProcuredProducts","ProcuredProducts");

						AddToEasySupplyWarehouse = new Label("Views.Services.EasySupply.ProcuredProducts.AddToEasySupplyWarehouse","AddToEasySupplyWarehouse");

						AddToWarehouse = new Label("Views.Services.EasySupply.ProcuredProducts.AddToWarehouse","AddToWarehouse");

						CreateNewProcuredProduct = new Label("Views.Services.EasySupply.ProcuredProducts.CreateNewProcuredProduct","CreateNewProcuredProduct");

						DeleteItemWarning = new Label("Views.Services.EasySupply.ProcuredProducts.DeleteItemWarning","DeleteItemWarning");

						EasySupplyWarehouseId = new Label("Views.Services.EasySupply.ProcuredProducts.EasySupplyWarehouseId","EasySupplyWarehouseId");

						EasySupplyWarehouseIdentifier = new Label("Views.Services.EasySupply.ProcuredProducts.EasySupplyWarehouseIdentifier","EasySupplyWarehouseIdentifier");

						EasySupplyWarehouseLocation = new Label("Views.Services.EasySupply.ProcuredProducts.EasySupplyWarehouseLocation","EasySupplyWarehouseLocation");

						EasySupplyWarehouseName = new Label("Views.Services.EasySupply.ProcuredProducts.EasySupplyWarehouseName","EasySupplyWarehouseName");

						EasySupplyWarehouseQuantity = new Label("Views.Services.EasySupply.ProcuredProducts.EasySupplyWarehouseQuantity","EasySupplyWarehouseQuantity");

						Manufacturer = new Label("Views.Services.EasySupply.ProcuredProducts.Manufacturer","Manufacturer");

						ProcuredProductNotUsed = new Label("Views.Services.EasySupply.ProcuredProducts.ProcuredProductNotUsed","ProcuredProductNotUsed");

						ProcuredProductsTitle = new Label("Views.Services.EasySupply.ProcuredProducts.ProcuredProductsTitle","ProcuredProductsTitle");

						ProcuredProductUsedInEasySupplyWarehouses = new Label("Views.Services.EasySupply.ProcuredProducts.ProcuredProductUsedInEasySupplyWarehouses","ProcuredProductUsedInEasySupplyWarehouses");

						ProcuredProductUsedInWarehouses = new Label("Views.Services.EasySupply.ProcuredProducts.ProcuredProductUsedInWarehouses","ProcuredProductUsedInWarehouses");

						ProductId = new Label("Views.Services.EasySupply.ProcuredProducts.ProductId","ProductId");

						ProductSupplementaryText = new Label("Views.Services.EasySupply.ProcuredProducts.ProductSupplementaryText","ProductSupplementaryText");

						ProductText = new Label("Views.Services.EasySupply.ProcuredProducts.ProductText","ProductText");

						Search = new Label("Views.Services.EasySupply.ProcuredProducts.Search","Search");

						SearchForProcuredProducts = new Label("Views.Services.EasySupply.ProcuredProducts.SearchForProcuredProducts","SearchForProcuredProducts");

						SupplierProductId = new Label("Views.Services.EasySupply.ProcuredProducts.SupplierProductId","SupplierProductId");

						Update = new Label("Views.Services.EasySupply.ProcuredProducts.Update","Update");

						UpdateProcuredProduct = new Label("Views.Services.EasySupply.ProcuredProducts.UpdateProcuredProduct","UpdateProcuredProduct");

						WarehouseId = new Label("Views.Services.EasySupply.ProcuredProducts.WarehouseId","WarehouseId");

						WarehouseIdentifier = new Label("Views.Services.EasySupply.ProcuredProducts.WarehouseIdentifier","WarehouseIdentifier");

						WarehouseLocation = new Label("Views.Services.EasySupply.ProcuredProducts.WarehouseLocation","WarehouseLocation");

						WarehouseName = new Label("Views.Services.EasySupply.ProcuredProducts.WarehouseName","WarehouseName");

						WarehouseQuantity = new Label("Views.Services.EasySupply.ProcuredProducts.WarehouseQuantity","WarehouseQuantity");
					}

					ProductType = new class
					{

Label = new Label("Views.Services.EasySupply.ProductType","ProductType");

						Ambiguous = new Label("Views.Services.EasySupply.ProductType.Ambiguous","Ambiguous");

						Discontinued = new Label("Views.Services.EasySupply.ProductType.Discontinued","Discontinued");

						OrderlineProductExists = new Label("Views.Services.EasySupply.ProductType.OrderlineProductExists","OrderlineProductExists");

						Procured = new Label("Views.Services.EasySupply.ProductType.Procured","Procured");

						StockProduct = new Label("Views.Services.EasySupply.ProductType.StockProduct","StockProduct");

						Unknown = new Label("Views.Services.EasySupply.ProductType.Unknown","Unknown");
					}

					Reorder = new class
					{

Label = new Label("Views.Services.EasySupply.Reorder","Reorder");

						AddToBasket = new Label("Views.Services.EasySupply.Reorder.AddToBasket","AddToBasket");

						AddToList = new Label("Views.Services.EasySupply.Reorder.AddToList","AddToList");

						CheckoutInProgress = new Label("Views.Services.EasySupply.Reorder.CheckoutInProgress","CheckoutInProgress");

						ClearCheckout = new Label("Views.Services.EasySupply.Reorder.ClearCheckout","ClearCheckout");

						GoToDelivery = new Label("Views.Services.EasySupply.Reorder.GoToDelivery","GoToDelivery");

						ItemNo = new Label("Views.Services.EasySupply.Reorder.ItemNo","ItemNo");

						Name = new Label("Views.Services.EasySupply.Reorder.Name","Name");

						ProductNumber = new Label("Views.Services.EasySupply.Reorder.ProductNumber","ProductNumber");

						Quantity = new Label("Views.Services.EasySupply.Reorder.Quantity","Quantity");

						Title = new Label("Views.Services.EasySupply.Reorder.Title","Title");

						Unit = new Label("Views.Services.EasySupply.Reorder.Unit","Unit");
					}

					ReorderSuccess = new Label("Views.Services.EasySupply.ReorderSuccess","ReorderSuccess");

					StockList = new class
					{

Label = new Label("Views.Services.EasySupply.StockList","StockList");

						CreateWarehouse = new Label("Views.Services.EasySupply.StockList.CreateWarehouse","CreateWarehouse");

						DeleteWarehouseWarning = new Label("Views.Services.EasySupply.StockList.DeleteWarehouseWarning","DeleteWarehouseWarning");

						GatewayAssociated = new Label("Views.Services.EasySupply.StockList.GatewayAssociated","GatewayAssociated");

						GatewayAssociatedWarning = new Label("Views.Services.EasySupply.StockList.GatewayAssociatedWarning","GatewayAssociatedWarning");

						HasGateway = new Label("Views.Services.EasySupply.StockList.HasGateway","HasGateway");

						Reorder = new Label("Views.Services.EasySupply.StockList.Reorder","Reorder");

						WarehouseAccount = new Label("Views.Services.EasySupply.StockList.WarehouseAccount","WarehouseAccount");

						WarehouseId = new Label("Views.Services.EasySupply.StockList.WarehouseId","WarehouseId");

						WarehouseName = new Label("Views.Services.EasySupply.StockList.WarehouseName","WarehouseName");
					}

					stocklistCreateEasySupplyWarehouse = new Label("Views.Services.EasySupply.stocklistCreateEasySupplyWarehouse","stocklistCreateEasySupplyWarehouse");

					stocklistCreateWarehouse = new Label("Views.Services.EasySupply.stocklistCreateWarehouse","stocklistCreateWarehouse");

					stocklistDeleteEasySupplyWarehouseWarning = new Label("Views.Services.EasySupply.stocklistDeleteEasySupplyWarehouseWarning","stocklistDeleteEasySupplyWarehouseWarning");

					stocklistDeleteWarehouseWarning = new Label("Views.Services.EasySupply.stocklistDeleteWarehouseWarning","stocklistDeleteWarehouseWarning");

					stocklistEasySupplyWarehouseAccount = new Label("Views.Services.EasySupply.stocklistEasySupplyWarehouseAccount","stocklistEasySupplyWarehouseAccount");

					stocklistEasySupplyWarehouseId = new Label("Views.Services.EasySupply.stocklistEasySupplyWarehouseId","stocklistEasySupplyWarehouseId");

					stocklistEasySupplyWarehouseName = new Label("Views.Services.EasySupply.stocklistEasySupplyWarehouseName","stocklistEasySupplyWarehouseName");

					stocklistReorder = new Label("Views.Services.EasySupply.stocklistReorder","stocklistReorder");

					stocklistTitle = new Label("Views.Services.EasySupply.stocklistTitle","stocklistTitle");

					stocklistWarehouseAccount = new Label("Views.Services.EasySupply.stocklistWarehouseAccount","stocklistWarehouseAccount");

					stocklistWarehouseId = new Label("Views.Services.EasySupply.stocklistWarehouseId","stocklistWarehouseId");

					stocklistWarehouseName = new Label("Views.Services.EasySupply.stocklistWarehouseName","stocklistWarehouseName");
				}

				MyServices = new Label("Views.Services.MyServices","MyServices");

				NotAllowedFromPunchOut = new Label("Views.Services.NotAllowedFromPunchOut","NotAllowedFromPunchOut");

				NotAvailableOnMobileDevice = new Label("Views.Services.NotAvailableOnMobileDevice","NotAvailableOnMobileDevice");

				OtherServices = new Label("Views.Services.OtherServices","OtherServices");

				SecurityDatasheets = new class
				{

Label = new Label("Views.Services.SecurityDatasheets","SecurityDatasheets");

					Download = new Label("Views.Services.SecurityDatasheets.Download","Download");

					Products = new Label("Views.Services.SecurityDatasheets.Products","Products");

					Title = new Label("Views.Services.SecurityDatasheets.Title","Title");
				}
			}

			SpecialOrder = new class
			{

Label = new Label("Views.SpecialOrder","SpecialOrder");

				Add = new Label("Views.SpecialOrder.Add","Add");

				Cancel = new Label("Views.SpecialOrder.Cancel","Cancel");

				Manufacturer = new Label("Views.SpecialOrder.Manufacturer","Manufacturer");

				ProductItemNumber = new Label("Views.SpecialOrder.ProductItemNumber","ProductItemNumber");

				ProductText = new Label("Views.SpecialOrder.ProductText","ProductText");

				Quantity = new Label("Views.SpecialOrder.Quantity","Quantity");

				Save = new Label("Views.SpecialOrder.Save","Save");

				SupplementaryText = new Label("Views.SpecialOrder.SupplementaryText","SupplementaryText");

				SupplierProductId = new Label("Views.SpecialOrder.SupplierProductId","SupplierProductId");

				Text = new Label("Views.SpecialOrder.Text","Text");

				Unit = new Label("Views.SpecialOrder.Unit","Unit");
			}

			Store = new class
			{

Label = new Label("Views.Store","Store");

				Fax = new Label("Views.Store.Fax","Fax");

				OpeningHours = new Label("Views.Store.OpeningHours","OpeningHours");

				Phone = new Label("Views.Store.Phone","Phone");

				PhoneStore = new Label("Views.Store.PhoneStore","PhoneStore");
			}

			Survey = new class
			{

Label = new Label("Views.Survey","Survey");

				Company = new Label("Views.Survey.Company","Company");

				Email = new Label("Views.Survey.Email","Email");

				EmailSubject = new Label("Views.Survey.EmailSubject","EmailSubject");

				Header = new Label("Views.Survey.Header","Header");

				MarketingEmail = new Label("Views.Survey.MarketingEmail","MarketingEmail");

				Mobile = new Label("Views.Survey.Mobile","Mobile");

				Name = new Label("Views.Survey.Name","Name");

				Submit = new Label("Views.Survey.Submit","Submit");

				SubmittedContact = new Label("Views.Survey.SubmittedContact","SubmittedContact");

				SubmittedHeader = new Label("Views.Survey.SubmittedHeader","SubmittedHeader");
			}

			TextAndImageCollapsible = new class
			{

Label = new Label("Views.TextAndImageCollapsible","TextAndImageCollapsible");

				ShowLess = new Label("Views.TextAndImageCollapsible.ShowLess","ShowLess");

				ShowMore = new Label("Views.TextAndImageCollapsible.ShowMore","ShowMore");
			}

			TimePicker = new class
			{

Label = new Label("Views.TimePicker","TimePicker");

				Hour = new Label("Views.TimePicker.Hour","Hour");

				Minute = new Label("Views.TimePicker.Minute","Minute");

				Second = new Label("Views.TimePicker.Second","Second");
			}

			Top100Products = new class
			{

Label = new Label("Views.Top100Products","Top100Products");

				AddAllToFavoriteOrder = new Label("Views.Top100Products.AddAllToFavoriteOrder","AddAllToFavoriteOrder");

				AddToFavoriteOrder = new Label("Views.Top100Products.AddToFavoriteOrder","AddToFavoriteOrder");

				BuyAgain = new Label("Views.Top100Products.BuyAgain","BuyAgain");

				Download = new Label("Views.Top100Products.Download","Download");

				EmptyResponseText = new Label("Views.Top100Products.EmptyResponseText","EmptyResponseText");

				ExportList = new Label("Views.Top100Products.ExportList","ExportList");

				NoProductsText = new Label("Views.Top100Products.NoProductsText","NoProductsText");

				PageHeader = new Label("Views.Top100Products.PageHeader","PageHeader");

				SeeAll = new Label("Views.Top100Products.SeeAll","SeeAll");

				Show = new Label("Views.Top100Products.Show","Show");

				Sort = new Label("Views.Top100Products.Sort","Sort");

				SortByLastSixMonths = new Label("Views.Top100Products.SortByLastSixMonths","SortByLastSixMonths");

				SortByLastThreeMonths = new Label("Views.Top100Products.SortByLastThreeMonths","SortByLastThreeMonths");

				SortByOrderCount = new Label("Views.Top100Products.SortByOrderCount","SortByOrderCount");

				SortByQuantity = new Label("Views.Top100Products.SortByQuantity","SortByQuantity");

				TableHeaderDescription = new Label("Views.Top100Products.TableHeaderDescription","TableHeaderDescription");

				Text = new Label("Views.Top100Products.Text","Text");
			}

			TrackAndTrace = new class
			{

Label = new Label("Views.TrackAndTrace","TrackAndTrace");

				AcknowledgementOfReceipt = new Label("Views.TrackAndTrace.AcknowledgementOfReceipt","AcknowledgementOfReceipt");

				AllRegistrations = new Label("Views.TrackAndTrace.AllRegistrations","AllRegistrations");

				CloseMap = new Label("Views.TrackAndTrace.CloseMap","CloseMap");

				Colli = new Label("Views.TrackAndTrace.Colli","Colli");

				Courier = new Label("Views.TrackAndTrace.Courier","Courier");

				Description = new Label("Views.TrackAndTrace.Description","Description");

				EmptyResponseText = new Label("Views.TrackAndTrace.EmptyResponseText","EmptyResponseText");

				Goods = new Label("Views.TrackAndTrace.Goods","Goods");

				Height = new Label("Views.TrackAndTrace.Height","Height");

				LatestRegistration = new Label("Views.TrackAndTrace.LatestRegistration","LatestRegistration");

				Length = new Label("Views.TrackAndTrace.Length","Length");

				NoTrackingInfo = new Label("Views.TrackAndTrace.NoTrackingInfo","NoTrackingInfo");

				OpenMap = new Label("Views.TrackAndTrace.OpenMap","OpenMap");

				Package = new Label("Views.TrackAndTrace.Package","Package");

				Packages = new Label("Views.TrackAndTrace.Packages","Packages");

				Quantity = new Label("Views.TrackAndTrace.Quantity","Quantity");

				Receiver = new Label("Views.TrackAndTrace.Receiver","Receiver");

				Registrations = new Label("Views.TrackAndTrace.Registrations","Registrations");

				SaveReceipt = new Label("Views.TrackAndTrace.SaveReceipt","SaveReceipt");

				Sender = new Label("Views.TrackAndTrace.Sender","Sender");

				SendersReference = new Label("Views.TrackAndTrace.SendersReference","SendersReference");

				ShipmentNumber = new Label("Views.TrackAndTrace.ShipmentNumber","ShipmentNumber");

				ShowDetails = new Label("Views.TrackAndTrace.ShowDetails","ShowDetails");

				SummarySection = new Label("Views.TrackAndTrace.SummarySection","SummarySection");

				Type = new Label("Views.TrackAndTrace.Type","Type");

				Volume = new Label("Views.TrackAndTrace.Volume","Volume");

				Weight = new Label("Views.TrackAndTrace.Weight","Weight");

				WeightInKg = new Label("Views.TrackAndTrace.WeightInKg","WeightInKg");

				Width = new Label("Views.TrackAndTrace.Width","Width");
			}

			UsersAdministration = new class
			{

Label = new Label("Views.UsersAdministration","UsersAdministration");

				AccessToAccount = new Label("Views.UsersAdministration.AccessToAccount","AccessToAccount");

				AccessToOtherAccounts = new Label("Views.UsersAdministration.AccessToOtherAccounts","AccessToOtherAccounts");

				Delete = new class
				{

Label = new Label("Views.UsersAdministration.Delete","Delete");

					Confirm = new Label("Views.UsersAdministration.Delete.Confirm","Confirm");
				}

				DeleteConfirmation = new class
				{

Label = new Label("Views.UsersAdministration.DeleteConfirmation","DeleteConfirmation");

					Account = new Label("Views.UsersAdministration.DeleteConfirmation.Account","Account");

					AccountNumber = new Label("Views.UsersAdministration.DeleteConfirmation.AccountNumber","AccountNumber");

					ButtonText = new Label("Views.UsersAdministration.DeleteConfirmation.ButtonText","ButtonText");

					CarbonCopy = new Label("Views.UsersAdministration.DeleteConfirmation.CarbonCopy","CarbonCopy");

					CarbonCopyInfo = new Label("Views.UsersAdministration.DeleteConfirmation.CarbonCopyInfo","CarbonCopyInfo");

					DeleteNotSharedFavoriteOrders = new Label("Views.UsersAdministration.DeleteConfirmation.DeleteNotSharedFavoriteOrders","DeleteNotSharedFavoriteOrders");

					DeleteSharedFavoriteOrders = new Label("Views.UsersAdministration.DeleteConfirmation.DeleteSharedFavoriteOrders","DeleteSharedFavoriteOrders");

					Email = new Label("Views.UsersAdministration.DeleteConfirmation.Email","Email");

					FavoriteLists = new Label("Views.UsersAdministration.DeleteConfirmation.FavoriteLists","FavoriteLists");

					Name = new Label("Views.UsersAdministration.DeleteConfirmation.Name","Name");

					Text = new Label("Views.UsersAdministration.DeleteConfirmation.Text","Text");

					Title = new Label("Views.UsersAdministration.DeleteConfirmation.Title","Title");

					UserName = new Label("Views.UsersAdministration.DeleteConfirmation.UserName","UserName");

					UsersWithCarbonCopy = new Label("Views.UsersAdministration.DeleteConfirmation.UsersWithCarbonCopy","UsersWithCarbonCopy");
				}

				LatestLogin = new Label("Views.UsersAdministration.LatestLogin","LatestLogin");

				Name = new Label("Views.UsersAdministration.Name","Name");

				SearchUser = new Label("Views.UsersAdministration.SearchUser","SearchUser");

				Status = new Label("Views.UsersAdministration.Status","Status");

				UserCreatedMessage = new Label("Views.UsersAdministration.UserCreatedMessage","UserCreatedMessage");

				UserName = new Label("Views.UsersAdministration.UserName","UserName");

				UserType = new Label("Views.UsersAdministration.UserType","UserType");
			}

			VaCalculator = new class
			{

Label = new Label("Views.VaCalculator","VaCalculator");

				ColebrookWhite = new class
				{

Label = new Label("Views.VaCalculator.ColebrookWhite","ColebrookWhite");

					Diameter = new Label("Views.VaCalculator.ColebrookWhite.Diameter","Diameter");

					Drop = new Label("Views.VaCalculator.ColebrookWhite.Drop","Drop");

					FillPower = new Label("Views.VaCalculator.ColebrookWhite.FillPower","FillPower");

					HeaderText = new Label("Views.VaCalculator.ColebrookWhite.HeaderText","HeaderText");

					KubicCalculation = new Label("Views.VaCalculator.ColebrookWhite.KubicCalculation","KubicCalculation");

					KubicCalculationText = new Label("Views.VaCalculator.ColebrookWhite.KubicCalculationText","KubicCalculationText");

					LiterCalculation = new Label("Views.VaCalculator.ColebrookWhite.LiterCalculation","LiterCalculation");

					LiterCalculationText = new Label("Views.VaCalculator.ColebrookWhite.LiterCalculationText","LiterCalculationText");

					Result = new Label("Views.VaCalculator.ColebrookWhite.Result","Result");

					Roughness = new Label("Views.VaCalculator.ColebrookWhite.Roughness","Roughness");

					Waterdepth = new Label("Views.VaCalculator.ColebrookWhite.Waterdepth","Waterdepth");

					WaterFlow = new Label("Views.VaCalculator.ColebrookWhite.WaterFlow","WaterFlow");
				}

				HideCalculation = new Label("Views.VaCalculator.HideCalculation","HideCalculation");

				Lift = new class
				{

Label = new Label("Views.VaCalculator.Lift","Lift");

					BackFillWeight = new Label("Views.VaCalculator.Lift.BackFillWeight","BackFillWeight");

					Coverage = new Label("Views.VaCalculator.Lift.Coverage","Coverage");

					Diameter = new Label("Views.VaCalculator.Lift.Diameter","Diameter");

					HeaderText = new Label("Views.VaCalculator.Lift.HeaderText","HeaderText");

					Length = new Label("Views.VaCalculator.Lift.Length","Length");

					LiftCalculation = new Label("Views.VaCalculator.Lift.LiftCalculation","LiftCalculation");

					Result = new Label("Views.VaCalculator.Lift.Result","Result");

					ResultLift = new Label("Views.VaCalculator.Lift.ResultLift","ResultLift");

					ResultReverseLift = new Label("Views.VaCalculator.Lift.ResultReverseLift","ResultReverseLift");

					Weight = new Label("Views.VaCalculator.Lift.Weight","Weight");
				}

				PipeVolume = new class
				{

Label = new Label("Views.VaCalculator.PipeVolume","PipeVolume");

					CalcCassettesText = new Label("Views.VaCalculator.PipeVolume.CalcCassettesText","CalcCassettesText");

					CalcHeaderText = new Label("Views.VaCalculator.PipeVolume.CalcHeaderText","CalcHeaderText");

					Diameter = new Label("Views.VaCalculator.PipeVolume.Diameter","Diameter");

					Length = new Label("Views.VaCalculator.PipeVolume.Length","Length");

					NumberOfCassettes = new Label("Views.VaCalculator.PipeVolume.NumberOfCassettes","NumberOfCassettes");

					Result = new Label("Views.VaCalculator.PipeVolume.Result","Result");

					Volume = new Label("Views.VaCalculator.PipeVolume.Volume","Volume");

					VolumeCalculation = new Label("Views.VaCalculator.PipeVolume.VolumeCalculation","VolumeCalculation");
				}

				PromilleCalc = new class
				{

Label = new Label("Views.VaCalculator.PromilleCalc","PromilleCalc");

					CalcHeight = new Label("Views.VaCalculator.PromilleCalc.CalcHeight","CalcHeight");

					CalcLength = new Label("Views.VaCalculator.PromilleCalc.CalcLength","CalcLength");

					CalcPromille = new Label("Views.VaCalculator.PromilleCalc.CalcPromille","CalcPromille");

					Height = new Label("Views.VaCalculator.PromilleCalc.Height","Height");

					HeightCalculation = new Label("Views.VaCalculator.PromilleCalc.HeightCalculation","HeightCalculation");

					Length = new Label("Views.VaCalculator.PromilleCalc.Length","Length");

					LengthCalculation = new Label("Views.VaCalculator.PromilleCalc.LengthCalculation","LengthCalculation");

					Promille = new Label("Views.VaCalculator.PromilleCalc.Promille","Promille");

					PromilleCalculation = new Label("Views.VaCalculator.PromilleCalc.PromilleCalculation","PromilleCalculation");

					Result = new Label("Views.VaCalculator.PromilleCalc.Result","Result");
				}

				Rainwatercassettes = new class
				{

Label = new Label("Views.VaCalculator.Rainwatercassettes","Rainwatercassettes");

					Areal = new Label("Views.VaCalculator.Rainwatercassettes.Areal","Areal");

					CalcClay = new Label("Views.VaCalculator.Rainwatercassettes.CalcClay","CalcClay");

					CalcDS440 = new Label("Views.VaCalculator.Rainwatercassettes.CalcDS440","CalcDS440");

					CalcGravel = new Label("Views.VaCalculator.Rainwatercassettes.CalcGravel","CalcGravel");

					CalcSand = new Label("Views.VaCalculator.Rainwatercassettes.CalcSand","CalcSand");

					ClayCalculation = new Label("Views.VaCalculator.Rainwatercassettes.ClayCalculation","ClayCalculation");

					DrainCoff = new Label("Views.VaCalculator.Rainwatercassettes.DrainCoff","DrainCoff");

					DS440Calculation = new Label("Views.VaCalculator.Rainwatercassettes.DS440Calculation","DS440Calculation");

					FaskineVolume = new Label("Views.VaCalculator.Rainwatercassettes.FaskineVolume","FaskineVolume");

					GravelCalculation = new Label("Views.VaCalculator.Rainwatercassettes.GravelCalculation","GravelCalculation");

					Height = new Label("Views.VaCalculator.Rainwatercassettes.Height","Height");

					Length = new Label("Views.VaCalculator.Rainwatercassettes.Length","Length");

					NumberOfCassettes = new Label("Views.VaCalculator.Rainwatercassettes.NumberOfCassettes","NumberOfCassettes");

					RainIntensity = new Label("Views.VaCalculator.Rainwatercassettes.RainIntensity","RainIntensity");

					Result = new Label("Views.VaCalculator.Rainwatercassettes.Result","Result");

					SandCalculation = new Label("Views.VaCalculator.Rainwatercassettes.SandCalculation","SandCalculation");

					TimeToEmpty = new Label("Views.VaCalculator.Rainwatercassettes.TimeToEmpty","TimeToEmpty");

					Width = new Label("Views.VaCalculator.Rainwatercassettes.Width","Width");
				}

				ShowCalculation = new Label("Views.VaCalculator.ShowCalculation","ShowCalculation");

				WaterBreak = new class
				{

Label = new Label("Views.VaCalculator.WaterBreak","WaterBreak");

					CalcDiameter = new Label("Views.VaCalculator.WaterBreak.CalcDiameter","CalcDiameter");

					CalcWaterFlow = new Label("Views.VaCalculator.WaterBreak.CalcWaterFlow","CalcWaterFlow");

					DiameterHeader = new Label("Views.VaCalculator.WaterBreak.DiameterHeader","DiameterHeader");

					HeaderDescription = new Label("Views.VaCalculator.WaterBreak.HeaderDescription","HeaderDescription");

					HeightPressure = new Label("Views.VaCalculator.WaterBreak.HeightPressure","HeightPressure");

					Hole = new Label("Views.VaCalculator.WaterBreak.Hole","Hole");

					HoleCalculation = new Label("Views.VaCalculator.WaterBreak.HoleCalculation","HoleCalculation");

					Outflow = new Label("Views.VaCalculator.WaterBreak.Outflow","Outflow");

					OutflowCalculation = new Label("Views.VaCalculator.WaterBreak.OutflowCalculation","OutflowCalculation");

					Result = new Label("Views.VaCalculator.WaterBreak.Result","Result");

					WaterFlowHeader = new Label("Views.VaCalculator.WaterBreak.WaterFlowHeader","WaterFlowHeader");
				}
			}
		}

    }
