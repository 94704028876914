


































































































































import Vue from 'vue';
import {
    Component,
    Prop,
    Watch
} from 'vue-property-decorator';
import {
    EasySupplyWarehouseDetailViewModel
} from '@/types/serverContract';
import CallbackPaging from '@/project/shared/CallbackPaging.vue';
import EasySupplyPrintLabelsWarehouseLineItem from '@/project/easySupply/printLabels/EasySupplyPrintLabelsWarehouseLineItem.vue';
import {
    IWarehouseLines,
    IEasySupplyPrintLabelsStep
} from '@/project/easySupply/printLabels/EasySupplyPrintLabels.service';
import urlHelper from '@/project/shared/services/urlHelperSimpleSearch.service';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import { stringReplaceAll } from '@/project/config/utilities';

@Component({
    components: { SpinnerOverlay, EasySupplyPrintLabelsWarehouseLineItem, CallbackPaging }
})
export default class EasySupplyPrintLabelsProductsStep extends Vue {
    @Prop({
        required: true,
        type: Object
    }) step!: IEasySupplyPrintLabelsStep;

    pending: boolean = false;
    warehouseDetails: EasySupplyWarehouseDetailViewModel | null = null;
    warehouseLines: IWarehouseLines[] = [];
    currentPage: number = 1;
    pageSize: number = 20;

    get chosenProductIds(): string[] {
        return this.warehouseLines.filter(lineItem => lineItem.isChosen).map(lineItem => lineItem.warehouseLineDetails.productId);
    }

    get chosenProductIdsString(): string {
        return stringReplaceAll(this.chosenProductIds.join(), ',', ', ');
    }

    get isAllProductsChosen(): boolean {
        return this.chosenProductIds.length === this.warehouseLines.length;
    }

    get hasWarehouseLines(): boolean {
        return this.warehouseLines.length >= 1;
    }

    get warehouseLinesForPage(): IWarehouseLines[] {
        const pageStartIndex = (this.currentPage - 1) * this.pageSize;
        return this.hasWarehouseLines ? this.warehouseLines.slice(pageStartIndex, this.pageSize * this.currentPage) : [];
    }

    created(): void {
        this.getWarehouseDetails().then(warehouseDetails => {
            this.warehouseDetails = warehouseDetails;
            this.warehouseLines.push(...warehouseDetails.lines.map(warehouseLineDetails => {
                return {
                    warehouseLineDetails: warehouseLineDetails,
                    isChosen: true
                };
            }));
        });
    }

    @Watch('chosenProductIds')
    onHasChosenProductsChange(): void {
        this.$emit('choiceChange', this.chosenProductIds);
    }

    async getWarehouseDetails(): Promise<EasySupplyWarehouseDetailViewModel> {
        this.pending = true;
        try {
            const id = urlHelper.getId();
            return await Api.easySupply.getEasySupplyWarehouseDetailForLabels(id);
        } finally {
            this.pending = false;
        }
    }

    toggleAllProductsChosen(): void {
        // The value of the computed property is copied to a const because we cannot use it directly in the forEach loop since it gets recalculated when a "warehouseLine" is updated
        const isAllProductsChosen: boolean = this.isAllProductsChosen;
        this.warehouseLines.forEach(warehouseLine => {
            warehouseLine.isChosen = !isAllProductsChosen;
        });
    }

    gotoPage(pageNo: number): void {
        this.currentPage = pageNo;
    }
}
