





















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SORTING_DIRECTION } from '@/core/enums/sortingDirection';
import {
    AccountGroupResponseSlimViewModel,
    AccountGroupResponseViewModel
} from '@/types/serverContract';
import AccountGroupListItem from '@/components/molecules/AccountGroupList/AccountGroupListItem.vue';
import CurtainOverlay from '@/components/organisms/CurtainOverlay/CurtainOverlay.vue';
import AccountGroupAdministrationOverlay from '@/components/molecules/AccountGroupList/AccountGroupAdministrationOverlay.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import Api from '@/project/http/api';

@Component({
    components: {
        SpinnerOverlay,
        AccountGroupListItem,
        CurtainOverlay,
        AccountGroupAdministrationOverlay
    }
})
export default class AccountGroupAdministration extends Vue {
    @Prop({ type: String, default: '' }) header!: string;
    @Prop({ type: String, default: '' }) buttonText!: string;
    @Prop({ type: String, default: '' }) description!: string;

    activeSortingKey: string = '';
    activeSortingDirection: string = SORTING_DIRECTION.ASCENDING;
    pending: boolean = false;
    accountGroupList: AccountGroupResponseSlimViewModel[] | null = null;
    SORTING_DIRECTION = SORTING_DIRECTION;
    showGroupAdministrationOverlay: boolean = false;
    currentGroup: AccountGroupResponseViewModel | null = null;

    SORTING_KEY = {
        NAME: 'name',
        NUMBER_OF_ACCOUNTS: 'numberOfAccounts'
    };

    created() {
        this.getAccountGroups();
    }

    ascendingSortingDirection() {
        return SORTING_DIRECTION.ASCENDING;
    }

    async getAccountGroups() {
        this.pending = true;

        try {
            this.accountGroupList = null;
            this.accountGroupList = await Api.accountGroup.GetAll();
        } finally {
            this.pending = false;
        }
    }

    sortFavoriteOrdersList(sortingKey: string) {
        if (sortingKey === this.activeSortingKey) {
            this.toggleSortingDirection();
        } else {
            this.activeSortingKey = sortingKey;
            this.activeSortingDirection = SORTING_DIRECTION.ASCENDING;
        }

        if (this.accountGroupList) {
            this.accountGroupList = this.accountGroupList.slice().sort((a, b) => {
                const rowValueA = a[sortingKey];
                const rowValueB = b[sortingKey];

                if (rowValueA < rowValueB) {
                    return this.activeSortingDirection === SORTING_DIRECTION.ASCENDING ? -1 : 1;
                } else if (rowValueA > rowValueB) {
                    return this.activeSortingDirection === SORTING_DIRECTION.ASCENDING ? 1 : -1;
                } else {
                    return 0;
                }
            }) as AccountGroupResponseSlimViewModel[];
        }
    }

    toggleSortingDirection() {
        this.activeSortingDirection =
            this.activeSortingDirection === SORTING_DIRECTION.ASCENDING
                ? SORTING_DIRECTION.DESCENDING
                : SORTING_DIRECTION.ASCENDING;
    }

    toggleGroupAdministrationOverlay(e: any = null) {
        this.currentGroup = e && e.id ? e : null;
        this.showGroupAdministrationOverlay = !this.showGroupAdministrationOverlay;
    }

    async saveAccountGroup() {
        this.getAccountGroups();
        this.toggleGroupAdministrationOverlay();
    }

    async deleteGroup(e) {
        try {
            this.pending = true;
            await Api.accountGroup.Delete(e);

            this.getAccountGroups();
        } finally {
            this.pending = false;
        }
    }
}
