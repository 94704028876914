














































































import { Component, Watch, Prop } from 'vue-property-decorator';

import CurtainOverlayBase from '@/components/organisms/CurtainOverlay/overlays/CurtainOverlayBase.vue';
import { ICurtainOverlayBase } from '@/components/organisms/CurtainOverlay/overlays/ICurtainOverlayBase.vue';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';

import Api from '@/project/http/api';

import { SalesAccountSlim, AccountGroupResponseViewModel } from '@/types/serverContract';
import { CREATE_UPDATE } from '@/core/enums/enums';

export interface SalesAccountSlimWithSelectedState extends SalesAccountSlim {
    selected?: boolean;
}

export interface UserAdministrationPayload {
    name: string;
    salesAccountIds: number[];
}

@Component({
    components: {
        SpinnerElement
    }
})
export default class AccountGroupAdministrationOverlay
    extends CurtainOverlayBase
    implements ICurtainOverlayBase {
    @Prop({ type: Object, default: null }) group!: AccountGroupResponseViewModel;
    localGroup!: AccountGroupResponseViewModel;
    action!: CREATE_UPDATE;

    name: string = '';
    search: string = '';
    accountsForCurrentUser: SalesAccountSlimWithSelectedState[] | null = null;
    pending: boolean = false;

    vvFields!: any;

    get accountsWithAdminRights(): SalesAccountSlimWithSelectedState[] {
        if (!this.accountsForCurrentUser) {
            return [];
        }

        return this.accountsForCurrentUser.filter(user => user.isCurrentUserAdmin);
    }

    get filteredUsers(): SalesAccountSlimWithSelectedState[] {
        if (!this.accountsWithAdminRights || !this.accountsWithAdminRights.length) {
            return [];
        }

        if (this.search !== '') {
            return this.accountsWithAdminRights.filter(user =>
                user.soldToPartnerName.toLowerCase().includes(this.search.toLowerCase())
            );
        }

        return this.accountsWithAdminRights;
    }

    get selectedUsers(): number[] {
        if (!this.accountsWithAdminRights || !this.accountsWithAdminRights.length) {
            return [];
        }

        return this.accountsWithAdminRights.filter(user => user.selected).map(user => user.id);
    }

    get payload(): UserAdministrationPayload {
        return {
            name: this.name,
            salesAccountIds: this.selectedUsers
        };
    }

    get isFieldsValid(): boolean {
        const fields = !Object.values(this.vvFields).some((field: any) => field.invalid);
        const users = !!(
            this.name &&
            this.name.length &&
            this.selectedUsers &&
            this.selectedUsers.length
        );
        return fields && users;
    }

    get selectedAccountsCountText(): string {
        return this.selectedUsers.length + ' ' + (this.selectedUsers.length === 1 ? this.$tr(this.$labels.Views.AccountGroupAdministration.CreateOverlay.Account) : this.$tr(this.$labels.Views.AccountGroupAdministration.CreateOverlay.Accounts));
    }

    @Watch('isFieldsValid')
    toggleSubmitButton(enable: boolean) {
        this.overlay.submitButtonEnabled = enable;
    }

    created() {
        this.getAccountForCurrentUser();
    }

    mounted() {
        this.overlay.overlayChanged(this);
    }

    Active(): void {
        this.action = this.group ? CREATE_UPDATE.Update : CREATE_UPDATE.Create;
        if (this.action === CREATE_UPDATE.Create) {
            this.localGroup = new class AccountGroupResponseViewModel {
                id: string = '';
                name = '';
                salesAccounts = [];
            }();
        } else {
            this.localGroup = this.group;
        }
        this.updateOverlayTexts();

        this.name = this.localGroup.name;

        this.toggleSubmitButton(false);
    }

    updateOverlayTexts() {
        this.titleText = this.action === CREATE_UPDATE.Update
            ? this.$tr(this.$labels.Views.AccountGroupAdministration.EditOverlay.Title)
            : this.$tr(this.$labels.Views.AccountGroupAdministration.CreateOverlay.Title);
        this.submitButtonText = this.action === CREATE_UPDATE.Update
            ? this.$tr(this.$labels.Views.AccountGroupAdministration.EditOverlay.SubmitButtonText)
            : this.$tr(
                this.$labels.Views.AccountGroupAdministration.CreateOverlay.SubmitButtonText
            );
    }

    async getAccountForCurrentUser() {
        this.pending = true;

        try {
            const accountsForCurrentUser = await Api.account.getAccountsForCurrentUser();

            if (!(accountsForCurrentUser instanceof Array)) {
                return;
            }

            this.accountsForCurrentUser = accountsForCurrentUser.map(v => ({
                ...v,
                selected: false
            }));

            if (!this.localGroup) {
                return;
            }

            this.localGroup.salesAccounts.forEach(selectedAccount => {
                if (!this.accountsForCurrentUser || !this.accountsForCurrentUser.length) {
                    return;
                }

                const account = this.accountsForCurrentUser.find(
                    account => account.soldToPartnerId === selectedAccount.soldToPartnerId
                );

                if (!account) {
                    return;
                }

                account.selected = true;
            });
        } finally {
            this.pending = false;
        }
    }

    resetForm() {
        this.name = '';
        this.search = '';

        this.accountsForCurrentUser =
            this.accountsForCurrentUser &&
            this.accountsForCurrentUser.map(v => ({
                ...v,
                selected: false
            }));
    }

    async Submit(): Promise<void> {
        try {
            this.pending = true;
            this.overlay.setErrorMessage('');

            if (this.group) { // Check if account group is set
                await Api.accountGroup.Update(this.group.id, this.payload);
            } else {
                await Api.accountGroup.Create(this.payload);
            }
        } catch (error) {
            return Promise.reject(error);
        } finally {
            this.pending = false;
            this.$emit('submit');
        }
    }
}
