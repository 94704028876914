
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
    PriceRequest,
    PriceViewObject,
    ProductTileVariantViewObject,
    ProductTileViewObject,
    UnitViewObject,
    AvailabilityStatus
} from '@/types/serverContract';
import priceService from '@/project/products/price.service';
import { AppGetter } from '@/store/app';
import raptor, { TrackProductClickRaptorViewModel } from '@/core/tracking/raptorTracking.service';
import Constants from '@/project/config/constants';
import { FlexBoxGetter } from '@/store/flexBox';
import productTrackingService, { TrackProductClickViewModel } from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';

@Component
export default class ProductTileWrapper extends Vue {
    @Prop({
        type: Object,
        required: true
    }) product!: ProductTileViewObject;

    @Prop({
        required: false,
        type: String
    }) raptorMethod!: string;

    @Prop({
        required: false,
        type: String
    }) raptorEvent!: string;

    @Prop({
        default: false,
        type: Boolean
    }) selectDefaultVariant!: boolean;

    @AppGetter isLoggedIn!: boolean;
    @FlexBoxGetter isInFlexBoxContext!: boolean;

    price: PriceViewObject | null = null;
    pricesPending: boolean = false;
    selectedVariant: ProductTileVariantViewObject | null = null;
    productUrl: string = this.product.url;

    get variantForPrimaryProperties(): ProductTileVariantViewObject | null {
        if (this.selectedVariant != null) {
            return this.selectedVariant;
        }
        return this.product.variants[0];
    }

    get getThumbnailImage(): string {
        let thumbnailImage: string;

        if (this.selectedVariant != null && this.selectedVariant.thumbnailImage) {
            thumbnailImage = this.selectedVariant.thumbnailImage;
        } else if (this.product.thumbnailImage) {
            thumbnailImage = this.product.thumbnailImage;
        } else {
            thumbnailImage = Constants.ImageNotFound;
        }

        return thumbnailImage;
    }

    get isOrderable(): boolean {
        if (this.price === null) {
            return false;
        }
        if (this.isInFlexBoxContext && this.price.stockStatus.status !== AvailabilityStatus.InStock) {
            return false;
        }
        return this.price.stockStatus.isOrderable;
    }

    get orderableUnits(): UnitViewObject[] {
        if (this.selectedVariant && this.selectedVariant.orderableUnits) {
            return this.selectedVariant.orderableUnits;
        }
        return [];
    }

    get trackProductClickRaptorInfo(): TrackProductClickRaptorViewModel | null {
        const trackingInfo: TrackProductClickRaptorViewModel = {
            methodName: this.raptorMethod,
            eventType: this.raptorEvent,
            productId: this.product.id,
            productName: this.product.name
        };
        return this.raptorMethod ? trackingInfo : null;
    }

    get trackProductClickInfo(): TrackProductClickViewModel | null {
        const clickedProduct = this.selectedVariant ? this.selectedVariant : this.product.variants[0];
        const trackingInfo: TrackProductClickViewModel = {
            product: this.product,
            variant: clickedProduct
        };
        return clickedProduct.trackingContext ? trackingInfo : null;
    }

    created() {
        this.selectedVariant = this.product.variants.length === 1 ? this.product.variants[0] : null;

        if (this.selectDefaultVariant && this.product.defaultVariantId) {
            this.setVariant(this.product.defaultVariantId);
        }

        if (this.isLoggedIn) {
            this.getPricesForProduct();
        }
    }

    @Watch('product')
    onProductChange(newProduct: ProductTileViewObject): void {
        if (!newProduct) return;

        if (this.selectedVariant) {
            let variantId = this.selectedVariant.ids.variantId;
            this.selectedVariant = newProduct.variants.find(productVariant => productVariant.ids.variantId === variantId) || null;
        }

        if (!this.selectedVariant) {
            this.selectedVariant = newProduct.variants.length === 1 ? newProduct.variants[0] : null;
        }

        this.getPricesForProduct();
    }

    @Watch('selectedVariant')
    setProductUrl() {
        this.productUrl = this.selectedVariant ? this.selectedVariant.url : this.product.url;
    }

    async getPricesForProduct() {
        if (!this.selectedVariant || !this.isLoggedIn) {
            this.price = null;
            return;
        }

        this.pricesPending = true;

        try {
            const priceRequest: PriceRequest = {
                id: this.selectedVariant.ids.variantId,
                availabilityType: this.selectedVariant.availabilityType,
                certificateCode: this.selectedVariant.certificateCode,
                isSurplus: this.selectedVariant.isSurplus,
                isTemporarilyPhasedOut: this.selectedVariant.isTemporarilyPhasedOut,
                isVendorUnableToDeliver: this.selectedVariant.isVendorUnableToDeliver
            };

            this.price = await priceService.getPrice(priceRequest);
            this.$emit('priceLoaded', this.price);
        } finally {
            this.pricesPending = false;
        }
    }

    setVariant(variantId: string): void {
        if (variantId === '') {
            this.selectedVariant = null;
            return;
        }

        this.selectedVariant = this.product.variants.find(productVariant => productVariant.ids.variantId === variantId) || null;

        if (this.isLoggedIn) {
            this.getPricesForProduct();
        }
    }

    trackProductClick(): void {
        if (this.trackProductClickRaptorInfo) {
            raptor.trackProductClick(this.trackProductClickRaptorInfo);
        }
        if (this.trackProductClickInfo) {
            productTrackingService.TrackProduct(
                PRODUCT_TRACKING_EVENT.ProductClick,
                productTrackingService.ToTrackedProduct(
                    this.trackProductClickInfo,
                    PRODUCT_TRACKING_TYPE.TrackProductClickViewModel,
                    this.price
                )
            );
        }
    }

    render() {
        return this.$scopedSlots.default!({
            pricesPending: this.pricesPending,
            price: this.price,
            selectedVariant: this.selectedVariant,
            variantForPrimaryProperties: this.variantForPrimaryProperties,
            getThumbnailImage: this.getThumbnailImage,
            isOrderable: this.isOrderable,
            orderableUnits: this.orderableUnits,
            setVariant: this.setVariant,
            trackProductClick: this.trackProductClick,
            productUrl: this.productUrl
        });
    }
}
