
































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CategoryHitViewObject } from '@/types/serverContract';
import Constants from '@/project/config/constants';
import tracking from '@/core/tracking/tracking.service';

@Component
export default class SearchCategories extends Vue {
    @Prop({
        required: true,
        type: Array
    }) categories!: CategoryHitViewObject[];

    @Prop({
        default: 0,
        type: Number
    }) totalSearchResults!: number;

    subCategoriesLimit: number = 2;

    getCategoryImage(category: CategoryHitViewObject): string {
        return category.imageUrl ? category.imageUrl : Constants.ImageNotFound;
    }

    getSubCategoriesToDisplay(category: CategoryHitViewObject): CategoryHitViewObject[] {
        return category.subCategories.slice(0, this.subCategoriesLimit);
    }

    trackSearchNavigation(): void {
        tracking.trackSearchNavigation('Top category', this.totalSearchResults);
    }
}
