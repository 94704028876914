import httpService from '@/core/http/http.service';
import {
    AddCommentsRequest,
    AddOrderToBasketRequest,
    AddOrUpdateSpecialOrderLineItemRequest,
    AddQuotationToBasketRequest,
    AddToBasketRequest,
    BasketViewModel,
    ConvertToFavoriteOrderRequest, CopyToAccountRequest,
    OrderViewModel,
    QuickAddToBasketRequest,
    SubmitOrderRequest,
    UpdateLineItemRequest,
    QuickAddToBasketResultViewModel
} from '@/types/serverContract';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';

export default class BasketController {
    public async addToBasket(payload: AddToBasketRequest, messagesId: string): Promise<void> {
        return httpService.put('basketApi/lineitem?raptorSessionId=' + this.raptorSessionId, payload as AddToBasketRequest, messagesId);
    }

    public async updateBasketLineItem(payload: UpdateLineItemRequest): Promise<BasketViewModel> {
        return httpService.post<BasketViewModel>('basketapi/lineitem', payload);
    }

    public async removeAllowanceCharges(): Promise<BasketViewModel> {
        return httpService.get<BasketViewModel>('basketapi/removeAllowanceCharges');
    }

    public async getBasket(): Promise<void> {
        return httpService.get('basketapi/basket'); // Response will be handled by the basket.interceptor
    }

    public async deleteBasketLineItem(lineItemId: string): Promise<void> {
        return httpService.delete('basketapi/lineitem?lineItemId=' + lineItemId + '&raptorSessionId=' + this.raptorSessionId);
    }

    public async deleteAllLineItems(): Promise<void> {
        return httpService.delete('basketapi/deleteAllLineItems?raptorSessionId=' + this.raptorSessionId);
    }

    public async copyBasketToAccount(payload: CopyToAccountRequest): Promise<BasketViewModel> {
        return httpService.post<BasketViewModel>('basketApi/copyBasketToAccount', payload);
    }

    public addOrUpdateSpecialOrderLineItem(payload: AddOrUpdateSpecialOrderLineItemRequest, messagesId: string): Promise<void> {
        return httpService.put('basketapi/specialorderlineitem', payload, messagesId);
    }

    public async quickAddToBasket(payload: QuickAddToBasketRequest, messagesId: string): Promise<QuickAddToBasketResultViewModel> {
        return httpService.put<QuickAddToBasketResultViewModel>('basketapi/quickaddtobasket?raptorSessionId=' + this.raptorSessionId, payload, messagesId);
    }

    public async submitOrder(payload: SubmitOrderRequest): Promise<void> {
        return httpService.post<void>('basketapi/submitOrder', payload);
    }

    public async addCommentToBasket(payload: AddCommentsRequest, messagesId: string): Promise<void> {
        return httpService.post('basketapi/comments', payload, messagesId);
    }

    public async getOrder(id: string): Promise<OrderViewModel> {
        return httpService.get('orderapi/order/' + id);
    }

    public convertToFavoriteOrder(payload: ConvertToFavoriteOrderRequest): Promise<void> {
        return httpService.put('basketapi/converttofavoriteorder', payload);
    }

    public async addQuotationToBasket(payload: AddQuotationToBasketRequest): Promise<BasketViewModel> {
        return httpService.post<BasketViewModel>('basketapi/addquotationtobasket?raptorSessionId=' + this.raptorSessionId, payload);
    }

    public async addOrderToBasket(payload: AddOrderToBasketRequest): Promise<BasketViewModel> {
        return httpService.post('basketApi/addOrderToBasket?raptorSessionId=' + this.raptorSessionId, payload);
    }

    public async addFavoriteOrderToBasket(favoriteOrderId: number): Promise<BasketViewModel> {
        return httpService.put('basketApi/addFavoriteOrderToBasket?favoriteOrderId=' + favoriteOrderId + '&raptorSessionId=' + this.raptorSessionId);
    }

    public async addProductCatalogToBasket(productCatalogId: number): Promise<BasketViewModel> {
        return httpService.put('basketApi/addProductCatalogToBasket?productCatalogId=' + productCatalogId + '&raptorSessionId=' + this.raptorSessionId);
    }

    public async addScannerListToBasket(scannerListId: number): Promise<BasketViewModel> {
        return httpService.put('basketApi/addScannerListToBasket?scannerListId=' + scannerListId + '&raptorSessionId=' + this.raptorSessionId);
    }

    public async addBomProducts(bomProductId: string): Promise<void> {
        return httpService.put('basketapi/addbomproducts?raptorSessionId=' + this.raptorSessionId, { bomProductId });
    }

    public async addEasySupplyOrderLines(easySupplyWarehouseId: number): Promise<void> {
        return httpService.put('basketApi/addEasySupplyOrderLines?raptorSessionId=' + this.raptorSessionId, { easySupplyWarehouseId });
    }

    public get raptorSessionId(): any | null {
        if (!isNotNullEmptyOrUndefined(window.raptor)) {
            return null;
        }

        return window.raptor.sessionID;
    }
}
