import Vue from 'vue';

function round(value: number, decimals: number) {
    if (!decimals) {
        decimals = 0;
    }

    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    return value.toLocaleString('da-DK');
}

Vue.filter('round', round);

export default round;
