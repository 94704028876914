import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import { FavoriteOrderDetailViewModel, FavoriteOrdersViewModel } from '@/types/serverContract';

@Mixin
export class FavoriteOrderCurtainMixin extends Vue {
    showAdministrationFavoriteOrderForm: boolean = false;
    pending: boolean = false;
    favoriteOrders: FavoriteOrdersViewModel | FavoriteOrderDetailViewModel | null = null;

    updateFavoriteOrders(newFavoriteOrders: FavoriteOrdersViewModel | FavoriteOrderDetailViewModel): void{
        this.favoriteOrders = newFavoriteOrders;
    };
}
