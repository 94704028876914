


































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    HelpDeskSalesAccountsViewObject,
    GetAccountForUpdateAllowNewUsersRequest,
    HelpDeskAccountAdministrationViewModel,
    UpdateAllowNewUsersOnAccountRequest,
    UpdateAllowNewUsersOnAccount
} from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class HelpDeskEditAccountAccess extends Vue {
    @Prop({
        required: true,
        type: Object
    }) selectedAccount!: HelpDeskSalesAccountsViewObject;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    account: HelpDeskAccountAdministrationViewModel | null = null;
    timeout: number | null = null;

    created(): void {
        this.getAccountForUpdateAllowNewUsers();
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async getAccountForUpdateAllowNewUsers(): Promise<void> {
        try {
            const payload: GetAccountForUpdateAllowNewUsersRequest = {
                soldToPartnerSalesAccountId: this.selectedAccount.salesAccountId
            };

            this.account = await Api.helpDesk.accountForUpdateAllowNewUsers(payload);
        } finally {
        }
    }

    async updateAllowNewUsersOnAccount(): Promise<void> {
        this.pending = true;
        if (!this.account) {
            return;
        }

        const payloadAccounts: UpdateAllowNewUsersOnAccount[] = [{
            allowNewUsers: this.account.allowNewUsers,
            salesAccountId: this.account.salesAccountId
        }];

        const payload: UpdateAllowNewUsersOnAccountRequest = {
            accounts: payloadAccounts
        };

        try {
            await Api.helpDesk.updateAllowNewUsersOnAccount(payload).then(() => {
                this.success = true;
                this.showSuccessIndicator();
            });
        } finally {
            this.pending = false;
        }
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;
        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    goBack(): void {
        this.$emit('goBack');
    }
}
