

















































































import Vue from 'vue';
import {
    UnitViewObject
} from '@/types/serverContract';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { orderableUnitText, IUnitData, IUnitPayload } from '@/project/products/productHelper.utils';
import { uuid, isNotNullEmptyOrUndefined } from '@/project/config/utilities';

@Component
export default class ProductOrderableUnit extends Vue {
    @Prop({ required: true, type: Object }) orderableUnit!: UnitViewObject;
    @Prop({ type: Boolean }) hideQuantityInput!: boolean;
    @Prop({ type: Boolean }) hideRadioInput!: boolean;
    @Prop({ type: Number }) quantity!: number;
    @Prop({ required: true, type: String }) selectedUnit!: string;
    @Prop({ type: Number }) fixedUnitValue!: number;
    @Prop({ type: Object }) unitPayload!: IUnitPayload;
    @Prop({ default: true, type: Boolean }) allowEdit!: boolean;

    incrementQuantityError: boolean = false;
    quantityError: boolean = false;

    staticUnitPayload: IUnitPayload = {
        unit: '',
        quantity: this.orderableUnit.increment,
        fixedUnitValue: this.fixedUnitValue ? this.fixedUnitValue
            : this.unitPayload ? this.unitPayload.fixedUnitValue
                : this.orderableUnit.isFixedUnit && this.orderableUnit.fixedUnitQuantity ? 0
                    : null
    };

    get id(): string {
        return uuid();
    }

    get orderableUnitText(): string {
        return orderableUnitText(this.orderableUnit);
    }

    created() {
        if (this.orderableUnit.unitKey === this.selectedUnit) {
            this.staticUnitPayload.unit = this.selectedUnit;
            this.staticUnitPayload.quantity = isNotNullEmptyOrUndefined(this.quantity) ? this.quantity : this.orderableUnit.increment;
        }

        if (this.quantity) {
            this.validateQuantityIncrement();
            this.validateQuantity();
        }
    }

    @Watch('selectedUnit')
    onSelectedUnitChange(selectedUnit: string) {
        let hasError = this.quantityError || this.incrementQuantityError;
        let doesNotMatchSelectedUnit = this.orderableUnit.unitKey !== selectedUnit;

        if (doesNotMatchSelectedUnit && hasError) {
            this.staticUnitPayload.quantity = this.orderableUnit.increment;
            this.validateQuantityIncrement();
        }
    }

    @Watch('quantity')
    onQuantityChange(updatedQuantity: number) {
        if (this.orderableUnit.unitKey === this.selectedUnit) {
            this.staticUnitPayload.quantity = updatedQuantity;
        }
    }

    updateAddToBasketPayload(): void {
        this.validateQuantity();
        this.validateQuantityIncrement();

        let unitHasChanged = this.staticUnitPayload.unit !== this.selectedUnit;
        let quantityHasChanged = this.staticUnitPayload.quantity !== this.quantity;
        let fixedUnitValueChanged = this.staticUnitPayload.fixedUnitValue !== this.fixedUnitValue;

        if (unitHasChanged || quantityHasChanged || fixedUnitValueChanged) {
            this.$emit('updateOrderableUnit', this.setUnitData());
        }
    }

    setUnitData(): IUnitData {
        this.staticUnitPayload.unit = this.orderableUnit.unitKey;
        return {
            unitPayload: this.staticUnitPayload,
            orderableUnit: this.orderableUnit
        };
    }

    setFocusOnRadioInput(): void {
        if (!this.$refs['radioInput']) {
            return;
        }
        const radioInput = this.$refs['radioInput'] as HTMLInputElement;
        radioInput.checked = true;

        this.updateAddToBasketPayload();
    }

    setFocusOnTextInput(): void {
        if (!this.$refs['textInput']) {
            return;
        }
        const textInput = this.$refs['textInput'] as HTMLInputElement;
        textInput.focus();
    }

    validateQuantityIncrement(): void {
    // Uses modulus to check if the current Quantity is fully divisible by the specified increment.
        const isInvalidIncrement = this.staticUnitPayload.quantity % this.orderableUnit.increment !== 0;
        this.incrementQuantityError = isInvalidIncrement;
        this.$emit('quantityError', isInvalidIncrement);
    }

    validateQuantity(): void {
        const isInvalidQuantity = this.staticUnitPayload.quantity < this.orderableUnit.increment;
        this.quantityError = isInvalidQuantity;
        this.$emit('quantityError', isInvalidQuantity);
    }
}
