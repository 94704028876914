




























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import AddToEasySupplyWarehouseForm from '@/project/easySupply/AddToEasySupplyWarehouseForm.vue';
import {
    UnitViewObject
} from '@/types/serverContract';

@Component({
    components: {
        ModalOverlay,
        AddToEasySupplyWarehouseForm
    }
})
export default class AddToEasySupplyWarehouse extends Vue {
    showModal: boolean = false;
    createNewEasySupplyWarehouse: boolean = false;

    @Prop({
        required: true,
        type: Array
    }) orderableUnits!: UnitViewObject[];

    @Prop({
        required: true,
        type: String
    }) variantId!: string;

    get headingLabel(): string {
        return this.createNewEasySupplyWarehouse ? this.$tr(this.$labels.Views.Services.EasySupply.AddProductWarehouseModal.CreateAndAddToWarehouse) : this.$tr(this.$labels.Views.Services.EasySupply.AddProductWarehouseModal.AddToWarehouse);
    }

    get addToEasySupplyUnits(): UnitViewObject[] {
        return this.orderableUnits.filter(unit => !unit.isFixedUnit);
    }

    toggleModal(): void {
        this.showModal = !this.showModal;
    }

    showCreateNewWarehouse(showCreate: boolean) {
        this.createNewEasySupplyWarehouse = showCreate;
    }

    closeModal(): void {
        this.showModal = false;
        this.createNewEasySupplyWarehouse = false;
    }
};
