import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { namespace } from 'vuex-class';
import { MaintenanceDocument } from '@/types/serverContract';

interface IReportsState {
    maintenanceDocuments: MaintenanceDocument[];
}

enum MutationMethod {
    addMaintenanceDocument = 'addMaintenanceDocument',
    deleteMaintenanceDocument = 'deleteMaintenanceDocument'
}

const state: IReportsState = {
    maintenanceDocuments: []
};

const getters: GetterTree<IReportsState, any> = {
    maintenanceDocuments: state => state.maintenanceDocuments
};

const actions: ActionTree<IReportsState, any> = {
    addMaintenanceDocument({ commit }, maintenanceDocument: MaintenanceDocument) {
        commit(MutationMethod.addMaintenanceDocument, maintenanceDocument);
    },
    deleteMaintenanceDocument({ commit }, maintenanceDocument: MaintenanceDocument) {
        commit(MutationMethod.deleteMaintenanceDocument, maintenanceDocument);
    }
};

const mutations: MutationTree<IReportsState> = {
    [MutationMethod.addMaintenanceDocument](state, maintenanceDocument: MaintenanceDocument) {
        const hasMatch = state.maintenanceDocuments.some(document => maintenanceDocument.hash === document.hash);
        if (maintenanceDocument && !hasMatch) {
            state.maintenanceDocuments.push(maintenanceDocument);
        }
    },
    [MutationMethod.deleteMaintenanceDocument](state, maintenanceDocument: MaintenanceDocument) {
        const hasMatch = state.maintenanceDocuments.some(document => maintenanceDocument.hash === document.hash);
        const itemIndex = state.maintenanceDocuments.findIndex(item => item.hash === maintenanceDocument.hash);

        if (hasMatch) {
            state.maintenanceDocuments.splice(itemIndex, 1);
        }
    }
};

export default {
    namespaced: true,
    state: state,
    getters,
    actions,
    mutations
};

export const { Getter: ReportsGetter, Action: ReportsAction } = namespace('reports');
