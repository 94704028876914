
































































































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import keyboardService from '@/core/keyCodes';
import { AppGetter, AppAction } from '@/store/app';
import LoginPanel from '@/project/authentication/LoginPanel.vue';
import SelectAccountPanel from '@/project/authentication/SelectAccountPanel.vue';
import {
    IFeatureToggling,
    SalesAccountViewObject,
    LinkViewModel,
    BasketViewModel
} from '@/types/serverContract';
import AuthenticationDesktopBasketTotal from '@/project/authentication/AuthenticationDesktopBasketTotal.vue';
import ToggleCtrl from '../form/ToggleCtrl.vue';
import { BasketGetter } from '@/store/basket';
import { FlexBoxGetter, FlexBoxAction } from '@/store/flexBox';
import serverContext from '@/core/serverContext.service';
import AuthenticationDesktopToggleSkeleton from '@/project/authentication/skeleton/AuthenticationDesktopToggleSkeleton.vue';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import MiniBasket from '@/project/basket/MiniBasket.vue';
import { BreakpointsMixin } from '@/core/responsive/breakpoints/breakpoints.mixin';

@Component({
    components: {
        AuthenticationDesktopBasketTotal,
        SelectAccountPanel,
        LoginPanel,
        ToggleCtrl,
        AuthenticationDesktopToggleSkeleton,
        OffCanvasOverlay,
        MiniBasket
    }
})
export default class AuthenticationDesktopToggle extends Mixins<BreakpointsMixin>(BreakpointsMixin) {
    @BasketGetter totalLines!: number;
    @BasketGetter basket!: BasketViewModel;
    @BasketGetter subTotal!: string;

    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @FlexBoxAction setShowFlexBoxModal!: (show: boolean) => void;

    @Prop({
        default: false,
        type: Boolean
    })
    hasShopLink!: boolean;

    @Prop({
        default: () => {},
        type: Object
    })
    shopLink!: LinkViewModel;

    @AppGetter currentAccount!: SalesAccountViewObject;
    @AppGetter isLoggedIn!: boolean;
    @AppGetter isLoginOverlayOpen!: boolean;
    @AppGetter featureToggling!: IFeatureToggling;

    @AppAction toggleLoginOverlay!: (forceClose) => void;

    overlayActive = false;
    overlayTimeout: number | null = null;
    donePromise: Promise<void> | null = null;
    donePromiseResolve: (() => void) | null = null;

    toggleIsRight: boolean = true;

    get basketPageUrl(): string {
        return serverContext.sitePages.basketPage.url;
    }

    get toggleState() {
        return ['toggle', this.toggleIsRight ? 'on-toggle-right' : 'on-toggle-left'];
    }

    get currentAccountName() {
        return this.currentAccount
            ? `${this.currentAccount.soldToPartnerId} ${this.currentAccount.soldToPartnerName}`
            : '';
    }

    get loginLabel(): string {
        return this.$tr(this.$labels.Views.Header.LoginButton.Small);
    }

    mounted() {
        document.addEventListener('keyup', this.keyHandler);
        this.toggleIsRight = !this.isInFlexBoxContext;
    }

    OnToggleChanged(value: boolean) {
        this.toggleIsRight = value;
    }

    OnToggleValidated() {
        this.setShowFlexBoxModal(true);
    }

    toggleContent(isRightContent: boolean) {
        if (isRightContent) {
            return this.toggleIsRight;
        }
        return !this.toggleIsRight;
    }

    // We have to update the toggles size when the price changes.
    // With this current implementation we do not check for changes between gross and sub prices, but it has been deemed a negligible issue.
    @Watch('subTotal')
    updateSizeOnSubTotalChange(): void {
        const toggle = this.$refs.toggle as ToggleCtrl;
        if (toggle) {
            toggle.init();
        }
    }

    keyHandler(event) {
        if (!keyboardService.isEscape(event)) {
            return;
        }
        this.close();
    }

    destroyed() {
        this.toggleLoginOverlay(false);
    }

    close() {
        this.toggleLoginOverlay(true);
    }
}
