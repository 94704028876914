





















































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
    IFeatureToggling,
    NavigationItem,
    NavigationViewModel,
    TopLevelNavigationItem,
    UserViewModel
} from '@/types/serverContract';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import serverContext from '@/core/serverContext.service';
import LoginPanel from '@/project/authentication/LoginPanel.vue';
import { AppGetter, AppAction } from '@/store/app';
import SelectAccountPanel from '@/project/authentication/SelectAccountPanel.vue';
import CurrentAccountInfo from '@/project/authentication/CurrentAccountInfo.vue';
import Logout from '@/project/authentication/Logout.vue';
import { FlexBoxGetter, FlexBoxAction } from '@/store/flexBox';
import tracking from '@/core/tracking/tracking.service';
import AccountAvatarCircle from '@/project/navigation/page-header/AccountAvatarCircle.vue';
import { UserGetter } from '@/store/user';
import MobileMenuFlexboxToggle from '@/project/navigation/page-header/pageHeaderComponents/MobileMenuFlexboxToggle.vue';

const MAX_LEVELS = 10;

@Component({
    components: {
        Logout,
        CurrentAccountInfo,
        SelectAccountPanel,
        LoginPanel,
        OffCanvasOverlay,
        AccountAvatarCircle,
        MobileMenuFlexboxToggle
    }
})
export default class PageHeaderMobileMenu extends Vue {
    @Prop({
        type: Boolean,
        default: false
    }) showLoginFromStart!: boolean;

    @Prop({
        type: Boolean,
        required: true
    }) menuOpen!: boolean;

    @Prop({
        type: Object,
        required: true
    }) navigation!: NavigationViewModel;

    @AppGetter isLoggedIn!: boolean;
    @AppGetter featureToggling!: IFeatureToggling;
    @AppAction setLoginPanelActive!: (active: boolean) => void;
    @UserGetter user!: UserViewModel;

    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @FlexBoxGetter flexBoxPage!: string;
    @FlexBoxGetter flexBoxButtonText!: string;
    @FlexBoxAction setShowFlexBoxModal!: (show: boolean) => void;

    breadcrumb: NavigationItem[] = [];
    activeLevel: number = 0;
    forward = true;
    showLogin = false;
    showAccounts = false;

    get showLoginButton() {
        return this.featureToggling.loginEnabled && !this.isLoggedIn;
    }

    get frontpageUrl(): string {
        return serverContext.sitePages.frontPage.url;
    }

    get activeParent(): NavigationItem | null {
        if (!this.breadcrumb.length) return null;
        return this.breadcrumb[this.activeLevel - 1];
    }

    get children() {
        return this.activeParent ? this.activeParent.children : [];
    }

    get onlyCatalog(): TopLevelNavigationItem[] {
        return this.navigation.main.filter((navItem: TopLevelNavigationItem) => navItem.isCatalog === true);
    }

    get secondaryMenuItems(): NavigationItem[] {
        return this.navigation.main.filter((navItem: TopLevelNavigationItem) => navItem.isCatalog === false && navItem.link.url !== this.navigation.myPage.link.url);
    }

    @Watch('menuOpen')
    onMenuOpenChange(value: boolean) {
        if (value) {
            this.showLogin = this.showLoginFromStart;
        }
    }

    @Watch('showLoginFromStart')
    onshowLoginFromStartChange(value: boolean) {
        if (value) {
            this.showLogin = value;
        }
    }

    @Watch('showLogin')
    onShowLoginChange(value: boolean) {
        this.setLoginPanelActive(value);
    }

    shouldShowNextLevel(navigationItem: NavigationItem): boolean {
        return navigationItem.children && navigationItem.children.length > 0 && this.activeLevel + 1 < MAX_LEVELS;
    }

    nextLevel(navigationItem: NavigationItem) {
        this.activeLevel++;
        this.breadcrumb.push(navigationItem);
        this.forward = true;
    }

    previousLevel() {
        this.activeLevel--;
        this.breadcrumb.pop();
        this.forward = false;
    }

    overlayShowChanged(open: boolean) {
        this.activeLevel = 0;
        this.$emit('update:menuOpen', open);
        this.breadcrumb = [];
        this.showLogin = false;
        this.showAccounts = false;
        this.forward = true;
    }

    closeOverlay() {
        (this.$refs.overlay as any).hide();
        this.overlayShowChanged(false);
    }

    onShowLogin(): void {
        this.showLogin = true;
        this.forward = true;
    }

    onShowAccounts(): void {
        this.showAccounts = true;
        this.forward = true;
    }

    goBack(): void {
        this.showAccounts = false;
        this.forward = false;
    }

    showFlexBoxModal() {
        tracking.trackFlexBoxButtonPress('Menu button, mobile', true);
        this.setShowFlexBoxModal(true);
    }
}
