




































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EventViewObject } from '@/types/serverContract';

@Component
export default class TrackAndTraceDetailsShipmentInfoEvent extends Vue {
    @Prop({
        required: true,
        type: Object
    }) shipmentEvent!: EventViewObject;

    isMapOpen: boolean = false;

    get hasMap() {
        return this.shipmentEvent.location.latitude !== 0 && this.shipmentEvent.location.longitude !== 0;
    }

    get shipmentEventLatLng(): object {
        return {
            lat: this.shipmentEvent.location.latitude,
            lng: this.shipmentEvent.location.longitude
        };
    }

    toggleMap() {
        this.isMapOpen = !this.isMapOpen;
    }
}
