interface WebClient {
    Modules: {
        Scanner: {
            readScannerDataResult: 'Success' | 'Error' | 'ScannerNotFound';
            version: '';
        };
    };
}

interface WebClientWindow extends Window {
    WebClient?: WebClient | undefined;
}

let loadCount = 0;

export class ScannerService {
    private async loadScript(src): Promise<WebClient> {
        loadCount++;
        let script: any = document.createElement('script');

        script.setAttribute('src', src + `?cacheBuster=${loadCount}`);

        if ((window as WebClientWindow).WebClient) {
            (window as WebClientWindow).WebClient = undefined;
        }

        const headNode = document.getElementsByTagName('head')[0];
        headNode.appendChild(script);

        return new Promise((resolve, reject) => {
            script.onload = function() {
                if ((window as WebClientWindow).WebClient !== undefined) {
                    resolve((window as WebClientWindow).WebClient);
                }
                headNode.removeChild(script);
            };
            script.onerror = reject;
        });
    }

    async getScannerVersion(src): Promise<string> {
        return this.loadScript(src).then(webClient => webClient.Modules.Scanner.version);
    }

    async getScannerData(src): Promise<string> {
        return this.loadScript(src).then(webClient => webClient.Modules.Scanner.readScannerDataResult);
    }
}
export default new ScannerService();
