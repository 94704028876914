












import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import BrandPageHeader from '@/project/brandPage/BrandPageHeader.vue';

@Component({
    components: { BrandPageHeader }
})
export default class BrandPageWrapper extends Vue {
    @Prop({
        type: String,
        required: true
    }) brandName!: string;

    @Prop({
        type: String
    }) brandNameColor!: string;

    @Prop({
        type: String
    }) brandBackgroundColor!: string;

    @Prop({
        type: String
    }) logoUrl!: string;
}
