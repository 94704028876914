








































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    AvailableAccountsForAdministrationViewModel,
    SalesAccountForAdministrationViewObject,
    UpdateAllowNewUsersOnAccountsRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit,
        SpinnerOverlay
    }
})
export default class AccountsForAdministrationList extends Vue {
    pending: boolean = false;
    availableAccountsForAdministration: AvailableAccountsForAdministrationViewModel | null = null;
    updateAllowNewUsersOnAccountsRequestPayload: UpdateAllowNewUsersOnAccountsRequest = {
        accounts: []
    };

    disableButton: boolean = true;

    get allowNewUsersLabel(): string {
        return this.$tr(this.$labels.Views.AccountAdministration.AllowNewUsers);
    }

    created() {
        this.getAvailableAccountsForAdministration();
    }

    async getAvailableAccountsForAdministration() {
        this.pending = true;
        try {
            this.availableAccountsForAdministration = await Api.account.availableAccountsForAdministration();
        } finally {
            this.pending = false;
        }
    }

    async submitAllowNewUsers() {
        this.pending = true;
        try {
            await Api.account.updateAllowNewUsersOnAccounts(this.updateAllowNewUsersOnAccountsRequestPayload).then(updatedAccountsForAdministration => {
                this.availableAccountsForAdministration = updatedAccountsForAdministration;
                this.updateAllowNewUsersOnAccountsRequestPayload.accounts = [];
                this.disableButton = true;
            });
        } finally {
            this.pending = false;
        }
    }

    onChangeAllowNewUsers(salesAccount: SalesAccountForAdministrationViewObject): void {
        const updateAllowNewUsersSalesAccountIndex = this.updateAllowNewUsersOnAccountsRequestPayload.accounts.findIndex(salesAccountForUpdate => {
            return salesAccountForUpdate.salesAccountId === salesAccount.salesAccountId;
        });
        if (updateAllowNewUsersSalesAccountIndex >= 0) {
            this.updateAllowNewUsersOnAccountsRequestPayload.accounts.splice(updateAllowNewUsersSalesAccountIndex, 1);
        } else {
            this.updateAllowNewUsersOnAccountsRequestPayload.accounts.push({
                allowNewUsers: salesAccount.allowNewUsers,
                salesAccountId: salesAccount.salesAccountId
            });
        }
        this.disableButton = this.updateAllowNewUsersOnAccountsRequestPayload.accounts.length === 0;
    }
}
