







import Vue from 'vue';
import Component from 'vue-class-component';
import { LoginInformation, SalesAccountViewObject } from '@/types/serverContract';
import { AppGetter } from '@/store/app';

@Component
export default class CheckoutAccount extends Vue {
    @AppGetter loginInformation!: LoginInformation;

    get currentActiveAccount(): SalesAccountViewObject | null {
        return this.loginInformation ? this.loginInformation.currentAccount : null;
    }
}
