export enum IMAGE_LOADING {
    Eager = 'eager',
    Lazy = 'lazy'
}

export enum BRIGHTNESS_MODE {
    Dark = 'Dark',
    Light = 'Light'
}

export enum AHLSELL_THEME {
    AhlsellBlue = 'Ahlsell Blue',
    AhlsellUnity = 'Ahlsell Unity',
    Custom = 'Custom'
}

export enum BUTTON_VARIANTS {
    BLACK = 'black',
    CUSTOM = 'custom',
    LINK = 'link',
    ORANGE = 'orange',
    PRIMARY = 'primary',
    QUATERNARY = 'quaternary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    WHITE = 'white'
}

export enum BUTTON_SIZE {
    NORMAL = 'normal',
    SMALL = 'small'
}

export enum BUTTON_ICON_SIZE {
    NORMAL = 'normal',
    SMALL = 'small'
}

export enum BUTTON_ICON_TYPE {
    ICON_ONLY = 'iconOnly',
    ICON_WITH_TEXT = 'iconWithText',
    NONE = 'none'
}

export enum BANNER_TYPE {
    ContentBanner = 'contentBanner',
    HeroBanner = 'heroBanner',
    LoginBanner = 'loginBanner',
    VideoBanner = 'videoBanner'
}

export enum ADJACENT_DIRECTION {
    Next = 'Next',
    Previous = 'Previous'
}

export enum USER_VISIBILITY {
    Both = 'Both',
    LoggedIn = 'Logged-in user',
    NonLoggedIn = 'Non-logged-in user'
}

export enum SPACINGS {
    LARGE = '48',
    MEDIUM = '32',
    SMALL = '24'
}

export enum PRODUCT_TRACKING_EVENT {
    AddToCart = 'add_to_cart',
    Checkout = 'begin_checkout',
    Pdp = 'view_item',
    ProductClick = 'select_item',
    ProductImpression = 'view_item_list',
    RemoveFromCart = 'remove_from_cart',
    TransactionComplete = 'purchase',
    checkoutOption = 'add_shipping_info'
}

export enum PROMO_TRACKING_EVENT {
    PromotionClick = 'select_promotion',
    PromotionImpression = 'view_promotion'
}

export enum PRODUCT_TRACKING_TYPE {
    AddToBasketRequest,
    AddLineItemViewObject,
    TrackProductClickViewModel,
    FavoriteOrderLineItemViewObject,
    ProductTileSlimViewObject,
    LineItemViewObject,
    ProductDetailsViewObject,
    ProductTileViewObject
}

export enum CREATE_UPDATE {
    Create = 'Create',
    Update = 'Update'
}

export enum PRODUCT_STATUS {
    All = 'All',
    NaN = 'NaN'
}

export enum SHARED_STATUS {
    Editable = 'Editable',
    Readonly = 'Readonly'
}

export enum MEDIA_TYPE {
    Image = 'Image',
    Video = 'Video'
}

export enum USER_MENU_ITEM {
    DeliveryNotes = 'DeliveryNotes',
    FavoriteOrders = 'FavoriteOrders',
    OrderSummary = 'OrderSummary'
}
