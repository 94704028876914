
























































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EasySupplyProcuredProductViewObject } from '@/types/serverContract';
import ModalOverlay from '@/project/shared/ModalOverlay.vue';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';
import CreateOrUpdateProcuredProduct from '@/project/easySupply/procuredProducts/CreateOrUpdateProcuredProduct.vue';
import AddProcuredProductsToWarehouse from '@/project/easySupply/procuredProducts/AddProcuredProductsToWarehouse.vue';
import keyboardService from '@/core/keyCodes';
import EasySupplyProcuredProductUsedIn from '@/project/easySupply/procuredProducts/EasySupplyProcuredProductUsedIn.vue';

@Component({
    components: {
        ModalOverlay,
        ButtonSubmit,
        CreateOrUpdateProcuredProduct,
        AddProcuredProductsToWarehouse,
        EasySupplyProcuredProductUsedIn
    }
})
export default class EasySupplyProcuredProduct extends Vue {
    @Prop({
        required: true,
        type: Object
    }) procuredProduct!: EasySupplyProcuredProductViewObject;

    showEditModal: boolean = false;
    showAddToWarehouseModal: boolean = false;
    showConfirmDeleteModal: boolean = false;
    pending: boolean = false;

    async deleteProcuredProduct() {
        this.pending = true;
        try {
            this.closeDeleteModal();
            const newEasySupplyProcuredProductsListViewModel = await Api.easySupply.deleteEasySupplyProcuredProduct(this.procuredProduct.id);
            this.$emit('updatedProcuredProductsModel', newEasySupplyProcuredProductsListViewModel);
        } finally {
            this.pending = false;
        }
    }

    openConfirmationModal() {
        this.showConfirmDeleteModal = true;
        document.addEventListener('keyup', this.keyHandler);
    }

    closeDeleteModal(): void {
        this.showConfirmDeleteModal = false;
        document.removeEventListener('keyup', this.keyHandler);
    }

    closeEditModal(): void {
        this.showEditModal = false;
    }

    closeAddToWarehouseModal(): void {
        this.showAddToWarehouseModal = false;
    }

    keyHandler(event) {
        if (keyboardService.isEnter(event)) {
            this.deleteProcuredProduct();
        }
    }
}
