import httpService from '@/core/http/http.service';
import {
    AccountGroupRequestViewModel,
    AccountGroupResponseSlimViewModel,
    AccountGroupResponseViewModel
} from '@/types/serverContract';

export default class AccountGroupController {
    public async GetAll(): Promise<AccountGroupResponseSlimViewModel[]> {
        return httpService.get<AccountGroupResponseSlimViewModel[]>('AccountGroupApi/GetAll');
    }

    public async Get(id: string): Promise<AccountGroupResponseViewModel> {
        return httpService.get<AccountGroupResponseViewModel>('AccountGroupApi/Get', { id });
    }

    public async Create(payload: AccountGroupRequestViewModel): Promise<void> {
        return httpService.post<void>('AccountGroupApi/Create', payload);
    }

    public async Update(id: string, payload: AccountGroupRequestViewModel): Promise<void> {
        return httpService.put<void>(`AccountGroupApi/Update/${id}`, payload);
    }

    public async Delete(id: string): Promise<void> {
        return httpService.delete<void>('AccountGroupApi/Delete', { id });
    }
}
