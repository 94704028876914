



































































































































































































































import Api from '@/project/http/api';
import {
    CaseInformationRequest,
    CreateOrUpdateFavoriteOrderRequest,
    FavoriteOrderDetailViewModel,
    FavoriteOrderProductIdsFilteredByCriteriaViewModel,
    GetIdsInFavoriteOrderBySourceFieldRequest,
    GetFilteredFavoriteOrderByProductIdsRequest,
    GetFavoriteOrderRequest,
    SourceFilter,
    SortByViewObject
} from '@/types/serverContract';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import FavoriteOrderProductTile from '@/project/favoriteOrders/FavoriteOrderProductTile.vue';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import QueryPaging from '@/project/shared/QueryPaging.vue';
import FavoriteOrderEditInformation from '@/project/favoriteOrders/FavoriteOrderEditInformation.vue';
import FavoriteOrderSearch from '@/project/favoriteOrders/FavoriteOrderSearch.vue';
import urlHelper from './urlHelperFavoriteOrders.service';
import scrollService from '@/core/scroll/scroll.service';
import { Route } from 'vue-router';
import ProductAddToBasketLifeCycleIcons from '@/project/products/ProductAddToBasketLifeCycleIcons.vue';
import FavoriteOrderActionLinks from '@/project/favoriteOrders/FavoriteOrderActionLinks.vue';
import AddFavoriteOrderToBasket from '@/project/favoriteOrders/AddFavoriteOrderToBasket.vue';
import SearchSorting from '@/project/search/SearchSorting.vue';
import FavoriteOrderQuickAddLineItem from '@/project/favoriteOrders/FavoriteOrderQuickAddLineItem.vue';
import FavoriteOrderAddSpecialLineItem from '@/project/favoriteOrders/FavoriteOrderAddSpecialLineItem.vue';
import FavoriteOrderSorting from '@/project/favoriteOrders/FavoriteOrderSorting.vue';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import serverContext from '@/core/serverContext.service';
import { FavoriteOrderCurtainMixin } from './mixins/FavoriteOrderCurtain.mixin';
import CurtainOverlay from '@/components/organisms/CurtainOverlay/CurtainOverlay.vue';
import FavoriteOrderAdministrationOverlay from '@/components/molecules/FavoriteOrders/FavoriteOrderAdministrationOverlay.vue';
import { UserGetter } from '@/store/user';
import Cookies from 'js-cookie';

type tabs = 'specialLineItem' | 'quickAddLineItem';

export class IconText {
    constructor(icon, text) {
        this.icon = icon;
        this.text = text;
    }

    icon: string;
    text: string;
}

interface IFilterMethod {
    filter: string;
    label: string;
}

@Component({
    components: {
        FavoriteOrderSorting,
        SearchSorting,
        AddFavoriteOrderToBasket,
        FavoriteOrderActionLinks,
        ProductAddToBasketLifeCycleIcons,
        FavoriteOrderEditInformation,
        FavoriteOrderSearch,
        QueryPaging,
        FavoriteOrderProductTile,
        SpinnerOverlay,
        FavoriteOrderQuickAddLineItem,
        FavoriteOrderAddSpecialLineItem,
        FavoriteOrderAdministrationOverlay,
        CurtainOverlay
    }
})
export default class FavoriteOrder extends Mixins<FavoriteOrderCurtainMixin>(
    FavoriteOrderCurtainMixin
) {
    favoriteOrder: FavoriteOrderDetailViewModel | null = null;
    existingProducts: FavoriteOrderProductIdsFilteredByCriteriaViewModel | null = null;
    favoriteOrderDisplayName: string = '';
    currentPage: number = 1;
    pending: boolean = false;
    showEditInformation: boolean = false;
    activeTabs: tabs = 'quickAddLineItem';
    showEditFavoriteOrderForm: boolean = false;
    filterMethodOptions: IFilterMethod[] = [
        {
            filter: this.$enums.PRODUCT_STATUS.All,
            label: 'Alle'
        },
        {
            filter: this.$enums.PRODUCT_STATUS.NaN,
            label: 'Udgåede'
        }
    ];

    activeFilterMethod: IFilterMethod = { ...this.filterMethodOptions[0] }

    @UserGetter isAdmin!: boolean;

    FavoriteOrderRequestPayload: CreateOrUpdateFavoriteOrderRequest =
    new (class CreateOrUpdateFavoriteOrderRequest {
        caseId: string = '';
        name: string = '';
        favoriteOrderId: number | null = null;
        referenceId: string = '';
        requestId: string = '';
        readonly: boolean = true;
        accountGroupIds: string[] = [];
        shared: boolean = false;
    })();

    get sharedText(): string {
        if (!this.favoriteOrder || !this.favoriteOrder.shared) {
            return '';
        }
        return this.favoriteOrder.readOnly
            ? this.$tr(this.$labels.Views.FavoriteOrder.Header.Shared.ReadOnly)
            : this.$tr(this.$labels.Views.FavoriteOrder.Header.Shared.AllowEdit);
    }

    get tryGetEditIcon(): IconText {
        if (!this.favoriteOrder) {
            return new IconText('sanicon-ico-eye invisible', '');
        }
        if (this.favoriteOrder.readOnly && this.favoriteOrder.shared) {
            return new IconText(
                'sanicon-ico-eye',
                this.$tr(this.$labels.Views.FavoriteOrder.Shared.ReadOnly)
            );
        }
        return new IconText(
            'sanicon-ico-edit',
            this.$tr(this.$labels.Views.FavoriteOrder.Shared.AllowEdit)
        );
    }

    get hasFavoriteOrderInfo(): boolean {
        return isNotNullEmptyOrUndefined(this.favoriteOrder);
    }

    get favoriteOrderInformation(): CreateOrUpdateFavoriteOrderRequest | null {
        if (this.favoriteOrder === null) {
            return null;
        }
        return {
            caseId: this.favoriteOrder.caseId ? this.favoriteOrder.caseId : '',
            favoriteOrderId: this.favoriteOrder.id,
            name: this.favoriteOrder.name,
            referenceId: this.favoriteOrder.referenceId ? this.favoriteOrder.referenceId : '',
            requestId: this.favoriteOrder.requestId ? this.favoriteOrder.requestId : '',
            shared: this.favoriteOrder.shared,
            readonly: this.favoriteOrder.readOnly,
            accountGroupIds: this.favoriteOrder.accountGroups.map(group => group.id)
        } as CreateOrUpdateFavoriteOrderRequest;
    }

    get sortingOptions(): SortByViewObject[] | null {
        return this.favoriteOrder ? this.favoriteOrder.sortingOptions : null;
    }

    get caseInformation(): CaseInformationRequest | null {
        if (this.favoriteOrder === null) {
            return null;
        }

        return {
            reference: this.favoriteOrder.referenceId,
            requisitionNumber: this.favoriteOrder.requestId,
            caseNumber: this.favoriteOrder.caseId
        } as CaseInformationRequest;
    }

    get favoriteOrdersPage(): string {
        return serverContext.sitePages.favoriteOrdersPage.url;
    }

    public $refs!: {
        favoriteOrderAdministrationOverlay: HTMLElement;
        FavoriteOrderProductTileList: HTMLElement;
    };

    get favoriteOrderSubheaderFormatted(): string {
        if (!this.favoriteOrder) {
            return '';
        }
        const subheader = {
            Products: this.favoriteOrder.totalResults,
            ProductsTextSingle: this.$tr(
                this.$labels.Views.FavoriteOrder.Header.ProductsTextSingle
            ),
            ProductsTextMultiple: this.$tr(
                this.$labels.Views.FavoriteOrder.Header.ProductsTextMultiple
            ),
            Owner: this.favoriteOrder.owner,
            CreatedText: this.$tr(this.$labels.Views.FavoriteOrder.Header.Created),
            Created: this.favoriteOrder.createdDate
        };
        const productsText =
            subheader.Products === 1
                ? subheader.ProductsTextSingle
                : subheader.ProductsTextMultiple;
        return `${subheader.Products} ${productsText} - ${subheader.Owner} - ${subheader.CreatedText} ${subheader.Created}`;
    }

    // TODO SECOM-1890: Remove this section once account groups has been tested thoroughly.
    get accountGroupsEnabled(): boolean {
        return !!Cookies.get('showAccountGroups');
    }

    created() {
        this.getOrderInfo();
    }

    prepareForm(open: boolean) {
        if (open) {
            const payload = Object.assign({}, this.favoriteOrderInformation);
            if (payload) {
                this.FavoriteOrderRequestPayload = payload;
            }
        }
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute.path) {
            return;
        }
        this.getOrderInfo().then(() =>
            scrollService.scrollToElement(this.$refs.FavoriteOrderProductTileList, -100)
        );
    }

    async onFilterMethodChange(option: IFilterMethod) {
        this.activeFilterMethod.filter = option.filter;
        urlHelper.setPageNumber(1);
        this.getOrderInfo();
    }

    async saveFavoriteOrder() {
        this.pending = true;
        try {
            await Api.favoriteOrder
                .updateFavoriteOrder(this.FavoriteOrderRequestPayload)
                .then(() => this.$emit('submitSuccess'));
        } finally {
            this.pending = false;
            this.showAdministrationFavoriteOrderForm = false;
            this.getOrderInfo();
        }
    }

    async getPriliminaryInfo() {
        try {
            const searchPayload: GetIdsInFavoriteOrderBySourceFieldRequest = {
                favoriteOrderId: urlHelper.getFavoriteOrderId(),
                sourceFilter: SourceFilter.Id
            };
            this.existingProducts = await Api.favoriteOrder.getIdsInFavoriteOrderBySourceField(searchPayload);
        } finally {
            this.pending = false;
        }
    }

    async getOrderInfo() {
        const favoriteOrderId: number = urlHelper.getFavoriteOrderId();

        if (!favoriteOrderId) {
            this.redirectToFavoriteOrdersPage();
            return;
        }

        this.pending = true;
        try {
            switch (this.activeFilterMethod.filter) {
                case this.$enums.PRODUCT_STATUS.All:
                    this.favoriteOrder = await this.getAllProducts(favoriteOrderId);
                    break;
                case this.$enums.PRODUCT_STATUS.NaN:
                    this.favoriteOrder = await this.getFitProducts(favoriteOrderId);
                    break;
                default:
                    this.favoriteOrder = await this.getAllProducts(favoriteOrderId);
                    break;
            }
            this.favoriteOrderDisplayName = this.favoriteOrder.name;
            this.currentPage = urlHelper.getPageNumber();
        } finally {
            this.pending = false;
        }
    }

    async getAllProducts(favoriteOrderId: number): Promise<FavoriteOrderDetailViewModel> {
        const searchPayload: GetFavoriteOrderRequest = {
            favoriteOrderId: favoriteOrderId,
            lineSearchTerm: urlHelper.getSearchTerm(),
            page: urlHelper.getPageNumber(),
            sortBy: urlHelper.getSorting()
        };
        return Api.favoriteOrder.getFavoriteOrder(searchPayload);
    }

    async getFitProducts(favoriteOrderId: number): Promise<FavoriteOrderDetailViewModel> {
        await this.getPriliminaryInfo();

        if (!this.existingProducts) {
            this.existingProducts = {
                fitProducts: [],
                nonFitProducts: []
            };
        }

        const searchPayload: GetFilteredFavoriteOrderByProductIdsRequest = {
            favoriteOrderId: favoriteOrderId,
            productIds: this.existingProducts.nonFitProducts,
            page: urlHelper.getPageNumber(),
            sortBy: urlHelper.getSorting()
        };
        return Api.favoriteOrder.getFilteredFavoriteOrderByProductIds(searchPayload);
    }

    redirectToFavoriteOrdersPage() {
        this.$router.push(this.favoriteOrdersPage);
    }

    openEditInformation() {
        this.showEditInformation = true;
    }

    closeEditInformation() {
        this.showEditInformation = false;
    }

    updateDisplayedFavoriteOrderName(displayName: string) {
        this.favoriteOrderDisplayName = displayName;
    }

    updateFavoriteOrder() {
        this.getOrderInfo();
    }
}
