import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import { AppGetter } from '@/store/app';
import { isNotNullEmptyOrUndefined } from '@/project/config/utilities';
import { LoginInformation, ScalePriceViewObject } from '@/types/serverContract';
import lowerFirst from 'lodash-es/lowerFirst';

export interface PriceServiceViewObject {
    grossPrice: string;
    netPrice: string;
}

@Mixin
export class PriceServiceMixin extends Vue {
    @AppGetter hideNetPrices!: boolean;
    @AppGetter canSeeNetPrices!: boolean;
    @AppGetter canSeeGrossPrices!: boolean;
    @AppGetter loginInformation!: LoginInformation;

    price: PriceServiceViewObject | null = null;
    isGrossPrimaryPrice: boolean = false;

    get hasPrice(): boolean {
        return this.price !== null && (isNotNullEmptyOrUndefined(this.price.netPrice) || isNotNullEmptyOrUndefined(this.price.grossPrice));
    }

    get shouldShowNetPrice(): boolean {
        return !this.hideNetPrices && this.canSeeNetPrices;
    }

    get primaryPrice(): string | null {
        if (this.hasPrice) {
            if (this.shouldShowNetPrice && isNotNullEmptyOrUndefined(this.price!.netPrice)) {
                this.isGrossPrimaryPrice = false;
                return this.price!.netPrice;
            } else if (this.canSeeGrossPrices && isNotNullEmptyOrUndefined(this.price!.grossPrice)) {
                this.isGrossPrimaryPrice = true;
                return this.price!.grossPrice;
            }
            return null;
        }
        return null;
    }

    get secondaryPrice(): string | null {
        if (this.hasPrice) {
            if (this.shouldShowNetPrice && this.canSeeGrossPrices && isNotNullEmptyOrUndefined(this.price!.grossPrice)) {
                return this.price!.grossPrice;
            }
            return null;
        }
        return null;
    }

    scalePriceToShow(scalePrice: ScalePriceViewObject): string | null {
        if (this.shouldShowNetPrice && isNotNullEmptyOrUndefined(scalePrice.netPrice)) {
            return scalePrice.netPrice;
        } else if (this.canSeeGrossPrices && isNotNullEmptyOrUndefined(scalePrice.grossPrice)) {
            return scalePrice.grossPrice;
        }
        return null;
    }

    scaleDiscountPercentageToShow(scalePrice: ScalePriceViewObject): string | null {
        if (this.shouldShowNetPrice && isNotNullEmptyOrUndefined(scalePrice.netPrice)) {
            return scalePrice.netPriceDiscountPercentage;
        } else if (this.canSeeGrossPrices && isNotNullEmptyOrUndefined(scalePrice.grossPrice)) {
            return scalePrice.grossPriceDiscountPercentage;
        }
        return null;
    }

    hasScalePrices(scalePrices: ScalePriceViewObject[] | null): boolean {
        if (scalePrices && scalePrices.length > 0) {
            // hide scale prices when only gross prices are visible and they are all equal
            if (!this.shouldShowNetPrice && this.isAllGrossScalePricesEqual(scalePrices)) {
                return false;
            }
            return true;
        }
        return false;
    }

    isAllGrossScalePricesEqual(scalePrices: ScalePriceViewObject[]): boolean {
        const grossScalePrices = scalePrices.map(function(scalePrice: ScalePriceViewObject) {
            return scalePrice.grossPrice;
        });
        const isEqual = grossScalePrices.some(function(grossScalePrice, ix) {
            return grossScalePrices.indexOf(grossScalePrice) !== ix;
        });
        return isEqual;
    }

    formattedChargeDescription(description: string): string {
        const firstWord = description.split(/[\s-]+/).shift();
        if (firstWord && firstWord.toUpperCase() !== firstWord) {
            // lowercase first letter if first word isn't an abbreviation
            return lowerFirst(description);
        }
        return description;
    }
}
