



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    EasySupplyOrderLineViewObject,
    DeleteEasySupplyOrderLineRequest
} from '@/types/serverContract';
import Api from '@/project/http/api';
import ButtonSubmit from '@/project/form/ButtonSubmit.vue';

@Component({
    components: {
        ButtonSubmit
    }
})
export default class DeleteOrderLine extends Vue {
    @Prop({
        required: true,
        type: Object
    }) orderLineItem!: EasySupplyOrderLineViewObject;

    @Prop({
        required: true,
        type: Number
    }) warehouseId!: number;

    pending: boolean = false;
    success: boolean = false;
    successTimer: number = 3000;
    timeout: number = 0;

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async deleteOrderLine() {
        if (this.pending) return;

        this.pending = true;

        const payload: DeleteEasySupplyOrderLineRequest = {
            easySupplyOrderLineId: this.orderLineItem.id,
            easySupplyWarehouseId: this.warehouseId
        };

        try {
            await Api.easySupply.deleteEasySupplyOrderLine(payload);
            this.showSuccessIndicator();
            this.refreshOrderLineList();
            this.hideDeleteOrderLine();
        } finally {
            this.pending = false;
        }
    }

    hideDeleteOrderLine(): void {
        this.$emit('hideDeleteOrderLine');
    }

    refreshOrderLineList(): void {
        this.$emit('refreshOrderLineList');
    }

    showSuccessIndicator() {
        this.pending = false;
        this.success = true;

        this.timeout = setTimeout(() => {
            this.success = false;
        }, this.successTimer);
    }

    vvErrors!: any;
}
