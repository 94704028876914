







































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import Cookies from 'js-cookie';
import Api from '@/project/http/api';
import ForgotPasswordPanel from '@/project/authentication/ForgotPasswordPanel.vue';
import SpinnerOverlay from '@/project/spinners/SpinnerOverlay.vue';
import serverContext from '@/core/serverContext.service';

const rememberUserNameCookieKey = 'sani-username';
@Component({
    components: {
        SpinnerOverlay,
        ForgotPasswordPanel
    }
})
export default class LoginPage extends Vue {
    pending = false;
    rememberUsername = false;
    username = '';
    password = '';
    forgotPasswordActive = false;
    createUserUrl: string = serverContext.sitePages.createUserPage.url;

    created() {
        const username = Cookies.get(rememberUserNameCookieKey);
        if (username) {
            this.username = username;
            this.rememberUsername = true;
        }
    }

    @Watch('rememberUsername')
    onRememberUsernameChange(value: boolean) {
        if (!value) {
            Cookies.remove(rememberUserNameCookieKey);
        }
    }

    async login() {
        this.pending = true;
        try {
            if (this.rememberUsername) {
                Cookies.set(rememberUserNameCookieKey, this.username);
            }
            await Api.authentication.loginWithRedirectToRoot(this.username, this.password, 'login');
        } catch (e) {
            // Ok for validation-msgs.
        } finally {
            this.pending = false;
        }
    }
}
