






































































































































































































































































































































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    PriceViewModel,
    PriceViewObject,
    GetPriceRequest,
    ProductDetailsViewObject,
    ProductImageViewObject,
    StoresStockQuantityViewModel,
    StoreStockQuantityViewObject,
    UnitViewObject,
    UpdateOrCreateBuyerItemRequest,
    VariantDetailsViewObject,
    AvailabilityStatus,
    ProductTileViewObject
} from '@/types/serverContract';
import { AppGetter } from '@/store/app';
import { router } from '@/router';
import { IRelatedProductGroups } from '@/project/products/productHelper.utils';
import translateFilter from '@/core/translation/translate.filter';
import Api from '@/project/http/api';
import ProductVariantPicker from '@/project/products/ProductVariantPicker.vue';
import OffCanvasOverlay from '@/core/offcanvas-overlay/OffCanvasOverlay.vue';
import ProductDetailsImagesCarousel from '@/project/products/carousels/ProductDetailsImagesCarousel.vue';
import ProductDetailsImagesThumbs from '@/project/products/ProductDetailsImagesThumbs.vue';
import ProductDetailsPrices from '@/project/products/ProductDetailsPrices.vue';
import ProductDetailsTabs from '@/project/products/ProductDetailsTabs.vue';
import ProductInventory from '@/project/products/ProductInventory.vue';
import ProductDetailsPricesSkeleton from '@/project/shared/skeleton/ProductDetail/ProductDetailsPricesSkeleton.vue';
import ProductAddToBasket from '@/project/products/ProductAddToBasket.vue';
import ProductDetailsPrintAsLabel from '@/project/products/ProductDetailsPrintAsLabel.vue';
import ProductNudgingTile from '@/project/products/ProductNudgingTile.vue';
import tracking from '@/core/tracking/tracking.service';
import scrollService from '@/core/scroll/scroll.service';
import { headerHeight } from '@/core/responsive/breakpoints/breakpoints.service';
import serverContext from '@/core/serverContext.service';
import ProductDetailsCanBeReplacedByProduct from '@/project/products/ProductDetailsCanBeReplacedByProduct.vue';
import { FlexBoxGetter } from '@/store/flexBox';
import ProductCo2Info from '@/project/products/ProductCo2Info.vue';
import SpinnerElement from '@/project/spinners/SpinnerElement.vue';
import EcoCertificationsBadges from '@/project/products/EcoCertificationsBadges.vue';
import bus from '@/core/bus';
import product, { // eslint-disable-line @typescript-eslint/no-unused-vars
    ProductGetter,
    ProductAction
} from '@/store/product';
import CopyTooltip from '@/project/tooltip/copyTooltip.vue';
import productTrackingService, { TrackedProduct, TrackedTrackingContext } from '@/core/tracking/productTracking.service';
import { PRODUCT_TRACKING_EVENT, PRODUCT_TRACKING_TYPE } from '@/core/enums/enums';

@Component({
    components: {
        ProductCo2Info,
        ProductDetailsPrintAsLabel,
        ProductAddToBasket,
        ProductInventory,
        ProductVariantPicker,
        ProductNudgingTile,
        OffCanvasOverlay,
        ProductDetailsImagesCarousel,
        ProductDetailsImagesThumbs,
        ProductDetailsPrices,
        ProductDetailsTabs,
        ProductDetailsPricesSkeleton,
        ProductDetailsCanBeReplacedByProduct,
        SpinnerElement,
        CopyTooltip,
        EcoCertificationsBadges
    }
})
export default class ProductDetails extends Vue {
    @Prop({
        required: true,
        type: Object
    }) product!: ProductDetailsViewObject;

    @AppGetter isLoggedIn!: boolean;
    @FlexBoxGetter isInFlexBoxContext!: boolean;
    @ProductGetter productSelectedVariant!: VariantDetailsViewObject | null;
    @ProductGetter productStoreStocks!: StoreStockQuantityViewObject[] | null;
    @ProductAction setProductSelectedVariant!: (selectedVariant: VariantDetailsViewObject | null) => void;
    @ProductAction setProduct!: (product: ProductDetailsViewObject | null) => void;
    @ProductAction setProductStoreStocks!: (storeStocks: StoreStockQuantityViewObject[] | null) => void;

    // selectedVariant: VariantDetailsViewObject | null = null;
    customerProductId: string = '';
    showCustomerProductIdField: boolean = false;
    activateFullScreen: boolean = false;
    activeIndex: number = 0;
    storePending: boolean = false;
    pricesPending: boolean = false;
    customerProductNrPending: boolean = false;
    prices: PriceViewObject[] = [];
    pricesModel: PriceViewModel | null = null;
    priceServiceAvailable: boolean = false;
    timeout: number | null = null;
    productDetailsTabs: HTMLElement|null = null;
    relatedProduct: ProductTileViewObject | null = null;
    nudgingShow: boolean = false;
    showNudgingProductIfAny: boolean = true;
    renderNudgingProduct: boolean = false;
    isFormTransitioning: boolean = false;
    currentBuyerItemId: string = '';
    trackingCompleted: boolean = false;

    mounted() {
        this.$nextTick(() => {
            this.productDetailsTabs = document.getElementById('product-details-tabs');
        });
    }

    get variantId(): string {
        if (this.productSelectedVariant) {
            return this.productSelectedVariant.ids.variantId;
        }
        return router.currentRoute.query['variantId'] as string || '';
    }

    get myStore(): StoreStockQuantityViewObject | undefined {
        return this.productStoreStocks ? this.productStoreStocks.find(store => store.isMyStore) : undefined;
    }

    get variantForPrimaryProperties(): VariantDetailsViewObject | null {
        if (this.productSelectedVariant != null) {
            return this.productSelectedVariant;
        }
        return this.product.variants[0];
    }

    get storeUnitCode(): string {
        if (this.myStore && this.myStore.unitCode) {
            return translateFilter(`unit.${this.myStore.unitCode}`);
        }
        if (this.productSelectedVariant !== null) {
            return translateFilter(`unit.${this.productSelectedVariant!.units[0].unitCode}`);
        }
        return this.$tr(this.$labels.Unit.EA);
    }

    get defaultImages(): ProductImageViewObject[] {
        if (this.productSelectedVariant && this.productSelectedVariant.images) {
            return this.productSelectedVariant.images;
        }
        if (this.product.variants && this.product.variants[0].images) {
            return this.product.variants[0].images;
        }
        return [
            {
                url: '',
                basketThumbnailUrl: '',
                enlargedImageUrl: '',
                largeImageUrl: '',
                mailImageUrl: '',
                productImageUrl: '',
                thumbnailUrl: ''
            } as ProductImageViewObject
        ];
    }

    get productVariants(): VariantDetailsViewObject[] {
        return this.product.variants;
    }

    get orderableUnits(): UnitViewObject[] {
        return this.productSelectedVariant && this.productSelectedVariant.units ? this.productSelectedVariant.units : [];
    }

    get hasCustomerProductId(): boolean {
        return this.customerProductId !== '';
    }

    get currentPrice(): PriceViewObject | null {
        return this.prices.length ? this.prices[0] : null;
    }

    get relatedProductGroups(): IRelatedProductGroups | null {
        if (this.productSelectedVariant) {
            return {
                relatedProducts: this.productSelectedVariant.relatedProducts,
                alternativeProducts: this.productSelectedVariant.alternativeProducts,
                spareParts: this.productSelectedVariant.spareParts
            };
        } else {
            return null;
        }
    }

    get isOrderable(): boolean {
        if (this.currentPrice === null) {
            return false;
        }
        if (this.isInFlexBoxContext && this.currentPrice.stockStatus.status !== AvailabilityStatus.InStock) {
            return false;
        }
        return this.currentPrice.stockStatus.isOrderable;
    }

    get selectedPage(): string | null{
        return serverContext.sitePages.selectedPage ? serverContext.sitePages.selectedPage.url : null;
    }

    get nudgePDPVisibility(): boolean {
        return this.renderNudgingProduct && this.showNudgingProductIfAny && serverContext.nudgeOnPDP;
    }

    onAddedToBasket() {
        // if there was no nudging product then showNudgingProductIfAny will be set to false in onNudgingHaveRecommendedProduct
        if (!this.renderNudgingProduct && this.showNudgingProductIfAny) {
            // load nudging product if any
            this.renderNudgingProduct = true;
        } else if (this.showNudgingProductIfAny) {
            // if adding multiple items to the basket and we know there is a nudging product then scroll to it
            this.scrollToNudgingProduct();
        }
    }

    onNudgingShowUpdate(value: boolean) {
        this.nudgingShow = value;
        if (!this.nudgingShow) {
            return;
        }
        if (this.prices) {
            tracking.trackNudgingForSalesExpand(this.prices[0].netPrice, this.product.productId, 'PDP');
        }
    }

    onNudgingHasRecommendedProduct(value: boolean) {
        // if nudging have recommended product we want to scroll to it
        this.showNudgingProductIfAny = value;
        if (this.showNudgingProductIfAny) {
            this.scrollToNudgingProduct();
        }
    }

    scrollToNudgingProduct() {
        // scroll to nudging product
        if (this.nudgingShow) {
            const ProductNudgingTileComponent = this.$refs.ProductNudgingTile as ProductNudgingTile;
            scrollService.scrollToElement(ProductNudgingTileComponent.$el as HTMLElement);
        } else {
            const ProductNudgingTileComponent = this.$refs.ProductNudgingTile as ProductNudgingTile;
            ProductNudgingTileComponent.toggleShow();
        }
    }

    async created() {
        this.setProduct(this.product);

        if (this.product.variants.length === 1) {
            this.setVariant(this.product.variants[0].ids.variantId);
            this.currentBuyerItemId = this.product.variants[0].ids.customerProductId;
        } else {
            this.setVariant('');
        }
    }

    destroyed() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    async getStoresForProduct() {
        if (!this.productSelectedVariant) {
            return;
        }

        this.storePending = true;
        let storeStockViewModel: StoresStockQuantityViewModel | null = null;

        try {
            storeStockViewModel = await Api.stores.getStoreStockQuantity(this.productSelectedVariant.ids.variantId);
        } finally {
            this.storePending = false;
            if (storeStockViewModel) {
                this.setProductStoreStocks(storeStockViewModel.storeStockQuantityViewObject);
            }
        }
    }

    async addCustomerProductId() {
        if (this.productSelectedVariant === null) {
            return;
        }

        if (this.customerProductId === this.currentBuyerItemId) {
            this.showCustomerProductIdField = false;
            return;
        }

        let error;
        const payload: UpdateOrCreateBuyerItemRequest = {
            variantId: this.productSelectedVariant.ids.variantId,
            customerProductId: this.customerProductId
        };

        try {
            this.customerProductNrPending = true;
            await Api.buyerItem.updateOrCreate(payload);
        } catch (e) {
            error = e;
            this.customerProductNrPending = false;
            return;
        } finally {
            this.customerProductNrPending = false;
            if (!error) {
                this.showCustomerProductIdField = false;
                this.currentBuyerItemId = this.customerProductId;
            }
        }
    }

    async getPricesForVariant(selectedVariant: VariantDetailsViewObject) {
        if (!this.productSelectedVariant || !this.isLoggedIn) {
            this.trackProductDetailImpression();
            return;
        }

        this.pricesPending = true;

        const payload: GetPriceRequest = {
            priceRequests: [
                {
                    id: selectedVariant.ids.variantId,
                    availabilityType: selectedVariant.availabilityType,
                    certificateCode: selectedVariant.certificateCode,
                    isSurplus: selectedVariant.isSurplus,
                    isTemporarilyPhasedOut: selectedVariant.isTemporarilyPhasedOut,
                    isVendorUnableToDeliver: selectedVariant.isVendorUnableToDeliver
                }
            ]
        };

        try {
            this.pricesModel = await Api.prices.prices(payload);

            if (this.pricesModel.prices.length) {
                this.prices = this.pricesModel.prices;
            }
            this.priceServiceAvailable = this.pricesModel.priceServiceAvailable;
        } finally {
            this.pricesPending = false;
            if (!this.trackingCompleted) {
                this.trackProductDetailImpression();
                this.trackingCompleted = true;
            }
        }
    }

    toggleFullScreen(): void {
        this.activateFullScreen = !this.activateFullScreen;
    }

    setVariant(variantId: string, setQuery: boolean = false): void {
        if (variantId === '') {
            this.setProductSelectedVariant(null);
            this.setProductStoreStocks(null);
            return;
        }

        const productVariant = this.productVariants.find(productVariant => productVariant.ids.variantId === variantId);

        if (!productVariant) {
            return;
        }

        // Setting product selected variations in store
        this.setProductSelectedVariant(productVariant);

        if (this.productSelectedVariant) {
            this.customerProductId = this.productSelectedVariant.ids && this.productSelectedVariant.ids.customerProductId ? this.productSelectedVariant.ids.customerProductId : '';

            if (this.isLoggedIn) {
                this.getStoresForProduct();
            }

            this.getPricesForVariant(this.productSelectedVariant);
            if (setQuery) {
                this.setVariantIdQuery(variantId);
            }
        }
    }

    setVariantIdQuery(variantId: string): void {
        const query = { ...router.currentRoute.query };

        query['variantId'] = variantId;

        router.replace({ query });
    }

    toggleCustomerProductIdField(): void {
        this.showCustomerProductIdField = !this.showCustomerProductIdField;
    }

    updateSliderIndex(index: number): void {
        this.activeIndex = index;
    }

    scrollToProductDetailsTabs() {
        scrollService.scrollToElement(this.productDetailsTabs as HTMLElement);
        bus.emit('open-product-details-tab', 'productSpecs');
    }

    showAllStoreStockForProduct(): void {
        const scrollDuration = 200;
        scrollService.scrollToElement(this.productDetailsTabs as HTMLElement, -headerHeight(true), scrollDuration);
        bus.emit('open-product-details-tab', 'productsStock');
    }

    print(): void {
        window.print();
    }

    isSelectedOrUniqueSticker(stickerId: string): boolean {
        return stickerId === 'selected' || stickerId === 'selected-unique';
    }

    trackProductDetailImpression(): void{
        productTrackingService.TrackProduct(
            PRODUCT_TRACKING_EVENT.Pdp,
            productTrackingService.ToTrackedProduct(
                this.product,
                PRODUCT_TRACKING_TYPE.ProductDetailsViewObject,
                this.currentPrice,
                new TrackedProduct({
                    trackingContextObject: new TrackedTrackingContext({
                        list: 'Produktdetalje side'
                    })
                }
                )
            )
        );
    }
}
